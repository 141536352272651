import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { RootState } from "./index";
import { PageResponse } from "../types/page";
import { OrganMember, OrganMemberDTO, OrganMemberSearchCondition } from "../types/organMember";
import { OrganMemberApi } from "../api/OrganMemberApi";

export interface OrganMemberState extends SliceState {
  organMembers?: PageResponse<OrganMember>;
}

const initialState: OrganMemberState = {
  status: Status.IDLE,
};

export const getOrganMembersAsync = createAsyncThunk(
  "organMember/getOrganMembers",
  ({ organIdx, condition }: { organIdx: number; condition: OrganMemberSearchCondition }) =>
    executePromise(OrganMemberApi.getOrganMembers(organIdx, condition))
);

export const kickOutMembersAsync = createAsyncThunk(
  "organMember/kickOutMembers",
  ({ organIdx, memberIdxes }: { organIdx: number; memberIdxes: number[] }) =>
    executePromise(OrganMemberApi.kickOutMembers(organIdx, memberIdxes))
);

export const organMemberSlice = createSlice({
  name: "organMember",
  initialState,
  reducers: {
    setOrganMembers: (state, action: PayloadAction<PageResponse<OrganMemberDTO>>) => {
      state.organMembers = {
        ...action.payload,
        content: action.payload.content.map((dto) => ({
          ...dto,
          hasOrganVoucher: dto.isOrganVoucher,
        })),
      };
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getOrganMembersAsync);
    addCases(builder, kickOutMembersAsync);
  },
});

export default organMemberSlice.reducer;
export const { setOrganMembers } = organMemberSlice.actions;
export const organMembers = (state: RootState) => state.organMember.organMembers;
export const organMemberStatus = (state: RootState) => state.organMember.status;
