import { useAffiliateCodeFilters } from "./hooks/code/useAffiliateCodeFilters";
import { useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { affiliateCodeStatusState } from "../../store/affiliateCodeSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useAffiliateCodeList } from "./hooks/code/useAffiliateCodeList";
import { ListTable } from "../../layout/content/list/ListTable";
import { useAffiliateCodeColumns } from "./hooks/code/useAffiliateCodeColumns";
import { ListCount, WhichList } from "../../components/ListCount";
import { DetailActionButton } from "../../components/Buttons";
import deleteIcon from "../../assets/images/button_icons/delete_icon.svg";
import stopIcon from "../../assets/images/button_icons/stop_icon.svg";
import confirmIcon from "../../assets/images/button_icons/confirm_icon.svg";
import { useAffiliateCodeDeleterWithValidation } from "./hooks/code/useAffiliateCodeDeleterWithValidation";
import { useAffiliateCodeStatusUpdaterWithValidation } from "./hooks/code/useAffiliateCodeStatusUpdaterWithValidation";
import { AffiliateCodeStatus } from "../../types/affiliateCode";

export function CodeList() {
  const status = useAppSelector(affiliateCodeStatusState);
  useLoader({ status });
  const [selectedCodeIdxes, setSelectedCodeIdxes] = useState<number[]>([]);
  const { totalCodeCount, codeList, getCodeListWithParam, searchCodeList, pageCondition } = useAffiliateCodeList();
  const { filters } = useAffiliateCodeFilters({ search: searchCodeList({}) });

  const { resumeCodeUsage, stopCodeUsage } = useAffiliateCodeStatusUpdaterWithValidation({
    resolve: () => {
      setSelectedCodeIdxes([]);
      getCodeListWithParam();
    },
  });
  const { columns } = useAffiliateCodeColumns({
    resumeCodeUsage: (codeIdxes: number[]) => resumeCodeUsage(codeIdxes),
    stopCodeUsage: (codeIdxes: number[]) => stopCodeUsage(codeIdxes),
    getList: () => getCodeListWithParam(),
  });

  const { deleteCode } = useAffiliateCodeDeleterWithValidation({
    resolve: () => {
      setSelectedCodeIdxes([]);
      getCodeListWithParam();
    },
  });

  return (
    <>
      {codeList ? (
        <ListTable
          filters={filters}
          checkedRowIdxArr={selectedCodeIdxes}
          setCheckedRowIdxArr={setSelectedCodeIdxes}
          showCheckbox={(code) => code.status !== AffiliateCodeStatus.DELETED}
          buttonsNode={
            <>
              <div>
                <DetailActionButton
                  onClick={() => deleteCode(codeList?.content.filter((c) => selectedCodeIdxes.includes(c.idx)))}
                >
                  <img src={deleteIcon} alt={"삭제 아이콘"} />
                  코드삭제
                </DetailActionButton>
              </div>
              <div>
                <DetailActionButton onClick={() => stopCodeUsage(selectedCodeIdxes)}>
                  <img src={stopIcon} alt={"사용중지 아이콘"} />
                  사용중지
                </DetailActionButton>
                <DetailActionButton onClick={() => resumeCodeUsage(selectedCodeIdxes)}>
                  <img src={confirmIcon} alt={"사용재개 아이콘"} />
                  사용재개
                </DetailActionButton>
              </div>
            </>
          }
          listData={codeList}
          getList={({ page, sort, size }) => searchCodeList({ page, sort, size })}
          columns={columns}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalCodeCount ?? 0} whichList={WhichList.CODE} />,
            singleUnitKeyInCommonFile: "codeUnit.singleComponent",
            multipleUnitKeyInCommonFile: "codeUnit.multipleComponent",
          }}
          pageOnFilter={pageCondition}
        />
      ) : (
        <></>
      )}
    </>
  );
}
