import {useMemo} from "react";
import {useNewWindow, useTimeConverter} from "../../../../hooks/hooks";
import {TableColumnData} from "../../../../types/common";
import {BannerListVO, BannerStatus} from "../../../../types/banner";
import {ActionButton, ActiveActionButton} from "../../../../components/Buttons";
import {useBannerType} from "../../../../types/hooks/useBannerType";
import {Anchor} from "../../../../components/Anchor";

interface Args {
    showBanner: (banner: BannerListVO) => void;
    hideBanner: (banner: BannerListVO) => void;
    getList: () => void;
}

export function useBannerColumns({showBanner, hideBanner, getList} : Args) {
    const { timeConverter } = useTimeConverter();
    const {getBannerTypeLabel, getBannerLocationLabel, getBannerLinkOptionLabel,
        getBannerStatusLabel} = useBannerType();
    const {openBannerDetail} = useNewWindow();

    const createdAtColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "createdAt",
            header: "등록일시",
            sortable: true,
            getNode: (vo: BannerListVO) => <span>{timeConverter.convertToLocalTime(vo.createdAt)}</span>,
        }),
        [timeConverter]
    );

    const nameColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "name",
            header: "배너명",
            sortable: false,
            getNode: (vo: BannerListVO) => <Anchor onClick={() => openBannerDetail(vo.idx, () => getList())}>{vo.name}</Anchor>,
        }),
        [openBannerDetail, getList]
    );

    const websiteTypColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "websiteType",
            header: "홈페이지",
            sortable: false,
            getNode: (vo: BannerListVO) => <Anchor onClick={
                () => openBannerDetail(vo.idx, () => getList())
            }>{vo.websiteType}</Anchor>,
        }),
        [openBannerDetail, getList]
    );

    const bannerTypeColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "type",
            header: "배너 언어",
            sortable: false,
            getNode: (vo: BannerListVO) => <>{getBannerTypeLabel(vo.type)}</>,
        }),
        [getBannerTypeLabel]
    );

    const bannerLocationColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "bannerLocation",
            header: "배너 위치",
            sortable: false,
            getNode: (vo: BannerListVO) => <>{getBannerLocationLabel(vo.bannerLocation)}</>,
        }),
        [getBannerLocationLabel]
    );

    const linkOptionColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "linkOption",
            header: "링크 옵션",
            sortable: false,
            getNode: (vo: BannerListVO) => <>{!vo.linkOption ? "링크 없음" : getBannerLinkOptionLabel(vo.linkOption)}</>,
        }),
        [getBannerLinkOptionLabel]
    );

    const durationColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "availableStartAt",
            header: "유효기간",
            sortable: false,
            getNode: (vo: BannerListVO) => (
                <>
                    {timeConverter.convertToLocalDate(vo.availableStartAt)}
                    <br />~ {vo.availableEndAt ? timeConverter.convertToLocalDate(vo.availableEndAt) : "상시사용"}
                </>
            ),
        }),
        [timeConverter]
    );

    const statusColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "status",
            header: "상태",
            sortable: false,
            getNode: (vo: BannerListVO) => (
                <span className={vo.status === BannerStatus.EXPOSED ? "blue" : "red"}>{getBannerStatusLabel(vo.status)}</span>
            ),
        }),
        [getBannerStatusLabel]
    );

    const etcColumn: TableColumnData<BannerListVO> = useMemo(
        () => ({
            accessor: "idx",
            header: "관련 기능",
            sortable: false,
            getNode: (vo: BannerListVO) => (
                <>
                    {vo.status === BannerStatus.EXPOSED ? (
                        <ActionButton onClick={() => hideBanner(vo)}>노출중지</ActionButton>
                    ) : (
                        <ActiveActionButton onClick={() => showBanner(vo)}>노출재개</ActiveActionButton>
                    )}
                </>
            ),
        }),
        [hideBanner, showBanner]
    );

    const columns: TableColumnData<BannerListVO>[] = useMemo(
        () => [createdAtColumn, nameColumn, websiteTypColumn, bannerTypeColumn, bannerLocationColumn,
            linkOptionColumn, durationColumn, statusColumn, etcColumn],
        [createdAtColumn, nameColumn, websiteTypColumn, bannerTypeColumn, bannerLocationColumn,
            linkOptionColumn, durationColumn, statusColumn, etcColumn]
    );

    return{
        columns,
    }
}