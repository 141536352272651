import { MainApi } from "./MainApi";
import { OrganManagerOrganSearchCondition } from "../types/organ";

export class OrganApi {
  static baseUrl = `${MainApi.urlPrefix}`;

  static getOrgans = (organManagerIdx: number, condition: OrganManagerOrganSearchCondition) => () =>
    MainApi.api.get(
      `${OrganApi.baseUrl}/organ-managers/${organManagerIdx}/organs${MainApi.toParamStringFromObject(condition)}`
    );

  static getOrganDetail = (organIdx: number) => () => MainApi.api.get(`${OrganApi.baseUrl}/organ/${organIdx}`);

  static deleteOrgan = (organIdx: number) => () => MainApi.api.delete(`${OrganApi.baseUrl}/organ/${organIdx}`);
}
