import { useAppDispatch } from "../../../../../../hooks/hooks";
import { useCallback } from "react";
import { RefundRequestDTO } from "../../../../../../types/orders";
import { postRefundAsync } from "../../../../../../store/orderSlice";

export function useCreateRefund() {
  const dispatch = useAppDispatch();
  return useCallback(
    (orderIdx: number, refundRequestDTO: RefundRequestDTO, onSuccess: () => void) => {
      return dispatch(postRefundAsync({ orderIdx, refundRequestDTO }))
        .unwrap()
        .then(() => onSuccess());
    },
    [dispatch]
  );
}
