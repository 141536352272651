import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { CategoryTab, DetailSubMenuCategories } from "../../../layout/content/detail/DetailSubMenuCategories";
import { useState } from "react";

enum MenuCategory {
  COUPON = "COUPON",
  VOUCHER = "VOUCHER",
  ORGAN_VOUCHER = "ORGAN_VOUCHER",
}

export function ArticleContentDetailSubmenuCategories({ id, title }: ArticleProps) {
  const [menuCategory, setMenuCategory] = useState(MenuCategory.COUPON);

  const categoryMenus: CategoryTab<MenuCategory>[] = [
    {
      tabName: "쿠폰",
      tabRightValue: `10 장`,
      tabValue: MenuCategory.COUPON,
    },
    {
      tabName: "검사이용권",
      tabRightValue: `5 매`,
      tabValue: MenuCategory.VOUCHER,
    },
    {
      tabName: "소속이용권",
      tabRightValue: "3 매",
      tabValue: MenuCategory.ORGAN_VOUCHER,
    },
  ];

  const code = `
const [menuCategory, setMenuCategory] = useState(MenuCategory.COUPON);

const categoryMenus: CategoryTab<MenuCategory>[] = ${JSON.stringify(categoryMenus, null, 2)};

<DetailSubMenuCategories tabs={categoryMenus}
                         selectedTab={menuCategory}
                         onClickTab={setMenuCategory}/>  
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code}>
          <DetailSubMenuCategories tabs={categoryMenus} selectedTab={menuCategory} onClickTab={setMenuCategory} />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
