import { TableColumnData } from "../../../../../../../types/common";
import {
  OrganVoucherHistoryCategory,
  OrganVoucherHistorySetListDTO,
  VoucherType
} from "../../../../../../../types/voucher";
import { useCallback } from "react";
import styled from "styled-components";
import { OrganManagerVoucherRelatedActionColumn } from "../components/OrganManagerVoucherRelatedActionColumn";
import { useTimeConverter } from "../../../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useVoucherType } from "../../../../../../../types/hooks/useVoucherType";

const PositiveStatus = styled.span`
  color: ${(props) => props.theme.color.FGBlue};
`;

const NegativeStatus = styled.span`
  color: ${(props) => props.theme.color.FGPointRed};
`;

export function useOrganMangerVoucherColumns(): TableColumnData<OrganVoucherHistorySetListDTO>[] {
  const { t } = useTranslation("organManager");
  const { timeConverter } = useTimeConverter();
  const { toStringFromOrganVoucherHistoryCategory } = useVoucherType();

  const getStatus = useCallback(
    (organVoucherHistoryCategory: OrganVoucherHistoryCategory) => {
      const status = toStringFromOrganVoucherHistoryCategory(organVoucherHistoryCategory);
      if (
        organVoucherHistoryCategory === OrganVoucherHistoryCategory.COLLECTED ||
        organVoucherHistoryCategory === OrganVoucherHistoryCategory.BOUGHT ||
        organVoucherHistoryCategory === OrganVoucherHistoryCategory.GRANTED_FROM_ADMIN
      ) {
        return <NegativeStatus>{status}(+)</NegativeStatus>;
      } else {
        return <PositiveStatus>{status}(-)</PositiveStatus>;
      }
    },
    [toStringFromOrganVoucherHistoryCategory]
  );

  return [
    {
      accessor: "voucherType",
      header: t("detail.couponAndVoucher.historyList.type"),
      getNode: (it) => VoucherType[it.voucherType as keyof typeof VoucherType],
      sortable: false,
    },
    {
      accessor: "createAt",
      header: t("detail.couponAndVoucher.historyList.createdAt"),
      getNode: (it) => timeConverter.convertToLocalTime(it.createAt),
      sortable: true,
    },
    {
      accessor: "organName",
      header: t("detail.couponAndVoucher.historyList.organName"),
      getNode: (it) => it.organName ?? "-",
      sortable: false,
    },
    {
      accessor: "historyCount",
      header: t("detail.couponAndVoucher.historyList.voucherCount"),
      sortable: false,
    },
    {
      accessor: "organVoucherHistoryCategory",
      header: t("detail.couponAndVoucher.historyList.status"),
      getNode: (it) => getStatus(it.organVoucherHistoryCategory),
      sortable: false,
    },
    {
      accessor: "idx",
      header: t("detail.couponAndVoucher.historyList.additionalFunctions"),
      getNode: (it) => <OrganManagerVoucherRelatedActionColumn historySet={it} />,
      sortable: false,
    },
  ];
}
