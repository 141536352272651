import { Panel } from "../../../../layout/content/detail/Panel";
import { PanelWrapper } from "../../../../layout/content/detail/PanelWrapper";
import { OrderItemTitleRowStyle } from "../../component/OrderItemTitleRowStyle";
import { TableStyle } from "../../../../components/styled/TableStyle";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
  InputLineGroupFormStyle,
  InputLineStyle,
  InputTextAreaStyle,
  InputTextStyle,
  InputWrapper,
} from "../../../../components/Inputs";
import { Label132x46 } from "../../../../components/Label";
import { Radio, RadioGroup } from "../../../../components/RadioGroup";
import { PrimaryButton } from "../../../../components/Buttons";
import { useAppSelector, useLanguage, useNewWindow } from "../../../../hooks/hooks";
import { useLoader } from "../../../../hooks/common/useLoader";
import { useParams } from "react-router-dom";
import { orderStatus } from "../../../../store/orderSlice";
import useRefundModalGetter, { RefundOrderItem } from "./useRefundModalGetter";
import { numberWithCommas } from "../../../../util/etcUtil";
import { useCreateRefund } from "../component/refund/hooks/useCreateRefund";
import { RefundItemRequestDTO, RefundRequestDTO, RefundType } from "../../../../types/orders";
import { getOnlyNumberText } from "../../../../util/regexpUtil";
import { NumberStepper } from "../../../../components/NumberStepper";
import { WindowMessage } from "../../../../types/newWindow";
import { useRefundAccountGetter } from "../../hooks/useRefundAccountGetter";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";
import style from "../orderDetail.module.scss";

const RefundButton = styled(PrimaryButton)`
  width: 300px;
  margin: 35px auto 20px;
  letter-spacing: -0.6px;
  font-size: 20px;
`;

const SelectedCountStyle = styled.div`
  font-size: 16px;
  line-height: 160%;
  margin: 20px 0;
  text-align: right;
  color: ${(props) => props.theme.color.c75};

  > .count {
    font-weight: bold;
    color: ${(props) => props.theme.color.FGBlue};
  }
`;

const TableWrapperStyle = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  border: 1px solid ${(props) => props.theme.color.cDE};
`;

export function RefundModal() {
  const { t } = useTranslation("order");
  const createRefund = useCreateRefund();
  const [refundPrice, setRefundPrice] = useState<number>(0);
  const [refundMethod, setRefundMethod] = useState<RefundType | null>(null);
  const [refundReason, setRefundReason] = useState<string>("");
  const { id } = useParams<"id">();
  const orderIdx = useMemo(() => Number(id), [id]);
  const { getRefundOrder, refundOrder, updateRefundQuantity, productVouchers  } = useRefundModalGetter();
  const formRef = useRef<HTMLFormElement>(null);
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { loadRefundAccount, customerVO } = useRefundAccountGetter({
    buyerIdx: refundOrder?.buyerIdx,
    customerType: refundOrder?.customerType,
  });
  const { defaultErrorMessage } = useUtil();
  const { isKorean } = useLanguage();
  const isPaymentWayKO = productVouchers && (productVouchers[0].productDTO.language === 'ko');

  useEffect(() => {
    loadRefundAccount();
  }, [loadRefundAccount]);

  const loadRefundOrder = useCallback(
    () => getRefundOrder(orderIdx).catch(() => alert(defaultErrorMessage)),
    [orderIdx, getRefundOrder, defaultErrorMessage]
  );

  useEffect(() => {
    loadRefundOrder().then();
  }, [loadRefundOrder]);

  const totalRefundItemCount = useMemo(
    () => refundOrder?.refundOrderItems?.reduce((sum, item) => sum + item.selectedRefundQuantity, 0) ?? 0,
    [refundOrder]
  );

  const radios: Radio<RefundType>[] = [
    {
      check: (type) => type === RefundType.PG,
      onClickFunc: () => setRefundMethod(RefundType.PG),
      text: t("refundModal.refundWay.pg"),
    },
    {
      check: (type) => type === RefundType.CASH,
      onClickFunc: () => setRefundMethod(RefundType.CASH),
      text: t("refundModal.refundWay.remittance"),
    },
    {
      check: (type) => type === RefundType.PAYPAL,
      onClickFunc: () => setRefundMethod(RefundType.PAYPAL),
      text: t("refundModal.refundWay.paypal"),
    },
  ];

  const filteredRadios = isPaymentWayKO
      ? radios.slice(0, 2) // Show only the first 2 radios if isPaymentWay is true
      : radios.slice(2);

  const status = useAppSelector(orderStatus);
  useLoader({ status });

  const SelectedCount = useCallback(({ count }: { count: number }) => <span className="count">{count}</span>, []);

  return (
    <PanelWrapper>
      {refundOrder && (
        <Panel title={t("refundModal.title")} description={t("refundModal.description")}>
          <>
            <SelectedCountStyle>
              <Trans
                t={t}
                i18nKey={"refundModal.selected"}
                components={[<SelectedCount count={totalRefundItemCount} />]}
              />
            </SelectedCountStyle>

            <TableWrapperStyle>
              <TableStyle
                className={isKorean ? "" : "notKO"}
                style={{
                  margin: "-1px",
                  width: "calc(100% + 2px)",
                }}
              >
                <thead>
                  <tr>
                    <th>{t("refundModal.columns.productName")}</th>
                    <th>{t("refundModal.columns.quantity")}</th>
                    <th>{t("refundModal.columns.refundCompleted")}</th>
                    <th>{t("refundModal.columns.refundableQuantity")}</th>
                    <th>{t("refundModal.columns.selectedQuantity")}</th>
                  </tr>
                </thead>
                <tbody>
                  {refundOrder &&
                    refundOrder.refundOrderItems.map((refundOrderItem: RefundOrderItem) => (
                      <tr key={refundOrderItem.orderItemIdx}>
                        <td>
                          <OrderItemTitleRowStyle>
                            <img src={refundOrderItem.productImage} alt={t("productImage")} />
                            <span>{refundOrderItem.productTitle}</span>
                          </OrderItemTitleRowStyle>
                        </td>
                        <td>{refundOrderItem.paymentQuantity}</td>
                        <td>{refundOrderItem.refundQuantity}</td>
                        <td>{refundOrderItem.refundableQuantity}</td>
                        <td>
                          <NumberStepper
                            value={refundOrderItem.selectedRefundQuantity.toString()}
                            setValue={(value) => {
                              updateRefundQuantity(refundOrderItem.orderItemIdx, Number(getOnlyNumberText(value)));
                            }}
                            min={0}
                            max={refundOrderItem.refundableQuantity}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableStyle>
            </TableWrapperStyle>

            <InputLineGroupFormStyle ref={formRef}>
              <InputLineStyle>
                <Label132x46>{t("refundModal.refundAmount.label")}</Label132x46>
                <InputWrapper>
                  <InputTextStyle
                    required={true}
                    placeholder={t("refundModal.refundAmount.placeholder")}
                    value={numberWithCommas(refundPrice)}
                    onChange={(e) => {
                      let inputRefundPrice = Number(getOnlyNumberText(e.target.value));
                      if (inputRefundPrice > refundOrder.refundablePrice)
                        inputRefundPrice = refundOrder.refundablePrice;
                      setRefundPrice(inputRefundPrice);
                    }}
                  />
                  <span className="description">
                    {t("refundModal.refundAmount.refundableAmount")} :{" "}
                    {isPaymentWayKO
                        ? t("refundModal.refundAmount.amountUnit", {
                          amount: numberWithCommas(refundOrder.refundablePrice),
                        })
                        : t("refundModal.refundAmount.usAmountUnit", {
                          amount: numberWithCommas(refundOrder.refundablePrice),
                        })}
                  </span>
                </InputWrapper>
              </InputLineStyle>

              <InputLineStyle>
                <Label132x46>{t("refundModal.refundMethod")}</Label132x46>
                <RadioGroup
                  radios={filteredRadios}
                  groupName={"radio-refund-method"}
                  isRequired={true}
                  selectedValue={refundMethod}
                  wrapperClassName={style.refundModalRadioGroupWrapper}
                />
              </InputLineStyle>

              <InputLineStyle>
                <Label132x46>{t("refundModal.reason.label")}</Label132x46>
                <InputTextAreaStyle
                  required={true}
                  minLength={2}
                  maxLength={255}
                  placeholder={t("refundModal.reason.placeholder")}
                  defaultValue={""}
                  onChange={(e) => setRefundReason(e.target.value)}
                />
              </InputLineStyle>
            </InputLineGroupFormStyle>

            <RefundButton
              onClick={() => {
                if (!formRef.current?.reportValidity()) return;

                if (totalRefundItemCount === 0) {
                  alert(t("refundModal.validations.noProductSelected"));
                  return;
                }

                if (refundPrice === 0) {
                  alert(t("refundModal.validations.noAmount"));
                  return;
                }

                if (refundOrder?.refundablePrice && refundOrder?.refundablePrice < refundPrice) {
                  alert(t("refundModal.validations.aboveRefundableAmount"));
                  return;
                }

                if (!refundMethod) {
                  alert(t("refundModal.validations.noRefundMethod"));
                  return;
                }

                if (refundReason === "") {
                  alert(t("refundModal.validations.noReason"));
                  return;
                }

                if (refundMethod === RefundType.CASH && !Boolean(customerVO?.refundBankName)) {
                  alert(t("refundModal.validations.noBankAccount"));
                  return;
                }

                const refundOrderItems: RefundItemRequestDTO[] = refundOrder.refundOrderItems.map((refundOrderItem) => {
                  return {
                    orderItemIdx: refundOrderItem.orderItemIdx,
                    quantity: refundOrderItem.selectedRefundQuantity,
                  };
                });

                if (!window.confirm(t("refundModal.confirm"))) return;
                const refundRequestDTO: RefundRequestDTO = {
                  refundOrderItems,
                  refundPrice,
                  refundMethod: refundMethod!,
                  refundReason,
                };
                createRefund(refundOrder.orderIdx, refundRequestDTO, () => {
                  alert(t("refundModal.result"));
                  closeWindowAndNotifyToParentWindow(WindowMessage.REFUNDER);
                });
              }}
            >
              {t("refundModal.button")}
            </RefundButton>
          </>
        </Panel>
      )}
    </PanelWrapper>
  );
}
