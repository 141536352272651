import { DEFAULT_SIZE_IN_SUB_TABLE, PageSearchCondition } from "../../../../types/page";
import { OrganManagerSearchCondition, OrganManagerStatus, QueryType } from "../../../../types/organManager";
import { useOrganManagersGetter } from "./useOrganManagersGetter";

interface Args {
  searchWord: string;
}
export function useConditionalOrganManagerList({ searchWord }: Args) {
  const { getOrganManagerList, list, pageCondition } = useOrganManagersGetter({});

  const getConditionalOrganManagerList = ({ sort }: PageSearchCondition) => {
    const param: OrganManagerSearchCondition = {
      pageCondition: {
        page: 0,
        size: DEFAULT_SIZE_IN_SUB_TABLE,
        sort: sort ?? pageCondition.sort,
      },
      queryType: QueryType.NAME,
      query: searchWord?.trim(),
      status: [OrganManagerStatus.BLOCKED, OrganManagerStatus.DEFAULT],
    };

    return () => {
      getOrganManagerList(param, false).then();
    };
  };

  return {
    getConditionalOrganManagerList,
    list,
    pageCondition,
  };
}
