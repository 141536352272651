import { MiniModal } from "../../../../../../layout/modal/MiniModal";
import { useEffect, useRef, useState } from "react";
import { MINI_MODAL_LINK } from "../../../../../../util/etcUtil";
import { Anchor } from "../../../../../../components/Anchor";
import { useOrganGetter } from "../../../../../organ/useOrganGetter";
import { useTranslation } from "react-i18next";

interface Props {
  organVoucherCount: number;
  organIdx: number;
}
export function OrganVoucherCount({ organVoucherCount, organIdx }: Props) {
  const { t } = useTranslation("organManager");
  const [showModal, setShowModal] = useState(false);
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const { getOrganDetail, organDetail } = useOrganGetter();

  useEffect(() => {
    if (showModal && !organDetail) {
      getOrganDetail(organIdx).then();
    }
  }, [organIdx, getOrganDetail, organDetail, showModal]);

  return (
    <Anchor
      onClick={() => {
        setShowModal(true);
      }}
      style={{ position: "relative" }}
      data-name={MINI_MODAL_LINK}
      ref={linkRef}
    >
      {organVoucherCount}
      <MiniModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        title={t("detail.organMember.organVoucherCount.issuer")}
        modalWidth={"254px"}
        linkElement={linkRef.current ?? undefined}
        modalLeftPosition={"0"}
      >
        <>{organDetail?.organName}</>
      </MiniModal>
    </Anchor>
  );
}
