import { MainApi } from "./MainApi";
import { getTwoDigitsText } from "../util/etcUtil";
import { MonthlySettlementsSearchCondition, SettlementStatusUpdateParam } from "../types/affiliateCode";

export class AffiliateCodeSettlementApi {
  static baseUrl = `${MainApi.urlPrefix}/affiliate-code-settlements`;

  static getAffiliateCodeSettlementStaticsByOrderIdx = (organManagerIdx: number, year: number, month: number) => () =>
    MainApi.api.get(
      `${AffiliateCodeSettlementApi.baseUrl}/${organManagerIdx}/statics?year_month=${year}-${getTwoDigitsText(month)}`
    );

  static getAffiliateCodeSettlementStatics = (year: number, month: number) => () =>
    MainApi.api.get(`${AffiliateCodeSettlementApi.baseUrl}/statics?year_month=${year}-${getTwoDigitsText(month)}`);

  static getAffiliateCodeSettlements = (condition: MonthlySettlementsSearchCondition) => () =>
    MainApi.api.get(`${AffiliateCodeSettlementApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static updateSettlementStatus = (param: SettlementStatusUpdateParam) => () =>
    MainApi.api.put(`${AffiliateCodeSettlementApi.baseUrl}/status`, param);
}
