import { useAppDispatch } from "../../../hooks/hooks";
import { setContentDetailCategory, setContentDetailName } from "../../../store/contentDetailSlice";
import { useCallback } from "react";

interface ReturnType {
  setCategory: (_: string) => void;
  setName: (_: string) => void;
}

export function useContentDetailHeaderInfoSetter(): ReturnType {
  const dispatch = useAppDispatch();

  const setCategory = useCallback(
    (category: string) => dispatch(setContentDetailCategory(category)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const setName = useCallback(
    (name: string) => dispatch(setContentDetailName(name)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    setCategory,
    setName,
  };
}
