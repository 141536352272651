import { SearchCondition } from "./page";
import { CustomerType, ListObject } from "./common";
import { ProductListObj } from "./product";

interface CouponHistoryListObj extends ListObject {
  idx: number;
  coupon: CouponSummary;
  status: CouponHistoryStatus;
  availableStartAt: string;
  availableEndAt: string;
}

enum CouponType {
  TOTAL_AMOUNT_DISCOUNT = "TOTAL_AMOUNT_DISCOUNT",
  PRODUCT_DISCOUNT = "PRODUCT_DISCOUNT",
  GOODS = "GOODS",
}

enum CouponStatus {
  IS_ISSUING = "IS_ISSUING",
  STOP_ISSUING = "STOP_ISSUING",
  DELETED = "DELETED",
}

enum CouponHistoryStatus {
  BEFORE_USE = "BEFORE_USE",
  RESERVED = "RESERVED",
  USED = "USED",
  DELETED = "DELETED",
}

enum CouponAvailableDateType {
  DURATION = "DURATION",
  GRANTED_DATE = "GRANTED_DATE",
}

interface CouponSummary {
  idx: number;
  type: CouponType;
  status: CouponStatus;
  name: string;
  productIdx: number | null;
  availableDateType: CouponAvailableDateType;
  availableStartAt: string | null;
  availableEndAt: string | null;
  availableDateAfterGranted: number | null;
  point: number | null;
  percent: number | null;
  availableAboveAmount: number | null;
}

interface CouponListObj extends ListObject {
  idx: number;
  type: CouponType;
  status: CouponStatus;
  name: string;
  availableAboveAmount: number;
  point: number;
  percent: number;
  availableDateType: CouponAvailableDateType;
  availableDateAfterGranted: number;
  availableStartAt: string;
  availableEndAt: string;
  createAt: string;
  productIdx: number;
}

interface CouponHistorySearchCondition extends SearchCondition {
  isAvailable?: boolean;
}

interface CouponSearchCondition extends SearchCondition {
  q?: string;
  type?: CouponType;
  status?: CouponStatus;
  createdAtFrom?: Date;
  createdAtTo?: Date;
}

interface CouponDeleteParam {
  couponIdxList: number[];
}

interface CouponStatusUpdateParam {
  couponIdxList: number[];
  status: CouponStatus;
}

interface CouponHistoryPostParam {
  customerType: CustomerType;
  userIdx?: number;
  organManagerIdx?: number;
  couponIdxList: number[];
}

interface CouponDetail {
  idx: number;
  type: CouponType;
  name: string;
  product: ProductListObj;
  availableStartAt: string;
  availableEndAt: string;
  point: number;
  percent: number;
  createAt: string;
}

interface CouponPostParam {
  type: CouponType;
  name: string;
  productIdx?: number;
  availableDateType: CouponAvailableDateType;
  availableStartAt?: string;
  availableEndAt?: string;
  availableDateAfterGranted?: number;
  point?: number;
  percent?: number;
  availableAboveAmount?: number;
}

interface CouponCountInfoParam {
  targetIdx: number;
  targetType: CustomerType;
}

interface CouponCntInfo {
  issuedCoupon: number;
  availableCoupon: number;
  unavailableCoupon: number;
}

export type {
  CouponHistoryListObj,
  CouponSummary,
  CouponHistorySearchCondition,
  CouponListObj,
  CouponSearchCondition,
  CouponDeleteParam,
  CouponStatusUpdateParam,
  CouponDetail,
  CouponHistoryPostParam,
  CouponPostParam,
  CouponCountInfoParam,
  CouponCntInfo,
};
export { CouponType, CouponStatus, CouponHistoryStatus, CouponAvailableDateType };
