import styled, { css } from "styled-components";
import { OrderStatus } from "../../../types/orders";

export const OrderStatusStyle = styled.div<{ orderStatus: OrderStatus }>`
  ${(props) =>
    (props.orderStatus === OrderStatus.AWAITING_PAYMENT ||
      props.orderStatus === OrderStatus.PAID ||
      props.orderStatus === OrderStatus.OUTSTANDING_PAYMENT) &&
    css`
      color: ${() => props.theme.color.c75};
    `}

  ${(props) =>
    (props.orderStatus === OrderStatus.FAIL ||
      props.orderStatus === OrderStatus.CANCELLED ||
      props.orderStatus === OrderStatus.PARTIALLY_CANCELLED) &&
    css`
      color: ${() => props.theme.color.FGPointRed};
    `}

  ${(props) =>
    props.orderStatus === OrderStatus.AWAITING_DEPOSIT &&
    css`
      color: ${() => props.theme.color.FGBlue};
    `}
`;
