import React from "react";
import {
  AdminRoleParam,
  AffiliateCodeDurationParam,
  BlockMemberParam,
  CollectProductParam,
  DetailBarInfoCategory,
  GrantCouponParam,
  GrantProductParam,
  MemberUserParam,
  OpenNewWindowParam,
  OrganManagerGrantVoucherParam,
  RefundAccountParam,
  WindowMessage,
} from "../../types/newWindow";
import { CustomerType } from "../../types/common";

export interface RefreshingParam {
  windowMessage: WindowMessage;
  onRefresh: () => void;
}
export interface NewWindowOpenerContextType {
  windowArray: Window[];
  openNewWindowWithURL: (url: string, param: OpenNewWindowParam, refreshParam?: RefreshingParam) => Window | null;
  signOutOnChildren: () => void;
  openUserDetailPopup: (idx: number, param: MemberUserParam) => void;
  openOrganManagerDetailPopup: (idx: number) => void;
  openOrganManagerDetailAffiliateCodeTabPopup: (idx: number, code?: string) => void;
  openCustomerPopup: (idx: number, customerType: CustomerType) => void;
  openCreatedProfileDetailPopup: (idx: number, onRefresh: () => void) => void;
  openAdminDetailPopup: (idx: number) => void;
  openOrderDetailPopup: (idx: number) => void;
  openBlockMemberPopup: (param: BlockMemberParam, onRefresh: () => void) => void;
  openChangingPasswordPopup: () => void;
  openAdminRolePopup: (param: AdminRoleParam, onRefresh: () => void) => void;
  openGrantCoupon: (param: GrantCouponParam, onRefresh?: () => void) => void;
  openGrantProduct: (param: GrantProductParam, onRefresh?: () => void) => void;
  openGroupMemberRegistration: () => void;
  openUnitItemCreator: (onRefresh: () => void) => void;
  openProductDetail: (idx: number, onRefresh?: () => void) => void;
  openCollectProduct: (param: CollectProductParam, onRefresh: () => void) => void;
  openUnitItemProductsInfo: (unitItemIdx: number) => void;
  openSamplePage: (param: { name: string; category: DetailBarInfoCategory; otherParam: string }) => void;
  openRefund: (orderIdx: number, onRefresh: () => void) => void;
  openRefundAccount: (param: RefundAccountParam, onRefresh: () => void) => void;
  openOrganManagerGrantVoucher: (param: OrganManagerGrantVoucherParam, onRefresh?: () => void) => void;
  openAffiliateCodeDurationPopup: (param: AffiliateCodeDurationParam, onRefresh?: () => void) => void;
  closeWindowAndNotifyToParentWindow: (message: WindowMessage) => void;
  openPartnerCompanyDetailPopup: (idx: number, orderingNumber: number | null, onRefresh?: () => void) => void;
  openPopupDetail: (popupIdx: number, onRefresh?: () => void) => void;
  openBannerDetail: (bannerIdx: number, onRefresh?: () => void) => void;
}
export const NewWindowOpenerContext = React.createContext<NewWindowOpenerContextType>(null!);
