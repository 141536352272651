import { CalculatePanelStyle, CalculatePanelTitleStyle, FilterButtonStyle } from "./SettlementStyle";
import listStyle from "../../../layout/content/list/list.module.scss";
import { StrongBlueTextStyle, StrongDarkTextStyle, StrongTextStyle } from "../../../components/Texts";
import { PageSizeSelector } from "../../../layout/content/selector/PageSizeSelector";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ListAreaStyle,
  ListButtonLineStyle,
  ListButtonsWrapperStyle,
  ListPageInfoLineStyle,
  ListPaginationWrapperStyle,
} from "../../../components/styled/ListStyle";
import { Pagination } from "../../../layout/content/list/Pagination";
import { DEFAULT_PAGE, getPageResponseInitialized } from "../../../types/page";
import { SettlementStatus } from "../../../types/affiliateCode";
import { numberWithCommas } from "../../../util/etcUtil";
import { useSettlementStatusTextElement } from "../hooks/code/useSettlementStatusTextElement";
import { AnchorComponent } from "../../../layout/content/AnchorComponent";
import { useAppSelector, useListFilterValues, useNewWindow } from "../../../hooks/hooks";
import { SettlementOneRow, useSettlementDetailList } from "./hooks/useSettlementDetailList";
import { affiliateCodeStatusState } from "../../../store/affiliateCodeSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { useTable } from "../../../layout/content/list/table/useTable";
import { AffiliateCodeSettlementListFilterValues } from "./hooks/useAffiliateCodeSettlementFilterValues";
import { useAffiliateCodeSettlementStatusUpdateButtons } from "./hooks/useAffiliateCodeSettlementStatusUpdateButtons";

interface Props {
  refreshStatics: () => void;
}
export function SettlementDetailList({ refreshStatics }: Props) {
  const { searchList, filterValues } = useListFilterValues();
  const loaderStatus = useAppSelector(affiliateCodeStatusState);
  useLoader({ status: loaderStatus });

  const [checkedIdxArr, setCheckedIdxArr] = useState<number[]>([]);
  const { listByOrganManager, pageCondition, settlements, totalCodeCounts, getList } = useSettlementDetailList();

  const { getSettlementStatusLabelElement } = useSettlementStatusTextElement();
  const { openOrganManagerDetailPopup, openOrganManagerDetailAffiliateCodeTabPopup } = useNewWindow();

  const statusFilterValue = useMemo(
    () => (filterValues as AffiliateCodeSettlementListFilterValues).statusFilterValue,
    [filterValues]
  );

  const yearFilterValue = useMemo(() => filterValues.yearFilterValue, [filterValues.yearFilterValue]);
  const monthFilterValue = useMemo(() => filterValues.monthFilterValue, [filterValues.monthFilterValue]);

  const { onChangeHeaderCheckbox, onChangeRowCheckbox, isAllChecked } = useTable({
    listData: listByOrganManager ?? getPageResponseInitialized([], 0),
    getList: (pageCondition) => () => {
      searchList?.(pageCondition, {
        status: filterValues.status,
        year: yearFilterValue.value,
        month: monthFilterValue.value,
      })();
    },
    checkedRowIdxArr: checkedIdxArr,
    setCheckedRowIdxArr: setCheckedIdxArr,
    showCheckbox: () => true,
  });

  const onClickStatusButton = useCallback(
    (toStatus: SettlementStatus | "") => {
      searchList?.(
        {},
        {
          year: yearFilterValue.value,
          month: monthFilterValue.value,
          status: toStatus,
        }
      )();
    },
    [searchList, yearFilterValue.value, monthFilterValue.value]
  );

  const { getStatusUpdateButtonElement, SettlementCancellationButton, SettlementCompletionButton } =
    useAffiliateCodeSettlementStatusUpdateButtons({
      year: yearFilterValue.value,
      month: monthFilterValue.value,
      resolve: () => {
        getList().then();
        setCheckedIdxArr([]);
        refreshStatics();
      },
    });

  useEffect(() => {}, []);

  return (
    <CalculatePanelStyle>
      <CalculatePanelTitleStyle>월별 정산 상세 내역</CalculatePanelTitleStyle>
      <ListAreaStyle>
        <ListPageInfoLineStyle>
          <span>
            총 정산 대상 회원 <StrongDarkTextStyle>{listByOrganManager?.totalElements ?? 0}</StrongDarkTextStyle>
            명&nbsp;&nbsp;&nbsp;(제휴코드 <StrongBlueTextStyle>{totalCodeCounts}</StrongBlueTextStyle>개)
          </span>
          <PageSizeSelector
            pageSize={pageCondition.size}
            setPageSize={(size) => {
              searchList?.(
                { size, page: DEFAULT_PAGE, sort: pageCondition.sort },
                {
                  status: statusFilterValue.value,
                  year: yearFilterValue.value,
                  month: monthFilterValue.value,
                }
              )();

              setCheckedIdxArr([]);
            }}
          />
        </ListPageInfoLineStyle>
        <ListButtonLineStyle>
          <ListButtonsWrapperStyle>
            <FilterButtonStyle
              className={statusFilterValue.value === SettlementStatus.ALL ? "active" : ""}
              onClick={() => onClickStatusButton(SettlementStatus.ALL)}
            >
              전체
            </FilterButtonStyle>
            <FilterButtonStyle
              className={statusFilterValue.value === SettlementStatus.COMPLETE ? "active" : ""}
              onClick={() => onClickStatusButton(SettlementStatus.COMPLETE)}
            >
              정산완료
            </FilterButtonStyle>
            <FilterButtonStyle
              className={statusFilterValue.value === SettlementStatus.INCOMPLETE ? "active" : ""}
              onClick={() => onClickStatusButton(SettlementStatus.INCOMPLETE)}
            >
              미완료
            </FilterButtonStyle>
          </ListButtonsWrapperStyle>
          <ListButtonsWrapperStyle>
            <SettlementCancellationButton checkedIdxes={checkedIdxArr} />
            <SettlementCompletionButton checkedIdxes={checkedIdxArr} />
          </ListButtonsWrapperStyle>
        </ListButtonLineStyle>
        <table className={listStyle.table}>
          <thead>
            <tr>
              <th className={listStyle.th}>
                <input
                  className={listStyle.checkbox}
                  type={"checkbox"}
                  checked={isAllChecked}
                  onChange={onChangeHeaderCheckbox}
                />
              </th>
              <th className={listStyle.th}>보유자</th>
              <th className={listStyle.th}>제휴코드</th>
              <th className={listStyle.th}>결제건수</th>
              <th className={listStyle.th}>할인 금액</th>
              <th className={listStyle.th}>수수료</th>
              <th className={listStyle.th}>부가세</th>
              <th className={listStyle.th}>세금공제</th>
              <th className={listStyle.th}>정산금액</th>
              <th className={listStyle.th}>정산여부</th>
              <th className={listStyle.th}>관련 기능</th>
            </tr>
          </thead>
          <tbody>
            {settlements && settlements.length > 0 ? (
              <>
                {settlements.map((row: SettlementOneRow, rowIdx) => {
                  if (row.isTotalRow) {
                    return (
                      <tr className={listStyle.blue} key={`${rowIdx}`}>
                        <td className={listStyle.td} colSpan={3}>
                          <StrongTextStyle>계</StrongTextStyle>
                        </td>
                        <td className={listStyle.td}>{row.paidCounts}</td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.discountAmount)}
                        </td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.commissionAmount)}
                        </td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>{numberWithCommas(row.vatAmount)}</td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.deductedTaxAmount)}
                        </td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          <StrongTextStyle>{numberWithCommas(row.settlementAmount)}</StrongTextStyle>
                        </td>
                        <td className={listStyle.td}>
                          {row.status ? getSettlementStatusLabelElement(row.status) : ""}
                        </td>
                        <td className={listStyle.td}>
                          {row.status ? getStatusUpdateButtonElement(row.organManagerIdx, row.status) : ""}
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr
                        key={`${rowIdx}`}
                        className={
                          checkedIdxArr && checkedIdxArr.includes(row.organManagerIdx) ? listStyle.checked : ""
                        }
                      >
                        <td className={listStyle.td}>
                          {row.isFirstRow ? (
                            <input
                              className={listStyle.checkbox}
                              type={"checkbox"}
                              data-idx={row.organManagerIdx}
                              checked={checkedIdxArr.some((idx) => idx === row.organManagerIdx)}
                              onChange={onChangeRowCheckbox}
                            />
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className={listStyle.td}>
                          {row.isFirstRow ? (
                            <AnchorComponent
                              onClickFunc={(e) => {
                                e.preventDefault();
                                openOrganManagerDetailPopup(row.organManagerIdx);
                              }}
                              content={row.organManagerName}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                        <td className={listStyle.td}>
                          <AnchorComponent
                            onClickFunc={(e) => {
                              e.preventDefault();
                              openOrganManagerDetailAffiliateCodeTabPopup(row.organManagerIdx, row.code);
                            }}
                            content={row.code}
                          />
                        </td>
                        <td className={listStyle.td}>{row.paidCounts}</td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.discountAmount)}
                        </td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.commissionAmount)}
                        </td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>{numberWithCommas(row.vatAmount)}</td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.deductedTaxAmount)}
                        </td>
                        <td className={`${listStyle.td} ${listStyle.rightAlign}`}>
                          {numberWithCommas(row.settlementAmount)}
                        </td>
                        <td className={listStyle.td}></td>
                        <td className={listStyle.td}></td>
                      </tr>
                    );
                  }
                })}
              </>
            ) : (
              <tr>
                <td className={listStyle.td} colSpan={11} style={{ padding: "89px 0" }}>
                  정산 상세 내역이 없습니다.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {listByOrganManager && (
          <ListPaginationWrapperStyle>
            <Pagination
              page={listByOrganManager}
              goSpecificPage={(pageNum: number) => {
                searchList?.(
                  { page: pageNum, sort: pageCondition.sort, size: pageCondition.size },
                  {
                    status: filterValues.status,
                    year: yearFilterValue.value,
                    month: monthFilterValue.value,
                  }
                )();
              }}
            />
          </ListPaginationWrapperStyle>
        )}
      </ListAreaStyle>
    </CalculatePanelStyle>
  );
}
