import React, {useMemo} from "react";
import {Panel} from "../../../../../layout/content/detail/Panel";
import style from "../../memberUserCoupons.module.scss";
import {DescriptionValue} from "../../../../../layout/content/detail/DescriptionList";
import {useTranslation} from "react-i18next";
import KPASS_LOGO from "../../../../../assets/images/K-PASS_logo_in_tester.svg";
import DCSS_LOGO from "../../../../../assets/images/D-CAS_logo_in_tester.svg";
import {useLanguage} from "../../../../../hooks/hooks";
import {useUserOrganVoucherCurrentStatus} from "../hooks/useUserOrganVoucherCurrentStatus";
import {VoucherList} from "../../../../../layout/content/detail/VoucherList";

interface Props {
  userIdx: number;
}

export function OrganVoucherInfoStatus({ userIdx }: Props) {
  const { t } = useTranslation("user");
  const { userOrganVoucherNewStatusInfo } = useUserOrganVoucherCurrentStatus({ userIdx, isTesterType: false });
  const {isKorean} = useLanguage();

  const generateVoucherStatus = (
      logo: string, voucherTypeNumber: number) => [
    {
      key: t(""),
      value: <img className={style.logoImg} src={logo} alt={logo}/>,
    },
    {
      key: t("detail.organVoucherInfo.status.grantedVoucher"),
      value: t("detail.organVoucherInfo.status.unit", {
        count: userOrganVoucherNewStatusInfo?.voucherDTOs[voucherTypeNumber].receivedOrganVoucherCount ?? 0,
      }),
    },
    {
      key: t("detail.organVoucherInfo.status.availableVoucher"),
      value: t("detail.organVoucherInfo.status.unit", {
        count: userOrganVoucherNewStatusInfo?.voucherDTOs[voucherTypeNumber].availableOrganVoucherCount ?? 0,
      }),
    },
    {
      key: t("detail.organVoucherInfo.status.collectedVoucher"),
      value: t("detail.organVoucherInfo.status.unit", {
        count: userOrganVoucherNewStatusInfo?.voucherDTOs[voucherTypeNumber].collectedOrganVoucherCount ?? 0,
      }),
    },
  ];

  const kpassVoucherStatus: DescriptionValue[][] = useMemo(() => {
      return [generateVoucherStatus(KPASS_LOGO, 0)];
    //eslint-disable-next-line
  }, [t, userOrganVoucherNewStatusInfo]);

  const dcasVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [generateVoucherStatus(DCSS_LOGO, 1)];
    //eslint-disable-next-line
  }, [t, userOrganVoucherNewStatusInfo]);

  const voucherStatusList: DescriptionValue[][][] = useMemo(() => {
    return [kpassVoucherStatus, dcasVoucherStatus];
  }, [kpassVoucherStatus, dcasVoucherStatus]);




  return (
      <Panel title={t("detail.organVoucherInfo.status.title")}>
        {voucherStatusList.map((status, index) => (
            <React.Fragment key={index}>
              <VoucherList
                  data={status}
                  labelWidth={isKorean ? 130 : [133, 132, 133]}
                  valueWidth={isKorean ? 100 : 80}
                  valueClassName={style.statusCell}
              />
            </React.Fragment>
        ))}
      </Panel>
  );
}
