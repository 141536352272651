import { ListItem, Pre, PreDescription } from "../DocComponents";
import { useState } from "react";
import arrowCollapsed from "../../assets/images/content_layout/nav/arrow_collapsed.svg";
import arrowExpanded from "../../assets/images/content_layout/nav/arrow_expanded.svg";
import styled from "styled-components";

interface Props {
  children: JSX.Element;
  exampleCode: string;
  description?: string;
}

const ToggleButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: #333;
`;

const CollapsedButton = styled(ToggleButton)`
  -webkit-mask: url(${arrowCollapsed}) no-repeat center;
  mask: url(${arrowCollapsed}) no-repeat center;
`;
const ExpandedButton = styled(ToggleButton)`
  -webkit-mask: url(${arrowExpanded}) no-repeat center;
  mask: url(${arrowExpanded}) no-repeat center;
`;

export function ListItemViewAndCode({ children, description, exampleCode }: Props) {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <ListItem>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {showDetail ? (
          <ExpandedButton onClick={() => setShowDetail(false)} />
        ) : (
          <CollapsedButton onClick={() => setShowDetail(true)} />
        )}
        {children}
      </div>
      {showDetail ? (
        <>
          <Pre>{exampleCode}</Pre>
          {description ? <PreDescription>{description}</PreDescription> : <></>}
        </>
      ) : (
        <></>
      )}
    </ListItem>
  );
}
