import { MiniModal } from "../../../../../layout/modal/MiniModal";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  closeModal: VoidFunction;
  linkElement?: HTMLElement;
}

const ContentText = styled.span`
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.6;
  letter-spacing: -0.03em;
`;

export function GrantedVoucherHelpModal({ show, closeModal, linkElement }: Props) {
  const { t } = useTranslation("user");

  return (
    <MiniModal
      show={show}
      closeModal={closeModal}
      title={t("detail.userVoucherInfo.helpModal.title")}
      modalWidth={"218px"}
      linkElement={linkElement}
    >
      <ContentText>{t("detail.userVoucherInfo.helpModal.content")}</ContentText>
    </MiniModal>
  );
}
