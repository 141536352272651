import { useAppDispatch } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useCallback } from "react";
import { updatePartnerCompanyOrderingNumberAsync } from "../../../../store/partnerCompanySlice";
import { PartnerCompanyOrderingNumberUpdateDTO } from "../../../../types/partnerCompany";
import { ErrorType } from "../../../../types/error";

export function usePartnerCompaniesOrderingNumberUpdater() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const updatePartnerCompaniesOrderingNumber = useCallback(
    async (orderingNumberMap: Map<number, number | null>) => {
      try {
        const dtos = Array.from(orderingNumberMap.entries()).map(
          ([key, value]) =>
            ({
              idx: key,
              numericOrder: value,
            } as PartnerCompanyOrderingNumberUpdateDTO)
        );
        await dispatch(updatePartnerCompanyOrderingNumberAsync(dtos)).unwrap();
        alert("협력사 순번이 저장되었습니다.");
      } catch (e: any) {
        console.error({ e });
        if (e.message && (JSON.parse(e.message) as ErrorType).errorCode.httpCode === 430) {
          alert("중복되는 순번이 있습니다. 순번을 다시 수정해주세요.");
        } else {
          alert(defaultErrorMessage);
        }
        throw new Error("협력사 순번 업데이트 에러");
      }
    },
    [defaultErrorMessage, dispatch]
  );

  return {
    updatePartnerCompaniesOrderingNumber,
  };
}
