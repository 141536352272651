import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import {
  OrderListVO,
  OrderSearchCondition,
  OrderSearchDateType,
  OrderSearchWordType,
  OrderStatus,
} from "../../../types/orders";
import { useListFilterValues, useNationality, useTimeConverter } from "../../../hooks/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { hasBothValuesOrBothNoValue } from "../../../util/etcUtil";
import { OrderListQueryType } from "./useOrderFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { useSortParam } from "../../../hooks/list/useSortParam";
import { PaymentWay } from "../../../types/payment";
import { DisplayPosition } from "../../../types/product";
import { useOrdersGetter } from "./useOrdersGetter";
import { dateToString } from "../../../util/dateUtil";

interface Args {
  onCompletedCallback?: () => void;
}

export default function useOrderList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("order");
  const [list, setList] = useState<PageResponse<OrderListVO>>();
  const { defaultErrorMessage } = useUtil();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { toSortArray } = useSortParam();
  const { nationality } = useNationality();
  const { getOrders } = useOrdersGetter();
  const { timeConverter } = useTimeConverter();

  const [totalOrderCount, setTotalOrderCount] = useState<number>(0);
  const pageCondition = useMemo(
    () => ({
      page: list?.number ?? 0,
      size: list?.size ?? 20,
      sort: ordersToSortArr(list?.orders),
    }),
    [list]
  );

  const {
    queryFilterValue,
    queryTypeFilterValue,
    orderSearchDateTypeFilterValue,
    startDateFilterValue,
    endDateFilterValue,
    paymentWayFilterValue,
    statusFilterValue,
    displayPositionFilterValue,
  } = filterValues;

  const searchOrderList = (pageSearchCondition: PageSearchCondition) => {
    const param: OrderListQueryType = {
      query: queryFilterValue.value ? queryFilterValue.value.trim() : undefined,
      queryType: queryTypeFilterValue.value,
      startDate: startDateFilterValue.value ? dateToString(startDateFilterValue.value) : undefined,
      endDate: endDateFilterValue.value ? dateToString(endDateFilterValue.value) : undefined,
      paymentWay: paymentWayFilterValue.value ? paymentWayFilterValue.value : undefined,
      orderSearchDateType: orderSearchDateTypeFilterValue.value ? orderSearchDateTypeFilterValue.value : undefined,
      status: statusFilterValue.value ? statusFilterValue.value : undefined,
      displayPosition: displayPositionFilterValue.value ? displayPositionFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.startDate, param.endDate)) {
        alert(t("list.validations.bothSearchDatesNeeded"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getOrderList = async (param: OrderSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<OrderListVO> = await getOrders(param);
      if (isFirstLoad) {
        setTotalOrderCount(result.totalElements);
      } else {
        setList(result);
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  useEffect(() => {
    getOrderList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrderSearchConditionFromListQueryType = useCallback((): OrderSearchCondition | null => {
    if (listQueryType) {
      const orderListQueryType = listQueryType as OrderListQueryType;
      return {
        pageCondition: {
          page: orderListQueryType.page ? Number(orderListQueryType.page) : 0,
          size: orderListQueryType.size ? Number(orderListQueryType.size) : 20,
          sort: orderListQueryType.sort ? toSortArray(orderListQueryType.sort) : undefined,
        },
        query: orderListQueryType.query?.trim(),
        queryType: orderListQueryType.queryType ? (orderListQueryType.queryType as OrderSearchWordType) : undefined,
        orderSearchDateType: orderListQueryType.orderSearchDateType
          ? (orderListQueryType.orderSearchDateType as OrderSearchDateType)
          : undefined,
        startDate: orderListQueryType.startDate
          ? timeConverter.convertToFilterStartOfDay(orderListQueryType.startDate)
          : undefined,
        endDate: orderListQueryType.endDate
          ? timeConverter.convertToFilterEndOfDay(orderListQueryType.endDate)
          : undefined,
        paymentWay: orderListQueryType.paymentWay ? (orderListQueryType.paymentWay as PaymentWay) : undefined,
        status: orderListQueryType.status ? (orderListQueryType.status as OrderStatus) : undefined,
        displayPosition: orderListQueryType.displayPosition
          ? (orderListQueryType.displayPosition as DisplayPosition)
          : undefined,
      };
    } else {
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  useEffect(() => {
    const param = getOrderSearchConditionFromListQueryType();
    if (param) {
      getOrderList(param).then();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrderSearchConditionFromListQueryType]);

  return {
    searchOrderList,
    totalOrderCount,
    orderList: list,
    pageCondition,
    getOrderSearchConditionFromListQueryType,
  };
}
