import {AnImageAddition} from "../../../components/AnImageAddition";
import {AddedFile, FileType} from "../../../types/file";
import style from "../../product/edit/productEditForm.module.scss";

interface Props {
    image?: AddedFile;
    setImage: (_: AddedFile | undefined) => void;
    addBannerImage?: FileType;
    setAddBannerImage: (_: FileType | undefined) => void;
}
export default function BannerImageField({image, setImage, addBannerImage, setAddBannerImage} : Props) {
    return (
        <>
            <AnImageAddition image={image} setImage={setImage} addedImage={addBannerImage}
                             setAddedImage={setAddBannerImage}
                             additionalText={
                                 <span className={style.text}>
                                     * 배너 이미지는 가로:새로 1:1 비율로 제작해 주세요. <br/>
                                     * 권장 이미지 : 1M 이하 / gif, png, jpg(jpeg) <br/>
                                     * 이미지 크기 : 500px*500px <br/>
                                 </span>
                             }
            />
        </>
    );
};