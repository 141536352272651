import {DescriptionList, DescriptionValue} from "../../../../../../layout/content/detail/DescriptionList";
import {Panel} from "../../../../../../layout/content/detail/Panel";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DetailActionButton} from "../../../../../../components/Buttons";
import authorityFixIcon from "../../../../../../assets/images/button_icons/organ-manager-fix-icon.png";
import {MiddleAlignWrapperStyle} from "../../../../user/components/UserSelfInfoDataStyle";
import confirmIcon from "../../../../../../assets/images/button_icons/confirm_icon.svg";
import {MenuExposedStatus, OrganManagerVO} from "../../../../../../types/organManager";
import {
    OrganMemberVoucherLimitation
} from "../../../../../product/modal/organMemberField/OrganManagerVoucherLimitationField";
import {OrganManagerMenuExposedField} from "../../../../../product/modal/organMemberField/OrganManagerMenuExposedField";
import {AdditionalResultType, TestResultFormType} from "../../../../../../types/common";
import {
    OrganManagerKpassTestResultForm
} from "../../../../../product/modal/organMemberField/OrganManagerKpassTestResultForm";
import {
    OrganManagerKpassAdditionalResult
} from "../../../../../product/modal/organMemberField/OrganManagerKpassAdditionalResult";
import {
    OrganManagerDcasTestResultForm
} from "../../../../../product/modal/organMemberField/OrganManagerDcasTestResultForm";
import {
    OrganManagerDcasAdditionalResult
} from "../../../../../product/modal/organMemberField/OrganManagerDcasAdditionalResult";
import {VoucherPurchaseRestrictions} from "../../../../../../types/voucher";
import {useOrganManagerType} from "../../../../../../types/hooks/useOrganManagerType";
import {useOrganManagerPermissionUpdater} from "../../../../../product/hooks/useOrganManagerPermissionUpdater";
import {useLanguage} from "../../../../../../hooks/hooks";

interface Props {
    organManager: any;
    organManagerIdx: number;
}

export function OrganAuthority({organManager, organManagerIdx}: Props) {
    const {t} = useTranslation("organManager");
    const {isKorean} = useLanguage();
    const {toStringVoucherTypeLimitation, toStringTestResultForm, toStringAdditionalType, toStringFromMenuExposed}
        = useOrganManagerType();
    const {updateOrganManagerPermission} = useOrganManagerPermissionUpdater();

    const [isEditMode, setIsEditMode] = useState(true);
    const [checkedMenuStatus, setCheckedMenuStatus] = useState([MenuExposedStatus.HOME_AND_ORGAN_MANAGEMENT,
        MenuExposedStatus.PURCHASE_VOUCHER, MenuExposedStatus.PURCHASE_VOUCHER]);
    const [voucherPurchaseRestrictions, setVoucherPurchaseRestrictions] = useState(VoucherPurchaseRestrictions.NONE);
    const [kpassResultForm, setKpassResultForm] = useState(TestResultFormType.ADDITIONAL);
    const [dcasResultForm, setDcasResultForm] = useState(TestResultFormType.ADDITIONAL);
    const [kpassAdditionalResult, setKpassAdditionalResult] = useState(AdditionalResultType.NONE);
    const [dcasAdditionalResult, setDcasAdditionalResult] = useState(AdditionalResultType.NONE);

    useEffect(() => {
        const adminPermission = organManager.organManagerPermissionDTO;

        setCheckedMenuStatus(adminPermission.accessibleMenuDTO.menuCode)
        setVoucherPurchaseRestrictions(adminPermission.voucherPurchaseRestrictions);
        setKpassResultForm(adminPermission.kpassResultForm);
        setKpassAdditionalResult(adminPermission.kpassAdditionalResultForm);
        setDcasResultForm(adminPermission.dcasResultForm);
        setDcasAdditionalResult(adminPermission.dcasAdditionalResultForm);
        //eslint-disable-next-line
    }, []);

    const onClickOrganPermissionUpdate = useCallback(() => {
        const isConfirmed = window.confirm("소속관리자 권한을 수정하시겠습니까?");
        if (isConfirmed) {
            updateOrganManagerPermission({
                organManagerIdx,
                accessibleMenuPutDTO: {
                    menuCode: checkedMenuStatus
                },
                voucherPurchaseRestrictions,
                kpassAdditionalResultForm: kpassAdditionalResult,
                kpassResultForm,
                dcasAdditionalResultForm: dcasAdditionalResult,
                dcasResultForm,

            }).then(() => {
                alert("소속관리자 권한이 수정되었습니다.");
                window.location.reload()
            })
        }

        //eslint-disable-next-line
    }, [
        checkedMenuStatus,
        voucherPurchaseRestrictions,
        kpassAdditionalResult,
        kpassResultForm,
        dcasAdditionalResult,
        dcasResultForm
    ]);


    const buttons = (
        isKorean ? (
            isEditMode ? (
                <>
                    <DetailActionButton
                        onClick={() => {
                            setIsEditMode((p) => !p);
                        }}
                    >
                        <img src={authorityFixIcon} alt={t("list.putOrganMember.editButtonLabel")}/>
                        {t("list.putOrganMember.editButtonLabel")}
                    </DetailActionButton>
                </>
            ) : (
                <>
                    <DetailActionButton
                        onClick={() => {
                            setIsEditMode((p) => !p);
                        }}>
                        {t("list.putOrganMember.cancelButtonLabel")}
                    </DetailActionButton>
                    <DetailActionButton
                        onClick={() => onClickOrganPermissionUpdate()}
                    >
                        <img src={confirmIcon} alt={t("list.putOrganMember.confirmButtonLabel")}/>
                        {t("list.putOrganMember.confirmButtonLabel")}
                    </DetailActionButton>

                </>
            )
        ) : <></>
    );

    const getOrganDescription = function (organ: OrganManagerVO): DescriptionValue[][] {
        return [
            [
                {
                    key: t("registration.menuExposed"),
                    value: isEditMode
                        ? (
                            <div style={{gap: '10px', display: 'flex'}}>
                                {organ.organManagerPermissionDTO.accessibleMenuDTO.menuCode.map((idx) => (
                                    <span key={idx}>{toStringFromMenuExposed(idx)}</span>
                                ))}
                            </div>
                        )
                        : <OrganManagerMenuExposedField
                            checkedMenuStatus={checkedMenuStatus} setCheckedMenuStatus={setCheckedMenuStatus}/>
                }
                ,
            ],
            [
                {
                    key: t("registration.productPurchaseLimit"),
                    value: isEditMode
                        ? toStringVoucherTypeLimitation(organ?.organManagerPermissionDTO.voucherPurchaseRestrictions)
                        : <OrganMemberVoucherLimitation voucherTypeLimitation={voucherPurchaseRestrictions}
                                                        setVoucherTypeLimitation={setVoucherPurchaseRestrictions}/>
                }],
            [
                {
                    key: t("registration.kpassResultType"),
                    value:
                        isEditMode ? <MiddleAlignWrapperStyle>
                                {toStringTestResultForm(organ?.organManagerPermissionDTO.kpassResultForm)}
                            </MiddleAlignWrapperStyle>
                            : <MiddleAlignWrapperStyle>
                                <OrganManagerKpassTestResultForm kpassResultForm={kpassResultForm}
                                                                 setKpassResultForm={setKpassResultForm}/>
                            </MiddleAlignWrapperStyle>
                },
                {
                    key: t("registration.kpassAdditionalResult"),
                    value: (
                        isEditMode
                            ? <MiddleAlignWrapperStyle>
                                {toStringAdditionalType(organ?.organManagerPermissionDTO.kpassAdditionalResultForm)}
                            </MiddleAlignWrapperStyle>
                            : <MiddleAlignWrapperStyle>
                                <OrganManagerKpassAdditionalResult kpassAdditionalResult={kpassAdditionalResult}
                                                                   setKpassAdditionalResult={setKpassAdditionalResult}/>
                            </MiddleAlignWrapperStyle>
                    ),
                },
            ],
            [
                {
                    key: t("registration.dcasResultType"),
                    value: isEditMode
                        ? <MiddleAlignWrapperStyle>
                            {toStringTestResultForm(organ.organManagerPermissionDTO.dcasResultForm)}
                        </MiddleAlignWrapperStyle>
                        : <MiddleAlignWrapperStyle>
                            <OrganManagerDcasTestResultForm dcasResultForm={dcasResultForm}
                                                            setDcasResultForm={setDcasResultForm}
                            />
                        </MiddleAlignWrapperStyle>
                    ,

                },
                {
                    key: t("registration.dcasAdditionalResult"),
                    value: (isEditMode
                            ? <MiddleAlignWrapperStyle>
                                {toStringAdditionalType(organ.organManagerPermissionDTO.dcasAdditionalResultForm)}
                            </MiddleAlignWrapperStyle>
                            : <MiddleAlignWrapperStyle>
                                <OrganManagerDcasAdditionalResult dcasAdditionalResult={dcasAdditionalResult}
                                                                  setDcasAdditionalResult={setDcasAdditionalResult}/>
                            </MiddleAlignWrapperStyle>
                    ),

                },
            ],
        ];
    };

    return (
        <>
            <Panel title={t("detail.organManagement.organs.authorityTitle")}
                   additionalButtons={buttons}>

                {organManager ? (
                    <>
                        <DescriptionList
                            labelWidth={120} data={getOrganDescription(organManager)}
                            // isEditMode={isEditMode}
                        />
                    </>

                ) : (
                    <></>
                )}
            </Panel>
        </>
    )
}