import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../layout/content/detail/Panel";
import { List } from "../../DocComponents";
import { ArticleProps } from "../../docType";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { ActionButton } from "../../../components/Buttons";
import { ArticleWrapper } from "./ArticleWrapper";

export function ArticlePanel({ id, title }: ArticleProps) {
  const code = `
<PanelWrapper style={{width: "100%"}}>
  <Panel title={"Panel 1 제목"}
         panelClassName={""}
         subtitle={"부제"}
         additionalButtons={<>
           <ActionButton>취소</ActionButton>
         </>}>
    <div>샘플 1</div>
  </Panel>
  <Panel title={"Panel 2"}>
    <div>샘플 2</div>
  </Panel>
</PanelWrapper>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code}>
          <PanelWrapper style={{ width: "100%" }}>
            <Panel
              title={"Panel 1 제목"}
              panelClassName={""}
              subtitle={"부제"}
              additionalButtons={
                <>
                  <ActionButton>취소</ActionButton>
                </>
              }
            >
              <div>샘플 1</div>
            </Panel>
            <Panel title={"Panel 2"}>
              <div>샘플 2</div>
            </Panel>
          </PanelWrapper>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
