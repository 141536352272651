import { useAppDispatch } from "../../../hooks/hooks";
import { useCallback } from "react";
import { deleteOrderAsync } from "../../../store/orderSlice";

interface ReturnType {
  deleteOrder: (_: number) => Promise<any>;
}
export function useOrderDeleter(): ReturnType {
  const dispatch = useAppDispatch();

  const deleteOrder = useCallback(
    (orderIdx: number) => {
      return dispatch(deleteOrderAsync(orderIdx)).unwrap();
    },
    [dispatch]
  );

  return {
    deleteOrder,
  };
}
