import {RadioGroupField} from "../../../components/RadioGroupField";
import {usePopupType} from "../../../types/hooks/usePopupType";
import {WebsiteType} from "../../../types/common";

interface Props {
    websiteType: WebsiteType;
    setWebsiteType: (_: WebsiteType) => void;
}
export default function WebsiteTypeField({websiteType, setWebsiteType} : Props) {
    const {getWebsiteTypeLabel} = usePopupType();

    return (
        <>
            <RadioGroupField enumValues={Object.values(WebsiteType)}
                             getLabel={getWebsiteTypeLabel}
                             value={websiteType} setValue={setWebsiteType}
                             groupName={"websiteType"} isRequired={true}
            />
        </>
    );
};