import style from "../../../layout/content/post/creator.module.scss";

interface Props {
    buttonLink: string;
    setButtonLink: (_: string) => void;
}

export default function BannerButtonLink({buttonLink, setButtonLink}: Props) {

    return (
        <>
            <input className={style.halfInputField}
                value={buttonLink}
                required={true}
                onChange={(e) => setButtonLink(e.target.value)}
                placeholder={"버튼 링크(URL) 입력"}
            />
        </>
    );
};