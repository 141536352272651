import { Panel } from "../../../../layout/content/detail/Panel";
import { PanelWrapper } from "../../../../layout/content/detail/PanelWrapper";
import { Label132x46 } from "../../../../components/Label";
import { InputLineGroupFormStyle, InputLineStyle, InputTextStyle } from "../../../../components/Inputs";
import { Radio, RadioGroup } from "../../../../components/RadioGroup";
import { useRef, useState } from "react";
import { PrimaryButton } from "../../../../components/Buttons";
import styled from "styled-components";
import queryString from "query-string";
import { useRefundAccountModifier } from "../component/refund/hooks/useRefundAccountModifier";
import { CustomerType } from "../../../../types/common";
import { WindowMessage } from "../../../../types/newWindow";
import banks from "../../../../types/banks";
import { useNewWindow } from "../../../../hooks/hooks";
import { getOnlyNumberText } from "../../../../util/regexpUtil";
import { useTranslation } from "react-i18next";

const RefundButton = styled(PrimaryButton)`
  width: 300px;
  margin: 35px auto 20px;
  letter-spacing: -0.6px;
  font-size: 20px;
`;

export function RefundAccountModal() {
  const { t } = useTranslation("order");
  const {
    customerIdx: queryCustomerIdx,
    customerType: queryCustomerType,
    accountName: queryAccountName,
    accountNumber: queryAccountNumber,
    bankName: queryBankName,
  } = queryString.parse(window.location.search);
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  const [accountName, setAccountName] = useState<string>(() => (queryAccountName ? queryAccountName.toString() : ""));
  const [accountNumber, setAccountNumber] = useState<string>(() =>
    queryAccountNumber ? queryAccountNumber.toString() : ""
  );
  const [bankName, setBankName] = useState<string>(() => (queryBankName ? queryBankName.toString() : ""));
  const modifyRefundAccount = useRefundAccountModifier();
  const customerIdx = queryCustomerIdx ? Number(queryCustomerIdx.toString()) : -1;
  const customerTypeString = queryCustomerType ? queryCustomerType.toString() : "";
  const customerType = customerTypeString === "USER" ? CustomerType.USER : CustomerType.ORGAN_MANAGER;
  const formRef = useRef<HTMLFormElement>(null);

  const radios: Radio<string>[] = banks.map((bankName) => {
    return {
      check: (type: string) => type === bankName,
      onClickFunc: () => setBankName(bankName),
      text: bankName,
    };
  });

  return (
    <PanelWrapper>
      <Panel title={t("refundAccountModal.title")} description={t("refundAccountModal.description")}>
        <>
          <InputLineGroupFormStyle ref={formRef}>
            <InputLineStyle>
              <Label132x46>{t("refundAccountModal.bank.label")}</Label132x46>

              <RadioGroup
                radios={radios}
                wrapperClassName={"refund-radio-group"}
                groupName={"radio-refund-bank"}
                isRequired={true}
                selectedValue={bankName ?? ""}
                labelWidth={"100px"}
              />
            </InputLineStyle>

            <InputLineStyle>
              <Label132x46>{t("refundAccountModal.accountNumber.label")}</Label132x46>
              <InputTextStyle
                required={true}
                minLength={7}
                maxLength={20}
                value={accountNumber}
                onChange={(e) => {
                  const onlyNumberText = getOnlyNumberText(e.target.value);
                  setAccountNumber(onlyNumberText);
                }}
                placeholder={t("refundAccountModal.accountNumber.placeholder")}
              />
            </InputLineStyle>

            <InputLineStyle>
              <Label132x46>{t("refundAccountModal.holderName.label")}</Label132x46>
              <InputTextStyle
                required={true}
                minLength={2}
                maxLength={50}
                value={accountName}
                onChange={(e) => setAccountName(e.target.value)}
                onBlur={() => setAccountName(accountName.trim())}
                placeholder={t("refundAccountModal.holderName.placeholder")}
              />
            </InputLineStyle>
          </InputLineGroupFormStyle>

          <RefundButton
            onClick={() => {
              if (!formRef.current?.reportValidity()) return;

              if (!bankName) {
                alert(t("refundAccountModal.bank.noBank"));
                return;
              }

              if (!window.confirm(t("refundAccountModal.confirm"))) return;

              modifyRefundAccount(
                customerIdx,
                customerType,
                {
                  refundBankName: bankName,
                  refundAccountNumber: accountNumber.trim(),
                  refundAccountName: accountName.trim(),
                },
                () => {
                  alert(t("refundAccountModal.result"));
                  closeWindowAndNotifyToParentWindow(WindowMessage.REFUND_ACCOUNT);
                }
              );
            }}
          >
            {t("refundAccountModal.saveButton")}
          </RefundButton>
        </>
      </Panel>
    </PanelWrapper>
  );
}
