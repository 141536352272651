import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Textarea } from "../../../components/Textarea";

export function ArticleTextarea({ id, title }: ArticleProps) {
  const code = `
<Textarea
  style={{
    width: "100%",
    height: "100px"
  }}
  placeholder={"Textarea 샘플"}/>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code}>
          <Textarea
            style={{
              width: "100%",
              height: "100px",
            }}
            placeholder={"Textarea 샘플"}
          />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
