import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { PaymentWay, ReceiptType } from "../payment";

export function usePaymentType() {
  const { t } = useTranslation(["order", "common"]);

  const toStringFromReceiptType = useCallback(
    (type: ReceiptType) => {
      switch (type) {
        case ReceiptType.UNISSUED:
          return t("receiptType.unissued");
        case ReceiptType.DEDUCTION:
          return t("receiptType.deduction");
        case ReceiptType.PROOF:
          return t("receiptType.proof");
      }
    },
    [t]
  );

  const toStringFromPaymentWay = useCallback(
    (p: PaymentWay) => {
      switch (p) {
        case PaymentWay.CARD:
          return t("paymentWay.card");
        case PaymentWay.VBANK:
          return t("paymentWay.bank");
        case PaymentWay.GOOGLE:
          return "Google Play";
        case PaymentWay.APPLE:
          return "App Store";
        case PaymentWay.PAYPAL:
          return t("paymentWay.paypal");
        default:
          return "";
      }
    },
    [t]
  );

  const paymentWayOptions = useMemo(
    (defaultLabel: string = t("all", { ns: "common" })) => [
      { value: "", label: defaultLabel },
      { value: PaymentWay.CARD, label: toStringFromPaymentWay(PaymentWay.CARD) },
      { value: PaymentWay.VBANK, label: toStringFromPaymentWay(PaymentWay.VBANK) },
      { value: PaymentWay.PAYPAL, label: toStringFromPaymentWay(PaymentWay.PAYPAL) },
      { value: PaymentWay.GOOGLE, label: toStringFromPaymentWay(PaymentWay.GOOGLE) },
      { value: PaymentWay.APPLE, label: toStringFromPaymentWay(PaymentWay.APPLE) },
    ],
    [t, toStringFromPaymentWay]
  );

  return {
    toStringFromReceiptType,
    toStringFromPaymentWay,
    paymentWayOptions,
  };
}
