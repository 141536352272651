import { usePartnerCompanyFilterValues } from "./hooks/partner/usePartnerCompanyFilterValues";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";
import { PartnerCompanyList } from "./PartnerCompanyList";

export function PartnerCompanyListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = usePartnerCompanyFilterValues();
  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <PartnerCompanyList />
    </ListFilterValuesContext.Provider>
  );
}
