import {Gender, ListObject, TestResultFormType} from "./common";
import { UserStatus } from "./user";
import { SearchCondition } from "./page";

enum TestQueryType {
  USER_EMAIL = "USER_EMAIL",
  USER_NAME = "USER_NAME",
  TEST_REGION = "TEST_REGION",
}

interface TestListDTO extends ListObject {
  idx: number;
  userIdx: number;
  profileIdx: number;
  userStatus: UserStatus;
  endedAt: string;
  email: string;
  name: string;
  userCode: string;
  ageYear: number;
  ageMonth: number;
  gender?: Gender;
  region?: string;
  testRound: number;
  isDeleted: boolean;
  nationality: string;
  dcasResultForm?: TestResultFormType;
  kpassResultForm?: TestResultFormType;
}

interface TestListObj extends ListObject {
  idx: number;
  userIdx: number;
  profileIdx: number;
  userStatus: UserStatus;
  endedAt: string;
  email: string;
  name: string;
  userCode: string;
  age: string | null;
  gender?: Gender;
  region?: string;
  testRound: number;
  isDeleted: boolean;
  nationality: string;
  dcasResultForm?: TestResultFormType;
  kpassResultForm?: TestResultFormType;
}

interface TestSearchCondition extends SearchCondition {
  qtype?: TestQueryType;
  q?: string;
  startDate?: Date;
  endDate?: Date;
  minTestCnt?: number;
  maxTestCnt?: number;
  minAge?: number;
  maxAge?: number;
  gender?: Gender;
}

export { TestQueryType };

export type { TestListDTO, TestListObj, TestSearchCondition };
