import { useUnitItemFilterValues } from "./hooks/useUnitItemFilterValues";
import { UnitItemList } from "./UnitItemList";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function UnitItemListWrapper() {
  const { pageInfoOnFilter, filterValues, searchList, listQueryType } = useUnitItemFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <UnitItemList />
    </ListFilterValuesContext.Provider>
  );
}
