import { useListFilterValues, useNationality, useTimeConverter } from "../../../../hooks/hooks";
import { useEffect, useState } from "react";
import { DEFAULT_PAGE, DEFAULT_SIZE, DEFAULT_SORT, PageSearchCondition } from "../../../../types/page";
import { OrganManagerSearchCondition, OrganManagerStatus, QueryType } from "../../../../types/organManager";
import { hasBothValuesOrBothNoValue } from "../../../../util/etcUtil";
import { OrganManagerListQueryType } from "./useOrganManagerFilterValues";
import { useTranslation } from "react-i18next";
import { useSortParam } from "../../../../hooks/list/useSortParam";
import { dateToString } from "../../../../util/dateUtil";
import { useOrganManagersGetter } from "./useOrganManagersGetter";

interface Args {
  onCompletedCallback?: () => void;
}

export function useOrganManagerList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("organManager");

  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { toSortArray } = useSortParam();
  const { nationality } = useNationality();
  const { timeConverter } = useTimeConverter();

  const [totalOrganManagerCount, setTotalOrganManagerCount] = useState<number>(0);
  const { getOrganManagerList, list, pageCondition } = useOrganManagersGetter({
    setTotalOrganManagerCount,
    onCompletedCallback,
  });

  const {
    queryFilterValue,
    queryTypeFilterValue,
    startDateOfCreateAtFilterValue,
    endDateOfCreateAtFilterValue,
    statusFilterValue,
    minVoucherCountFilterValue,
    maxVoucherCountFilterValue,
  } = filterValues;

  const searchOrganManagerList = (pageSearchCondition: PageSearchCondition) => {
    const param: OrganManagerListQueryType = {
      query: queryFilterValue.value ? queryFilterValue.value.trim() : undefined,
      queryType: queryTypeFilterValue.value,
      startDateOfCreateAt: startDateOfCreateAtFilterValue.value
        ? dateToString(startDateOfCreateAtFilterValue.value)
        : undefined,
      endDateOfCreateAt: endDateOfCreateAtFilterValue.value
        ? dateToString(endDateOfCreateAtFilterValue.value)
        : undefined,
      status: statusFilterValue.value ? statusFilterValue.value : undefined,
      minVoucherCount: minVoucherCountFilterValue.value ? minVoucherCountFilterValue.value : undefined,
      maxVoucherCount: maxVoucherCountFilterValue.value ? maxVoucherCountFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.startDateOfCreateAt, param.endDateOfCreateAt)) {
        alert(t("list.validations.bothCreatedAtNeeded"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.minVoucherCount, param.maxVoucherCount)) {
        alert(t("list.validations.bothOrganVoucherCountNeeded"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  useEffect(() => {
    getOrganManagerList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      const param: OrganManagerSearchCondition = {
        pageCondition: {
          page: listQueryType.page ? Number(listQueryType.page) : DEFAULT_PAGE,
          size: listQueryType.size ? Number(listQueryType.size) : DEFAULT_SIZE,
          sort: listQueryType.sort ? toSortArray(listQueryType.sort) : DEFAULT_SORT,
        },
        queryType: listQueryType.queryType ? (listQueryType.queryType as QueryType) : undefined,
        query: listQueryType.query?.trim(),
        status: listQueryType.status ? [listQueryType.status as OrganManagerStatus] : undefined,
        startDateOfCreatedAt: listQueryType.startDateOfCreateAt
          ? timeConverter.convertToFilterStartOfDay(listQueryType.startDateOfCreateAt)
          : undefined,
        endDateOfCreatedAt: listQueryType.endDateOfCreateAt
          ? timeConverter.convertToFilterEndOfDay(listQueryType.endDateOfCreateAt)
          : undefined,
        minVoucherCount: listQueryType.minVoucherCount ? Number(listQueryType.minVoucherCount) : undefined,
        maxVoucherCount: listQueryType.maxVoucherCount ? Number(listQueryType.maxVoucherCount) : undefined,
      };

      getOrganManagerList(param).then();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchOrganManagerList,
    totalOrganManagerCount,
    organManagerList: list,
    pageCondition,
  };
}
