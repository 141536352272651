import style from "../productItemModal.module.scss";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {pwdRegExp} from "../../../../util/regexpUtil";

interface Props {
    password: string;
    setPassword: (_: string) => void;
}

export function OrganManagerPasswordField({password, setPassword}: Props) {
    const {t} = useTranslation("organManager");
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [isPasswordChecked, setIsPasswordChecked] = useState(false);

    useEffect(() => {
        checkPassword();
        //eslint-disable-next-line
    }, [password]);

    function checkPassword() {
        setIsPasswordChecked(pwdRegExp.test(password));
    }

    return (
        <div className={style.fieldLine}>
            <input
                minLength={8}
                maxLength={16}
                className={style.inputField}
                value={password}
                required={true}
                type={"password"}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("registration.validator.passwordPlaceholder")}
                onKeyUp={(e) => {
                    if (e.getModifierState("CapsLock")) {
                        setIsCapsLockOn(true);
                    } else {
                        setIsCapsLockOn(false);
                    }
                }}
            />
            {isCapsLockOn && <div className={style.capsLockMessage}>{t("registration.capsLockOn")}</div>}
            {password.length > 0 && (
                <>
                    {isPasswordChecked
                        ? <div className={style.correctMessage}>{t("registration.validator.passwordChecked")}</div>
                        : <div className={style.errorMessage}>{t("registration.validator.passwordUnchecked")}</div>
                    }
                </>
            )}
            <span>영문 대소문자/숫자/특수문자를 조합한 8~16자리 입력</span>
        </div>
    )
}