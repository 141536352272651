import { ListFilterValuesContext } from "../../../hooks/list/ListFilterValuesContext";
import { useUserFilterValues } from "./hooks/useUserFilterValues";
import { MemberUser } from "./MemberUser";

export function MemberUserWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useUserFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <MemberUser />
    </ListFilterValuesContext.Provider>
  );
}
