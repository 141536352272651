import { OrganManagerOrganDTO } from "../../../../../../types/organ";
import { Panel } from "../../../../../../layout/content/detail/Panel";
import React from "react";
import { DetailActionButton } from "../../../../../../components/Buttons";
import deleteIcon from "../../../../../../assets/images/button_icons/delete_icon.svg";
import { dateToString } from "../../../../../../util/dateUtil";
import { DescriptionList, DescriptionValue } from "../../../../../../layout/content/detail/DescriptionList";
import { useTranslation } from "react-i18next";
import { useOrganDeleter } from "../../../../../organ/useOrganDeleter";
import style from "../../../../member.module.scss";
import { MiddleAlignWrapperStyle } from "../../../../user/components/UserSelfInfoDataStyle";

interface Props {
  organ?: OrganManagerOrganDTO;
  resetSelectedOrgan: () => void;
}

export function OrganDetail({ organ, resetSelectedOrgan }: Props) {
  const { t } = useTranslation("organManager");
  const { deleteOrgan } = useOrganDeleter();
  const getDescriptions = function (organ: OrganManagerOrganDTO): DescriptionValue[][] {
    return [
      [
        { key: t("detail.organManagement.organ.organName"), value: organ.organName },
        { key: t("detail.organManagement.organ.createdAt"), value: dateToString(new Date(organ.createAt)) },
      ],
      [
        {
          key: t("detail.organManagement.organ.organType"),
          value: <MiddleAlignWrapperStyle>{organ.organType}</MiddleAlignWrapperStyle>,
        },
        {
          key: t("detail.organManagement.organ.phoneNumber"),
          value: (
            <MiddleAlignWrapperStyle>
              ({organ.codePhone}) {organ.phoneNumber}
            </MiddleAlignWrapperStyle>
          ),
        },
      ],
      [
        {
          key: t("detail.organManagement.organ.memberCount.label"),
          value: (
            <MiddleAlignWrapperStyle>
              {t("detail.organManagement.organ.memberCount.unit", { count: organ.memberCount })}
            </MiddleAlignWrapperStyle>
          ),
        },
        {
          key: t("detail.organManagement.organ.totalTestCount.label"),
          value: (
            <MiddleAlignWrapperStyle>
              {t("detail.organManagement.organ.totalTestCount.unit", { count: organ.testCompletionCount })}
            </MiddleAlignWrapperStyle>
          ),
        },
      ],
    ];
  };

  const buttons = (
    <>
      <DetailActionButton
        onClick={() => {
          if (organ && organ.memberCount > 0) {
            alert(t("organDeleter.notDeletableAlert"));
            return;
          }

          if (organ && window.confirm(t("organDeleter.confirm", { organName: organ.organName }))) {
            deleteOrgan(organ.idx).then(() => {
              alert(t("organDeleter.completeAlert"));
              resetSelectedOrgan();
            });
          }
        }}
      >
        <img src={deleteIcon} alt={t("detail.organManagement.deleteOrgan.icon")} />
        {t("detail.organManagement.deleteOrgan.label")}
      </DetailActionButton>
    </>
  );

  return organ ? (
    <Panel
      title={t("detail.organManagement.organ.title")}
      subtitle={`(${organ.organName})`}
      additionalButtons={buttons}
    >
      <DescriptionList data={getDescriptions(organ)} labelWidth={132} labelClassName={style.descriptionListDt} />
    </Panel>
  ) : (
    <></>
  );
}
