import { ListQueryType } from "../../../../types/list";
import { useCallback, useMemo, useState } from "react";
import { AffiliateCodeSearchWordType, AffiliateCodeStatus } from "../../../../types/affiliateCode";
import { PageSearchCondition } from "../../../../types/page";
import { FilterType, FilterValue } from "../../../../types/filter";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface AffiliateCodeListQueryType extends ListQueryType {
  searchWordType?: string;
  searchWord?: string;
  availableStartAt?: string;
  availableEndAt?: string;
  paidCountFrom?: string;
  paidCountTo?: string;
  status?: string;
}

export interface AffiliateCodeFilterValues {
  searchWordTypeFilterValue: FilterValue<AffiliateCodeSearchWordType>;
  searchWordFilterValue: FilterValue<string>;
  availableStartAtFilterValue: FilterValue<Date | undefined>;
  availableEndAtFilterValue: FilterValue<Date | undefined>;
  paidCountFromFilterValue: FilterValue<string>;
  paidCountToFilterValue: FilterValue<string>;
  statusFilterValue: FilterValue<AffiliateCodeStatus | "">;
}

interface ReturnType {
  pageInfoOnFilter: PageSearchCondition;
  filterValues: AffiliateCodeFilterValues;
  searchList: (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: AffiliateCodeListQueryType) => () => void;
  listQueryType?: AffiliateCodeListQueryType;
}

export function useAffiliateCodeFilterValues(): ReturnType {
  const [searchWordType, setSearchWordType] = useState(AffiliateCodeSearchWordType.CODE);
  const [searchWord, setSearchWord] = useState("");
  const [availableStartAt, setAvailableStartAt] = useState<Date | undefined>();
  const [availableEndAt, setAvailableEndAt] = useState<Date | undefined>();
  const [paidCountFrom, setPaidCountFrom] = useState("");
  const [paidCountTo, setPaidCountTo] = useState("");
  const [status, setStatus] = useState<AffiliateCodeStatus | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<AffiliateCodeListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      {
        key: "searchWordType",
        value: searchWordType,
        setter: setSearchWordType,
        defaultValue: AffiliateCodeSearchWordType.CODE,
        type: FilterType.OTHER,
      },
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "availableStartAt",
        value: availableStartAt,
        setter: setAvailableStartAt,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "availableEndAt",
        value: availableEndAt,
        setter: setAvailableEndAt,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "paidCountFrom",
        value: paidCountFrom,
        setter: setPaidCountFrom,
        defaultValue: "",
        type: FilterType.STRING,
      },
      { key: "paidCountTo", value: paidCountTo, setter: setPaidCountTo, defaultValue: "", type: FilterType.STRING },
      { key: "status", value: status, setter: setStatus, defaultValue: "", type: FilterType.OTHER },
    ],
    [searchWordType, searchWord, availableStartAt, availableEndAt, paidCountFrom, paidCountTo, status]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType: listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<AffiliateCodeSearchWordType>,
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        availableStartAtFilterValue: getFilterValueByKey("availableStartAt") as FilterValue<Date | undefined>,
        availableEndAtFilterValue: getFilterValueByKey("availableEndAt") as FilterValue<Date | undefined>,
        paidCountFromFilterValue: getFilterValueByKey("paidCountFrom") as FilterValue<string>,
        paidCountToFilterValue: getFilterValueByKey("paidCountTo") as FilterValue<string>,
        statusFilterValue: getFilterValueByKey("status") as FilterValue<AffiliateCodeStatus | "">,
      } as AffiliateCodeFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: AffiliateCodeListQueryType) => {
      const param: AffiliateCodeListQueryType = {
        searchWordType: listQueryTypeParam?.searchWordType,
        searchWord: listQueryTypeParam?.searchWord,
        availableStartAt: listQueryTypeParam?.availableStartAt,
        availableEndAt: listQueryTypeParam?.availableEndAt,
        paidCountFrom: listQueryTypeParam?.paidCountFrom,
        paidCountTo: listQueryTypeParam?.paidCountTo,
        status: listQueryTypeParam?.status,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
