import { useCallback } from "react";
import { OrderStatus } from "../../../types/orders";

export function useOrderStatus() {
  const isBeforePaid = useCallback((status: OrderStatus) => {
    switch (status) {
      case OrderStatus.PARTIALLY_CANCELLED:
        return false;
      case OrderStatus.CANCELLED:
        return false;
      case OrderStatus.PAID:
        return false;
      default:
        return true;
    }
  }, []);

  return {
    isBeforePaid,
  };
}
