import React from "react";
import { ModalType } from "../types/common";
import { TermsCategory } from "../types/terms";

export const ModalContext = React.createContext({
  showModal: ModalType.NONE,
  setShowModal: (_: ModalType) => {},
  closeModal: () => {},
  openTermsModal: (_: TermsCategory) => {},
});
