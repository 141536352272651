import { useListFilterValues, useTimeConverter } from "../../../../hooks/hooks";
import { FilterContentType, TableFilter } from "../../../../types/tableFilter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AffiliateCodeSearchWordType, AffiliateCodeStatus } from "../../../../types/affiliateCode";
import { useAffiliateCodeType } from "../../../../types/hooks/useAffiliateCodeType";

interface Args {
  search: () => void;
}
export function useAffiliateCodeFilters({ search }: Args) {
  const { t } = useTranslation(["filter", "common"]);
  const { filterValues } = useListFilterValues();
  const { timeConverter } = useTimeConverter();
  const { getAffiliateCodeStatusLabel, getAffiliateCodeSearchWordTypeLabel } = useAffiliateCodeType();

  const searchWordTypeFilter = useMemo(
    () => filterValues.searchWordTypeFilterValue,
    [filterValues.searchWordTypeFilterValue]
  );
  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const availableStartAtFilter = useMemo(
    () => filterValues.availableStartAtFilterValue,
    [filterValues.availableStartAtFilterValue]
  );
  const availableEndAtFilter = useMemo(
    () => filterValues.availableEndAtFilterValue,
    [filterValues.availableEndAtFilterValue]
  );
  const paidCountFromFilter = useMemo(
    () => filterValues.paidCountFromFilterValue,
    [filterValues.paidCountFromFilterValue]
  );
  const paidCountToFilter = useMemo(() => filterValues.paidCountToFilterValue, [filterValues.paidCountToFilterValue]);
  const statusFilter = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: "검색어",
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter?.value,
          maxLength: 100,
          placeholderVal: t("searchWord.placeholder"),
          onChangeFunc: (v) => searchWordFilter.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: searchWordTypeFilter?.value,
            options: [
              {
                value: AffiliateCodeSearchWordType.CODE,
                label: getAffiliateCodeSearchWordTypeLabel(AffiliateCodeSearchWordType.CODE),
              },
              {
                value: AffiliateCodeSearchWordType.NAME,
                label: getAffiliateCodeSearchWordTypeLabel(AffiliateCodeSearchWordType.NAME),
              },
            ],
            onChangeFunc: (v) => searchWordTypeFilter?.setter(v as AffiliateCodeSearchWordType),
          },
        },
      },
    ],
    [
      {
        labelTitle: "사용기한",
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(availableStartAtFilter?.value),
            onChangeFunc: (v) =>
              availableStartAtFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder"),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(availableEndAtFilter?.value),
            onChangeFunc: (v) => availableEndAtFilter?.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder"),
          },
        },
      },
      {
        labelTitle: "결제건수",
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: paidCountFromFilter?.value,
            min: 0,
            max: 100,
            onChangeFunc: (v) => paidCountFromFilter?.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: paidCountToFilter?.value,
            min: 0,
            max: 100,
            onChangeFunc: (v) => paidCountToFilter?.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
        },
      },
      {
        labelTitle: "상태",
        content: {
          type: FilterContentType.SELECT,
          value: statusFilter.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getAffiliateCodeStatusLabel(AffiliateCodeStatus.AVAILABLE),
              value: AffiliateCodeStatus.AVAILABLE.toString(),
            },
            {
              label: getAffiliateCodeStatusLabel(AffiliateCodeStatus.UNAVAILABLE),
              value: AffiliateCodeStatus.UNAVAILABLE.toString(),
            },
          ],
          onChangeFunc: (v) => statusFilter.setter(v as unknown as AffiliateCodeStatus),
        },
      },
    ],
  ];

  return {
    filters,
  };
}
