import style from "../../style/grantModal.module.scss";
import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../layout/content/detail/Panel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { DisplayPosition, GrantableProductListObj, ProductStatus } from "../../../types/product";
import { useGrantableProductColumns } from "../hooks/useGrantableProductColumns";
import { PageResponse } from "../../../types/page";
import { useAppDispatch, useAppSelector, useLanguage, useNewWindow } from "../../../hooks/hooks";
import { productStatus } from "../../../store/productSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { callAsync } from "../../../util/sliceUtil";
import { grantVoucherAsync, voucherStatus } from "../../../store/voucherSlice";
import { isLoadingStatus } from "../../../util/etcUtil";
import { CustomerType, Status } from "../../../types/common";
import { WindowMessage } from "../../../types/newWindow";
import { Textarea } from "../../../components/Textarea";
import { useUserGetter } from "../../member/user/hooks/useUserGetter";
import { UserDTO } from "../../../types/user";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { Label104x46 } from "../../../components/Label";
import { BodyScollTable } from "../../../layout/content/list/table/BodyScrollTable";
import { useConditionalProductList } from "../hooks/useConditionalProductList";

export function GrantProductModal() {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const { isKorean } = useLanguage();

  const prdStatus = useAppSelector(productStatus);
  const vchStatus = useAppSelector(voucherStatus);
  useLoader({ status: isLoadingStatus([prdStatus, vchStatus]) ? Status.LOADING : Status.IDLE });
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { defaultErrorMessage } = useUtil();

  const [searchWord, setSearchWord] = useState("");
  const [reason, setReason] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<GrantableProductListObj[]>([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { targetIdxes, targetName, targetType } = queryString.parse(window.location.search);

  const columns = useGrantableProductColumns({
    selectedProducts,
    setSelectedProducts,
  });

  const idxes = useMemo(() => {
    if (typeof targetIdxes === "string") {
      return [Number(targetIdxes)];
    }

    return targetIdxes?.map((idx) => Number(idx)) ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customerType = useMemo(() => {
    if (!targetType) return CustomerType.USER;
    return targetType as CustomerType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onKeyUpSearchInput = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onClickSearchBtn();
    }
  };

  const { getGrantableProductList, grantableProductList } = useConditionalProductList({
    searchWord,
    displayPosition: DisplayPosition.GRANTABLE_PRODUCT,
    status: ProductStatus.IS_SELLING,
  });

  const grantableSellingProductList: PageResponse<GrantableProductListObj> | undefined = useMemo(() => {
    if (grantableProductList) {
      return {
        ...grantableProductList,
        content: grantableProductList?.content.filter((gp) => gp.status === ProductStatus.IS_SELLING) ?? [],
      };
    } else {
      return undefined;
    }
  }, [grantableProductList]);

  const { getUserPromise } = useUserGetter();

  const [grantTo, setGrantTo] = useState<JSX.Element>(<></>);
  const [grantToString, setGrantToString] = useState<string>("");
  const MemberCount = useCallback(() => <>{idxes.length}</>, [idxes]);

  useEffect(() => {
    if (idxes.length > 1) {
      setGrantTo(<Trans t={t} i18nKey={"grantProduct.selectedMember.value"} components={[<MemberCount />]} />);
      setGrantToString(t("grantProduct.selectedMember.valueString", { count: idxes.length }));
    } else {
      const thisIdx = idxes[0];
      if (!targetName && targetType === "USER" && thisIdx) {
        getUserPromise(thisIdx).then(({ name }: UserDTO) => {
          setGrantTo(<>{name}</>);
          setGrantToString(name);
        });
      } else {
        const name = (targetName as string | null) ?? "";
        setGrantTo(<>{name}</>);
        setGrantToString(name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, idxes, MemberCount, getUserPromise]);

  const onClickSearchBtn = () => {
    setIsFirstLoad(false);
    getGrantableProductList({})();
  };

  const onClickGrantBtn = () => {
    if (!idxes || idxes.length === 0) {
      return;
    }

    if (selectedProducts.length === 0) {
      alert(t("grantProduct.validations.noProduct"));
      return;
    }

    if (!reason) {
      alert(t("grantProduct.validations.noReason"));
      return;
    }

    const isConfirmed = window.confirm(t("grantProduct.validations.confirm", { grantTo: grantToString }));
    if (isConfirmed) {
      callAsync(
        dispatch(
          grantVoucherAsync({
            targetIdxes: idxes,
            targetType: customerType,
            grantProductVouchersRequest: selectedProducts.map((product) => ({
              productId: product.idx,
              quantity: product.qty,
            })),
            grantReason: reason,
          })
        ).unwrap(),
        () => {
          alert(t("grantProduct.validations.result"));
          closeWindowAndNotifyToParentWindow(WindowMessage.GRANT_PRODUCT);
        },
        () => alert(defaultErrorMessage)
      ).then();
    }
  };

  useEffect(() => {
    getGrantableProductList({}, ProductStatus.IS_SELLING)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GrantToComponent = useCallback(
    () => (
      <>
        <span className={style.name}>{grantTo}</span>
        {idxes.length > 1 ? "" : t("grantProduct.sir")}
      </>
    ),
    [grantTo, t, idxes.length]
  );

  return (
    <PanelWrapper>
      <Panel title={t("grantProduct.title")} panelClassName={style.panel}>
        <>
          <p className={style.desc}>
            <span className={style.otherDesc}>
              <Trans t={t} i18nKey={"grantProduct.description"} components={[<GrantToComponent />]} />
            </span>
          </p>
          <div className={style.searchArea}>
            <div className={style.search}>
              <Label104x46
                text={t("grantProduct.search.label")}
                className={`${style.grantProductModalLabel} ${isKorean ? "" : style.notKO}`}
              ></Label104x46>
              <div className={style.textInput}>
                <input
                  type={"text"}
                  placeholder={t("grantProduct.search.placeholder")}
                  value={searchWord}
                  onKeyUp={onKeyUpSearchInput}
                  onChange={(e) => setSearchWord(e.target.value)}
                  onBlur={(e) => setSearchWord(e.target.value.trim())}
                />
                <button onClick={onClickSearchBtn}>{t("grantProduct.search.button")}</button>
              </div>
            </div>
          </div>
          <div className={style.productResults}>
            {grantableSellingProductList ? (
              <>
                <BodyScollTable
                  listData={grantableSellingProductList}
                  columns={columns}
                  getList={getGrantableProductList}
                  noDataText={
                    isFirstLoad ? t("grantProduct.search.noGrantableProduct") : t("grantProduct.search.noSearchData")
                  }
                  tableClassName={style.grantProductModalTable}
                  tbodyClassName={style.tableBody}
                  noResultRowClassName={style.noResultRow}
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className={style.reasonArea}>
            <label className={style.label}>{t("grantProduct.reason.label")}</label>
            <Textarea
              className={style.textarea}
              placeholder={t("grantProduct.reason.placeholder")}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
          <div className={style.buttonArea}>
            <button onClick={onClickGrantBtn}>{t("grantProduct.button")}</button>
          </div>
        </>
      </Panel>
    </PanelWrapper>
  );
}
