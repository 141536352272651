import {
  KoreanAddress1InputStyle,
  KoreanAddress2InputStyle,
  KoreanAddressInputWrapperStyle,
} from "../../../components/styled/AddressInputStyle";
import { ChangeAddressButton } from "../../member/user/components/UserSelfInfoDataStyle";
import React, { useMemo } from "react";
import { Address, toAddressLine1 } from "../../../types/user";
import { LightGrayTextStyle } from "../../../components/Texts";

interface Props {
  address: Address;
  setAddress: (_: Address) => void;
}

export function PartnerKoreanAddress({ address, setAddress }: Props) {
  const address1: string = useMemo(() => toAddressLine1(address).trim(), [address]);

  return (
    <KoreanAddressInputWrapperStyle>
      <KoreanAddress1InputStyle>
        {address1 ? <span>{address1}</span> : <LightGrayTextStyle>기본주소 주소찾기로 입력</LightGrayTextStyle>}
        <ChangeAddressButton onComplete={(address) => setAddress(address)}>주소찾기</ChangeAddressButton>
      </KoreanAddress1InputStyle>
      <KoreanAddress2InputStyle
        placeholder={"상세주소 2~60자리 입력"}
        value={address.address2}
        minLength={2}
        maxLength={60}
        onChange={(e) =>
          setAddress({
            ...address,
            address2: e.target.value,
          })
        }
      />
    </KoreanAddressInputWrapperStyle>
  );
}
