import style from "../../../layout/content/post/creator.module.scss";
import React from "react";

interface Props {
  homepage: string;
  setHomepage: (_: string) => void;
}
export function PartnerHomepageField({ homepage, setHomepage }: Props) {
  return (
    <input
      className={style.inputField}
      type={"url"}
      value={homepage}
      required={false}
      maxLength={255}
      onChange={(e) => setHomepage(e.target.value)}
      placeholder={"협력사 홈페이지 링크(url) 입력"}
    />
  );
}
