import { useAppDispatch } from "../../../hooks/hooks";
import { useMemo, useState } from "react";
import { DEFAULT_SIZE_IN_SUB_TABLE, ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import {
  DisplayPosition,
  GrantableProductListObj,
  ProductListObj,
  ProductSearchCondition,
  ProductStatus,
} from "../../../types/product";
import { callAsync } from "../../../util/sliceUtil";
import { getProductListAsync } from "../../../store/productSlice";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Args {
  searchWord?: string;
  displayPosition?: DisplayPosition | "";
  status?: ProductStatus | "";
  isCouponUsable?: boolean;
  onCompletedCallback?: () => void;
}
export function useConditionalProductList({
  searchWord,
  displayPosition,
  status,
  isCouponUsable,
  onCompletedCallback,
}: Args) {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const [productList, setProductList] = useState<PageResponse<ProductListObj>>();
  const [grantableProductList, setGrantableProductList] = useState<PageResponse<GrantableProductListObj>>();
  const pageCondition = useMemo(
    () => ({
      page: productList?.number ?? 0,
      size: productList?.size ?? 20,
      sort: ordersToSortArr(productList?.orders),
    }),
    [productList]
  );

  const getProductList = ({ page, sort, size }: PageSearchCondition) => {
    const param: ProductSearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      },
      q: searchWord ? searchWord.trim() : undefined,
      displayPosition: displayPosition === "" ? undefined : displayPosition,
      status: status === "" ? undefined : status,
      isCouponUsable: isCouponUsable === true ? true : undefined,
    };

    return () => {
      callAsync<PageResponse<ProductListObj>>(
        dispatch(getProductListAsync(param)).unwrap(),
        (result) => {
          setProductList(result);
          onCompletedCallback?.();
        },
        () => alert(`${t("list.error")} : ${defaultErrorMessage}`)
      ).then();
    };
  };

  const getGrantableProductList = ({ sort }: PageSearchCondition, status?: ProductStatus) => {
    const param: ProductSearchCondition = {
      pageCondition: {
        page: 0,
        size: DEFAULT_SIZE_IN_SUB_TABLE,
        sort: sort ?? ordersToSortArr(grantableProductList?.orders),
      },
      q: searchWord ? searchWord.trim() : undefined,
      displayPosition: DisplayPosition.GRANTABLE_PRODUCT,
      status,
      sellingStartDate: undefined,
      sellingEndDate: undefined,
      isCouponUsable: undefined,
      createdAtFrom: undefined,
      createdAtTo: undefined,
    };

    return () => {
      callAsync(
        dispatch(getProductListAsync(param)).unwrap(),
        (result: PageResponse<ProductListObj>) => {
          setGrantableProductList({
            ...result,
            content: result.content.map((product) => ({
              idx: product.idx,
              name: product.name,
              createAt: product.createAt,
              status: product.status,
              productItemCount: product.productItemCount,
              qty: 1,
            })),
          });
        },
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  return {
    getProductList,
    getGrantableProductList,
    productList,
    grantableProductList,
    setGrantableProductList,
    pageCondition,
  };
}
