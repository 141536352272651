import { H1, Main, Nav, NavList, NavListItem, TopButton } from "./DocComponents";
import { ArticleButtons } from "./components/article/ArticleButtons";
import { ArticleInputs } from "./components/article/ArticleInputs";
import { ArticleAnchors } from "./components/article/ArticleAnchors";
import { ArticleListFilter } from "./components/article/ArticleListFilter";
import { ArticleSelector } from "./components/article/ArticleSelector";
import { Fragment, useEffect } from "react";
import { ArticlePanel } from "./components/article/ArticlePanel";
import { ArticleTexts } from "./components/article/ArticleTexts";
import { ArticleDescriptionList } from "./components/article/ArticleDescriptionList";
import { ArticleRoleChecker } from "./components/article/ArticleRoleChecker";
import { ArticleTable } from "./components/article/ArticleTable";
import { ArticlePagination } from "./components/article/ArticlePagination";
import { ArticleListTable } from "./components/article/ArticleListTable";
import { ArticleContentDetail } from "./components/article/ArticleContentDetail";
import { ArticleContentDetailSubmenus } from "./components/article/ArticleContentDetailSubmenus";
import { ArticleMiniModal } from "./components/article/ArticleMiniModal";
import { ArticleContentDetailSubmenuCategories } from "./components/article/ArticleContentDetailSubmenuCategories";
import { ArticleTextarea } from "./components/article/ArticleTextarea";
import { ArticleLabel } from "./components/article/ArticleLabel";
import { ArticleRadioGroup } from "./components/article/ArticleRadioGroup";
import { ArticleStepper } from "./components/article/ArticleStepper";
import { useNavigate } from "react-router-dom";

interface GuideData {
  id: string;
  title: string;
  getArticleComponent: () => JSX.Element;
}

export function ComponentGuide() {
  const navigate = useNavigate();

  const data: GuideData[] = [
    {
      id: "buttons",
      title: "Buttons",
      getArticleComponent() {
        return <ArticleButtons id={this.id} title={this.title} />;
      },
    },
    {
      id: "inputs",
      title: "Inputs",
      getArticleComponent() {
        return <ArticleInputs id={this.id} title={this.title} />;
      },
    },
    {
      id: "textarea",
      title: "Textarea",
      getArticleComponent() {
        return <ArticleTextarea id={this.id} title={this.title} />;
      },
    },
    {
      id: "label",
      title: "Label",
      getArticleComponent() {
        return <ArticleLabel id={this.id} title={this.title} />;
      },
    },
    {
      id: "anchor",
      title: "Anchor",
      getArticleComponent() {
        return <ArticleAnchors id={this.id} title={this.title} />;
      },
    },
    {
      id: "radioGroup",
      title: "Radio Group",
      getArticleComponent() {
        return <ArticleRadioGroup id={this.id} title={this.title} />;
      },
    },
    {
      id: "texts",
      title: "Texts",
      getArticleComponent() {
        return <ArticleTexts id={this.id} title={this.title} />;
      },
    },
    {
      id: "selectors",
      title: "Selectors",
      getArticleComponent() {
        return <ArticleSelector id={this.id} title={this.title} />;
      },
    },
    {
      id: "stepper",
      title: "Stepper",
      getArticleComponent() {
        return <ArticleStepper id={this.id} title={this.title} />;
      },
    },
    {
      id: "panel",
      title: "Panel",
      getArticleComponent() {
        return <ArticlePanel id={this.id} title={this.title} />;
      },
    },
    {
      id: "descriptionList",
      title: "Description List",
      getArticleComponent() {
        return <ArticleDescriptionList id={this.id} title={this.title} />;
      },
    },
    {
      id: "roleChecker",
      title: "Role Checker",
      getArticleComponent() {
        return <ArticleRoleChecker id={this.id} title={this.title} />;
      },
    },
    {
      id: "miniModal",
      title: "Mini Modal",
      getArticleComponent() {
        return <ArticleMiniModal id={this.id} title={this.title} />;
      },
    },
    {
      id: "listFilter",
      title: "List Filter",
      getArticleComponent() {
        return <ArticleListFilter id={this.id} title={this.title} />;
      },
    },
    {
      id: "table",
      title: "Table",
      getArticleComponent() {
        return <ArticleTable id={this.id} title={this.title} />;
      },
    },
    {
      id: "pagination",
      title: "Pagination",
      getArticleComponent() {
        return <ArticlePagination id={this.id} title={this.title} />;
      },
    },
    {
      id: "listTable",
      title: "List Table",
      getArticleComponent() {
        return <ArticleListTable id={this.id} title={this.title} />;
      },
    },
    {
      id: "contentDetail",
      title: "Content Detail",
      getArticleComponent() {
        return <ArticleContentDetail id={this.id} title={this.title} />;
      },
    },
    {
      id: "contentDetailSubmenus",
      title: "Content Detail Submenus",
      getArticleComponent() {
        return <ArticleContentDetailSubmenus id={this.id} title={this.title} />;
      },
    },
    {
      id: "detailSubmenuCategories",
      title: "Content Detail Submenu Categories",
      getArticleComponent() {
        return <ArticleContentDetailSubmenuCategories id={this.id} title={this.title} />;
      },
    },
  ];

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Main>
      <TopButton onClick={() => window.scrollTo(0, 0)}>top</TopButton>
      <H1>Component Guide</H1>
      <Nav>
        <NavList>
          {data.map((item, idx) => (
            <NavListItem key={`nav-item-${idx}`}>
              <a href={`#${item.id}`}>{item.title}</a>
            </NavListItem>
          ))}
        </NavList>
      </Nav>
      {data.map((item, idx) => (
        <Fragment key={`data-item-${idx}`}>{item.getArticleComponent()}</Fragment>
      ))}
    </Main>
  );
}
