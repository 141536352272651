import { UnitItemType } from "../../../types/unitItem";
import { useCallback, useMemo, useState } from "react";
import { ListQueryType } from "../../../types/list";
import { FilterType, FilterValue } from "../../../types/filter";
import { PageSearchCondition } from "../../../types/page";
import { useFilterValues } from "../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../hooks/list/useListSearchParams";

export interface UnitItemListQueryType extends ListQueryType {
  searchWord?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  unitItemType?: string;
  productCountFrom?: string;
  productCountTo?: string;
}

export interface UnitItemListFilterValues {
  searchWordFilterValue: FilterValue<string>;
  createdAtFromFilterValue: FilterValue<Date | undefined>;
  createdAtToFilterValue: FilterValue<Date | undefined>;
  unitItemTypeFilterValue: FilterValue<UnitItemType | "">;
  productCountFromFilterValue: FilterValue<string>;
  productCountToFilterValue: FilterValue<string>;
}

export function useUnitItemFilterValues() {
  const [searchWord, setSearchWord] = useState("");
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();
  const [unitItemType, setUnitItemType] = useState<UnitItemType | "">("");
  const [productCountFrom, setProductCountFrom] = useState("");
  const [productCountTo, setProductCountTo] = useState("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<UnitItemListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      { key: "unitItemType", value: unitItemType, setter: setUnitItemType, defaultValue: "", type: FilterType.OTHER },
      {
        key: "productCountFrom",
        value: productCountFrom,
        setter: setProductCountFrom,
        defaultValue: "",
        type: FilterType.STRING,
      },
      {
        key: "productCountTo",
        value: productCountTo,
        setter: setProductCountTo,
        defaultValue: "",
        type: FilterType.STRING,
      },
    ],
    [searchWord, createdAtFrom, createdAtTo, unitItemType, productCountFrom, productCountTo]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
        createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
        unitItemTypeFilterValue: getFilterValueByKey("unitItemType") as FilterValue<UnitItemType | "">,
        productCountFromFilterValue: getFilterValueByKey("productCountFrom") as FilterValue<string>,
        productCountToFilterValue: getFilterValueByKey("productCountTo") as FilterValue<string>,
      } as UnitItemListFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: UnitItemListQueryType) => {
      const param: UnitItemListQueryType = {
        searchWord: listQueryTypeParam?.searchWord,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
        unitItemType: listQueryTypeParam?.unitItemType,
        productCountFrom: listQueryTypeParam?.productCountFrom,
        productCountTo: listQueryTypeParam?.productCountTo,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
