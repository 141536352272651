import React, { useCallback } from "react";
import { AdminStatus } from "../../../../types/admin";
import { ActionButton, ActiveActionButton, DetailActionButton } from "../../../../components/Buttons";
import { useAdminStatus } from "./useAdminStatus";
import { callAsync } from "../../../../util/sliceUtil";
import { authorizeAdminAsync, unblockAdminAsync } from "../../../../store/adminSlice";
import { useAppDispatch, useNewWindow } from "../../../../hooks/hooks";
import { RoleChecker } from "../../../role/RoleChecker";
import { AdminMenuCode } from "../../../../types/adminRole";
import checkIcon from "../../../../assets/images/button_icons/check_icon.svg";
import style from "../../user/memberUserDetail.module.scss";
import deleteIcon from "../../../../assets/images/button_icons/delete_icon.svg";
import leaveIcon from "../../../../assets/images/button_icons/leave_icon.svg";
import { ActionUserType } from "../../../../types/dataCommon";
import { useMyAccountDeleter } from "../../../account/mypage/hooks/useMyAccountDeleter";
import { useAdminEmailResender } from "./useAdminEmailResender";
import { useAdminDeleter } from "./useAdminDeleter";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Args {
  onRefresh: () => void;
}

interface AdminIdxAndStatusProps {
  adminIdx: number;
  adminStatus: AdminStatus;
}

interface AdminIdxAndRoleAndStatusProps {
  adminIdx: number;
  adminRoleIdx: number;
  adminStatus: AdminStatus;
}
export function useAdminButtons({ onRefresh }: Args) {
  const { t } = useTranslation("admin");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { openAdminRolePopup, openBlockMemberPopup } = useNewWindow();
  const { isSelfAuthentication, isNoAuthentication, isChangeableAuthentication, isBlocked, isBeforeSignUp, isLeaved } =
    useAdminStatus();
  const { deleteMyAccount } = useMyAccountDeleter();
  const { resendConfirmEmail } = useAdminEmailResender();
  const { deleteAdmin } = useAdminDeleter({ onRefresh });

  const DeleteButton = useCallback(
    ({ adminStatus, adminIdx }: AdminIdxAndStatusProps): JSX.Element => {
      if (isBeforeSignUp(adminStatus)) {
        return <ActionButton onClick={() => deleteAdmin(adminIdx)}>{t("buttons.delete.label")}</ActionButton>;
      } else {
        return <></>;
      }
    },
    [deleteAdmin, isBeforeSignUp, t]
  );

  const ResendButton = useCallback(
    ({ adminStatus, adminIdx }: AdminIdxAndStatusProps): JSX.Element => {
      if (isNoAuthentication(adminStatus)) {
        return <ActionButton onClick={() => resendConfirmEmail(adminIdx)}>{t("buttons.resend.label")}</ActionButton>;
      } else {
        return <></>;
      }
    },
    [isNoAuthentication, resendConfirmEmail, t]
  );

  const onClickUnlockButton = useCallback(
    (idx: number) => {
      if (!window.confirm(t("buttons.unblock.confirmMessage"))) return;

      callAsync(
        dispatch(unblockAdminAsync(idx)).unwrap(),
        () => onRefresh(),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, onRefresh, t, defaultErrorMessage]
  );

  const UnblockButton = useCallback(
    ({ adminStatus, adminIdx }: AdminIdxAndStatusProps): JSX.Element => {
      if (isBlocked(adminStatus)) {
        return (
          <ActiveActionButton onClick={() => onClickUnlockButton(adminIdx)}>
            {t("buttons.unblock.label")}
          </ActiveActionButton>
        );
      } else {
        return <></>;
      }
    },
    [t, isBlocked, onClickUnlockButton]
  );

  const UnblockButtonOnDetail = useCallback(
    ({ adminIdx, adminStatus }: AdminIdxAndStatusProps): JSX.Element => {
      if (isBlocked(adminStatus)) {
        return (
          <DetailActionButton onClick={() => onClickUnlockButton(adminIdx)}>
            <img src={checkIcon} alt={t("buttons.unblock.checkIcon")} />
            {t("buttons.unblock.label")}
          </DetailActionButton>
        );
      } else {
        return <></>;
      }
    },
    [isBlocked, onClickUnlockButton, t]
  );

  const onClickAuthorizeButton = useCallback(
    (idx: number) => {
      if (!window.confirm(t("buttons.authorize.confirmMessage"))) return;

      callAsync(
        dispatch(authorizeAdminAsync(idx)).unwrap(),
        () => {
          onRefresh();
          alert(t("buttons.authorize.resultMessage"));
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, onRefresh, t, defaultErrorMessage]
  );

  const AuthorizeButton = useCallback(
    ({ adminStatus, adminIdx }: AdminIdxAndStatusProps): JSX.Element => {
      if (isSelfAuthentication(adminStatus)) {
        return (
          <ActiveActionButton onClick={() => onClickAuthorizeButton(adminIdx)}>
            {t("buttons.authorize.label")}
          </ActiveActionButton>
        );
      } else {
        return <></>;
      }
    },
    [isSelfAuthentication, onClickAuthorizeButton, t]
  );

  const onClickChangeRoleButton = useCallback(
    ({ adminIdx, adminRoleIdx }: { adminIdx: number; adminRoleIdx: number }) => {
      openAdminRolePopup(
        {
          adminIdx,
          roleIdx: adminRoleIdx,
        },
        () => onRefresh()
      );
    },
    [onRefresh, openAdminRolePopup]
  );

  const RoleSettingButton = useCallback(
    ({ adminIdx, adminStatus, adminRoleIdx }: AdminIdxAndRoleAndStatusProps): JSX.Element => {
      if (isChangeableAuthentication(adminStatus) || isBlocked(adminStatus)) {
        return (
          <RoleChecker menuCode={AdminMenuCode.ADMIN_ROLE}>
            <ActionButton onClick={() => onClickChangeRoleButton({ adminIdx, adminRoleIdx })}>
              {t("buttons.roleSetting.label")}
            </ActionButton>
          </RoleChecker>
        );
      } else {
        return <></>;
      }
    },
    [isChangeableAuthentication, isBlocked, onClickChangeRoleButton, t]
  );

  const onClickBlockMemberButton = useCallback(
    (idx: number) => {
      openBlockMemberPopup(
        {
          userType: ActionUserType.ADMIN,
          memberIdxArr: [idx],
        },
        () => onRefresh()
      );
    },
    [openBlockMemberPopup, onRefresh]
  );

  const BlockButtonOnDetail = useCallback(
    ({ admin, isCurrentAdminIsMe }: { admin: AdminIdxAndStatusProps; isCurrentAdminIsMe: boolean }) => {
      if (!isCurrentAdminIsMe && !isBlocked(admin.adminStatus) && !isLeaved(admin.adminStatus)) {
        return (
          <button className={style.blockBtn} onClick={() => onClickBlockMemberButton(admin.adminIdx)}>
            <img src={deleteIcon} alt={t("buttons.block.blockIcon")} />
            {t("buttons.block.label")}
          </button>
        );
      } else {
        return <></>;
      }
    },
    [isBlocked, isLeaved, onClickBlockMemberButton, t]
  );

  const LeaveButtonOnDetail = useCallback(
    ({ adminStatus, isCurrentAdminIsMe }: { adminStatus: AdminStatus; isCurrentAdminIsMe: boolean }) => {
      if (isCurrentAdminIsMe && !isBlocked(adminStatus)) {
        return (
          <DetailActionButton onClick={() => deleteMyAccount()}>
            <img src={leaveIcon} alt={t("buttons.leave.leaveIcon")} />
            {t("buttons.leave.label")}
          </DetailActionButton>
        );
      } else {
        return <></>;
      }
    },
    [t, isBlocked, deleteMyAccount]
  );

  return {
    DeleteButton,
    ResendButton,
    UnblockButton,
    AuthorizeButton,
    RoleSettingButton,
    UnblockButtonOnDetail,
    BlockButtonOnDetail,
    LeaveButtonOnDetail,
  };
}
