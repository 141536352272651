import listStyle from "../list.module.scss";
import React from "react";
import { RoleChecker } from "../../../../features/role/RoleChecker";
import { ListObject, TableColumnData } from "../../../../types/common";

interface Props<T extends ListObject> {
  columns: TableColumnData<T>[];
  rowData: T;
  dataIdx: number;
}
export function TableTds<T extends ListObject>({ columns, rowData, dataIdx }: Props<T>) {
  return (
    <>
      {columns.map((c, colIdx) => {
        const element = (
          <td
            className={`${listStyle.td} ${c.className ?? ""} ${c.isLeftAlign ? listStyle.leftAlign : ""} ${
              c.isRightAlign ? listStyle.rightAlign : ""
            }`}
            style={{ width: c.width ?? "", padding: `${c.padding}`?? ""  }}
          >
            {c.getNode ? c.getNode(rowData, dataIdx) : <>{rowData[c.accessor]}</>}
          </td>
        );

        return (
          <React.Fragment key={`tr-${dataIdx}-td-${colIdx}}`}>
            {c.menuCode ? (
              <RoleChecker menuCode={c.menuCode}>{element}</RoleChecker>
            ) : c.hideThisColumn ? (
              <></>
            ) : (
              element
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
