import { Status } from "../../types/common";
import { useContext, useEffect } from "react";
import { CommonContext } from "./CommonContext";
import { isLoadingStatus } from "../../util/etcUtil";

interface Args {
  status: Status | Status[];
}

export function useLoader({ status }: Args) {
  const { setLoaderStatus } = useContext(CommonContext);
  useEffect(() => {
    if (Array.isArray(status)) {
      setLoaderStatus(isLoadingStatus(status) ? Status.LOADING : Status.IDLE);
    } else {
      setLoaderStatus(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
}
