import {useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SORT,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../types/page";
import {PopupListVO, PopupSearchCondition, PopupStatus, PopupType} from "../../../../types/popup";
import {useSortParam} from "../../../../hooks/list/useSortParam";
import {useUtil} from "../../../../util/hooks/useUtil";
import {usePopupsGetter} from "./usePopupsGetter";
import {PopupListQueryType} from "./usePopupFilterValues";
import {dateToString} from "../../../../util/dateUtil";
import {Languages, WebsiteType} from "../../../../types/common";

export function usePopupList() {
  const { timeConverter } = useTimeConverter();
  const [popupList, setPopupList] = useState<PageResponse<PopupListVO>>();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { toSortArray } = useSortParam();
  const { defaultErrorMessage } = useUtil();
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const { getPopups } = usePopupsGetter();
  const pageCondition = useMemo(
    () => ({
      page: popupList?.number ?? DEFAULT_PAGE,
      size: popupList?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(popupList?.orders),
    }),
    [popupList]
  );

  const searchWordFilterValue = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const availableStartAtFilterValue = useMemo(
    () => filterValues.availableStartAtFilterValue,
    [filterValues.availableStartAtFilterValue]
  );
  const availableEndAtFilterValue = useMemo(
    () => filterValues.availableEndAtFilterValue,
    [filterValues.availableEndAtFilterValue]
  );
  const statusFilterValue = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);
  const typeFilterValue = useMemo(() => filterValues.typeFilterValue, [filterValues.typeFilterValue]);
  const websiteTypeFilterValue = useMemo(() => filterValues.websiteTypeFilterValue, [filterValues.websiteTypeFilterValue]);
  const popupLanguageFilterValue = useMemo(() => filterValues.popupLanguageFilterValue, [filterValues.popupLanguageFilterValue]);

  const searchPopupList = useCallback(
    (pageSearchCondition: PageSearchCondition) => {
      const param: PopupListQueryType = {
        searchWord: searchWordFilterValue.value,
        availableStartAt: availableStartAtFilterValue.value
          ? dateToString(availableStartAtFilterValue.value)
          : undefined,
        availableEndAt: availableEndAtFilterValue.value ? dateToString(availableEndAtFilterValue.value) : undefined,
        type: typeFilterValue.value ? typeFilterValue.value : undefined,
        status: statusFilterValue.value ? statusFilterValue.value : undefined,
        websiteType: websiteTypeFilterValue.value ? websiteTypeFilterValue.value : undefined,
        popupLanguage: popupLanguageFilterValue.value ? popupLanguageFilterValue.value : undefined,
      };

      return () => {
        searchList(pageSearchCondition, param)();
      };
    },
    [
      searchList,
      searchWordFilterValue,
      availableStartAtFilterValue,
      availableEndAtFilterValue,
      typeFilterValue,
      statusFilterValue,
      websiteTypeFilterValue,
      popupLanguageFilterValue
    ]
  );

  const getPopupList = useCallback(
    async (condition: PopupSearchCondition, isFirstLoad?: boolean) => {
      try {
        const result: PageResponse<PopupListVO> = await getPopups(condition);
        if (isFirstLoad) {
          setTotalCount(result.totalElements);
        } else {
          setPopupList(result);
        }
      } catch (e) {
        alert(defaultErrorMessage);
      }
    },
    [defaultErrorMessage, getPopups]
  );

  const getPopupListWithParam = useCallback(
    (isFirstLoad?: boolean) => {
      if (isFirstLoad) {
        getPopupList({}, true).then();
      }

      const popupListQueryType = listQueryType as PopupListQueryType;
      getPopupList({
        pageCondition: {
          page: popupListQueryType.page ? Number(popupListQueryType.page) : DEFAULT_PAGE,
          size: popupListQueryType.size ? Number(popupListQueryType.size) : DEFAULT_SIZE,
          sort: popupListQueryType.sort ? toSortArray(popupListQueryType.sort) : DEFAULT_SORT,
        },
        q: popupListQueryType.searchWord?.trim(),
        availableStartAt: popupListQueryType.availableStartAt
          ? timeConverter.convertToFilterStartOfDay(popupListQueryType.availableStartAt)
          : undefined,
        availableEndAt: popupListQueryType.availableEndAt
          ? timeConverter.convertToFilterEndOfDay(popupListQueryType.availableEndAt)
          : undefined,
        type: popupListQueryType.type ? (popupListQueryType.type as PopupType) : undefined,
        status: popupListQueryType.status ? (popupListQueryType.status as PopupStatus) : undefined,
        websiteType: popupListQueryType.websiteType ? (popupListQueryType.websiteType as WebsiteType) : undefined,
        language: popupListQueryType.popupLanguage ? (popupListQueryType.popupLanguage as Languages) : undefined,
      }).then();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listQueryType]
  );

  useEffect(() => {
    getPopupList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      getPopupListWithParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPopupListWithParam]);

  return {
    popupList,
    pageCondition,
    totalCount,
    getPopupListWithParam,
    searchPopupList,
  };
}
