import { SelectorOption } from "../../../types/selector";
import { useSelector } from "./useSelector";
import {
  FooterBoxSelectorWrapperStyle,
  FooterSelectAreaStyle,
  FooterSelectedItemStyle,
  FooterSelectorArrowStyle,
  FooterSelectorOptionsStyle,
  OptionItem,
} from "../../../components/SelectorStyle";
import { SELECTOR_AREA } from "../../../util/etcUtil";
import arrow from "../../../assets/images/select_arrow.svg";
import React from "react";

interface Props {
  options: SelectorOption[];
  selectedValue: string;
  setSelectorOption: (_: SelectorOption) => void;
}
export function FooterSelector({ options, selectedValue, setSelectorOption }: Props) {
  const { selectorRef, onClickSelector, onClickOption, selectedOption, showOptions } = useSelector({
    options,
    selectedValue,
    setSelectorOption,
  });

  return (
    <FooterBoxSelectorWrapperStyle>
      <FooterSelectAreaStyle data-name={SELECTOR_AREA} onClick={onClickSelector} ref={selectorRef}>
        <FooterSelectedItemStyle>{selectedOption?.label}</FooterSelectedItemStyle>
        <FooterSelectorArrowStyle src={arrow} />
      </FooterSelectAreaStyle>

      <FooterSelectorOptionsStyle className={showOptions ? "" : "hide"}>
        {options.map((option, idx) => (
          <OptionItem
            key={`option-${option.value}-${idx}`}
            data-value={option.value}
            onClick={() => onClickOption(option)}
          >
            {option.label}
          </OptionItem>
        ))}
      </FooterSelectorOptionsStyle>
    </FooterBoxSelectorWrapperStyle>
  );
}
