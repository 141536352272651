import { useAppDispatch } from "../../../../../hooks/hooks";
import { getUserProfileDetailAsync, setSelectedProfileName } from "../../../../../store/userProfileSlice";
import { AdminUserProfileTesterDetail } from "../../../../../types/userProfile";
import { useCallback, useState } from "react";
import { useVoucherGetter } from "../../../../voucher/useVoucherGetter";
import { ProductVoucherDTO, ProductVoucherStatus, VoucherType } from "../../../../../types/voucher";
import { callAsync } from "../../../../../util/sliceUtil";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";

interface ReturnType {
  profile: AdminUserProfileTesterDetail | undefined;
  getTesterDetail: (profileIdx: number) => void;
}

export function useTesterDetailGetter(): ReturnType {
  const { t } = useTranslation("user");
  const dispatch = useAppDispatch();
  const [profile, setProfile] = useState<AdminUserProfileTesterDetail>();
  const { defaultErrorMessage } = useUtil();
  const { getVoucherDetail } = useVoucherGetter();

  const getTesterDetail = useCallback(
    (profileIdx: number) => {
      callAsync(
        dispatch(getUserProfileDetailAsync(profileIdx)).unwrap(),
        (result: AdminUserProfileTesterDetail) => {
          setProfile(result);
          dispatch(setSelectedProfileName(result.name));

          if (result.organVoucher) {
            getVoucherDetail(result.organVoucher.idx).then((voucherDTO: ProductVoucherDTO) => {
              if (voucherDTO && voucherDTO.productVoucherStatus === ProductVoucherStatus.GRANTED_TO_PROFILE) {
                const hasValidVoucher = voucherDTO.unitVouchers.some(
                  (v) => v.voucherType === VoucherType.KPASS && moment.utc(v.availableUntil).isAfter(moment())
                );
                if (!hasValidVoucher) {
                  setProfile({
                    ...result,
                    organVoucher: null,
                  });
                }
              }
            });
          }
        },
        (e) => {
          setProfile(undefined);
          if (e.errorCode.httpCode === 466) {
            alert(t("detail.profile.deletedProfileNotice"));
          } else {
            alert(defaultErrorMessage);
          }
        }
      ).then();
    },
    [defaultErrorMessage, t, dispatch, getVoucherDetail]
  );

  return {
    profile,
    getTesterDetail,
  };
}
