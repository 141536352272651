import React from "react";
import styled from "styled-components";
import variables from "../../../assets/scss/variable.module.scss";

const RemainingSecondsStyle = styled.p`
  margin-top: 10px;
  text-align: center;

  font-size: 16px;
  color: ${variables.colorFGPointRed};
  line-height: 1.6;
  letter-spacing: -0.48px;
`;

interface Props {
  remainSeconds: number;
}

export function RemainingSeconds({ remainSeconds }: Props) {
  return (
    <>
      {remainSeconds > 0 && (
        <RemainingSecondsStyle>
          00:{remainSeconds.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })}
        </RemainingSecondsStyle>
      )}
    </>
  );
}
