import React from "react";

export function useDescriptionList() {
  const fillWithAlternativeChildren = (startColIdx: number, alternativeElement?: JSX.Element) => {
    return alternativeElement ? (
      <dd
        style={{
          gridColumn: `${startColIdx} / ${startColIdx + 2}`,
        }}
      >
        {alternativeElement}
      </dd>
    ) : (
      <></>
    );
  };

  const BlankElement = () => <div style={{ backgroundColor: "white", height: "100%" }}></div>;

  return {
    fillWithAlternativeChildren,
    BlankElement,
  };
}
