import style from "./copiableText.module.scss";
import React, { useCallback } from "react";
import { useUtil } from "../util/hooks/useUtil";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  isBlue: boolean;
}

export function CopiableText({ text, isBlue }: Props) {
  const { t } = useTranslation("common");
  const { defaultErrorMessage } = useUtil();

  const copyToClipboard = useCallback(
    (s?: string) => {
      if (s) {
        navigator.clipboard
          .writeText(s)
          .then(() => {
            alert(t("copyResultMessage"));
          })
          .catch(() => {
            alert(defaultErrorMessage);
          });
      }
    },
    [t, defaultErrorMessage]
  );

  return (
    <span className={style.copiableText}>
      <span className={`${style.text} ${isBlue ? style.blue : ""}`} onClick={() => copyToClipboard(text)}>
        {text}
      </span>
    </span>
  );
}
