import { UserStatus } from "../../../types/user";
import React, { useRef, useState } from "react";
import { LeaveModal } from "../../component/modal/LeaveModal";
import { BlockReasonModal } from "../../component/modal/BlockReasonModal";
import style from "./memberUser.module.scss";
import { MINI_MODAL_LINK } from "../../../util/etcUtil";
import { useUserType } from "../../../types/hooks/useUserType";

interface Props {
  userStatus: UserStatus;
  leavedAt: string;
  blockedAt: string;
  reasonForBlock: string;
  reasonForLeave: string;
}

export function UserStatusText({ userStatus, leavedAt, blockedAt, reasonForBlock, reasonForLeave }: Props) {
  const [show, setShow] = useState(false);
  const linkRef = useRef<HTMLSpanElement | null>(null);
  const { toStringFromUserStatus } = useUserType();

  const isLeaved = (s: UserStatus) => s === UserStatus.LEAVE;
  const isBlocked = (s: UserStatus) => s === UserStatus.BLOCKED;

  const getStatusClassName = (s: UserStatus) => {
    switch (s) {
      case UserStatus.BLOCKED:
        return style.statusBlocked;
      case UserStatus.LEAVE:
        return style.statusLeave;
      default:
        return "";
    }
  };

  return (
    <span
      className={getStatusClassName(userStatus)}
      onClick={(e) => setShow(true)}
      data-name={MINI_MODAL_LINK}
      ref={linkRef}
    >
      {toStringFromUserStatus(userStatus)}
      {isLeaved(userStatus) && (
        <LeaveModal
          isShow={show}
          callbackCloseModal={() => setShow(false)}
          leaveAt={leavedAt}
          linkElement={linkRef?.current ?? undefined}
        />
      )}
      {isBlocked(userStatus) && (
        <BlockReasonModal
          isShow={show}
          callbackCloseModal={() => setShow(false)}
          blockAt={blockedAt}
          reasonForBlocking={reasonForBlock}
          linkElement={linkRef?.current ?? undefined}
        />
      )}
    </span>
  );
}
