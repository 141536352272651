import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { RootState } from "./index";
import { PageResponse } from "../types/page";
import { OrganManagerOrganDTO, OrganManagerOrganSearchCondition } from "../types/organ";
import { OrganApi } from "../api/OrganApi";

export interface OrganState extends SliceState {
  organs?: PageResponse<OrganManagerOrganDTO>;
}

const initialState: OrganState = {
  status: Status.IDLE,
};

export const getOrgansAsync = createAsyncThunk(
  "organ/getOrgans",
  ({ organManagerIdx, condition }: { organManagerIdx: number; condition: OrganManagerOrganSearchCondition }) =>
    executePromise(OrganApi.getOrgans(organManagerIdx, condition))
);

export const getOrganDetailAsync = createAsyncThunk("organ/getOrganDetail", (organIdx: number) =>
  executePromise(OrganApi.getOrganDetail(organIdx))
);

export const deleteOrganAsync = createAsyncThunk("organ/deleteOrgan", (organIdx: number) =>
  executePromise(OrganApi.deleteOrgan(organIdx))
);

export const organSlice = createSlice({
  name: "organ",
  initialState,
  reducers: {
    setOrgans: (state, action: PayloadAction<PageResponse<OrganManagerOrganDTO>>) => {
      state.organs = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getOrgansAsync);
    addCases(builder, getOrganDetailAsync);
  },
});

export default organSlice.reducer;

export const { setOrgans } = organSlice.actions;

export const organs = (state: RootState) => state.organ.organs;
export const organStatus = (state: RootState) => state.organ.status;
