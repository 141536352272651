import { useEffect, useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import { UnitItemListObj, UnitItemSearchCondition, UnitItemType } from "../../../types/unitItem";
import { hasBothValuesOrBothNoValue, isFromNumberMoreThanToNumber } from "../../../util/etcUtil";
import { useAppDispatch, useListFilterValues, useNationality, useTimeConverter } from "../../../hooks/hooks";
import { getUnitItemListAsync } from "../../../store/unitItemSlice";
import { UnitItemListQueryType } from "./useUnitItemFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { useSortParam } from "../../../hooks/list/useSortParam";
import { dateToString } from "../../../util/dateUtil";

interface Args {
  onCompletedCallback?: () => void;
}

export function useUnitItemList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("unitItem");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { toSortArray } = useSortParam();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { nationality } = useNationality();
  const { timeConverter } = useTimeConverter();

  const {
    searchWordFilterValue,
    createdAtFromFilterValue,
    createdAtToFilterValue,
    unitItemTypeFilterValue,
    productCountFromFilterValue,
    productCountToFilterValue,
  } = filterValues;

  const [unitItemList, setUnitItemList] = useState<PageResponse<UnitItemListObj>>();
  const [totalCnt, setTotalCnt] = useState(0);
  const pageCondition = useMemo(
    () => ({
      page: unitItemList?.number ?? 0,
      size: unitItemList?.size ?? 20,
      sort: ordersToSortArr(unitItemList?.orders),
    }),
    [unitItemList]
  );

  const searchUnitItemList = (pageSearchCondition: PageSearchCondition) => {
    const param: UnitItemListQueryType = {
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      createdAtFrom: createdAtFromFilterValue.value ? dateToString(createdAtFromFilterValue.value) : undefined,
      createdAtTo: createdAtToFilterValue.value ? dateToString(createdAtToFilterValue.value) : undefined,
      unitItemType: unitItemTypeFilterValue.value,
      productCountFrom: productCountFromFilterValue.value ? productCountFromFilterValue.value : undefined,
      productCountTo: productCountToFilterValue.value ? productCountToFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.createdAtFrom, param.createdAtTo)) {
        alert(t("list.validations.bothCreatedAtNeeded"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.productCountFrom, param.productCountTo)) {
        alert(t("list.validations.bothProductCountNeeded"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.productCountFrom), Number(param.productCountTo))) {
        alert(t("list.validations.incorrectProductCounts"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getUnitItemList = async (param: UnitItemSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<UnitItemListObj> = await dispatch(getUnitItemListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalCnt(result.totalElements);
      } else {
        setUnitItemList(result);
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  useEffect(() => {
    getUnitItemList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      const unitItemListQueryType = listQueryType as UnitItemListQueryType;
      const param: UnitItemSearchCondition = {
        pageCondition: {
          page: unitItemListQueryType.page ? Number(unitItemListQueryType.page) : 0,
          size: unitItemListQueryType.size ? Number(unitItemListQueryType.size) : 20,
          sort: unitItemListQueryType.sort ? toSortArray(unitItemListQueryType.sort) : undefined,
        },
        q: unitItemListQueryType.searchWord?.trim(),
        type: unitItemListQueryType.unitItemType ? (unitItemListQueryType.unitItemType as UnitItemType) : undefined,
        createdAtFrom: unitItemListQueryType.createdAtFrom
          ? timeConverter.convertToFilterStartOfDay(unitItemListQueryType.createdAtFrom)
          : undefined,
        createdAtTo: unitItemListQueryType.createdAtTo
          ? timeConverter.convertToFilterEndOfDay(unitItemListQueryType.createdAtTo)
          : undefined,
        productCntFrom: unitItemListQueryType.productCountFrom
          ? Number(unitItemListQueryType.productCountFrom)
          : undefined,
        productCntTo: unitItemListQueryType.productCountTo ? Number(unitItemListQueryType.productCountTo) : undefined,
      };

      getUnitItemList(param).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    unitItemList,
    pageCondition,
    totalCnt,
    searchUnitItemList,
  };
}
