import { useCallback } from "react";
import { CookieKeys, useCookie } from "./auth/useCookie";
import {VoucherType} from "../types/voucher";

export function useOpenGuide(testType: VoucherType) {
  const { getValueFromCookie } = useCookie();

  return useCallback(
    (contentExecutedInfoIdx: number) => {
      const token = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
        const voucherType = testType === VoucherType.KPASS ? 'kpass' : 'dcas';

      const baseUrl = process.env.REACT_APP_BASE_URL;
      if (token) {
        window.open(
          `${baseUrl}/api/v1/${voucherType}/admin/test-result/${contentExecutedInfoIdx}/recommend-job?token=${token}`,
          "_blank"
        );
      }
    },
      //eslint-disable-next-line
    [getValueFromCookie]
  );
}
