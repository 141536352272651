import { ListQueryType } from "../../../../types/list";
import { FilterType, FilterValue } from "../../../../types/filter";
import { PopupStatus, PopupType} from "../../../../types/popup";
import { useCallback, useMemo, useState } from "react";
import { PageSearchCondition } from "../../../../types/page";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import {Languages, WebsiteType} from "../../../../types/common";

export interface PopupListQueryType extends ListQueryType {
  searchWord?: string;
  type?: string;
  status?: string;
  availableStartAt?: string;
  availableEndAt?: string;
  websiteType?: string;
  popupLanguage?: string;
}

export interface PopupFilterValues {
  searchWordFilterValue: FilterValue<string>;
  typeFilterValue: FilterValue<PopupType | "">;
  statusFilterValue: FilterValue<PopupStatus | "">;
  websiteTypeFilterValue: FilterValue<WebsiteType | "">;
  popupLanguageFilterValue: FilterValue<Languages | "">;
  availableStartAtFilterValue: FilterValue<Date | undefined>;
  availableEndAtFilterValue: FilterValue<Date | undefined>;
}

export function usePopupFilterValues() {
  const [searchWord, setSearchWord] = useState("");
  const [availableStartAt, setAvailableStartAt] = useState<Date | undefined>();
  const [availableEndAt, setAvailableEndAt] = useState<Date | undefined>();
  const [popupLanguage, setPopupLanguage] = useState<Languages | undefined>();
  const [status, setStatus] = useState<PopupStatus | "">("");
  const [type, setType] = useState<PopupType | "">("");
  const [websiteType, setWebsiteType] = useState<WebsiteType | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<PopupListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "availableStartAt",
        value: availableStartAt,
        setter: setAvailableStartAt,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "popupLanguage",
        value: popupLanguage,
        setter: setPopupLanguage,
        defaultValue: "",
        type: FilterType.OTHER,
      },
      {
        key: "availableEndAt",
        value: availableEndAt,
        setter: setAvailableEndAt,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "type",
        value: type,
        setter: setType,
        defaultValue: "",
        type: FilterType.OTHER,
      },
      {
        key: "websiteType",
        value: websiteType,
        setter: setWebsiteType,
        defaultValue: "",
        type: FilterType.OTHER,
      },
      {
        key: "status",
        value: status,
        setter: setStatus,
        defaultValue: "",
        type: FilterType.OTHER,
      },
    ],
    [searchWord, type, status, availableStartAt, availableEndAt, websiteType, popupLanguage]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues: PopupFilterValues = useMemo(
    () =>
      ({
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        availableStartAtFilterValue: getFilterValueByKey("availableStartAt") as FilterValue<Date | undefined>,
        availableEndAtFilterValue: getFilterValueByKey("availableEndAt") as FilterValue<Date | undefined>,
        statusFilterValue: getFilterValueByKey("status") as FilterValue<PopupStatus | "">,
        typeFilterValue: getFilterValueByKey("type") as FilterValue<PopupType | "">,
        websiteTypeFilterValue: getFilterValueByKey("websiteType") as FilterValue<WebsiteType | "">,
        popupLanguageFilterValue: getFilterValueByKey("popupLanguage") as FilterValue<Languages | "">,
      } as PopupFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: PopupListQueryType) => {
      const param: PopupListQueryType = {
        searchWord: listQueryTypeParam?.searchWord,
        type: listQueryTypeParam?.type,
        websiteType: listQueryTypeParam?.websiteType,
        popupLanguage: listQueryTypeParam?.popupLanguage,
        status: listQueryTypeParam?.status,
        availableStartAt: listQueryTypeParam?.availableStartAt,
        availableEndAt: listQueryTypeParam?.availableEndAt,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
