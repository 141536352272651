import style from "../layout/content/post/creator.module.scss";
import bannerCreator from '../features/catalog/banner/banner.creator.module.scss';
import React, {useRef} from "react";

interface Props{
    additionText?: JSX.Element;
    bannerText : string;
    bannerTextNumber : string;
    setBannerText: (_: string) => void;
}
export function AdditionalInputText({bannerText, additionText, bannerTextNumber, setBannerText} : Props) {
    // 배너 문구 1, 2 줄바꿈 처리
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const onChangeTextArea = () => {
        if (textareaRef && textareaRef.current) {
            textareaRef.current.style.height = "48px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    };

    return (
        <div className={bannerCreator.textInput}>
            <textarea
                ref={textareaRef}
                className={style.textAreaField}
                value={bannerText}
                onChange={(e) => setBannerText(e.target.value)}
                onInput={onChangeTextArea}
                placeholder={`${bannerTextNumber} 입력`}
            />
            {additionText ?? null}
        </div>
    );
}