import {BannerPostParam, BannerPutDTO, BannerSearchCondition, BannerStatusUpdateParam} from "../types/banner";
import {MainApi} from "./MainApi";

export class BannerApi {
    static baseUrl = `${MainApi.urlPrefix}/catalog/banners`;

    // 등록
    static createBanner = (param: BannerPostParam) => () => MainApi.api.post(`${BannerApi.baseUrl}`, param);

    // 수정
    static updateBanner = (idx: number, param: BannerPutDTO) => () => MainApi.api.put(`${BannerApi.baseUrl}/${idx}`, param);

    // 조회
    static getBanners = (condition: BannerSearchCondition) => () =>
        MainApi.api.get(`${BannerApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

    // 상세
    static getBanner = (idx: number) => () =>
        MainApi.api.get(`${BannerApi.baseUrl}/${idx}`);

    // 삭제
    static deletePopups = (idxes: number[]) => () =>
        MainApi.api.delete(`${BannerApi.baseUrl}/${idxes.join(",")}`);

    // 상태 수정
    static updateBannerStatus = (param: BannerStatusUpdateParam) => () =>
        MainApi.api.put(`${BannerApi.baseUrl}/status`, param);

}