import { MainApi } from "./MainApi";
import { UnitItemPostParam, UnitItemSearchCondition } from "../types/unitItem";
import { PageSearchCondition } from "../types/page";

export class UnitItemApi {
  static baseUrl = `${MainApi.urlPrefix}/unit-item`;

  static getUnitItemList = (condition: UnitItemSearchCondition) => () =>
    MainApi.api.get(`${UnitItemApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static deleteUnitItems = (idxes: number[]) => () =>
    MainApi.api.delete(`${UnitItemApi.baseUrl}${MainApi.toParamStringFromObject({ idxList: idxes })}`);

  static checkWhetherNameIsUnique = (name: string) => () =>
    MainApi.api.get(`${UnitItemApi.baseUrl}/is-name-unique${MainApi.toParamStringFromObject({ name })}`);

  static createUnitItem = (param: UnitItemPostParam) => () => MainApi.api.post(`${UnitItemApi.baseUrl}`, param);

  static getUnitItemDetail = (unitItemIdx: number) => () => MainApi.api.get(`${UnitItemApi.baseUrl}/${unitItemIdx}`);

  static getLinkedProducts = (unitItemIdx: number, pageCondition: PageSearchCondition) => () =>
    MainApi.api.get(
      `${UnitItemApi.baseUrl}/linked-products/${unitItemIdx}${MainApi.toParamStringFromObject({ pageCondition })}`
    );
}
