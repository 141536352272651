import { PopupList } from "./PopupList";
import { usePopupFilterValues } from "./hooks/usePopupFilterValues";
import { ListFilterValuesContext } from "../../../hooks/list/ListFilterValuesContext";

export function PopupListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = usePopupFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <PopupList />
    </ListFilterValuesContext.Provider>
  );
}
