import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FeelGoodLogoImageStyle = styled.img`
  width: 240px;
  height: 38px;
`;

export const FeedGoodLogoImage = function () {
  const { t } = useTranslation("common");
  return <FeelGoodLogoImageStyle src="https://images.feel-good.io/feelgood-logo.svg" alt={t("logo")} />;
};
