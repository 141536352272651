import { MainApi } from "./MainApi";
import {
  OrdersDTO,
  OrderItemDTO,
  OrderSearchCondition,
  BuyerDTO,
  RefundDTO,
  RefundItemDTO,
  RefundRequestDTO,
  AffiliateCodeOrderSearchCondition,
} from "../types/orders";

export class OrderApi {
  static baseUrl = `${MainApi.urlPrefix}/orders`;

  static searchOrders = (condition: OrderSearchCondition | AffiliateCodeOrderSearchCondition) => () =>
    MainApi.api.get(`${OrderApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static getOrderItems = (orderIdx: number) => () =>
    MainApi.api.get<OrderItemDTO[]>(`${OrderApi.baseUrl}/${orderIdx}/order-items`);

  static getOrders = (orderIdx: number) => () => MainApi.api.get<OrdersDTO>(`${OrderApi.baseUrl}/${orderIdx}`);

  static getBuyer = (orderIdx: number) => () => MainApi.api.get<BuyerDTO>(`${OrderApi.baseUrl}/${orderIdx}/buyer`);

  static getRefunds = (orderIdx: number) => () =>
    MainApi.api.get<RefundDTO[]>(`${OrderApi.baseUrl}/${orderIdx}/refunds`);

  static getRefundItems = (refundIdx: number) => () =>
    MainApi.api.get<RefundItemDTO[]>(`${OrderApi.baseUrl}/refunds/${refundIdx}/items`);

  static postRefund = (orderIdx: number, refundRequestDTO: RefundRequestDTO) => () =>
    MainApi.api.post<OrdersDTO>(`${OrderApi.baseUrl}/${orderIdx}/refund`, refundRequestDTO);

  static deleteOrder = (orderIdx: number) => () => MainApi.api.delete(`${OrderApi.baseUrl}/${orderIdx}`);

  static downloadOrders = (orderIdxes: number[], timezone: string) => () =>
    MainApi.api.get(
      `${OrderApi.baseUrl}/info/download?${orderIdxes.map((idx) => `orderIdx=${idx}`).join("&")}&time_zone=${timezone}`,
      {
        responseType: "blob",
      }
    );

  static downloadAllOrders =
    (condition: OrderSearchCondition | AffiliateCodeOrderSearchCondition, timezone: string) => () =>
      MainApi.api.get(
        `${OrderApi.baseUrl}/info/download-with-filter${MainApi.toParamStringFromObject(
          condition
        )}&time_zone=${timezone}`,
        {
          responseType: "blob",
        }
      );
}
