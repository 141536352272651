import React from "react";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";
import {RadioGroupField} from "../../../../components/RadioGroupField";
import {VoucherPurchaseRestrictions} from "../../../../types/voucher";

interface Props {
    voucherTypeLimitation: VoucherPurchaseRestrictions;
    setVoucherTypeLimitation: (_: VoucherPurchaseRestrictions) => void;
}

export function OrganMemberVoucherLimitation({voucherTypeLimitation, setVoucherTypeLimitation}: Props) {
    const {toStringVoucherTypeLimitation} = useOrganManagerType();

    // todo ''에 대한 처리가 필요함
    const voucherTypeLimitationValues = [
        VoucherPurchaseRestrictions.NONE, VoucherPurchaseRestrictions.KPASS, VoucherPurchaseRestrictions.DCAS
    ];

    return (
        <RadioGroupField enumValues={voucherTypeLimitationValues}
                         getLabel={toStringVoucherTypeLimitation}
                         value={voucherTypeLimitation} setValue={setVoucherTypeLimitation}
                         groupName={"voucherLimitation"} isRequired={true}
        />
    );
}