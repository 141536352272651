import { useCallback, useMemo, useState } from "react";
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../../types/page";
import { OrderListVO, OrderSearchCondition } from "../../../../../types/orders";
import { hasBothValuesOrBothNoValue } from "../../../../../util/etcUtil";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";
import { MemberOrderFilterValues } from "./useMemberOrderFilterValues";
import { useOrdersGetter } from "../../../../order/hooks/useOrdersGetter";

export default function useMemberOrderList(
  orderFilterValues: MemberOrderFilterValues,
  organManagerIdx?: number,
  userIdx?: number
) {
  const { t } = useTranslation("member");
  const [list, setList] = useState<PageResponse<OrderListVO>>();
  const { defaultErrorMessage } = useUtil();
  const { getOrders } = useOrdersGetter();

  const [totalOrderCount, setTotalOrderCount] = useState<number>(0);
  const pageCondition = useMemo(
    () => ({
      page: list?.number ?? DEFAULT_PAGE,
      size: list?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(list?.orders),
    }),
    [list]
  );

  const getOrderSearchConditionFromOrderFilterValues = useCallback(
    (): OrderSearchCondition => ({
      query: orderFilterValues.query ? orderFilterValues.query.trim() : undefined,
      queryType: orderFilterValues.queryType,
      orderSearchDateType: orderFilterValues.orderSearchDateType,
      startDate: orderFilterValues.startDate ? orderFilterValues.startDate : undefined,
      endDate: orderFilterValues.endDate ? orderFilterValues.endDate : undefined,
      paymentWay: orderFilterValues.paymentWay ? orderFilterValues.paymentWay : undefined,
      status: orderFilterValues.status ? orderFilterValues.status : undefined,
      displayPosition: orderFilterValues.displayPosition ? orderFilterValues.displayPosition : undefined,
      organManagerIdx: organManagerIdx ?? undefined,
      userIdx: userIdx ?? undefined,
    }),
    [orderFilterValues, organManagerIdx, userIdx]
  );

  const getOrderList = ({ page, size, sort }: PageSearchCondition, isFirstLoad?: boolean) => {
    const param: OrderSearchCondition = {
      ...getOrderSearchConditionFromOrderFilterValues(),
      pageCondition: {
        page: page ?? pageCondition.page,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      },
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.startDate, param.endDate)) {
        alert(t("order.list.validation.bothTestCompletionDatesNeeded"));
        return;
      }

      getOrders(param)
        .then((result) => {
          setList(result);
          if (isFirstLoad) setTotalOrderCount(result.totalElements);
        })
        .catch(() => alert(defaultErrorMessage));
    };
  };

  return {
    getOrderList,
    totalOrderCount,
    orderList: list,
    pageCondition,
    getOrderSearchConditionFromOrderFilterValues,
  };
}
