import { useCallback } from "react";
import { callAsync } from "../../../../../../util/sliceUtil";
import { updateOrganManagerAsync } from "../../../../../../store/organManagerSlice";
import {
  OrganManagerDTO,
  OrganManagerTranslator,
  OrganManagerUpdateDTO,
  OrganManagerVO,
} from "../../../../../../types/organManager";
import { useAppDispatch } from "../../../../../../hooks/hooks";
import { useUtil } from "../../../../../../util/hooks/useUtil";

export function useOrganManagerUpdater() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return useCallback(
    (
      organManagerIdx: number,
      organManagerUpdateDTO: OrganManagerUpdateDTO,
      onSuccess: (organManagerVO: OrganManagerVO) => void
    ) => {
      callAsync(
        dispatch(updateOrganManagerAsync({ organManagerIdx, organManagerUpdateDTO })).unwrap(),
        (response: OrganManagerDTO) => {
          onSuccess(OrganManagerTranslator.createOrganManagerVO(response));
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );
}
