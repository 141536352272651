import { AddedFile, FileType } from "../types/file";
import { useUtil } from "../util/hooks/useUtil";
import React, { useCallback } from "react";
import { FileAcceptType, FileInput } from "../features/component/file/FileInput";
import { ImageButton } from "../features/product/edit/ImageButton";
import { ImageFieldStyle } from "./styled/ImageStyle";

interface Props {
  image: AddedFile | undefined;
  setImage: (_: AddedFile | undefined) => void;
  addedImage: FileType | undefined;
  setAddedImage: (_: FileType | undefined) => void;
  additionalText?: JSX.Element;
  buttonStyleObject?: React.CSSProperties;
}
export function AnImageAddition({
  image,
  setImage,
  addedImage,
  setAddedImage,
  additionalText,
  buttonStyleObject,
}: Props) {
  const { wantToDeleteImage } = useUtil();

  const onClickImageCloseButton = useCallback(() => {
    if (window.confirm(wantToDeleteImage)) {
      setImage(undefined);
    }
  }, [wantToDeleteImage, setImage]);

  const onClickAddedImageCloseButton = useCallback(() => {
    if (window.confirm(wantToDeleteImage)) {
      setAddedImage(undefined);
    }
  }, [wantToDeleteImage, setAddedImage]);

  const addImage = useCallback(() => {
    const fileInput = new FileInput(
      (files) => {
        const reader = new FileReader();

        reader.onload = () => {
          setAddedImage({
            src: reader.result?.toString() ?? "",
            file: files[0],
          });
        };

        reader.readAsDataURL(files[0]);
      },
      false,
      [FileAcceptType.IMAGE]
    );

    fileInput.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ImageFieldStyle>
      {image?.uri ? (
        <ImageButton
          isThumbnail={true}
          imageSrc={image?.uri}
          onClickCloseBtn={onClickImageCloseButton}
          buttonStyleObject={buttonStyleObject}
        />
      ) : addedImage ? (
        <ImageButton
          isThumbnail={true}
          imageSrc={addedImage.src}
          onClickCloseBtn={onClickAddedImageCloseButton}
          buttonStyleObject={buttonStyleObject}
        />
      ) : (
        <ImageButton isThumbnail={true} onClickBlankImgBtn={addImage} buttonStyleObject={buttonStyleObject} />
      )}
      {additionalText ?? null}
    </ImageFieldStyle>
  );
}
