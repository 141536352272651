import style from "../../../components/radioGroup.module.scss";
import React from "react";
import { CouponDiscountType } from "../CouponCreator";
import { Radio, RadioGroup } from "../../../components/RadioGroup";
import { useTranslation } from "react-i18next";

interface Props {
  discountType: CouponDiscountType;
  setDiscountType: (_: CouponDiscountType) => void;
}

export function DiscountTypeRadioField({ discountType, setDiscountType }: Props) {
  const { t } = useTranslation("coupon");
  const radios: Radio<CouponDiscountType>[] = [
    {
      check: (type: CouponDiscountType) => type === CouponDiscountType.PERCENT,
      onClickFunc: () => setDiscountType(CouponDiscountType.PERCENT),
      text: t("creator.discount.percent"),
    },
    {
      check: (type: CouponDiscountType) => type === CouponDiscountType.AMOUNT,
      onClickFunc: () => setDiscountType(CouponDiscountType.AMOUNT),
      text: t("creator.discount.amount"),
    },
  ];

  return (
    <div className={style.radioGroup}>
      <RadioGroup groupName={"discountType"} isRequired={true} radios={radios} selectedValue={discountType} />
    </div>
  );
}
