import { FileClassification, FileResponse } from "../../types/file";
import { callAsync } from "../../util/sliceUtil";
import { uploadFilesAsync } from "../../store/fileSlice";
import { useAppDispatch } from "../hooks";

export function useFile() {
  const dispatch = useAppDispatch();

  const uploadImages = (files: File[], fileClassification: FileClassification) => {
    return callAsync(
      dispatch(uploadFilesAsync({ files, classification: fileClassification })).unwrap(),
      (result: FileResponse[]) => result,
      () => {
        throw Error("fail upload file");
      }
    ).then((files) => files);
  };

  return {
    uploadImages,
  };
}
