import { TableColumnData } from "../../../types/common";
import { GrantableProductListObj } from "../../../types/product";
import React, { useRef, useState } from "react";
import { ProductItemModal } from "../modal/ProductItemModal";
import { NumberStepper } from "../../../components/NumberStepper";
import { ActionButton, RedActionButton } from "../../../components/Buttons";
import { Anchor } from "../../../components/Anchor";
import { MINI_MODAL_LINK } from "../../../util/etcUtil";
import { ModalWrapperStyle } from "../../../components/Modal";
import { useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";

interface Args {
  selectedProducts: GrantableProductListObj[];
  setSelectedProducts: (_: GrantableProductListObj[]) => void;
}

export function useGrantableProductColumns({ selectedProducts, setSelectedProducts }: Args) {
  const { t } = useTranslation("product");
  const { timeConverter } = useTimeConverter();
  const isSelectedProduct = (gp: GrantableProductListObj) => selectedProducts.findIndex((sp) => sp.idx === gp.idx) > -1;
  const getSelectedProduct = (idx: number) => selectedProducts.find((sp) => sp.idx === idx);

  const ProductName = ({ p }: { p: GrantableProductListObj }) => {
    const [showProductItems, setShowProductItems] = useState(false);
    const linkRef = useRef<HTMLAnchorElement | null>(null);

    const [top, setTop] = useState(0);
    const [left, setLeft] = useState(0);

    const onClickAnchor = (e: React.MouseEvent) => {
      e.preventDefault();
      const anchorRect = (e.target as HTMLAnchorElement).getBoundingClientRect();
      setTop(anchorRect.top);
      setLeft(anchorRect.left);
      setShowProductItems(true);
    };

    return (
      <>
        <ModalWrapperStyle style={{ top: top, left: left }}>
          <ProductItemModal
            isShow={showProductItems}
            callbackCloseModal={() => setShowProductItems(false)}
            productIdx={p.idx}
            linkElement={linkRef.current ?? undefined}
          />
        </ModalWrapperStyle>
        <Anchor onClick={onClickAnchor} href={"#"} rel="noopener noreferrer" data-name={MINI_MODAL_LINK} ref={linkRef}>
          {p.name}
        </Anchor>
      </>
    );
  };

  const QuantityField = ({ qty, idx }: { qty: number; idx: number }) => {
    return (
      <NumberStepper
        value={qty.toString()}
        setValue={(v) => {
          const foundIdx = selectedProducts.findIndex((sp) => sp.idx === idx);
          const copiedProducts = [...selectedProducts];
          copiedProducts.splice(foundIdx, 0, { ...selectedProducts[foundIdx], qty: Number(v) });
          copiedProducts.splice(foundIdx + 1, 1);
          setSelectedProducts(copiedProducts);
        }}
        min={1}
      />
    );
  };

  const columns: TableColumnData<GrantableProductListObj>[] = [
    {
      accessor: "idx",
      header: "",
      sortable: false,
      width: 78,
      getNode: (a) => {
        if (isSelectedProduct(a)) {
          return (
            <RedActionButton
              onClick={() => {
                const foundIdx = selectedProducts.findIndex((sp) => sp.idx === a.idx);
                const copiedProducts = [...selectedProducts];
                copiedProducts.splice(foundIdx, 1);
                setSelectedProducts(copiedProducts);
              }}
            >
              {t("grantableProduct.cancelButton")}
            </RedActionButton>
          );
        }

        return (
          <ActionButton onClick={() => setSelectedProducts([...selectedProducts, a])}>
            {t("grantableProduct.selectButton")}
          </ActionButton>
        );
      },
    },
    {
      accessor: "name",
      header: t("grantableProduct.columns.productName"),
      sortable: false,
      isLeftAlign: true,
      getNode: (a) => <ProductName p={a} />,
      width: 420,
    },
    {
      accessor: "productItemCount",
      header: t("grantableProduct.columns.unitItem"),
      sortable: false,
      width: 80,
    },
    {
      accessor: "createAt",
      header: t("grantableProduct.columns.createdAt"),
      sortable: true,
      getNode: (a) => <>{timeConverter.convertToLocalTime(a.createAt)}</>,
      width: 212,
    },
    {
      accessor: "idx",
      header: t("grantableProduct.columns.quantity"),
      sortable: false,
      getNode: (a) => {
        const selectedProduct = getSelectedProduct(a.idx);
        if (selectedProduct) {
          return <QuantityField qty={selectedProduct.qty} idx={a.idx} />;
        }

        return <></>;
      },
      width: 130,
    },
  ];

  return columns;
}
