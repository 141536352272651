import { MainApi } from "./MainApi";
import { SearchCondition } from "../types/page";
import { ProfileSearchCondition } from "../types/userProfile";

export class UserProfileApi {
  static baseUrl = `${MainApi.urlPrefix}/user/profile`;

  static getUserProfilesByUser = (userIdx: number, condition: SearchCondition) => () =>
    MainApi.api.get(`${UserProfileApi.baseUrl}/${userIdx}${MainApi.toParamStringFromObject(condition)}`);

  static getTestHistoriesByUserProfile = (userProfileIdx: number, condition: SearchCondition) => () =>
    MainApi.api.get(
      `${UserProfileApi.baseUrl}/test-history/${userProfileIdx}${MainApi.toParamStringFromObject(condition)}`
    );

  static getUserProfileDetail = (profileIdx: number) => () =>
    MainApi.api.get(`${UserProfileApi.baseUrl}/detail/${profileIdx}`);

  static getUserProfileList = (condition: ProfileSearchCondition) => () =>
    MainApi.api.get(`${UserProfileApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static getOrgansByProfile = (profileIdx: number) => () =>
    MainApi.api.get(`${UserProfileApi.baseUrl}/${profileIdx}/organs`);

  static deleteProfiles = (profileIdxes: number[]) => () =>
    MainApi.api.delete(`${UserProfileApi.baseUrl}?${profileIdxes.map((idx) => `profileIdxes=${idx}`).join("&")}`);
}
