import { useAppDispatch } from "../../hooks/hooks";
import { getVoucherDetailAsync } from "../../store/voucherSlice";
import { useCallback, useState } from "react";
import { ProductVoucherDTO } from "../../types/voucher";

interface ReturnType {
  getVoucherDetail: (productVoucherIdx: number) => Promise<ProductVoucherDTO>;
  productVoucherDTO: ProductVoucherDTO | undefined;
}

export function useVoucherGetter(): ReturnType {
  const dispatch = useAppDispatch();
  const [productVoucherDTO, setProductVoucherDTO] = useState<ProductVoucherDTO>();

  const getVoucherDetail = useCallback(
    (productVoucherIdx: number) => {
      return dispatch(getVoucherDetailAsync(productVoucherIdx))
        .unwrap()
        .then((result) => {
          setProductVoucherDTO(result);
          return result;
        });
    },
    [dispatch]
  );

  return {
    getVoucherDetail,
    productVoucherDTO,
  };
}
