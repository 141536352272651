import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SliceState, Status } from "../types/common";
import { RootState } from "./index";
import { executePromise } from "../util/sliceUtil";
import { TermsCategory } from "../types/terms";
import { TermsApi } from "../api/TermsApi";

export interface TermsState extends SliceState {}

const initialState: TermsState = {
  status: Status.IDLE,
};

export const getTermsByCategoryAsync = createAsyncThunk("terms/getTermsByCategory", (category: TermsCategory) =>
  executePromise(TermsApi.getTermsByCategory(category))
);

export const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default termsSlice.reducer;

export const termsStatus = (state: RootState) => state.terms.status;
