import style from "./style.module.scss";
import checkbox from "../../assets/images/login_home/checkbox.svg";
import checkboxActive from "../../assets/images/login_home/checkbox_active.svg";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AdminType } from "../../types/account";
import { useNavigate } from "react-router-dom";
import { AdminRoleCategory, AdminRoleSummary } from "../../types/adminRole";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { accountStatus, checkDuplicatedPhoneNumberAsync, signUpAysnc } from "../../store/accountSlice";
import { getRoleByCategoryAsync } from "../../store/adminRoleSlice";
import { TermsCategory } from "../../types/terms";
import { ErrorType } from "../../types/error";
import {
  phoneNumberInKRRegExp,
  phoneNumberInOtherRegExp,
  phoneNumberInKRRegExpStr,
  pwdRegexStr,
  phoneNumberInOtherRegExpStr,
} from "../../util/regexpUtil";
import { useLoader } from "../../hooks/common/useLoader";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../util/hooks/useUtil";
import { NationalityUnderlineSelector } from "../../components/NationalityUnderlineSelector";
import { PrimaryButton, PrimaryOutlineButton } from "../../components/Buttons";
import { PhoneCountryCodeUnderlineSelector } from "../../components/PhoneCountryCodeUnderlineSelector";
import { useBlockEnter } from "../../hooks/useBlockEnter";
import { PasswordInputUnderline } from "../../components/PasswordInputUnderline";

enum SignUpErrorCode {
  CODE_473 = "CODE_473",
  CODE_475 = "CODE_475",
  CODE_477 = "CODE_477",
}

const FieldLineStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ErrorMessageStyle = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: ${(props) => props.theme.color.FGPointRed};
`;

export function SignUp() {
  const { t } = useTranslation(["account", "common"]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const { defaultErrorMessage } = useUtil();

  const status = useAppSelector(accountStatus);
  useLoader({ status });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [phoneCountryCode, setPhoneCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const adminType = useMemo(() => AdminType.GENERAL, []);
  const [consultantType, setConsultantType] = useState(AdminRoleCategory.CONSULTANT_GENERAL);
  const [agreeTermsOfService, setAgreeTermsOfService] = useState(false);
  const [agreeTermsOfPrivacy, setAgreeTermsOfPrivacy] = useState(false);
  const [isDuplicatedPhoneNumber, setIsDuplicatedPhoneNumber] = useState<boolean | null>(null);
  const [idErrorMessage, setIdErrorMessage] = useState("");
  const [nationality, setNationality] = useState("");

  const alertDuplicatedPhoneNumber = useCallback(() => alert(t("signUp.error.duplicatedPhoneNumber")), [t]);
  const isKoreanPhoneNumber = useMemo(() => phoneCountryCode === "+82", [phoneCountryCode]);
  const { blockEnterKeyEvent } = useBlockEnter();

  const errorTable = useMemo(
    () => ({
      [SignUpErrorCode.CODE_473.toString()]: () => alertDuplicatedPhoneNumber(),
      [SignUpErrorCode.CODE_475.toString()]: () => setIdErrorMessage(`- ${t("signUp.error.alreadySignedUp")}`),
      [SignUpErrorCode.CODE_477.toString()]: () => setIdErrorMessage(`- ${t("signUp.error.leavedAccount")}`),
    }),
    [t, alertDuplicatedPhoneNumber]
  );

  const onClickSignUp = async () => {
    if (formRef.current?.reportValidity()) {
      if (password !== confirmPassword) {
        alert(t("signUp.validation.incorrectPassword"));
        return;
      }

      if (!agreeTermsOfService || !agreeTermsOfPrivacy) {
        alert(t("signUp.validation.termsAgreementNeeded"));
        return;
      }

      if (isDuplicatedPhoneNumber === null) {
        alert(t("signUp.validation.phoneNumberCheckNeeded"));
        return;
      }

      if (isDuplicatedPhoneNumber) {
        alertDuplicatedPhoneNumber();
        return;
      }

      try {
        const roleCategory = adminType === AdminType.CONSULTANT ? consultantType : AdminRoleCategory.ADMIN_GENERAL;

        const roleResult: AdminRoleSummary = await dispatch(getRoleByCategoryAsync(roleCategory)).unwrap();
        await dispatch(
          signUpAysnc({
            email: email.trim(),
            password,
            name: name.trim(),
            phoneNumber,
            adminType,
            roleIdx: roleResult.idx,
            nationality,
            codePhone: phoneCountryCode,
          })
        ).unwrap();
        navigate(`/sign-up/success`);
      } catch (error) {
        console.error(error);
        const errorMessage = (error as Error).message;
        const errorObj: ErrorType = JSON.parse(errorMessage);
        const errorCaseFunc = errorTable[`CODE_${errorObj.errorCode.httpCode}`];
        if (errorCaseFunc) {
          errorCaseFunc();
        } else {
          alert(defaultErrorMessage);
        }
      }
    }
  };
  const onClickAgreeTermsBtn = (e: React.MouseEvent) => {
    e.preventDefault();
    setAgreeTermsOfService(!agreeTermsOfService);
  };

  const onClickAgreePrivacyTermsButton = (e: React.MouseEvent) => {
    e.preventDefault();
    setAgreeTermsOfPrivacy(!agreeTermsOfPrivacy);
  };
  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const thisValue = e.target.value;
    setPhoneNumber(thisValue.replace(/[^0-9]/g, ""));
    setIsDuplicatedPhoneNumber(null);
  };
  const onClickCheckDuplicatedPhoneNumberBtn = async (e: React.MouseEvent) => {
    e.preventDefault();
    if (!phoneNumber) {
      alert(t("signUp.validation.noPhoneNumber"));
      return;
    }

    if (isKoreanPhoneNumber) {
      if (!phoneNumberInKRRegExp.test(phoneNumber)) {
        alert(t("signUp.validation.wrongPhoneNumberLengthInKR"));
        return;
      }
    } else {
      if (!phoneNumberInOtherRegExp.test(phoneNumber)) {
        alert(t("signUp.validation.wrongPhoneNumberLengthInOther"));
        return;
      }
    }

    try {
      const { result } = await dispatch(
        checkDuplicatedPhoneNumberAsync({
          adminType,
          phoneNumber,
          codePhone: phoneCountryCode,
        })
      ).unwrap();

      setIsDuplicatedPhoneNumber(result);
    } catch (error) {
      console.error(error);
      alert(t("signUp.error.checkDuplicationServerError"));
    }
  };

  const ConsultantType = () => (
    <div className={style.field}>
      <label className={style.label}>{t("signUp.consultant.label")}</label>
      <div className={style.line}>
        <label className={style.radioLabel}>
          <input
            type={"radio"}
            name={"consultant-type"}
            className={style.radioButton}
            required={true}
            value={AdminRoleCategory.CONSULTANT_GENERAL}
            readOnly={true}
            checked={consultantType === AdminRoleCategory.CONSULTANT_GENERAL}
            onClick={() => setConsultantType(AdminRoleCategory.CONSULTANT_GENERAL)}
            onKeyDown={blockEnterKeyEvent}
          />
          {t("signUp.consultant.member")}
        </label>
        <label className={style.radioLabel}>
          <input
            type={"radio"}
            name={"consultant-type"}
            className={style.radioButton}
            required={true}
            value={AdminRoleCategory.CONSULTANT_MNG}
            readOnly={true}
            checked={consultantType === AdminRoleCategory.CONSULTANT_MNG}
            onClick={() => setConsultantType(AdminRoleCategory.CONSULTANT_MNG)}
          />
          {t("signUp.consultant.manager")}
        </label>
      </div>
    </div>
  );

  return (
    <div className={style.signUpWrapper}>
      <h1 className={style.signUpTitle}>{t("signUp.title")}</h1>
      <form
        className={style.form}
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {adminType === AdminType.CONSULTANT ? <ConsultantType /> : <></>}
        <div className={style.field}>
          <label className={style.label}>{t("signUp.nationality")}</label>
          <div className={style.line}>
            <NationalityUnderlineSelector nationality={nationality} setNationality={setNationality} isRequired={true} />
          </div>
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("signUp.name.label")}</label>
          <div className={style.line}>
            <input
              type={"text"}
              name={"name"}
              className={style.input}
              minLength={2}
              maxLength={50}
              required={true}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("signUp.name.placeholder")}
              onKeyDown={blockEnterKeyEvent}
            />
          </div>
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("signUp.id.label")}</label>
          <FieldLineStyle>
            <input
              type={"email"}
              name={"email"}
              className={style.input}
              minLength={6}
              maxLength={60}
              required={true}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setIdErrorMessage("");
              }}
              placeholder={t("signUp.id.placeholder")}
              onKeyDown={blockEnterKeyEvent}
            />
            {idErrorMessage ? <ErrorMessageStyle>{idErrorMessage}</ErrorMessageStyle> : <></>}
          </FieldLineStyle>
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("signUp.password.label")}</label>
          <div className={style.line}>
            <PasswordInputUnderline
              className={style.input}
              minLength={8}
              maxLength={16}
              required={true}
              pattern={pwdRegexStr}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("signUp.password.placeholder")}
              onKeyDown={blockEnterKeyEvent}
            />
          </div>
          <span className={style.inputGuide}>- {t("signUp.password.support")}</span>
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("signUp.confirmPassword.label")}</label>
          <div className={style.line}>
            <PasswordInputUnderline
              className={style.input}
              minLength={8}
              maxLength={16}
              required={true}
              pattern={pwdRegexStr}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t("signUp.confirmPassword.placeholder")}
              onKeyDown={blockEnterKeyEvent}
            />
          </div>
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("signUp.phoneNumber.label")}</label>
          <div className={style.line}>
            <PhoneCountryCodeUnderlineSelector
              code={phoneCountryCode}
              setCode={(code) => {
                setPhoneCountryCode(code);
                setIsDuplicatedPhoneNumber(null);
              }}
            />
          </div>
          <div className={style.line}>
            <input
              type={"tel"}
              name={"phone"}
              className={style.input}
              minLength={isKoreanPhoneNumber ? 11 : 5}
              maxLength={isKoreanPhoneNumber ? 11 : 15}
              required={true}
              pattern={isKoreanPhoneNumber ? phoneNumberInKRRegExpStr : phoneNumberInOtherRegExpStr}
              value={phoneNumber}
              onChange={(e) => onChangePhoneNumber(e)}
              placeholder={
                isKoreanPhoneNumber
                  ? t("inputPhoneNumber.placeholder1", { ns: "common" })
                  : t("inputPhoneNumber.placeholder2", { ns: "common" })
              }
              onKeyDown={blockEnterKeyEvent}
            />
            <button className={style.verificationBtn} onClick={onClickCheckDuplicatedPhoneNumberBtn}>
              {t("signUp.phoneNumber.checkButton")}
            </button>
          </div>
          {isDuplicatedPhoneNumber === null ? (
            <></>
          ) : isDuplicatedPhoneNumber ? (
            <span className={`${style.addedMessage} ${style.notAvailableValue}`}>
              - {t("signUp.phoneNumber.checkError.message1")}
              <br />
              {t("signUp.phoneNumber.checkError.message2")}
            </span>
          ) : (
            <span className={`${style.addedMessage} ${style.availableValue}`}>
              - {t("signUp.phoneNumber.availablePhoneNumber")}
            </span>
          )}
        </div>
        <div className={style.field}>
          <label className={style.label}>{t("signUp.termsAgreement.label")}</label>
          <div className={style.line}>
            {agreeTermsOfService ? (
              <button onClick={(e) => onClickAgreeTermsBtn(e)}>
                <img src={checkboxActive} alt={t("signUp.termsAgreement.checkedIcon")} />
              </button>
            ) : (
              <button onClick={(e) => onClickAgreeTermsBtn(e)}>
                <img src={checkbox} alt={t("signUp.termsAgreement.checkboxIcon")} />
              </button>
            )}
            <span className={style.termsText}>
              <span className={style.required}>[{t("signUp.termsAgreement.required")}]</span>&nbsp;&nbsp;
              <Trans
                t={t}
                i18nKey={"signUp.termsAgreement.message1"}
                components={[
                  // eslint-disable-next-line
                  <a
                    href="#"
                    className={style.underline}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        `${process.env.REACT_APP_BASE_URL}/terms?terms_category=${TermsCategory.TERMS_OF_SERVICE_ADMIN}`,
                        "_blank"
                      );
                    }}
                  />,
                ]}
              />
            </span>
          </div>
          <div className={style.line} style={{ marginTop: "0" }}>
            {agreeTermsOfPrivacy ? (
              <button onClick={onClickAgreePrivacyTermsButton}>
                <img src={checkboxActive} alt={t("signUp.termsAgreement.checkedIcon")} />
              </button>
            ) : (
              <button onClick={onClickAgreePrivacyTermsButton}>
                <img src={checkbox} alt={t("signUp.termsAgreement.checkboxIcon")} />
              </button>
            )}
            <span className={style.termsText}>
              <span className={style.required}>[{t("signUp.termsAgreement.required")}]</span>&nbsp;&nbsp;
              <Trans
                t={t}
                i18nKey={"signUp.termsAgreement.message2"}
                components={[
                  // eslint-disable-next-line
                  <a
                    href="#"
                    className={style.underline}
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        `${process.env.REACT_APP_BASE_URL}/terms?terms_category=${TermsCategory.PRIVACY_POLICY_ADMIN}`,
                        "_blank"
                      );
                    }}
                  />,
                ]}
              />
            </span>
          </div>
        </div>
        <div className={style.buttonWrapper}>
          <PrimaryOutlineButton className={style.cancelButton} onClick={() => navigate(`/login`)}>
            {t("findAccount.cancelButton")}
          </PrimaryOutlineButton>
          <PrimaryButton className={style.signUpBtn} onClick={onClickSignUp}>
            {t("signUp.signUpButton")}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
}
