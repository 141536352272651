import { useCouponFilterValues } from "./hooks/useCouponFilterValues";
import { CouponList } from "./CouponList";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function CouponListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useCouponFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <CouponList />
    </ListFilterValuesContext.Provider>
  );
}
