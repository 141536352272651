import { useAppDispatch } from "../../../../../../../hooks/hooks";
import { useCallback, useState } from "react";
import {
  OrganManagerVoucherMemberVO,
  OrganVoucherHistoryDTO,
  VoucherTranslator,
} from "../../../../../../../types/voucher";
import { getVoucherHistoriesAsync } from "../../../../../../../store/voucherSlice";

export function useVoucherHistoriesGetter() {
  const dispatch = useAppDispatch();

  const [histories, setHistories] = useState<OrganManagerVoucherMemberVO[]>();

  const getHistories = useCallback(
    (historySetIdx: number): Promise<OrganManagerVoucherMemberVO[]> => {
      return dispatch(getVoucherHistoriesAsync(historySetIdx))
        .unwrap()
        .then((values: OrganVoucherHistoryDTO[]) => {
          let histories = values.map(VoucherTranslator.createOrganManagerVoucherMemberVO);
          setHistories(histories);
          return histories;
        });
    },
    [dispatch]
  );

  return {
    histories,
    getHistories,
  };
}
