import { useAppDispatch } from "../../../hooks/hooks";
import { useCallback } from "react";
import { askProductsDeletableOrNotAsync } from "../../../store/productSlice";

interface ProductDeletableOrNotResult {
  idx: number;
  deletable: boolean;
}
export function useProductDeletableOrNotGetter() {
  const dispatch = useAppDispatch();

  const getProductDeletableOrNot = useCallback(
    async (productIdxes: number[]) => {
      const result: ProductDeletableOrNotResult[] = await dispatch(
        askProductsDeletableOrNotAsync(productIdxes)
      ).unwrap();
      return result.every((result) => result.deletable);
    },
    [dispatch]
  );

  return {
    getProductDeletableOrNot,
  };
}
