import style from "./subNav.module.scss";
import { Menu } from "../../types/common";
import { useNavigate } from "react-router-dom";
import { Fragment, useCallback } from "react";
import { AdminMenuCode } from "../../types/adminRole";
import { useAccessibleMenus } from "../../hooks/hooks";

interface Props {
  menus: Menu[];
  firstPath: string;
  secondPath: string;
}

export function SubNav({ menus, firstPath, secondPath }: Props) {
  const navigate = useNavigate();

  const { accessibleMenus } = useAccessibleMenus();

  const isAllowedMenu = useCallback(
    (menuCode: AdminMenuCode) => accessibleMenus?.some((menu) => menu.menu.code === menuCode) ?? false,
    [accessibleMenus]
  );

  const onClickMenu = useCallback(
    (thisMenu: Menu) => {
      if (thisMenu.isDisabled) {
        return;
      }

      if (secondPath === thisMenu.link) {
        window.location.reload();
        return;
      }

      navigate(`/${firstPath}/${thisMenu.link}`);
    },
    [secondPath, navigate, firstPath]
  );

  if (menus.length === 0) {
    return <></>;
  } else {
    return (
      <ul className={style.subNav}>
        {menus.map((menu, idx) => {
          return !menu.menuCode || isAllowedMenu(menu.menuCode) ? (
            <li
              key={`sub-menu-${idx}`}
              className={
                `${style.item} ` +
                `${menu.isDisabled ? style.disabled : ""} ` +
                `${secondPath === menu.link ? style.active : ""}`
              }
              onClick={() => onClickMenu(menu)}
            >
              {menu.label}
            </li>
          ) : (
            <Fragment key={`sub-menu-${idx}`} />
          );
        })}
      </ul>
    );
  }
}
