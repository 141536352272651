import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceState, Status } from "../types/common";
import { UserListObj, UserPutParam, UserSearchCondition, UserVO } from "../types/user";
import { addCases, executePromise } from "../util/sliceUtil";
import { UserApi } from "../api/UserApi";
import { RootState } from "./index";
import { PageResponse } from "../types/page";
import { RefundAccountPutDTO } from "../features/order/detail/component/refund/hooks/useRefundAccountModifier";
import { MarketingTermsCategory } from "../types/terms";

export interface MemberUserState extends SliceState {
  userList?: PageResponse<UserListObj>;
  userDetail?: UserVO;
}

const initialState: MemberUserState = {
  status: Status.IDLE,
};

export const getUserListAsync = createAsyncThunk("user/getList", (condition: UserSearchCondition) =>
  executePromise(UserApi.getUserList(condition))
);

export const getUserDetailAsync = createAsyncThunk("user/getUserDetail", (idx: number) =>
  executePromise(UserApi.getUserDetail(idx))
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUser",
  ({ idx, userPutParam }: { idx: number; userPutParam: UserPutParam }) =>
    executePromise(UserApi.updateUser(idx, userPutParam))
);

export const blockUsersAsync = createAsyncThunk(
  "user/blockUsers",
  ({ userIdxArr, blockReason }: { userIdxArr: number[]; blockReason: string }) =>
    executePromise(UserApi.blockUsers(userIdxArr, blockReason))
);

export const unblockUsersAsync = createAsyncThunk("user/unblockUsers", (userIdxArr: number[]) =>
  executePromise(UserApi.unblockUsers(userIdxArr))
);

export const disagreeMarketingAsync = createAsyncThunk(
  "user/disagreeMarketing",
  ({ userIdx, category }: { userIdx: number; category: MarketingTermsCategory }) =>
    executePromise(UserApi.disagreeMarketing(userIdx, category))
);

export const updateUserRefundAccount = createAsyncThunk(
  "user/updateRefundAccount",
  ({ userIdx, refundAccountPutDTO }: { userIdx: number; refundAccountPutDTO: RefundAccountPutDTO }) =>
    executePromise(UserApi.updateUserRefundAccount(userIdx, refundAccountPutDTO))
);

export const downloadUsersAsync = createAsyncThunk("user/downloadUsers", (userIdxes: number[]) =>
  executePromise(UserApi.downloadUsers(userIdxes))
);

export const userSlice = createSlice({
  name: "memberUser",
  initialState,
  reducers: {
    setUserList: (state, action: PayloadAction<PageResponse<UserListObj>>) => {
      state.userList = action.payload;
    },
    setUserDetail: (state, action: PayloadAction<UserVO>) => {
      state.userDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getUserListAsync);
    addCases(builder, getUserDetailAsync);
    addCases(builder, blockUsersAsync);
    addCases(builder, unblockUsersAsync);
    addCases(builder, updateUserAsync);
    addCases(builder, updateUserRefundAccount);
  },
});

export default userSlice.reducer;

export const { setUserList, setUserDetail } = userSlice.actions;

export const memberUserList = (state: RootState) => state.memberUser.userList;
export const memberUserStatus = (state: RootState) => state.memberUser.status;
export const memberUserDetailState = (state: RootState) => state.memberUser.userDetail;
