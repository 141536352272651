import { useTranslation } from "react-i18next";
import { UnitItemType, ValidDurationFrom } from "../unitItem";
import { useCallback, useMemo } from "react";

export function useUnitItemType() {
  const { t } = useTranslation(["unitItem", "common"]);

  const toStringFromValidDurationFrom = useCallback(
    (from: ValidDurationFrom) => {
      switch (from) {
        case ValidDurationFrom.ORDERED_DATE:
          return t("validDuration.orderedDate");
        case ValidDurationFrom.TEST_COMPLETED_DATE:
          return t("validDuration.testCompletedDate");
      }
    },
    [t]
  );

  const toStringFromUnitItemType = useCallback(
    (type: UnitItemType) => {
      switch (type) {
        case UnitItemType.KPASS_VOUCHER:
          return t("unitItemType.kpass");
        case UnitItemType.DCAS_VOUCHER:
          return t("unitItemType.dcas");
        case UnitItemType.CONSULTING_VOUCHER:
          return t("unitItemType.consulting");
        default:
          return "";
      }
    },
    [t]
  );

  const unitItemOptions = useMemo(() => {
    const list = Object.keys(UnitItemType).flatMap((key) => {
      const valueObj = UnitItemType[key as keyof typeof UnitItemType];

      if (typeof valueObj === "string" && valueObj !== UnitItemType.DEFAULT) {
        return [
          {
            value: key,
            label: toStringFromUnitItemType(valueObj),
          },
        ];
      } else {
        return [];
      }
    });

    list.unshift({ value: "", label: t("all", { ns: "common" }) });

    return list;
  }, [toStringFromUnitItemType, t]);

  return {
    toStringFromValidDurationFrom,
    toStringFromUnitItemType,
    unitItemOptions,
  };
}
