import style from "../../features/account/style.module.scss";
import feelgoodLogo from "../../assets/images/feelgood_logo.svg";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  title: string;
  description: string;
  buttonText?: string;
  buttonAction?: VoidFunction;
  children?: JSX.Element;
  wrapperStyle?: React.CSSProperties;
}

export function AccountResult({ title, description, buttonText, buttonAction, children, wrapperStyle }: Props) {
  const { t } = useTranslation("account");

  return (
    <div className={style.signUpSuccess} style={wrapperStyle}>
      <img className={style.logo} src={feelgoodLogo} alt={t("accountResult.logo")} />
      <h1 className={style.title}>{title}</h1>
      <p className={style.description}>{description}</p>
      {buttonText && buttonAction ? (
        <button className={style.goTologinPageBtn} onClick={buttonAction}>
          {buttonText}
        </button>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
}
