import { Outlet } from "react-router-dom";
import style from "./accountHome.module.scss";
import footerLogo from "../../assets/images/logo_darkblue.svg";
import { LanguageFooterSelector } from "../content/selector/LanguageFooterSelector";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../hooks/hooks";
import { useCallback } from "react";
import { TermsCategory } from "../../types/terms";

export function AccountHome() {
  const { t } = useTranslation("account");
  const { isKorean } = useLanguage();

  const openTermsWindow = useCallback((termsCategory: TermsCategory) => {
    window.open(`${process.env.REACT_APP_BASE_URL}/terms?terms_category=${termsCategory}`, "_blank");
  }, []);

  return (
    <>
      <main className={style.main}>
        <Outlet />
      </main>
      <footer className={style.footer}>
        <div className={style.footerContent}>
          <div className={style.texts}>
            <div className={style.terms}>
              <button onClick={() => openTermsWindow(TermsCategory.TERMS_OF_SERVICE_ADMIN)}>
                {t("footer.termsUsage")}
              </button>
              <span>|</span>
              <button onClick={() => openTermsWindow(TermsCategory.PRIVACY_POLICY_ADMIN)}>
                {t("footer.termsPrivateInfo")}
              </button>
            </div>
            <div className={style.companyInfo}>
              <div className={style.bold}>{t("footer.feelgood")}</div>
              <p>
                {t("footer.ceo")}&nbsp;&nbsp;|&nbsp;&nbsp;{t("footer.ceoName")}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("footer.companyRegistrationNumberLabel")}
                &nbsp;&nbsp;|&nbsp;&nbsp;397-81-01770&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {t("footer.businessLabel")}&nbsp;&nbsp;|&nbsp;&nbsp;{t("footer.businessNumber")}
                <br />
                {t("footer.inquiry")}&nbsp;&nbsp;|&nbsp;&nbsp;MAIL&nbsp;:&nbsp;contact@feel-good.io
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {isKorean ? `전화  |  051-747-4050` : null}
                <br />
                {t("footer.busanAddress")}
                <br />
                {t("footer.gyeonggiAddress")}
              </p>
            </div>
            <div className={style.language}>
              <label className={style.label}>Language</label>
              <LanguageFooterSelector />
            </div>
          </div>
          <div className={style.logo}>
            <img src={footerLogo} alt={"FeelGood Logo"} />
          </div>
        </div>
      </footer>
    </>
  );
}
