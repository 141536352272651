import {Selector} from "../layout/content/selector/Selector";
import {useCallback} from "react";
import {SelectorOption} from "../types/selector";
import {useCountry} from "../hooks/hooks";
import {filterAllowedCountriesCode} from "../util/etcUtil";

interface Props {
  isNationality?: boolean;
  className?: string;
  selectedCountryCodeISOAlpha2: string;
  setSelectedCountryCodeISOAlpha2: (_: string) => void;
  hasAllOption?: boolean;
}

export function CountrySelector({
  isNationality = false,
  hasAllOption,
  className,
  selectedCountryCodeISOAlpha2,
  setSelectedCountryCodeISOAlpha2,
}: Props) {
  const { countryOptionsWithNone} = useCountry();
  const filteredOptions = filterAllowedCountriesCode({countries:countryOptionsWithNone, withEmpty:true});

  const setSelectorOption = useCallback(
    (option: SelectorOption) => setSelectedCountryCodeISOAlpha2(option.value),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Selector
      className={className ?? ""}
      isPageSizeSelector={false}
      options={
        filteredOptions
      }
      selectedValue={selectedCountryCodeISOAlpha2}
      setSelectorOption={setSelectorOption}
    />
  );
}
