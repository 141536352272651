import { Post } from "../../../layout/content/post/Post";
import React, { useCallback } from "react";
import { ProductEditForm } from "./ProductEditForm";
import style from "./productCreator.module.scss";
import { useProductEditState } from "../hooks/useProductEditState";
import { useAppDispatch, useAppSelector, useTimeConverter } from "../../../hooks/hooks";
import { callAsync } from "../../../util/sliceUtil";
import { FileClassification, FileResponse } from "../../../types/file";
import { fileStatus } from "../../../store/fileSlice";
import {
  createGrantableProductAsync,
  createProductAsync,
  productStatus,
  setIsProductDataSaved,
} from "../../../store/productSlice";
import {
  DisplayPosition,
  GrantableProductPostParam,
  ProductDiscountType,
  ProductImageType,
  ProductItemInDetail,
  ProductPostParam,
} from "../../../types/product";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../../hooks/common/useLoader";
import { useFile } from "../../../hooks/file/useFile";
import { useProductDisplayPosition } from "../hooks/useProductDisplayPosition";
import { useProductDetailImageUploader } from "../hooks/useProductDetailImageUploader";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

export function ProductCreator() {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const { timeConverter } = useTimeConverter();
  const { defaultErrorMessage } = useUtil();

  const state = useProductEditState();

  const prdStatus = useAppSelector(productStatus);
  const fStatus = useAppSelector(fileStatus);
  useLoader({ status: [prdStatus, fStatus] });
  const { uploadImages } = useFile();
  const { uploadDetailImages } = useProductDetailImageUploader();

  const getProductItemsParam = useCallback(
    (unitItems: ProductItemInDetail[]) => unitItems.map((ui) => ({ unitItemIdx: ui.idx })),
    []
  );

  const resolveCreating = () => {
    dispatch(setIsProductDataSaved(true));
    alert(t("creator.result"));
    navigator(`/product/list`);
  };

  const { isDesktopImagesNeeded, isMobileImagesNeeded } = useProductDisplayPosition();

  const createNormalProduct = async () => {
    if (!state.addedThumbnailImage) {
      alert(t("creator.validations.noThumbnail"));
      return;
    }

    if (state.discountType === ProductDiscountType.AMOUNT) {
      if (state.discountValue && Number(state.discountValue) > Number(state.regularPrice)) {
        alert(t("creator.validations.incorrectDiscountAmount"));
        return;
      }
    }

    if (state.discountType === ProductDiscountType.PERCENT) {
      if (state.discountValue && (Number(state.discountValue) < 0 || Number(state.discountValue) > 100)) {
        alert(t("creator.validations.incorrectDiscountPercent"));
        return;
      }
    }

    if (isDesktopImagesNeeded(state.displayPosition) && state.addedDesktopImages.length === 0) {
      alert(t("creator.validations.noDetailImage"));
      return;
    }

    if (isMobileImagesNeeded(state.displayPosition) && state.addedMobileImages.length === 0) {
      alert(t("creator.validations.noDetailImage"));
      return;
    }

    if (state.addedUnitItems.length === 0) {
      alert(t("creator.validations.noUnitItem"));
      return;
    }

    if (!state.isSellingAlways && !state.sellingEndDate) {
      alert(t("creator.validations.noSellingDate"));
      return;
    }

    if (!confirmCreateProduct()) {
      return;
    }

    const thumbnailFile: FileResponse[] = await uploadImages(
      [state.addedThumbnailImage.file],
      FileClassification.PRODUCT_THUMBNAIL_IMAGE
    );

    const desktopImageFiles: FileResponse[] =
      state.addedDesktopImages.length > 0
        ? await uploadDetailImages(state.addedDesktopImages.map((file) => file.file))
        : [];

    const mobileImageFiles: FileResponse[] =
      state.addedMobileImages.length > 0
        ? await uploadDetailImages(state.addedMobileImages.map((file) => file.file))
        : [];

    const param: ProductPostParam = {
      name: state.name.trim(),
      displayPosition: state.displayPosition,
      language: state.language,
      thumbnail: {
        uri: thumbnailFile[0].uri,
        fileIdx: thumbnailFile[0].idx,
      },
      regularPrice: Number(state.regularPrice),
      discountType: state.discountType,
      discountValue: state.discountValue ? Number(state.discountValue) : undefined,
      sellingPrice: Number(state.sellingPrice),
      sellingStartDate: state.sellingStartDate ? timeConverter.convertToUTC(state.sellingStartDate) : undefined,
      sellingEndDate: state.sellingEndDate ? timeConverter.convertToUTC(state.sellingEndDate) : undefined,
      productItems: getProductItemsParam(state.addedUnitItems),
      description: state.description,
      specialDescription: state.specialDescription ? state.specialDescription : undefined,
      desktopImages:
        desktopImageFiles.length > 0
          ? desktopImageFiles.map((df, idx) => ({
              file: {
                uri: df.uri,
                fileIdx: df.idx,
              },
              orderNum: idx + 1,
              type: ProductImageType.DESKTOP,
            }))
          : undefined,
      mobileImages:
        mobileImageFiles.length > 0
          ? mobileImageFiles.map((df, idx) => ({
              file: {
                uri: df.uri,
                fileIdx: df.idx,
              },
              orderNum: idx + 1,
              type: ProductImageType.MOBILE,
            }))
          : undefined,
      isCouponUsable: state.isCouponUsable,
    };

    return callAsync(dispatch(createProductAsync(param)).unwrap(), resolveCreating, () => alert(defaultErrorMessage));
  };

  const confirmCreateProduct = useCallback(() => window.confirm(t("creator.confirm")), [t]);

  const createGrantableProduct = () => {
    if (state.addedUnitItems.length === 0) {
      alert(t("creator.validations.noUnitItem"));
      return;
    }

    if (!confirmCreateProduct()) {
      return;
    }

    const param: GrantableProductPostParam = {
      name: state.name,
      productItems: getProductItemsParam(state.addedUnitItems),
    };

    return callAsync(dispatch(createGrantableProductAsync(param)).unwrap(), resolveCreating, () =>
      alert(defaultErrorMessage)
    );
  };

  const createProduct = () => {
    if (state.displayPosition === DisplayPosition.GRANTABLE_PRODUCT) {
      createGrantableProduct()?.then();
    } else {
      createNormalProduct().then();
    }
  };

  return (
    <>
      <Post
        title={t("creator.title")}
        buttonTitle={t("creator.title")}
        buttonOnClickAction={createProduct}
        formClassName={style.productCreatorForm}
      >
        <ProductEditForm state={state} isCreatePage={true} />
      </Post>
    </>
  );
}
