import React, { useEffect, useState } from "react";
import { SelectorOption } from "../../../types/selector";
import {
  OptionItem,
  Options,
  SelectAreaStyle,
  SelectArrowStyle,
  SelectedItem,
  SelectorHiddenInputStyle,
  SelectorWrapper,
} from "../../../components/SelectorStyle";
import { useSelector } from "./useSelector";
import selectorArrow from "../../../assets/images/select_arrow.svg";
import { SELECTED_VALUE, SELECTOR_AREA, SELECTOR_ARROW } from "../../../util/etcUtil";
import { useLanguage } from "../../../hooks/hooks";

interface Props {
  className?: string;
  isPageSizeSelector: boolean;
  options: SelectorOption[];
  selectedValue: string;
  setSelectorOption: (_: SelectorOption) => void;
  required?: boolean;
  width?: number;
}

export function Selector({
  className,
  isPageSizeSelector,
  options,
  selectedValue,
  setSelectorOption,
  required: isRequired = false,
  width,
}: Props) {
  const { isKorean } = useLanguage();
  const { selectorRef, onClickSelector, onClickOption, selectedOption, showOptions } = useSelector({
    options,
    selectedValue,
    setSelectorOption,
  });
  const [optionTop, setOptionTop] = useState(46);

  useEffect(() => {
    if (selectorRef.current) {
      const { height } = selectorRef.current.getBoundingClientRect();
      setOptionTop(height);
    }
  }, [selectorRef, selectedOption]);

  return (
    <>
      <SelectorWrapper
        className={`${className ?? ""} ${isPageSizeSelector ? `pagingSize ${isKorean ? "" : "notKO"}` : ""}`}
        style={{ width: width ? `${width}px` : "", backgroundColor: "white" }}
      >
        <SelectAreaStyle
          data-name={SELECTOR_AREA}
          onClick={onClickSelector}
          ref={selectorRef}
          className={isPageSizeSelector ? "pagingSize" : ""}
        >
          <SelectedItem
            data-name={SELECTED_VALUE}
            className={`${isPageSizeSelector ? "pagingSize" : ""} ${selectedOption?.isLight ? "none" : ""}`}
          >
            {selectedOption?.label}
          </SelectedItem>
          <SelectArrowStyle data-name={SELECTOR_ARROW} src={selectorArrow} alt={"arrow"} />
        </SelectAreaStyle>
        <Options
          className={`${showOptions ? "" : "hide"} ${isPageSizeSelector ? "pagingSize" : ""}`}
          style={{ top: `${optionTop}px` }}
        >
          {options.map((option, idx) => (
            <OptionItem
              key={`option-${option.value}-${idx}`}
              data-value={option.value}
              onClick={() => onClickOption(option)}
            >
              {option.label}
            </OptionItem>
          ))}
        </Options>
        <SelectorHiddenInputStyle required={isRequired} value={selectedOption?.value ?? ""} onChange={() => {}} />
      </SelectorWrapper>
    </>
  );
}
