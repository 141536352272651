import { YearAndMonth } from "../../../components/YearAndMonth";
import { useListFilterValues } from "../../../hooks/hooks";
import { AffiliateCodeSettlementListFilterValues } from "./hooks/useAffiliateCodeSettlementFilterValues";
import { useMemo } from "react";

export const SETTLEMENT_START_YEAR = 2023;
export const SETTLEMENT_START_MONTH = 10;

interface Props {
  setYearAndMonth: (y: string, m: string) => void;
}
export function SettlementYearAndMonthWrapper({ setYearAndMonth }: Props) {
  const { filterValues } = useListFilterValues();
  const { yearFilterValue, monthFilterValue } = filterValues as AffiliateCodeSettlementListFilterValues;
  const year = useMemo(() => Number(yearFilterValue.value), [yearFilterValue.value]);
  const month = useMemo(() => Number(monthFilterValue.value), [monthFilterValue.value]);

  return (
    <YearAndMonth
      startMonth={SETTLEMENT_START_MONTH}
      startYear={SETTLEMENT_START_YEAR}
      year={year}
      month={month}
      setYearAndMonth={(y, m) => setYearAndMonth(y.toString(), m.toString())}
    />
  );
}
