import style from "../style.module.scss";
import React, { useState } from "react";
import { FindID } from "./FindID";
import { FindPW } from "./FindPW";
import { useAppSelector } from "../../../hooks/hooks";
import { accountStatus } from "../../../store/accountSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { useTranslation } from "react-i18next";

export function FindAccount() {
  const { t } = useTranslation("account");
  const status = useAppSelector(accountStatus);

  const [accountType, setAccountType] = useState<"EMAIL" | "PASSWORD">("EMAIL");

  useLoader({ status });

  return (
    <div className={style.findAccountWrapper}>
      <h1 className={style.signUpTitle}>{t("findAccount.title")}</h1>
      <ul className={style.adminTypeTabs}>
        <li
          className={`${style.tabButton} ${accountType === "EMAIL" ? style.active : ""}`}
          onClick={() => setAccountType("EMAIL")}
        >
          {t("findAccount.findIDTab")}
        </li>
        <li
          className={`${style.tabButton} ${accountType === "PASSWORD" ? style.active : ""}`}
          onClick={() => setAccountType("PASSWORD")}
        >
          {t("findAccount.findPasswordTab")}
        </li>
      </ul>
      {accountType === "EMAIL" ? <FindID /> : <FindPW />}
    </div>
  );
}
