import { useCallback } from "react";
import { useAffiliateCodeDeleter } from "./useAffiliateCodeDeleter";
import { AffiliateCodeListVO } from "../../../../types/affiliateCode";

interface Args {
  resolve: () => void;
}
export function useAffiliateCodeDeleterWithValidation({ resolve }: Args) {
  const { deleteAffiliateCode } = useAffiliateCodeDeleter();

  const deleteCode = useCallback(
    (codes: AffiliateCodeListVO[]) => {
      if (codes.length === 0) {
        alert("제휴코드를 선택해주세요.");
        return;
      }

      const confirmMessage = codes.some((code) => code.paymentsCount > 0)
        ? "선택한 제휴코드를 삭제하시겠습니까?\n보유자의 제휴코드 목록에서도 삭제되며 재사용 할 수 없습니다."
        : "선택한 제휴코드를 삭제하시겠습니까?";

      if (!window.confirm(confirmMessage)) {
        return;
      }

      deleteAffiliateCode(codes.map((c) => c.idx)).then(() => resolve());
    },
    [deleteAffiliateCode, resolve]
  );

  return {
    deleteCode,
  };
}
