import { useUnitItemType } from "../../../types/hooks/useUnitItemType";
import { Trans, useTranslation } from "react-i18next";
import { useCallback } from "react";
import { ValidDurationFrom } from "../../../types/unitItem";

interface Props {
  from: ValidDurationFrom;
  day: number;
}
export function ValidDurationValue({ from, day }: Props) {
  const { t } = useTranslation("product");
  const { toStringFromValidDurationFrom } = useUnitItemType();

  const ValidDurationFrom = useCallback(
    ({ from }: { from: ValidDurationFrom }) => <>{toStringFromValidDurationFrom(from)}</>,
    [toStringFromValidDurationFrom]
  );
  const ValidDurationDay = useCallback(({ day }: { day: number }) => <>{day}</>, []);

  return (
    <Trans
      t={t}
      i18nKey={"productItem.availableUntil.value"}
      components={[<ValidDurationFrom from={from} />, <ValidDurationDay day={day} />]}
    />
  );
}
