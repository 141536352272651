import { PopupType } from "../../../types/popup";
import { RadioGroupField } from "../../../components/RadioGroupField";
import { usePopupType } from "../../../types/hooks/usePopupType";

interface Props {
  popupType: PopupType;
  setPopupType: (_: PopupType) => void;
}
export function PopupTypeField({ popupType, setPopupType }: Props) {
  const { getPopupTypeLabel } = usePopupType();

  return (
    <RadioGroupField
      enumValues={Object.values(PopupType)}
      getLabel={getPopupTypeLabel}
      value={popupType}
      setValue={setPopupType}
      groupName={"popupType"}
      isRequired={true}
    />
  );
}
