import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { MemoPutParam } from "../../../../types/memo";
import { callAsync } from "../../../../util/sliceUtil";
import { updateMemoAsync } from "../../../../store/memoSlice";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Args {
  onRefresh: () => void;
}
export function useMemoUpdater({ onRefresh }: Args) {
  const { t } = useTranslation("memo");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const updateMemo = useCallback(
    (memoIdx: number, param: MemoPutParam) => {
      if (window.confirm(t("update.confirm"))) {
        callAsync(
          dispatch(updateMemoAsync({ memoIdx, param })).unwrap(),
          () => {
            onRefresh();
            alert(t("update.result"));
          },
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [onRefresh, defaultErrorMessage, dispatch, t]
  );

  return {
    updateMemo,
  };
}
