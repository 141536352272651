import { TimeConverterContext } from "./TimeConverterContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { TimeConverter } from "../../util/timeConverter";
import { CookieKeys, useCookie } from "../auth/useCookie";

export function TimeConverterProvider({ children }: { children: JSX.Element }) {
  const timeConverter = useMemo(() => TimeConverter.getInstance(), []);
  const [currentTimezone, setCurrentTimezone] = useState(timeConverter.getTimezone());
  const { setCookie, getValueFromCookie } = useCookie();
  const [isInitialized, setIsInitialized] = useState(false);

  const setTimezone = useCallback(
    (timezone: string) => {
      timeConverter.setTimezone(timezone);
      setCurrentTimezone(timezone);
      setCookie(CookieKeys.TIMEZONE, timezone);
    },
    [timeConverter, setCurrentTimezone, setCookie]
  );

  useEffect(() => {
    if (!isInitialized) {
      const cookieValue = getValueFromCookie(CookieKeys.TIMEZONE);
      if (cookieValue) {
        setTimezone(cookieValue);
      } else {
        setCurrentTimezone(timeConverter.getTimezone());
        setCookie(CookieKeys.TIMEZONE, timeConverter.getTimezone());
      }
      setIsInitialized(true);
    }
  }, [isInitialized, getValueFromCookie, setTimezone, setCurrentTimezone, setIsInitialized, timeConverter, setCookie]);

  return (
    <TimeConverterContext.Provider
      value={{
        timeConverter,
        timezone: currentTimezone,
        setTimezone,
      }}
    >
      {children}
    </TimeConverterContext.Provider>
  );
}
