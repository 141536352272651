import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BannerPostParam, BannerPutDTO, BannerSearchCondition, BannerStatusUpdateParam} from "../types/banner";
import {executePromise} from "../util/sliceUtil";
import {BannerApi} from "../api/BannerApi";
import {SliceState, Status} from "../types/common";

export interface BannerState extends SliceState {
    isBannerDataSaved: boolean;
}

const initialState: BannerState = {
    status: Status.IDLE,
    isBannerDataSaved: false,
};

export const bannerSlice = createSlice({
    name: "banner",
    initialState,
    reducers: {
        setIsBannerDataSaved: (state, action: PayloadAction<boolean>) => {
            state.isBannerDataSaved = action.payload;
        },
    },
    extraReducers: (builder) => {
    },
});

export const { setIsBannerDataSaved } = bannerSlice.actions;

/* 배너 생성 */
export const createBannerAsync = createAsyncThunk("banner/createBanner", (param: BannerPostParam) =>
    executePromise(BannerApi.createBanner(param))
);

/* 배너 리스트 조회 */
export const getBannerAsync = createAsyncThunk("banner/getBanners", (condition: BannerSearchCondition) =>
    executePromise(BannerApi.getBanners(condition)));

/* 배너 상세 조회 */
export const getBannerDetailAsync = createAsyncThunk("banner/getBannerDetail", (idx: number) =>
    executePromise(BannerApi.getBanner(idx)));

/* 배너 수정 */
export const updateBannerAsync = createAsyncThunk("", ({ bannerIdx, param }: { bannerIdx: number; param: BannerPutDTO }) =>
    executePromise(BannerApi.updateBanner(bannerIdx, param))
);

/* 배너 삭제 */
export const deleteBannersAsync = createAsyncThunk("banner/deleteBanners", (idxes: number[]) =>
    executePromise(BannerApi.deletePopups(idxes)));

/* 배너 노출 상태 변경 */
export const updateBannerStatusAsync = createAsyncThunk("banner/updateBannerStatus", (param: BannerStatusUpdateParam) =>
    executePromise(BannerApi.updateBannerStatus(param))
);