import { MainApi } from "./MainApi";
import { OrganMemberSearchCondition } from "../types/organMember";

export class OrganMemberApi {
  static baseUrl = `${MainApi.urlPrefix}/organ-member`;

  static getOrganMembers = (organIdx: number, condition: OrganMemberSearchCondition) => () =>
    MainApi.api.get(`${OrganMemberApi.baseUrl}/${organIdx}${MainApi.toParamStringFromObject(condition)}`);

  static kickOutMembers = (organIdx: number, memberIdxes: number[]) => () =>
    MainApi.api.delete(
      `${OrganMemberApi.baseUrl}/export/${organIdx}${MainApi.toParamStringFromObject({ memberIdxes })}`
    );
}
