import { useAppSelector } from "../../../hooks/hooks";
import { popupStatusState } from "../../../store/popupSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { useCallback, useMemo, useState } from "react";
import { usePopupFilters } from "./hooks/usePopupFilters";
import { usePopupList } from "./hooks/usePopupList";
import { usePopupColumns } from "./hooks/usePopupColumns";
import { ListTable } from "../../../layout/content/list/ListTable";
import { ListCount, WhichList } from "../../../components/ListCount";
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import stopIcon from "../../../assets/images/button_icons/stop_icon.svg";
import confirmIcon from "../../../assets/images/button_icons/confirm_icon.svg";
import { DetailActionButton } from "../../../components/Buttons";
import { usePopupStatusUpdater } from "./hooks/usePopupStatusUpdater";
import { PopupListVO, PopupStatus } from "../../../types/popup";
import { usePopupDeleter } from "./hooks/usePopupDeleter";

export function PopupList() {
  const status = useAppSelector(popupStatusState);
  useLoader({ status });
  const [selectedPopupIdxes, setSelectedPopupIdxes] = useState<number[]>([]);
  const { totalCount, popupList, getPopupListWithParam, searchPopupList, pageCondition } = usePopupList();
  const { filters } = usePopupFilters({ search: searchPopupList({}) });
  const { updatePopupStatus } = usePopupStatusUpdater({ onCompletedCallback: () => getPopupListWithParam() });
  const { deletePopups } = usePopupDeleter({ onCompletedCallback: () => getPopupListWithParam(true) });

  const selectedPopups = useMemo(
    () => popupList?.content.filter((vo) => selectedPopupIdxes.includes(vo.idx)) ?? [],
    [selectedPopupIdxes, popupList?.content]
  );

  const onClickHidePopupButton = useCallback(
    (popups: PopupListVO[], isButtonOnList: boolean) => {
      updatePopupStatus(popups, PopupStatus.UNEXPOSED, isButtonOnList).then();
    },
    [updatePopupStatus]
  );

  const onClickShowPopupButton = useCallback(
    (popups: PopupListVO[], isButtonOnList: boolean) => {
      updatePopupStatus(popups, PopupStatus.EXPOSED, isButtonOnList).then();
    },
    [updatePopupStatus]
  );

  const onClickDeleteButton = useCallback(() => {
    deletePopups(selectedPopupIdxes).then();
  }, [deletePopups, selectedPopupIdxes]);

  const { columns } = usePopupColumns({
    showPopup: (vo: PopupListVO) => onClickShowPopupButton([vo], true),
    hidePopup: (vo: PopupListVO) => onClickHidePopupButton([vo], true),
    getList: getPopupListWithParam,
  });

  return (
    <>
      {popupList ? (
        <ListTable
          filters={filters}
          checkedRowIdxArr={selectedPopupIdxes}
          setCheckedRowIdxArr={setSelectedPopupIdxes}
          buttonsNode={
            <>
              <div>
                <DetailActionButton onClick={() => onClickDeleteButton()}>
                  <img src={deleteIcon} alt={"삭제 아이콘"} />
                  팝업삭제
                </DetailActionButton>
              </div>
              <div>
                <DetailActionButton onClick={() => onClickHidePopupButton(selectedPopups, false)}>
                  <img src={stopIcon} alt={"노출중지 아이콘"} />
                  노출중지
                </DetailActionButton>
                <DetailActionButton onClick={() => onClickShowPopupButton(selectedPopups, false)}>
                  <img src={confirmIcon} alt={"노출재개 아이콘"} />
                  노출재개
                </DetailActionButton>
              </div>
            </>
          }
          listData={popupList}
          getList={({ page, sort, size }) => searchPopupList({ page, sort, size })}
          columns={columns}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalCount ?? 0} whichList={WhichList.POPUP} />,
            singleUnitKeyInCommonFile: "popupUnit.singleComponent",
            multipleUnitKeyInCommonFile: "popupUnit.multipleComponent",
          }}
          pageOnFilter={pageCondition}
        />
      ) : null}
    </>
  );
}
