import { NumberFilter } from "../../../../types/tableFilter";
import { LOCALES_STRING } from "../../../../util/etcUtil";
import { getOnlyNumberText } from "../../../../util/regexpUtil";
import { FilterInputNumber } from "../../../../components/Inputs";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../../hooks/hooks";

interface NumberFieldProps {
  numberField: NumberFilter;
  className?: string;
}

export function FilterNumber({ numberField, className }: NumberFieldProps) {
  const { t } = useTranslation("common");
  const { isKorean } = useLanguage();
  const mask = (v: string) => {
    return v ? parseFloat(v).toLocaleString(LOCALES_STRING) : "";
  };

  const onChangeFunc = (v: string) => {
    numberField.onChangeFunc(v);
  };

  return (
    <FilterInputNumber
      className={`${isKorean ? "" : "notKO"} ${className ?? ""}`}
      value={mask(numberField.value)}
      onChange={(e) => onChangeFunc(getOnlyNumberText(e.target.value))}
      min={numberField.min ?? undefined}
      max={numberField.max ?? undefined}
      required={numberField.isRequired}
      placeholder={numberField.placeholderVal ?? t("inputNumberPlaceholder")}
    />
  );
}
