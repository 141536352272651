import style from "../../../layout/content/post/creator.module.scss";
import React from "react";

interface Props {
  name: string;
  setName: (_: string) => void;
}

export function PartnerNameField({ name, setName }: Props) {
  return (
    <input
      className={style.inputField}
      type={"text"}
      value={name}
      required={true}
      onChange={(e) => setName(e.target.value)}
      placeholder={"등록할 협력사명 입력"}
    />
  );
}
