import style from "../productEditForm.module.scss";
import React, { useMemo } from "react";
import { Label104x46 } from "../../../../components/Label";
import { Radio, RadioGroup } from "../../../../components/RadioGroup";
import { useTranslation } from "react-i18next";

interface Props {
  isCouponUsable: boolean;
  setIsCouponUsable: (_: boolean) => void;
}

enum CouponUsable {
  TRUE = "true",
  FALSE = "false",
}

export function ProductEditCouponUsable({ isCouponUsable, setIsCouponUsable }: Props) {
  const { t } = useTranslation("product");
  const couponUsable = useMemo(() => (isCouponUsable ? CouponUsable.TRUE : CouponUsable.FALSE), [isCouponUsable]);

  const radios: Radio<CouponUsable>[] = [
    {
      check: (type) => type === CouponUsable.TRUE,
      onClickFunc: () => {
        setIsCouponUsable(true);
      },
      text: t("edit.couponUsable.available"),
    },
    {
      check: (type) => type === CouponUsable.FALSE,
      onClickFunc: () => {
        setIsCouponUsable(false);
      },
      text: t("edit.couponUsable.unavailable"),
    },
  ];

  return (
    <div className={`${style.field} ${style.alignCenter}`}>
      <Label104x46 text={t("edit.couponUsable.label")} className={style.label} />
      <RadioGroup groupName={"couponUsable"} isRequired={true} radios={radios} selectedValue={couponUsable} />
    </div>
  );
}
