import {useCallback} from "react";
import {CookieKeys, useCookie} from "./auth/useCookie";
import {VoucherType} from "../types/voucher";
import {TestResultFormType} from "../types/common";

export function useOpenTestResult() {
  const { getValueFromCookie } = useCookie();

  return useCallback(
    (contentExecutedInfoIdx: number, voucherType : VoucherType, resultForm ?: TestResultFormType) => {
      const token = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
      const type = voucherType === VoucherType.KPASS ? 'kpass' : 'dcas';
        const refUrl = resultForm === TestResultFormType.SUMMARY
            // eslint-disable-next-line max-len
            ? `${process.env.REACT_APP_BASE_URL}/api/v1/${type}/organ-manager/test-result/summary/${contentExecutedInfoIdx}/${token}`
            : `${process.env.REACT_APP_BASE_URL}/api/v1/${type}/admin/test-result/${contentExecutedInfoIdx}/${token}`;
      console.log(resultForm)


      if (token) {
        window.open(
          refUrl,
          "_blank"
        );
      }
    },
      //eslint-disable-next-line
    [getValueFromCookie]
  );
}
