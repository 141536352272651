import { OrderSearchDateType, OrderSearchWordType, OrderStatus } from "../../../../../types/orders";
import { PaymentWay } from "../../../../../types/payment";
import { FilterContentType, TableFilter } from "../../../../../types/tableFilter";
import { useTimeConverter } from "../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useOrdersType } from "../../../../../types/hooks/useOrdersType";
import { usePaymentType } from "../../../../../types/hooks/usePaymentType";
import { DisplayPosition } from "../../../../../types/product";
import { useProductType } from "../../../../../types/hooks/useProductType";
import { MemberOrderFilterValues } from "./useMemberOrderFilterValues";
import { useMemo } from "react";

export interface OrderFilterValues {
  query: string;
  queryType: OrderSearchWordType;
  orderSearchDateType: OrderSearchDateType;
  startDate?: Date;
  endDate?: Date;
  paymentWay: PaymentWay | "";
  status: OrderStatus | "";
  displayPosition: DisplayPosition | "";
}

interface Args {
  search: () => void;
  filterValues: MemberOrderFilterValues;
}

export function useMemberOrderFilters({ search, filterValues }: Args) {
  const { t } = useTranslation(["member", "order"]);
  const { timeConverter } = useTimeConverter();
  const { orderStatusOptions } = useOrdersType();
  const { paymentWayOptions } = usePaymentType();
  const { userDisplayOptions } = useProductType();

  const CodeSearchFilter: TableFilter = useMemo(
    () => ({
      labelTitle: "제휴코드",
      content: {
        type: FilterContentType.TEXT,
        value: filterValues.query,
        maxLength: 100,
        placeholderVal: t("filter.search.placeholder", { ns: "order" }),
        onChangeFunc: filterValues.setQuery,
        onEnterKeyUpFunc: search,
      },
    }),
    [t, search, filterValues.query, filterValues.setQuery]
  );
  const durationFilter: TableFilter = useMemo(
    () => ({
      labelTitle: t("order.filter.duration.label"),
      content: {
        type: FilterContentType.CALENDAR_RANGE,
        from: {
          type: FilterContentType.CALENDAR,
          value: timeConverter.convertToFilterDateString(filterValues.startDate),
          onChangeFunc: (v) => filterValues.setStartDate(timeConverter.convertToFilterDate(v)),
          placeholderVal: t("order.filter.duration.placeholder"),
        },
        to: {
          type: FilterContentType.CALENDAR,
          value: timeConverter.convertToFilterDateString(filterValues.endDate),
          onChangeFunc: (v) => filterValues.setEndDate(timeConverter.convertToFilterDate(v)),
          placeholderVal: t("order.filter.duration.placeholder"),
        },
        typeSelector: {
          type: FilterContentType.SELECT,
          value: filterValues.orderSearchDateType,
          options: [
            { value: OrderSearchDateType.ORDERED_AT, label: t("order.filter.orderedAt") },
            { value: OrderSearchDateType.PAID_AT, label: t("order.filter.paidAt") },
          ],
          onChangeFunc: (v) => filterValues.setOrderSearchDateType(v as OrderSearchDateType),
        },
      },
    }),
    [t, timeConverter, filterValues]
  );
  const displayPositionFilter: TableFilter = useMemo(
    () => ({
      labelTitle: t("filter.displayPosition", { ns: "order" }),
      content: {
        type: FilterContentType.SELECT,
        value: filterValues.displayPosition,
        options: [
          { label: t("all", { ns: "order" }), value: "" },
          ...userDisplayOptions.filter((option) => option.value !== DisplayPosition.GRANTABLE_PRODUCT.toString()),
        ],
        onChangeFunc: (v) => filterValues.setDisplayPosition(v as DisplayPosition),
      },
    }),
    [userDisplayOptions, filterValues, t]
  );
  const paymentWayFilter: TableFilter = useMemo(
    () => ({
      labelTitle: t("order.filter.paymentWay"),
      content: {
        type: FilterContentType.SELECT,
        value: filterValues.paymentWay,
        options: paymentWayOptions,
        onChangeFunc: (v) => filterValues.setPaymentWay(v as PaymentWay),
      },
    }),
    [filterValues, paymentWayOptions, t]
  );
  const statusFilter: TableFilter = useMemo(
    () => ({
      labelTitle: t("order.filter.status"),
      content: {
        type: FilterContentType.SELECT,
        value: filterValues.status,
        options: orderStatusOptions,
        onChangeFunc: (v) => filterValues.setStatus(v as OrderStatus),
      },
    }),
    [filterValues, orderStatusOptions, t]
  );

  const userFilters: TableFilter[][] = useMemo(
    () => [[CodeSearchFilter], [durationFilter], [displayPositionFilter, paymentWayFilter], [statusFilter]],
    [CodeSearchFilter, durationFilter, displayPositionFilter, paymentWayFilter, statusFilter]
  );

  const organManagerFilters: TableFilter[][] = useMemo(
    () => [[durationFilter], [paymentWayFilter], [statusFilter]],
    [durationFilter, paymentWayFilter, statusFilter]
  );

  return {
    userFilters,
    organManagerFilters,
  };
}
