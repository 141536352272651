import { useCallback, useState } from "react";
import { useOrdersGetter } from "../../../../order/hooks/useOrdersGetter";
import {
  AffiliateCodeOrderSearchCondition,
  OrderListVO,
  OrderSearchDateType,
  OrderSearchWordType,
} from "../../../../../types/orders";
import { DEFAULT_PAGE, DEFAULT_SIZE_IN_DETAIL, PageResponse } from "../../../../../types/page";
import { useUtil } from "../../../../../util/hooks/useUtil";
import { useTimeConverter } from "../../../../../hooks/hooks";

interface AffiliateCodeOrderListParam {
  page?: number;
  code: string;
  codeHolderOrganManagerIdx: number;
  year?: number;
  month?: number;
}

export function useOrganManagerCodeOrderList() {
  const { getOrders } = useOrdersGetter();
  const { defaultErrorMessage } = useUtil();
  const [list, setList] = useState<PageResponse<OrderListVO>>();
  const { timeConverter } = useTimeConverter();

  const getListParameters = useCallback(
    ({
      page,
      code,
      codeHolderOrganManagerIdx,
      year,
      month,
    }: AffiliateCodeOrderListParam): AffiliateCodeOrderSearchCondition => {
      const hasDateFilter = Boolean(year && month);
      const koreanTimezone = "Asia/Seoul";

      return {
        pageCondition: {
          page: page ?? DEFAULT_PAGE,
          size: DEFAULT_SIZE_IN_DETAIL,
        },
        query: code ? code : undefined,
        queryType: code ? OrderSearchWordType.AFFILIATE_CODE : undefined,
        orderSearchDateType: hasDateFilter ? OrderSearchDateType.PAID_AT : undefined,
        affiliateOrganManagerIdx: codeHolderOrganManagerIdx,
        startDate: hasDateFilter
          ? timeConverter.convertToUTCFromDate(timeConverter.getStartOfMonth(year!, month!, koreanTimezone))
          : undefined,
        endDate: hasDateFilter
          ? timeConverter.convertToUTCFromDate(timeConverter.getEndOfMonth(year!, month!, koreanTimezone))
          : undefined,
      };
    },
    [timeConverter]
  );

  const getOrderList = useCallback(
    async ({ page, code, codeHolderOrganManagerIdx, year, month }: AffiliateCodeOrderListParam) => {
      try {
        const result = await getOrders(getListParameters({ page, code, codeHolderOrganManagerIdx, year, month }));
        setList(result);
      } catch (e) {
        alert(defaultErrorMessage);
      }
    },
    [defaultErrorMessage, getOrders, getListParameters]
  );

  return { getOrderList, list, getListParameters };
}
