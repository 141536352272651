import { PanelWrapper } from "../../../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../../../layout/content/detail/Panel";
import style from "../popup.module.scss";
import React, { useCallback, useRef, useState } from "react";
import { pwdRegexStr } from "../../../../../util/regexpUtil";
import { callAsync } from "../../../../../util/sliceUtil";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { updatePasswordAsync } from "../../../../../store/myPageSlice";
import styled from "styled-components";
import { SupportTextStyle } from "../../../../../components/Texts";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";
import { PasswordInputBox } from "../../../../../components/PasswordInputBox";

const PasswordFormStyle = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const InputPasswordWrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const LabelStyle = styled.label`
  width: 150px !important;
`;

export function ChangingPassword() {
  const { t } = useTranslation("member");
  const dispatch = useAppDispatch();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { defaultErrorMessage } = useUtil();

  const formRef = useRef<HTMLFormElement>(null);

  const onClickChangePasswordButton = useCallback(() => {
    if (!formRef.current?.reportValidity()) return;

    if (newPassword !== confirmPassword) {
      alert(t("changePassword.incorrectInputValue"));
      return;
    }

    callAsync(
      dispatch(
        updatePasswordAsync({
          oldPwd: currentPassword,
          newPwd: newPassword,
        })
      ).unwrap(),
      () => {
        alert(t("changePassword.resultMessage"));
        window.close();
      },
      (error) => {
        if (error.errorCode.httpCode === 401) alert(t("changePassword.incorrectInputValue"));
        else {
          alert(defaultErrorMessage);
          console.dir(error);
        }
      }
    ).then();
  }, [dispatch, t, currentPassword, newPassword, confirmPassword, defaultErrorMessage]);

  return (
    <PanelWrapper>
      <Panel title={t("changePassword.title")} description={t("changePassword.description")}>
        <PasswordFormStyle ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <div className={style.inputArea}>
            <LabelStyle className={style.label}>{t("changePassword.currentPassword.label")}</LabelStyle>
            <InputPasswordWrapperStyle>
              <PasswordInputBox
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                className={style.input}
                required={true}
                placeholder={t("changePassword.currentPassword.placeholder")}
              />
            </InputPasswordWrapperStyle>
          </div>
          <div className={style.inputArea}>
            <LabelStyle className={style.label}>{t("changePassword.newPassword.label")}</LabelStyle>
            <InputPasswordWrapperStyle>
              <PasswordInputBox
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className={style.input}
                minLength={8}
                maxLength={16}
                required={true}
                pattern={pwdRegexStr}
                placeholder={t("changePassword.newPassword.placeholder")}
              />
              <SupportTextStyle>- {t("changePassword.newPassword.supportMessage")}</SupportTextStyle>
            </InputPasswordWrapperStyle>
          </div>
          <div className={style.inputArea}>
            <LabelStyle className={style.label}>{t("changePassword.confirmNewPassword.label")}</LabelStyle>
            <InputPasswordWrapperStyle>
              <PasswordInputBox
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={style.input}
                minLength={8}
                maxLength={16}
                required={true}
                pattern={pwdRegexStr}
                placeholder={t("changePassword.confirmNewPassword.placeholder")}
              />
            </InputPasswordWrapperStyle>
          </div>

          <div className={style.buttonArea}>
            <button onClick={onClickChangePasswordButton} className={style.blockButton}>
              {t("changePassword.buttonLabel")}
            </button>
          </div>
        </PasswordFormStyle>
      </Panel>
    </PanelWrapper>
  );
}
