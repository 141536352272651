import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { AdminApi } from "../api/AdminApi";
import { RootState } from "./index";
import { AdminListObj, AdminPutParam, AdminSearchCondition, ChangeRoleParam } from "../types/admin";
import { PageResponse } from "../types/page";

export interface AdminState extends SliceState {
  adminList?: PageResponse<AdminListObj>;
}

const initialState: AdminState = {
  status: Status.IDLE,
};

export const getAdminListAsync = createAsyncThunk("admin/getAdminList", (condition: AdminSearchCondition) =>
  executePromise(AdminApi.getAdminList(condition))
);

export const getAdminDetailAsync = createAsyncThunk("admin/getAdminDetail", (idx: number) =>
  executePromise(AdminApi.getAdminDetail(idx))
);

export const updateAdminAsync = createAsyncThunk(
  "admin/updateAdmin",
  ({ idx, adminPutParam }: { idx: number; adminPutParam: AdminPutParam }) =>
    executePromise(AdminApi.updateAdmin(idx, adminPutParam))
);

export const blockAdminsAsync = createAsyncThunk(
  "admin/blockAdmins",
  ({ adminIdxArr, blockReason }: { adminIdxArr: number[]; blockReason: string }) =>
    executePromise(AdminApi.blockAdmins(adminIdxArr, blockReason))
);

export const unblockAdminAsync = createAsyncThunk("admin/unblockAdmin", (adminIdx: number) =>
  executePromise(AdminApi.unblockAdmin(adminIdx))
);

export const authorizeAdminAsync = createAsyncThunk("admin/authorizeAdmin", (adminIdx: number) =>
  executePromise(AdminApi.authorizeAdmin(adminIdx))
);

export const changeRoleAsync = createAsyncThunk("admin/changeRole", (changeRoleParam: ChangeRoleParam) =>
  executePromise(AdminApi.changeRole(changeRoleParam))
);

export const issueTemporaryPassword = createAsyncThunk("admin/issueTemporaryPassword", (adminIdx: number) =>
  executePromise(AdminApi.issueTemporaryPassword(adminIdx))
);

export const resendSignUpConfirmEmailAsync = createAsyncThunk("admin/resendSignUpConfirmEmail", (adminIdx: number) =>
  executePromise(AdminApi.resendSignUpConfirmEmail(adminIdx))
);

export const deleteAdminAsync = createAsyncThunk("admin/deleteAdmin", (adminIdx: number) =>
  executePromise(AdminApi.deleteAdmin(adminIdx))
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminList: (state, action: PayloadAction<PageResponse<AdminListObj>>) => {
      state.adminList = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getAdminListAsync);
    addCases(builder, getAdminDetailAsync);
    addCases(builder, blockAdminsAsync);
    addCases(builder, unblockAdminAsync);
    addCases(builder, authorizeAdminAsync);
    addCases(builder, changeRoleAsync);
    addCases(builder, updateAdminAsync);
    addCases(builder, issueTemporaryPassword);
    addCases(builder, resendSignUpConfirmEmailAsync);
  },
});

export default adminSlice.reducer;

export const { setAdminList } = adminSlice.actions;

export const adminList = (state: RootState) => state.admin.adminList;
export const adminStatus = (state: RootState) => state.admin.status;
