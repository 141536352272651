import style from "./productEditForm.module.scss";
import {HrLine} from "../../../layout/content/post/HrLine";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {ProductEditStateValues} from "../hooks/useProductEditState";
import {ProductEditName} from "./fields/ProductEditName";
import {ProductEditDisplayPosition} from "./fields/ProductEditDisplayPosition";
import {ProductEditThumbnail} from "./fields/ProductEditThumbnail";
import {ProductEditRegularPrice} from "./fields/ProductEditRegularPrice";
import {ProductEditDiscount} from "./fields/ProductEditDiscount";
import {ProductEditSellingPrice} from "./fields/ProductEditSellingPrice";
import {ProductEditCouponUsable} from "./fields/ProductEditCouponUsable";
import {ProductEditSellingDate} from "./fields/ProductEditSellingDate";
import {ProductEditUnitItems} from "./fields/ProductEditUnitItems";
import {ProductEditDescription} from "./fields/ProductEditDescription";
import {ProductEditSpecialDescription} from "./fields/ProductEditSpecialDescription";
import {ProductEditDetailImages} from "./fields/ProductEditDetailImages";
import {DisplayPosition, ProductDiscountType, ProductImageType, ProductImageVOType} from "../../../types/product";
import {useProductDisplayPosition} from "../hooks/useProductDisplayPosition";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector, useLanguage} from "../../../hooks/hooks";
import {isProductDataSavedState, setIsProductDataSaved} from "../../../store/productSlice";
import {useNavigationBlocker} from "../../../hooks/navigationblocker/useNavigationBlocker";
import {ProductEditLanguage} from "./fields/ProductEditLanguage";

interface Props {
  state: ProductEditStateValues;
  isCreatePage: boolean;
}

export function ProductEditForm({ state, isCreatePage }: Props) {
  const { t } = useTranslation("product");
  const isGrantableProduct = useMemo(
    () => state.displayPosition === DisplayPosition.GRANTABLE_PRODUCT,
    [state.displayPosition]
  );
  const dispatch = useAppDispatch();
  const isProductDataSaved = useAppSelector(isProductDataSavedState);
  const { isKorean } = useLanguage();

  // 상품언어 속성이 보여야하는 케이스
    const visibleDisplay = [
        DisplayPosition.USER_WEB_KPASS,
        DisplayPosition.USER_WEB_DCAS,
        DisplayPosition.ORGAN_WEB_KPASS,
        DisplayPosition.ORGAN_WEB_DCAS
    ];

  useEffect(() => {
    let sellingPrice = "";

    const regularPrice = Number(state.regularPrice);
    const discountValue = Number(state.discountValue);

    switch (state.discountType) {
      case ProductDiscountType.NONE:
        sellingPrice = state.regularPrice;
        break;
      case ProductDiscountType.AMOUNT:
        sellingPrice = (regularPrice - discountValue).toString();
        break;
      case ProductDiscountType.PERCENT:
        sellingPrice = Math.floor(regularPrice - (regularPrice * discountValue) / 100).toString();
        break;
    }
    state.setSellingPrice(sellingPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.regularPrice, state.discountType, state.discountValue]);


  const [hasChangedValue, setHasChangedValue] = useState(false);
  const { isMobileImagesNeeded, isDesktopImagesNeeded } = useProductDisplayPosition();

  useNavigationBlocker({
    hasChangedValue,
    isDataSaved: isProductDataSaved,
    confirmMessage: t("promptConfirm"),
  });

  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      dispatch(setIsProductDataSaved(false));
      setHasChangedValue(true);
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  return (
    <>
      <div className={style.fieldLine}>
        <ProductEditName name={state.name} setName={(v) => changeValue(v, state.setName)} />
      </div>
      <div className={style.fieldLine}>
        <ProductEditDisplayPosition
          displayPosition={state.displayPosition}
          setDisplayPosition={(v) => changeValue(v, state.setDisplayPosition)}
          setLanguage={state.setLanguage}
          isCreatePage={isCreatePage}
        />
      </div>
        {visibleDisplay.includes(state.displayPosition) &&
        <div className={style.fieldLine}>
            <ProductEditLanguage language={state.language} setLanguage={(v) => changeValue(v, state.setLanguage)}
                                 setDisplayPosition={state.setDisplayPosition}
                                 displayPosition={state.displayPosition} />
        </div>
        }
      {isGrantableProduct ? (
        <></>
      ) : (
        <>
          <div className={style.fieldLine}>
            <ProductEditThumbnail
              thumbnail={state.thumbnail}
              setThumbnail={(v) => changeValue(v, state.setThumbnail)}
              addedThumbnailImage={state.addedThumbnailImage}
              setAddedThumbnailImage={(v) => changeValue(v, state.setAddedThumbnailImage)}
            />
          </div>
          <div className={style.fieldLine}>
            <ProductEditRegularPrice
              regularPrice={state.regularPrice}
              setRegularPrice={(v) => changeValue(v, state.setRegularPrice)}
              language={state.language}
            />
            {isKorean && (
              <ProductEditDiscount
                discountType={state.discountType}
                setDiscountType={(v) => changeValue(v, state.setDiscountType)}
                discountValue={state.discountValue ?? ""}
                setDiscountValue={(v) => changeValue(v, state.setDiscountValue)}
                language={state.language}
              />
            )}
          </div>
          {!isKorean && (
            <div className={style.fieldLine}>
              <ProductEditDiscount
                discountType={state.discountType}
                setDiscountType={(v) => changeValue(v, state.setDiscountType)}
                discountValue={state.discountValue ?? ""}
                setDiscountValue={(v) => changeValue(v, state.setDiscountValue)}
                language={state.language}
              />
            </div>
          )}
          <div className={style.fieldLine}>
            <ProductEditSellingPrice
              sellingPrice={state.sellingPrice}
              setSellingPrice={(v) => changeValue(v, state.setSellingPrice)}
              language={state.language}
            />
            <ProductEditCouponUsable
              isCouponUsable={state.isCouponUsable}
              setIsCouponUsable={(v) => changeValue(v, state.setIsCouponUsable)}
            />
          </div>
          <div className={style.fieldLine}>
            <ProductEditSellingDate
              sellingStartDate={state.sellingStartDate}
              setSellingStartDate={(v) => {
                changeValue(v, state.setSellingStartDate);
              }}
              sellingEndDate={state.sellingEndDate}
              setSellingEndDate={(v) => {
                changeValue(v, state.setSellingEndDate);
              }}
              isSellingAlways={state.isSellingAlways}
              setIsSellingAlways={state.setIsSellingAlways}
            />
          </div>
        </>
      )}
      <HrLine />
      <div className={style.fieldLine}>
        <ProductEditUnitItems
          productItems={state.productItems}
          isCreatePage={isCreatePage}
          addedUnitItems={state.addedUnitItems}
          setAddedUnitItems={(v) => changeValue(v, state.setAddedUnitItems)}
        />
      </div>
      <HrLine />
      {isGrantableProduct ? (
        <></>
      ) : (
        <>
          <div className={style.fieldLine}>
            <ProductEditDescription
              description={state.description}
              setDescription={(v) => changeValue(v, state.setDescription)}
            />
          </div>
          <div className={style.fieldLine}>
            <ProductEditSpecialDescription
              specialDescription={state.specialDescription}
              setSpecialDescription={(v) => changeValue(v, state.setSpecialDescription)}
            />
          </div>
          {isDesktopImagesNeeded(state.displayPosition) ? (
            <div className={`${style.fieldLine} ${style.lastField}`}>
              <ProductEditDetailImages
                detailImages={state.desktopImages}
                setDetailImages={(v) => changeValue(v, state.setDesktopImages)}
                addedDetailImages={state.addedDesktopImages}
                setAddedDetailImages={(v) => changeValue(v, state.setAddedDesktopImages)}
                imageVOType={ProductImageVOType.getProductImageVOType(ProductImageType.DESKTOP, state.displayPosition)}
              />
            </div>
          ) : (
            <></>
          )}
          {isMobileImagesNeeded(state.displayPosition) ? (
            <div className={`${style.fieldLine} ${style.lastField}`}>
              <ProductEditDetailImages
                detailImages={state.mobileImages}
                setDetailImages={(v) => changeValue(v, state.setMobileImages)}
                addedDetailImages={state.addedMobileImages}
                setAddedDetailImages={(v) => changeValue(v, state.setAddedMobileImages)}
                imageVOType={ProductImageVOType.getProductImageVOType(ProductImageType.MOBILE, state.displayPosition)}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}
