import {useCallback} from "react";
import {PageResponse} from "../../../../types/page";
import {BannerDto, BannerListVO, BannerSearchCondition} from "../../../../types/banner";
import {getBannerAsync} from "../../../../store/bannerSlice";
import {useAppDispatch} from "../../../../hooks/hooks";

export function useBannerGetter() {
    const dispatch = useAppDispatch();

    const getBanners = useCallback(
        async (condition: BannerSearchCondition) => {
            try {
                const result: PageResponse<BannerDto> = await dispatch(getBannerAsync(condition)).unwrap();
                return {
                    ...result,
                    content: result.content.map((dto) => ({ ...dto } as BannerListVO)),
                };
            } catch (e) {
                console.error(e);
                throw new Error("배너 목록 조회 에러");
            }
        },
        [dispatch]
    );
    return {
        getBanners
    };
}