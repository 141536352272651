import { ActionUserType } from "./dataCommon";
import { MemberUserDetailTabType } from "./user";
import { CustomerType } from "./common";

export enum NewWindow {
  USER_DETAIL = "/detail/member/user",
  ADMIN_DETAIL = "/detail/member/admin",
  ORGAN_MANAGER_DETAIL = "/detail/member/organ-manager",
  BLOCK_MEMBER = "/detail/member/block",
  CHANGING_PASSWORD = "/detail/member/admin/password",
  ADMIN_ROLE = "/detail/member/admin/role",
  GRANT_COUPON = "/detail/coupon/grant",
  GRANT_PRODUCT = "/detail/product/grant",
  UNIT_ITEM_CREATOR = "/detail/unit-item/new",
  PRODUCT_DETAIL = "/detail/product",
  ORDER_DETAIL = "/detail/order",
  COLLECT_PRODUCT = "/detail/product/collect",
  UNIT_ITEM_PRODUCTS = "/detail/unit-item/:id/products",
  SAMPLE = "/detail/sample",
  REFUND = "/detail/order/:id/refund",
  REFUND_ACCOUNT = "/detail/order/refund-account",
  ORGAN_MANAGER_GRANT_VOUCHER = "/detail/organ-manager/grant-voucher",
  CREATED_PROFILE_DETAIL = "/detail/member/tester",
  PARTNER_COMPANY_DETAIL = "/detail/partner",
  GROUP_MEMBER = "/member/organ-member/post"
}

export enum DetailBarInfoCategory {
  ADMIN = "ADMIN",
  USER = "USER",
  ORGAN_MANAGER = "ORGAN_MANAGER",
}

export interface DetailBarInfo {
  category: DetailBarInfoCategory;
  name: string;
}

export interface MemberUserParam extends DetailBarInfo {
  type: MemberUserDetailTabType;
  isLeaved: boolean;
}

export interface BlockMemberParam {
  userType: ActionUserType;
  memberIdxArr: number[];
}

export interface AdminRoleParam {
  adminIdx: number;
  roleIdx: number;
}

export interface GrantCouponParam {
  targetIdx: number;
  targetName?: string;
  customerType?: CustomerType;
}

export interface GrantProductParam {
  targetIdxes: number[];
  targetType: CustomerType;
  targetName?: string;
}

export interface CollectProductParam {
  voucherIdx: number;
  userIdx: number;
  userName: string;
}

export interface RefundAccountParam {
  customerIdx: number;
  customerType: CustomerType;
  bankName: string;
  accountNumber: string;
  accountName: string;
}

export interface OrganManagerGrantVoucherParam {
  organIdx: number;
  organName: string;
  organMemberIdxes: number[];
}

export interface AffiliateCodeDurationParam {
  codeIdx: number;
  organManagerName: string;
  code: string;
  startAt: string;
  endAt: string | null;
}

export interface OpenNewWindowParam {
  idx?: number;
  param?: object;
  width: number;
  height: number;
}

export enum WindowMessage {
  ADMIN_LOGOUT = "adminLogout",
  BLOCK_MEMBER = "refreshFromBlockMember",
  COLLECT_VOUCHER = "refreshFromCollectVoucher",
  CREATE_UNIT_ITEM = "refreshFromCreateUnitItem",
  ADMIN_ROLE = "refreshFromAdminRole",
  UPDATE_PRODUCT = "refreshFromProductDetail",
  GRANT_PRODUCT = "refreshFromGrantProduct",
  GRANT_COUPON = "refreshFromGrantCoupon",
  REFUND_ACCOUNT = "refreshRefundAccount",
  CREATED_PROFILE = "refreshCreatedProfile",
  REFUNDER = "refreshFromRefunder",
  USER = "refreshFromUser",
  ORGAN_MANAGER_GRANT_VOUCHER = "refreshFromOrganManagerGrantVoucher",
  PARTNER_COMPANY = "refreshFromPartnerCompany",
  AFFILIATE_CODE_DURATION = "refreshFromAffiliateCodeDuration",
  POPUP = "refreshFromPopup",
  BANNER = "refreshFromBanner",
}
