import React from "react";
import { AccessibleMenu } from "../../types/adminRole";

export interface AccessibleMenuContextType {
  accessibleMenus: AccessibleMenu[];
}

export const AccessibleMenuContext = React.createContext<AccessibleMenuContextType>({
  accessibleMenus: [],
});
