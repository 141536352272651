import { SelectorOption } from "../../../types/selector";
import { useSelector } from "./useSelector";
import {
  OptionItem,
  RoundedSelectAreaStyle,
  RoundedSelectedItemStyle,
  RoundedSelectorArrowStyle,
  RoundedSelectorOptionsStyle,
  RoundedSelectorWrapperStyle,
} from "../../../components/SelectorStyle";
import React, { useMemo } from "react";
import arrow from "../../../assets/images/select_arrow.svg";
import arrowWhite from "../../../assets/images/select_arrow_white.svg";
import { SELECTOR_AREA } from "../../../util/etcUtil";

interface Props {
  options: SelectorOption[];
  selectedValue: string;
  setSelectorOption: (_: SelectorOption) => void;
  selectedValueAreaStyle?: React.CSSProperties;
  selectedValueStyle?: React.CSSProperties;
  optionsAreaStyle?: React.CSSProperties;
  isWhiteVersion?: boolean;
}
export function RoundSelector({
  options,
  selectedValue,
  setSelectorOption,
  selectedValueAreaStyle,
  selectedValueStyle,
  optionsAreaStyle,
  isWhiteVersion,
}: Props) {
  const { selectorRef, onClickSelector, onClickOption, selectedOption, showOptions } = useSelector({
    options,
    selectedValue,
    setSelectorOption,
  });

  const whiteVersionClassName = useMemo(() => (isWhiteVersion ? "white" : ""), [isWhiteVersion]);

  return (
    <RoundedSelectorWrapperStyle>
      <RoundedSelectAreaStyle
        data-name={SELECTOR_AREA}
        onClick={onClickSelector}
        ref={selectorRef}
        style={selectedValueAreaStyle}
      >
        <RoundedSelectedItemStyle
          className={`${selectedOption?.value ? "" : "none"} ${whiteVersionClassName}`}
          style={selectedValueStyle}
        >
          {selectedOption?.label}
        </RoundedSelectedItemStyle>
        <RoundedSelectorArrowStyle src={isWhiteVersion ? arrowWhite : arrow} alt={""} />
      </RoundedSelectAreaStyle>

      <RoundedSelectorOptionsStyle className={`${showOptions ? "" : "hide"}`} style={optionsAreaStyle}>
        {options.map((option, idx) => (
          <OptionItem
            className={"rounded"}
            key={`option-${option.value}-${idx}`}
            data-value={option.value}
            onClick={() => onClickOption(option)}
          >
            {option.label}
          </OptionItem>
        ))}
      </RoundedSelectorOptionsStyle>
    </RoundedSelectorWrapperStyle>
  );
}
