import React, { useCallback, useRef, useState } from "react";
import { Anchor } from "../../components/Anchor";
import { MINI_MODAL_LINK } from "../../util/etcUtil";
import { ProductSummaryModal } from "../component/modal/ProductSummaryModal";
import { ModalWrapperStyle } from "../../components/Modal";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const ProductSummaryStyle = styled.div`
  position: relative;
  width: fit-content;
  margin: 0 auto;
`;

interface Props {
  productIdx?: number;
}

export function ProductSummaryViewer({ productIdx }: Props) {
  const { t } = useTranslation("product");
  const [showProductInfo, setShowProductInfo] = useState(false);
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const onClickAnchor = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setShowProductInfo(true);
  }, []);

  return (
    <>
      {productIdx ? (
        <ProductSummaryStyle>
          <ModalWrapperStyle>
            <ProductSummaryModal
              isShow={showProductInfo}
              callbackCloseModal={() => setShowProductInfo(false)}
              productIdx={productIdx}
              linkElement={linkRef.current ?? undefined}
            />
          </ModalWrapperStyle>
          <Anchor
            onClick={onClickAnchor}
            href={"#"}
            rel="noopener noreferrer"
            ref={linkRef}
            data-name={MINI_MODAL_LINK}
          >
            {t("viewAppliedProducts")}
          </Anchor>
        </ProductSummaryStyle>
      ) : (
        <></>
      )}
    </>
  );
}
