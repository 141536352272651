import { OrganVoucherHistoryCategory, OrganVoucherHistorySetListDTO } from "../../../../../../../types/voucher";
import { ActionButton } from "../../../../../../../components/Buttons";
import React, { useRef, useState } from "react";
import { MiniModal } from "../../../../../../../layout/modal/MiniModal";
import styled from "styled-components";
import { TableStyle } from "../../../../../../../components/styled/TableStyle";
import { useVoucherHistoriesGetter } from "../hooks/useVoucherHistoriesGetter";
import { useLoader } from "../../../../../../../hooks/common/useLoader";
import { useAppSelector } from "../../../../../../../hooks/hooks";
import { voucherStatus } from "../../../../../../../store/voucherSlice";
import { MINI_MODAL_LINK } from "../../../../../../../util/etcUtil";
import { useTranslation } from "react-i18next";

const ColumnWrap = styled.div`
  position: relative;
`;

interface Props {
  historySet: OrganVoucherHistorySetListDTO;
}

export function OrganManagerVoucherRelatedActionColumn({ historySet }: Props) {
  const { t } = useTranslation("organManager");
  const category = historySet.organVoucherHistoryCategory;
  const [isShow, setIsShow] = useState<boolean>(false);
  const { histories, getHistories } = useVoucherHistoriesGetter();
  const status = useAppSelector(voucherStatus);
  useLoader({ status });
  const linkRef = useRef<HTMLButtonElement | null>(null);

  return (
    <ColumnWrap>
      {category === OrganVoucherHistoryCategory.GRANTED || category === OrganVoucherHistoryCategory.COLLECTED ? (
        <>
          <ActionButton
            onClick={(_) => getHistories(historySet.idx).then(() => setIsShow(true))}
            data-name={MINI_MODAL_LINK}
            ref={linkRef}
          >
            {t("detail.couponAndVoucher.historyList.seeMore")}
          </ActionButton>

          <MiniModal
            show={isShow}
            closeModal={() => setIsShow(false)}
            title={t("detail.couponAndVoucher.historyList.seeMore")}
            modalWidth={"302px"}
            modalLeftPosition={"-137px"}
            linkElement={linkRef.current ?? undefined}
          >
            <TableStyle>
              <thead>
                <tr>
                  <th>No</th>
                  <th>{t("detail.couponAndVoucher.historyList.grantedMemberName")}</th>
                </tr>
              </thead>
              {histories && (
                <tbody>
                  {histories.map((history, idx) => (
                    <tr key={history.idx}>
                      <td>{idx + 1}</td>
                      <td>{history.profileName}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </TableStyle>
          </MiniModal>
        </>
      ) : (
        <></>
      )}
    </ColumnWrap>
  );
}
