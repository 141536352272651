import { OrganManagerListDTO, OrganManagerSearchCondition } from "../../../../types/organManager";
import { ordersToSortArr, PageResponse } from "../../../../types/page";
import { organManagerList, searchOrganManagersAsync, setOrganManagerList } from "../../../../store/organManagerSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useMemo } from "react";

interface Args {
  setTotalOrganManagerCount?: (_: number) => void;
  onCompletedCallback?: () => void;
}
export function useOrganManagersGetter({ setTotalOrganManagerCount, onCompletedCallback }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const list = useAppSelector(organManagerList);

  const pageCondition = useMemo(
    () => ({
      page: list?.number ?? 0,
      size: list?.size ?? 20,
      sort: ordersToSortArr(list?.orders),
    }),
    [list]
  );
  const getOrganManagerList = async (param: OrganManagerSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<OrganManagerListDTO> = await dispatch(searchOrganManagersAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalOrganManagerCount?.(result.totalElements);
      } else {
        dispatch(setOrganManagerList(result));
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  return {
    getOrganManagerList,
    list,
    pageCondition,
  };
}
