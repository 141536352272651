import { DisplayPosition, ProductDiscountType, ProductSummaryDetail } from "./product";
import { CustomerType } from "./common";
import { SearchCondition } from "./page";
import { PaymentWay } from "./payment";

enum OrderStatus {
  AWAITING_PAYMENT = "AWAITING_PAYMENT",
  AWAITING_DEPOSIT = "AWAITING_DEPOSIT",
  PAID = "PAID",
  FAIL = "FAIL",
  CANCELLED = "CANCELLED",
  PARTIALLY_CANCELLED = "PARTIALLY_CANCELLED",
  OUTSTANDING_PAYMENT = "OUTSTANDING_PAYMENT",
}

export enum RefundType {
  PG = "PG",
  CASH = "CASH",
  PAYPAL = "PAYPAL",
}

export interface OrdersDTO {
  idx: number;
  orderTitle: string;
  orderImage: string;
  displayPosition: DisplayPosition;
  status: OrderStatus;
  customerType: CustomerType;
  buyerIdx: number;
  buyerName: string;
  paymentWay: PaymentWay;
  regularPriceAmount: number;
  couponDiscountAmount: number;
  productDiscountAmount: number;
  affiliateCodeDiscountAmount: number;
  payAmount: number;
  orderQuantity: number;
  failReason: string;
  createAt: string;
  vbankName: string;
  vbankAccountNumber: string;
  vbankDepositDeadline: string;
  paidAt: string;
  hasMemo: boolean;
  affiliateCode: string;
  affiliateCodeIdx: number;
  organManagerIdx: number;
}

export interface OrdersVO {
  idx: number;
  orderTitle: string;
  orderImage: string;
  displayPosition: DisplayPosition;
  status: OrderStatus;
  customerType: CustomerType;
  buyerIdx: number;
  buyerName: string;
  paymentWay: PaymentWay;
  regularPriceAmount: number;
  couponDiscountAmount: number;
  productDiscountAmount: number;
  salesCodeDiscountAmount: number;
  payAmount: number;
  failReason: string;
  createAt: string;
  paidAt: string | undefined;
  vbankName: string | undefined;
  vbankAccountNumber: string | undefined;
  vbankDepositDeadline: string | undefined;
  hasMemo: boolean;
  affiliateCode: string;
  affiliateCodeIdx: number;
}

export interface OrderListVO {
  idx: number;
  orderTitle: string;
  orderImage: string;
  displayPosition: DisplayPosition;
  status: OrderStatus;
  customerType: CustomerType;
  buyerIdx: number;
  buyerName: string;
  paymentWay: PaymentWay;
  regularPriceAmount: number;
  payAmount: number;
  createAt: string;
  paidAt: string;
  hasMemo: boolean;
  affiliateCode: string;
  affiliateCodeIdx: number;
  organManagerIdx: number;
}

export interface OrderItemDTO {
  idx: number;
  productIdx: number;
  status: OrderStatus;
  product: ProductSummaryDetail;
  productName: string;
  productImage: string;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue: number;
  sellingPrice: number;
  quantity: number;
  refundQuantity: number;
  couponHistoryIdx: number;
  couponDiscountPrice: number;
  couponName: string;
}

export interface OrderItemVO {
  idx: number;
  productIdx: number;
  status: OrderStatus;
  productName: string;
  productImage: string;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue: number;
  sellingPrice: number;
  quantity: number;
  refundQuantity: number;
  couponDiscountPrice: number;
  couponName: string;
}

export interface BuyerDTO {
  idx: number;
  name: string;
  email: string;
  codePhone: string;
  phoneNumber: string;
}

export interface BuyerVO {
  idx: number;
  name: string;
  email: string;
  codePhone: string;
  phoneNumber: string;
}

export interface RefundAccountVO {
  idx: number;
  customerType: CustomerType;
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
}

export enum OrderSearchDateType {
  ORDERED_AT = "ORDERED_AT",
  PAID_AT = "PAID_AT",
}

export enum OrderSearchWordType {
  ORDERER_NAME = "ORDERER_NAME",
  SALES_DISCOUNT_CODE = "SALES_DISCOUNT_CODE",
  AFFILIATE_CODE = "AFFILIATE_CODE",
}

interface OrderSearchConditionCommonPart extends SearchCondition {
  query?: string;
  queryType?: OrderSearchWordType;
  orderSearchDateType?: OrderSearchDateType;
  paymentWay?: PaymentWay | "";
  status?: OrderStatus | "";
  displayPosition?: DisplayPosition | "";
  organManagerIdx?: number;
  userIdx?: number;
}

export interface OrderSearchCondition extends OrderSearchConditionCommonPart {
  startDate?: Date;
  endDate?: Date;
}

export interface AffiliateCodeOrderSearchCondition extends OrderSearchConditionCommonPart {
  startDate?: string;
  endDate?: string;
  affiliateOrganManagerIdx: number;
}

export enum OrderDetailTabMenu {
  BUYER = "BUYER",
  ORDER_ITEM = "ORDER_ITEM",
  PAYMENT = "PAYMENT",
  PAYMENT_WAY = "PAYMENT_WAY",
  CASH_RECEIPT = "CASH_RECEIPT",
  TAX_BILL = "TAX_BILL",
  REFUND = "REFUND",
  CS = "CS",
  MEMO = "MEMO",
}

export interface RefundDTO {
  idx: number;
  orderIdx: number;
  refundPrice: number;
  refundMethod: string;
  refundReason: string;
  createAt: string;
}

export interface RefundVO {
  idx: number;
  orderIdx: number;
  refundPrice: number;
  refundMethod: string;
  refundReason: string;
  createAt: string;
}

export interface RefundItemDTO {
  idx: number;
  refundIdx: number;
  orderItemIdx: number;
  productName: string;
  productImage: string;
  quantity: number;
  createAt: string;
}

export interface RefundItemVO {
  idx: number;
  refundIdx: number;
  orderItemIdx: number;
  productName: string;
  productImage: string;
  quantity: number;
  createAt: string;
}

export interface RefundRequestDTO {
  refundOrderItems: RefundItemRequestDTO[];
  refundPrice: number;
  refundMethod: RefundType;
  refundReason: string;
}

export interface RefundItemRequestDTO {
  orderItemIdx: number;
  quantity: number;
}

export namespace OrderTranslator {
  export function createRefundVO(refundDTO: RefundDTO): RefundVO {
    return refundDTO as RefundVO;
  }

  export function createRefundItemVO(refundItemDTO: RefundItemDTO): RefundItemVO {
    return refundItemDTO as RefundItemVO;
  }

  export function createOrdersVO(ordersDTO: OrdersDTO): OrdersVO {
    return {
      idx: ordersDTO.idx,
      orderTitle: ordersDTO.orderTitle,
      orderImage: ordersDTO.orderImage,
      displayPosition: ordersDTO.displayPosition,
      status: ordersDTO.status,
      customerType: ordersDTO.customerType,
      buyerIdx: ordersDTO.buyerIdx,
      buyerName: ordersDTO.buyerName,
      paymentWay: ordersDTO.paymentWay,
      regularPriceAmount: ordersDTO.regularPriceAmount,
      couponDiscountAmount: ordersDTO.couponDiscountAmount,
      productDiscountAmount: ordersDTO.productDiscountAmount,
      payAmount: ordersDTO.payAmount,
      failReason: ordersDTO.failReason,
      createAt: ordersDTO.createAt,
      hasMemo: ordersDTO.hasMemo,
      salesCodeDiscountAmount: ordersDTO.affiliateCodeDiscountAmount,
      affiliateCode: ordersDTO.affiliateCode,
      affiliateCodeIdx: ordersDTO.affiliateCodeIdx,
      paidAt: ordersDTO.paidAt === null ? undefined : ordersDTO.paidAt,
      vbankName: ordersDTO.vbankName === null ? undefined : ordersDTO.vbankName,
      vbankAccountNumber: ordersDTO.vbankAccountNumber === null ? undefined : ordersDTO.vbankAccountNumber,
      vbankDepositDeadline: ordersDTO.vbankDepositDeadline === null ? undefined : ordersDTO.vbankDepositDeadline,
    };
  }

  export function createOrderItemVO(orderItemDTO: OrderItemDTO): OrderItemVO {
    return {
      idx: orderItemDTO.idx,
      productIdx: orderItemDTO.productIdx,
      status: orderItemDTO.status,
      productName: orderItemDTO.productName,
      productImage: orderItemDTO.productImage,
      regularPrice: orderItemDTO.regularPrice,
      discountType: orderItemDTO.discountType,
      discountValue: orderItemDTO.discountValue,
      sellingPrice: orderItemDTO.sellingPrice,
      quantity: orderItemDTO.quantity,
      refundQuantity: orderItemDTO.refundQuantity,
      couponDiscountPrice: orderItemDTO.couponDiscountPrice,
      couponName: orderItemDTO.couponName,
    };
  }
}

export { OrderStatus };
