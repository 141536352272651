import { useAppSelector } from "../../../hooks/hooks";
import { contentDetailCategoryState, contentDetailNameState } from "../../../store/contentDetailSlice";

interface ReturnType {
  name: string;
  category: string;
}

export function useContentDetailHeaderInfoGetter(): ReturnType {
  const name = useAppSelector(contentDetailNameState);
  const category = useAppSelector(contentDetailCategoryState);

  return {
    name,
    category,
  };
}
