import React from "react";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";
import {AdditionalResultType} from "../../../../types/common";
import {RadioGroupField} from "../../../../components/RadioGroupField";

interface Props {
    kpassAdditionalResult: AdditionalResultType;
    setKpassAdditionalResult: (_: AdditionalResultType) => void;
}

export function OrganManagerKpassAdditionalResult({kpassAdditionalResult, setKpassAdditionalResult}: Props) {
    const {toStringAdditionalType} = useOrganManagerType();

    const getLabel = (value: string) => {
        return toStringAdditionalType(value as AdditionalResultType);
    };

    const testResultFormValues = [AdditionalResultType.NONE];

    return (
        <RadioGroupField enumValues={testResultFormValues}
                         getLabel={getLabel}
                         value={kpassAdditionalResult} setValue={setKpassAdditionalResult}
                         groupName={"KpassAdditionalResult"} isRequired={true}
        />
    );
}