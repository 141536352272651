import { useEffect, useMemo, useRef, useState } from "react";
import { CLICK_DOCUMENT_EVENT } from "../../../util/events";
import { useClickEvent } from "../../../hooks/hooks";
import { SelectorOption } from "../../../types/selector";
import { SELECTOR_AREA } from "../../../util/etcUtil";

interface Args {
  options: SelectorOption[];
  selectedValue: string;
  setSelectorOption: (_: SelectorOption) => void;
}

export function useSelector({ options, selectedValue, setSelectorOption }: Args) {
  const selectorRef = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);
  const selectedOption = useMemo(() => options.find((o) => o.value === selectedValue), [selectedValue, options]);

  const onClickOption = (option: SelectorOption) => setSelectorOption(option);
  const onClickSelector = () => {
    setShowOptions(!showOptions);
  };

  const { subscribe, unsubscribe } = useClickEvent();

  useEffect(() => {
    const clickDocumentListener = (e: { detail: any }) => {
      if (showOptions && e.detail.closest(`div[data-name='${SELECTOR_AREA}']`) !== selectorRef.current) {
        setShowOptions(false);
      }
    };

    subscribe(CLICK_DOCUMENT_EVENT, clickDocumentListener);

    return () => {
      unsubscribe(CLICK_DOCUMENT_EVENT, clickDocumentListener);
    };
  }, [showOptions, subscribe, unsubscribe]);

  return {
    showOptions,
    selectorRef,
    onClickOption,
    selectedOption,
    onClickSelector,
  };
}
