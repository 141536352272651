import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {
  getOrganManagerVoucherStatusAsync,
  organManagerVoucherStatusDTO,
  setOrganManagerVoucherStatusDTO,
} from "../../../../../../store/voucherSlice";
import {useCallback} from "react";
import {callAsync} from "../../../../../../util/sliceUtil";
import {OrganManagerVoucherStatusDTO} from "../../../../../../types/voucher";
import {useUtil} from "../../../../../../util/hooks/useUtil";

export function useOrganManagerVoucherStatusGetter(organMangerIdx: number) {
  const dispatch = useAppDispatch();
  const voucherStatus = useAppSelector(organManagerVoucherStatusDTO);
  const { defaultErrorMessage } = useUtil();
  const getOrganMangerVoucherStatus = useCallback(() => {
    callAsync<OrganManagerVoucherStatusDTO>(
      dispatch(getOrganManagerVoucherStatusAsync(organMangerIdx)).unwrap(),
      (result) => {
        dispatch(setOrganManagerVoucherStatusDTO(result));
      },
      () => alert(defaultErrorMessage)
    ).then();
  }, [dispatch, organMangerIdx, defaultErrorMessage]);

  return {
    organMangerVoucherStatus: voucherStatus,
    getOrganMangerVoucherStatus,
  };
}
