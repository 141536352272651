import { useAppDispatch } from "../../../../../hooks/hooks";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../../types/page";
import { OrganVoucherListDTO, OrganVoucherListVO, VoucherHistorySearchCondition } from "../../../../../types/voucher";
import { useMemo, useState } from "react";
import { callAsync } from "../../../../../util/sliceUtil";
import { getOrganVoucherHistoryListAsync } from "../../../../../store/voucherSlice";
import { useUtil } from "../../../../../util/hooks/useUtil";

const LIST_SIZE = 5;

interface Args {
  userIdx: number;
  isAvailable: boolean;
}

export function useUserOrganVoucherInfoList({ userIdx, isAvailable }: Args) {
  const dispatch = useAppDispatch();
  const [organVoucherHistoryList, setOrganVoucherHistoryList] = useState<PageResponse<OrganVoucherListVO>>();
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: organVoucherHistoryList?.number ?? 0,
      size: LIST_SIZE,
      sort: ordersToSortArr(organVoucherHistoryList?.orders),
    }),
    [organVoucherHistoryList]
  );

  const getOrganVoucherHistoryList = ({ page, sort }: PageSearchCondition) => {
    const param: VoucherHistorySearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: LIST_SIZE,
        sort: sort ?? pageCondition.sort,
      },
      userIdx,
      usable: isAvailable,
    };

    return () => {
      callAsync<PageResponse<OrganVoucherListDTO>>(
        dispatch(getOrganVoucherHistoryListAsync(param)).unwrap(),
        (result: PageResponse<OrganVoucherListDTO>) => {
          setOrganVoucherHistoryList({
            ...result,
            content: result.content.map((dto) => OrganVoucherListDTO.toOrganVoucherListVO(dto)),
          });
        },
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  return {
    pageCondition,
    getOrganVoucherHistoryList,
    organVoucherHistoryList,
  };
}
