import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { ListObject } from "../../types/common";
import { DEFAULT_SIZE_IN_SUB_TABLE } from "../../types/page";

export function useUtil() {
  const { t } = useTranslation("common");

  const defaultErrorMessage = useMemo(() => t("defaultErrorMessage"), [t]);
  const wantToDeleteImage = useMemo(() => t("wantToDeleteImage"), [t]);

  const getDefaultPageResponseObject = useCallback(<T extends ListObject>(content: T[]) => {
    return {
      totalPages: 1,
      totalElements: 1,
      number: 0,
      size: DEFAULT_SIZE_IN_SUB_TABLE,
      numberOfElements: 1,
      first: true,
      last: true,
      empty: false,
      orders: [],
      pageable: {
        offset: 0,
        sort: {
          sorted: false,
          unsorted: true,
          empty: false,
        },
        pageNumber: 0,
        pageSize: DEFAULT_SIZE_IN_SUB_TABLE,
        paged: true,
        unpaged: false,
      },
      content,
    };
  }, []);

  return {
    defaultErrorMessage,
    wantToDeleteImage,
    getDefaultPageResponseObject,
  };
}
