import { UserStatus } from "../../../../types/user";
import { ProfileQueryType } from "../../../../types/userProfile";
import { FilterContentType, TableFilter } from "../../../../types/tableFilter";
import { useCountry, useLanguage, useListFilterValues, useTimeConverter } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useUserType } from "../../../../types/hooks/useUserType";
import {filterAllowedCountriesCode} from "../../../../util/etcUtil";

interface UseTesterFiltersReturnType {
  filters: TableFilter[][];
}

interface Args {
  search: () => void;
}

export function useTesterFilters({ search }: Args): UseTesterFiltersReturnType {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation(["tester", "common"]);
  const { countryOptionsWithAll } = useCountry();
  const filteredOptions = filterAllowedCountriesCode(
      {countries:countryOptionsWithAll, withEmpty:true}
  );
  const { timeConverter } = useTimeConverter();
  const { userStatusOptions } = useUserType();
  const { isKorean: isKoreanLanguage } = useLanguage();

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.searchWord.label"),
        content: {
          type: FilterContentType.TEXT,
          value: filterValues.searchWordFilterValue.value,
          maxLength: 100,
          placeholderVal: t("list.filter.searchWord.placeholder"),
          onChangeFunc: (v) => filterValues.searchWordFilterValue.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: filterValues.searchWordTypeFilterValue.value,
            options: [
              { value: ProfileQueryType.NAME, label: t("list.filter.queryType.name") },
              { value: ProfileQueryType.USER_CODE, label: t("list.filter.queryType.userCode") },
              { value: ProfileQueryType.PHONE_NUMBER, label: t("list.filter.queryType.phoneNumber") },
              { value: ProfileQueryType.REGION, label: t("list.filter.queryType.region") },
            ],
            onChangeFunc: (v) => filterValues.searchWordTypeFilterValue.setter(v as ProfileQueryType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.createdAt.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(filterValues.createdAtFromFilterValue.value),
            onChangeFunc: (v) =>
              filterValues.createdAtFromFilterValue.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(filterValues.createdAtToFilterValue.value),
            onChangeFunc: (v) =>
              filterValues.createdAtToFilterValue.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
          },
        },
      },
      {
        labelTitle: t("list.filter.status"),
        content: {
          type: FilterContentType.SELECT,
          value: filterValues.statusFilterValue.value,
          options: userStatusOptions,
          onChangeFunc: (v) => filterValues.statusFilterValue.setter(v as UserStatus),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.filter.age.label"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: filterValues.ageFromFilterValue.value,
            min: 0,
            max: 100,
            onChangeFunc: (v) => filterValues.ageFromFilterValue.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: filterValues.ageToFilterValue.value,
            min: 0,
            max: 100,
            onChangeFunc: (v) => filterValues.ageToFilterValue.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.nationality"),
        content: {
          type: FilterContentType.SELECT,
          value: filterValues.nationalityFilterValue.value,
          options: filteredOptions,
          onChangeFunc: (v) => filterValues.nationalityFilterValue.setter(v),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.filter.testCount.label"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: filterValues.testCntFromFilterValue.value,
            min: 1,
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
            onChangeFunc: (v) => filterValues.testCntFromFilterValue.setter(v),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: filterValues.testCntToFilterValue.value,
            min: 1,
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
            onChangeFunc: (v) => filterValues.testCntToFilterValue.setter(v),
          },
        },
      },
    ],
  ];

  return {
    filters,
  };
}
