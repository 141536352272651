import { SearchCondition } from "./page";
import { ListObject } from "./common";
import { ActionUser } from "./dataCommon";
import { DisplayPosition, ProductStatus } from "./product";

enum UnitItemType {
  DEFAULT = "DEFAULT",
  KPASS_VOUCHER = "KPASS_VOUCHER",
  DCAS_VOUCHER = "DCAS_VOUCHER",
  CONSULTING_VOUCHER = "CONSULTING_VOUCHER",
  // GOODS = "GOODS"
}

export enum ValidDurationFrom {
  ORDERED_DATE = "ORDERED_DATE",
  TEST_COMPLETED_DATE = "TEST_COMPLETED_DATE",
}

interface UnitItemPostParam {
  name: string;
  type: UnitItemType;
  validDurationFrom: ValidDurationFrom;
  validDuration: number;
}

interface UnitItemSearchCondition extends SearchCondition {
  q?: string;
  type?: UnitItemType;
  createdAtFrom?: Date;
  createdAtTo?: Date;
  productCntFrom?: number;
  productCntTo?: number;
}

interface UnitItemListObj extends ListObject {
  name: string;
  type: UnitItemType;
  createAt: string;
  productItemCnt: number;
  salesCnt: number;
  validDurationFrom: ValidDurationFrom;
  validDuration: number;
}

interface ProductTypeDetail {
  code: UnitItemType;
  name: string;
}

interface UnitItemDetail {
  idx: number;
  name: string;
  type: ProductTypeDetail;
  isDeleted: boolean;
  validDurationFrom: ValidDurationFrom;
  validDuration: number;
  salesCnt: number;
  productItemCnt: number;
  createBy: ActionUser;
  createAt: string;
}

interface LinkedProductListObj extends ListObject {
  name: string;
  sellingStartDate: string;
  sellingEndDate: string;
  isCouponUsable: boolean;
  status: ProductStatus;
  regularPrice: number;
  sellingPrice: number;
  thumbnailUri: string;
  displayPosition: DisplayPosition;
}

export interface UnitItemSummary {
  idx: number;
  name: string;
  isDeleted: boolean;
  validDurationFrom: ValidDurationFrom;
  validDuration: number;
  type: UnitItemType;
}

export type {
  UnitItemPostParam,
  UnitItemSearchCondition,
  UnitItemListObj,
  UnitItemDetail,
  ProductTypeDetail,
  LinkedProductListObj,
};
export { UnitItemType };
