import { SelectorOption } from "../../../types/selector";
import { useMemo } from "react";
import { RoundSelector } from "./RoundSelector";
import { useCountry, useTimeConverter } from "../../../hooks/hooks";

interface Props {
  isWhiteVersion?: boolean;
}
export function TimeZoneSelector({ isWhiteVersion = false }: Props) {
  const { timeConverter, timezone, setTimezone } = useTimeConverter();
  const { countries } = useCountry();
  const timeZoneCountry = useMemo(() => ["GB", "KR", "AZ"], []);

  const timeZoneOptions: SelectorOption[] = useMemo(
    () =>
      countries
        .filter((c) => timeZoneCountry.includes(c.codeISOAlpha2))
        .map((c) => ({
          label: `${c.timeZone} ${timeConverter.getTimezoneFormat(c.timeZone)}`,
          value: c.timeZone,
        })),
    [countries, timeConverter, timeZoneCountry]
  );

  return (
    <RoundSelector
      isWhiteVersion={isWhiteVersion}
      options={timeZoneOptions}
      selectedValue={timezone}
      setSelectorOption={(tz) => setTimezone(tz.value)}
      selectedValueStyle={{
        whiteSpace: "nowrap",
        overflowX: "hidden",
      }}
      selectedValueAreaStyle={{
        width: "200px",
      }}
      optionsAreaStyle={{
        width: "fit-content",
        minWidth: "200px",
        whiteSpace: "nowrap",
      }}
    />
  );
}
