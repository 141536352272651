import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function Home() {
  const navigate = useNavigate();

  useEffect(
    () => navigate(`/member/user`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return <></>;
}
