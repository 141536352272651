import { callAsync } from "../../../../util/sliceUtil";
import { unblockOrganManagerAsync } from "../../../../store/organManagerSlice";
import { useAppDispatch } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useUnblockOrganMember() {
  const { t } = useTranslation("organManager");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return (organManagerIdx: number, onRefresh?: () => void) => {
    if (!window.confirm(t("unblock.confirm"))) return;

    callAsync(
      dispatch(unblockOrganManagerAsync(organManagerIdx)).unwrap(),
      () => {
        onRefresh?.();
        alert(t("unblock.result"));
      },
      () => alert(defaultErrorMessage)
    ).then();
  };
}
