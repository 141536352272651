import {SelectorOption} from "../types/selector";
import {
    OptionItem,
    OrganMemberPhoneOptions,
    OrganMemberSelectAreaStyle,
    OrganMemberSelectedItemStyle,
    OrganMemberSelectorArrowStyle,
    SelectorHiddenInputStyle,
} from "./SelectorStyle";
import arrow from "../assets/images/select_arrow.svg";
import React from "react";
import {useSelector} from "../layout/content/selector/useSelector";
import {SELECTOR_AREA} from "../util/etcUtil";
import {useBlockEnter} from "../hooks/useBlockEnter";

interface Props {
  options: SelectorOption[];
  selectedValue: string;
  setSelectorOption: (_: SelectorOption) => void;
  isRequired?: boolean;
}

export function OrganMemberPhoneSelector({ options, selectedValue, setSelectorOption, isRequired = false }: Props) {
  const { selectorRef, onClickSelector, onClickOption, selectedOption, showOptions } = useSelector({
    options,
    selectedValue,
    setSelectorOption,
  });
  const { blockEnterKeyEvent } = useBlockEnter();


  return (
    <>
      <OrganMemberSelectAreaStyle
        data-name={SELECTOR_AREA}
        className={`${selectedOption?.value ? "" : "none"}`}
        onClick={onClickSelector}
        ref={selectorRef}
      >
        <OrganMemberSelectedItemStyle className={`${selectedOption?.value ? "" : "none"}`}>
          {selectedOption?.label}
        </OrganMemberSelectedItemStyle>
        <OrganMemberSelectorArrowStyle>
          <img src={arrow} alt={""} />
        </OrganMemberSelectorArrowStyle>
      </OrganMemberSelectAreaStyle>
      <OrganMemberPhoneOptions className={`${showOptions ? "" : "hide"}`}>
        {options.map((option, idx) => (
          <OptionItem
            className={"rounded"}
            key={`option-${option.value}-${idx}`}
            data-value={option.value}
            onClick={() => onClickOption(option)}
          >
            {option.label}
          </OptionItem>
        ))}
      </OrganMemberPhoneOptions>
      <SelectorHiddenInputStyle
        required={isRequired}
        value={selectedOption?.value ?? ""}
        onChange={() => {}}
        onKeyDown={blockEnterKeyEvent}
      />
    </>
  );
}
