import { Modal } from "../../layout/modal/Modal";
import style from "./termsModal.module.scss";
import { Terms, TermsCategory } from "../../types/terms";
import { useCallback, useContext, useEffect, useState } from "react";
import { ModalContext } from "../../hooks/ModalContext";
import { ModalType } from "../../types/common";
import { useAppDispatch } from "../../hooks/hooks";
import { getTermsByCategoryAsync } from "../../store/termsSlice";
import { useTranslation } from "react-i18next";

interface Props {
  termsCategory: TermsCategory;
}

export function TermsModal({ termsCategory }: Props) {
  const { t } = useTranslation("terms");
  const dispatch = useAppDispatch();

  const [terms, setTerms] = useState<Terms | null>(null);

  const { closeModal, showModal } = useContext(ModalContext);

  const getTermsByCategory = useCallback(
    async (termsCategory: TermsCategory) => {
      try {
        const terms: Terms = await dispatch(getTermsByCategoryAsync(termsCategory)).unwrap();
        setTerms(terms);
      } catch (error) {
        console.error(error);
        alert(t("loadError"));
      }
    },
    [t, dispatch]
  );

  useEffect(() => {
    if (termsCategory) {
      getTermsByCategory(termsCategory).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termsCategory]);

  return (
    <Modal contentStyle={style.termsModal} show={showModal === ModalType.TERMS} hasBg={false} closeModal={closeModal}>
      <>
        <h2 className={style.title}>{terms?.title}</h2>
        <div className={style.termsDetail}>{terms?.content}</div>
      </>
    </Modal>
  );
}
