import style from "../style/grantModal.module.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Panel } from "../../layout/content/detail/Panel";
import { PanelWrapper } from "../../layout/content/detail/PanelWrapper";
import { useCouponColumns } from "./hooks/useCouponColumns";
import { CouponAvailableDateType, CouponListObj, CouponStatus } from "../../types/coupon";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../hooks/hooks";
import { couponStatus, grantCouponAsync } from "../../store/couponSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { CustomerType, TableColumnData } from "../../types/common";
import { callAsync } from "../../util/sliceUtil";
import { SearchField } from "../../layout/content/detail/SearchField";
import queryString from "query-string";
import { CustomerVO, useCustomerGetter } from "../order/hooks/useCustomerGetter";
import { memberUserStatus } from "../../store/userSlice";
import { organManagerStatus } from "../../store/organManagerSlice";
import { WindowMessage } from "../../types/newWindow";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../util/hooks/useUtil";
import { BodyScollTable } from "../../layout/content/list/table/BodyScrollTable";
import { Label104x46 } from "../../components/Label";
import { useConditionalCouponList } from "./hooks/useConditionalCouponList";
import { DEFAULT_SIZE_IN_SUB_TABLE } from "../../types/page";

export function GrantCouponModal() {
  const { t } = useTranslation("coupon");
  const dispatch = useAppDispatch();
  const getCustomer = useCustomerGetter();
  const [customerVO, setCustomerVO] = useState<CustomerVO>();
  const { defaultErrorMessage } = useUtil();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  useLoader({ status: useAppSelector(couponStatus) });
  useLoader({ status: useAppSelector(memberUserStatus) });
  useLoader({ status: useAppSelector(organManagerStatus) });

  const { targetIdx, customerType: customerTypeString } = queryString.parse(window.location.search);
  const customerType: CustomerType = useMemo(
    () => (customerTypeString ? (customerTypeString as CustomerType) : CustomerType.USER),
    [customerTypeString]
  );
  const [searchWord, setSearchWord] = useState("");

  useEffect(() => getCustomer(Number(targetIdx), customerType, setCustomerVO), [getCustomer, customerType, targetIdx]);

  const { getCouponList, couponList, totalCnt } = useConditionalCouponList({
    pageSize: DEFAULT_SIZE_IN_SUB_TABLE,
    couponStatus: CouponStatus.IS_ISSUING,
    searchWord,
  });
  const { columns } = useCouponColumns({ stopIssuingCoupons: null, resumeIssuingCoupons: null });

  const refinedCouponList = useMemo(() => {
    return couponList?.content.filter((c) => {
      if (c.availableDateType === CouponAvailableDateType.GRANTED_DATE) {
        return true;
      }

      const now = moment();
      return moment.utc(c.availableStartAt).isSameOrBefore(now) && moment.utc(c.availableEndAt).isSameOrAfter(now);
    });
  }, [couponList]);

  const refinedColumns: TableColumnData<CouponListObj>[] = useMemo(() => {
    return columns
      .filter((column) => column.accessor !== "status" && column.accessor !== "idx")
      .map((c) => {
        switch (c.accessor) {
          case "createAt":
            return { ...c, width: 132 };
          case "type":
            return { ...c, width: 127 };
          case "name":
            return { ...c, width: 304 };
          case "availableAboveAmount":
            return { ...c, width: 200 };
          case "point":
            return { ...c, width: 127 };
          case "availableDateType":
            return { ...c, width: 132 };
          default:
            return { ...c };
        }
      });
  }, [columns]);

  const [checkedCouponIdxArr, setCheckedCouponIdxArr] = useState<number[]>([]);

  useEffect(() => {
    getCouponList({}, true)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSearchBtn = () => {
    getCouponList({})();
  };

  const onKeyUpSearchInput = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      onClickSearchBtn();
    }
  };

  const onClickGrantCouponButton = () => {
    if (checkedCouponIdxArr.length === 0) {
      alert(t("grant.noSelectedCoupon"));
      return;
    }

    if (!window.confirm(t("grant.confirm"))) {
      return;
    }

    callAsync(
      dispatch(
        grantCouponAsync({
          customerType: customerType,
          userIdx: customerType === CustomerType.USER ? Number(targetIdx) : undefined,
          organManagerIdx: customerType === CustomerType.ORGAN_MANAGER ? Number(targetIdx) : undefined,
          couponIdxList: checkedCouponIdxArr,
        })
      ).unwrap(),
      () => {
        alert(t("grant.result"));
        closeWindowAndNotifyToParentWindow(WindowMessage.GRANT_COUPON);
      },
      () => alert(defaultErrorMessage)
    ).then();
  };

  const SelectedCouponCount = useCallback(
    () => <span className={style.count}>{checkedCouponIdxArr.length}</span>,
    [checkedCouponIdxArr.length]
  );

  const Name = useCallback(() => <span className={style.name}>{customerVO?.name}</span>, [customerVO?.name]);

  return (
    <PanelWrapper>
      <Panel title={t("grant.title")} panelClassName={style.panel}>
        <>
          <p className={style.desc}>
            <span className={style.otherDesc}>
              <Trans t={t} i18nKey={"grant.description"} components={[<Name />]} />
            </span>
          </p>
          <div className={style.searchArea}>
            <div className={style.search}>
              <Label104x46 text={t("grant.search.label")}></Label104x46>
              <SearchField
                value={searchWord}
                onKeyUpFunc={onKeyUpSearchInput}
                onChangeFunc={(e) => setSearchWord((e.target as HTMLInputElement).value)}
                placeholder={t("grant.search.placeholder")}
                onClickSearchButtonFunc={onClickSearchBtn}
              />
            </div>
            <span className={style.resultCntArea}>
              <Trans t={t} i18nKey={"grant.search.count"} components={[<SelectedCouponCount />]} />
            </span>
          </div>
          <div className={style.results}>
            {couponList && refinedCouponList ? (
              <BodyScollTable
                listData={{
                  ...couponList,
                  content: refinedCouponList,
                }}
                checkboxColumnWidth={58}
                columns={refinedColumns}
                getList={getCouponList}
                checkedRowIdxArr={checkedCouponIdxArr}
                setCheckedRowIdxArr={setCheckedCouponIdxArr}
                noDataText={totalCnt === 0 ? t("grant.search.noCoupon") : t("grant.search.noResult")}
                tableClassName={style.table}
                tbodyClassName={style.tableBody}
                noResultRowClassName={style.noResultRow}
              />
            ) : (
              <></>
            )}
          </div>
          <div className={style.buttonArea}>
            <button onClick={onClickGrantCouponButton}>{t("grant.button")}</button>
          </div>
        </>
      </Panel>
    </PanelWrapper>
  );
}
