import { ListObject, TableColumnData } from "../../../types/common";
import React, { ReactNode, useCallback, useMemo } from "react";
import { PageResponse, PageSearchCondition } from "../../../types/page";
import { TableFilter } from "../../../types/tableFilter";
import listStyle from "./list.module.scss";
import { PageSizeSelector } from "../selector/PageSizeSelector";
import { ListFilter } from "./ListFilter";
import { Pagination } from "./Pagination";
import { Table } from "./table/Table";
import styled from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import { LOCALES_STRING } from "../../../util/etcUtil";

export interface TotalRowsCnt {
  totalCount: JSX.Element;
  singleUnitKeyInCommonFile: string;
  multipleUnitKeyInCommonFile: string;
}

interface Props<T extends ListObject> {
  filters: TableFilter[][] | null;
  filterTitle?: string;
  buttonsNode: ReactNode;
  listData: PageResponse<T>;
  getList: (pageCondition: PageSearchCondition) => VoidFunction;
  columns: TableColumnData<T>[];
  totalRowsCnt: TotalRowsCnt;
  checkedRowIdxArr?: number[];
  setCheckedRowIdxArr?: (_: number[]) => void;
  showCheckbox?: (_: T) => boolean;
  noDataText?: string;
  isDetailPage?: boolean;
  pageOnFilter: PageSearchCondition;
  totalCountInfoRightElement?: JSX.Element;
}

const ListTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function ListTable<T extends ListObject>({
  filters,
  filterTitle,
  buttonsNode,
  listData,
  getList,
  columns,
  totalRowsCnt,
  checkedRowIdxArr,
  setCheckedRowIdxArr,
  showCheckbox,
  noDataText,
  isDetailPage,
  pageOnFilter,
  totalCountInfoRightElement,
}: Props<T>) {
  const { t } = useTranslation("common");
  const isBlankPage = useMemo(
    () => listData.content.length === 0 && !noDataText,
    [listData.content.length, noDataText]
  );
  const SearchResultCount = useCallback(
    () => <span className={listStyle.searchTotalCnt}>{listData.totalElements.toLocaleString(LOCALES_STRING)}</span>,
    [listData.totalElements]
  );

  return (
    <ListTableWrapper>
      {filters ? (
        <ListFilter
          filters={filters}
          doSearch={() => {
            getList({})();
            setCheckedRowIdxArr?.([]);
          }}
          isDetailPage={isDetailPage}
          title={filterTitle}
        />
      ) : (
        <></>
      )}
      <div className={`${listStyle.list} ${isDetailPage ? listStyle.detailPage : ""}`}>
        <div className={listStyle.totalPageInfo}>
          <span className={listStyle.totalText}>
            <>{totalRowsCnt.totalCount}</>&nbsp;&nbsp;&nbsp;({t("searchResult")}{" "}
            {listData.totalElements < 2 ? (
              <Trans t={t} i18nKey={totalRowsCnt.singleUnitKeyInCommonFile} components={[<SearchResultCount />]} />
            ) : (
              <Trans t={t} i18nKey={totalRowsCnt.multipleUnitKeyInCommonFile} components={[<SearchResultCount />]} />
            )}
            )
          </span>
          {totalCountInfoRightElement ?? <></>}
          {isBlankPage ? (
            <></>
          ) : (
            <>
              {isDetailPage ? (
                <></>
              ) : (
                <PageSizeSelector
                  pageSize={listData.size!}
                  setPageSize={(size) => {
                    getList({ size, page: 0, sort: pageOnFilter?.sort })();
                    setCheckedRowIdxArr?.([]);
                  }}
                />
              )}
            </>
          )}
        </div>
        {isBlankPage ? (
          <div className={listStyle.noData}>{t("noSearchResult")}</div>
        ) : (
          <>
            <div className={listStyle.buttons}>{buttonsNode}</div>
            <Table
              listData={listData}
              columns={columns}
              getList={getList}
              checkedRowIdxArr={checkedRowIdxArr}
              setCheckedRowIdxArr={setCheckedRowIdxArr}
              showCheckbox={showCheckbox}
              noDataText={noDataText ?? t("noSearchResult")}
            />
            <div className={listStyle.buttons}>{buttonsNode}</div>
            <div className={listStyle.pagingWrapper}>
              <Pagination
                page={listData}
                goSpecificPage={(pageNum: number) => {
                  getList({ page: pageNum, sort: pageOnFilter?.sort, size: pageOnFilter?.size })();
                  setCheckedRowIdxArr?.([]);
                }}
              />
            </div>
          </>
        )}
      </div>
    </ListTableWrapper>
  );
}
