import { ActionReducerMapBuilder, AsyncThunk } from "@reduxjs/toolkit";
import { SliceState, Status } from "../types/common";
import { AxiosResponse } from "axios";
import { ErrorType } from "../types/error";

export const addCases = <T extends SliceState>(
  builder: ActionReducerMapBuilder<T>,
  asyncThunk: AsyncThunk<any, any, {}>
) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.status = Status.LOADING;
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.status = Status.FAILED;
    })
    .addCase(asyncThunk.fulfilled, (state) => {
      state.status = Status.IDLE;
    });
};

export const executePromise = async <T>(f: () => Promise<AxiosResponse<T>>) => {
  try {
    return await f();
  } catch (error: any) {
    throw new Error(JSON.stringify(error?.response?.data));
  }
};

export const getErrorType = (error: Error) => JSON.parse((error as Error).message);

export const callAsync = <T>(callPromise: Promise<T>, resolve: (_: T) => any, reject: (_: ErrorType) => void) => {
  return callPromise.then(resolve).catch((error: Error) => {
    console.error(error);
    const errorObj: ErrorType = getErrorType(error);
    if (errorObj.errorCode.httpCode === 401) {
      window.location.href = "/login";
    } else {
      reject(errorObj);
    }
  });
};
