import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { useUtil } from "../../../../util/hooks/useUtil";
import { deletePopupsAsync } from "../../../../store/popupSlice";

interface Args {
  onCompletedCallback: () => void;
}
export function usePopupDeleter({ onCompletedCallback }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deletePopups = useCallback(
    async (popupIdxes: number[]) => {
      if (popupIdxes.length === 0) {
        alert("팝업을 선택해주세요.");
        return;
      }

      if (!window.confirm("선택한 팝업을 삭제 하시겠습니까?")) {
        return;
      }

      try {
        await dispatch(deletePopupsAsync(popupIdxes)).unwrap();
        alert("팝업이 삭제 되었습니다.");
        onCompletedCallback();
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("팝업 삭제 에러");
      }
    },
    [onCompletedCallback, defaultErrorMessage, dispatch]
  );

  return {
    deletePopups,
  };
}
