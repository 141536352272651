import {BannerList} from "./BannerList";
import {ListFilterValuesContext} from "../../../hooks/list/ListFilterValuesContext";
import {useBannerFilterValues} from "./hooks/useBannerFilterValues";

export function BannerListWrapper() {
    const {filterValues, pageInfoOnFilter, searchList, listQueryType} = useBannerFilterValues();
    return (
        <>
            <ListFilterValuesContext.Provider
                value={{
                    filterValues,
                    pageInfoOnFilter,
                    searchList,
                    listQueryType,
                }}>
                <BannerList/>
            </ListFilterValuesContext.Provider>
        </>
    );
}
