import {Table} from "../../../../../layout/content/list/table/Table";
import React, {useEffect, useState} from "react";
import {Pagination} from "../../../../../layout/content/list/Pagination";
import {Panel} from "../../../../../layout/content/detail/Panel";
import {useOrganManagerOrganColumns} from "./hooks/useOrganManagerOrganColumns";
import {useOrgansGetter} from "./hooks/useOrgansGetter";
import {OrganManagerOrganDTO} from "../../../../../types/organ";
import {OrganDetail} from "./components/OrganDetail";
import {OrganMembers} from "./components/OrganMembers";
import queryString from "query-string";
import {useAppSelector} from "../../../../../hooks/hooks";
import {organStatus} from "../../../../../store/organSlice";
import {useLoader} from "../../../../../hooks/common/useLoader";
import {PaginationWrapper} from "../../../../../layout/content/list/PaginationWrapper";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {OrganAuthority} from "./components/OrganAuthority";
import {useOrganManagerGetter} from "../info/hooks/useOrganManagerGetter";

interface Props {
  organManagerIdx: number;
}

export function OrganManagerOrgans({ organManagerIdx }: Props) {
  const { t } = useTranslation(["organManager", "common"]);
  const navigate = useNavigate();
  const status = useAppSelector(organStatus);
  useLoader({ status });
  const [selectedOrgan, setSelectedOrgan] = useState<OrganManagerOrganDTO | undefined>(undefined);
  const columns = useOrganManagerOrganColumns(organManagerIdx);
  const { organ: queryOrgan } = queryString.parse(window.location.search);
    const {organManagerVO} = useOrganManagerGetter();
    const {getOrganList, organs} = useOrgansGetter(organManagerIdx);

  useEffect(() => {
    getOrganList({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const organIdx = Number(queryOrgan);
    const foundOrgan = organs?.content?.find((it) => it.idx === organIdx) ?? undefined;
    if (foundOrgan !== undefined) setSelectedOrgan(foundOrgan);
  }, [organs, queryOrgan]);

    return (
        <>
            <OrganAuthority organManager={organManagerVO} organManagerIdx={organManagerIdx}/>
            <Panel title={t("detail.organManagement.organs.listTitle")}>
                {organs ? (
                    <>
                        <Table
                            listData={organs}
                            columns={columns}
                            getList={getOrganList}
                            noDataText={t("noSearchResult", {ns: "common"})}
                        />
                        <PaginationWrapper>
                            <Pagination page={organs}
                                        goSpecificPage={(pageNum: number) => getOrganList({page: pageNum})()}/>
                        </PaginationWrapper>
                    </>
                ) : (
                    <></>
                )}
            </Panel>

      <OrganDetail
        organ={selectedOrgan}
        resetSelectedOrgan={() => {
          setSelectedOrgan(undefined);
          navigate(`/detail/member/organ-manager/${organManagerIdx}?tab=ORGAN`);
          getOrganList({})();
        }}
      />
      <OrganMembers organ={selectedOrgan} onRefresh={getOrganList({})} />
    </>
  );
}
