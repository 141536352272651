import {useCallback, useMemo} from "react";
import {BannerLocation, BannerStatus, BannerType, LinkOption} from "../banner";
import {WebsiteType} from "../common";

export function useBannerType() {
    const bannerTypeLabelRouter = useMemo(
        () => ({
            [BannerType.KOREAN.toString()]: "한국어",
            [BannerType.ENGLISH.toString()]: "영어",
            [BannerType.TAIWAN.toString()]: "중문(번체)",
            [BannerType.RUSSIA.toString()]: "러시아어",
        }),
        []
    );
    const getBannerTypeLabel = useCallback(
        (type: BannerType) => bannerTypeLabelRouter[type.toString()],
        [bannerTypeLabelRouter]
    );

    const bannerLocationLabelRouter = useMemo(
        () => ({
            [BannerLocation.MAIN.toString()]: "메인",
        }),
        []
    );
    const getBannerLocationLabel = useCallback(
        /* eslint-disable */
        (type: BannerLocation) => "메인",
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bannerLocationLabelRouter]
    );


    const bannerLinkOptionLabelRouter = useMemo(
        () => ({
            [LinkOption.NEW_TAB.toString()]: "새로운 탭으로 이동",
            [LinkOption.CURRENT_TAB.toString()]: "현재 탭에서 이동",
        }),
        []
    );
    const getBannerLinkOptionLabel = useCallback(
        (linkOption: LinkOption) => bannerLinkOptionLabelRouter[linkOption.toString()],
        [bannerLinkOptionLabelRouter]
    );


    const bannerLinkOptionLabelOnListRouter = useMemo(
        () => ({
            [LinkOption.NEW_TAB.toString()]: "새로운 탭",
            [LinkOption.CURRENT_TAB.toString()]: "현재 탭",
        }),
        []
    );
    const bannerStatusLabelRouter = useMemo(
        () => ({
            // todo 노출 / 미노출 vs 사용 / 미사용
            [BannerStatus.UNEXPOSED.toString()]: "미노출",
            [BannerStatus.EXPOSED.toString()]: "노출",
        }),
        []
    );

    const getBannerStatusLabel = useCallback(
        (status: BannerStatus) => bannerStatusLabelRouter[status.toString()],
        [bannerStatusLabelRouter]
    );

    const websiteTypeLabelRouter = useMemo(
        () => ({
            [WebsiteType.KPASS.toString()]: "K-PASS",
            [WebsiteType.DCAS.toString()]: "D-CAS",
        }),
        []
    );
    const getWebsiteType = useCallback(
        (type: WebsiteType) => websiteTypeLabelRouter[type.toString()],
        [websiteTypeLabelRouter]
    );


    return {
        getBannerTypeLabel,
        getBannerLocationLabel,
        getBannerLinkOptionLabel,
        getBannerStatusLabel,
        bannerLinkOptionLabelOnListRouter,
        getWebsiteType,
    };
}