import { DescriptionValue } from "../../../../../../layout/content/detail/DescriptionList";
import { OrganManagerUpdateDTO, OrganManagerVO } from "../../../../../../types/organManager";
import OrganManagerStatusComponent from "../../../components/OrganManagerStatusComponent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { GridInputText } from "../../../../../../components/Inputs";
import { PhoneNumber } from "../../../../../../components/PhoneNumber";
import { InputRefundAccount } from "../../../../../../components/InputRefundAccount";
import { useTermsAgreementText } from "../../../../../../hooks/common/useTermsAgreementText";
import { useOrganManagerStatus } from "../../../hooks/useOrganManagerStatus";
import {
  DetailPositionInMiddleStyle,
  MiddleAlignWrapperStyle,
  UserDetailMarketingReceiptInfoStyle,
  UserDetailMarketingReceiptWrapperStyle,
} from "../../../../user/components/UserSelfInfoDataStyle";
import { MemberUserDisagreeMarketingTerms } from "../../../../user/MemberUserDisagreeMarketingTerms";
import { MarketingTermsCategory } from "../../../../../../types/terms";
import { CustomerType } from "../../../../../../types/common";
import { useCountry, useLanguage, useTimeConverter } from "../../../../../../hooks/hooks";
import { RedActionButton } from "../../../../../../components/Buttons";
import { useOrganManagerPhoneNumberRevoker } from "../../../hooks/useOrganManagerPhoneNumberRevoker";
import { useTranslation } from "react-i18next";

interface Args {
  organManagerVO?: OrganManagerVO;
  onUpdateOrganManagerPutParam: (dto: OrganManagerUpdateDTO) => void;
  getDetail: () => void;
}

export function useOrganManagerInfoData({ organManagerVO, onUpdateOrganManagerPutParam, getDetail }: Args) {
  const { t } = useTranslation("organManager");
  const [name, setName] = useState<string>("");
  const [refundAccountBank, setRefundAccountBank] = useState<string>("");
  const [refundAccountNumber, setRefundAccountNumber] = useState<string>("");
  const [refundAccountName, setRefundAccountName] = useState<string>("");
  const { isLeave } = useOrganManagerStatus();
  const { timeConverter } = useTimeConverter();
  const { getCountryNameByCodeISOAlpha2 } = useCountry();
  const { isKorean } = useLanguage();

  useEffect(() => {
    setName(organManagerVO?.name ?? "");
    setRefundAccountBank(organManagerVO?.refundBankName ?? "");
    setRefundAccountNumber(organManagerVO?.refundAccountNumber ?? "");
    setRefundAccountName(organManagerVO?.refundAccountName ?? "");
  }, [organManagerVO]);

  useEffect(() => {
    onUpdateOrganManagerPutParam({
      name,
      refundBankName: refundAccountBank,
      refundAccountNumber: refundAccountNumber,
      refundAccountName: refundAccountName,
    });
  }, [name, refundAccountBank, refundAccountName, refundAccountNumber, onUpdateOrganManagerPutParam]);

  const isRequiredRefundAccount = useMemo(
    () => refundAccountBank.length > 0 || refundAccountNumber.length > 0 || refundAccountName.length > 0,
    [refundAccountBank, refundAccountNumber, refundAccountName]
  );

  const { toAgreementString } = useTermsAgreementText();

  const { revokePhoneNumberAuthentication } = useOrganManagerPhoneNumberRevoker();
  const onClickRevokePhoneNumberAuthenticationButton = useCallback(() => {
    if (organManagerVO?.idx && window.confirm(t("detail.authenticatePhoneNumber.confirmMessage"))) {
      revokePhoneNumberAuthentication(organManagerVO.idx, () => {
        alert(t("detail.authenticatePhoneNumber.resultMessage"));
        getDetail();
      });
    }
  }, [organManagerVO?.idx, getDetail, revokePhoneNumberAuthentication, t]);

  const getDescList = (organManagerVO: OrganManagerVO): DescriptionValue[][] => {
    if (isLeave(organManagerVO.status)) {
      return [
        [
          {
            key: t("detail.memberInfo.name.label"),
            value: t("detail.memberInfo.name.leavedMember"),
          },
          {
            key: t("detail.memberInfo.category.label"),
            value: t("detail.memberInfo.category.value"),
          },
        ],
        [
          {
            key: t("detail.memberInfo.createdAt"),
            value: timeConverter.convertToLocalTime(organManagerVO.createAt),
          },
          {
            key: t("detail.memberInfo.leavedAt"),
            value: timeConverter.convertToLocalTime(organManagerVO.leaveAt),
          },
        ],
        [
          {
            key: t("list.filter.nationality"),
            value: getCountryNameByCodeISOAlpha2(organManagerVO.nationality),
          },
        ],
      ];
    } else {
      return [
        [
          {
            key: t("detail.memberInfo.name.label"),
            value: organManagerVO.name,
            editModeValue: (
              <GridInputText
                value={name}
                minLength={2}
                maxLength={50}
                required={true}
                placeholder={t("detail.memberInfo.name.placeholder")}
                onChange={(e) => setName(e.target.value)}
              />
            ),
          },
          { key: t("detail.memberInfo.createdAt"), value: timeConverter.convertToLocalTime(organManagerVO.createAt) },
        ],
        [
          { key: t("detail.memberInfo.id"), value: organManagerVO.email },
          { key: t("detail.memberInfo.status"), value: <OrganManagerStatusComponent organManager={organManagerVO} /> },
        ],
        [
          {
            key: t("detail.memberInfo.phoneNumber"),
            value: !organManagerVO.isPhoneAuthentication ? (
              <>-</>
            ) : (
              <PhoneNumber codePhone={organManagerVO.codePhone} phoneNumber={organManagerVO.phoneNumber} />
            ),
            editModeValue: !organManagerVO.isPhoneAuthentication ? (
              <UserDetailMarketingReceiptInfoStyle>-</UserDetailMarketingReceiptInfoStyle>
            ) : (
              <UserDetailMarketingReceiptInfoStyle>
                ({organManagerVO.codePhone}) {organManagerVO.phoneNumber}
                <RedActionButton onClick={onClickRevokePhoneNumberAuthenticationButton}>
                  {t("detail.authenticatePhoneNumber.cancelLabel")}
                </RedActionButton>
              </UserDetailMarketingReceiptInfoStyle>
            ),
          },
          {
            key: t("detail.memberInfo.organVoucher.label"),
            value: organManagerVO.voucherCount
              ? t("detail.memberInfo.organVoucher.unit", { count: organManagerVO.voucherCount })
              : "-",
          },
        ],
        [
          {
            key: t("detail.memberInfo.lastLoggedIn"),
            value: (
              <>
                <p>{timeConverter.convertToLocalTime(organManagerVO.lastSignInAt)}</p>
                <p>{organManagerVO.lastSignInIp}</p>
              </>
            ),
          },
          {
            key: t("list.filter.nationality"),
            value: (
              <DetailPositionInMiddleStyle>
                {getCountryNameByCodeISOAlpha2(organManagerVO.nationality)}
              </DetailPositionInMiddleStyle>
            ),
          },
        ],
        [
          {
            key: t("detail.memberInfo.refundAccount.label"),
            value: organManagerVO.refundBankName ? (
              <MiddleAlignWrapperStyle>
                <span>
                  {t("detail.memberInfo.refundAccount.bank")}: {organManagerVO.refundBankName}
                </span>
                <span>&nbsp;/&nbsp;</span>
                <span>
                  {t("detail.memberInfo.refundAccount.accountNumber")}: {organManagerVO.refundAccountNumber}
                </span>
                <span>&nbsp;/&nbsp;</span>
                <span>
                  {t("detail.memberInfo.refundAccount.accountHolderName")}: {organManagerVO.refundAccountName}
                </span>
              </MiddleAlignWrapperStyle>
            ) : (
              "-"
            ),
            editModeValue: (
              <InputRefundAccount
                bankName={refundAccountBank}
                onChangeBankName={setRefundAccountBank}
                accountNumber={refundAccountNumber}
                onChangeAccountNumber={setRefundAccountNumber}
                accountName={refundAccountName}
                onChangeAccountName={setRefundAccountName}
                required={isRequiredRefundAccount}
              />
            ),
          },
        ],
        [
          {
            key: t("detail.memberInfo.termsAgreement.label"),
            value: (
              <MiddleAlignWrapperStyle>
                {t("detail.memberInfo.termsAgreement.privateInfo")}:{" "}
                {toAgreementString(organManagerVO.isOrganManagersAgreementPersonalInfoCollectionAndUsage)} /{" "}
                {t("detail.memberInfo.termsAgreement.viaEmail")}:{" "}
                {toAgreementString(organManagerVO.isOrganManagersAgreementEmailMarketingReceipt)} /{" "}
                {t("detail.memberInfo.termsAgreement.viaSMS")}:{" "}
                {toAgreementString(organManagerVO.isOrganManagersAgreementSMSMarketingReceipt)}
              </MiddleAlignWrapperStyle>
            ),
            editModeValue: (
              <UserDetailMarketingReceiptWrapperStyle>
                <UserDetailMarketingReceiptInfoStyle className={isKorean ? "" : "notKO"}>
                  {t("detail.memberInfo.termsAgreement.privateInfo")}:{" "}
                  {toAgreementString(organManagerVO.isOrganManagersAgreementPersonalInfoCollectionAndUsage)}
                  {organManagerVO.isOrganManagersAgreementPersonalInfoCollectionAndUsage ? (
                    <MemberUserDisagreeMarketingTerms
                      targetIdx={organManagerVO.idx}
                      targetName={organManagerVO.name}
                      targetType={CustomerType.ORGAN_MANAGER}
                      category={MarketingTermsCategory.COLLECTION_AND_USE_OF_PERSONAL_INFORMATION}
                      onCompletedCallback={() => getDetail()}
                    />
                  ) : (
                    <></>
                  )}
                </UserDetailMarketingReceiptInfoStyle>
                <UserDetailMarketingReceiptInfoStyle className={isKorean ? "" : "notKO"}>
                  {t("detail.memberInfo.termsAgreement.viaEmail")}:{" "}
                  {toAgreementString(organManagerVO.isOrganManagersAgreementEmailMarketingReceipt)}
                  {organManagerVO.isOrganManagersAgreementEmailMarketingReceipt ? (
                    <MemberUserDisagreeMarketingTerms
                      targetIdx={organManagerVO.idx}
                      targetName={organManagerVO.name}
                      targetType={CustomerType.ORGAN_MANAGER}
                      category={MarketingTermsCategory.RECEIVE_MARKETING_INFORMATION_EMAILS}
                      onCompletedCallback={() => getDetail()}
                    />
                  ) : (
                    <></>
                  )}
                </UserDetailMarketingReceiptInfoStyle>
                <UserDetailMarketingReceiptInfoStyle className={isKorean ? "" : "notKO"}>
                  {t("detail.memberInfo.termsAgreement.viaSMS")}:{" "}
                  {toAgreementString(organManagerVO.isOrganManagersAgreementSMSMarketingReceipt)}
                  {organManagerVO.isOrganManagersAgreementSMSMarketingReceipt ? (
                    <MemberUserDisagreeMarketingTerms
                      targetIdx={organManagerVO.idx}
                      targetName={organManagerVO.name}
                      targetType={CustomerType.ORGAN_MANAGER}
                      category={MarketingTermsCategory.RECEIVE_MARKETING_INFORMATION_SMS}
                      onCompletedCallback={() => getDetail()}
                    />
                  ) : (
                    <></>
                  )}
                </UserDetailMarketingReceiptInfoStyle>
              </UserDetailMarketingReceiptWrapperStyle>
            ),
          },
        ],
      ];
    }
  };

  return {
    getDescList,
  };
}
