import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceState, Status } from "../types/common";
import { addCases, executePromise } from "../util/sliceUtil";
import { CouponApi } from "../api/CouponApi";
import {
  CouponCntInfo,
  CouponCountInfoParam,
  CouponHistoryPostParam,
  CouponHistorySearchCondition,
  CouponPostParam,
  CouponSearchCondition,
  CouponStatus,
} from "../types/coupon";
import { RootState } from "./index";

export interface CouponState extends SliceState {
  userUsableCouponStatusInfo?: CouponCntInfo;
  isCouponDataSaved: boolean;
}

const initialState: CouponState = {
  status: Status.IDLE,
  isCouponDataSaved: false,
};

export const getUserCouponHistoryListAsync = createAsyncThunk(
  "coupon/getUserCouponHistoryList",
  ({ userIdx, condition }: { userIdx: number; condition: CouponHistorySearchCondition }) =>
    executePromise(CouponApi.getUserCouponHistoryList(userIdx, condition))
);

export const getOrganManagerCouponHistoryListAsync = createAsyncThunk(
  "coupon/getOrganManagerCouponHistoryList",
  ({ organManagerIdx, condition }: { organManagerIdx: number; condition: CouponHistorySearchCondition }) =>
    executePromise(CouponApi.getOrganManagerCouponHistoryList(organManagerIdx, condition))
);

export const deleteCouponHistoryAsync = createAsyncThunk("coupon/deleteCouponHistory", (historyIdx: number) =>
  executePromise(CouponApi.deleteCouponHistory(historyIdx))
);

export const getCouponListAsync = createAsyncThunk("coupon/getCouponList", (condition: CouponSearchCondition) =>
  executePromise(CouponApi.getCouponList(condition))
);

export const resumeIssuingCouponAsync = createAsyncThunk("coupon/resumeIssuingCoupon", (couponIdxArr: number[]) =>
  executePromise(CouponApi.updateCouponStatus({ couponIdxList: couponIdxArr, status: CouponStatus.IS_ISSUING }))
);

export const stopIssuingCouponAsync = createAsyncThunk("coupon/stopIssuingCoupon", (couponIdxArr: number[]) =>
  executePromise(CouponApi.updateCouponStatus({ couponIdxList: couponIdxArr, status: CouponStatus.STOP_ISSUING }))
);

export const deleteCouponAsync = createAsyncThunk("coupon/deleteCoupon", (idxArr: number[]) =>
  executePromise(CouponApi.deleteCoupon({ couponIdxList: idxArr }))
);

export const deleteCouponCompletelyAsync = createAsyncThunk("coupon/deleteCouponCompletely", (idxArr: number[]) =>
  executePromise(CouponApi.deleteCouponCompletely({ couponIdxList: idxArr }))
);

export const getCouponDetailAsync = createAsyncThunk("coupon/getCouponDetail", (couponIdx: number) =>
  executePromise(CouponApi.getCouponDetail(couponIdx))
);

export const grantCouponAsync = createAsyncThunk("coupon/grantCoupon", (param: CouponHistoryPostParam) =>
  executePromise(CouponApi.grantCoupon(param))
);

export const createCouponAsync = createAsyncThunk("coupon/createCoupon", (param: CouponPostParam) =>
  executePromise(CouponApi.createCoupon(param))
);

export const getCouponCountInfoAsync = createAsyncThunk("coupon/getCouponCountInfo", (param: CouponCountInfoParam) =>
  executePromise(CouponApi.getCouponCountInfo(param))
);

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setUserUsableCouponStatusInfo: (state, action: PayloadAction<CouponCntInfo>) => {
      state.userUsableCouponStatusInfo = action.payload;
    },
    setIsCouponDataSaved: (state, action: PayloadAction<boolean>) => {
      state.isCouponDataSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getUserCouponHistoryListAsync);
    addCases(builder, getOrganManagerCouponHistoryListAsync);
    addCases(builder, deleteCouponHistoryAsync);
    addCases(builder, getCouponListAsync);
    addCases(builder, resumeIssuingCouponAsync);
    addCases(builder, stopIssuingCouponAsync);
    addCases(builder, deleteCouponAsync);
    addCases(builder, deleteCouponCompletelyAsync);
    addCases(builder, getCouponDetailAsync);
    addCases(builder, grantCouponAsync);
  },
});

export default couponSlice.reducer;

export const { setUserUsableCouponStatusInfo, setIsCouponDataSaved } = couponSlice.actions;

export const couponStatus = (state: RootState) => state.coupon.status;
export const userUsableCouponStatusInfo = (state: RootState) => state.coupon.userUsableCouponStatusInfo;
export const isCouponDataSavedState = (state: RootState) => state.coupon.isCouponDataSaved;
