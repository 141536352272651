import React from "react";
import { VoucherInfoStatus } from "./VoucherInfoStatus";
import { VoucherInfoList } from "./VoucherInfoList";
import { useAppSelector } from "../../../../../hooks/hooks";
import { voucherStatus } from "../../../../../store/voucherSlice";
import { useLoader } from "../../../../../hooks/common/useLoader";

interface Props {
  userIdx: number;
  name: string;
}

export function VoucherInfo({ userIdx, name }: Props) {
  const status = useAppSelector(voucherStatus);
  useLoader({ status });

  return (
    <>
      <VoucherInfoStatus userIdx={userIdx} />
      <VoucherInfoList userIdx={userIdx} name={name} />
    </>
  );
}
