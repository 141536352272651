import React from "react";
import { Address, useDaumPostcodePopup } from "react-daum-postcode";
import { Address as FeelGoodAddress } from "../types/user";

interface Props {
  onComplete: (address: FeelGoodAddress) => void;
  children: JSX.Element | string;
  className?: string;
}

export function Postcode({ onComplete, className, children }: Props) {
  const open = useDaumPostcodePopup();

  const handleComplete = (data: Address) => {
    onComplete({
      address1: `${data.roadname} ${data.roadAddressEnglish.split(",")[0]}`,
      address2: "",
      address3: "",
      city: data.sigungu,
      state: data.sido,
      country: "KR",
      postalCode: data.zonecode,
    });
  };

  const handleClick = () => {
    open({ onComplete: handleComplete }).then();
  };

  return (
    <button className={className} type="button" onClick={handleClick}>
      {children}
    </button>
  );
}
