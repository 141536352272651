import style from "../../layout/content/post/creator.module.scss";
import { Post } from "../../layout/content/post/Post";
import React, { useCallback, useEffect, useState } from "react";
import { CouponAvailableDateType, CouponPostParam, CouponType } from "../../types/coupon";
import { PercentField } from "../../components/discount/PercentField";
import { PointField } from "../../components/discount/PointField";
import { DurationField } from "./creator/DurationField";
import { GrantedDateField } from "./creator/GrantedDateField";
import { CouponNameField } from "./creator/CouponNameField";
import { CouponTypeRadioField } from "./creator/CouponTypeRadioField";
import { AvailableConditionField } from "./creator/AvailableConditionField";
import { AvailableConditionAmountField } from "./creator/AvailableConditionAmountField";
import { DiscountTypeRadioField } from "./creator/DiscountTypeRadioField";
import { AvailableDateTypeRadioField } from "./creator/AvailableDateTypeRadioField";
import { callAsync } from "../../util/sliceUtil";
import { useAppDispatch, useAppSelector, useTimeConverter } from "../../hooks/hooks";
import { couponStatus, createCouponAsync, isCouponDataSavedState, setIsCouponDataSaved } from "../../store/couponSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useNavigate } from "react-router-dom";
import { HrLine } from "../../layout/content/post/HrLine";
import { CouponCreatorProductList } from "./creator/CouponCreatorProductList";
import { Label104x46 } from "../../components/Label";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../util/hooks/useUtil";
import { useCallbackPrompt } from "../../hooks/navigationblocker/useCallbackPrompt";

export enum AvailableCondition {
  NONE = "NONE",
  PAY_AMOUNT = "PAY_AMOUNT",
}

export enum CouponDiscountType {
  PERCENT = "PERCENT",
  AMOUNT = "AMOUNT",
}

export function CouponCreator() {
  const { t } = useTranslation("coupon");
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const { timeConverter } = useTimeConverter();
  const status = useAppSelector(couponStatus);
  const isCouponDataSaved = useAppSelector(isCouponDataSavedState);
  useLoader({ status });
  const { defaultErrorMessage } = useUtil();

  const [name, setName] = useState("");
  const [couponType, setCouponType] = useState<CouponType>(CouponType.TOTAL_AMOUNT_DISCOUNT);
  const [availableDateType, setAvailableDateType] = useState<CouponAvailableDateType>(CouponAvailableDateType.DURATION);
  const [availableStartAt, setAvailableStartAt] = useState("");
  const [availableEndAt, setAvailableEndAt] = useState("");
  const [availableDate, setAvailableDate] = useState("");
  const [availableCondition, setAvailableCondition] = useState<AvailableCondition>(AvailableCondition.NONE);
  const [conditionAmount, setConditionAmount] = useState("");
  const [discountType, setDiscountType] = useState<CouponDiscountType>(CouponDiscountType.PERCENT);
  const [percent, setPercent] = useState("");
  const [point, setPoint] = useState("");
  const [selectedProductIdx, setSelectedProductIdx] = useState<number | null>(null);

  const isDiscountTypeAmount = useCallback((d: CouponDiscountType) => d === CouponDiscountType.AMOUNT, []);
  const isDiscountTypePercent = useCallback((d: CouponDiscountType) => d === CouponDiscountType.PERCENT, []);
  const isConditionPayAmount = useCallback((c: AvailableCondition) => c === AvailableCondition.PAY_AMOUNT, []);
  const isDurationType = useCallback((t: CouponAvailableDateType) => t === CouponAvailableDateType.DURATION, []);
  const isGrantDateType = useCallback((t: CouponAvailableDateType) => t === CouponAvailableDateType.GRANTED_DATE, []);

  const createCoupon = () => {
    if (couponType === CouponType.PRODUCT_DISCOUNT) {
      if (!selectedProductIdx) {
        alert(t("creator.validations.productNeeded"));
        return;
      }
    }

    if (availableStartAt && availableEndAt) {
      if (new Date(availableStartAt) > new Date(availableEndAt)) {
        alert(t("creator.validations.startDateBeforeEndDate"));
        return;
      }
    }

    const isConfirmed = window.confirm(t("creator.confirm"));

    if (isConfirmed) {
      const param: CouponPostParam = {
        type: couponType,
        name,
        productIdx: selectedProductIdx ?? undefined,
        availableDateType,
        availableStartAt:
          isDurationType(availableDateType) && availableStartAt
            ? timeConverter.convertStartOfDayToUTC(availableStartAt)
            : undefined,
        availableEndAt:
          isDurationType(availableDateType) && availableEndAt
            ? timeConverter.convertEndOfDayToUTC(availableEndAt)
            : undefined,
        availableDateAfterGranted: isGrantDateType(availableDateType) ? Number(availableDate) : undefined,
        point: isDiscountTypeAmount(discountType) ? Number(point) : undefined,
        percent: isDiscountTypePercent(discountType) ? Number(percent) : undefined,
        availableAboveAmount: isConditionPayAmount(availableCondition) ? Number(conditionAmount) : undefined,
      };

      callAsync(
        dispatch(createCouponAsync(param)).unwrap(),
        () => {
          dispatch(setIsCouponDataSaved(true));
          alert(t("creator.resultMessage"));
          navigator(`/coupon/list`);
        },
        () => alert(defaultErrorMessage)
      ).then();
    }
  };

  const [hasChangedValue, setHasChangedValue] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(!isCouponDataSaved && hasChangedValue);

  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      if (v) {
        dispatch(setIsCouponDataSaved(false));
        setHasChangedValue(true);
      }
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  useEffect(() => {
    if (!isCouponDataSaved && showPrompt) {
      const isConfirmed = window.confirm(t("creator.promptMessage"));
      if (isConfirmed) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    } else {
      confirmNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCouponDataSaved, showPrompt, confirmNavigation, cancelNavigation]);

  return (
    <Post title={t("creator.title")} buttonTitle={t("creator.title")} buttonOnClickAction={createCoupon}>
      <>
        <div className={style.fieldLine}>
          <Label104x46 text={t("creator.name.label")} />
          <CouponNameField name={name} setName={(v) => changeValue(v, setName)} />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={t("creator.type.label")} />
          <CouponTypeRadioField couponType={couponType} setCouponType={(v) => changeValue(v, setCouponType)} />
        </div>
        <HrLine />
        {couponType === CouponType.TOTAL_AMOUNT_DISCOUNT ? (
          <div className={style.fieldLine}>
            <Label104x46 text={t("creator.condition.label")} />
            <AvailableConditionField
              availableCondition={availableCondition}
              setAvailableCondition={(v) => changeValue(v, setAvailableCondition)}
            />
            {availableCondition === AvailableCondition.PAY_AMOUNT ? (
              <AvailableConditionAmountField
                conditionAmount={conditionAmount}
                setConditionAmount={(v) => changeValue(v, setConditionAmount)}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <CouponCreatorProductList setSelectedProductIdx={(v) => changeValue(v, setSelectedProductIdx)} />
        )}
        <div className={style.fieldLine}>
          <Label104x46 text={t("creator.benefits")} />
          <DiscountTypeRadioField
            discountType={discountType}
            setDiscountType={(v) => changeValue(v, setDiscountType)}
          />
          <div className={style.addedField}>
            {discountType === CouponDiscountType.PERCENT ? (
              <PercentField percent={percent} setPercent={(v) => changeValue(v, setPercent)} />
            ) : (
              <PointField point={point} setPoint={(v) => changeValue(v, setPoint)} />
            )}
          </div>
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={t("creator.grantedDate.label")} />
          <AvailableDateTypeRadioField
            availableDateType={availableDateType}
            setAvailableDateType={(v) => changeValue(v, setAvailableDateType)}
          />
          <div className={style.addedField}>
            {availableDateType === CouponAvailableDateType.DURATION ? (
              <DurationField
                availableStartAt={availableStartAt}
                availableEndAt={availableEndAt}
                setAvailableStartAt={(v) => changeValue(v, setAvailableStartAt)}
                setAvailableEndAt={(v) => changeValue(v, setAvailableEndAt)}
              />
            ) : (
              <GrantedDateField
                availableDate={availableDate}
                setAvailableDate={(v) => changeValue(v, setAvailableDate)}
              />
            )}
          </div>
        </div>
        <HrLine />
      </>
    </Post>
  );
}
