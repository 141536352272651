import { DescriptionList } from "../../../../../layout/content/detail/DescriptionList";
import { LinkStyle } from "../../../../../components/styled/LinkStyle";
import { BuyerVO, OrdersVO } from "../../../../../types/orders";
import useBuyerGetter from "./useBuyerGetter";
import { useEffect, useState } from "react";
import { useNewWindow } from "../../../../../hooks/hooks";
import { Panel } from "../../../../../layout/content/detail/Panel";
import style from "../../orderDetail.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  orderVO: OrdersVO;
}

export function OrderBuyerInfo({ orderVO }: Props) {
  const { t } = useTranslation("order");
  const getBuyer = useBuyerGetter();
  const [buyerVO, setBuyerVO] = useState<BuyerVO>();

  useEffect(() => getBuyer(orderVO.idx, (buyerVO) => setBuyerVO(buyerVO)), [getBuyer, orderVO]);

  const { openCustomerPopup } = useNewWindow();
  function getDescription(buyerVO: BuyerVO) {
    return [
      [
        {
          key: t("buyerInfo.name"),
          value: (
            <LinkStyle
              onClick={(e) => {
                e.preventDefault();
                openCustomerPopup(buyerVO.idx, orderVO.customerType);
              }}
            >
              {buyerVO.name}
            </LinkStyle>
          ),
        },
        { key: t("buyerInfo.id"), value: buyerVO.email },
      ],
      [{ key: t("buyerInfo.phoneNumber"), value: `(${buyerVO.codePhone}) ${buyerVO.phoneNumber}` }],
    ];
  }

  return (
    <Panel id="BUYER" title={t("buyerInfo.title")} panelClassName={style.scrollMarginTop}>
      <>{buyerVO && <DescriptionList data={getDescription(buyerVO)} labelWidth={132} />}</>
    </Panel>
  );
}
