import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "../features/account/style.module.scss";
import { InputPasswordStyle } from "./Inputs";
import { ErrorMessageStyle } from "./Texts";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputRef?: React.RefObject<HTMLInputElement>;
}
export function PasswordInputBox({ inputRef, ...rest }: Props) {
  const { t } = useTranslation(["common"]);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  return (
    <div className={style.passwordInputWrapper}>
      <InputPasswordStyle
        {...rest}
        type={"password"}
        onKeyUp={(e) => {
          if (e.getModifierState("CapsLock")) {
            setIsCapsLockOn(true);
          } else {
            setIsCapsLockOn(false);
          }
        }}
      />
      {isCapsLockOn && <ErrorMessageStyle>{t("capsLockOn")}</ErrorMessageStyle>}
    </div>
  );
}
