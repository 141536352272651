import style from "./layout.module.scss";
import {useAppSelector, useAuth, useNewWindow} from "../../hooks/hooks";
import {useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {adminStatus} from "../../store/adminSlice";
import {useLoader} from "../../hooks/common/useLoader";
import {useMyInfoGetter} from "../../features/account/mypage/hooks/useMyInfoGetter";
import {Trans, useTranslation} from "react-i18next";
import {useRole} from "../../hooks/useRole";
import {LanguageSelector} from "./selector/LanguageSelector";
import {TimeZoneSelector} from "./selector/TimeZoneSelector";

export function ProfileBar() {
  const { t } = useTranslation(["profileBar"]);
  const navigate = useNavigate();
  const auth = useAuth();
  const { openAdminDetailPopup } = useNewWindow();
  const { getRoleText } = useRole();

  const status = useAppSelector(adminStatus);
  useLoader({ status });
  const { myInfo } = useMyInfoGetter();

  const onClickLogoutBtn = () => {
    const wantToLogout = window.confirm(t("logoutConfirmMessage"));

    if (wantToLogout) {
      auth.signOut(() => {
        navigate("/login");
      });
    }
  };

  const onClickName = () => {
    if (myInfo) {
      openAdminDetailPopup(myInfo.idx);
    }
  };

  const LoginAdminName = useCallback(() => <span className={style.accent}>{myInfo?.name}</span>, [myInfo?.name]);

  return (
    <div className={style.profileBar}>
      <div className={style.links}>
        <a className={style.feelgoodLink} href={"https://www.feel-good.io/"} target={"_blank"} rel="noreferrer">
          <div className={style.linkIcon}></div>
          FeelGood
        </a>
        <a className={style.kpassLink} href={"https://kpass.feel-good.io/"} target={"_blank"} rel="noreferrer">
          <div className={style.linkIcon}></div>
          K-PASS
        </a>
        <a className={style.dcasLink} href={"https://dcas.feel-good.io/"} target={"_blank"} rel="noreferrer">
          <div className={style.linkIcon}></div>
          D-CAS
        </a>
      </div>

      <div className={style.selectorsWrapper}>
        {/*  국가항목 임시 주석처리  */}
        {/*<RoleChecker menuCode={AdminMenuCode.CHANGE_NATIONALITY}>*/}
        {/*  <div className={style.selectors}>*/}
        {/*    <label className={style.label}>{t("country")}</label>*/}
        {/*    <NationalityRoundSelector />*/}
        {/*  </div>*/}
        {/*</RoleChecker>*/}
        <div className={style.selectors}>
          <label className={style.label}>{t("languageAndTimezone")}</label>
          <LanguageSelector />
          <TimeZoneSelector />
        </div>
        <div className={style.myInfo}>
          {myInfo ? (
            <div className={style.info}>
              <span className={style.roleName}>[{getRoleText(myInfo.role.category)}]</span>
              <span className={style.name} onClick={onClickName}>
                <Trans t={t} i18nKey={"sir"} components={[<LoginAdminName />]} />
              </span>
            </div>
          ) : (
            <></>
          )}
          <button className={style.logoutBtn} onClick={onClickLogoutBtn}>
            {t("logout")}
          </button>
        </div>
      </div>
    </div>
  );
}
