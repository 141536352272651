import { useCallback } from "react";
import { useAppDispatch } from "../../../hooks/hooks";
import { createCodeAsync } from "../../../store/affiliateCodeSlice";
import { AffiliateCodePostParam } from "../../../types/affiliateCode";
import { useUtil } from "../../../util/hooks/useUtil";

export function useAffiliateCodeCreator() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const createCode = useCallback(
    async (param: AffiliateCodePostParam) => {
      if (!window.confirm("제휴코드를 등록 하시겠습니까?")) return;

      try {
        await dispatch(createCodeAsync(param)).unwrap();
        alert("제휴코드가 등록되었습니다.");
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("제휴 코드 등록 에러");
      }
    },
    [dispatch, defaultErrorMessage]
  );

  return {
    createCode,
  };
}
