import { useCallback, useMemo } from "react";
import { CouponHistoryStatus, CouponStatus, CouponType } from "../coupon";
import { useTranslation } from "react-i18next";
import { SelectorOption } from "../selector";
import moment from "moment/moment";

export function useCouponType() {
  const { t } = useTranslation(["coupon", "common"]);

  const toStringFromCouponType = useCallback(
    (type: CouponType) => {
      switch (type) {
        case CouponType.TOTAL_AMOUNT_DISCOUNT:
          return t("couponType.amountDiscount");
        case CouponType.PRODUCT_DISCOUNT:
          return t("couponType.productDiscount");
        case CouponType.GOODS:
          return t("couponType.goods");
        default:
          return "";
      }
    },
    [t]
  );

  const couponTypeOptions: SelectorOption[] = useMemo(() => {
    const list = Object.keys(CouponType).flatMap((key) => {
      const valueObj = CouponType[key as keyof typeof CouponType];

      if (typeof valueObj === "string") {
        return [
          {
            value: key,
            label: toStringFromCouponType(valueObj),
          },
        ];
      } else {
        return [];
      }
    });

    list.unshift({ value: "", label: t("all", { ns: "common" }) });

    return list;
  }, [toStringFromCouponType, t]);

  const couponTypeOptionsExceptGoods: SelectorOption[] = useMemo(
    () => couponTypeOptions.filter((type) => type.value !== CouponType.GOODS.toString()),
    [couponTypeOptions]
  );

  const toStringFromCouponStatus = useCallback(
    (status: CouponStatus) => {
      switch (status) {
        case CouponStatus.DELETED:
          return t("couponStatus.deleted");
        case CouponStatus.IS_ISSUING:
          return t("couponStatus.isIssuing");
        case CouponStatus.STOP_ISSUING:
          return t("couponStatus.stopIssuing");
        default:
          return "";
      }
    },
    [t]
  );

  const couponStatusOptions = useMemo(() => {
    const list = Object.keys(CouponStatus).flatMap((key) => {
      const valueObj = CouponStatus[key as keyof typeof CouponStatus];

      if (typeof valueObj === "string") {
        return [
          {
            value: key,
            label: toStringFromCouponStatus(valueObj),
          },
        ];
      } else {
        return [];
      }
    });

    list.unshift({ value: "", label: t("all", { ns: "common" }) });

    return list;
  }, [t, toStringFromCouponStatus]);

  const toStringFromCouponHistoryStatus = useCallback(
    (status: CouponHistoryStatus, availableEndAt?: string) => {
      if (availableEndAt && moment.utc(availableEndAt).isBefore(moment())) {
        return t("couponHistoryStatus.expired");
      }

      switch (status) {
        case CouponHistoryStatus.BEFORE_USE:
          return t("couponHistoryStatus.usable");
        case CouponHistoryStatus.USED:
        case CouponHistoryStatus.RESERVED:
          return t("couponHistoryStatus.used");
        case CouponHistoryStatus.DELETED:
          return t("couponHistoryStatus.deleted");
        default:
          return "";
      }
    },
    [t]
  );

  return {
    toStringFromCouponType,
    couponTypeOptions,
    toStringFromCouponStatus,
    couponStatusOptions,
    toStringFromCouponHistoryStatus,
    couponTypeOptionsExceptGoods,
  };
}
