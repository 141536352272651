import { useNavigate } from "react-router-dom";
import { AccountResult } from "../../layout/account/AccountResult";
import { useTranslation } from "react-i18next";

export function SignUpCompleted() {
  const { t } = useTranslation("account");
  const navigate = useNavigate();

  return (
    <AccountResult
      title={t("signUpCompleted.title")}
      description={t("signUpCompleted.description")}
      buttonText={t("signUpCompleted.button")}
      buttonAction={() => navigate(`/login`)}
    />
  );
}
