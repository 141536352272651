import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "./memberUserDetail.module.scss";
import personIcon from "../../../assets/images/button_icons/person_icon.svg";
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import confirmIcon from "../../../assets/images/button_icons/confirm_icon.svg";
import voucherIcon from "../../../assets/images/button_icons/voucher_icon.svg";
import couponIcon from "../../../assets/images/button_icons/coupon_icon.svg";
import checkIcon from "../../../assets/images/button_icons/check_icon.svg";
import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../../hooks/hooks";
import { memberUserStatus, unblockUsersAsync } from "../../../store/userSlice";
import { UserPutParam, UserStatus } from "../../../types/user";
import { Panel } from "../../../layout/content/detail/Panel";
import { DescriptionList } from "../../../layout/content/detail/DescriptionList";
import { useUserSelfInfoData } from "./hooks/useUserSelfInfoData";
import { ActionUserType } from "../../../types/dataCommon";
import { useLoader } from "../../../hooks/common/useLoader";
import { BlockMemberParam, GrantCouponParam } from "../../../types/newWindow";
import { MemoComponent } from "../../component/memo/MemoComponent";
import { MemoTargetType } from "../../../types/memo";
import { DetailActionButton } from "../../../components/Buttons";
import { RoleChecker } from "../../role/RoleChecker";
import { AdminMenuCode } from "../../../types/adminRole";
import { useUserGetter } from "./hooks/useUserGetter";
import { CustomerType } from "../../../types/common";
import { useUserDetailUpdater } from "./hooks/useUserDetailUpdater";
import { useUserVoucherStatus } from "./coupon_and_voucher/hooks/useUserVoucherStatus";
import { isValidDate } from "../../../util/dateUtil";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  idx: number;
}

export function MemberUserSelfInfo({ idx }: Props) {
  const { t } = useTranslation("user");
  const dispatch = useAppDispatch();
  const { user, getUser } = useUserGetter();
  const status = useAppSelector(memberUserStatus);
  const formRef = useRef<HTMLFormElement>(null);
  const { openBlockMemberPopup, openGrantCoupon, openGrantProduct } = useNewWindow();
  const { defaultErrorMessage } = useUtil();

  useLoader({ status });

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [userPutParam, setUserPutParam] = useState<UserPutParam | null>(null);

  const loadUser = useCallback(
    (userIdx: number): void => {
      if (!user || user?.idx !== userIdx) {
        getUser(userIdx);
      }
    },
    [getUser, user]
  );

  const { updateUser } = useUserDetailUpdater();
  const { getDescList, getLeavedUserDescList, resetData } = useUserSelfInfoData({
    user,
    getUserDetail: getUser,
    onUpdateUserPutParam: setUserPutParam,
  });

  const onClickBlockBtn = () => {
    const param: BlockMemberParam = {
      memberIdxArr: [idx],
      userType: ActionUserType.USER,
    };
    openBlockMemberPopup(param, () => getUser(idx));
  };

  useEffect(() => {
    if (idx) {
      loadUser(idx);
    }
  }, [idx, loadUser]);

  const onClickGrantCouponBtn = useCallback(() => {
    const param: GrantCouponParam = {
      targetIdx: idx,
      customerType: CustomerType.USER,
    };
    openGrantCoupon(param);
  }, [idx, openGrantCoupon]);

  const { getVoucherStatusInfo } = useUserVoucherStatus({ userIdx: idx });

  const onClickGrantProductBtn = useCallback(() => {
    openGrantProduct({ targetIdxes: [idx], targetType: CustomerType.USER, targetName: user?.name ?? "" }, () =>
      getVoucherStatusInfo()
    );
  }, [idx, user?.name, openGrantProduct, getVoucherStatusInfo]);

  const onClickUpdateUserButton = useCallback(() => {
    if (userPutParam == null) return;
    if (!formRef.current?.reportValidity()) return;

    if (userPutParam.birthday) {
      const birthdayString = userPutParam.birthday
        .split("")
        .map((num, idx) => `${num}${idx === 3 || idx === 5 ? "-" : ""}`)
        .join("");
      const birthdayDate = new Date(`${birthdayString} 00:00:00`);
      if (!isValidDate(birthdayDate)) {
        alert(t("detail.userInfo.update.validationMessages.incorrectBirthday"));
        return;
      }

      const today = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`);
      if (today < birthdayDate) {
        alert(t("detail.userInfo.update.validationMessages.futureBirthday"));
        return;
      }

      const date14YearsAgo = new Date(`${today.getFullYear() - 14}-${today.getMonth() + 1}-${today.getDate()}`);
      if (date14YearsAgo.getTime() < birthdayDate.getTime()) {
        alert(t("detail.userInfo.update.validationMessages.underAge14"));
        return;
      }
    }

    const param: UserPutParam = {
      ...userPutParam,
      gender: userPutParam.gender ? userPutParam.gender : undefined,
      birthday: userPutParam.birthday ? userPutParam.birthday : undefined,
    };

    callAsync(
      updateUser(idx, param),
      (_) => {
        alert(t("detail.userInfo.update.result"));
        getUser(idx);
        setIsEditMode(false);
      },
      () => {
        alert(defaultErrorMessage);
      }
    ).then();
  }, [setIsEditMode, idx, userPutParam, t, defaultErrorMessage, getUser, updateUser]);

  useEffect(() => {
    if (!formRef.current) return;
    formRef.current.onsubmit = function () {
      return false;
    };
  }, [formRef]);

  const isLeaved = useCallback((status: UserStatus) => status === UserStatus.LEAVE, []);
  const isBlocked = useCallback((status: UserStatus) => status === UserStatus.BLOCKED, []);

  const onClickUnblockButton = useCallback(() => {
    const isConfirmed = window.confirm(t("detail.userInfo.unblock.confirmMessage", { name: user?.name ?? "" }));
    if (isConfirmed) {
      callAsync(
        dispatch(unblockUsersAsync([idx])).unwrap(),
        () => getUser(idx),
        () => alert(defaultErrorMessage)
      ).then();
    }
  }, [dispatch, getUser, t, user?.name, idx, defaultErrorMessage]);

  return (
    <>
      {user ? (
        <>
          <Panel
            title={t("detail.userInfo.title")}
            additionalButtons={
              isEditMode ? (
                <>
                  <DetailActionButton
                    onClick={() => {
                      setIsEditMode(false);
                      resetData();
                    }}
                  >
                    {t("detail.userInfo.update.cancelButton")}
                  </DetailActionButton>
                  <DetailActionButton onClick={() => onClickUpdateUserButton()}>
                    <img src={confirmIcon} alt={t("detail.userInfo.update.personIcon")} />
                    {t("detail.userInfo.update.saveButton")}
                  </DetailActionButton>
                </>
              ) : (
                <>
                  {isLeaved(user.status) ? (
                    <></>
                  ) : (
                    <>
                      {isBlocked(user.status) ? (
                        <>
                          <DetailActionButton onClick={onClickUnblockButton}>
                            <img src={checkIcon} alt={t("detail.userInfo.unblock.checkIcon")} />
                            {t("detail.userInfo.unblock.button")}
                          </DetailActionButton>
                        </>
                      ) : (
                        <>
                          <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_UPDATE}>
                            <DetailActionButton onClick={() => setIsEditMode(true)}>
                              <img src={personIcon} alt={t("detail.userInfo.update.personIcon")} />
                              {t("detail.userInfo.update.updateButton")}
                            </DetailActionButton>
                          </RoleChecker>
                          <RoleChecker menuCode={AdminMenuCode.ISSUE_COUPON}>
                            <DetailActionButton onClick={onClickGrantCouponBtn}>
                              <img src={couponIcon} alt={t("detail.userInfo.update.couponIcon")} />
                              {t("detail.userInfo.update.couponButton")}
                            </DetailActionButton>
                          </RoleChecker>
                          <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_GRANT_PRODUCT}>
                            <DetailActionButton onClick={onClickGrantProductBtn}>
                              <img src={voucherIcon} alt={t("detail.userInfo.update.voucherIcon")} />
                              {t("detail.userInfo.update.grantVoucherButton")}
                            </DetailActionButton>
                          </RoleChecker>
                        </>
                      )}
                    </>
                  )}
                </>
              )
            }
          >
            <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
              <DescriptionList
                data={isLeaved(user.status) ? getLeavedUserDescList(user) : getDescList(user)}
                isEditMode={isEditMode}
                labelWidth={132}
              />
            </form>
          </Panel>

          <RoleChecker menuCode={AdminMenuCode.USER_MEMO}>
            <MemoComponent targetIdx={idx} targetType={MemoTargetType.USER} isWritable={!isLeaved(user.status)} />
          </RoleChecker>
          <div className={style.buttonArea}>
            {isLeaved(user.status) || isBlocked(user.status) ? (
              <></>
            ) : (
              <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_BLOCK}>
                <button className={style.blockBtn} onClick={onClickBlockBtn}>
                  <img src={deleteIcon} alt={t("detail.userInfo.block.deleteIcon")} />
                  {t("detail.userInfo.block.button")}
                </button>
              </RoleChecker>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
