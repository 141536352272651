import style from "../../memberUserCoupons.module.scss";
import React, { useEffect, useState } from "react";
import { Panel } from "../../../../../layout/content/detail/Panel";
import { useUserOrganVoucherInfoList } from "../hooks/useUserOrganVoucherInfoList";
import { useUserOrganVoucherInfoColumns } from "../hooks/useUserOrganVoucherInfoColumns";
import { Table } from "../../../../../layout/content/list/table/Table";
import { PaginationWrapper } from "../../../../../layout/content/list/PaginationWrapper";
import { Pagination } from "../../../../../layout/content/list/Pagination";
import { useUserOrganVoucherStatusGetter } from "../hooks/useUserOrganVoucherStatusGetter";
import { useTranslation } from "react-i18next";
import { OrderDirection } from "../../../../../types/page";

interface Props {
  userIdx: number;
  name: string;
}

export function OrganVoucherInfoList({ userIdx, name }: Props) {
  const { t } = useTranslation("user");
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const { organVoucherHistoryList, getOrganVoucherHistoryList } = useUserOrganVoucherInfoList({
    userIdx,
    isAvailable,
  });
  const { getOrganVoucherStatusInfo } = useUserOrganVoucherStatusGetter({ userIdx });
  const { columns } = useUserOrganVoucherInfoColumns({
    userIdx,
    name,
    onCollectCompleted: () => {
      getOrganVoucherHistoryList({})();
      getOrganVoucherStatusInfo();
    },
  });

  useEffect(() => {
    getOrganVoucherHistoryList({ sort: [{ columnName: "grantedAt", direction: OrderDirection.DESC }] })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  return (
    <Panel
      title={t("detail.organVoucherInfo.list.label")}
      additionalButtons={
        <label className={style.headerCheckboxLabel}>
          <input type={"checkbox"} readOnly={true} checked={isAvailable} onClick={() => setIsAvailable(!isAvailable)} />
          {t("detail.organVoucherInfo.list.viewOnlyAvailableItems")}
        </label>
      }
    >
      <>
        {organVoucherHistoryList ? (
          <>
            <Table
              listData={organVoucherHistoryList}
              columns={columns}
              getList={getOrganVoucherHistoryList}
              noDataText={t("detail.organVoucherInfo.list.noData")}
            />
            {organVoucherHistoryList.content.length === 0 ? (
              <></>
            ) : (
              <PaginationWrapper>
                <Pagination
                  page={organVoucherHistoryList}
                  goSpecificPage={(page: number) => getOrganVoucherHistoryList({ page })()}
                />
              </PaginationWrapper>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </Panel>
  );
}
