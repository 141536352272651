import style from "./searchField.module.scss";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: string;
  onKeyUpFunc: (e: React.KeyboardEvent) => void;
  onChangeFunc: (e: React.ChangeEvent) => void;
  onClickSearchButtonFunc: (e: React.MouseEvent) => void;
  placeholder?: string;
  buttonText?: string;
  className?: string;
  isRequired?: boolean;
  minLength?: number;
  maxLength?: number;
}

export function SearchField({
  value,
  onKeyUpFunc,
  onChangeFunc,
  onClickSearchButtonFunc,
  placeholder,
  buttonText,
  className,
  isRequired,
  minLength,
  maxLength,
}: Props) {
  const { t } = useTranslation("common");

  return (
    <div className={`${style.searchField} ${className ?? ""}`}>
      <input
        type={"text"}
        placeholder={placeholder ?? ""}
        value={value}
        required={isRequired}
        onKeyUp={onKeyUpFunc}
        onChange={onChangeFunc}
        minLength={minLength}
        maxLength={maxLength}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
      <button onClick={onClickSearchButtonFunc}>{buttonText ?? t("search")}</button>
    </div>
  );
}
