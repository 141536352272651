import React from "react";
import { Language } from "../../types/language";
import { SelectorOption } from "../../types/selector";

export interface LanguageContextType {
  language: Language;
  setLang: (_: Language) => void;
  changeLanguage: (_: Language) => void;
  toStringFromLanguage: (_: Language) => string;
  isKorean: boolean;
  languageOptions: SelectorOption[];
}

export const LanguageContext = React.createContext<LanguageContextType>(null!);
