import { useAppDispatch } from "../../../hooks/hooks";
import { useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import { CouponListObj, CouponSearchCondition, CouponStatus } from "../../../types/coupon";
import { callAsync } from "../../../util/sliceUtil";
import { getCouponListAsync } from "../../../store/couponSlice";
import { useUtil } from "../../../util/hooks/useUtil";

interface Args {
  pageSize?: number;
  searchWord?: string;
  couponStatus?: CouponStatus | "";
}

export function useConditionalCouponList({ searchWord, couponStatus, pageSize }: Args) {
  const dispatch = useAppDispatch();

  const [couponList, setCouponList] = useState<PageResponse<CouponListObj>>();
  const [totalCnt, setTotalCnt] = useState(0);
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: couponList?.number ?? 0,
      size: couponList?.size ?? pageSize,
      sort: ordersToSortArr(couponList?.orders),
    }),
    [couponList, pageSize]
  );

  const getCouponList = ({ page, sort, size }: PageSearchCondition, isFirstLoad?: boolean) => {
    const param: CouponSearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      },
      q: searchWord?.trim(),
      status: couponStatus ? couponStatus : undefined,
    };

    return () => {
      callAsync<PageResponse<CouponListObj>>(
        dispatch(getCouponListAsync(param)).unwrap(),
        (result) => {
          setCouponList(result);
          if (isFirstLoad) setTotalCnt(result.totalElements);
        },
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    };
  };

  return {
    getCouponList,
    totalCnt,
    couponList,
  };
}
