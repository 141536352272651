import { UnderlineSelector } from "./UnderlineSelector";
import { useCountry } from "../hooks/hooks";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  code: string;
  setCode: (_: string) => void;
}
export function PhoneCountryCodeUnderlineSelector({ code, setCode }: Props) {
  const { t } = useTranslation("common");
  const { phoneNumberOptions } = useCountry();

  const countryCodeOptionsWithBlank = useMemo(
    () => [{ value: "", label: t("selectCountry") }, ...phoneNumberOptions],
    [phoneNumberOptions, t]
  );
  return (
    <UnderlineSelector
      isRequired={true}
      options={countryCodeOptionsWithBlank}
      selectedValue={code}
      setSelectorOption={(o) => setCode(o.value)}
    />
  );
}
