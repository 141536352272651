import { useAppDispatch } from "../../../hooks/hooks";
import { useCallback, useState } from "react";
import {
  getProductDetailAsync,
  getProductImagesAsync,
  getProductItemsByProductAsync,
} from "../../../store/productSlice";
import { ProductDetailImageDTO, ProductDTO, ProductVO } from "../../../types/product";
import { ProductItemDTO } from "../../../types/productItem";
import { useUtil } from "../../../util/hooks/useUtil";

export function useProductGetter() {
  const dispatch = useAppDispatch();
  const [productVO, setProductVO] = useState<ProductVO>();
  const { defaultErrorMessage } = useUtil();

  const getProductDetail = useCallback(
    (productIdx: number): Promise<ProductDTO> => dispatch(getProductDetailAsync(productIdx)).unwrap(),
    [dispatch]
  );

  const getProductImages = useCallback(
    (productIdx: number): Promise<ProductDetailImageDTO> => dispatch(getProductImagesAsync(productIdx)).unwrap(),
    [dispatch]
  );

  const getProductItems = useCallback(
    (productIdx: number): Promise<ProductItemDTO[]> => dispatch(getProductItemsByProductAsync(productIdx)).unwrap(),
    [dispatch]
  );

  const getProductVO = useCallback(
    async (productIdx: number): Promise<ProductVO | undefined> => {
      try {
        const productDTO = await getProductDetail(productIdx);
        const imageDTO = await getProductImages(productIdx);
        const itemsDTO = await getProductItems(productIdx);

        const productVO = ProductVO.getProductVO(productDTO, itemsDTO, imageDTO);
        setProductVO(productVO);
        return productVO;
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
      }
    },
    [getProductDetail, getProductImages, getProductItems, defaultErrorMessage]
  );

  return {
    productVO,
    getProductVO,
  };
}
