import { MainApi } from "./MainApi";
import {
  GrantableProductPostParam,
  ProductPostParam,
  ProductPutParam,
  ProductSearchCondition,
  ProductStatusUpdateParam,
} from "../types/product";

export class ProductApi {
  static baseUrl = `${MainApi.urlPrefix}/product`;

  static getProductDetail = (productIdx: number) => () => MainApi.api.get(`${ProductApi.baseUrl}/${productIdx}`);

  static getProductList = (condition: ProductSearchCondition) => () => {
    return MainApi.api.get(`${ProductApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);
  };

  static createProduct = (param: ProductPostParam) => () => MainApi.api.post(`${ProductApi.baseUrl}`, param);

  static createGrantableProduct = (param: GrantableProductPostParam) => () =>
    MainApi.api.post(`${ProductApi.baseUrl}/grant`, param);

  static updateStatus = (param: ProductStatusUpdateParam) => () =>
    MainApi.api.put(`${ProductApi.baseUrl}/status${MainApi.toParamStringFromObject(param)}`);

  static getProductItemsByProductIdx = (productIdx: number) => () =>
    MainApi.api.get(`${ProductApi.baseUrl}/${productIdx}/items`);

  static getProductImages = (productIdx: number) => () => MainApi.api.get(`${ProductApi.baseUrl}/${productIdx}/image`);

  static deleteProducts = (productIdxes: number[]) => () =>
    MainApi.api.delete(`${ProductApi.baseUrl}${MainApi.toParamStringFromObject({ idxes: productIdxes })}`);

  static updateProduct = (productIdx: number, param: ProductPutParam) => () =>
    MainApi.api.put(`${ProductApi.baseUrl}/${productIdx}`, param);

  static askProductsDeletableOrNot = (productIdxes: number[]) => () =>
    MainApi.api.get(`${ProductApi.baseUrl}/deletable${MainApi.toParamStringFromObject({ idxes: productIdxes })}`);
}
