import styled from "styled-components/macro";
import { SettlementYearAndMonthWrapper } from "./settlement/SettlementYearAndMonthWrapper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SettlementStatus } from "../../types/affiliateCode";
import { useListFilterValues } from "../../hooks/hooks";
import { AffiliateCodeSettlementListFilterValues } from "./settlement/hooks/useAffiliateCodeSettlementFilterValues";
import { SettlementStatistics } from "./settlement/SettlementStatistics";
import { SettlementDetailList } from "./settlement/SettlementDetailList";

const WrapperStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function SettlementList() {
  const { filterValues, searchList } = useListFilterValues();
  const { yearFilterValue, monthFilterValue } = useMemo(
    () => filterValues as AffiliateCodeSettlementListFilterValues,
    [filterValues]
  );
  const [tmpYear, setTmpYear] = useState<string>(yearFilterValue.value);
  const [tmpMonth, setTmpMonth] = useState<string>(monthFilterValue.value);

  useEffect(() => {
    if (tmpYear && tmpMonth) {
      searchList?.(
        {},
        {
          year: tmpYear,
          month: tmpMonth,
          status: SettlementStatus.ALL,
        }
      )();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tmpYear, tmpMonth]);

  const changeYearNMonth = useCallback((year: string, month: string) => {
    setTmpYear(year);
    setTmpMonth(month);
  }, []);

  const refreshStaticsRef = useRef<(y: string, m: string) => void>(() => {});
  const refreshStatics = useCallback(() => {
    refreshStaticsRef?.current(yearFilterValue.value, monthFilterValue.value);
  }, [yearFilterValue.value, monthFilterValue.value]);

  return (
    <WrapperStyle>
      <SettlementYearAndMonthWrapper
        setYearAndMonth={(y, m) => {
          changeYearNMonth(y, m);
        }}
      />
      <SettlementStatistics refreshRef={refreshStaticsRef} />
      <SettlementDetailList refreshStatics={refreshStatics} />
    </WrapperStyle>
  );
}
