import { ProductStatus } from "../../types/product";
import { useProductFilters } from "./hooks/useProductFilters";
import { useProductList } from "./hooks/useProductList";
import React, { useCallback, useState } from "react";
import { ListTable } from "../../layout/content/list/ListTable";
import { useProductColumns } from "./hooks/useProductColumns";
import deleteIcon from "../../assets/images/button_icons/delete_icon.svg";
import confirmIcon from "../../assets/images/button_icons/confirm_icon.svg";
import stopIcon from "../../assets/images/button_icons/stop_icon.svg";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { callAsync } from "../../util/sliceUtil";
import { deleteProductsAsync, productStatus, updateProductStatusAsync } from "../../store/productSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../util/hooks/useUtil";
import { ListCount, WhichList } from "../../components/ListCount";
import { useProductDeletableOrNotGetter } from "./hooks/useProductDeletableOrNotGetter";

export function ProductList() {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const status = useAppSelector(productStatus);
  useLoader({ status });

  const [selectedProductIdxList, setSelectedProductIdxList] = useState<number[]>([]);

  const { searchProductList, totalCnt, productList, pageCondition, getProductListWithParam } = useProductList({
    onCompletedCallback: () => setSelectedProductIdxList([]),
  });
  const { filters } = useProductFilters({ search: searchProductList({}) });

  const changeSellingStatus = useCallback(
    (productIdxList: number[], nextStatus: ProductStatus) => {
      callAsync(
        dispatch(updateProductStatusAsync({ idxes: productIdxList, status: nextStatus })).unwrap(),
        () => {
          setSelectedProductIdxList([]);
          searchProductList({})();
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, searchProductList, defaultErrorMessage]
  );

  const columns = useProductColumns({
    stopSelling: (idxArr) => {
      const isConfirmed = window.confirm(t("stopSelling.confirm1"));
      if (isConfirmed) {
        changeSellingStatus(idxArr, ProductStatus.STOP_SELLING);
      }
    },
    resumeSelling: (idxArr) => {
      const isConfirmed = window.confirm(t("resumeSelling.confirm1"));
      if (isConfirmed) {
        changeSellingStatus(idxArr, ProductStatus.IS_SELLING);
      }
    },
    getProductList: getProductListWithParam,
    hasProductLink: true,
  });

  const onClickStopSellingBtn = useCallback(() => {
    if (selectedProductIdxList.length === 0) {
      alert(t("noSelectedProduct"));
      return;
    }

    const isConfirmed = window.confirm(t("stopSelling.confirm2"));

    if (isConfirmed) {
      changeSellingStatus(selectedProductIdxList, ProductStatus.STOP_SELLING);
    }
  }, [t, selectedProductIdxList, changeSellingStatus]);

  const onClickResumeSellingBtn = useCallback(() => {
    if (selectedProductIdxList.length === 0) {
      alert(t("noSelectedProduct"));
      return;
    }

    const isConfirmed = window.confirm(t("resumeSelling.confirm2"));

    if (isConfirmed) {
      changeSellingStatus(selectedProductIdxList, ProductStatus.IS_SELLING);
    }
  }, [t, selectedProductIdxList, changeSellingStatus]);

  const { getProductDeletableOrNot } = useProductDeletableOrNotGetter();
  const onClickDeleteBtn = async () => {
    if (selectedProductIdxList.length === 0) {
      alert(t("noSelectedProduct"));
      return;
    }

    const allDeletable = await getProductDeletableOrNot(selectedProductIdxList);
    if (!allDeletable) {
      alert(t("cannotDelete"));
      return;
    }

    const isConfirmed = window.confirm(t("delete.confirm"));
    if (isConfirmed) {
      callAsync(
        dispatch(deleteProductsAsync(selectedProductIdxList)).then(),
        () => {
          setSelectedProductIdxList([]);
          searchProductList(pageCondition)();
        },
        () => alert(defaultErrorMessage)
      ).then();
    }
  };

  return (
    <>
      {productList ? (
        <ListTable
          pageOnFilter={pageCondition}
          filters={filters}
          listData={productList}
          getList={({ page, sort, size }) => searchProductList({ page, sort, size })}
          columns={columns}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalCnt} whichList={WhichList.PRODUCT} />,
            singleUnitKeyInCommonFile: "productUnit.singleComponent",
            multipleUnitKeyInCommonFile: "productUnit.multipleComponent",
          }}
          checkedRowIdxArr={selectedProductIdxList}
          setCheckedRowIdxArr={setSelectedProductIdxList}
          buttonsNode={
            <>
              <div>
                <button onClick={onClickDeleteBtn}>
                  <img src={deleteIcon} alt={t("delete.alt")} />
                  {t("delete.button")}
                </button>
              </div>
              <div>
                <button onClick={onClickStopSellingBtn}>
                  <img src={stopIcon} alt={t("stopSelling.alt")} />
                  {t("stopSelling.button")}
                </button>
                <button onClick={onClickResumeSellingBtn}>
                  <img src={confirmIcon} alt={t("resumeSelling.alt")} />
                  {t("resumeSelling.button")}
                </button>
              </div>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}
