import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { deleteAdminAsync } from "../../../../store/adminSlice";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Args {
  onRefresh: () => void;
}
export function useAdminDeleter({ onRefresh }: Args) {
  const { t } = useTranslation("admin");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deleteAdmin = useCallback(
    (adminIdx: number) => {
      if (window.confirm(t("buttons.delete.confirmMessage"))) {
        callAsync(
          dispatch(deleteAdminAsync(adminIdx)).unwrap(),
          () => {
            alert(t("buttons.delete.resultMessage"));
            onRefresh();
          },
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, t, onRefresh, defaultErrorMessage]
  );

  return {
    deleteAdmin,
  };
}
