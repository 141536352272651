import listStyle from "../list.module.scss";
import React from "react";
import { PageResponse, PageSearchCondition } from "../../../../types/page";
import { ListObject, TableColumnData } from "../../../../types/common";
import { RoleChecker } from "../../../../features/role/RoleChecker";
import { TableTds } from "./TableTds";
import { useTable } from "./useTable";
import { useLanguage } from "../../../../hooks/hooks";

interface Props<T extends ListObject> {
  listData: PageResponse<T>;
  checkboxColumnWidth?: number;
  checkedRowIdxArr?: number[];
  setCheckedRowIdxArr?: (_: number[]) => void;
  columns: TableColumnData<T>[];
  getList: (pageCondition: PageSearchCondition) => VoidFunction;
  noDataText: string;
  showCheckbox?: (_: T) => boolean;
  tableClassName?: string;
  tbodyClassName?: string;
  noResultRowClassName?: string;
}

export function BodyScollTable<T extends ListObject>({
  listData,
  checkboxColumnWidth,
  checkedRowIdxArr,
  setCheckedRowIdxArr,
  showCheckbox,
  columns,
  getList,
  noDataText,
  tableClassName,
  tbodyClassName,
  noResultRowClassName,
}: Props<T>) {
  const { showOrderNode, onChangeHeaderCheckbox, onChangeRowCheckbox, isAllChecked } = useTable({
    listData,
    getList,
    setCheckedRowIdxArr,
    checkedRowIdxArr,
    showCheckbox,
  });
  const { isKorean } = useLanguage();

  return (
    <table className={`${listStyle.table} ${listStyle.scrollingTable} ${tableClassName}`}>
      <thead>
        <tr>
          {checkedRowIdxArr ? (
            <th
              className={`${listStyle.th} ${isKorean ? "" : listStyle.notKO}`}
              style={{ width: `${checkboxColumnWidth}px` }}
            >
              <input
                className={listStyle.checkbox}
                checked={isAllChecked}
                type={"checkbox"}
                onChange={onChangeHeaderCheckbox}
              />
            </th>
          ) : (
            <></>
          )}
          {columns.map((c, idx) => {
            const element = (
              <th
                key={`table-th-${idx}`}
                className={`${listStyle.th} ${isKorean ? "" : listStyle.notKO}`}
                style={{ width: `${c.width}px` }}
              >
                <div className={listStyle.header}>
                  {c.header}
                  {showOrderNode(c)}
                </div>
              </th>
            );

            return (
              <React.Fragment key={`table-th-${idx}}`}>
                {c.menuCode ? (
                  <RoleChecker menuCode={c.menuCode}>{element}</RoleChecker>
                ) : c.hideThisColumn ? (
                  <></>
                ) : (
                  element
                )}
              </React.Fragment>
            );
          })}
        </tr>
      </thead>
      <tbody className={`${listStyle.scrollingBody} ${tbodyClassName}`}>
        {listData.content.length === 0 ? (
          <tr className={`${listStyle.noResultRow} ${noResultRowClassName}`}>
            <td className={`${listStyle.td}`} colSpan={columns.length + (checkedRowIdxArr ? 1 : 0)}>
              {noDataText}
            </td>
          </tr>
        ) : (
          listData.content.map((rowData, dataIdx) => (
            <tr
              key={`table-tbody-tr-${dataIdx}}`}
              className={checkedRowIdxArr && checkedRowIdxArr.includes(rowData.idx) ? listStyle.checked : ""}
            >
              {checkedRowIdxArr ? (
                <td className={listStyle.td} style={{ width: `${checkboxColumnWidth}px` }}>
                  {!showCheckbox || (showCheckbox && showCheckbox(rowData)) ? (
                    <input
                      className={listStyle.checkbox}
                      type={"checkbox"}
                      data-idx={rowData.idx}
                      checked={checkedRowIdxArr.some((idx) => idx === rowData.idx)}
                      onChange={onChangeRowCheckbox}
                    />
                  ) : (
                    <></>
                  )}
                </td>
              ) : (
                <></>
              )}
              <TableTds columns={columns} rowData={rowData} dataIdx={dataIdx} />
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
}
