import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AdminStatus } from "../admin";
import { SelectorOption } from "../selector";

export function useAdminType() {
  const { t } = useTranslation(["admin", "common"]);

  const toStringFromAdminStatus = useCallback(
    (adminStatus: AdminStatus) => {
      switch (adminStatus) {
        case AdminStatus.BLOCKED:
          return t("type.adminStatus.blocked");
        case AdminStatus.BEFORE_SELF_AUTHENTICATION:
          return t("type.adminStatus.beforeSelfAuth");
        case AdminStatus.BEFORE_ADMIN_AUTHENTICATION:
          return t("type.adminStatus.beforeAdminAuth");
        case AdminStatus.LEAVE:
          return t("type.adminStatus.leaved");
        case AdminStatus.DEFAULT:
          return t("type.adminStatus.default");
      }
    },
    [t]
  );

  const getAdminStatusOptions = useCallback((): SelectorOption[] => {
    const options: SelectorOption[] = Object.keys(AdminStatus).flatMap((it) => {
      let adminStatus = AdminStatus[it as keyof typeof AdminStatus];
      if (typeof adminStatus !== "string") {
        return [];
      }
      return [
        {
          value: it,
          label: toStringFromAdminStatus(adminStatus),
        },
      ];
    });

    options.unshift({ value: "", label: t("all", { ns: "common" }) });
    return options;
  }, [t, toStringFromAdminStatus]);

  return {
    toStringFromAdminStatus,
    getAdminStatusOptions,
  };
}
