import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { useUtil } from "../../../../util/hooks/useUtil";
import { getPartnerCompanyAsync } from "../../../../store/partnerCompanySlice";
import { PartnerCompanyDTO, PartnerCompanyVO } from "../../../../types/partnerCompany";

export function usePartnerCompanyGetter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const getPartnerCompany = useCallback(
    async (idx: number): Promise<PartnerCompanyVO> => {
      try {
        const dto: PartnerCompanyDTO = await dispatch(getPartnerCompanyAsync(idx)).unwrap();
        return {
          idx: dto.idx,
          name: dto.name,
          type: dto.type,
          status: dto.status,
          logo: dto.logo,
          address: dto.address,
          webSite: dto.webSite,
          affiliateCode: dto.affiliateCodeDTO,
        } as PartnerCompanyVO;
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("협력사 조회 에러");
      }
    },
    [dispatch, defaultErrorMessage]
  );

  return {
    getPartnerCompany,
  };
}
