import { useAppDispatch, useTimeConverter } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { createPopupAsync, setIsPopupDataSaved } from "../../../../store/popupSlice";
import { PopupPostVO } from "../../../../types/popup";
import { FileClassification, FileResponse } from "../../../../types/file";
import { useFile } from "../../../../hooks/file/useFile";

export function usePopupCreator() {
  const dispatch = useAppDispatch();
  const { timeConverter } = useTimeConverter();
  const { uploadImages } = useFile();

  const createPopup = useCallback(
    async ({
               name, availableStartAt, availableEndAt, type, image, imageLink, linkOption, status, websiteType, language
    }: PopupPostVO) => {
      try {
        const files: FileResponse[] = await uploadImages([image.file], FileClassification.POP_UP_IMAGE);
        await dispatch(
          createPopupAsync({
            name: name.trim(),
            availableStartAt: timeConverter.convertStartOfDayToUTC(availableStartAt),
            availableEndAt: availableEndAt ? timeConverter.convertEndOfDayToUTC(availableEndAt) : undefined,
            type,
            image: {
              uri: files[0].uri,
              fileIdx: files[0].idx,
            },
            imageLink: imageLink?.trim(),
            linkOption,
            status,
            websiteType,
            language,
          })
        ).unwrap();
        dispatch(setIsPopupDataSaved(true));
      } catch (e) {
        console.error(e);
        throw new Error("팝업 등록 에러");
      }
    },
    [dispatch, uploadImages, timeConverter]
  );

  return {
    createPopup,
  };
}
