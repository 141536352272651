import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { DetailSubMenus, DetailTab } from "../../../layout/content/detail/DetailSubMenus";
import { ProfileDetail } from "../user/tester/ProfileDetail";
import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { WindowMessage } from "../../../types/newWindow";
import { useNewWindow } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";

enum MemberTesterDetailTabType {
  SELF_INFO = "SELF_INFO",
}

export function MemberCreatedTesterDetail() {
  const { t } = useTranslation("tester");

  const navigate = useNavigate();
  const { id } = useParams<"id">();
  const profileIdx = useMemo(() => Number(id), [id]);
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  const tabMenus: DetailTab<MemberTesterDetailTabType>[] = [
    {
      tabValue: MemberTesterDetailTabType.SELF_INFO,
      tabName: t("detail.submenus.testerInfo"),
    },
  ];

  const onClickTabMenu = useCallback(() => {
    navigate(`/detail/member/tester/${id}`);
  }, [id, navigate]);

  return (
    <>
      <DetailSubMenus tabs={tabMenus} onClickTab={onClickTabMenu} selectedTab={MemberTesterDetailTabType.SELF_INFO} />
      <PanelWrapper>
        <ProfileDetail
          profileIdx={profileIdx}
          onDeletedCallback={() => closeWindowAndNotifyToParentWindow(WindowMessage.CREATED_PROFILE)}
        />
      </PanelWrapper>
    </>
  );
}
