export class FileInput {
  private fileInput: HTMLInputElement;

  public constructor(doWithFiles: (files: FileList) => void, multiple: boolean, acceptTypes: FileAcceptType[]) {
    this.fileInput = document.createElement("input");
    this.fileInput.type = "file";
    this.fileInput.accept = acceptTypes.map((type) => type.toString()).join(", ");
    this.fileInput.multiple = multiple;
    this.fileInput.onchange = async () => {
      if (this.fileInput.files) {
        doWithFiles(this.fileInput.files);
      }
    };
  }

  public click() {
    this.fileInput.click();
  }
}

export enum FileAcceptType {
  IMAGE = ".jpg, .jpeg, .png, .gif",
  VIDEO = ".mov, .mp4",
}

export namespace FileAcceptType {
  export const getTypes = (type: FileAcceptType) =>
    type
      .toString()
      .split(",")
      .map((ele) => ele.trim());
}
