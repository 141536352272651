import {MainApi} from "./MainApi";
import {
  OrganManagerDTO, OrganManagerPermissionPutDTO,
  OrganManagerPostDTO,
  OrganManagerSearchCondition,
  OrganManagerUpdateDTO
} from "../types/organManager";
import {RefundAccountPutDTO} from "../features/order/detail/component/refund/hooks/useRefundAccountModifier";
import {MarketingTermsCategory} from "../types/terms";
import {OrganEmailCheckDTO, OrganPhoneCheckDTO} from "../types/organ";

export class OrganManagerApi {
  static baseUrl = `${MainApi.urlPrefix}/organ-manager`;

  static searchOrganManagers = (condition: OrganManagerSearchCondition) => () =>
    MainApi.api.get(`${OrganManagerApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static getOrganManager = (organManagerIdx: number) => () =>
    MainApi.api.get<OrganManagerDTO>(`${OrganManagerApi.baseUrl}/${organManagerIdx}`);

  static blockOrganManagers = (idxes: number[], blockReason: string) => () =>
    MainApi.api.put(`${OrganManagerApi.baseUrl}/block`, {
      idxes,
      reasonForBlocking: blockReason,
    });

  static unblockOrganManager = (idx: number) => () => MainApi.api.put(`${OrganManagerApi.baseUrl}/unblock/${idx}`);

  static updateOrganManagerRefundAccount = (idx: number, refundAccountPutDTO: RefundAccountPutDTO) => () =>
    MainApi.api.put(`${OrganManagerApi.baseUrl}/${idx}/refund-account`, refundAccountPutDTO);

  static updateOrganManager = (idx: number, organManagerUpdateDTO: OrganManagerUpdateDTO) => () =>
    MainApi.api.put(`${OrganManagerApi.baseUrl}/${idx}`, organManagerUpdateDTO);

  static resendEmail = (organManagerIdx: number) => () =>
    MainApi.api.get(`${OrganManagerApi.baseUrl}/resend/signup/confirm/mail/${organManagerIdx}`);

  static deleteJoiningOrganManager = (organManagerIdx: number) => () =>
    MainApi.api.delete(`${OrganManagerApi.baseUrl}/joining/${organManagerIdx}`);

  static issueTemporaryPassword = (organManagerIdx: number) => () =>
    MainApi.api.put(`${OrganManagerApi.baseUrl}/send/temporary-password/${organManagerIdx}`);

  static withdrawTerms = (organManagerIdx: number, termsCategory: MarketingTermsCategory) => () =>
    MainApi.api.delete(
      `${OrganManagerApi.baseUrl}/${organManagerIdx}/withdraw-terms?terms_category_enum=${termsCategory}`
    );

  static revokePhoneNumberAuthentication = (organManagerIdx: number) => () =>
    MainApi.api.put(`${OrganManagerApi.baseUrl}/${organManagerIdx}/authentication-revoked`);

  // 소속관리자 등록 시 아이디(이메일) 체크 API
  static checkOrganEmail = (condition: OrganEmailCheckDTO) => () =>
      MainApi.api.post(
          `${OrganManagerApi.baseUrl}/email-duplicates-check`,condition);

  // 소속관리자 등록 시 휴대폰번호 체크 API
  static checkOrganPhoneNumber = (condition: OrganPhoneCheckDTO) => () =>
      MainApi.api.post(
          `${OrganManagerApi.baseUrl}/phone-number-duplicates-check`,condition);

  // 소속관리자 등록 API
  static createOrganManager = (condition: OrganManagerPostDTO) => () =>
      MainApi.api.post(
          `${OrganManagerApi.baseUrl}`,condition);

  // 소속관리자 권한 수정 API
  static updateOrganManagerPermission = (organManagerIdx:number,condition: OrganManagerPermissionPutDTO) => () =>
      MainApi.api.put(
          `${OrganManagerApi.baseUrl}/${organManagerIdx}/permission`,condition);
}
