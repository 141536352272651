import { DescriptionValue } from "../../../../../layout/content/detail/DescriptionList";
import { AdminPutParam, AdminVO } from "../../../../../types/admin";
import React, { useCallback, useEffect, useState } from "react";
import style from "./memberAdminDetail.module.scss";
import { AdminStatusComponent } from "../../AdminStatusComponent";
import { ActionButton } from "../../../../../components/Buttons";
import { GridInputText } from "../../../../../components/Inputs";
import { getOnlyNumberText } from "../../../../../util/regexpUtil";
import { RoleChecker } from "../../../../role/RoleChecker";
import { AdminMenuCode } from "../../../../../types/adminRole";
import { useSignedInAdminIdx } from "../../hooks/useSignedInAdminIdx";
import { useAdminStatus } from "../../hooks/useAdminStatus";
import { useCountry, useTimeConverter } from "../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { DetailPositionInMiddleStyle, MiddleAlignWrapperStyle } from "../../../user/components/UserSelfInfoDataStyle";
import { InputPhoneNumber } from "../../../../../components/InputPhoneNumber";
import { PhoneNumber } from "../../../../../components/PhoneNumber";
import { useRole } from "../../../../../hooks/useRole";

interface Props {
  admin?: AdminVO;
  onClickChangeRoleButton: () => void;
  onUpdateAdminPutParam: (adminPutParam: AdminPutParam) => void;
}

export function useAdminInfoData({ admin, onClickChangeRoleButton, onUpdateAdminPutParam }: Props) {
  const { t } = useTranslation(["admin", "common"]);
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [codePhone, setCodePhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const signedInAdminIdx = useSignedInAdminIdx();
  const { isLeaved } = useAdminStatus();
  const { timeConverter } = useTimeConverter();
  const { getCountryNameByCodeISOAlpha2 } = useCountry();
  const { getRoleText } = useRole();

  const reset = useCallback(() => {
    setName(admin?.name ?? "");
    setPhoneNumber(admin?.phoneNumber ?? "");
    setCodePhone(admin?.codePhone ?? "");
  }, [admin?.name, admin?.phoneNumber, admin?.codePhone]);

  useEffect(() => reset(), [reset]);

  useEffect(() => {
    onUpdateAdminPutParam({
      name,
      phoneNumber,
      codePhone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, phoneNumber, codePhone]);

  const getDescList = (adminDetail: AdminVO): DescriptionValue[][] => {
    if (isLeaved(adminDetail.status)) {
      return [
        [
          { key: t("adminInfoData.name.label"), value: t("adminInfoData.leavedAt") },
          { key: t("adminInfoData.role.label"), value: getRoleText(adminDetail.role.category) },
        ],
        [
          { key: t("adminInfoData.createdAt"), value: timeConverter.convertToLocalTime(adminDetail.createAt) },
          { key: t("adminInfoData.leavedAt"), value: timeConverter.convertToLocalTime(adminDetail.leaveAt) },
        ],
        [{ key: t("nationality"), value: getCountryNameByCodeISOAlpha2(adminDetail.nationality) }],
      ];
    }

    return [
      [
        {
          key: t("adminInfoData.name.label"),
          value: adminDetail.name,
          editModeValue: (
            <GridInputText
              value={name}
              required={true}
              placeholder={t("adminInfoData.name.placeholder")}
              minLength={2}
              maxLength={50}
              onChange={(e) => setName(e.target.value)}
            />
          ),
        },
        { key: t("adminInfoData.createdAt"), value: timeConverter.convertToLocalTime(adminDetail.createAt) },
      ],
      [
        { key: t("adminInfoData.id"), value: adminDetail.email },
        {
          key: t("adminInfoData.status"),
          value: (
            <AdminStatusComponent
              admin={adminDetail}
              isShow={show}
              onHideStatusPopup={() => setShow(false)}
              onShowStatusPopup={() => setShow(true)}
            />
          ),
        },
      ],
      [
        {
          key: t("adminInfoData.phoneNumber.label"),
          value: <PhoneNumber codePhone={adminDetail.codePhone} phoneNumber={adminDetail.phoneNumber} />,
          editModeValue: (
            <>
              <InputPhoneNumber
                codePhone={codePhone}
                phoneNumber={phoneNumber}
                onChangeCodePhone={(codePhone) => setCodePhone(codePhone)}
                onChangePhoneNumber={(phoneNumber) => setPhoneNumber(getOnlyNumberText(phoneNumber))}
              />
            </>
          ),
        },
        {
          key: t("adminInfoData.role.label"),
          value: (
            <MiddleAlignWrapperStyle>
              <p>
                <span className={style.role}>{getRoleText(adminDetail.role.category)}</span>
                {adminDetail.idx === signedInAdminIdx ? (
                  <></>
                ) : (
                  <RoleChecker menuCode={AdminMenuCode.ADMIN_ROLE}>
                    <ActionButton onClick={() => onClickChangeRoleButton()}>
                      {t("adminInfoData.role.roleSetting")}
                    </ActionButton>
                  </RoleChecker>
                )}
              </p>
            </MiddleAlignWrapperStyle>
          ),
        },
      ],
      [
        {
          key: t("adminInfoData.lastLoginLog"),
          value: (
            <div>
              <p>{timeConverter.convertToLocalTime(adminDetail.lastSignInAt)}</p>
              <p>{adminDetail.lastSignInIp}</p>
            </div>
          ),
        },
        {
          key: t("nationality"),
          value: (
            <DetailPositionInMiddleStyle>
              {getCountryNameByCodeISOAlpha2(adminDetail.nationality)}
            </DetailPositionInMiddleStyle>
          ),
        },
      ],
    ];
  };

  return {
    getDescList,
    reset,
  };
}
