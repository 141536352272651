import { Panel } from "../../../../../../layout/content/detail/Panel";
import { Table } from "../../../../../../layout/content/list/table/Table";
import { useOrganManagerVoucherList } from "./hooks/useOrganManagerVoucherList";
import { useOrganMangerVoucherColumns } from "./hooks/useOrganMangerVoucherColumns";
import { PaginationWrapper } from "../../../../../../layout/content/list/PaginationWrapper";
import { Pagination } from "../../../../../../layout/content/list/Pagination";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  organManagerIdx: number;
}

export function OrganManagerVoucherList({ organManagerIdx }: Props) {
  const { t } = useTranslation("organManager");
  const { getOrganManagerVoucher, organManagerVoucherList } = useOrganManagerVoucherList();
  const columns = useOrganMangerVoucherColumns();

  useEffect(() => {
    getOrganManagerVoucher(organManagerIdx, {})();
  }, [getOrganManagerVoucher, organManagerIdx]);

  return (
    <Panel title={t("detail.couponAndVoucher.historyList.title")}>
      {organManagerVoucherList ? (
        <>
          <Table
            listData={organManagerVoucherList}
            columns={columns}
            getList={(pageCondition) => getOrganManagerVoucher(organManagerIdx, pageCondition)}
            noDataText={t("detail.couponAndVoucher.historyList.noData")}
          />
          {organManagerVoucherList.content.length > 0 ? (
            <PaginationWrapper>
              <Pagination
                page={organManagerVoucherList}
                goSpecificPage={(pageNum) => getOrganManagerVoucher(organManagerIdx, { page: pageNum })()}
              />
            </PaginationWrapper>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Panel>
  );
}
