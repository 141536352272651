import { SearchCondition } from "./page";
import { ListObject } from "./common";

export enum CodeDiscountType {
  PERCENT = "PERCENT",
  AMOUNT = "AMOUNT",
}

export enum CodeChargeType {
  PERCENT = "PERCENT",
  AMOUNT = "AMOUNT",
}

export enum TaxDeduction {
  Y = "Y",
  N = "N",
}

export enum VATIncluded {
  Y = "Y",
  N = "N",
}

export interface AffiliateCodePostParam {
  discountType: CodeDiscountType;
  discountValue: number;
  commissionType: CodeChargeType;
  commissionValue: number;
  availableStartAt: string;
  availableEndAt?: string;
  organManagerIdx: number;
  isTaxDeduction: boolean;
  isVatInclude: boolean;
}

export interface AffiliateCodeStatusPutParam {
  affiliateCodeIdxes: number[];
  status: AffiliateCodeStatus;
}

export enum AffiliateCodeSearchWordType {
  CODE = "CODE",
  NAME = "NAME",
}

export enum AffiliateCodeStatus {
  AVAILABLE = "AVAILABLE",
  UNAVAILABLE = "UNAVAILABLE",
  DELETED = "DELETED",
}

export interface AffiliateCodeSearchCondition extends SearchCondition {
  q?: string;
  qtype?: AffiliateCodeSearchWordType;
  availableStartAt?: Date;
  availableEndAt?: Date;
  paymentsCountTo?: number;
  paymentsCountFrom?: number;
  status?: AffiliateCodeStatus;
  organManagerIdx?: number;
  hasPartnerCompany?: boolean;
}

export interface AffiliateCodeListDTO extends ListObject {
  createAt: string;
  organManagerIdx: number;
  name: string;
  email: string;
  codePhone: string;
  phoneNumber: string;
  code: string;
  status: AffiliateCodeStatus;
  discountType: CodeDiscountType;
  discountValue: number;
  commissionType: CodeChargeType;
  commissionValue: number;
  isVatInclude: boolean;
  isTaxDeduction: boolean;
  paymentsCount: number;
  availableStartAt: string;
  availableEndAt: string;
}

export interface AffiliateCodeSettlementStaticsParam {
  year: number;
  month: number;
  organManagerIdx: number;
}

export enum SettlementStatus {
  ALL = "ALL",
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
}

export interface OrganManagerMonthlyAffiliateCodeStaticsDTO {
  paidCount: number;
  totalSettlementValue: number;
  status: SettlementStatus | null;
}

export interface OrganManagerMonthlyAffiliateCodeStaticsVO {
  paidCount: number;
  totalSettlementValue: number;
  status: SettlementStatus | null;
}

export interface MonthlyAffiliateCodeStatisticsDTO {
  completeCount: number;
  incompleteCount: number;
  totalAffiliatePaidCount: number;
  totalSettlementValue: number;
}

export interface MonthlyAffiliateCodeStatisticsVO {
  completeCount: number;
  incompleteCount: number;
  totalAffiliatePaidCount: number;
  totalSettlementValue: number;
}

export interface MonthlySettlementsSearchCondition extends SearchCondition {
  status?: SettlementStatus;
  yearMonth: string;
}

export interface MonthlyAffiliateCodeListDTO {
  settlementIdxes: number[];
  organManagerIdx: number;
  affiliateCode: string;
  paymentsCount: number;
  discountValue: number;
  commissionValue: number;
  vatValue: number;
  taxValue: number;
  settlementValue: number;
  status: SettlementStatus;
}

export interface SettlementOrganManagerPageDTO extends ListObject {
  organManagerIdx: number;
  organManagerName: string;
  monthlyAffiliateCodeListDTO: MonthlyAffiliateCodeListDTO[];
}

export interface SettlementDetailsVO extends ListObject {
  organManagerIdx: number;
  organManagerName: string;
  codeDetails: MonthlyAffiliateCodeListVO[];
}

export interface MonthlyAffiliateCodeListVO {
  affiliateCode: string;
  paymentsCount: number;
  discountValue: number;
  commissionValue: number;
  vatValue: number;
  taxValue: number;
  settlementValue: number;
  status: SettlementStatus;
}

export interface SettlementStatusUpdateParam {
  organManagerIdxes: number[];
  yearMonth: string;
  status: SettlementStatus;
}

export interface AffiliateCodeDTO {
  idx: number;
  discountCode?: string;
  percent?: number;
  createAt?: string;
  updateAt?: string;
  code: string;
  status: AffiliateCodeStatus;
  discountType: CodeDiscountType;
  discountValue: number;
  commissionType: CodeChargeType;
  commissionValue: number;
  availableStartAt: string;
  availableEndAt?: string;
  organManagerIdx: number;
  partnerCompanyIdx?: number;
  vatInclude?: boolean;
  isTaxDeduction: boolean;
}

export interface AffiliateCodeListVO {
  idx: number;
  createAt?: string;
  organManagerIdx: number;
  name: string;
  email?: string;
  codePhone?: string;
  phoneNumber?: string;
  code: string;
  status: AffiliateCodeStatus;
  discountType: CodeDiscountType;
  discountValue: number;
  commissionType: CodeChargeType;
  commissionValue: number;
  isVatInclude?: boolean;
  isTaxDeduction: boolean;
  availableStartAt: string;
  availableEndAt?: string;
  paymentsCount: number;
}
