import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import {
  FilterInput,
  FilterInputCalendar,
  FilterInputNumber,
  FilterInputText,
  GridInput,
  GridInputNumber,
  GridInputTel,
  GridInputText,
  Input,
  InputTextStyle,
} from "../../../components/Inputs";
import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { FilterNumber } from "../../../layout/content/list/filter/FilterNumber";
import { FilterContentType } from "../../../types/tableFilter";
import React from "react";
import { SearchField } from "../../../layout/content/detail/SearchField";
import { useSampleSearchWord } from "./hooks/useSampleSearchWord";
import { useSampleFilterNumber } from "./hooks/useSampleFilterNumber";

export function ArticleInputs({ id, title }: ArticleProps) {
  const { sampleFilterNumber, setSampleFilterNumber, filterNumberCode } = useSampleFilterNumber();

  const { sampleSearchWord, setSampleSearchWord, searchWordCode, searchWordDescription } = useSampleSearchWord();

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={`<Input placeholder={"Input"}/>`}>
          <Input placeholder={"Input"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<GridInput placeholder={"GridInput"}/>`}>
          <GridInput placeholder={"GridInput"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<GridInputText placeholder={"GridInputText"}/>`}>
          <GridInputText placeholder={"GridInputText"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<GridInputNumber placeholder={"GridInputNumber"}/>`}>
          <GridInputNumber placeholder={"GridInputNumber"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<GridInputTel placeholder={"GridInputTel"}/>`}>
          <GridInputTel placeholder={"GridInputTel"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<FilterInput placeholder={"FilterInput"}/>`}>
          <FilterInput placeholder={"FilterInput"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<FilterInputCalendar placeholder={"FilterInputCalendar"}/>`}>
          <FilterInputCalendar placeholder={"FilterInputCalendar"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<FilterInputNumber placeholder={"FilterInputNumber"}/>`}>
          <FilterInputNumber placeholder={"FilterInputNumber"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<FilterInputText placeholder={"FilterInputText"}/>`}>
          <FilterInputText placeholder={"FilterInputText"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<InputTextStyle placeholder={"InputTextStyle"}/>`}>
          <InputTextStyle placeholder={"InputTextStyle"} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={filterNumberCode}>
          <FilterNumber
            numberField={{
              type: FilterContentType.NUMBER,
              value: sampleFilterNumber,
              min: 1,
              isRequired: true,
              placeholderVal: "숫자 입력",
              onChangeFunc: setSampleFilterNumber,
            }}
          />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={searchWordCode} description={searchWordDescription}>
          <SearchField
            value={sampleSearchWord}
            onKeyUpFunc={(e) => {
              if (e.key === "Enter") {
                alert("검색!");
              }
            }}
            onChangeFunc={(e) => setSampleSearchWord((e.target as HTMLInputElement).value)}
            onClickSearchButtonFunc={() => alert("버튼 클릭")}
          />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
