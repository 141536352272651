import { useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { usePartnerCompanyType } from "../../../../types/hooks/usePartnerCompanyType";
import { TableColumnData } from "../../../../types/common";
import {
  PartnerCompanyListVO,
  PartnerCompanyOrderingNumberUpdateVO,
  PartnerCompanyStatus,
} from "../../../../types/partnerCompany";
import React, { useMemo } from "react";
import { numberWithCommas } from "../../../../util/etcUtil";
import { AffiliateCodeStatus, CodeDiscountType } from "../../../../types/affiliateCode";
import { Anchor } from "../../../../components/Anchor";
import { ActionButton, ActiveActionButton } from "../../../../components/Buttons";
import { PartnerCompanyOrderingNumber } from "../../partner_company/PartnerCompanyOrderingNumber";

interface Args {
  hidePartner: (_: PartnerCompanyListVO) => void;
  showPartner: (_: PartnerCompanyListVO) => void;
  setOrderingNumberMap: (_: PartnerCompanyOrderingNumberUpdateVO) => void;
  orderingNumberMap: Map<number, number | null>;
  getPartnerCompanyList: () => void;
}

export function usePartnerCompanyColumns({
  hidePartner,
  showPartner,
  setOrderingNumberMap,
  orderingNumberMap,
  getPartnerCompanyList,
}: Args) {
  const { timeConverter } = useTimeConverter();
  const { getPartnerCompanyTypeLabel, getPartnerCompanyStatusLabel } = usePartnerCompanyType();
  const { openPartnerCompanyDetailPopup } = useNewWindow();

  const columns: TableColumnData<PartnerCompanyListVO>[] = useMemo(
    () => [
      {
        accessor: "numericOrder",
        header: "순번",
        sortable: true,
        getNode: (vo: PartnerCompanyListVO) => (
          <PartnerCompanyOrderingNumber vo={vo} setOrderingNumber={setOrderingNumberMap} />
        ),
        width: 100,
      },
      {
        accessor: "createdAt",
        header: "등록일시",
        sortable: true,
        getNode: (vo: PartnerCompanyListVO) => (
          <span>{timeConverter.convertToLocalTime(vo.createdAt).replace(" ", "\n")}</span>
        ),
        width: 120,
      },
      {
        accessor: "name",
        header: "협력사명",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => (
          <Anchor
            onClick={() =>
              openPartnerCompanyDetailPopup(
                vo.idx,
                orderingNumberMap.get(vo.idx) ?? vo.numericOrder ?? null,
                getPartnerCompanyList
              )
            }
          >
            {vo.name}
          </Anchor>
        ),
        width: 250,
      },
      {
        accessor: "type",
        header: "유형",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => <>{getPartnerCompanyTypeLabel(vo.type)}</>,
      },
      {
        accessor: "affiliateCode",
        header: "제휴코드",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => <>{vo.affiliateCode ? vo.affiliateCode : "없음"}</>,
      },
      {
        accessor: "discountType",
        header: "할인 금액",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => (
          <>
            {vo.affiliateCode ? (
              <>
                {numberWithCommas(vo.discountValue!)}
                {vo.discountType === CodeDiscountType.PERCENT ? "%" : "원"} 할인
              </>
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        accessor: "affiliateCode",
        header: "제휴코드 유효기간",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => (
          <>
            {vo.affiliateCode ? (
              vo.codeStatus === AffiliateCodeStatus.DELETED ? (
                "삭제됨"
              ) : (
                <>
                  {timeConverter.convertToLocalDate(vo.codeAvailableStartAt)}
                  <br />~ {vo.codeAvailableEndAt ? timeConverter.convertToLocalDate(vo.codeAvailableEndAt) : "상시사용"}
                </>
              )
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        accessor: "status",
        header: "상태",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => (
          <span className={vo.status === PartnerCompanyStatus.EXPOSED ? "blue" : "red"}>
            {getPartnerCompanyStatusLabel(vo.status)}
          </span>
        ),
      },
      {
        accessor: "idx",
        header: "관련 기능",
        sortable: false,
        getNode: (vo: PartnerCompanyListVO) => (
          <>
            {vo.status === PartnerCompanyStatus.EXPOSED && (
              <ActionButton onClick={() => hidePartner(vo)}>노출중지</ActionButton>
            )}
            {vo.status === PartnerCompanyStatus.UNEXPOSED && (
              <ActiveActionButton onClick={() => showPartner(vo)}>노출재개</ActiveActionButton>
            )}
          </>
        ),
      },
    ],
    [
      timeConverter,
      getPartnerCompanyStatusLabel,
      getPartnerCompanyTypeLabel,
      openPartnerCompanyDetailPopup,
      showPartner,
      hidePartner,
      setOrderingNumberMap,
      orderingNumberMap,
      getPartnerCompanyList,
    ]
  );

  return {
    columns,
  };
}
