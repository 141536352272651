import { useAppDispatch, useListFilterValues, useNationality, useTimeConverter } from "../../../hooks/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import { DisplayPosition, ProductListObj, ProductSearchCondition, ProductStatus } from "../../../types/product";
import { hasBothValuesOrBothNoValue } from "../../../util/etcUtil";
import { getProductListAsync } from "../../../store/productSlice";
import { ProductListQueryType } from "./useProductFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { useSortParam } from "../../../hooks/list/useSortParam";
import { dateToString } from "../../../util/dateUtil";
import {Languages} from "../../../types/common";

interface Args {
  onCompletedCallback?: () => void;
}

export function useProductList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { toSortArray } = useSortParam();
  const { nationality } = useNationality();
  const { timeConverter } = useTimeConverter();

  const [productList, setProductList] = useState<PageResponse<ProductListObj>>();
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const pageCondition = useMemo(
    () => ({
      page: productList?.number ?? 0,
      size: productList?.size ?? 20,
      sort: ordersToSortArr(productList?.orders),
    }),
    [productList]
  );
  const { filterValues, searchList, listQueryType } = useListFilterValues();

  const {
    searchWordFilterValue,
    displayPositionFilterValue,
    languageFilterValue,
    statusFilterValue,
    isCouponUsableFilterValue,
    sellingStartDateFilterValue,
    sellingEndDateFilterValue,
    createdAtFromFilterValue,
    createdAtToFilterValue,
  } = filterValues;

  const searchProductList = (pageSearchCondition: PageSearchCondition) => {
    const param: ProductListQueryType = {
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      displayPosition: displayPositionFilterValue.value ? displayPositionFilterValue.value : undefined,
      language: languageFilterValue.value ? languageFilterValue.value : undefined,
      status: statusFilterValue.value ? statusFilterValue.value : undefined,
      isCouponUsable: isCouponUsableFilterValue.value ? isCouponUsableFilterValue.value : undefined,
      sellingStartDate: sellingStartDateFilterValue.value ? dateToString(sellingStartDateFilterValue.value) : undefined,
      sellingEndDate: sellingEndDateFilterValue.value ? dateToString(sellingEndDateFilterValue.value) : undefined,
      createdAtFrom: createdAtFromFilterValue.value ? dateToString(createdAtFromFilterValue.value) : undefined,
      createdAtTo: createdAtToFilterValue.value ? dateToString(createdAtToFilterValue.value) : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.sellingStartDate, param.sellingEndDate)) {
        alert(t("list.validations.bothSellingDatesNeeded"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.createdAtFrom, param.createdAtTo)) {
        alert(t("list.validations.bothCreatedAtNeeded"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getProductList = async (param: ProductSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<ProductListObj> = await dispatch(getProductListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalCnt(result.totalElements);
      } else {
        setProductList(result);
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(`${t("list.error")} : ${defaultErrorMessage}`);
    }
  };

  useEffect(() => {
    getProductList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProductListWithParam = useCallback(() => {
    const productListQueryType = listQueryType as ProductListQueryType;
    getProductList({
      pageCondition: {
        page: productListQueryType.page ? Number(productListQueryType.page) : 0,
        size: productListQueryType.size ? Number(productListQueryType.size) : 20,
        sort: productListQueryType.sort ? toSortArray(productListQueryType.sort) : undefined,
      },
      q: productListQueryType.searchWord?.trim(),
      displayPosition: productListQueryType.displayPosition
        ? (productListQueryType.displayPosition as DisplayPosition)
        : undefined,
      language: productListQueryType.language ? (productListQueryType.language as Languages) : undefined,
      status: productListQueryType.status ? (productListQueryType.status as ProductStatus) : undefined,
      isCouponUsable: productListQueryType.isCouponUsable ? Boolean(productListQueryType.isCouponUsable) : undefined,
      sellingStartDate: productListQueryType.sellingStartDate
        ? timeConverter.convertToFilterStartOfDay(productListQueryType.sellingStartDate)
        : undefined,
      sellingEndDate: productListQueryType.sellingEndDate
        ? timeConverter.convertToFilterEndOfDay(productListQueryType.sellingEndDate)
        : undefined,
      createdAtFrom: productListQueryType.createdAtFrom
        ? timeConverter.convertToFilterStartOfDay(productListQueryType.createdAtFrom)
        : undefined,
      createdAtTo: productListQueryType.createdAtTo
        ? timeConverter.convertToFilterEndOfDay(productListQueryType.createdAtTo)
        : undefined,
    }).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType]);

  useEffect(() => {
    if (listQueryType) {
      getProductListWithParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductListWithParam, nationality]);

  return {
    productList,
    pageCondition,
    totalCnt,
    searchProductList,
    getProductListWithParam,
  };
}
