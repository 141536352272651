import { MyInfoContext } from "./MyInfoContext";
import { useMyInfoGetter } from "../../features/account/mypage/hooks/useMyInfoGetter";
import { useMemo } from "react";

export function MyInfoProvider({ children }: { children: JSX.Element }) {
  const { myInfo } = useMyInfoGetter();
  const isKorean = useMemo(() => myInfo?.nationality === "KR", [myInfo?.nationality]);

  return (
    <MyInfoContext.Provider
      value={{
        isKorean,
      }}
    >
      {children}
    </MyInfoContext.Provider>
  );
}
