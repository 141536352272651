import { useCallback } from "react";
import { UserDTO, UserStatus, UserVO } from "../../../../types/user";
import { callAsync } from "../../../../util/sliceUtil";
import { getUserDetailAsync, memberUserDetailState, setUserDetail } from "../../../../store/userSlice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { useContentDetailHeaderInfoSetter } from "../../../../layout/content/hooks/useContentDetailHeaderInfoSetter";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface ReturnType {
  user: UserVO | undefined;
  setUser: (_: UserVO) => void;
  getUser: (userIdx: number) => void;
  getUserPromise: (_: number) => Promise<UserDTO>;
}

export function useUserGetter(): ReturnType {
  const { t } = useTranslation("user");
  const dispatch = useAppDispatch();
  const user = useAppSelector(memberUserDetailState);
  const { setCategory, setName } = useContentDetailHeaderInfoSetter();
  const { defaultErrorMessage } = useUtil();

  const setUser = useCallback(
    (userParam: UserVO) => dispatch(setUserDetail(userParam)),
    // eslint-disable-next-line
    []
  );

  const getUserPromise = useCallback((userIdx: number) => dispatch(getUserDetailAsync(userIdx)).unwrap(), [dispatch]);

  const getUser = useCallback(
    (userIdx: number) => {
      callAsync(
        getUserPromise(userIdx),
        (it: UserDTO) => {
          if (it.status === UserStatus.LEAVE) {
            setName("");
            setCategory("");
          } else {
            setName(it.profile.name);
            setCategory(t("detail.memberCategory"));
          }
          setUser(it as UserVO);
        },
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    },
    [t, defaultErrorMessage, getUserPromise, setCategory, setName, setUser]
  );

  return {
    user,
    setUser,
    getUser,
    getUserPromise,
  };
}
