import { MemoTargetType, MemoType } from "../../../types/memo";
import style from "./memo.module.scss";
import { useTranslation } from "react-i18next";

interface Props {
  targetType: MemoTargetType;
  memoType: MemoType;
  setMemoType: (_: MemoType) => void;
}

export function MemoTypeSelector({ targetType, memoType, setMemoType }: Props) {
  const { t } = useTranslation("memo");
  return (
    <>
      {targetType === MemoTargetType.USER || targetType === MemoTargetType.ORGAN_MANAGER ? (
        <div className={style.typeRadios}>
          <label className={style.radioLabel}>
            <input
              className={style.radio}
              checked={memoType === MemoType.GENERAL}
              onChange={() => setMemoType(MemoType.GENERAL)}
              type={"radio"}
            />
            {t("aMemo.category.general")}
          </label>
          <label className={style.radioLabel}>
            <input
              className={style.radio}
              checked={memoType === MemoType.INQUIRY}
              onChange={() => setMemoType(MemoType.INQUIRY)}
              type={"radio"}
            />
            {t("aMemo.category.inquiry")}
          </label>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
