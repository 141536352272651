import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface ReturnType {
  toAgreementString: (_: boolean) => string;
}
export function useTermsAgreementText(): ReturnType {
  const { t } = useTranslation("common");
  const toAgreementString = useCallback((isAgree: boolean) => (isAgree ? t("agree") : t("disagree")), [t]);

  return {
    toAgreementString,
  };
}
