import { useCallback, useMemo, useState } from "react";
import { PageSearchCondition } from "../../../../types/page";
import { SettlementStatus } from "../../../../types/affiliateCode";
import { FilterType, FilterValue } from "../../../../types/filter";
import { ListQueryType } from "../../../../types/list";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface AffiliateCodeSettlementListQueryType extends ListQueryType {
  status?: string;
  year: string;
  month: string;
}

export interface AffiliateCodeSettlementListFilterValues {
  statusFilterValue: FilterValue<SettlementStatus>;
  yearFilterValue: FilterValue<string>;
  monthFilterValue: FilterValue<string>;
}

export function useAffiliateCodeSettlementFilterValues() {
  const [status, setStatus] = useState<SettlementStatus>(SettlementStatus.ALL);
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const defaultYear = useMemo(() => new Date().getFullYear().toString(), []);
  const defaultMonth = useMemo(() => (new Date().getMonth() + 1).toString(), []);

  const { listQueryType, setUrlSearchParams } = useListSearchParams<AffiliateCodeSettlementListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "status", value: status, setter: setStatus, defaultValue: SettlementStatus.ALL, type: FilterType.OTHER },
      {
        key: "year",
        value: year,
        setter: setYear,
        defaultValue: defaultYear,
        type: FilterType.STRING,
      },
      {
        key: "month",
        value: month,
        setter: setMonth,
        defaultValue: defaultMonth,
        type: FilterType.STRING,
      },
    ],
    [status, year, month, defaultYear, defaultMonth]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType: listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        statusFilterValue: getFilterValueByKey("status") as FilterValue<SettlementStatus>,
        yearFilterValue: getFilterValueByKey("year") as FilterValue<string>,
        monthFilterValue: getFilterValueByKey("month") as FilterValue<string>,
      } as AffiliateCodeSettlementListFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: AffiliateCodeSettlementListQueryType) => {
      const param: AffiliateCodeSettlementListQueryType = {
        status: listQueryTypeParam?.status ?? SettlementStatus.ALL,
        year: listQueryTypeParam?.year ?? defaultYear,
        month: listQueryTypeParam?.month ?? defaultMonth,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues: filterValues,
    searchList,
  };
}
