import { CountryContext } from "./CountryContext";
import { useAppDispatch, useAppSelector, useLanguage } from "../hooks";
import { countriesState, getCountriesAsync, setCountries } from "../../store/countrySlice";
import { useCallback, useEffect, useMemo } from "react";
import { callAsync } from "../../util/sliceUtil";
import { Country, CountryDTO } from "../../types/country";
import { SelectorOption } from "../../types/selector";
import { useTranslation } from "react-i18next";
import { DEFAULT_NATIONALITY } from "../nationality/NationalityProvider";

export function CountryProvider({ children }: { children: JSX.Element }) {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const countries = useAppSelector(countriesState);
  // const nationalityCountries = useMemo(() => [DEFAULT_NATIONALITY, "JP", "CA"], []); // 기존 세팅 백업
  const nationalityCountries = useMemo(() => [DEFAULT_NATIONALITY, "US", "MN", "TW", "VN"], []);
  const { isKorean } = useLanguage();

  const sortedCountries = useMemo(() => {
    if (!countries) return [];
    return [...countries].sort((a, b) =>
      isKorean ? (a.nameKor < b.nameKor ? -1 : 1) : a.nameEn.toLowerCase() < b.nameEn.toLowerCase() ? -1 : 1
    );
  }, [countries, isKorean]);

  const convertCountryDTOToCountry = useCallback((countryDTO: CountryDTO): Country[] => {
    return countryDTO.codePhone.map((it) => {
      return {
        idx: countryDTO.idx,
        nameKor: countryDTO.nameKor,
        nameEn: countryDTO.nameEn,
        codeISONumeric: countryDTO.codeISONumeric,
        codeISOAlpha2: countryDTO.codeISOAlpha2,
        codeISOAlpha3: countryDTO.codeISOAlpha3,
        codePhone: it,
        timeZone: countryDTO.timeZone,
      };
    });
  }, []);

  useEffect(() => {
    if (countries !== undefined) return;
    callAsync<CountryDTO[]>(
      dispatch(getCountriesAsync()).unwrap(),
      (result) => {
        const countries = result
          .flatMap((country) => convertCountryDTOToCountry(country))
          .sort((a, b) => (a.nameKor < b.nameKor ? -1 : 1));
        dispatch(setCountries(countries));
      },
      (error) => {
        if (error.errorCode.serverCode === 3002) {
          alert(t("notSignedUpMember"));
        } else {
          alert(t("defaultErrorMessage"));
        }
      }
    ).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  const phoneNumberOptions: SelectorOption[] = useMemo(
    () =>
      sortedCountries?.map((it) => {
        return {
          label: `(${it.codePhone}) ${isKorean ? it.nameKor : it.nameEn}`,
          value: it.codePhone,
        };
      }) ?? [],
    [isKorean, sortedCountries]
  );

  const countryOptions: SelectorOption[] = useMemo(() => {
    const options = sortedCountries?.map((it) => ({
      label: isKorean ? it.nameKor : it.nameEn,
      value: it.codeISOAlpha2,
    }));

    return options ?? [];
  }, [sortedCountries, isKorean]);

  const countryOptionsWithAll: SelectorOption[] = useMemo(() => {
    return [{ label: t("all"), value: "" }, ...countryOptions.filter((o) => o.value !== "")];
  }, [t, countryOptions]);

  const countryOptionsWithNone: SelectorOption[] = useMemo(() => {
      return [{ label: t("selectYourCountry"), value: "" }, ...countryOptions.filter((o) => o.value !== "")];
  },[t,countryOptions])

  const onlyNationalityOptions: SelectorOption[] = useMemo(
    () =>
      sortedCountries
        ?.filter((c) => nationalityCountries.includes(c.codeISOAlpha2))
        .map((it) => ({
          label: isKorean ? it.nameKor : it.nameEn,
          value: it.codeISOAlpha2,
        })),
    [sortedCountries, isKorean, nationalityCountries]
  );

  const nationalityOptions: SelectorOption[] = useMemo(() => {
    const options = [...onlyNationalityOptions];

    options?.unshift({ label: t("nationality.placeholder"), value: "" });
    return options ?? [];
  }, [onlyNationalityOptions, t]);

  const nationalityOptionsWithAll: SelectorOption[] = useMemo(() => {
    return [{ label: t("all"), value: "" }, ...nationalityOptions.filter((o) => o.value !== "")];
  }, [t, nationalityOptions]);

  const getCountryByCodeISOAlpha2 = useCallback(
    (code: string) => countries?.find((country) => country.codeISOAlpha2 === code),
    [countries]
  );

  const getCountryNameByCodeISOAlpha2 = useCallback(
    (code: string) => {
      const country = getCountryByCodeISOAlpha2(code);
      return isKorean ? country?.nameKor : country?.nameEn;
    },
    [isKorean, getCountryByCodeISOAlpha2]
  );

  return (
    <CountryContext.Provider
      value={{
        countries: countries ?? [],
        phoneNumberOptions,
        countryOptions,
        countryOptionsWithAll,
        countryOptionsWithNone,
        onlyNationalityOptions,
        nationalityOptions,
        nationalityOptionsWithAll,
        getCountryByCodeISOAlpha2,
        getCountryNameByCodeISOAlpha2,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
}
