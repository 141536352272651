import { useCallback } from "react";

export function useValidator() {
  const getTotalLength = useCallback(
    (someString: string) =>
      someString
        .trim()
        .split("")
        .reduce((acc, char) => {
          const enLowercaseRegExp = /[a-z]/;
          const numRegExp = /[0-9]/;
          const spaceRegExp = /\s/;

          const isHalfChar = enLowercaseRegExp.test(char) || numRegExp.test(char) || spaceRegExp.test(char);
          if (isHalfChar) return acc + 1;
          else return acc + 2;
        }, 0),
    []
  );

  return {
    getTotalLength,
  };
}
