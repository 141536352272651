import {convertCurrencyUnit, MINI_MODAL_LINK, numberWithCommas} from "../../../../../util/etcUtil";
import { LinkStyle } from "../../../../../components/styled/LinkStyle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RefundItemVO, RefundVO } from "../../../../../types/orders";
import { MiniModal } from "../../../../../layout/modal/MiniModal";
import { TableStyle } from "../../../../../components/styled/TableStyle";
import { OrderItemTitleRowStyle } from "../../../component/OrderItemTitleRowStyle";
import styled from "styled-components";
import { useRefundItemsGetter } from "./hooks/useRefundItemsGetter";
import { useAppSelector, useNewWindow } from "../../../../../hooks/hooks";
import { refundItemGetterStatus } from "../../../../../store/orderSlice";
import { Anchor } from "../../../../../components/Anchor";
import useOrderItemGetter from "../../../hooks/useOrderItemGetter";
import { useTranslation } from "react-i18next";
import {PaymentWay} from "../../../../../types/payment";

const RefundPriceColumnStyle = styled.div`
  position: relative;
`;

interface Props {
  orderRefundVO: RefundVO;
  paymentWay: PaymentWay;
}

export function RefundPriceColumn({ orderRefundVO, paymentWay }: Props) {
  const { t } = useTranslation("order");
  const getRefundItems = useRefundItemsGetter();
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [refundItems, setRefundItems] = useState<RefundItemVO[]>([]);
  const { openProductDetail } = useNewWindow();
  const { getOrderItems, orderItems } = useOrderItemGetter();

  useEffect(() => {
    if (isShowModal) {
      getOrderItems(orderRefundVO.orderIdx).then();
    }
  }, [isShowModal, getOrderItems, orderRefundVO.orderIdx]);

  const loadRefundItems = useCallback(
    (orderRefundVO: RefundVO) => {
      setShowModal(true);
      getRefundItems(orderRefundVO.idx, (refundItems: RefundItemVO[]) => {
        setRefundItems(refundItems);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isShowModal]
  );

  const refundItemStatus = useAppSelector(refundItemGetterStatus);
  const linkRef = useRef<HTMLSpanElement | null>(null);
  return (
    <RefundPriceColumnStyle>
      <LinkStyle onClick={(_) => loadRefundItems(orderRefundVO)} ref={linkRef} data-name={MINI_MODAL_LINK}>
        {numberWithCommas(orderRefundVO.refundPrice)}
        {convertCurrencyUnit(paymentWay, t)}
      </LinkStyle>

      <MiniModal
        title={t("refund.refundProduct.label")}
        show={isShowModal}
        closeModal={() => setShowModal(false)}
        modalWidth="504px"
        linkElement={linkRef.current ?? undefined}
        statusValues={[refundItemStatus]}
      >
        <TableStyle>
          <thead>
            <tr>
              <th>{t("refund.refundProduct.name")}</th>
              <th>{t("refund.refundProduct.quantity")}</th>
            </tr>
          </thead>
          <tbody>
            {refundItems.map((it) => (
              <tr key={it.idx}>
                <td>
                  <OrderItemTitleRowStyle>
                    <img src={it.productImage} alt={t("productImage")} />
                    <Anchor
                      onClick={() => {
                        const productIdx = orderItems.find((oi) => oi.idx === it.orderItemIdx)?.productIdx;
                        if (productIdx) {
                          openProductDetail(productIdx);
                        }
                      }}
                    >
                      {it.productName}
                    </Anchor>
                  </OrderItemTitleRowStyle>
                </td>
                <td>{it.quantity}</td>
              </tr>
            ))}
          </tbody>
        </TableStyle>
      </MiniModal>
    </RefundPriceColumnStyle>
  );
}
