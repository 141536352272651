import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MemberUserCoupons } from "./MemberUserCoupons";
import { MemberUserTesters } from "./MemberUserTesters";
import { MemberUserSelfInfo } from "./MemberUserSelfInfo";
import { useAppSelector } from "../../../hooks/hooks";
import { memberUserStatus } from "../../../store/userSlice";
import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { DetailSubMenus, DetailTab } from "../../../layout/content/detail/DetailSubMenus";
import { MemberUserDetailTabType, UserStatus } from "../../../types/user";
import queryString from "query-string";
import { useLoader } from "../../../hooks/common/useLoader";
import { RoleChecker } from "../../role/RoleChecker";
import { AdminMenuCode } from "../../../types/adminRole";
import { useContentDetailHeaderInfoGetter } from "../../../layout/content/hooks/useContentDetailHeaderInfoGetter";
import { useUserGetter } from "./hooks/useUserGetter";
import { MemberOrderList } from "../components/order/MemberOrderList";
import { useTranslation } from "react-i18next";

export function MemberUserDetail() {
  const { t } = useTranslation("user");
  const navigate = useNavigate();
  const status = useAppSelector(memberUserStatus);
  const { name } = useContentDetailHeaderInfoGetter();
  const { user, getUser } = useUserGetter();

  useLoader({ status });

  const { id } = useParams<"id">();
  const memberIdx = Number(id);

  useEffect(() => {
    if (!user) {
      getUser(memberIdx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLeaved = useMemo(() => user?.status === UserStatus.LEAVE, [user?.status]);
  const { type } = queryString.parse(window.location.search);
  const [selectedTab, setSelectedTab] = useState<MemberUserDetailTabType>(type as MemberUserDetailTabType);

  const onClickTabMenu = (type: MemberUserDetailTabType) => {
    navigate(`/detail/member/user/${id}?${queryString.stringify({ type })}`);
  };

  const getContentComponent = useCallback(
    (tab: MemberUserDetailTabType) => {
      switch (tab) {
        case MemberUserDetailTabType.SELF_INFO:
          return <MemberUserSelfInfo idx={memberIdx} />;
        case MemberUserDetailTabType.COUPON:
          return (
            <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_COUPON_VOUCHER}>
              <MemberUserCoupons idx={memberIdx} name={String(name)} />
            </RoleChecker>
          );
        case MemberUserDetailTabType.PAYMENT_HISTORY:
          return (
            <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_PAYMENTS}>
              <MemberOrderList userIdx={memberIdx} />
            </RoleChecker>
          );
        case MemberUserDetailTabType.TESTERS:
          return <MemberUserTesters idx={memberIdx} />;
        default:
          return <></>;
      }
    },
    [memberIdx, name]
  );

  useEffect(() => {
    setSelectedTab(type as MemberUserDetailTabType);
  }, [type]);

  const tabMenus: DetailTab<MemberUserDetailTabType>[] = [
    {
      tabValue: MemberUserDetailTabType.SELF_INFO,
      tabName: t("detail.submenus.memberInfo"),
    },
    {
      tabValue: MemberUserDetailTabType.PAYMENT_HISTORY,
      tabName: t("detail.submenus.payments"),
      menuCode: AdminMenuCode.USER_DETAIL_PAYMENTS,
    },
    {
      tabValue: MemberUserDetailTabType.COUPON,
      tabName: t("detail.submenus.couponAndVoucher"),
      menuCode: AdminMenuCode.USER_DETAIL_COUPON_VOUCHER,
    },
    {
      tabValue: MemberUserDetailTabType.TESTERS,
      tabName: t("detail.submenus.testers"),
    },
  ];

  const leavedUserTabMenus: DetailTab<MemberUserDetailTabType>[] = [
    {
      tabValue: MemberUserDetailTabType.SELF_INFO,
      tabName: t("detail.submenus.memberInfo"),
    },
  ];

  return (
    <>
      <DetailSubMenus
        tabs={!isLeaved ? tabMenus : leavedUserTabMenus}
        onClickTab={onClickTabMenu}
        selectedTab={selectedTab}
      />
      <PanelWrapper>{getContentComponent(selectedTab)}</PanelWrapper>
    </>
  );
}
