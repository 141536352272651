import variables from "../assets/scss/variable.module.scss";

export const theme = {
  color: {
    FGDarkBlue: variables.colorFGDarkBlue,
    FGOrange: variables.colorFGOrange,
    BodyTextBlue: variables.colorBodyTextBlue,
    MediumBlue: variables.colorMediumBlue,
    LightBlue: variables.colorLightBlue,
    KPBlue: variables.colorKPBlue,
    FGBlue: variables.colorFGBlue,
    FGFooterBlue: variables.colorFGFooterBlue,
    FGPointRed: variables.colorFGPointRed,
    required: variables.colorRequired,
    cE5: variables.colorE5,
    cF6: variables.colorF6,
    c75: variables.color75,
    cDE: variables.colorDE,
    c48: variables.color48,
    cC6: variables.colorC6,
    cE1: variables.colorE1,
  },
  zIndex: {
    selectOptions: variables.zIndexSelectOptions,
    fixedMenu: variables.zIndexFixedMenu,
    hidden: variables.zIndexHidden,
    content: variables.zIndexContent,
  },
};
