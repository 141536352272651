import { OrderSearchDateType, OrderSearchWordType, OrderStatus } from "../../../types/orders";
import { DisplayPosition } from "../../../types/product";
import { FilterContentType, TableFilter } from "../../../types/tableFilter";
import { PaymentWay } from "../../../types/payment";
import { useLanguage, useListFilterValues, useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useOrdersType } from "../../../types/hooks/useOrdersType";
import { usePaymentType } from "../../../types/hooks/usePaymentType";
import { useProductType } from "../../../types/hooks/useProductType";
import { useMemo } from "react";

interface Args {
  search: () => void;
}

export function useOrderFilters({ search }: Args): {
  filters: TableFilter[][];
} {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation("order");
  const { timeConverter } = useTimeConverter();
  const { orderStatusOptions, orderSearchWordTypeOptions } = useOrdersType();
  const { paymentWayOptions } = usePaymentType();
  const { displayPositionOptions } = useProductType();
  const { isKorean } = useLanguage();

  const queryFilter = useMemo(() => filterValues.queryFilterValue, [filterValues.queryFilterValue]);
  const queryTypeFilter = useMemo(() => filterValues.queryTypeFilterValue, [filterValues.queryTypeFilterValue]);
  const orderSearchDateTypeFilter = useMemo(
    () => filterValues.orderSearchDateTypeFilterValue,
    [filterValues.orderSearchDateTypeFilterValue]
  );
  const startDateFilter = useMemo(() => filterValues.startDateFilterValue, [filterValues.startDateFilterValue]);
  const endDateFilter = useMemo(() => filterValues.endDateFilterValue, [filterValues.endDateFilterValue]);
  const paymentWayFilter = useMemo(() => filterValues.paymentWayFilterValue, [filterValues.paymentWayFilterValue]);
  const statusFilter = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);
  const displayPositionFilter = useMemo(
    () => filterValues.displayPositionFilterValue,
    [filterValues.displayPositionFilterValue]
  );

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("filter.search.label"),
        content: {
          type: FilterContentType.TEXT,
          value: queryFilter.value,
          maxLength: 100,
          placeholderVal: t("filter.search.placeholder"),
          onChangeFunc: (v) => queryFilter.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: queryTypeFilter.value,
            options: orderSearchWordTypeOptions,
            onChangeFunc: (v) => queryTypeFilter.setter(v as OrderSearchWordType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("filter.duration.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(startDateFilter.value),
            onChangeFunc: (v) => startDateFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("filter.duration.placeholder"),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(endDateFilter.value),
            onChangeFunc: (v) => endDateFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("filter.duration.placeholder"),
          },
          typeSelector: {
            type: FilterContentType.SELECT,
            value: orderSearchDateTypeFilter.value,
            options: [
              { value: OrderSearchDateType.ORDERED_AT, label: t("filter.duration.orderedAt") },
              { value: OrderSearchDateType.PAID_AT, label: t("filter.duration.paidAt") },
            ],
            onChangeFunc: (v) => orderSearchDateTypeFilter.setter(v as OrderSearchDateType),
            width: isKorean ? undefined : 200,
          },
        },
      },
    ],
    [
      {
        labelTitle: t("filter.paymentMethod"),
        content: {
          type: FilterContentType.SELECT,
          value: paymentWayFilter.value,
          options: paymentWayOptions,
          onChangeFunc: (v) => paymentWayFilter.setter(v as PaymentWay),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("filter.status"),
        content: {
          type: FilterContentType.SELECT,
          value: statusFilter.value,
          options: orderStatusOptions,
          onChangeFunc: (v) => statusFilter.setter(v as OrderStatus),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("filter.displayPosition"),
        content: {
          type: FilterContentType.SELECT,
          value: displayPositionFilter.value,
          options: [
            { label: t("all"), value: "" },
            ...displayPositionOptions.filter((option) => option.value !== DisplayPosition.GRANTABLE_PRODUCT.toString()),
          ],
          onChangeFunc: (v) => displayPositionFilter.setter(v as DisplayPosition),
          width: isKorean ? undefined : 200,
        },
      },
    ],
  ];

  return {
    filters,
  };
}
