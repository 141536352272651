import style from "../productItemModal.module.scss";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    name: string;
    setName: (_: string) => void;
}

export function OrganManagerNameField({name, setName}: Props) {
    const {t} = useTranslation("organManager");

    return (
        <div className={style.fieldLine}>
            <input
                minLength={2}
                maxLength={50}
                className={style.inputField}
                value={name}
                required={true}
                onChange={(e) => setName(e.target.value)}
                placeholder={t("registration.validator.namePlaceholder")}
            />
        </div>
    )
}