import style from "../../../layout/content/post/creator.module.scss";
import { Table } from "../../../layout/content/list/table/Table";
import React from "react";
import { AffiliateCodeListVO } from "../../../types/affiliateCode";
import { useUtil } from "../../../util/hooks/useUtil";
import { TableColumnData } from "../../../types/common";

interface Props {
  selectedCode: AffiliateCodeListVO | null;
  columns: TableColumnData<AffiliateCodeListVO>[];
}

export function PartnerSelectedAffiliateCode({ selectedCode, columns }: Props) {
  const { getDefaultPageResponseObject } = useUtil();

  return (
    <div className={style.selectedItem}>
      <h3 className={style.title}>선택된 제휴코드</h3>
      <div className={style.list}>
        {selectedCode ? (
          <Table
            listData={getDefaultPageResponseObject([selectedCode])}
            columns={columns}
            getList={() => () => {}}
            noDataText={""}
          />
        ) : (
          <span className={style.noItem}>선택된 제휴코드가 없습니다.</span>
        )}
      </div>
    </div>
  );
}
