import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { DisplayPosition, ProductDiscountType, ProductStatus } from "../product";
import { SelectorOption } from "../selector";
import {Languages} from "../common";

export function useProductType() {
  const { t } = useTranslation(["product", "common"]);

  const toStringFromLanguage = useCallback(
      (s: Languages) => {
        switch (s) {
          case Languages.KO:
            return t("language.korean");
          case Languages.EN:
            return t("language.english");
          case Languages.ZH:
            return t("language.taiwanese");
          case Languages.RU:
            return t("language.russian");
          default:
            return "";
        }
      },
      [t]
  );

  const toStringFromProductStatus = useCallback(
    (s: ProductStatus) => {
      switch (s) {
        case ProductStatus.IS_SELLING:
          return t("productStatus.isSelling");
        case ProductStatus.STOP_SELLING:
          return t("productStatus.stopSelling");
        default:
          return '';
      }
    },
    [t]
  );

  const productStatusOptions = useMemo(() => {
    const list = Object.keys(ProductStatus).flatMap((key) => {
      const valueObj = ProductStatus[key as keyof typeof ProductStatus];

      if (typeof valueObj === "string") {
        return [
          {
            value: key,
            label: toStringFromProductStatus(valueObj),
          },
        ];
      } else {
        return [];
      }
    });

    list.unshift({ value: "", label: t("all", { ns: "common" }) });

    return list;
  }, [toStringFromProductStatus, t]);

  const toStringFromDisplayPosition = useCallback(
    (d: DisplayPosition) => {
      switch (d) {
        case DisplayPosition.GRANTABLE_PRODUCT:
          return t("displayPosition.grantableProduct");
        case DisplayPosition.USER_APP:
          return "with-And";
        case DisplayPosition.USER_APP_WITH_IOS:
          return "with-iOS";
        case DisplayPosition.USER_APP_KPASS_ANDROID:
          return "K-PASS-And";
        case DisplayPosition.USER_APP_KPASS_IOS:
          return "K-PASS-iOS";
        case DisplayPosition.USER_APP_DCAS_ANDROID:
          return "D-CAS-And";
        case DisplayPosition.USER_APP_DCAS_IOS:
          return "D-CAS-iOS";
        case DisplayPosition.USER_WEB_KPASS:
          return t("displayPosition.kpass_homepage");
        case DisplayPosition.USER_WEB_DCAS:
          return t("displayPosition.dcas_homepage");
        case DisplayPosition.ORGAN_WEB_KPASS:
          return t("displayPosition.organManagerCenterKpass");
        case DisplayPosition.ORGAN_WEB_DCAS:
          return t("displayPosition.organManagerCenterDcas");
        default:
          return "";
      }
    },
    [t]
  );

  const displayPositionOptions: SelectorOption[] = useMemo(
    () =>
      Object.keys(DisplayPosition)
        .flatMap((key) => {
          const valueObj = DisplayPosition[key as keyof typeof DisplayPosition];

          if (typeof valueObj === "string") {
            return [
              {
                value: key,
                label: toStringFromDisplayPosition(valueObj),
              },
            ];
          } else {
            return [];
          }
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)),
    [toStringFromDisplayPosition]
  );

  const toStringFromProductDiscountType = useCallback(
    (type: ProductDiscountType) => {
      switch (type) {
        case ProductDiscountType.AMOUNT:
          return t("productDiscountType.amount");
        case ProductDiscountType.PERCENT:
          return t("productDiscountType.percent");
        case ProductDiscountType.NONE:
          return t("productDiscountType.none");
        default:
          return "";
      }
    },
    [t]
  );

  const userDisplayOptions = useMemo(
    () => displayPositionOptions.filter((dp) => dp.value !== DisplayPosition.ORGAN_WEB_KPASS.toString()),
    [displayPositionOptions]
  );

  return {
    toStringFromLanguage,
    toStringFromProductStatus,
    productStatusOptions,
    displayPositionOptions,
    toStringFromDisplayPosition,
    toStringFromProductDiscountType,
    userDisplayOptions,
  };
}
