import { MainApi } from "./MainApi";
import { AdminPutParam, AdminSearchCondition, ChangeRoleParam } from "../types/admin";

export class AdminApi {
  static baseUrl = `${MainApi.urlPrefix}/admin`;

  static getAdminList = (condition: AdminSearchCondition) => () =>
    MainApi.api.get(`${AdminApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static getAdminDetail = (idx: number) => () => MainApi.api.get(`${AdminApi.baseUrl}/${idx}`);

  static updateAdmin = (idx: number, adminPutParam: AdminPutParam) => () =>
    MainApi.api.put(`${AdminApi.baseUrl}/${idx}`, adminPutParam);

  static blockAdmins = (adminIdxArr: number[], blockReason: string) => () =>
    MainApi.api.put(`${AdminApi.baseUrl}/block`, {
      idx: adminIdxArr,
      reasonForBlocking: blockReason,
    });

  static unblockAdmin = (adminIdx: number) => () =>
    MainApi.api.put(`${AdminApi.baseUrl}/unblock`, {
      idx: [adminIdx],
    });

  static authorizeAdmin = (adminIdx: number) => () =>
    MainApi.api.post(`${AdminApi.baseUrl}/sign-up/confirm/${adminIdx}`);

  static changeRole = (changeRoleParam: ChangeRoleParam) => () =>
    MainApi.api.put(`${AdminApi.baseUrl}/${changeRoleParam.adminIdx}/role`, {
      roleIdx: changeRoleParam.roleIdx,
    });

  static issueTemporaryPassword = (adminIdx: number) => () =>
    MainApi.api.put(`${AdminApi.baseUrl}/temp/pwd/${adminIdx}`);

  static resendSignUpConfirmEmail = (adminIdx: number) => () =>
    MainApi.api.get(`${AdminApi.baseUrl}/resend/signup/confirm/mail/${adminIdx}`);

  static deleteAdmin = (adminIdx: number) => () => MainApi.api.delete(`${AdminApi.baseUrl}/${adminIdx}`);
}
