import { OrganManagerStatus, QueryType } from "../../../../types/organManager";
import { useCallback, useMemo, useState } from "react";
import { ListQueryType } from "../../../../types/list";
import { FilterType, FilterValue } from "../../../../types/filter";
import { PageSearchCondition } from "../../../../types/page";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface OrganManagerListQueryType extends ListQueryType {
  query?: string;
  queryType?: string;
  startDateOfCreateAt?: string;
  endDateOfCreateAt?: string;
  status?: string;
  minVoucherCount?: string;
  maxVoucherCount?: string;
}

export interface OrganManagerFilterValues {
  queryFilterValue: FilterValue<string>;
  queryTypeFilterValue: FilterValue<QueryType>;
  startDateOfCreateAtFilterValue: FilterValue<Date | undefined>;
  endDateOfCreateAtFilterValue: FilterValue<Date | undefined>;
  statusFilterValue: FilterValue<OrganManagerStatus | undefined>;
  minVoucherCountFilterValue: FilterValue<number | undefined>;
  maxVoucherCountFilterValue: FilterValue<number | undefined>;
}

export function useOrganManagerFilterValues() {
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState<QueryType>(QueryType.NAME);
  const [startDateOfCreateAt, setStartDateOfCreateAt] = useState<Date | undefined>();
  const [endDateOfCreateAt, setEndDateOfCreateAt] = useState<Date | undefined>();
  const [status, setStatus] = useState<OrganManagerStatus | undefined>(undefined);
  const [minVoucherCount, setMinVoucherCount] = useState("");
  const [maxVoucherCount, setMaxVoucherCount] = useState("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<OrganManagerListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "query", value: query, setter: setQuery, defaultValue: "", type: FilterType.STRING },
      {
        key: "queryType",
        value: queryType,
        setter: setQueryType,
        defaultValue: QueryType.NAME,
        type: FilterType.OTHER,
      },
      {
        key: "startDateOfCreateAt",
        value: startDateOfCreateAt,
        setter: setStartDateOfCreateAt,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "endDateOfCreateAt",
        value: endDateOfCreateAt,
        setter: setEndDateOfCreateAt,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      { key: "status", value: status, setter: setStatus, defaultValue: undefined, type: FilterType.OTHER },
      {
        key: "minVoucherCount",
        value: minVoucherCount,
        setter: setMinVoucherCount,
        defaultValue: "",
        type: FilterType.STRING,
      },
      {
        key: "maxVoucherCount",
        value: maxVoucherCount,
        setter: setMaxVoucherCount,
        defaultValue: "",
        type: FilterType.STRING,
      },
    ],
    [query, queryType, startDateOfCreateAt, endDateOfCreateAt, minVoucherCount, maxVoucherCount, status]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        queryFilterValue: getFilterValueByKey("query") as FilterValue<string>,
        queryTypeFilterValue: getFilterValueByKey("queryType") as FilterValue<QueryType>,
        startDateOfCreateAtFilterValue: getFilterValueByKey("startDateOfCreateAt") as FilterValue<Date | undefined>,
        endDateOfCreateAtFilterValue: getFilterValueByKey("endDateOfCreateAt") as FilterValue<Date | undefined>,
        statusFilterValue: getFilterValueByKey("status") as FilterValue<OrganManagerStatus | undefined>,
        minVoucherCountFilterValue: getFilterValueByKey("minVoucherCount") as FilterValue<number | undefined>,
        maxVoucherCountFilterValue: getFilterValueByKey("maxVoucherCount") as FilterValue<number | undefined>,
      } as OrganManagerFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: OrganManagerListQueryType) => {
      const param: OrganManagerListQueryType = {
        query: listQueryTypeParam?.query,
        queryType: listQueryTypeParam?.queryType,
        startDateOfCreateAt: listQueryTypeParam?.startDateOfCreateAt,
        endDateOfCreateAt: listQueryTypeParam?.endDateOfCreateAt,
        status: listQueryTypeParam?.status,
        minVoucherCount: listQueryTypeParam?.minVoucherCount,
        maxVoucherCount: listQueryTypeParam?.maxVoucherCount,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
