import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { UserProfileApi } from "../api/UserProfileApi";
import { PageResponse, SearchCondition } from "../types/page";
import { RootState } from "./index";
import { AdminUserProfileTesterListObj, ProfileSearchCondition } from "../types/userProfile";

export interface UserProfileState extends SliceState {
  organsByProfileStatus: Status;
  deleteProfileStatus: Status;
  userProfiles?: PageResponse<AdminUserProfileTesterListObj>;
  userIdx?: number;
  selectedProfileName?: string;
}

const initialState: UserProfileState = {
  status: Status.IDLE,
  organsByProfileStatus: Status.IDLE,
  deleteProfileStatus: Status.IDLE,
};

export const getUserProfilesByUserAsync = createAsyncThunk(
  "userProfile/getUserProfilesByUser",
  ({ userIdx, condition }: { userIdx: number; condition: SearchCondition }) =>
    executePromise(UserProfileApi.getUserProfilesByUser(userIdx, condition))
);

export const getTestHistoriesByUserProfileAsync = createAsyncThunk(
  "userProfile/getTestHistoriesByUserProfile",
  ({ userProfileIdx, condition }: { userProfileIdx: number; condition: SearchCondition }) =>
    executePromise(UserProfileApi.getTestHistoriesByUserProfile(userProfileIdx, condition))
);

export const getUserProfileDetailAsync = createAsyncThunk("userProfile/getUserProfileDetail", (profileIdx: number) =>
  executePromise(UserProfileApi.getUserProfileDetail(profileIdx))
);

export const getUserProfileListAsync = createAsyncThunk(
  "userProfile/getUserProfileList",
  (condition: ProfileSearchCondition) => executePromise(UserProfileApi.getUserProfileList(condition))
);

export const getOrgansByProfileAsync = createAsyncThunk("userProfile/getOrgansByProfile", (profileIdx: number) =>
  executePromise(UserProfileApi.getOrgansByProfile(profileIdx))
);

export const deleteProfilesAsync = createAsyncThunk("userProfile/deleteProfiles", (profileIdxes: number[]) =>
  executePromise(UserProfileApi.deleteProfiles(profileIdxes))
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {
    setUserProfiles: (state, action: PayloadAction<PageResponse<AdminUserProfileTesterListObj>>) => {
      const userProfilesPageable = action.payload;
      userProfilesPageable.content.sort((a, b) => {
        if (a.isOwnerProfile && !b.isOwnerProfile) {
          return -1;
        }

        if (!a.isOwnerProfile && b.isOwnerProfile) {
          return 1;
        }

        return a.createAt < b.createAt ? -1 : 1;
      });
      state.userProfiles = userProfilesPageable;
    },
    setUserProfileUserIdx: (state, action: PayloadAction<number>) => {
      state.userIdx = action.payload;
    },
    setSelectedProfileName: (state, action: PayloadAction<string>) => {
      state.selectedProfileName = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getUserProfilesByUserAsync);
    addCases(builder, getTestHistoriesByUserProfileAsync);
    addCases(builder, getUserProfileDetailAsync);
    addCases(builder, getUserProfileListAsync);

    builder
      .addCase(deleteProfilesAsync.pending, (state) => {
        state.deleteProfileStatus = Status.LOADING;
      })
      .addCase(deleteProfilesAsync.rejected, (state) => {
        state.deleteProfileStatus = Status.FAILED;
      })
      .addCase(deleteProfilesAsync.fulfilled, (state) => {
        state.deleteProfileStatus = Status.IDLE;
      })
      .addCase(getOrgansByProfileAsync.pending, (state) => {
        state.organsByProfileStatus = Status.LOADING;
      })
      .addCase(getOrgansByProfileAsync.rejected, (state) => {
        state.organsByProfileStatus = Status.FAILED;
      })
      .addCase(getOrgansByProfileAsync.fulfilled, (state) => {
        state.organsByProfileStatus = Status.IDLE;
      });
  },
});

export default userProfileSlice.reducer;

export const { setUserProfiles, setUserProfileUserIdx, setSelectedProfileName } = userProfileSlice.actions;
export const userProfileStatus = (state: RootState) => state.userProfile.status;
export const organsByUserProfileStatus = (state: RootState) => state.userProfile.organsByProfileStatus;
export const deleteProfileStatus = (state: RootState) => state.userProfile.deleteProfileStatus;
export const userProfilesState = (state: RootState) => state.userProfile.userProfiles;
export const userProfileUserIdxState = (state: RootState) => state.userProfile.userIdx;
export const userProfileNameState = (state: RootState) => state.userProfile.selectedProfileName;
