import { TesterListVO } from "../../../types/userProfile";
import { useAppSelector } from "../../../hooks/hooks";
import { deleteProfileStatus, userProfileStatus } from "../../../store/userProfileSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { useTesterFilters } from "./hooks/useTesterFilters";
import { useTesterList } from "./hooks/useTesterList";
import { useTesterColumns } from "./hooks/useTesterColumns";
import React, { useCallback, useMemo, useState } from "react";
import { ListTable } from "../../../layout/content/list/ListTable";
import { DetailActionButton } from "../../../components/Buttons";
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import { useTesterDeleter } from "../user/tester/hooks/useTesterDeleter";
import { useTranslation } from "react-i18next";
import { ListCount, WhichList } from "../../../components/ListCount";

export function MemberTester() {
  const { t } = useTranslation("tester");
  const status = useAppSelector(userProfileStatus);
  const deleteStatus = useAppSelector(deleteProfileStatus);
  useLoader({ status: [status, deleteStatus] });

  const [selectedProfileIdxes, setSelectedProfileIdxes] = useState<number[]>([]);

  const { searchTesterList, totalTesterCount, testers, pageCondition } = useTesterList({
    onCompletedCallback: () => setSelectedProfileIdxes([]),
  });
  const { filters } = useTesterFilters({ search: searchTesterList({}) });

  const refresh = useCallback(() => {
    searchTesterList(pageCondition)();
    setSelectedProfileIdxes([]);
  }, [searchTesterList, pageCondition, setSelectedProfileIdxes]);
  const { columns } = useTesterColumns({ onRefreshCallback: refresh });
  const { deleteUserProfiles } = useTesterDeleter({
    onCompleteCallback: refresh,
  });

  const onClickDeleteTesterButton = useCallback(
    (profiles: TesterListVO[]) => {
      if (profiles.length === 0) {
        alert(t("delete.noSelect"));
        return;
      }

      const hasVoucher = profiles.some((profile) => Boolean(profile.productVoucherIdx));

      if (hasVoucher) {
        alert(t("delete.cannotDeleteAlertMessage"));
        return;
      }

      const isConfirmed = window.confirm(t("delete.confirmMessage"));
      if (isConfirmed) {
        deleteUserProfiles(profiles.map((profile) => profile.idx));
      }
    },
    [deleteUserProfiles, t]
  );

  const selectedProfiles = useMemo(
    () => testers?.content.filter((tester) => selectedProfileIdxes.includes(tester.idx)) ?? [],
    [testers?.content, selectedProfileIdxes]
  );

  return (
    <>
      {testers ? (
        <ListTable
          pageOnFilter={pageCondition}
          filters={filters}
          buttonsNode={
            <>
              <div>
                <DetailActionButton onClick={() => onClickDeleteTesterButton(selectedProfiles)}>
                  <img src={deleteIcon} alt={t("delete.iconAlt")} />
                  {t("delete.buttonLabel")}
                </DetailActionButton>
              </div>
            </>
          }
          checkedRowIdxArr={selectedProfileIdxes}
          setCheckedRowIdxArr={setSelectedProfileIdxes}
          showCheckbox={(vo) => !vo.isOwnerProfile}
          listData={testers}
          getList={({ page, sort, size }) => searchTesterList({ page, sort, size })}
          columns={columns}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalTesterCount} whichList={WhichList.TESTER} />,
            singleUnitKeyInCommonFile: "testerUnit.singleComponent",
            multipleUnitKeyInCommonFile: "testerUnit.multipleComponent",
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
