import { useFile } from "../../../hooks/file/useFile";
import { useCallback } from "react";
import { FileClassification, FileResponse } from "../../../types/file";

export function useProductDetailImageUploader() {
  const { uploadImages } = useFile();

  const getFileType = useCallback((fileType: string) => fileType.split("/")[0], []);

  const uploadDetailImages = useCallback(
    async (files: File[]) => {
      const groupedFiles: File[][] = files.reduce((acc: File[][], currentFile: File, currentIndex) => {
        if (currentIndex === 0) {
          acc.push([currentFile]);
        } else {
          const lastFileGroup = acc[acc.length - 1];
          const lastFile = lastFileGroup[lastFileGroup.length - 1];
          const lastFileType = getFileType(lastFile.type);
          if (getFileType(currentFile.type) === lastFileType) {
            acc[acc.length - 1].push(currentFile);
          } else {
            acc.push([currentFile]);
          }
        }
        return acc;
      }, []);

      const result: FileResponse[][] = await Promise.all(
        groupedFiles.map(async (fileGroup) => {
          const uploadedFileResponse: FileResponse[] = await uploadImages(
            fileGroup,
            getFileType(fileGroup[0].type) === "image"
              ? FileClassification.PRODUCT_IMAGE
              : FileClassification.PRODUCT_VIDEO
          );
          return uploadedFileResponse;
        })
      );

      return result.flatMap((response) => response);
    },
    [getFileType, uploadImages]
  );

  return {
    uploadDetailImages,
  };
}
