import { useAppDispatch } from "../../../../../hooks/hooks";
import { useCallback } from "react";
import { deleteProfilesAsync } from "../../../../../store/userProfileSlice";
import { callAsync } from "../../../../../util/sliceUtil";
import { useTranslation } from "react-i18next";

interface ReturnType {
  deleteUserProfiles: (profileIdxes: number[]) => void;
}

interface Props {
  onCompleteCallback: VoidFunction;
}

export function useTesterDeleter({ onCompleteCallback }: Props): ReturnType {
  const { t } = useTranslation(["user", "common"]);
  const dispatch = useAppDispatch();

  const deleteUserProfiles = useCallback(
    (profileIdxes: number[]) => {
      callAsync(
        dispatch(deleteProfilesAsync(profileIdxes)).unwrap(),
        () => {
          alert(t("detail.profile.delete.resultMessage"));
          onCompleteCallback();
        },
        () => {
          alert(t("defaultErrorMessage"));
        }
      ).then();
    },
    [dispatch, t, onCompleteCallback]
  );

  return {
    deleteUserProfiles,
  };
}
