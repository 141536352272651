import { TableColumnData } from "../../../../types/common";
import { AffiliateCodeListVO, AffiliateCodeStatus, CodeDiscountType } from "../../../../types/affiliateCode";
import { useMemo } from "react";
import { useTimeConverter } from "../../../../hooks/hooks";
import { numberWithCommas } from "../../../../util/etcUtil";
import { ActionButton, RedActionButton } from "../../../../components/Buttons";

interface Props {
  selectedCodeIdx: number | null;
  setSelectedCodeIdx: (_: number | null) => void;
}
export function usePartnerAffiliateCodeColumns({ selectedCodeIdx, setSelectedCodeIdx }: Props) {
  const { timeConverter } = useTimeConverter();

  const discountColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "discountValue",
      header: "할인 금액",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <>
          {numberWithCommas(vo.discountValue)}
          {vo.discountType === CodeDiscountType.PERCENT ? "%" : "원"} 할인
        </>
      ),
      width: 150,
    }),
    []
  );

  const availableDurationColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "availableStartAt",
      header: "사용기한",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <>
          {vo.status === AffiliateCodeStatus.DELETED ? (
            "삭제됨"
          ) : (
            <>
              {timeConverter.convertToLocalDate(vo.availableStartAt)}
              <br />~ {vo.availableEndAt ? timeConverter.convertToLocalDate(vo.availableEndAt) : "상시사용"}
            </>
          )}
        </>
      ),
      width: 200,
    }),
    [timeConverter]
  );

  const nameColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "name",
      header: "이름",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => <>{vo.name}</>,
      width: 150,
    }),
    []
  );

  const emailColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "email",
      header: "아이디",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => <>{vo.email}</>,
      width: 180,
    }),
    []
  );

  const phoneNumberColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "phoneNumber",
      header: "휴대전화",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <>
          ({vo.codePhone}) {vo.phoneNumber}
        </>
      ),
      width: 180,
    }),
    []
  );

  const codeColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "code",
      header: "제휴코드",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => <>{vo.code}</>,
      width: 120,
    }),
    []
  );

  const columns: TableColumnData<AffiliateCodeListVO>[] = useMemo(
    () => [nameColumn, emailColumn, phoneNumberColumn, codeColumn, discountColumn, availableDurationColumn],
    [nameColumn, emailColumn, phoneNumberColumn, codeColumn, discountColumn, availableDurationColumn]
  );

  const selectButtonColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) =>
        selectedCodeIdx === vo.idx ? (
          <span className={"blue"}>추가됨</span>
        ) : (
          <ActionButton
            type={"button"}
            onClick={(e) => {
              e.preventDefault();

              if (selectedCodeIdx) {
                alert("이미 보유자가 선택되어 있습니다.\n선택된 보유자를 삭제하고 다시 시도해주세요.");
                return;
              }
              setSelectedCodeIdx(vo.idx);
            }}
          >
            선택
          </ActionButton>
        ),
      width: 80,
    }),
    [selectedCodeIdx, setSelectedCodeIdx]
  );

  const deleteButtonColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: () => (
        <RedActionButton
          onClick={(e) => {
            e.preventDefault();
            setSelectedCodeIdx(null);
          }}
        >
          삭제
        </RedActionButton>
      ),
    }),
    [setSelectedCodeIdx]
  );

  return {
    columns: [...columns, selectButtonColumn],
    selectedCodeColumns: [...columns, deleteButtonColumn],
    columnsOnDetail: [
      { ...nameColumn, width: 120 },
      { ...emailColumn, width: 160 },
      { ...phoneNumberColumn, width: 160 },
      { ...codeColumn, width: 80 },
      { ...discountColumn, width: 100 },
      { ...availableDurationColumn, width: 120 },
      { ...selectButtonColumn, width: 66 },
    ],
    selectedCodeColumnsOnDetail: [
      { ...nameColumn, width: 120 },
      { ...emailColumn, width: 160 },
      { ...phoneNumberColumn, width: 160 },
      { ...codeColumn, width: 80 },
      { ...discountColumn, width: 100 },
      { ...availableDurationColumn, width: 120 },
      { ...deleteButtonColumn, width: 66 },
    ],
  };
}
