import { useCallback, useMemo, useState } from "react";
import { AdminQueryType, AdminStatus } from "../../../../types/admin";
import { ListQueryType } from "../../../../types/list";
import { FilterType, FilterValue } from "../../../../types/filter";
import { PageSearchCondition } from "../../../../types/page";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface AdminFilterValues {
  searchWordTypeFilterValue: FilterValue<AdminQueryType>;
  searchWordFilterValue: FilterValue<string>;
  createdAtFromFilterValue: FilterValue<Date | undefined>;
  createdAtToFilterValue: FilterValue<Date | undefined>;
  adminStatusFilterValue: FilterValue<AdminStatus | "">;
  adminRoleIdxFilterValue: FilterValue<string>;
}

export interface AdminListQueryType extends ListQueryType {
  searchWordType?: string;
  searchWord?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  adminStatus?: string;
  adminRoleIdx?: string;
}

export function useAdminFilterValues() {
  const [searchWordType, setSearchWordType] = useState<AdminQueryType>(AdminQueryType.NAME);
  const [searchWord, setSearchWord] = useState("");
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();
  const [adminStatus, setAdminStatus] = useState<AdminStatus | "">("");
  const [adminRoleIdx, setAdminRoleIdx] = useState<string>("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<AdminListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      {
        key: "searchWordType",
        value: searchWordType,
        setter: setSearchWordType,
        defaultValue: AdminQueryType.NAME,
        type: FilterType.OTHER,
      },
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      { key: "adminStatus", value: adminStatus, setter: setAdminStatus, defaultValue: "", type: FilterType.STRING },
      { key: "adminRoleIdx", value: adminRoleIdx, setter: setAdminRoleIdx, defaultValue: "", type: FilterType.STRING },
    ],
    [searchWordType, searchWord, createdAtFrom, createdAtTo, adminStatus, adminRoleIdx]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<AdminQueryType>,
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
        createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
        adminStatusFilterValue: getFilterValueByKey("adminStatus") as FilterValue<AdminStatus | "">,
        adminRoleIdxFilterValue: getFilterValueByKey("adminRoleIdx") as FilterValue<string>,
      } as AdminFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: AdminListQueryType) => {
      const param: AdminListQueryType = {
        searchWordType: listQueryTypeParam?.searchWordType,
        searchWord: listQueryTypeParam?.searchWord,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
        adminStatus: listQueryTypeParam?.adminStatus,
        adminRoleIdx: listQueryTypeParam?.adminRoleIdx,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
