import {useValidator} from "../../../hooks/useValidator";
import {useCallback} from "react";
import {pwdRegExp} from "../../../util/regexpUtil";
import {OrganManagerCellphoneValidStatus, OrganManagerIdValidStatus} from "../../../types/organ";

interface Props {
    nation: string,
    id: string,
    name: string;
    phoneCountryCode: string,
    cellphone: string,
    password:string,
    confirmPassword: string
    confirmMessage: string;
    idValid: OrganManagerIdValidStatus;
    cellphoneValid: OrganManagerCellphoneValidStatus;
    setIdValid: (_: OrganManagerIdValidStatus) => void;
    setCellphoneValid: (_: OrganManagerCellphoneValidStatus) => void;
}

export function useOrganManagerValidator({
                                             nation,
                                             id,
                                             name,
                                             phoneCountryCode,
                                             cellphone,
                                             password,
                                             confirmPassword,
                                             confirmMessage,
                                             idValid,
                                             cellphoneValid,
                                             setIdValid,
                                             setCellphoneValid
                                         }: Props) {


    const validator = useValidator();

    // 국내 휴대폰번호 길이 제한 유효성 검사
    const innerCellPhoneLengthValid = phoneCountryCode === '+82' && validator.getTotalLength(cellphone) >= 11

    // 국외 휴대폰번호 길이 제한 유효성 검사
    const outerCellPhoneLengthValid = phoneCountryCode !== '+82' && validator.getTotalLength(cellphone) >= 5;

    const validate = useCallback(() => {
        if (nation === '') {
            alert('국적을 선택해주세요.');
            return false;
        }

        if (idValid !== OrganManagerIdValidStatus.VALID) {
            setIdValid(OrganManagerIdValidStatus.UNCHECKED)
            alert('아이디 중복확인 버튼을 눌러주세요.')
            return false;
        }

        if ((id && validator.getTotalLength(id) > 60) ||
            (id && validator.getTotalLength(id) < 5)) {
            alert('아이디를 수정해주세요.\n ※ 5자 이상 60자 이하');
            return false;
        }

        if (phoneCountryCode === '') {
            alert('휴대폰 번호 국적을 선택해주세요.');
            return false;
        }

        if (cellphone && !innerCellPhoneLengthValid && phoneCountryCode === '+82') {
            setCellphoneValid(OrganManagerCellphoneValidStatus.INNER_LENGTH)
            alert('휴대폰번호를 양식에 맞춰주세요.');
            return false;
        }

        if (cellphone && !outerCellPhoneLengthValid && phoneCountryCode !== '+82') {
            setCellphoneValid(OrganManagerCellphoneValidStatus.OUTER_LENGTH)
            alert('휴대폰번호를 양식에 맞춰주세요.');
            return false;
        }

        if (cellphoneValid !== OrganManagerCellphoneValidStatus.VALID) {
            setCellphoneValid(OrganManagerCellphoneValidStatus.UNCHECKED)
            alert('휴대폰번호 중복확인 버튼을 눌러주세요.')
            return false;
        }

        if ((name && validator.getTotalLength(name) > 50) ||
            (name && validator.getTotalLength(name) < 2)) {
            alert('이름을 수정해주세요.\n ※ 2자 이상 50자 이하');
            return false;
        }

        if (!pwdRegExp.test(password)) {
            alert('비밀번호 양식에 맞춰주세요.');
            return false;
        }

        if (password !== confirmPassword) {
            alert('비밀번호가 일치하지 않습니다.');
            return false;
        }

        return window.confirm(confirmMessage);
        //eslint-disable-next-line
    }, [
        nation,
        id,
        name,
        phoneCountryCode,
        cellphone,
        password,
        confirmPassword,
        idValid,
        cellphoneValid,
        setIdValid,
        setCellphoneValid
    ]);
    return {validate};
}