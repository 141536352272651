import { TesterOrganVoucherStatus } from "./organ_voucher/TesterOrganVoucherStatus";
import { TesterOrganVoucherList } from "./organ_voucher/TesterOrganVoucherList";

interface Props {
  profileIdx: number;
}

export function ProfileOrganVoucher({ profileIdx }: Props) {
  return (
    <>
      <TesterOrganVoucherStatus profileIdx={profileIdx} />
      <TesterOrganVoucherList profileIdx={profileIdx} />
    </>
  );
}
