import {Languages, ListObject} from "./common";
import { AddedFile } from "./file";
import { SearchCondition } from "./page";
import { UnitItemType } from "./unitItem";
import { ProductItemDTO } from "./productItem";

enum ProductStatus {
  IS_SELLING = "IS_SELLING",
  STOP_SELLING = "STOP_SELLING",
}

enum DisplayPosition {
  USER_WEB_KPASS = "USER_WEB_KPASS",
  USER_WEB_DCAS = "USER_WEB_DCAS",
  USER_APP = "USER_APP",
  ORGAN_WEB_KPASS = "ORGAN_WEB_KPASS",
  ORGAN_WEB_DCAS = "ORGAN_WEB_DCAS",
  GRANTABLE_PRODUCT = "GRANTABLE_PRODUCT",
  USER_APP_WITH_IOS = "USER_APP_WITH_IOS",
  USER_APP_KPASS_IOS = "USER_APP_KPASS_IOS",
  USER_APP_KPASS_ANDROID = "USER_APP_KPASS_ANDROID",
  USER_APP_DCAS_IOS = "USER_APP_DCAS_IOS",
  USER_APP_DCAS_ANDROID = "USER_APP_DCAS_ANDROID",
}

enum ProductDiscountType {
  AMOUNT = "AMOUNT",
  PERCENT = "PERCENT",
  NONE = "NONE",
}

interface ProductListObj extends ListObject {
  idx: number;
  name: string;
  thumbnailUri: string;
  thumbnailUrl: string;
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountValue: number;
  sellingPrice: number;
  isCouponUsable: boolean;
  createAt: string;
  productItemCount: number;
  language: Languages;
}

interface GrantableProductListObj extends ListObject {
  idx: number;
  name: string;
  status: ProductStatus;
  createAt: string;
  productItemCount: number;
  qty: number;
}

interface ProductImage {
  idx: number;
  file: AddedFile;
  productIdx: number;
  orderNum: number;
}

export interface ProductDTO {
  idx: number;
  name: string;
  description: string[];
  language: Languages;
  specialDescription: string[];
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue: number;
  sellingPrice: number;
  thumbnail: AddedFile;
  productItemCount: number;
  isCouponUsable: boolean;
}

export interface ProductDetailImageDTO {
  desktopImages: ProductImage[];
  mobileImages: ProductImage[];
}

export interface ProductVO {
  idx: number;
  name: string;
  description: string[];
  language: Languages;
  specialDescription: string[];
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue: number;
  sellingPrice: number;
  thumbnail: AddedFile;
  productItemCount: number;
  isCouponUsable: boolean;
  desktopImages: ProductImage[];
  mobileImages: ProductImage[];
  productItems: ProductItemDTO[];
}

export namespace ProductVO {
  export const getProductVO = (
    productDTO: ProductDTO,
    items: ProductItemDTO[],
    images: ProductDetailImageDTO
  ): ProductVO => {
    const sortOrderNumAsc = (a: ProductImage, b: ProductImage) => a.orderNum - b.orderNum;
    return {
      ...productDTO,
      desktopImages: images.desktopImages.sort(sortOrderNumAsc),
      mobileImages: images.mobileImages.sort(sortOrderNumAsc),
      productItems: items,
    };
  };
}

interface ProductDetail {
  idx: number;
  name: string;
  description: string[];
  specialDescription: string[];
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue: number;
  sellingPrice: number;
  isCouponUsable: boolean;
  thumbnail: AddedFile;
  productItems: ProductItemDTO[];
  detailImages: ProductImage[];
}

interface ProductSearchCondition extends SearchCondition {
  q?: string;
  displayPosition?: DisplayPosition;
  language?: Languages;
  status?: ProductStatus;
  isCouponUsable?: boolean;
  sellingStartDate?: Date;
  sellingEndDate?: Date;
  createdAtFrom?: Date;
  createdAtTo?: Date;
}

interface ProductItemPostParam {
  unitItemIdx: number;
}

interface ProductImagePostParam {
  file: AddedFile;
  orderNum: number;
  type: ProductImageType;
}

interface ProductPostParam {
  name: string;
  displayPosition: DisplayPosition;
  language: Languages;
  thumbnail: AddedFile;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue?: number;
  sellingPrice: number;
  sellingStartDate?: string;
  sellingEndDate?: string;
  productItems: ProductItemPostParam[];
  description: string[];
  specialDescription?: string[];
  desktopImages: ProductImagePostParam[] | undefined;
  mobileImages: ProductImagePostParam[] | undefined;
  isCouponUsable: boolean;
}

interface GrantableProductPostParam {
  name: string;
  productItems: ProductItemPostParam[];
}

interface ProductItemInDetail {
  idx: number;
  unitItemName: string;
  itemType: UnitItemType;
}

interface ProductStatusUpdateParam {
  idxes: number[];
  status: ProductStatus;
}

interface ProductSummaryDetail {
  idx: number;
  name: string;
  description: string[];
  specialDescription: string[];
  status: ProductStatus;
  sellingStartDate: string;
  sellingEndDate: string;
  displayPosition: DisplayPosition;
  regularPrice: number;
  discountType: ProductDiscountType;
  discountValue: number;
  sellingPrice: number;
  isCouponUsable: boolean;
  thumbnail: AddedFile;
}

export enum ProductImageType {
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export enum ProductImageVOType {
  NONE = "NONE",
  DESKTOP = "DESKTOP",
  MOBILE = "MOBILE",
}

export namespace ProductImageVOType {
  export const toVOType = (imageType: ProductImageType): ProductImageVOType => {
    switch (imageType) {
      case ProductImageType.DESKTOP:
        return ProductImageVOType.DESKTOP;
      case ProductImageType.MOBILE:
        return ProductImageVOType.MOBILE;
    }
  };
  export const getProductImageVOType = (
    imageType: ProductImageType,
    displayPosition: DisplayPosition
  ): ProductImageVOType => {
    if (displayPosition === DisplayPosition.USER_WEB_KPASS ||
        displayPosition === DisplayPosition.USER_WEB_DCAS) {
      return toVOType(imageType);
    } else {
      return ProductImageVOType.NONE;
    }
  };
}

interface ProductImagePutParam {
  idx?: number;
  file: AddedFile;
  orderNum: number;
  type: ProductImageType;
}

interface ProductPutParam {
  name: string;
  thumbnail: AddedFile;
  regularPrice: number;
  discountType: ProductDiscountType;
  language: Languages;
  discountValue?: number;
  sellingPrice: number;
  sellingStartDate?: string;
  sellingEndDate?: string;
  description: string[];
  specialDescription?: string[];
  desktopImages: ProductImagePutParam[] | undefined;
  mobileImages: ProductImagePutParam[] | undefined;
  isCouponUsable: boolean;
}

export type {
  ProductListObj,
  ProductDetail,
  ProductImage,
  ProductSearchCondition,
  ProductItemPostParam,
  ProductImagePostParam,
  ProductPostParam,
  ProductItemInDetail,
  GrantableProductListObj,
  GrantableProductPostParam,
  ProductStatusUpdateParam,
  ProductSummaryDetail,
  ProductPutParam,
  ProductImagePutParam,
};
export { ProductStatus, DisplayPosition, ProductDiscountType };
