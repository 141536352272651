import { getPageResponseInitialized, PageResponse } from "../../../types/page";
import { ListObject, TableColumnData } from "../../../types/common";
import { FilterContentType, TableFilter } from "../../../types/tableFilter";

export interface SampleListObject extends ListObject {
  name: string;
  orderNumber: number;
  organName: string;
}

export const sampleListData: PageResponse<SampleListObject> = {
  ...getPageResponseInitialized(
    [
      { idx: 101, name: "홍길동", orderNumber: 1, organName: "가나다 초등학교" },
      { idx: 102, name: "김길동", orderNumber: 2, organName: "가나다라 학원" },
      { idx: 103, name: "박길동", orderNumber: 1, organName: "ABC 유치원" },
    ],
    20
  ),
  totalPages: 1,
  totalElements: 3,
  last: false,
};

export const sampleColumns: TableColumnData<SampleListObject>[] = [
  {
    accessor: "name",
    header: "이름",
    sortable: false,
  },
  {
    accessor: "organName",
    header: "소속",
    isLeftAlign: true,
    sortable: true,
  },
  {
    accessor: "orderNumber",
    header: "번호",
    sortable: false,
  },
];

export const sampleFilters: TableFilter[][] = [
  [
    {
      labelTitle: "TEXT",
      content: {
        type: FilterContentType.TEXT,
        value: "",
        maxLength: 100,
        placeholderVal: "텍스트 placeholder",
        onChangeFunc: (_) => {},
        typeSelector: {
          type: FilterContentType.SELECT,
          value: "",
          options: [
            { label: "전체", value: "" },
            { label: "가나다", value: "abc" },
            { label: "라마바사", value: "defg" },
          ],
          onChangeFunc: (_) => {},
        },
      },
    },
  ],
  [
    {
      labelTitle: "SELECT",
      content: {
        type: FilterContentType.SELECT,
        value: "",
        options: [
          { label: "전체", value: "" },
          { label: "가나다", value: "abc" },
          { label: "라마바사", value: "defg" },
        ],
        onChangeFunc: (_) => {},
      },
    },
    {
      labelTitle: "CALENDAR",
      content: {
        type: FilterContentType.CALENDAR,
        value: "",
        onChangeFunc: (_) => {},
        placeholderVal: "",
        isRequired: true,
        isDisabled: false,
      },
    },
    {
      labelTitle: "NUMBER",
      content: {
        type: FilterContentType.NUMBER,
        value: "",
        max: 100,
        min: 1,
        isRequired: true,
        placeholderVal: "입력하세요",
        onChangeFunc: (_) => {},
      },
    },
  ],
  [
    {
      labelTitle: "NUMBER 범위",
      content: {
        type: FilterContentType.NUMBER_RANGE,
        from: {
          type: FilterContentType.NUMBER,
          value: "",
          onChangeFunc: (_) => {},
        },
        to: {
          type: FilterContentType.NUMBER,
          value: "",
          onChangeFunc: (_) => {},
        },
      },
    },
    {
      labelTitle: "NUMBER 범위",
      content: {
        type: FilterContentType.CALENDAR_RANGE,
        from: {
          type: FilterContentType.CALENDAR,
          value: "",
          onChangeFunc: (_) => {},
        },
        to: {
          type: FilterContentType.CALENDAR,
          value: "",
          onChangeFunc: (_) => {},
        },
        typeSelector: {
          type: FilterContentType.SELECT,
          value: "",
          options: [
            { label: "전체", value: "" },
            { label: "가나다", value: "abc" },
            { label: "라마바사", value: "defg" },
          ],
          onChangeFunc: (_) => {},
        },
      },
    },
  ],
];
