import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { ListTable, TotalRowsCnt } from "../../../layout/content/list/ListTable";
import { sampleColumns, sampleFilters, sampleListData } from "./data";
import couponIcon from "../../../assets/images/button_icons/coupon_icon.svg";
import stopIcon from "../../../assets/images/button_icons/stop_icon.svg";
import { DetailActionButton } from "../../../components/Buttons";

export function ArticleListTable({ id, title }: ArticleProps) {
  const listData = sampleListData;
  const columns = sampleColumns;
  const totalRowsCnt: TotalRowsCnt = {
    totalCount: <></>,
    singleUnitKeyInCommonFile: "명",
    multipleUnitKeyInCommonFile: "명",
  };

  const code = `
<ListTable filters={sampleFilters}
           buttonsNode={<>
             <div>
               <DetailActionButton>
                 <img src={stopIcon} alt={"멈춤 아이콘"}/>
                 판매 중지
               </DetailActionButton>
             </div>
             <div>
              <DetailActionButton>
                <img src={couponIcon} alt={"쿠폰 아이콘"}/>
                쿠폰 부여
              </DetailActionButton>
             </div>
           </>}
           listData={listData}
           getList={() => () => {}}
           columns={columns}
           totalRowsCnt={totalRowsCnt}/>
  `;

  const description = `
- ListTable의 Props 타입
{
  filters: TableFilter[][] | null; -- 검색 필터 
  buttonsNode: ReactNode; -- 테이블 위 아래에 들어갈 버튼 영역의 element, 첫번째 div는 왼쪽에, 두번째 div는 오른쪽에 위치하게 됨
  listData: PageResponse<T>; -- 테이블의 데이터
  getList: (pageCondition: PageSearchCondition) => VoidFunction; -- 테이블에 보여줄 데이터를 불러오는 함수
  columns: TableColumnData<T>[]; -- 테이블의 칼럼 데이터
  totalRowsCnt: TotalRowsCnt; -- 결과 카운트 수 관련 데이터
  checkedRowIdxArr?: number[]; -- 이 데이터 넣으면 첫 칼럼에 체크박스 칼럼 자동 인서트
  setCheckedRowIdxArr?: (_: number[]) => void; -- 체크된 테이블 데이터의 idx의 array 
  showCheckbox?: (_: T) => boolean; -- 체크박스를 보여줄 조건을 나타내는 함수
}
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code} description={description}>
          <ListTable
            filters={sampleFilters}
            buttonsNode={
              <>
                <div>
                  <DetailActionButton>
                    <img src={stopIcon} alt={"멈춤 아이콘"} />
                    판매 중지
                  </DetailActionButton>
                </div>
                <div>
                  <DetailActionButton>
                    <img src={couponIcon} alt={"쿠폰 아이콘"} />
                    쿠폰 부여
                  </DetailActionButton>
                </div>
              </>
            }
            listData={listData}
            getList={() => () => {}}
            columns={columns}
            totalRowsCnt={totalRowsCnt}
            pageOnFilter={{}}
          />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
