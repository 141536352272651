import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { useUtil } from "../../../../util/hooks/useUtil";
import { deletePartnerCompaniesAsync } from "../../../../store/partnerCompanySlice";

interface Args {
  onCompletedCallback: () => void;
}
export function usePartnerCompaniesDeleter({ onCompletedCallback }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deletePartnerCompanies = useCallback(
    async (idxes: number[]) => {
      if (idxes.length === 0) {
        alert("협력사를 선택해주세요.");
        return;
      }

      if (!window.confirm("선택한 협력사를 삭제 하시겠습니까?")) {
        return;
      }

      try {
        await dispatch(deletePartnerCompaniesAsync(idxes)).unwrap();
        alert("협력사가 삭제 되었습니다.");
        onCompletedCallback();
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("협력사 삭제 에러");
      }
    },
    [onCompletedCallback, defaultErrorMessage, dispatch]
  );

  return {
    deletePartnerCompanies,
  };
}
