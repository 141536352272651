import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { MiniModal } from "../../../layout/modal/MiniModal";
import { useRef, useState } from "react";
import { Anchor } from "../../../components/Anchor";
import { MINI_MODAL_LINK } from "../../../util/etcUtil";

export function ArticleMiniModal({ id, title }: ArticleProps) {
  const [showModal, setShowModal] = useState(false);
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const code = `
const [showModal, setShowModal] = useState(false);

<Anchor
  onClick={(e) => {
    setShowModal(true);
  }}
  style={{ position: "relative" }}
  data-name={MINI_MODAL_LINK}
  ref={linkRef}
>
  Mini Modal 열기
  <MiniModal
    show={showModal}
    closeModal={() => setShowModal(false)}
    title={"미니모달 샘플"}
    modalWidth={"200px"}
    linkElement={linkRef.current ?? undefined}
  >
    <div>내용</div>
  </MiniModal>
</Anchor>
  `;

  const description = `
- 클릭하는 element에 
  - data-name={MINI_MODAL_LINK} 추가 필요
  - ref 지정 필요
- mini modal에 linkElement 전달해야 해당 링크를 클릭했을 때 미니모달이 열림
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code} description={description}>
          <Anchor
            onClick={(e) => {
              setShowModal(true);
            }}
            style={{ position: "relative" }}
            data-name={MINI_MODAL_LINK}
            ref={linkRef}
          >
            Mini Modal 열기
            <MiniModal
              show={showModal}
              closeModal={() => setShowModal(false)}
              title={"미니모달 샘플"}
              modalWidth={"200px"}
              linkElement={linkRef.current ?? undefined}
            >
              <div>내용</div>
            </MiniModal>
          </Anchor>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
