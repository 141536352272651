import {TableColumnData} from "../../../../types/common";
import React from "react";
import {useTimeConverter} from "../../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {SimpleTesterVO} from "../../../../types/tester";
import styles from "../../../../layout/content/list/table/tableButton.module.scss";
import {useOpenSimpleTestResult} from "../../../../hooks/useOpenSimpleTestResult";
import {callAsync} from "../../../../util/sliceUtil";
import {useSimpleTesterMarketingDisagree} from "./useSimpleTesterMarketingDisagree";

interface UseTesterColumnsReturnType {
  columns: TableColumnData<SimpleTesterVO>[];
}

export function useSimpleTesterColumns(): UseTesterColumnsReturnType {
  const { t } = useTranslation("simpleTester");
  const { timeConverter } = useTimeConverter();
  const openSimpleTestResult = useOpenSimpleTestResult();
  const {marketingDisagree} = useSimpleTesterMarketingDisagree();

  const openMarketingAgreementChange = (selectedIdx:number) =>{
    const confirm =  window.confirm(t('confirmMessage'));
    if (confirm) {
      callAsync(
          marketingDisagree([selectedIdx]),
          () => {
          },
          () => alert('오류가 발생했습니다.\n다시 시도해주세요.')
      ).then();
        alert(t('disableMessage'))
    }
  }

  const columns: TableColumnData<SimpleTesterVO>[] = [
    {
      accessor: "createdAt",
      header: t("list.createAt"),
      getNode: (t: SimpleTesterVO) => <span>{timeConverter.convertToLocalTime(t.createdAt)}</span>,
      sortable: true,
    },
    {
      accessor: "name",
      header: t("list.name"),
      getNode: (it: SimpleTesterVO) =>
        (
            <>{it.name}</>
        ),
      sortable: false,
    },
    {
      accessor: "birthday",
      header: t("list.age"),
      getNode: (t: SimpleTesterVO) =>
        // (<>{t.birthday === 0 || t.birthday ? `${t.birthday}` : `-`}</>),
        (<>{t.birthday ? `${t.birthday}` : `-`}</>),
      sortable: false,
    },
    {
      accessor: "phoneNumber",
      header: t("list.phoneNumber"),
      getNode: (t: SimpleTesterVO) =>
          (
              <>{t.phoneNumber ? ` ${t.phoneNumber}` : `-`}</>
        ),
      sortable: false,
    },
    {
      accessor: "accessLocation",
      header: t("list.approached"),
      getNode: (t: SimpleTesterVO) =>
          (
              <>{t.accessLocation}</>
          ),
      sortable: false,
    },
    {
      accessor: "isAgreementMarketing",
      header: t("list.marketingYn"),
      getNode: (t: SimpleTesterVO) =>
          (
              <>
                {t.isAgreementMarketing === true ? 'Y' : 'N'}
              </>
          ),
      sortable: false,
    },
    {
      accessor: "discountCode",
      header: t("list.saleCode"),
      getNode: (t: SimpleTesterVO) =>
          (
              <>{t.discountCode ? `${t.discountCode}` : `-`}</>
          ),
      sortable: false,
    },
    {
      accessor: "testResultIdx",
      header: t("list.functions"),
      getNode: (t: SimpleTesterVO) =>
          (
              <>
                <div className={styles.additionalFunctionWrapper}>
                  {t.testResultIdx ? (
                      <button className={styles.testResultBtn} onClick={() => openSimpleTestResult(t.executedInfoIdx)}>
                        <span className={styles.text}>검사결과</span>
                      </button>
                  ) : null}
                  {t.isAgreementMarketing ? (
                      <button className={styles.withdrawBtn} onClick={() => openMarketingAgreementChange(t.idx)}>
                        <span className={styles.text}>마케팅 동의 철회</span>
                      </button>
                  ) : null}
                  {!t.isAgreementMarketing && !t.testResultIdx ? (
                      <span className={styles.text}>-</span>
                  ) : null}
                </div>
              </>
          ),
      sortable: false,
      padding: '5px 12px',
    },
  ];

  return {columns};
}
