import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import {
  PartnerCompanyListDTO,
  PartnerCompanyListVO,
  PartnerCompanySearchCondition,
} from "../../../../types/partnerCompany";
import { getPartnerCompaniesAsync } from "../../../../store/partnerCompanySlice";
import { PageResponse } from "../../../../types/page";

export function usePartnerCompaniesGetter() {
  const dispatch = useAppDispatch();

  const getPartnerCompanies = useCallback(
    async (param: PartnerCompanySearchCondition): Promise<PageResponse<PartnerCompanyListVO>> => {
      try {
        const result: PageResponse<PartnerCompanyListDTO> = await dispatch(getPartnerCompaniesAsync(param)).unwrap();
        return {
          ...result,
          content: result.content.map((dto) => ({
            idx: dto.idx,
            editedOrderingNumber: dto.numericOrder ?? undefined,
            numericOrder: dto.numericOrder ?? undefined,
            createdAt: dto.createdAt,
            name: dto.name,
            type: dto.type,
            affiliateCode: dto.affiliateCodeDTO?.code,
            discountType: dto.affiliateCodeDTO?.discountType,
            discountValue: dto.affiliateCodeDTO?.discountValue,
            codeAvailableStartAt: dto.affiliateCodeDTO?.availableStartAt,
            codeAvailableEndAt: dto.affiliateCodeDTO?.availableEndAt,
            codeStatus: dto.affiliateCodeDTO?.status,
            status: dto.status,
          })),
        };
      } catch (e) {
        console.error(e);
        throw new Error("getPartnerCompanies occurs an error");
      }
    },
    [dispatch]
  );

  return {
    getPartnerCompanies,
  };
}
