import { OrderSearchDateType, OrderSearchWordType, OrderStatus } from "../../../types/orders";
import { PaymentWay } from "../../../types/payment";
import { DisplayPosition } from "../../../types/product";
import { useCallback, useMemo, useState } from "react";
import { ListQueryType } from "../../../types/list";
import { FilterType, FilterValue } from "../../../types/filter";
import { PageSearchCondition } from "../../../types/page";
import { useFilterValues } from "../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../hooks/list/useListSearchParams";

export interface OrderListQueryType extends ListQueryType {
  query?: string;
  queryType?: string;
  orderSearchDateType?: string;
  startDate?: string;
  endDate?: string;
  paymentWay?: string;
  status?: string;
  displayPosition?: string;
}

export interface OrderFilterValues {
  queryFilterValue: FilterValue<string>;
  queryTypeFilterValue: FilterValue<OrderSearchWordType>;
  orderSearchDateTypeFilterValue: FilterValue<OrderSearchDateType>;
  startDateFilterValue: FilterValue<Date | undefined>;
  endDateFilterValue: FilterValue<Date | undefined>;
  paymentWayFilterValue: FilterValue<PaymentWay | "">;
  statusFilterValue: FilterValue<OrderStatus | "">;
  displayPositionFilterValue: FilterValue<DisplayPosition | "">;
}

export function useOrderFilterValues() {
  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState<OrderSearchWordType>(OrderSearchWordType.ORDERER_NAME);
  const [orderSearchDateType, setOrderSearchDateType] = useState<OrderSearchDateType>(OrderSearchDateType.ORDERED_AT);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [paymentWay, setPaymentWay] = useState<PaymentWay | "">("");
  const [status, setStatus] = useState<OrderStatus | "">("");
  const [displayPosition, setDisplayPosition] = useState<DisplayPosition | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<OrderListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "query", value: query, setter: setQuery, defaultValue: "", type: FilterType.STRING },
      {
        key: "queryType",
        value: queryType,
        setter: setQueryType,
        defaultValue: OrderSearchWordType.ORDERER_NAME,
        type: FilterType.OTHER,
      },
      {
        key: "orderSearchDateType",
        value: orderSearchDateType,
        setter: setOrderSearchDateType,
        defaultValue: OrderSearchDateType.ORDERED_AT,
        type: FilterType.OTHER,
      },
      { key: "startDate", value: startDate, setter: setStartDate, defaultValue: undefined, type: FilterType.DATE },
      { key: "endDate", value: endDate, setter: setEndDate, defaultValue: undefined, type: FilterType.DATE },
      { key: "paymentWay", value: paymentWay, setter: setPaymentWay, defaultValue: "", type: FilterType.OTHER },
      { key: "status", value: status, setter: setStatus, defaultValue: "", type: FilterType.OTHER },
      {
        key: "displayPosition",
        value: displayPosition,
        setter: setDisplayPosition,
        defaultValue: "",
        type: FilterType.OTHER,
      },
    ],
    [query, queryType, orderSearchDateType, startDate, endDate, paymentWay, status, displayPosition]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () => ({
      queryFilterValue: getFilterValueByKey("query") as FilterValue<string>,
      queryTypeFilterValue: getFilterValueByKey("queryType") as FilterValue<OrderSearchWordType>,
      orderSearchDateTypeFilterValue: getFilterValueByKey("orderSearchDateType") as FilterValue<OrderSearchDateType>,
      startDateFilterValue: getFilterValueByKey("startDate") as FilterValue<Date | undefined>,
      endDateFilterValue: getFilterValueByKey("endDate") as FilterValue<Date | undefined>,
      paymentWayFilterValue: getFilterValueByKey("paymentWay") as FilterValue<PaymentWay | "">,
      statusFilterValue: getFilterValueByKey("status") as FilterValue<OrderStatus | "">,
      displayPositionFilterValue: getFilterValueByKey("displayPosition") as FilterValue<DisplayPosition | "">,
    }),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: OrderListQueryType) => {
      const param: OrderListQueryType = {
        query: listQueryTypeParam?.query,
        queryType: listQueryTypeParam?.queryType,
        orderSearchDateType: listQueryTypeParam?.orderSearchDateType,
        startDate: listQueryTypeParam?.startDate,
        endDate: listQueryTypeParam?.endDate,
        paymentWay: listQueryTypeParam?.paymentWay,
        status: listQueryTypeParam?.status,
        displayPosition: listQueryTypeParam?.displayPosition,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
