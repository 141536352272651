import { useAppDispatch, useAppSelector } from "../../../../hooks/hooks";
import { adminMyInfo, getPersonalInfoAsync, setMyAdminInfo } from "../../../../store/myPageSlice";
import { callAsync } from "../../../../util/sliceUtil";
import { AdminVO } from "../../../../types/admin";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ErrorType } from "../../../../types/error";

export function useMyInfoGetter() {
  const { t } = useTranslation("account");
  const dispatch = useAppDispatch();
  const myInfo = useAppSelector(adminMyInfo);

  const getMyInfo = useCallback(
    (resolve?: () => void, reject?: (_: ErrorType) => void) => {
      callAsync<AdminVO>(
        dispatch(getPersonalInfoAsync()).unwrap(),
        (result) => {
          dispatch(setMyAdminInfo(result));
          resolve?.();
          return result;
        },
        (error) => {
          reject?.(error);
          console.error(t("myInfo.errorMessageLoadingUserInfo"));
        }
      ).then();
    },
    [dispatch, t]
  );

  return {
    myInfo,
    getMyInfo,
  };
}
