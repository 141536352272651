import listStyle from "./list.module.scss";
import prevIcon from "../../../assets/images/content_layout/list/paging_prev_icon.svg";
import nextIcon from "../../../assets/images/content_layout/list/paging_next_icon.svg";
import { PageResponse } from "../../../types/page";
import { useTranslation } from "react-i18next";

interface Props {
  page: PageResponse<any>;
  goSpecificPage: (pageNum: number) => void;
}

export function Pagination({ page, goSpecificPage }: Props) {
  const pageNumberCount = 5;
  const currentPage = page.number + 1;
  const pageNumberQuotient = Math.floor(page.number / pageNumberCount);
  const pageNumberStart = pageNumberQuotient * pageNumberCount + 1;
  const totalPageQuotient = Math.floor((page.totalPages - 1) / pageNumberCount);
  const { t } = useTranslation("common");

  const hasPrevPageNumbers = pageNumberQuotient > 0;
  const hasNextPageNumbers = totalPageQuotient > pageNumberQuotient;

  const getPageNumberNodes = (pageNumberStart: number, currentPage: number) => {
    return Array(pageNumberCount)
      .fill(undefined)
      .map((_, idx) => {
        const thisPageNumber = pageNumberStart + idx;
        if (thisPageNumber > page.totalPages) {
          return null;
        } else {
          return (
            <button
              key={`pageNum-${idx}`}
              className={`${listStyle.pageBtn} ${currentPage === thisPageNumber ? listStyle.active : ""}`}
              onClick={() => {
                goSpecificPage(thisPageNumber - 1);
              }}
            >
              {thisPageNumber}
            </button>
          );
        }
      });
  };

  return (
    <div className={listStyle.paging}>
      {hasPrevPageNumbers ? (
        <button
          className={listStyle.pageBtn}
          onClick={() => {
            goSpecificPage(pageNumberQuotient * pageNumberCount - 1);
          }}
        >
          <img src={prevIcon} alt={t("pagination.lastPageIcon")} />
        </button>
      ) : (
        <></>
      )}
      {getPageNumberNodes(pageNumberStart, currentPage)}
      {hasNextPageNumbers ? (
        <button
          className={listStyle.pageBtn}
          onClick={() => {
            goSpecificPage((pageNumberQuotient + 1) * pageNumberCount);
          }}
        >
          <img src={nextIcon} alt={t("pagination.nextPageIcon")} />
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}
