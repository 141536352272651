import React, { useCallback } from "react";
import { SettlementStatus } from "../../../../types/affiliateCode";
import { useAffiliateCodeSettlementStatusUpdater } from "./useAffiliateCodeSettlementStatusUpdater";
import { useUtil } from "../../../../util/hooks/useUtil";
import { ActionButton, ActiveActionButton, DetailActionButton } from "../../../../components/Buttons";
import deleteIcon from "../../../../assets/images/button_icons/delete_icon.svg";
import confirmIcon from "../../../../assets/images/button_icons/confirm_icon.svg";

interface Args {
  year: number;
  month: number;
  resolve: () => void;
}

export function useAffiliateCodeSettlementStatusUpdateButtons({ year, month, resolve }: Args) {
  const { updateAffiliateCodeSettlementStatus } = useAffiliateCodeSettlementStatusUpdater();
  const { defaultErrorMessage } = useUtil();

  const onClickCancelSettlement = useCallback(
    (organManagerIdxes: number[]) => {
      if (organManagerIdxes.length === 0) {
        alert("보유자를 선택해주세요. ");
        return;
      }

      if (
        !window.confirm(`선택한 보유자를 ${organManagerIdxes.length > 1 ? "모두 " : ""}정산취소 처리 하시겠습니까?`)
      ) {
        return;
      }

      updateAffiliateCodeSettlementStatus({
        organManagerIdxes: organManagerIdxes,
        year,
        month,
        toStatus: SettlementStatus.INCOMPLETE,
      })
        .then(() => resolve())
        .catch(() => alert(defaultErrorMessage));
    },
    [year, month, defaultErrorMessage, resolve, updateAffiliateCodeSettlementStatus]
  );

  const onClickCompleteSettlement = useCallback(
    (organManagerIdxes: number[]) => {
      if (organManagerIdxes.length === 0) {
        alert("보유자를 선택해주세요.");
        return;
      }

      if (
        !window.confirm(`선택한 보유자를 ${organManagerIdxes.length > 1 ? "모두 " : ""}정산완료 처리 하시겠습니까?`)
      ) {
        return;
      }

      updateAffiliateCodeSettlementStatus({
        organManagerIdxes: organManagerIdxes,
        year: year,
        month: month,
        toStatus: SettlementStatus.COMPLETE,
      })
        .then(() => resolve())
        .catch(() => alert(defaultErrorMessage));
    },
    [year, month, defaultErrorMessage, resolve, updateAffiliateCodeSettlementStatus]
  );

  const getStatusUpdateButtonElement = useCallback(
    (organManagerIdx: number, status: SettlementStatus) => {
      if (status === SettlementStatus.COMPLETE) {
        return <ActionButton onClick={() => onClickCancelSettlement([organManagerIdx])}>정산취소</ActionButton>;
      } else {
        return (
          <ActiveActionButton onClick={() => onClickCompleteSettlement([organManagerIdx])}>정산완료</ActiveActionButton>
        );
      }
    },
    [onClickCancelSettlement, onClickCompleteSettlement]
  );

  const SettlementCancellationButton = useCallback(
    ({ checkedIdxes }: { checkedIdxes: number[] }) => (
      <DetailActionButton onClick={() => onClickCancelSettlement(checkedIdxes)}>
        <img src={deleteIcon} alt={"delete icon"} />
        정산취소
      </DetailActionButton>
    ),
    [onClickCancelSettlement]
  );

  const SettlementCompletionButton = useCallback(
    ({ checkedIdxes }: { checkedIdxes: number[] }) => (
      <DetailActionButton onClick={() => onClickCompleteSettlement(checkedIdxes)}>
        <img src={confirmIcon} alt={"confirm icon"} />
        정산완료
      </DetailActionButton>
    ),
    [onClickCompleteSettlement]
  );

  return {
    onClickCancelSettlement,
    onClickCompleteSettlement,
    getStatusUpdateButtonElement,
    SettlementCancellationButton,
    SettlementCompletionButton,
  };
}
