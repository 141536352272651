import {SliceState, Status} from "../types/common";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {addCases, executePromise} from "../util/sliceUtil";
import {RootState} from "./index";
import {PageResponse} from "../types/page";
import {
  OrganManagerListDTO, OrganManagerPermissionPutDTO, OrganManagerPostDTO,
  OrganManagerSearchCondition,
  OrganManagerUpdateDTO,
  OrganManagerVO,
} from "../types/organManager";
import {OrganManagerApi} from "../api/OrganManagerApi";
import {RefundAccountPutDTO} from "../features/order/detail/component/refund/hooks/useRefundAccountModifier";
import {MarketingTermsCategory} from "../types/terms";
import {OrganEmailCheckDTO, OrganPhoneCheckDTO} from "../types/organ";

export interface OrganManagerState extends SliceState {
  organManagerList?: PageResponse<OrganManagerListDTO>;
  organManagerVO?: OrganManagerVO;
}

const initialState: OrganManagerState = {
  status: Status.IDLE,
};

export const searchOrganManagersAsync = createAsyncThunk(
  "organManager/searchOrganManagers",
  (condition: OrganManagerSearchCondition) => executePromise(OrganManagerApi.searchOrganManagers(condition))
);

export const getOrganManagerAsync = createAsyncThunk("organManager/getOrganManager", (organManagerIdx: number) =>
  executePromise(OrganManagerApi.getOrganManager(organManagerIdx))
);

export const blockOrganManagersAsync = createAsyncThunk(
  "organManager/blockOrganManagers",
  ({ organManagerIdxes, blockReason }: { organManagerIdxes: number[]; blockReason: string }) =>
    executePromise(OrganManagerApi.blockOrganManagers(organManagerIdxes, blockReason))
);

export const unblockOrganManagerAsync = createAsyncThunk(
  "organManager/unblockOrganManagers",
  (organManagerIdx: number) => executePromise(OrganManagerApi.unblockOrganManager(organManagerIdx))
);

export const updateOrganManagerRefundAccountAsync = createAsyncThunk(
  "organManager/updateOrganManagerRefundAccount",
  ({ organManagerIdx, refundAccountPutDTO }: { organManagerIdx: number; refundAccountPutDTO: RefundAccountPutDTO }) =>
    executePromise(OrganManagerApi.updateOrganManagerRefundAccount(organManagerIdx, refundAccountPutDTO))
);

export const updateOrganManagerAsync = createAsyncThunk(
  "organManager/updateOrganManagerAsync",
  ({
    organManagerIdx,
    organManagerUpdateDTO,
  }: {
    organManagerIdx: number;
    organManagerUpdateDTO: OrganManagerUpdateDTO;
  }) => executePromise(OrganManagerApi.updateOrganManager(organManagerIdx, organManagerUpdateDTO))
);

export const resendSignUpEmailAsync = createAsyncThunk("organManager/resendSignUpEmail", (organManagerIdx: number) =>
  executePromise(OrganManagerApi.resendEmail(organManagerIdx))
);

export const deleteJoiningOrganManagerAsync = createAsyncThunk(
  "organManager/deleteJoiningOrganManager",
  (organManagerIdx: number) => executePromise(OrganManagerApi.deleteJoiningOrganManager(organManagerIdx))
);

export const issueTemporaryPasswordAsync = createAsyncThunk(
  "organManager/issueTemporaryPassword",
  (organManagerIdx: number) => executePromise(OrganManagerApi.issueTemporaryPassword(organManagerIdx))
);

export const withdrawMarketingTermsAsync = createAsyncThunk(
  "organManager/withdrawMarketingTerms",
  ({ organManagerIdx, termsCategory }: { organManagerIdx: number; termsCategory: MarketingTermsCategory }) =>
    executePromise(OrganManagerApi.withdrawTerms(organManagerIdx, termsCategory))
);

export const revokePhoneNumberAuthenticationAsync = createAsyncThunk(
  "organManager/revokePhoneNumberAuthentication",
  (organManagerIdx: number) => executePromise(OrganManagerApi.revokePhoneNumberAuthentication(organManagerIdx))
);

// 소속관리자 생성 시 이메일 중복 검증 API
export const checkOrganEmailAsync = createAsyncThunk(
    "organ/checkOrganEmail",
    ({ condition }: { condition: OrganEmailCheckDTO }) =>
        executePromise(OrganManagerApi.checkOrganEmail(condition))
);

// 소속관리자 생성 시 휴대폰번호 중복 검증 API
export const checkOrganPhoneNumberAsync = createAsyncThunk(
    "organ/checkOrganPhoneNumber",
    ({ condition }: { condition: OrganPhoneCheckDTO }) =>
        executePromise(OrganManagerApi.checkOrganPhoneNumber(condition))
);

// 소속관리자 생성 API
export const createOrganManagerAsync = createAsyncThunk("organ/createOrganManager", ( condition: OrganManagerPostDTO ) =>
        executePromise(OrganManagerApi.createOrganManager(condition))
);

// 소속관리자 권한 수정 API
export const updateOrganManagerPermissionAsync = createAsyncThunk("organ/updateOrganManagerPermission",
    ( { organManagerIdx, condition }:
          { organManagerIdx: number; condition: OrganManagerPermissionPutDTO } ) =>
    executePromise(OrganManagerApi.updateOrganManagerPermission(organManagerIdx,condition))
);

export const organManagerSlice = createSlice({
  name: "organManager",
  initialState,
  reducers: {
    setOrganManagerList: (state, action: PayloadAction<PageResponse<OrganManagerListDTO>>) => {
      state.organManagerList = action.payload;
    },
    setOrganManagerVO: (state, action: PayloadAction<OrganManagerVO>) => {
      state.organManagerVO = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, searchOrganManagersAsync);
    addCases(builder, getOrganManagerAsync);
    addCases(builder, blockOrganManagersAsync);
    addCases(builder, unblockOrganManagerAsync);
    addCases(builder, updateOrganManagerRefundAccountAsync);
    addCases(builder, updateOrganManagerAsync);
    addCases(builder, resendSignUpEmailAsync);
  },
});

export default organManagerSlice.reducer;

export const { setOrganManagerList, setOrganManagerVO } = organManagerSlice.actions;

export const organManagerList = (state: RootState) => state.organManager.organManagerList;
export const organManagerStatus = (state: RootState): Status => state.organManager.status;
export const organManagerVOState = (state: RootState) => state.organManager.organManagerVO;
