import { DescriptionList } from "../../../../layout/content/detail/DescriptionList";
import React from "react";
import { PaymentVO } from "../../../../types/payment";
import { Panel } from "../../../../layout/content/detail/Panel";
import style from "../orderDetail.module.scss";
import { useTranslation } from "react-i18next";
import { usePaymentType } from "../../../../types/hooks/usePaymentType";

interface Props {
  paymentVO?: PaymentVO;
}

export function CashReceiptInfo({ paymentVO }: Props) {
  const { t } = useTranslation("order");
  const { toStringFromReceiptType } = usePaymentType();
  function getDescription() {
    return [
      [
        {
          key: t("cashReceiptInfo.type"),
          value: paymentVO?.receiptType
            ? toStringFromReceiptType(paymentVO.receiptType)
            : t("cashReceiptInfo.unpublished"),
        },
        { key: t("cashReceiptInfo.receiptNumber"), value: paymentVO?.receiptNumber ? paymentVO.receiptNumber : "-" },
      ],
    ];
  }

  return (
    <Panel id="CASH_RECEIPT" title={t("cashReceiptInfo.title")} panelClassName={style.scrollMarginTop}>
      <DescriptionList data={getDescription()} labelWidth={132} />
    </Panel>
  );
}
