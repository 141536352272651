import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../../../types/page";
import { callAsync } from "../../../../../../util/sliceUtil";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooks";
import { getOrganMembersAsync, organMembers, setOrganMembers } from "../../../../../../store/organMemberSlice";
import { OrganMemberDTO, OrganMemberSearchCondition } from "../../../../../../types/organMember";
import { useUtil } from "../../../../../../util/hooks/useUtil";

interface Args {
  onCompletedCallback: () => void;
}
export function useOrganMemberGetter({ onCompletedCallback }: Args) {
  const dispatch = useAppDispatch();
  const list = useAppSelector(organMembers);
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: list?.number ?? 0,
      size: list?.size ?? 5,
      sort: ordersToSortArr(list?.orders),
    }),
    [list]
  );

  const getOrganMembers = (organIdx: number, { page, size, sort }: PageSearchCondition) => {
    const param: OrganMemberSearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      },
    };

    return () => {
      callAsync<PageResponse<OrganMemberDTO>>(
        dispatch(getOrganMembersAsync({ organIdx, condition: param })).unwrap(),
        (result) => {
          dispatch(setOrganMembers(result));
          onCompletedCallback();
        },
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  return {
    getOrganMembers,
    organMembers: list,
  };
}
