import { useProductFilterValues } from "./hooks/useProductFilterValues";
import { ProductList } from "./ProductList";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function ProductListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useProductFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <ProductList />
    </ListFilterValuesContext.Provider>
  );
}
