import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import {Post} from "../../../layout/content/post/Post";
import style from "./productItemModal.module.scss";
import {OrganManagerIdField} from "./organMemberField/OrganManagerIdField";
import {OrganManagerNameField} from "./organMemberField/OrganManagerNameField";
import {OrganManagerPasswordField} from "./organMemberField/OrganManagerPasswordField";
import {OrganManagerConfirmPasswordField} from "./organMemberField/OrganManagerConfirmPasswordField";
import {OrganManagerNationField} from "./organMemberField/OrganManagerNationField";
import {useLanguage} from "../../../hooks/hooks";
import {Language} from "../../../types/language";
import {OrganManagerCellphoneField} from "./organMemberField/OrganManagerCellphoneField";
import {OrganManagerMenuExposedField} from "./organMemberField/OrganManagerMenuExposedField";
import {AdminOrganManagerPermissionPostDTO, MenuExposedStatus} from "../../../types/organManager";
import {OrganMemberVoucherLimitation} from "./organMemberField/OrganManagerVoucherLimitationField";
import {OrganManagerKpassTestResultForm} from "./organMemberField/OrganManagerKpassTestResultForm";
import {AdditionalResultType, TestResultFormType} from "../../../types/common";
import {OrganManagerDcasTestResultForm} from "./organMemberField/OrganManagerDcasTestResultForm";
import {OrganManagerKpassAdditionalResult} from "./organMemberField/OrganManagerKpassAdditionalResult";
import {OrganManagerDcasAdditionalResult} from "./organMemberField/OrganManagerDcasAdditionalResult";
import {useOrganManagerValidator} from "../hooks/useOrganManagerValidator";
import {Label104x46} from "../../../components/Label";
import {OrganManagerCellphoneValidStatus, OrganManagerIdValidStatus} from "../../../types/organ";
import {useOrganManagerCreator} from "../hooks/useOrganManagerCreator";
import {useUtil} from "../../../util/hooks/useUtil";
import {useNavigate} from "react-router-dom";
import {VoucherPurchaseRestrictions} from "../../../types/voucher";

export function OrganManagerRegistration() {
    const {t} = useTranslation("organManager");
    const {language} = useLanguage();
    const navigate = useNavigate();

    const {createOrganManager} = useOrganManagerCreator();
    const { defaultErrorMessage } = useUtil();

    const [nation, setNation] = useState(language === Language.KO ? 'KR' : '');
    const [id, setId] = useState('');
    const [idValid, setIdValid] = useState(OrganManagerIdValidStatus.NONE);
    const [name, setName] = useState("");
    const [phoneCountryCode, setPhoneCountryCode] = useState(language === Language.KO ? '+82' : '');
    const [cellphone, setCellphone] = useState("");
    const [cellphoneValid, setCellphoneValid] = useState(OrganManagerCellphoneValidStatus.NONE);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [checkedMenuStatus, setCheckedMenuStatus] = useState([MenuExposedStatus.HOME_AND_ORGAN_MANAGEMENT
        // ,MenuExposedStatus.PURCHASE_VOUCHER, MenuExposedStatus.PARENT_DISPOSITION_TEST
    ]);
    const [voucherTypeLimitation, setVoucherTypeLimitation] = useState(VoucherPurchaseRestrictions.NONE);
    const [kpassResultForm, setKpassResultForm] = useState(TestResultFormType.FORMAL);
    const [dcasResultForm, setDcasResultForm] = useState(TestResultFormType.FORMAL);
    const [kpassAdditionalResult, setKpassAdditionalResult] = useState(AdditionalResultType.NONE);
    const [dcasAdditionalResult, setDcasAdditionalResult] = useState(AdditionalResultType.NONE);


    const {validate} = useOrganManagerValidator({
        nation, id, name, phoneCountryCode, cellphone, password, confirmPassword,
        idValid, cellphoneValid,setIdValid, setCellphoneValid,
        confirmMessage: "소속관리자를 등록하시겠습니까?"
    });
    const adminPermission: AdminOrganManagerPermissionPostDTO = {
        accessibleMenuPostDTO: {
            menuCode: checkedMenuStatus
        },
        voucherPurchaseRestrictions: voucherTypeLimitation,
        kpassResultForm: kpassResultForm,
        kpassAdditionalResultForm: kpassAdditionalResult,
        dcasResultForm: dcasResultForm,
        dcasAdditionalResultForm: dcasAdditionalResult,
    };



    const onClickCreateButton = useCallback(() => {
        if (!validate()) return;
        //
        createOrganManager({
            nationality: nation,
            email: id,
            codePhone: phoneCountryCode,
            phoneNumber: cellphone,
            name,
            password,
            adminOrganManagerPermissionPostDTO: adminPermission,

        })
            .then(() => {
                alert("소속관리자가 등록되었습니다.");
                navigate(`/member/organ-manager`);
            })
            .catch(() => alert(defaultErrorMessage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        nation,
        id,
        name,
        phoneCountryCode,
        cellphone,
        password,
        confirmPassword,
        idValid,
        cellphoneValid,
        checkedMenuStatus, voucherTypeLimitation, kpassResultForm, kpassAdditionalResult, dcasResultForm, dcasAdditionalResult
    ]);

    // @ts-ignore
    return (
        <div className={style.postWrapper}>
            <Post buttonTitle={t("registration.buttonTitle")} title={t("registration.title")}
                  buttonOnClickAction={onClickCreateButton} formClassName={style.organManagerPostForm}>
                <>
                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.nation")} isRequired={true}/>
                        <OrganManagerNationField nation={nation} setNation={(v) => setNation(v)}/>
                    </div>

                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.id")} isRequired={true}/>
                        <OrganManagerIdField id={id} setId={(v) => setId(v)}
                                             idValid={idValid}
                                             setIdValid={(v) => setIdValid(v)}/>
                    </div>

                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.cellphone")} isRequired={true}/>
                        <OrganManagerCellphoneField cellphone={cellphone}
                                                    setCellphone={(v) => setCellphone(v)}
                                                    phoneCountryCode={phoneCountryCode}
                                                    setPhoneCountryCode={(v) => setPhoneCountryCode(v)}
                                                    cellphoneValid={cellphoneValid}
                                                    setCellphoneValid={(v) => setCellphoneValid(v)}/>
                    </div>


                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.name")} isRequired={true}/>
                        <OrganManagerNameField name={name} setName={(v) => setName(v)}/>
                    </div>

                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.password")} isRequired={true}/>
                        <OrganManagerPasswordField password={password}
                                                   setPassword={(v) => setPassword(v)}/>
                    </div>

                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.passwordCheck")} isRequired={true}/>
                        <OrganManagerConfirmPasswordField confirmPassword={confirmPassword} password={password}
                                                          setConfirmPassword={(v) => setConfirmPassword(v)}/>
                    </div>

                    <span className={style.authorityText}>{t("registration.authorityTitle")}</span>


                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.menuExposed")} isRequired={false}/>
                        <OrganManagerMenuExposedField checkedMenuStatus={checkedMenuStatus}
                                                      setCheckedMenuStatus={(v) => setCheckedMenuStatus(v)}/>
                    </div>

                    <div className={style.fieldWrapper}>
                        <Label104x46 text={t("registration.productPurchaseLimit")} isRequired={false}/>
                        <OrganMemberVoucherLimitation voucherTypeLimitation={voucherTypeLimitation}
                                                      setVoucherTypeLimitation={(v) => setVoucherTypeLimitation(v)}/>
                    </div>

                    <div className={style.authorityWrapper}>
                        <div className={style.fieldWrapper}>
                            <Label104x46 text={t("registration.kpassResultType")} isRequired={false}/>
                            <OrganManagerKpassTestResultForm kpassResultForm={kpassResultForm}
                                                             setKpassResultForm={(v) => setKpassResultForm(v)}/>
                        </div>

                        <div className={style.fieldWrapper}>
                            <Label104x46 text={t("registration.kpassAdditionalResult")} isRequired={false}/>
                            <OrganManagerKpassAdditionalResult kpassAdditionalResult={kpassAdditionalResult}
                                                               setKpassAdditionalResult={(v) => setKpassAdditionalResult(v)}/>
                        </div>
                    </div>

                    <div className={style.authorityWrapper}>
                        <div className={style.fieldWrapper}>
                            <Label104x46 text={t("registration.dcasResultType")} isRequired={false}/>
                            <OrganManagerDcasTestResultForm dcasResultForm={dcasResultForm}
                                                            setDcasResultForm={(v) => setDcasResultForm(v)}/>
                        </div>

                        <div className={style.fieldWrapper}>
                            <Label104x46 text={t("registration.dcasAdditionalResult")} isRequired={false}/>
                            <OrganManagerDcasAdditionalResult dcasAdditionalResult={dcasAdditionalResult}
                                                              setDcasAdditionalResult={(v) => setDcasAdditionalResult(v)}/>
                        </div>
                    </div>
                </>
            </Post>
        </div>
    );
}