import { useCallback, useMemo, useState } from "react";
import { LinkStatus, PartnerCompanyStatus, PartnerCompanyType } from "../../../../types/partnerCompany";
import { PageSearchCondition } from "../../../../types/page";
import { ListQueryType } from "../../../../types/list";
import { FilterType, FilterValue } from "../../../../types/filter";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface PartnerCompanyListQueryType extends ListQueryType {
  searchWord?: string;
  type?: string;
  status?: string;
  linkStatus?: string;
}

export interface PartnerCompanyFilterValues {
  searchWordFilterValue: FilterValue<string>;
  typeFilterValue: FilterValue<PartnerCompanyType | "">;
  statusFilterValue: FilterValue<PartnerCompanyStatus | "">;
  linkStatusFilterValue: FilterValue<LinkStatus | "">;
}

export function usePartnerCompanyFilterValues() {
  const [searchWord, setSearchWord] = useState("");
  const [type, setType] = useState<PartnerCompanyType | "">("");
  const [status, setStatus] = useState<PartnerCompanyStatus | "">("");
  const [linkStatus, setLinkStatus] = useState<LinkStatus | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<PartnerCompanyListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      {
        key: "searchWord",
        value: searchWord,
        setter: setSearchWord,
        defaultValue: "",
        type: FilterType.STRING,
      },
      {
        key: "type",
        value: type,
        setter: setType,
        defaultValue: "",
        type: FilterType.OTHER,
      },
      { key: "status", value: status, setter: setStatus, defaultValue: "", type: FilterType.OTHER },
      {
        key: "linkStatus",
        value: linkStatus,
        setter: setLinkStatus,
        defaultValue: "",
        type: FilterType.OTHER,
      },
    ],
    [searchWord, type, status, linkStatus]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType: listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        typeFilterValue: getFilterValueByKey("type") as FilterValue<PartnerCompanyType | "">,
        statusFilterValue: getFilterValueByKey("status") as FilterValue<PartnerCompanyStatus | "">,
        linkStatusFilterValue: getFilterValueByKey("linkStatus") as FilterValue<LinkStatus | "">,
      } as PartnerCompanyFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: PartnerCompanyListQueryType) => {
      const param: PartnerCompanyListQueryType = {
        searchWord: listQueryTypeParam?.searchWord,
        type: listQueryTypeParam?.type,
        status: listQueryTypeParam?.status,
        linkStatus: listQueryTypeParam?.linkStatus,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
