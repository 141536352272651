import {AddedFile, FileType} from "./file";
import {Languages, ListObject, WebsiteType} from "./common";
import {SearchCondition} from "./page";

export enum PopupStatus {
  UNEXPOSED = "UNEXPOSED",
  EXPOSED = "EXPOSED",
}

export enum LinkOption {
  NEW_TAB = "NEW_TAB",
  CURRENT_TAB = "CURRENT_TAB",
}

export enum PopupType {
  IMAGE = "IMAGE",
}

export interface PopupPostParam {
  name: string;
  availableStartAt: string;
  availableEndAt?: string;
  type: PopupType;
  image: AddedFile;
  imageLink?: string;
  linkOption: LinkOption;
  status: PopupStatus;
  websiteType: WebsiteType;
  language: Languages;
}

export interface PopupPostVO {
  name: string;
  availableStartAt: string;
  availableEndAt?: string;
  type: PopupType;
  image: FileType;
  imageLink?: string;
  linkOption: LinkOption;
  status: PopupStatus;
  websiteType: WebsiteType;
  language: Languages;
}

export interface PopupDTO extends ListObject {
  name: string;
  createdAt: string;
  availableStartAt: string;
  availableEndAt: string;
  type: PopupType;
  image: AddedFile;
  imageLink: string;
  linkOption: LinkOption;
  status: PopupStatus;
  websiteType: WebsiteType;
  language: Languages;
}

export interface PopupListVO extends ListObject {
  name: string;
  createdAt: string;
  availableStartAt: string;
  availableEndAt: string;
  type: PopupType;
  linkOption: LinkOption;
  imageLink: string;
  status: PopupStatus;
  websiteType: WebsiteType;
  language: Languages;
}

export interface PopupSearchCondition extends SearchCondition {
  q?: string;
  availableStartAt?: Date;
  availableEndAt?: Date;
  type?: PopupType;
  status?: PopupStatus;
  websiteType?: WebsiteType;
  language?: Languages;
}

export interface PopupDetailVO {
  idx: number;
  name: string;
  createdAt: string;
  availableStartAt: string;
  availableEndAt: string;
  type: PopupType;
  image: AddedFile;
  imageLink: string;
  linkOption: LinkOption;
  status: PopupStatus;
  websiteType: WebsiteType
  language: Languages;
}

export interface PopupStatusUpdateParam {
  idxes: number[];
  status: PopupStatus;
}

export interface PopupPutDTO {
  name: string;
  availableStartAt: string;
  availableEndAt?: string;
  type: PopupType;
  image: AddedFile;
  imageLink?: string;
  linkOption: LinkOption;
  status: PopupStatus;
  websiteType: WebsiteType;
  language: Languages;
}

export interface PopupPutVO {
  name: string;
  availableStartAt: string;
  availableEndAt?: string;
  type: PopupType;
  image: AddedFile;
  addedImage?: FileType;
  imageLink?: string;
  linkOption: LinkOption;
  status: PopupStatus;
  websiteType: WebsiteType;
  popupLanguage: Languages;
}
