import style from "./miniModal.module.scss";
import React, { useEffect, useRef } from "react";
import { useClickEvent } from "../../hooks/hooks";
import { CLICK_DOCUMENT_EVENT } from "../../util/events";
import { Status } from "../../types/common";
import loader from "../../assets/images/loader.gif";
import styled from "styled-components";

interface Props {
  show: boolean;
  closeModal: (_: React.MouseEvent) => void;
  title: string;
  children: JSX.Element;
  modalWidth: string;
  modalLeftPosition?: string;
  modalRightPosition?: string;
  statusValues?: Status[];
  linkElement?: HTMLElement;
}

const LoaderWrapperStyle = styled.div`
  width: 100%;
  text-align: center;
  padding: 30px 0;
`;

const LoadingImageStyle = styled.img`
  width: 20px;
  height: 20px;
`;

export function MiniModal({
  show,
  closeModal,
  title,
  children,
  modalWidth,
  modalLeftPosition,
  modalRightPosition,
  statusValues,
  linkElement,
}: Props) {
  const modalRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);
  const { subscribe, unsubscribe } = useClickEvent();

  useEffect(() => {
    const clickDocumentListener = (e: { detail: any }) => {
      if (show) {
        if (e.detail.closest(`div[data-name='miniModalWrapper']`) === modalRef.current) {
          return;
        }

        if (linkElement && e.detail.closest(`[data-name='miniModalLink']`) === linkElement) {
          return;
        }

        closeButtonRef.current?.click();
      }
    };

    subscribe(CLICK_DOCUMENT_EVENT, clickDocumentListener);

    return () => {
      unsubscribe(CLICK_DOCUMENT_EVENT, clickDocumentListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, linkElement, modalRef, closeButtonRef, subscribe, unsubscribe]);

  return (
    <div
      data-name="miniModalWrapper"
      className={`${style.miniModal} ${show ? style.show : ""}`}
      style={{ width: modalWidth, left: modalLeftPosition ?? "auto", right: modalRightPosition ?? "auto" }}
      ref={modalRef}
    >
      <div className={style.title}>
        {title}
        <button
          className={style.closeBtn}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            closeModal(e);
          }}
          ref={closeButtonRef}
        />
      </div>
      <div className={style.content}>
        {statusValues && statusValues.some((status) => status === Status.LOADING) ? (
          <LoaderWrapperStyle>
            <LoadingImageStyle src={loader} alt="loading icon" />
          </LoaderWrapperStyle>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
}
