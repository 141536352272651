import {ordersToSortArr, PageResponse, PageSearchCondition} from "../../../types/page";
import {
  useAppDispatch,
  useAppSelector,
  useListFilterValues,
  useNationality,
  useTimeConverter,
} from "../../../hooks/hooks";
import {getDcasTestListAsync, getKpassTestListAsync, setTestList, testList} from "../../../store/testSlice";
import {useEffect, useMemo, useState} from "react";
import {TestListDTO, TestQueryType, TestSearchCondition} from "../../../types/test";
import {hasBothValuesOrBothNoValue, isFromNumberMoreThanToNumber} from "../../../util/etcUtil";
import {TestListQueryType} from "./useTestFilterValues";
import {useTranslation} from "react-i18next";
import {useUtil} from "../../../util/hooks/useUtil";
import {Gender} from "../../../types/common";
import {useSortParam} from "../../../hooks/list/useSortParam";
import {dateToString} from "../../../util/dateUtil";
import {VoucherType} from "../../../types/voucher";

interface Args {
  onCompletedCallback?: () => void;
  testType: VoucherType;
}

export function useTestList({ onCompletedCallback, testType }: Args) {
  const { t } = useTranslation("test");
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const {
    searchWordFilterValue,
    searchWordTypeFilterValue,
    startDateFilterValue,
    endDateFilterValue,
    minTestCntFilterValue,
    maxTestCntFilterValue,
    minAgeFilterValue,
    maxAgeFilterValue,
    genderFilterValue,
  } = filterValues;
  const { defaultErrorMessage } = useUtil();
  const { toSortArray } = useSortParam();
  const { nationality } = useNationality();
  const { timeConverter } = useTimeConverter();

  const dispatch = useAppDispatch();
  const list = useAppSelector(testList);

  const [totalTestCnt, setTotalTestCnt] = useState<number>(0);
  const pageCondition = useMemo(
    () => ({
      page: list?.number ?? 0,
      size: list?.size ?? 20,
      sort: ordersToSortArr(list?.orders),
    }),
    [list]
  );

  const searchTestList = (pageSearchCondition: PageSearchCondition) => {
    const param: TestListQueryType = {
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      searchWordType: searchWordTypeFilterValue.value,
      startDate: startDateFilterValue.value ? dateToString(startDateFilterValue.value) : undefined,
      endDate: endDateFilterValue.value ? dateToString(endDateFilterValue.value) : undefined,
      minTestCnt: minTestCntFilterValue.value ? minTestCntFilterValue.value : undefined,
      maxTestCnt: maxTestCntFilterValue.value ? maxTestCntFilterValue.value : undefined,
      minAge: minAgeFilterValue.value ? minAgeFilterValue.value : undefined,
      maxAge: maxAgeFilterValue.value ? maxAgeFilterValue.value : undefined,
      gender: genderFilterValue.value ? genderFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.startDate, param.endDate)) {
        alert(t("list.validations.bothEndedAtNeeded"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(Number(param.minTestCnt), Number(param.maxTestCnt))) {
        alert(t("list.validations.bothTestCountNeeded"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.minTestCnt), Number(param.maxTestCnt))) {
        alert(t("list.validations.incorrectTestCounts"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(Number(param.minAge), Number(param.maxAge))) {
        alert(t("list.validations.bothAgeNeeded"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.minAge), Number(param.maxAge))) {
        alert(t("list.validations.incorrectAges"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getTestList = async (param: TestSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<TestListDTO>  = testType === VoucherType.KPASS
          ? await dispatch(getKpassTestListAsync(param)).unwrap()
          : await dispatch(getDcasTestListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalTestCnt(result.totalElements);
      } else {
        dispatch(setTestList(result));
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  useEffect(() => {
    getTestList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      const testListQueryType = listQueryType as TestListQueryType;
      const param: TestSearchCondition = {
        pageCondition: {
          page: testListQueryType.page ? Number(testListQueryType.page) : 0,
          size: testListQueryType.size ? Number(testListQueryType.size) : 20,
          sort: testListQueryType.sort ? toSortArray(testListQueryType.sort) : undefined,
        },
        qtype: testListQueryType.searchWordType ? (testListQueryType.searchWordType as TestQueryType) : undefined,
        q: testListQueryType.searchWord?.trim(),
        startDate: testListQueryType.startDate
          ? timeConverter.convertToFilterStartOfDay(testListQueryType.startDate)
          : undefined,
        endDate: testListQueryType.endDate
          ? timeConverter.convertToFilterEndOfDay(testListQueryType.endDate)
          : undefined,
        minTestCnt: testListQueryType.minTestCnt ? Number(testListQueryType.minTestCnt) : undefined,
        maxTestCnt: testListQueryType.maxTestCnt ? Number(testListQueryType.maxTestCnt) : undefined,
        minAge: testListQueryType.minAge ? Number(testListQueryType.minAge) : undefined,
        maxAge: testListQueryType.maxAge ? Number(testListQueryType.maxAge) : undefined,
        gender: testListQueryType.gender ? (testListQueryType.gender as Gender) : undefined,
      };

      getTestList(param).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchTestList,
    totalTestCnt,
    testList: list,
    pageCondition,
  };
}
