import { useCallback } from "react";
import { grantOrganManagerVoucherAsync } from "../../../../../../store/voucherSlice";
import { GrantOrganManagerVoucherRequestParam } from "../../../../../../types/voucher";
import { useAppDispatch } from "../../../../../../hooks/hooks";
import { callAsync } from "../../../../../../util/sliceUtil";
import { useUtil } from "../../../../../../util/hooks/useUtil";

export function useGrantOrganManagerVoucher() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return useCallback(
    (param: GrantOrganManagerVoucherRequestParam): Promise<void> => {
      return callAsync<void>(
        dispatch(grantOrganManagerVoucherAsync(param)).unwrap(),
        () => {},
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );
}
