import { AddedFile, FileType } from "./file";
import { Address } from "./user";
import { ListObject } from "./common";
import { AffiliateCodeDTO, AffiliateCodeStatus, CodeDiscountType } from "./affiliateCode";
import { SearchCondition } from "./page";

export enum PartnerCompanyType {
  DOMESTIC = "DOMESTIC",
  OVERSEAS = "OVERSEAS",
}

export enum PartnerCompanyStatus {
  UNEXPOSED = "UNEXPOSED",
  EXPOSED = "EXPOSED",
}

export interface PartnerCompanyPostDTO {
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: AddedFile;
  address?: Address;
  webSite?: string;
  affiliateCodeIdx?: number;
}

export interface PartnerCompanyPostVO {
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: FileType;
  address: Address;
  webSite?: string;
  affiliateCodeIdx?: number;
}

export interface PartnerCompanyListDTO extends ListObject {
  createdAt: string;
  updatedAt: string;
  numericOrder: number | null;
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: AddedFile;
  address: Address;
  webSite: string;
  affiliateCodeDTO: AffiliateCodeDTO | null;
}

export interface PartnerCompanyListVO extends ListObject {
  numericOrder?: number;
  createdAt: string;
  name: string;
  type: PartnerCompanyType;
  affiliateCode?: string;
  discountType?: CodeDiscountType;
  discountValue?: number;
  codeAvailableStartAt?: string;
  codeAvailableEndAt?: string;
  codeStatus?: AffiliateCodeStatus;
  status: PartnerCompanyStatus;
}

export interface PartnerCompanySearchCondition extends SearchCondition {
  q?: string;
  type?: PartnerCompanyType;
  status?: PartnerCompanyStatus;
  linkStatus?: LinkStatus;
}

export interface PartnerCompanyDTO {
  idx: number;
  createdAt: string;
  updatedAt: string;
  numericOrder: number;
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: AddedFile;
  address: Address;
  webSite: string;
  affiliateCodeDTO: AffiliateCodeDTO;
}

export interface PartnerCompanyVO {
  idx: number;
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: AddedFile;
  address?: Address;
  webSite?: string;
  affiliateCode?: AffiliateCodeDTO;
}

export interface PartnerCompanyPutDTO {
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  logo: AddedFile;
  address?: Address;
  webSite?: string;
  affiliateCodeIdx?: number;
}

export interface PartnerCompanyPutVO {
  name: string;
  type: PartnerCompanyType;
  status: PartnerCompanyStatus;
  defaultLogo: AddedFile;
  addedLogo?: FileType;
  address: Address;
  webSite?: string;
  affiliateCodeIdx?: number;
}

export enum LinkStatus {
  LINK = "LINK",
  UNLINK = "UNLINK",
  DELETED = "DELETED",
}

export interface PartnerCompanyStatusUpdateDTO {
  idx: number[];
  status: PartnerCompanyStatus;
}

export interface PartnerCompanyOrderingNumberUpdateDTO {
  idx: number;
  numericOrder: number;
}

export interface PartnerCompanyOrderingNumberUpdateVO {
  idx: number;
  orderingNumber?: number;
}
