import React from "react";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";
import {TestResultFormType} from "../../../../types/common";
import {RadioGroupField} from "../../../../components/RadioGroupField";

interface Props {
    kpassResultForm: TestResultFormType;
    setKpassResultForm: (_: TestResultFormType) => void;
}

export function OrganManagerKpassTestResultForm({kpassResultForm, setKpassResultForm}: Props) {
    const {toStringTestResultForm} = useOrganManagerType();

    const getLabel = (value: string) => {
        return toStringTestResultForm(value as TestResultFormType);
    };

    const testResultFormValues = [
        TestResultFormType.FORMAL,
        TestResultFormType.SUMMARY
    ];


    return (
        <RadioGroupField enumValues={testResultFormValues}
                         getLabel={getLabel}
                         value={kpassResultForm} setValue={setKpassResultForm}
                         groupName={"KpassTestResultForm"} isRequired={true}
        />
    );
}