import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { DetailSubMenus, DetailTab } from "../../../layout/content/detail/DetailSubMenus";
import { MemberUserDetailTabType } from "../../../types/user";
import { useState } from "react";

export function ArticleContentDetailSubmenus({ id, title }: ArticleProps) {
  const [selectedTab, setSelectedTab] = useState<MemberUserDetailTabType>(MemberUserDetailTabType.COUPON);

  const tabMenus: DetailTab<MemberUserDetailTabType>[] = [
    {
      tabValue: MemberUserDetailTabType.SELF_INFO,
      tabName: "회원정보",
    },
    {
      tabValue: MemberUserDetailTabType.PAYMENT_HISTORY,
      tabName: "결제내역",
    },
    {
      tabValue: MemberUserDetailTabType.COUPON,
      tabName: "쿠폰/이용권",
    },
    {
      tabValue: MemberUserDetailTabType.TESTERS,
      tabName: "피검사자",
    },
  ];

  const code = `
const [selectedTab, setSelectedTab] = useState<MemberUserDetailTabType>(MemberUserDetailTabType.COUPON);

const tabMenus: DetailTab<MemberUserDetailTabType>[] = ${JSON.stringify(tabMenus, null, 2)};

<DetailSubMenus tabs={tabMenus}
                onClickTab={setSelectedTab}
                selectedTab={selectedTab}/>  
  `;

  const description = `
- DetailTab 타입
{
  tabValue: T;
  tabName: string;
  menuCode?: AdminMenuCode; -- 이 코드 추가 시, 접근 권한이 있는 유저에게만 메뉴가 보임
}  
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code} description={description}>
          <DetailSubMenus tabs={tabMenus} onClickTab={setSelectedTab} selectedTab={selectedTab} />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
