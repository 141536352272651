import { useState } from "react";
import { OrderSearchDateType, OrderSearchWordType, OrderStatus } from "../../../../../types/orders";
import { PaymentWay } from "../../../../../types/payment";
import { DisplayPosition } from "../../../../../types/product";

export interface MemberOrderFilterValues {
  query: string;
  setQuery: (_: string) => void;
  queryType: OrderSearchWordType;
  orderSearchDateType: OrderSearchDateType;
  setOrderSearchDateType: (_: OrderSearchDateType) => void;
  startDate: Date | undefined;
  setStartDate: (_: Date | undefined) => void;
  endDate: Date | undefined;
  setEndDate: (_: Date | undefined) => void;
  paymentWay: PaymentWay | "";
  setPaymentWay: (_: PaymentWay | "") => void;
  status: OrderStatus | "";
  setStatus: (_: OrderStatus | "") => void;
  displayPosition: DisplayPosition | "";
  setDisplayPosition: (_: DisplayPosition | "") => void;
}

export function useMemberOrderFilterValues(): MemberOrderFilterValues {
  const [query, setQuery] = useState("");
  const [queryType] = useState<OrderSearchWordType>(OrderSearchWordType.AFFILIATE_CODE);
  const [orderSearchDateType, setOrderSearchDateType] = useState<OrderSearchDateType>(OrderSearchDateType.ORDERED_AT);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [paymentWay, setPaymentWay] = useState<PaymentWay | "">("");
  const [status, setStatus] = useState<OrderStatus | "">("");
  const [displayPosition, setDisplayPosition] = useState<DisplayPosition | "">("");

  return {
    query,
    setQuery,
    queryType,
    orderSearchDateType,
    setOrderSearchDateType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    paymentWay,
    setPaymentWay,
    status,
    setStatus,
    displayPosition,
    setDisplayPosition,
  };
}
