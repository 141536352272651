import React from "react";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";
import {TestResultFormType} from "../../../../types/common";
import {RadioGroupField} from "../../../../components/RadioGroupField";

interface Props {
    dcasResultForm: TestResultFormType;
    setDcasResultForm: (_: TestResultFormType) => void;
}

export function OrganManagerDcasTestResultForm({dcasResultForm, setDcasResultForm}: Props) {
    const {toStringTestResultForm} = useOrganManagerType();

    const getLabel = (value: string) => {
        return toStringTestResultForm(value as TestResultFormType);
    };

    const testResultFormValues = [TestResultFormType.FORMAL];

    return (
        <RadioGroupField enumValues={testResultFormValues}
                         getLabel={getLabel}
                         value={dcasResultForm} setValue={setDcasResultForm}
                         groupName={"DcasTestResultForm"} isRequired={true}
        />
    );
}