import { TableColumnData } from "../../../../types/common";
import { TesterListVO } from "../../../../types/userProfile";
import React, { useCallback } from "react";
import { Anchor } from "../../../../components/Anchor";
import { CopiableText } from "../../../../components/CopiableText";
import { UserStatusText } from "../../user/UserStatusText";
import { TesterOrganVoucherText } from "../TesterOrganVoucherText";
import { TestersOrganCount } from "../TestersOrganCount";
import { useCountry, useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { UserStatus } from "../../../../types/user";
import { WindowMessage } from "../../../../types/newWindow";
import { useTranslation } from "react-i18next";

interface Args {
  onRefreshCallback: () => void;
}
interface UseTesterColumnsReturnType {
  columns: TableColumnData<TesterListVO>[];
}

export function useTesterColumns({ onRefreshCallback }: Args): UseTesterColumnsReturnType {
  const { t } = useTranslation("tester");
  const { getCountryNameByCodeISOAlpha2 } = useCountry();
  const { timeConverter } = useTimeConverter();
  const { openCreatedProfileDetailPopup, openNewWindowWithURL } = useNewWindow();

  const onClickName = useCallback(
    (userIdx: number | undefined, profileIdx: number, callback?: () => void) => {
      if (userIdx) {
        openNewWindowWithURL(
          `/detail/member/user/${userIdx}?type=TESTERS&profileIdx=${profileIdx}`,
          {
            width: 1000,
            height: 800,
          },
          {
            windowMessage: WindowMessage.USER,
            onRefresh: onRefreshCallback,
          }
        );
      } else {
        openCreatedProfileDetailPopup(profileIdx, () => {
          callback?.();
        });
      }
    },
    [openNewWindowWithURL, openCreatedProfileDetailPopup, onRefreshCallback]
  );

  const isLeavedUser = useCallback((userStatus: UserStatus) => userStatus === UserStatus.LEAVE, []);
  const getComponentOrDashOnLeavedUser = useCallback(
    (parentStatus: UserStatus | null, notLeavedUserComponent: JSX.Element, leavedUserComponent?: JSX.Element) =>
      parentStatus && isLeavedUser(parentStatus) ? leavedUserComponent ?? <>-</> : notLeavedUserComponent,
    [isLeavedUser]
  );

  const columns: TableColumnData<TesterListVO>[] = [
    {
      accessor: "createAt",
      header: t("list.createAt"),
      getNode: (t: TesterListVO) => <span>{timeConverter.convertToLocalTime(t.createAt)}</span>,
      sortable: true,
    },
    {
      accessor: "name",
      header: t("list.name"),
      getNode: (it: TesterListVO) =>
        isLeavedUser(it.parentStatus) ? (
          t("list.leavedMember")
        ) : (
          <Anchor
            onClick={(e) => {
              e.preventDefault();
              onClickName(it.userIdx, it.idx, onRefreshCallback);
            }}
          >
            {it.name}
          </Anchor>
        ),
      sortable: false,
      width: 104,
    },
    {
      accessor: "userCode",
      header: t("list.userCode"),
      getNode: (it: TesterListVO) =>
        getComponentOrDashOnLeavedUser(
          it.parentStatus,
          <>
            {it.isCreatedMember ? <>{t("list.createdMember")}</> : <CopiableText text={it.userCode} isBlue={false} />}
          </>
        ),
      sortable: false,
      width: 116,
    },
    {
      accessor: "phoneNumber",
      header: t("list.phoneNumber"),
      getNode: (t: TesterListVO) =>
        getComponentOrDashOnLeavedUser(
          t.parentStatus,
          <>{t.phoneNumber ? `(${t.codePhone}) ${t.phoneNumber}` : `-`}</>
        ),
      sortable: false,
    },
    {
      accessor: "parentStatus",
      header: t("list.status"),
      getNode: (t: TesterListVO) =>
        t.isCreatedMember ? (
          `-`
        ) : (
          <UserStatusText
            userStatus={t.parentStatus}
            leavedAt={t.leaveAt}
            blockedAt={t.blockAt}
            reasonForBlock={t.reasonForBlocking}
            reasonForLeave={t.reasonForLeave}
          />
        ),
      sortable: false,
    },
    {
      accessor: "age",
      header: t("list.age"),
      getNode: (t: TesterListVO) =>
        getComponentOrDashOnLeavedUser(t.parentStatus, <>{t.age === 0 || t.age ? `${t.age}` : `-`}</>),
      sortable: true,
    },
    {
      accessor: "nationality",
      header: t("list.nationality"),
      getNode: (t: TesterListVO) =>
        getComponentOrDashOnLeavedUser(
          t.parentStatus,
          <>{t.nationality ? getCountryNameByCodeISOAlpha2(t.nationality) ?? "-" : `-`}</>
        ),
      sortable: false,
    },
    {
      accessor: "region",
      header: t("list.region"),
      getNode: (t: TesterListVO) => getComponentOrDashOnLeavedUser(t.parentStatus, <>{t.region ?? `-`}</>),
      sortable: true,
    },
    {
      accessor: "testCount",
      header: t("list.testCount.label"),
      getNode: (it: TesterListVO) =>
        getComponentOrDashOnLeavedUser(it.parentStatus, <>{t("list.testCount.unit", { count: it.testCount })}</>),
      sortable: true,
    },
    {
      accessor: "organCount",
      header: t("list.organ"),
      getNode: (t: TesterListVO) =>
        getComponentOrDashOnLeavedUser(
          t.parentStatus,
          <TestersOrganCount organCount={t.organCount} profileIdx={t.idx} />
        ),
      sortable: false,
    },
    {
      accessor: "productVoucherIdx",
      header: t("list.organVoucher"),
      getNode: (t: TesterListVO) =>
        getComponentOrDashOnLeavedUser(
          t.parentStatus,
          <>
            {t.productVoucherIdx ? (
              <TesterOrganVoucherText productVoucherIdx={t.productVoucherIdx} organSignInCode={t.organSignInCode} />
            ) : (
              `-`
            )}
          </>
        ),
      sortable: false,
    },
  ];

  return { columns };
}
