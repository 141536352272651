import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./assets/scss/reset.scss";
import "./assets/scss/app.scss";
import { TermsModal } from "./features/terms/TermsModal";
import { ModalContext } from "./hooks/ModalContext";
import { ModalType, Status } from "./types/common";
import { TermsCategory } from "./types/terms";
import { Loader } from "./layout/loader/Loader";
import { CommonContext } from "./hooks/common/CommonContext";
import { ThemeProvider } from "styled-components";
import { theme } from "./theme/theme";
import { CountryProvider } from "./hooks/country/CountryProvider";
import { SelectorClickEventProvider } from "./hooks/common/SelectorClickEventProvider";
import { useAuth, useNewWindow } from "./hooks/hooks";
import { WindowMessage } from "./types/newWindow";
import { TimeConverterProvider } from "./hooks/timeconverter/TimeConverterProvider";
import { LanguageProvider } from "./hooks/language/LanguageProvider";
import { MyInfoProvider } from "./hooks/myinfo/MyInfoProvider";
import { NationalityProvider } from "./hooks/nationality/NationalityProvider";

function App() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(ModalType.NONE);
  const [loaderStatus, setLoaderStatus] = useState(Status.IDLE);
  const [termsCategory, setTermsCategory] = useState<TermsCategory>(TermsCategory.NONE);
  const { isLoggedIn, signOut } = useAuth();
  const { signOutOnChildren } = useNewWindow();

  useEffect(() => {
    if (isLoggedIn) {
      const onEventCallback = (event: MessageEvent) => {
        const { message } = event.data;
        if (message === WindowMessage.ADMIN_LOGOUT.toString()) {
          signOut(() => {
            signOutOnChildren();
            navigate("/login");
          });
        }
      };
      window.addEventListener("message", onEventCallback, false);
      return () => {
        window.removeEventListener("message", onEventCallback);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, signOut, signOutOnChildren]);

  const closeModal = () => setShowModal(ModalType.NONE);
  const openTermsModal = (category: TermsCategory) => {
    setTermsCategory(category);
    setShowModal(ModalType.TERMS);
  };

  const commonContextObj = {
    setLoaderStatus,
  };

  const modalContextObj = {
    showModal,
    setShowModal,
    openTermsModal,
    closeModal,
  };

  const outletContextObj = {
    ...modalContextObj,
    ...commonContextObj,
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <MyInfoProvider>
          <SelectorClickEventProvider>
            <LanguageProvider>
              <CountryProvider>
                <NationalityProvider>
                  <TimeConverterProvider>
                    <CommonContext.Provider value={commonContextObj}>
                      <ModalContext.Provider value={modalContextObj}>
                        <Loader status={loaderStatus} />
                        <TermsModal termsCategory={termsCategory} />
                        <Outlet context={outletContextObj} />
                      </ModalContext.Provider>
                    </CommonContext.Provider>
                  </TimeConverterProvider>
                </NationalityProvider>
              </CountryProvider>
            </LanguageProvider>
          </SelectorClickEventProvider>
        </MyInfoProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
