import React, { Fragment, useMemo, useState } from "react";
import style from "./contentDetail.module.scss";
import { AdminMenuCode } from "../../../types/adminRole";
import { useRoleChecker } from "../../../features/role/hooks/useRoleChecker";
import { useDescriptionList } from "./hooks/useDescriptionList";
import { useLanguage } from "../../../hooks/hooks";

export interface DescriptionValue {
  key: string | JSX.Element;
  value?: string | JSX.Element;
  editModeValue?: JSX.Element;
  editModeDisabledValue?: JSX.Element;
  menuCode?: AdminMenuCode;
  alternativeElement?: JSX.Element;
  keyStyleObject?: React.CSSProperties;
}

interface Props {
  data: DescriptionValue[][];
  labelWidth: number | number[];
  valueWidth?: number;
  labelClassName?: string;
  valueClassName?: string;
  listClassName?: string;
  isEditMode?: boolean;
}

export function DescriptionList({
  data,
  labelWidth,
  valueWidth,
  labelClassName,
  valueClassName,
  listClassName,
  isEditMode,
}: Props) {
  const [colNum, setColNum] = useState(0);
  const gridLastColNum = useMemo(() => colNum * 2 + 1, [colNum]);
  const { getRoleCheckedElement } = useRoleChecker();
  const { fillWithAlternativeChildren } = useDescriptionList();
  const { isKorean } = useLanguage();

  const dlStyleObj: React.CSSProperties = useMemo(() => {
    const rowNum = data.length;
    const colNum = Math.max(...data.map((innerArr) => innerArr.length));

    setColNum(colNum);

    return {
      width: valueWidth ? "fit-content" : "100%",
      gridTemplateColumns: Array(colNum)
        .fill(null)
        .map(
          (_, index) =>
            `${(Array.isArray(labelWidth) ? labelWidth[index] : labelWidth) - (index === 0 ? 2 : 1)}px ${
              valueWidth ? `${valueWidth - (index === colNum - 1 ? 2 : 1)}px` : "auto"
            }`
        )
        .join(" "),
      gridTemplateRows: `repeat(${rowNum}, auto)`,
    };
  }, [data, labelWidth, valueWidth]);

  const getGridColumnValue = (key: string | JSX.Element, colIdx: number, colLength: number) => {
    const colStartNum = (colIdx + 1) * 2;

    if (colIdx + 1 === colLength && colLength < colNum) {
      return `${colStartNum}/${gridLastColNum}`;
    }

    if (key.toString().length === 0) {
      return `${colStartNum - 1}/${colStartNum + 1}`;
    }

    return `${colStartNum}/${colStartNum + 1}`;
  };

  const isNotEditableValue = (value: DescriptionValue) => isEditMode && !value.editModeValue;

  return (
    <dl className={`${style.descriptionList} ${listClassName ?? ""}`} style={dlStyleObj}>
      {data.map((row, rowIdx) => (
        <Fragment key={`row-${rowIdx}-${row[0]?.key}`}>
          {row.map((desc, colIdx, colArr) => {
            const key = `row-${rowIdx}-col-${colIdx}-${desc.key}`;
            const element = (
              <>
                {desc.key.toString().length > 0 && (
                  <dt
                    className={`${style.item} ${style.dt} ${isKorean ? "" : style.notKO} ${
                      labelClassName ? labelClassName : ""
                    }`}
                    style={{
                      gridColumn: `${(colIdx + 1) * 2 - 1} / ${(colIdx + 1) * 2}`,
                      ...desc.keyStyleObject,
                    }}
                  >
                    {desc.key}
                  </dt>
                )}
                <dd
                  className={`${style.item} ${style.dd} ${valueClassName ?? ""} 
                  ${isNotEditableValue(desc) ? style.disabled : ""}
                  ${isEditMode && !isNotEditableValue(desc) ? style.noPadding : ""}
                  `}
                  style={{
                    gridColumn: getGridColumnValue(desc.key, colIdx, colArr.length),
                  }}
                >
                  {isEditMode ? desc.editModeValue ?? desc.editModeDisabledValue ?? desc.value ?? "" : desc.value ?? ""}
                </dd>
              </>
            );

            return getRoleCheckedElement(
              key,
              element,
              desc.menuCode,
              fillWithAlternativeChildren((colIdx + 1) * 2 - 1, desc.alternativeElement)
            );
          })}
        </Fragment>
      ))}
    </dl>
  );
}
