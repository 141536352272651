import { useCallback, useMemo, useState } from "react";

export function useTimer(totalMilliseconds: number) {
  const [time, setTime] = useState(0);
  const [resetTimerFunc, setResetTimerFunc] = useState<VoidFunction>(() => () => {});

  const isTimerStarted = useMemo(() => Boolean(time > 0), [time]);

  const startTimer = useCallback(() => {
    let totalTime = totalMilliseconds;
    resetTimerFunc();
    const timer = setInterval(() => {
      if (totalTime > 0) {
        totalTime = totalTime - 1000;
        setTime(totalTime);
      }
    }, 1000);

    setResetTimerFunc(() => () => {
      clearInterval(timer);
    });

    return () => clearInterval(timer);
  }, [resetTimerFunc, totalMilliseconds]);

  const getTwoDigitsText = useCallback((num: number) => String(num).padStart(2, "0"), []);

  const timeFormattedText = useMemo(() => {
    const totalSeconds = Math.floor(time / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const seconds = totalSeconds % 60;

    let hoursText = "";
    if (hours > 0) {
      hoursText = getTwoDigitsText(hours) + ":";
    }

    return `${hoursText}${getTwoDigitsText(minutes)}:${getTwoDigitsText(seconds)}`;
  }, [time, getTwoDigitsText]);

  return {
    startTimer,
    timeFormattedText,
    isTimerStarted,
    time,
  };
}
