import { AddedFile, FileType } from "../../../../types/file";
import { useCallback } from "react";
import { useValidator } from "../../../../hooks/useValidator";

interface Args {
  name: string;
  image: AddedFile | undefined;
  addedImage: FileType | undefined;
  availableEndAt: string;
  isAvailableAlways: boolean;
  confirmMessage: string;
}

const POPUP_NAME_MAX_LENGTH = 50;

export function usePopupValidator({
  name,
  image,
  addedImage,
  availableEndAt,
  isAvailableAlways,
  confirmMessage,
}: Args) {
  const { getTotalLength } = useValidator();

  const validate = useCallback(() => {
    if (getTotalLength(name) > POPUP_NAME_MAX_LENGTH) {
      alert("팝업명이 너무 깁니다.\n※ 띄어쓰기 특수문자 포함, 최대 영문 50자, 한글 25자");
      return false;
    }

    if (!Boolean(availableEndAt) && !isAvailableAlways) {
      alert("유효기간 종료일을 선택하세요.");
      return false;
    }

    if (!image && !addedImage) {
      alert("팝업 이미지 파일을 업로드해 주세요.");
      return false;
    }

    return window.confirm(confirmMessage);
  }, [getTotalLength, name, image, addedImage, availableEndAt, isAvailableAlways, confirmMessage]);

  return {
    validate,
  };
}
