import styled from "styled-components/macro";
import variables from "../../../assets/scss/variable.module.scss";

export const PanelWrapper = styled.div`
  background-color: ${variables.colorF6};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
