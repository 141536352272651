import React from "react";
import { PageSearchCondition } from "../../types/page";
import { ListQueryType } from "../../types/list";

export interface ListFilterValuesContextType<T, U extends ListQueryType> {
  filterValues: T;
  pageInfoOnFilter: PageSearchCondition;
  searchList: (pageSearchCondition: PageSearchCondition, listQueryType?: U) => () => void;
  listQueryType?: U;
}

export const ListFilterValuesContext = React.createContext<ListFilterValuesContextType<any, any>>(null!);
