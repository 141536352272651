import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, PrimaryOutlineButton } from "../../../components/Buttons";
import { AccountResult } from "../../../layout/account/AccountResult";
import styled from "styled-components";
import { useTimer } from "../../../hooks/common/useTimer";
import queryString from "query-string";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { callAsync } from "../../../util/sliceUtil";
import { accountStatus, findPwdAsync } from "../../../store/accountSlice";
import { AdminType } from "../../../types/account";
import { useLoader } from "../../../hooks/common/useLoader";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

const ButtonWrapperStyle = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
`;

const ButtonColumnStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

const TimerTextStyle = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${(props) => props.theme.color.FGPointRed};
`;

export function FindPWSuccess() {
  const { t } = useTranslation("account");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector(accountStatus);
  useLoader({ status });
  const { defaultErrorMessage } = useUtil();

  const { startTimer, timeFormattedText, isTimerStarted, time } = useTimer(30000);

  const { email, adminType, phoneNumber, phoneCountryCode } = queryString.parse(window.location.search);

  useEffect(() => {
    if (!(email && adminType && phoneNumber)) {
      navigate("/find-account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, adminType, phoneNumber]);

  const onClickLoginButton = useCallback(() => navigate(`/login`), [navigate]);
  const onClickResendEmail = useCallback(() => {
    if (isTimerStarted) {
      alert(t("findAccount.findPWResult.waitForSec", { sec: Math.floor(time / 1000) }));
    } else {
      callAsync(
        dispatch(
          findPwdAsync({
            codePhone: phoneCountryCode as string,
            email: email as string,
            adminType: adminType as AdminType,
            phoneNumber: phoneNumber as string,
          })
        ).unwrap(),
        () => {
          startTimer();
        },
        (error) => {
          if (error.errorCode.httpCode === 404) {
            alert(t("findAccount.notSignedUpMember"));
          } else {
            alert(defaultErrorMessage);
          }
        }
      ).then();
    }
  }, [
    t,
    dispatch,
    startTimer,
    isTimerStarted,
    time,
    email,
    adminType,
    phoneNumber,
    defaultErrorMessage,
    phoneCountryCode,
  ]);

  useEffect(() => {
    startTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountResult
      title={t("findAccount.findPWResult.title")}
      description={t("findAccount.findPWResult.description")}
      wrapperStyle={{ paddingTop: "359px" }}
    >
      <ButtonWrapperStyle>
        <ButtonColumnStyle>
          <PrimaryButton onClick={onClickResendEmail}>{t("findAccount.findPWResult.reissueButton")}</PrimaryButton>
          {isTimerStarted ? <TimerTextStyle>{timeFormattedText}</TimerTextStyle> : <></>}
        </ButtonColumnStyle>
        <PrimaryOutlineButton onClick={onClickLoginButton}>
          {t("findAccount.findPWResult.loginButton")}
        </PrimaryOutlineButton>
      </ButtonWrapperStyle>
    </AccountResult>
  );
}
