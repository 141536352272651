import { PanelWrapper } from "../../../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../../../layout/content/detail/Panel";
import { PrimaryButton } from "../../../../../components/Buttons";
import styled from "styled-components";
import queryString from "query-string";
import React, { useCallback, useMemo } from "react";
import { useGrantOrganManagerVoucher } from "../../detail/organ/hooks/useGrantOrganManagerVoucher";
import { useLoader } from "../../../../../hooks/common/useLoader";
import { useAppSelector, useNewWindow } from "../../../../../hooks/hooks";
import { voucherStatus } from "../../../../../store/voucherSlice";
import { WindowMessage } from "../../../../../types/newWindow";
import { Trans, useTranslation } from "react-i18next";

const GrantMessageStyle = styled.p`
  font-style: normal;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.03em;

  color: ${(props) => props.theme.color.c75};
  margin-top: 10px;

  em {
    font-weight: bold;
    font-style: normal;
    color: ${(props) => props.theme.color.FGBlue};
  }
`;

const GrantButtonStyle = styled(PrimaryButton)`
  width: 300px;
  margin: 20px auto;
  font-size: 20px;
  letter-spacing: -0.6px;
`;

export function OrganManagerGrantVoucher() {
  const { t } = useTranslation(["organManager", "common"]);
  const status = useAppSelector(voucherStatus);
  useLoader({ status });

  const grantOrganManagerVoucher = useGrantOrganManagerVoucher();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  const {
    organIdx: organIdxParam,
    organName: organNameParam,
    organMemberIdxes: organMemberIdxesParam,
  } = queryString.parse(window.location.search);
  const { organIdx, organName, organMemberIdxes } = useMemo(() => {
    return {
      organIdx: Number(organIdxParam),
      organName: `${organNameParam}`,
      organMemberIdxes: Array.isArray(organMemberIdxesParam!!)
        ? organMemberIdxesParam.map((it) => Number(it))
        : [Number(organMemberIdxesParam)],
    };
  }, [organIdxParam, organNameParam, organMemberIdxesParam]);

  const OrganComponent = useCallback(({ organName }: { organName: string }) => <em>{organName}</em>, []);

  const MemberComponent = useCallback(
    ({ memberCount }: { memberCount: number }) => (
      <em>
        {memberCount === 1
          ? t("memberUnit.single", { count: memberCount, ns: "common" })
          : t("memberUnit.multiple", { count: memberCount, ns: "common" })}
      </em>
    ),
    [t]
  );

  return (
    <PanelWrapper>
      <Panel title={t("organVoucherGranter.title")}>
        <>
          <GrantMessageStyle>
            <Trans
              i18nKey={"organVoucherGranter.subtext"}
              t={t}
              components={[
                <OrganComponent organName={organName} />,
                <MemberComponent memberCount={organMemberIdxes.length} />,
              ]}
            />
          </GrantMessageStyle>

          <GrantButtonStyle
            onClick={() => {
              if (!window.confirm(t("organVoucherGranter.confirmMessage"))) return;

              grantOrganManagerVoucher({
                organIdx: organIdx,
                memberIdxes: organMemberIdxes,
              }).then(() => {
                alert(t("organVoucherGranter.resultMessage"));
                closeWindowAndNotifyToParentWindow(WindowMessage.ORGAN_MANAGER_GRANT_VOUCHER);
              });
            }}
          >
            {t("organVoucherGranter.buttonLabel")}
          </GrantButtonStyle>
        </>
      </Panel>
    </PanelWrapper>
  );
}
