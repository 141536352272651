import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { useDownloadFile } from "../../../../hooks/common/useDownloadFile";
import { useUtil } from "../../../../util/hooks/useUtil";
import {downloadSimpleTesterAsync} from "../../../../store/simpleTestSlice";

interface ReturnType {
  downloadSimpleTester: (_: number[]) => void;
}

export function useSimpleTesterDownloader(): ReturnType {
  const dispatch = useAppDispatch();

  const { getDownloadExcelFile } = useDownloadFile();
  const { defaultErrorMessage } = useUtil();

  const downloadSimpleTester = useCallback(
    (userIdxes: number[]) => {
      callAsync(
        dispatch(downloadSimpleTesterAsync(userIdxes)).unwrap(),
        (result) => getDownloadExcelFile(result, "simpleTesters"),
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    },
    [defaultErrorMessage, dispatch, getDownloadExcelFile]
  );

  return {
      downloadSimpleTester,
  };
}
