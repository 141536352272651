import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminPasswordUpdateParam } from "../types/mypage";
import { addCases, executePromise } from "../util/sliceUtil";
import { MyPageApi } from "../api/MyPageApi";
import { SliceState, Status } from "../types/common";
import { AdminVO } from "../types/admin";
import { RootState } from "./index";

export const getPersonalInfoAsync = createAsyncThunk("myPage/getPersonalInfo", () =>
  executePromise(MyPageApi.getPersonalInfo())
);

export const updatePasswordAsync = createAsyncThunk(
  "myPage/updatePassword",
  (adminPasswordUpdateParam: AdminPasswordUpdateParam) =>
    executePromise(MyPageApi.updatePassword(adminPasswordUpdateParam))
);

export const deleteMyAccountAsync = createAsyncThunk("myPage/deleteMyAccount", () =>
  executePromise(MyPageApi.deleteMyAccount())
);

export interface MyPageState extends SliceState {
  myAdminInfo?: AdminVO;
}

const initialState: MyPageState = {
  status: Status.IDLE,
};

export const myPageSlice = createSlice({
  name: "myPage",
  initialState,
  reducers: {
    setMyAdminInfo: (state, action: PayloadAction<AdminVO>) => {
      state.myAdminInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, updatePasswordAsync);
    addCases(builder, getPersonalInfoAsync);
  },
});

export default myPageSlice.reducer;

export const { setMyAdminInfo } = myPageSlice.actions;

export const adminMyInfo = (state: RootState) => state.myPage.myAdminInfo;
