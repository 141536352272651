import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { AffiliateCodeApi } from "../api/AffiliateCodeApi";
import {
  AffiliateCodePostParam,
  AffiliateCodeSearchCondition,
  AffiliateCodeSettlementStaticsParam,
  AffiliateCodeStatusPutParam,
  MonthlySettlementsSearchCondition,
  SettlementStatusUpdateParam,
} from "../types/affiliateCode";
import { RootState } from "./index";
import { AffiliateCodeSettlementApi } from "../api/AffiliateCodeSettlementApi";

export interface AffiliateCodeState extends SliceState {
  isCodeDataSaved: boolean;
}

const initialState: AffiliateCodeState = {
  status: Status.IDLE,
  isCodeDataSaved: false,
};

export const createCodeAsync = createAsyncThunk("affiliateCode/createCode", (param: AffiliateCodePostParam) =>
  executePromise(AffiliateCodeApi.createCode(param))
);

export const getAffiliateCodesAsync = createAsyncThunk(
  "affiliateCode/getAffiliateCodes",
  (param: AffiliateCodeSearchCondition) => executePromise(AffiliateCodeApi.getAffiliateCodes(param))
);

export const getAffiliateCodeAsync = createAsyncThunk("affiliateCode/getAffiliateCode", (idx: number) =>
  executePromise(AffiliateCodeApi.getAffiliateCode(idx))
);

export const updateAffiliateCodeStatusAsync = createAsyncThunk(
  "affiliateCode/updateAffiliateCodeStatus",
  (param: AffiliateCodeStatusPutParam) => executePromise(AffiliateCodeApi.updateAffiliateCodeStatus(param))
);

export const deleteAffiliateCodeAsync = createAsyncThunk("affiliateCode/deleteAffiliateCode", (codeIdxes: number[]) =>
  executePromise(AffiliateCodeApi.deleteAffiliateCode(codeIdxes))
);

export const updateAffiliateCodeAvailableDurationAsync = createAsyncThunk(
  "affiliateCode/updateAffiliateCodeAvailableDuration",
  ({ idx, startAt, endAt }: { idx: number; startAt: string; endAt: string | null }) =>
    executePromise(AffiliateCodeApi.updateAffiliateCodeAvailableDuration(idx, startAt, endAt))
);

export const getAffiliateCodeSettlementStaticsByOrderIdxAsync = createAsyncThunk(
  "affiliateCode/getAffiliateCodeSettlementStaticsByOrderIdx",
  ({ organManagerIdx, year, month }: AffiliateCodeSettlementStaticsParam) =>
    executePromise(AffiliateCodeSettlementApi.getAffiliateCodeSettlementStaticsByOrderIdx(organManagerIdx, year, month))
);

export const getAffiliateCodeSettlementStaticsAsync = createAsyncThunk(
  "affiliateCode/getAffiliateCodeSettlementStatics",
  ({ year, month }: { year: number; month: number }) =>
    executePromise(AffiliateCodeSettlementApi.getAffiliateCodeSettlementStatics(year, month))
);

export const getAffiliateCodeSettlementsAsync = createAsyncThunk(
  "affiliateCode/getAffiliateCodeSettlements",
  (condition: MonthlySettlementsSearchCondition) =>
    executePromise(AffiliateCodeSettlementApi.getAffiliateCodeSettlements(condition))
);

export const updateAffiliateCodeSettlementStatusAsync = createAsyncThunk(
  "affiliateCode/updateAffiliateCodeSettlementStatus",
  (param: SettlementStatusUpdateParam) => executePromise(AffiliateCodeSettlementApi.updateSettlementStatus(param))
);

export const affiliateCodeSlice = createSlice({
  name: "affiliateCode",
  initialState,
  reducers: {
    setIsCodeDataSaved: (state, action: PayloadAction<boolean>) => {
      state.isCodeDataSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, createCodeAsync);
    addCases(builder, getAffiliateCodesAsync);
    addCases(builder, updateAffiliateCodeStatusAsync);
    addCases(builder, deleteAffiliateCodeAsync);
    addCases(builder, getAffiliateCodeSettlementsAsync);
    addCases(builder, updateAffiliateCodeSettlementStatusAsync);
  },
});

export default affiliateCodeSlice.reducer;

export const { setIsCodeDataSaved } = affiliateCodeSlice.actions;

export const affiliateCodeStatusState = (state: RootState) => state.affiliateCode.status;
export const isCodeDataSavedState = (state: RootState) => state.affiliateCode.isCodeDataSaved;
