import { MainApi } from "./MainApi";
import { AdminRoleCategory } from "../types/adminRole";

export class AdminRoleApi {
  static baseUrl = `${MainApi.urlPrefix}/role`;

  static getRoles = () => () => MainApi.api.get(AdminRoleApi.baseUrl);

  static getRoleByCategory = (category: AdminRoleCategory) => () =>
    MainApi.api.get(`${AdminRoleApi.baseUrl}/by-category/${category}`);

  static getMyAccessibleMenu = () => () => MainApi.api.get(`${AdminRoleApi.baseUrl}/accessible-menu`);
}
