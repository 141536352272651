import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { NumberStepper } from "../../../components/NumberStepper";

export function ArticleStepper({ id, title }: ArticleProps) {
  const code = `
        <NumberStepper value={String(1)} setValue={(n) => alert(number : {n})} min={-1} max={10} />
      `;

  return (
    <ArticleWrapper id={id} title={title}>
      <ListItemViewAndCode exampleCode={code}>
        <NumberStepper value={String(1)} setValue={(n) => alert(`number : ${n}`)} min={-1} max={10} />
      </ListItemViewAndCode>
    </ArticleWrapper>
  );
}
