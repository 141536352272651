import styled from "styled-components";

const GroupWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 14px;
`;

export interface Radio<T> {
  check: (_: T) => boolean;
  onClickFunc: () => void;
  text: string;
  isDisabled?: boolean;
  isHide?: boolean;
}

interface Props<T> {
  groupName: string;
  isRequired: boolean;
  radios: Radio<T>[];
  selectedValue: T | null;
  wrapperClassName?: string;
  labelWidth?: string;
}

interface RadioLabelProp {
  labelWidth?: string;
}

const RadioLabel = styled.label<RadioLabelProp>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.c75};
  width: ${(props) => (props.labelWidth ? props.labelWidth : "")};
`;

export function RadioGroup<T>({
  groupName,
  isRequired,
  radios,
  selectedValue,
  wrapperClassName,
  labelWidth,
}: Props<T>) {
  return (
    <GroupWrapper className={wrapperClassName ?? ""}>
      {radios.map((radio, idx) => (
        <RadioLabel key={`${groupName}-${idx}`} labelWidth={labelWidth}
                    style={{ display: radio.isHide ?  'none':'block' }}>
          <input
            type={"radio"}
            name={groupName}
            required={isRequired}
            readOnly={true}
            checked={selectedValue ? radio.check(selectedValue) : false}
            disabled={radio.isDisabled}
            onClick={radio.onClickFunc}
          />
          {radio.text}
        </RadioLabel>
      ))}
    </GroupWrapper>
  );
}
