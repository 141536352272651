import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { resendSignUpConfirmEmailAsync } from "../../../../store/adminSlice";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useAdminEmailResender() {
  const { t } = useTranslation("admin");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const resendConfirmEmail = useCallback(
    (adminIdx: number) => {
      if (window.confirm(t("buttons.resend.confirmMessage"))) {
        callAsync(
          dispatch(resendSignUpConfirmEmailAsync(adminIdx)).unwrap(),
          () => alert(t("buttons.resend.resultMessage")),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, t, defaultErrorMessage]
  );

  return {
    resendConfirmEmail,
  };
}
