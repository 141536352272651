import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { AffiliateCodeStatus } from "../../../../types/affiliateCode";
import { updateAffiliateCodeStatusAsync } from "../../../../store/affiliateCodeSlice";

export function useAffiliateCodeStatusUpdater() {
  const dispatch = useAppDispatch();

  const updateAffiliateCodeStatus = useCallback(
    async (codeIdxes: number[], toStatus: AffiliateCodeStatus) => {
      try {
        await dispatch(
          updateAffiliateCodeStatusAsync({
            affiliateCodeIdxes: codeIdxes,
            status: toStatus,
          })
        ).unwrap();
      } catch (e) {
        console.error(e);
        throw new Error("updateAffiliateCodeStatus occurs an error");
      }
    },
    [dispatch]
  );

  return {
    updateAffiliateCodeStatus,
  };
}
