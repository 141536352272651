import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { deleteAffiliateCodeAsync } from "../../../../store/affiliateCodeSlice";
import { getErrorType } from "../../../../util/sliceUtil";
import { ErrorType } from "../../../../types/error";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useAffiliateCodeDeleter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deleteAffiliateCode = useCallback(
    async (codeIdxes: number[]) => {
      try {
        await dispatch(deleteAffiliateCodeAsync(codeIdxes)).unwrap();
      } catch (e) {
        console.error(e);

        const error: ErrorType = getErrorType(e as Error);
        if (error.errorCode.httpCode === 495) {
          alert("결제건이 있는 제휴코드는 삭제가 불가능합니다.");
        } else {
          alert(defaultErrorMessage);
        }

        throw error;
      }
    },
    [dispatch, defaultErrorMessage]
  );

  return {
    deleteAffiliateCode,
  };
}
