enum PaymentWay {
  CARD = "CARD",
  VBANK = "VBANK",
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
  PAYPAL = "PAYPAL",
}

enum ReceiptType {
  UNISSUED = "UNISSUED",
  DEDUCTION = "DEDUCTION",
  PROOF = "PROOF",
}

interface PaymentDTO {
  idx: number;
  orderIdx: number;
  name: string;
  email: string;
  phoneNumber: string;
  paymentWay: PaymentWay;
  tid: string;
  cardType?: string;
  cardCorp?: string;
  approvedAt?: string;
  vbankName?: string;
  vbankAccountNumber?: string;
  vbankDepositDeadline?: string;
  receiptType?: ReceiptType;
  receiptNumber?: string;
}

interface PaymentVO {
  idx: number;
  orderIdx: number;
  name: string;
  email: string;
  phoneNumber: string;
  paymentWay: PaymentWay;
  tid: string;
  cardType?: string;
  cardCorp?: string;
  approvedAt?: string;
  vBankName?: string;
  vBankAccountNumber?: string;
  vBankDepositDeadline?: string;
  receiptType?: ReceiptType;
  receiptNumber?: string;
}

export { PaymentWay, ReceiptType };
export type { PaymentVO, PaymentDTO };
