import styled from "styled-components/macro";
import { StrongTextStyle } from "../Texts";

export const InfoAboveListTextStyle = styled.span`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${(props) => props.theme.color.c75};
`;

export const InfoAboveListStrongTextStyle = styled(StrongTextStyle)`
  color: ${(props) => props.theme.color.FGDarkBlue};
`;

export const InfoAboveListStyle = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PaginationWrapperStyle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
