import { useAffiliateCodeFilterValues } from "./hooks/code/useAffiliateCodeFilterValues";
import { CodeList } from "./CodeList";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function CodeListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useAffiliateCodeFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <CodeList />
    </ListFilterValuesContext.Provider>
  );
}
