import {ListTable} from "../../../layout/content/list/ListTable";
import {useOrganManagerColumns} from "./hooks/useOrganManagerColumns";
import {useOrganManagerFilter} from "./hooks/useOrganManagerFilter";
import {useOrganManagerList} from "./hooks/useOrganManagerList";
import React, {useState} from "react";
import {AddNewOrganMemberButton, ListActionButton, OrganMemberListContainer} from "../../../components/Buttons";
import voucherIcon from "../../../assets/images/button_icons/voucher_icon.svg";
import addIcon from "../../../assets/images/button_icons/person-add-icon.png";
import {CustomerType} from "../../../types/common";
import {useAppSelector, useLanguage, useNewWindow} from "../../../hooks/hooks";
import {organManagerStatus} from "../../../store/organManagerSlice";
import {useLoader} from "../../../hooks/common/useLoader";
import {OrganManagerListDTO} from "../../../types/organManager";
import {useOrganManagerStatus} from "./hooks/useOrganManagerStatus";
import {useTranslation} from "react-i18next";
import {ListCount, WhichList} from "../../../components/ListCount";
import {useNavigate} from "react-router-dom";

export function MemberOrganManager() {
  const { t } = useTranslation("organManager");
  const status = useAppSelector(organManagerStatus);

  useLoader({ status });

  const [selectedOrganManagerIdxes, setSelectedOrganManagerIdxes] = useState<number[]>([]);

  const { searchOrganManagerList, totalOrganManagerCount, organManagerList, pageCondition } = useOrganManagerList({
    onCompletedCallback: () => setSelectedOrganManagerIdxes([]),
  });
  const { filters } = useOrganManagerFilter({ search: searchOrganManagerList({}) });
  const { columns } = useOrganManagerColumns(searchOrganManagerList(pageCondition));
  const { openGrantProduct} = useNewWindow();
  const { isNormal } = useOrganManagerStatus();
  const navigate = useNavigate();
  const {isKorean} = useLanguage();

    function navigateOrganMemberPost() {
        navigate(`/member/organ-manager/post`);
    }

  return organManagerList ? (
    <ListTable
      pageOnFilter={pageCondition}
      filters={filters}
      listData={organManagerList}
      getList={searchOrganManagerList}
      columns={columns}
      totalRowsCnt={{
        totalCount: <ListCount totalCount={totalOrganManagerCount} whichList={WhichList.MEMBER} />,
        singleUnitKeyInCommonFile: t("memberUnit.singleComponent"),
        multipleUnitKeyInCommonFile: t("memberUnit.multipleComponent"),
      }}
      showCheckbox={(organManager: OrganManagerListDTO) => isNormal(organManager.status)}
      checkedRowIdxArr={selectedOrganManagerIdxes}
      setCheckedRowIdxArr={setSelectedOrganManagerIdxes}
      buttonsNode={
        <OrganMemberListContainer>
            {isKorean ? (
            <AddNewOrganMemberButton onClick={navigateOrganMemberPost}>
                <img src={addIcon} alt={t("list.addOrganMember.buttonLabel")} />
                {t("list.addOrganMember.buttonLabel")}
            </AddNewOrganMemberButton>) :  (<div></div>)}
          <ListActionButton
            onClick={() => {
              if (selectedOrganManagerIdxes.length === 0) {
                alert(t("상품을 부여할 회원을 선택해주세요."));
              } else {
                openGrantProduct({
                  targetIdxes: selectedOrganManagerIdxes,
                  targetType: CustomerType.ORGAN_MANAGER,
                  targetName:
                    selectedOrganManagerIdxes.length === 1
                      ? organManagerList.content.find((om) => om.idx === selectedOrganManagerIdxes[0])?.name ?? ""
                      : "",
                });
              }
            }}
          >
            <img src={voucherIcon} alt={t("list.grantVoucher.alt")} />
            {t("list.grantVoucher.buttonLabel")}
          </ListActionButton>
        </OrganMemberListContainer>
      }
    />
  ) : (
    <></>
  );
}
