import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  getCouponCountInfoAsync,
  setUserUsableCouponStatusInfo,
  userUsableCouponStatusInfo,
} from "../../../store/couponSlice";
import { CouponCntInfo, CouponCountInfoParam } from "../../../types/coupon";
import { useUtil } from "../../../util/hooks/useUtil";

interface Args {
  targetParam: CouponCountInfoParam;
}

export function useCouponCntInfo({ targetParam }: Args) {
  const dispatch = useAppDispatch();

  const couponStatusInfo = useAppSelector(userUsableCouponStatusInfo);
  const { defaultErrorMessage } = useUtil();

  const getCouponCntInfo = () => {
    callAsync<CouponCntInfo>(
      dispatch(getCouponCountInfoAsync(targetParam)).unwrap(),
      (result) => dispatch(setUserUsableCouponStatusInfo(result)),
      () => alert(defaultErrorMessage)
    ).then();
  };

  return {
    getCouponCntInfo,
    couponStatusInfo,
  };
}
