import { LanguageContext } from "./LanguageContext";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Language } from "../../types/language";
import { CookieKeys, useCookie } from "../auth/useCookie";
import { SelectorOption } from "../../types/selector";

export function LanguageProvider({ children }: { children: JSX.Element }) {
  const { i18n } = useTranslation();

  const getLanguageFromString = useCallback((langString: string) => {
    switch (langString) {
      case Language.KO:
        return Language.KO;
      case Language.EN:
        return Language.EN;
      default:
        return Language.EN;
    }
  }, []);

  const [language, setLanguage] = useState<Language>(getLanguageFromString(i18n.language));

  const isKorean = useMemo(() => language === Language.KO, [language]);
  const { setCookie } = useCookie();

  const changeLanguage = useCallback(
    (lang: Language) => {
      setLanguage(lang);
      i18n.changeLanguage(lang).then();
      setCookie(CookieKeys.LANGUAGE, lang.toString());
    },
    [setLanguage, i18n, setCookie]
  );

  const setLang = useCallback(
    (lang: Language) => {
      changeLanguage(lang);
    },
    [changeLanguage]
  );

  useEffect(() => {
    const cookies = document.cookie.split(";");
    const langCookie = cookies.find((it) => it.includes("lang"));
    let lang;
    if (typeof langCookie === "undefined") {
      lang = window.navigator.language;
      setCookie(CookieKeys.LANGUAGE, lang);
    } else {
      lang = langCookie.split("=")[1];
    }

    lang = lang.substring(0, 2);
    switch (lang) {
      case "ko":
        setLang(Language.KO);
        break;
      case "ko-KR":
        setLang(Language.KO);
        break;
      case "en":
        setLang(Language.EN);
        break;
      default:
        setLang(Language.EN);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toStringFromLanguage = useCallback((lang: Language) => {
    switch (lang) {
      case Language.KO:
        return "한국어";
      case Language.EN:
        return "English";
    }
  }, []);

  const languageOptions: SelectorOption[] = useMemo(
    () =>
      Object.keys(Language).flatMap((key) => {
        const valueObj = Language[key as keyof typeof Language];

        if (typeof valueObj === "string") {
          return [
            {
              value: valueObj.toString(),
              label: toStringFromLanguage(valueObj),
            },
          ];
        } else {
          return [];
        }
      }),
    [toStringFromLanguage]
  );

  return (
    <LanguageContext.Provider
      value={{
        language,
        setLang,
        changeLanguage,
        toStringFromLanguage,
        isKorean,
        languageOptions,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
