import { MiniModal } from "../../../layout/modal/MiniModal";
import style from "./productItemModal.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { callAsync } from "../../../util/sliceUtil";
import { getProductItemsByProductAsync, unitItemsStatus } from "../../../store/productSlice";
import { ProductItemDTO } from "../../../types/productItem";
import { useTranslation } from "react-i18next";
import { ValidDurationValue } from "./ValidDurationValue";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  isShow: boolean;
  callbackCloseModal: VoidFunction;
  productIdx: number;
  linkElement?: HTMLElement;
}

export function ProductItemModal({ isShow, callbackCloseModal, productIdx, linkElement }: Props) {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const [productItems, setProductItems] = useState<ProductItemDTO[]>([]);
  const { defaultErrorMessage } = useUtil();
  const status = useAppSelector(unitItemsStatus);

  const getProductItems = () => {
    callAsync<ProductItemDTO[]>(
      dispatch(getProductItemsByProductAsync(productIdx)).unwrap(),
      (result) => setProductItems(result),
      () => alert(defaultErrorMessage)
    ).then();
  };

  useEffect(() => {
    if (isShow) {
      getProductItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIdx, isShow]);

  return (
    <MiniModal
      show={isShow}
      closeModal={() => callbackCloseModal()}
      title={t("productItem.title")}
      modalWidth={"811px"}
      linkElement={linkElement}
      statusValues={[status]}
    >
      <table className={style.itemDetailTable}>
        <thead>
          <tr>
            <th>{t("productItem.columns.type")}</th>
            <th>{t("productItem.columns.unitItemName")}</th>
            <th>{t("productItem.columns.availableUntil")}</th>
            <th>{t("productItem.columns.quantity")}</th>
          </tr>
        </thead>
        <tbody>
          {productItems.map((item, idx) => (
            <tr key={`product-items-${idx}`}>
              <td>{t(`productItem.${item.unitItem.type.toLowerCase()}`)}</td>
              <td>{item.unitItem.name}</td>
              <td>
                <ValidDurationValue day={item.unitItem.validDuration} from={item.unitItem.validDurationFrom} />
              </td>
              <td>{item.quantity}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </MiniModal>
  );
}
