import {CustomerType, TableColumnData, TestResultFormType} from "../../../types/common";
import {TestListObj} from "../../../types/test";
import listStyle from "../../../layout/content/list/list.module.scss";
import React, {useCallback} from "react";
import {CopiableText} from "../../../components/CopiableText";
import {MemberUserDetailTabType, UserStatus} from "../../../types/user";
import {DetailBarInfoCategory} from "../../../types/newWindow";
import {ActionButton} from "../../../components/Buttons";
import {AdminMenuCode} from "../../../types/adminRole";
import {useOpenTestResult} from "../../../hooks/useOpenTestResult";
import {useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useGenderType} from "../../../types/hooks/useGenderType";
import {useOpenGuide} from "../../../hooks/useOpenGuide";
import {VoucherType} from "../../../types/voucher";

export function useTestColumns(testType: VoucherType): TableColumnData<TestListObj>[] {
  const { t } = useTranslation("test");
  const openTestResult = useOpenTestResult();
  const openGuide = useOpenGuide(testType);
  const { openUserDetailPopup, openGrantProduct, openNewWindowWithURL, openCreatedProfileDetailPopup } = useNewWindow();
  const { timeConverter } = useTimeConverter();
  const { toStringFromGender } = useGenderType();
  const onClickGrantProduct = (testListObj: TestListObj) => {
    openGrantProduct({
      targetIdxes: [testListObj.userIdx],
      targetType: CustomerType.USER,
    });
  };

  const isLeavedUser = useCallback((status: UserStatus) => status === UserStatus.LEAVE, []);

  return [
    {
      accessor: "endedAt",
      header: t("list.columns.endedAt"),
      getNode: (it) => timeConverter.convertToLocalTime(it.endedAt),
      sortable: true,
    },
    {
      accessor: "email",
      header: t("list.columns.id"),
      getNode: (it) =>
        Boolean(it.email) ? (
          <a
            className={listStyle.underline}
            onClick={(e) => {
              e.preventDefault();
              if (isLeavedUser(it.userStatus)) {
                openNewWindowWithURL(`/detail/member/user/${it.userIdx}?type=SELF_INFO`, {
                  width: 1000,
                  height: 800,
                });
              } else {
                openUserDetailPopup(it.userIdx, {
                  type: MemberUserDetailTabType.SELF_INFO,
                  name: it.name,
                  category: DetailBarInfoCategory.USER,
                  isLeaved: false,
                });
              }
            }}
            rel="noopener noreferrer"
          >
            {isLeavedUser(it.userStatus) ? t("list.columns.leavedMember") : it.email}
          </a>
        ) : (
          <>-</>
        ),
      sortable: false,
      width: 236,
    },
    {
      accessor: "name",
      header: t("list.columns.testerName"),
      getNode: (it) =>
        isLeavedUser(it.userStatus) ? (
          <>{t("list.columns.leavedMember")}</>
        ) : (
          <a
            className={listStyle.underline}
            onClick={(e) => {
              e.preventDefault();
              if (it.userIdx) {
                openNewWindowWithURL(
                  isLeavedUser(it.userStatus)
                    ? `/detail/member/user/${it.userIdx}?type=SELF_INFO`
                    : `/detail/member/user/${it.userIdx}?type=TESTERS&profileIdx=${it.profileIdx}`,
                  {
                    width: 1000,
                    height: 800,
                  }
                );
              } else {
                openCreatedProfileDetailPopup(it.profileIdx, () => {});
              }
            }}
            rel="noopener noreferrer"
          >
            {it.name}
          </a>
        ),
      sortable: false,
      width: 104,
    },
    {
      accessor: "userCode",
      header: t("list.columns.userCode"),
      getNode: (it) =>
        isLeavedUser(it.userStatus) || !it.userCode ? <>-</> : <CopiableText text={it.userCode} isBlue={false} />,
      sortable: false,
      menuCode: AdminMenuCode.PROFILE_CODE_READ,
    },
    {
      accessor: "age",
      header: t("list.columns.age"),
      sortable: true,
      getNode: (it) => (it.age ? <>{it.age}</> : <>-</>),
    },
    {
      accessor: "gender",
      header: t("list.columns.gender"),
      getNode: (it) => (it?.gender ? toStringFromGender(it.gender) : "-"),
      sortable: true,
    },
    {
      accessor: "region",
      header: t("list.columns.region"),
      getNode: (it) => it?.region ?? "-",
      sortable: true,
    },
    {
      accessor: "testRound",
      header: t("list.columns.testCount.label"),
      getNode: (it) => `${it.testRound}${t("list.columns.testCount.unit")}`,
      sortable: true,
    },
    {
      accessor: "isDeleted",
      header: t("list.columns.status.label"),
      getNode: (it) =>
        !it.isDeleted ? (
          <span>{t("list.columns.status.normal")}</span>
        ) : (
          <span className={listStyle.deleted}>{t("list.columns.status.deleted")}</span>
        ),
      sortable: false,
      menuCode: AdminMenuCode.TEST_HISTORY_STATUS_READ,
    },
    {
      accessor: "idx",
      header: t("list.columns.additionalFunctions.label"),
      getNode: (it) => (
        <div className={listStyle.additionalButtons}>
          {isLeavedUser(it.userStatus) || !it.email ? (
            <></>
          ) : (
            <ActionButton onClick={() => onClickGrantProduct(it)}>
              {t("list.columns.additionalFunctions.grantableProduct")}
            </ActionButton>
          )}
          <ActionButton onClick={() => openTestResult
          (it.idx, testType, testType === VoucherType.KPASS ? it.kpassResultForm : it.dcasResultForm)
          }>
            {t("list.columns.additionalFunctions.testResult")}
          </ActionButton>
          {testType === VoucherType.KPASS && it.kpassResultForm !== TestResultFormType.SUMMARY && (
            <ActionButton onClick={() => openGuide(it.idx)}>{t("list.columns.additionalFunctions.guide")}</ActionButton>
          )}

          {testType === VoucherType.DCAS && it.dcasResultForm !== TestResultFormType.SUMMARY && (
              <ActionButton onClick={() => openGuide(it.idx)}>{t("list.columns.additionalFunctions.guide")}</ActionButton>
          )}
        </div>
      ),
      sortable: false,
    },
  ];
}
