import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import { callAsync } from "../../../util/sliceUtil";
import { LinkedProductListObj } from "../../../types/unitItem";
import { getLinkedProductsAsync } from "../../../store/unitItemSlice";
import { useMemo, useState } from "react";
import { useAppDispatch } from "../../../hooks/hooks";
import { useUtil } from "../../../util/hooks/useUtil";

interface Args {
  unitItemIdx: number;
}

export function useLinkedProductsList({ unitItemIdx }: Args) {
  const dispatch = useAppDispatch();
  const [linkedProducts, setLinkedProducts] = useState<PageResponse<LinkedProductListObj>>();
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: linkedProducts?.number ?? 0,
      size: linkedProducts?.size ?? 5,
      sort: ordersToSortArr(linkedProducts?.orders),
    }),
    [linkedProducts]
  );

  const getLinkedProducts = ({ page, size, sort }: PageSearchCondition) => {
    return () => {
      callAsync<PageResponse<LinkedProductListObj>>(
        dispatch(
          getLinkedProductsAsync({
            unitItemIdx,
            pageCondition: {
              page: page ?? 0,
              size: size ?? pageCondition.size,
              sort: sort ?? pageCondition.sort,
            },
          })
        ).unwrap(),
        (result) => setLinkedProducts(result),
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  return {
    getLinkedProducts,
    linkedProducts,
    pageCondition,
  };
}
