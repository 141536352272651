import {useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {usePopupType} from "../../../../types/hooks/usePopupType";
import {PopupFilterValues} from "./usePopupFilterValues";
import {FilterContentType, TableFilter} from "../../../../types/tableFilter";
import {PopupStatus, PopupType} from "../../../../types/popup";
import {Languages, WebsiteType} from "../../../../types/common";

interface Args {
  search: () => void;
}

export function usePopupFilters({ search }: Args) {
  const { filterValues } = useListFilterValues();
  const { getPopupTypeLabel, getPopupStatusLabel, getWebsiteTypeLabel, getPopupLanguageLabel } = usePopupType();
  const { timeConverter } = useTimeConverter();

  const {
    searchWordFilterValue,
    availableStartAtFilterValue,
    availableEndAtFilterValue,
    websiteTypeFilterValue,
    typeFilterValue,
    statusFilterValue,
    popupLanguageFilterValue,
  } = filterValues as PopupFilterValues;

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: "팝업명 검색",
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilterValue?.value,
          maxLength: 100,
          placeholderVal: "검색할 팝업명 입력",
          onChangeFunc: (v) => searchWordFilterValue.setter(v),
          onEnterKeyUpFunc: search,
        },
      },
    ],
    [
      {
        labelTitle: "유효기간",
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(availableStartAtFilterValue?.value),
            onChangeFunc: (v) =>
              availableStartAtFilterValue?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: "날짜 입력",
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(availableEndAtFilterValue?.value),
            onChangeFunc: (v) =>
              availableEndAtFilterValue?.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: "날짜 입력",
          },
        },
      },
    ],
    [
      {
        labelTitle: "홈페이지",
        content: {
          type: FilterContentType.SELECT,
          value: websiteTypeFilterValue.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getWebsiteTypeLabel(WebsiteType.KPASS),
              value: WebsiteType.KPASS.toString(),
            },
            {
              label: getWebsiteTypeLabel(WebsiteType.DCAS),
              value: WebsiteType.DCAS.toString(),
            },
          ],
          onChangeFunc: (v) => websiteTypeFilterValue.setter(v as unknown as WebsiteType),
        },
      },
      {
        labelTitle: "팝업 언어",
        content: {
          type: FilterContentType.SELECT,
          value: popupLanguageFilterValue.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getPopupLanguageLabel(Languages.KO),
              value: Languages.KO.toString(),
            },
            {
              label: getPopupLanguageLabel(Languages.EN),
              value: Languages.EN.toString(),
            },
            {
              label: getPopupLanguageLabel(Languages.ZH),
              value: Languages.ZH.toString(),
            },
            {
              label: getPopupLanguageLabel(Languages.RU),
              value: Languages.RU.toString(),
            },
          ],
          onChangeFunc: (v) => popupLanguageFilterValue.setter(v as unknown as Languages),
        },
      },
      {
        labelTitle: "팝업 유형",
        content: {
          type: FilterContentType.SELECT,
          value: typeFilterValue.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getPopupTypeLabel(PopupType.IMAGE),
              value: PopupType.IMAGE.toString(),
            },
          ],
          onChangeFunc: (v) => typeFilterValue.setter(v as unknown as PopupType),
        },
      },
      {
        labelTitle: "상태",
        content: {
          type: FilterContentType.SELECT,
          value: statusFilterValue.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getPopupStatusLabel(PopupStatus.EXPOSED),
              value: PopupStatus.EXPOSED.toString(),
            },
            {
              label: getPopupStatusLabel(PopupStatus.UNEXPOSED),
              value: PopupStatus.UNEXPOSED.toString(),
            },
          ],
          onChangeFunc: (v) => statusFilterValue.setter(v as unknown as PopupStatus),
        },
      },
    ],
  ];

  return {
    filters,
  };
}
