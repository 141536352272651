import { useAppDispatch, useTimeConverter } from "../../../hooks/hooks";
import { useCallback } from "react";
import { useDownloadFile } from "../../../hooks/common/useDownloadFile";
import { callAsync } from "../../../util/sliceUtil";
import { downloadAllOrdersAsync, downloadOrdersAsync } from "../../../store/orderSlice";
import { useUtil } from "../../../util/hooks/useUtil";
import { useTranslation } from "react-i18next";
import { AffiliateCodeOrderSearchCondition, OrderSearchCondition } from "../../../types/orders";

export function useOrderDownloader() {
  const { t } = useTranslation("order");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { timeConverter } = useTimeConverter();

  const { getDownloadExcelFile } = useDownloadFile();

  const downloadOrders = useCallback(
    (orderIdxes: number[]) => {
      callAsync(
        dispatch(downloadOrdersAsync({ orderIdxes, timezone: timeConverter.getTimezone() })).unwrap(),
        (result) => getDownloadExcelFile(result, t("downloadFileName")),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, getDownloadExcelFile, defaultErrorMessage, t, timeConverter]
  );

  const downloadAllOrders = useCallback(
    (condition: OrderSearchCondition | AffiliateCodeOrderSearchCondition) => {
      callAsync(
        dispatch(downloadAllOrdersAsync({ condition, timezone: timeConverter.getTimezone() })).unwrap(),
        (result) => getDownloadExcelFile(result, t("downloadFileName")),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, getDownloadExcelFile, defaultErrorMessage, t, timeConverter]
  );

  const onClickDownloadSelectedOrders = useCallback(
    (orderIdxes: number[]) => {
      if (orderIdxes.length < 1) {
        alert(t("list.noOrderSelected"));
      } else {
        downloadOrders(orderIdxes);
      }
    },
    [downloadOrders, t]
  );

  return {
    downloadOrders,
    downloadAllOrders,
    onClickDownloadSelectedOrders,
  };
}
