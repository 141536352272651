import { ListTable } from "../../layout/content/list/ListTable";
import useOrderColumns from "./hooks/useOrderColumns";
import React, { useCallback, useState } from "react";
import { useOrderFilters } from "./hooks/useOrderFilters";
import useOrderList from "./hooks/useOrderList";
import { useOrderDownloader } from "./hooks/useOrderDownloader";
import { useAppSelector } from "../../hooks/hooks";
import { orderStatus } from "../../store/orderSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { ListCount, WhichList } from "../../components/ListCount";
import { ExcelDownloadButton } from "../../layout/content/selector/ExcelDownloadButton";

export function OrderList() {
  const [selectedOrderIdxes, setSelectedOrderIdxes] = useState<number[]>([]);

  const { searchOrderList, totalOrderCount, orderList, getOrderSearchConditionFromListQueryType, pageCondition } =
    useOrderList({
      onCompletedCallback: () => setSelectedOrderIdxes([]),
    });
  const columns = useOrderColumns({ onRefresh: searchOrderList({}) });
  const { filters } = useOrderFilters({ search: searchOrderList({}) });
  const status = useAppSelector(orderStatus);
  useLoader({ status });

  const { onClickDownloadSelectedOrders, downloadAllOrders } = useOrderDownloader();

  const onClickDownloadAllOrders = useCallback(() => {
    const condition = getOrderSearchConditionFromListQueryType();
    if (condition) {
      downloadAllOrders(condition);
    }
  }, [getOrderSearchConditionFromListQueryType, downloadAllOrders]);

  return orderList ? (
    <ListTable
      pageOnFilter={pageCondition}
      filters={filters}
      totalRowsCnt={{
        totalCount: <ListCount totalCount={totalOrderCount} whichList={WhichList.PAYMENT} />,
        singleUnitKeyInCommonFile: "paymentUnit.singleComponent",
        multipleUnitKeyInCommonFile: "paymentUnit.multipleComponent",
      }}
      columns={columns}
      listData={orderList}
      getList={({ page, sort, size }) => searchOrderList({ page, sort, size })}
      checkedRowIdxArr={selectedOrderIdxes}
      setCheckedRowIdxArr={setSelectedOrderIdxes}
      buttonsNode={
        <>
          <div></div>
          <div>
            <ExcelDownloadButton
              onClickSelectedItemsDownloadButton={() => onClickDownloadSelectedOrders(selectedOrderIdxes)}
              onClickAllDownloadButton={() => onClickDownloadAllOrders()}
            />
          </div>
        </>
      }
    />
  ) : (
    <></>
  );
}
