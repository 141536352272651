import { ListTable } from "../../../layout/content/list/ListTable";
import { useAdminColumns } from "./hooks/useAdminColumns";
import { AdminStatus } from "../../../types/admin";
import { useAdminFilters } from "./hooks/useAdminFilters";
import React from "react";
import { useAdminList } from "./hooks/useAdminList";
import { useSignedInAdminIdx } from "./hooks/useSignedInAdminIdx";
import { ListCount, WhichList } from "../../../components/ListCount";

export function MemberAdmin() {
  const { searchAdminList, totalAdminCnt, adminList, pageCondition } = useAdminList();
  const { filters } = useAdminFilters({ search: searchAdminList({}) });
  const { columns } = useAdminColumns({ getList: searchAdminList(pageCondition) });
  const signedInAdminIdx = useSignedInAdminIdx();

  return adminList ? (
    <ListTable
      filters={filters}
      listData={adminList}
      getList={({ page, sort, size }) => searchAdminList({ page, sort, size })}
      columns={columns}
      totalRowsCnt={{
        totalCount: <ListCount totalCount={totalAdminCnt} whichList={WhichList.MEMBER} />,
        singleUnitKeyInCommonFile: "memberUnit.singleComponent",
        multipleUnitKeyInCommonFile: "memberUnit.multipleComponent",
      }}
      showCheckbox={(it) => it.status !== AdminStatus.BEFORE_SELF_AUTHENTICATION && it.idx !== signedInAdminIdx}
      buttonsNode={<></>}
      pageOnFilter={pageCondition}
    />
  ) : (
    <></>
  );
}
