import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  memoListTitle: string;
  setMemo: (_: string) => void;
  memoRef: React.Ref<HTMLTextAreaElement>;
  defaultValue: string;
}
export function MemoTextArea({ memoListTitle, defaultValue, memoRef, setMemo }: Props) {
  const { t } = useTranslation("memo");

  return (
    <textarea
      required={true}
      minLength={2}
      maxLength={1000}
      placeholder={`${memoListTitle} ${t("memoTextArea.placeholder")}`}
      defaultValue={defaultValue}
      onChange={(e) => setMemo(e.target.value)}
      draggable={"false"}
      title={t("memoTextArea.title")}
      ref={memoRef}
    />
  );
}
