import { useCallback, useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../../../../types/page";
import { OrganManagerVoucherSearchCondition, OrganVoucherHistorySetListDTO } from "../../../../../../../types/voucher";
import { callAsync } from "../../../../../../../util/sliceUtil";
import { useAppDispatch } from "../../../../../../../hooks/hooks";
import { getOrganManagerVouchersAsync } from "../../../../../../../store/voucherSlice";
import { useUtil } from "../../../../../../../util/hooks/useUtil";

export function useOrganManagerVoucherList() {
  const dispatch = useAppDispatch();
  const [organManagerVoucherList, setOrganManagerVoucherList] = useState<PageResponse<OrganVoucherHistorySetListDTO>>();

  const pageCondition = useMemo(
    () => ({
      page: organManagerVoucherList?.number ?? 0,
      size: organManagerVoucherList?.size ?? 5,
      sort: ordersToSortArr(organManagerVoucherList?.orders),
    }),
    [organManagerVoucherList]
  );
  const { defaultErrorMessage } = useUtil();

  const getOrganManagerVoucher = useCallback(
    (organManagerIdx: number, { page, sort, size }: PageSearchCondition) => {
      const param: OrganManagerVoucherSearchCondition = {
        pageCondition: {
          page: page ?? 0,
          size: size ?? pageCondition.size,
          sort: sort ?? pageCondition.sort,
        },
      };

      return () => {
        callAsync<PageResponse<OrganVoucherHistorySetListDTO>>(
          dispatch(getOrganManagerVouchersAsync({ organManagerIdx, condition: param })).unwrap(),
          (result) => {
            setOrganManagerVoucherList(result);
          },
          () => alert(defaultErrorMessage)
        ).then();
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, defaultErrorMessage]
  );

  return {
    getOrganManagerVoucher,
    organManagerVoucherList,
    pageCondition,
  };
}
