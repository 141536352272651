import style from "../productEditForm.module.scss";
import { FilterNumber } from "../../../../layout/content/list/filter/FilterNumber";
import { FilterContentType } from "../../../../types/tableFilter";
import React from "react";
import { Label104x46 } from "../../../../components/Label";
import { useTranslation } from "react-i18next";
import {Languages} from "../../../../types/common";
import {convertCurrencyUnit} from "../../../../util/etcUtil";
import {PaymentWay} from "../../../../types/payment";

interface Props {
  sellingPrice: string;
  setSellingPrice: (_: string) => void;
  language: Languages;
}

export function ProductEditSellingPrice({ sellingPrice, setSellingPrice, language }: Props) {
  const { t } = useTranslation(["product", "common"]);
  return (
      <div className={`${style.field} ${style.alignCenter} ${style.flex0}`}>
          <Label104x46 text={t("edit.sellingPrice.title")} className={style.label}/>
          <FilterNumber
              numberField={{
                  type: FilterContentType.NUMBER,
                  value: sellingPrice,
                  min: 1,
                  isRequired: true,
                  placeholderVal: t("inputNumberPlaceholder", {ns: "common"}),
                  // onChangeFunc: (v: string) => setSellingPrice(v)
                  onChangeFunc: () => {
                  },
              }}
              className={style.priceField}
          />
          <span className={style.unitText}>
              {convertCurrencyUnit(language !== Languages.KO
                  ? PaymentWay.PAYPAL
                  : PaymentWay.CARD, t)}
          </span>
      </div>
  );
}
