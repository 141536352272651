import style from "../productEditForm.module.scss";
import { ImageButton } from "../ImageButton";
import React, { useCallback, useMemo } from "react";
import { ProductImage, ProductImageVOType } from "../../../../types/product";
import { FileType } from "../../../../types/file";
import { FileAcceptType, FileInput } from "../../../component/file/FileInput";
import { Label104x46 } from "../../../../components/Label";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";
import { ImageFieldStyle } from "../../../../components/styled/ImageStyle";

interface Props {
  detailImages: ProductImage[];
  setDetailImages: (_: ProductImage[]) => void;
  addedDetailImages: FileType[];
  setAddedDetailImages: (_: FileType[]) => void;
  imageVOType: ProductImageVOType;
}

const UPLOADABLE_FILES_MAX_COUNT = 20;

export function ProductEditDetailImages({
  detailImages,
  setDetailImages,
  addedDetailImages,
  setAddedDetailImages,
  imageVOType,
}: Props) {
  const { t } = useTranslation("product");
  const { wantToDeleteImage } = useUtil();

  const readFile = useCallback((file: File) => {
    return new Promise<FileType>((resolve, _) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          src: reader.result?.toString() ?? "",
          file: file,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const addDetailImg = () => {
    const fileInput = new FileInput(
      (files) => {
        const uploadableCount = UPLOADABLE_FILES_MAX_COUNT - detailImages.length - addedDetailImages.length;
        Promise.all(
          Array.from(files)
            .slice(0, uploadableCount)
            .map((file) => readFile(file))
        ).then((results) => {
          setAddedDetailImages([...addedDetailImages, ...results]);
        });
      },
      true,
      [FileAcceptType.IMAGE, FileAcceptType.VIDEO]
    );

    fileInput.click();
  };

  const onClickDeleteDetailImage = (arrayIdx: number) => {
    const copiedDetailImages = [...detailImages];
    copiedDetailImages.splice(arrayIdx, 1);
    setDetailImages(copiedDetailImages);
  };

  const ImageTypeDesc = useMemo(
    () => ({
      [ProductImageVOType.DESKTOP.toString()]: t("edit.detailImages.desktopLabel"),
      [ProductImageVOType.MOBILE.toString()]: t("edit.detailImages.mobileLabel"),
    }),
    [t]
  );

  const MaxFileCount = useCallback(() => <>{UPLOADABLE_FILES_MAX_COUNT}</>, []);

  return (
    <div className={style.field}>
      <Label104x46 text={t("edit.detailImages.title")} className={style.label} />
      <ImageFieldStyle>
        {imageVOType !== ProductImageVOType.NONE ? (
          <span className={"imageType"}>{ImageTypeDesc[imageVOType.toString()] ?? ""}</span>
        ) : (
          <></>
        )}
        <div className={"images"}>
          {detailImages.map((image, idx) => (
            <ImageButton
              key={`detail-${idx}`}
              className={style.detail}
              onClickCloseBtn={() => {
                const wantToDelete = window.confirm(wantToDeleteImage);
                if (wantToDelete) {
                  onClickDeleteDetailImage(idx);
                }
              }}
              imageSrc={image.file.uri}
            />
          ))}
          {addedDetailImages.map((di, idx) => (
            <ImageButton
              key={`test-${idx}`}
              className={style.detail}
              onClickCloseBtn={() => {
                const wantToDelete = window.confirm(wantToDeleteImage);
                if (wantToDelete) {
                  const copiedImages = [...addedDetailImages];
                  copiedImages.splice(idx, 1);
                  setAddedDetailImages(copiedImages);
                }
              }}
              imageSrc={di.src}
            />
          ))}
          {detailImages.length + addedDetailImages.length < UPLOADABLE_FILES_MAX_COUNT && (
            <ImageButton className={style.detail} onClickBlankImgBtn={addDetailImg} />
          )}
        </div>
        <span className={"text"}>
          <Trans t={t} i18nKey={"edit.detailImages.support"} components={[<MaxFileCount />]} />
        </span>
      </ImageFieldStyle>
    </div>
  );
}
