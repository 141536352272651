import { useAppDispatch, useNewWindow } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { deleteMyAccountAsync } from "../../../../store/myPageSlice";
import { callAsync } from "../../../../util/sliceUtil";
import { WindowMessage } from "../../../../types/newWindow";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useMyAccountDeleter() {
  const { t } = useTranslation("account");
  const dispatch = useAppDispatch();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { defaultErrorMessage } = useUtil();

  const deleteMyAccount = useCallback(() => {
    if (!window.confirm(t("leave.confirmMessage"))) return;

    callAsync(
      dispatch(deleteMyAccountAsync()).unwrap(),
      () => {
        closeWindowAndNotifyToParentWindow(WindowMessage.ADMIN_LOGOUT);
        alert(t("leave.resultMessage"));
      },
      () => alert(defaultErrorMessage)
    ).then();
  }, [t, defaultErrorMessage, closeWindowAndNotifyToParentWindow, dispatch]);

  return {
    deleteMyAccount,
  };
}
