import {AdditionalInputText} from "../../../components/AdditionalInputText";
import bannerCreator from "../banner/banner.creator.module.scss"

interface Props{
    bannerText : string;
    bannerTextNumber : string;
    setBannerText: (_: string) => void;
}

export default function BannerSecTextField({bannerText, setBannerText, bannerTextNumber}: Props) {

    return (
        <AdditionalInputText
            bannerText={bannerText}
            bannerTextNumber={bannerTextNumber}
            setBannerText={setBannerText}
            additionText={
                <span className={bannerCreator.lowerText}>
                        * 배너 하단문구 : 40px(PC)/28px(MO), Bold체 <br/>
                        * 배너 문구는 1과 2중 하나만 작성해도 등록 가능함.
                    </span>
            }
        />
    );
};