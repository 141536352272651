import { useAppDispatch } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useCallback } from "react";
import { PartnerCompanyPostVO, PartnerCompanyType } from "../../../../types/partnerCompany";
import { createPartnerCompanyAsync, setIsPartnerDataSaved } from "../../../../store/partnerCompanySlice";
import { useFile } from "../../../../hooks/file/useFile";
import { FileClassification, FileResponse } from "../../../../types/file";

export function usePartnerCompanyCreator() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { uploadImages } = useFile();

  const createPartner = useCallback(
    async (param: PartnerCompanyPostVO) => {
      try {
        const files: FileResponse[] = await uploadImages([param.logo.file], FileClassification.PARTNER_COMPANY_LOGO);
        const isDomestic = param.type === PartnerCompanyType.DOMESTIC;
        await dispatch(
          createPartnerCompanyAsync({
            name: param.name.trim(),
            type: param.type,
            status: param.status,
            logo: {
              uri: files[0].uri,
              fileIdx: files[0].idx,
            },
            address: isDomestic ? param.address : undefined,
            webSite: isDomestic ? param.webSite?.trim() : undefined,
            affiliateCodeIdx: isDomestic ? param.affiliateCodeIdx : undefined,
          })
        ).unwrap();
        dispatch(setIsPartnerDataSaved(true));
        alert("협력사가 등록 되었습니다.");
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("협력사 등록 에러");
      }
    },
    [dispatch, defaultErrorMessage, uploadImages]
  );

  return {
    createPartner,
  };
}
