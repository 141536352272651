import { UserVO } from "../../../../types/user";
import style from "../memberUserDetail.module.scss";
import { RoleChecker } from "../../../role/RoleChecker";
import { AdminMenuCode } from "../../../../types/adminRole";
import { AnchorComponent } from "../../../../layout/content/AnchorComponent";
import { CopiableText } from "../../../../components/CopiableText";
import React, { useCallback } from "react";
import { useNewWindow } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";

export function useUserDetailValues() {
  const { t } = useTranslation("user");
  const { openNewWindowWithURL } = useNewWindow();

  const OrganListValue = useCallback(
    ({ user, isEditMode }: { user: UserVO; isEditMode: boolean }) => {
      return (
        <ul className={style.listElement}>
          {!user.organList || user.organList.length === 0
            ? "-"
            : user.organList.map((organ) => (
                <li key={`organ-idx-${organ.idx}`}>
                  <RoleChecker
                    menuCode={AdminMenuCode.USER_DETAIL_ORGAN_READ}
                    alternativeChildren={<>{organ.organName}</>}
                  >
                    {isEditMode ? (
                      <>{organ.organName}</>
                    ) : (
                      <AnchorComponent
                        onClickFunc={(e) => {
                          e.preventDefault();
                          openNewWindowWithURL(
                            `/detail/member/organ-manager/${organ.organManagerIdx}?tab=ORGAN&organ=${organ.idx}`,
                            {
                              width: 1000,
                              height: 800,
                            }
                          );
                        }}
                        content={organ.organName}
                      />
                    )}
                  </RoleChecker>
                </li>
              ))}
        </ul>
      );
    },
    [openNewWindowWithURL]
  );

  const OrganVoucherValue = useCallback(
    ({ user, isEditMode }: { user: UserVO; isEditMode: boolean }) => {
      return (
        <ul>
          {!user.voucherCountFromOrganList || user.voucherCountFromOrganList.length === 0
            ? "-"
            : user.voucherCountFromOrganList.map((voucherInfo, idx) => (
                <li key={`voucher-${idx}`}>
                  <span style={{ marginRight: "10px" }}>
                    1 {t("unit")} ({voucherInfo.organName})
                  </span>
                  <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_ORGAN_CODE_READ}>
                    {isEditMode ? (
                      <>{user.organList.find((o) => o.idx === voucherInfo.organIdx)?.signInCode ?? ""}</>
                    ) : (
                      <CopiableText
                        text={user.organList.find((o) => o.idx === voucherInfo.organIdx)?.signInCode ?? ""}
                        isBlue={true}
                      />
                    )}
                  </RoleChecker>
                </li>
              ))}
        </ul>
      );
    },
    [t]
  );

  return {
    OrganVoucherValue,
    OrganListValue,
  };
}
