import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { revokePhoneNumberAuthenticationAsync } from "../../../../store/organManagerSlice";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useOrganManagerPhoneNumberRevoker() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const revokePhoneNumberAuthentication = useCallback(
    (organManagerIdx: number, onSuccess: () => void) => {
      callAsync(
        dispatch(revokePhoneNumberAuthenticationAsync(organManagerIdx)).unwrap(),
        () => onSuccess(),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );

  return {
    revokePhoneNumberAuthentication,
  };
}
