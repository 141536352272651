import { AddedFile, FileType } from "../../../types/file";
import style from "../../product/edit/productEditForm.module.scss";
import { AnImageAddition } from "../../../components/AnImageAddition";

interface Props {
  logo?: AddedFile;
  setLogo: (_: AddedFile | undefined) => void;
  addedLogo?: FileType;
  setAddedLogo: (_: FileType | undefined) => void;
}
export function PartnerLogoField({ logo, setLogo, addedLogo, setAddedLogo }: Props) {
  return (
    <AnImageAddition
      buttonStyleObject={{ width: "150px", height: "150px" }}
      image={logo}
      setImage={setLogo}
      addedImage={addedLogo}
      setAddedImage={setAddedLogo}
      additionalText={
        <span className={style.text}>
          * 로고 이미지는 가로:세로 1:1 비율로 제작해 주세요.
          <br />
          * 권장 이미지 : 1M 이하 / gif, png, jpg(jpeg)
          <br />* 이미지 크기 : 300px*300px
        </span>
      }
    />
  );
}
