import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Anchor } from "../../../components/Anchor";
import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";

export function ArticleAnchors({ id, title }: ArticleProps) {
  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={`<Anchor>Anchor</Anchor>`}>
          <Anchor>Anchor</Anchor>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
