import { useUtil } from "../../../../util/hooks/useUtil";
import { useMemo, useState } from "react";
import { DEFAULT_PAGE, DEFAULT_SIZE, ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../types/page";
import {
  AffiliateCodeListVO,
  AffiliateCodeSearchCondition,
  AffiliateCodeSearchWordType,
  AffiliateCodeStatus,
} from "../../../../types/affiliateCode";
import { useAffiliateCodesGetter } from "./useAffiliateCodesGetter";

interface Args {
  organManagerIdx?: number;
  status?: AffiliateCodeStatus;
  hasPartnerCompany?: boolean;
  organManagerNameQuery?: string;
}

export function useConditionalAffiliateCodeList({
  organManagerIdx,
  status,
  hasPartnerCompany,
  organManagerNameQuery,
}: Args) {
  const { defaultErrorMessage } = useUtil();
  const { getAffiliateCodes } = useAffiliateCodesGetter();

  const [codeList, setCodeList] = useState<PageResponse<AffiliateCodeListVO>>();

  const pageCondition: PageSearchCondition = useMemo(
    () => ({
      page: codeList?.number ?? DEFAULT_PAGE,
      size: codeList?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(codeList?.orders),
    }),
    [codeList]
  );

  const getCodeList = ({ page, sort, size }: PageSearchCondition) => {
    const param: AffiliateCodeSearchCondition = {
      organManagerIdx,
      hasPartnerCompany,
      status,
      q: organManagerNameQuery?.trim(),
      qtype: organManagerNameQuery?.trim() ? AffiliateCodeSearchWordType.NAME : undefined,
      pageCondition: {
        page: page ?? pageCondition.page,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      },
    };

    getAffiliateCodes(param)
      .then((response) => setCodeList(response))
      .catch(() => alert(defaultErrorMessage));
  };

  return {
    getCodeList,
    codeList,
  };
}
