import {SliceState, Status} from "../types/common";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {addCases, executePromise} from "../util/sliceUtil";
import {VoucherApi} from "../api/VoucherApi";
import {RootState} from "./index";
import {
  CollectVoucherRequestParam,
  GrantOrganManagerVoucherRequestParam,
  GrantVoucherRequestParam,
  OrganManagerVoucherSearchCondition,
  OrganManagerVoucherStatus, OrganManagerVoucherStatusDTO,
  OrganVoucherNewStatus,
  OrganVoucherStatusInfo,
  OrganVoucherStatusVO,
  ProfileVoucherHistorySearchCondition,
  VoucherHistorySearchCondition,
  VoucherStatusInfo, VoucherStatusNewDTO,
} from "../types/voucher";

export interface VoucherState extends SliceState {
  voucherStatusInfo?: VoucherStatusInfo;
  voucherStatusNewDTO?: VoucherStatusNewDTO;
  organVoucherNewStatus?: OrganVoucherNewStatus;
  organVoucherStatusInfo?: OrganVoucherStatusInfo;
  organManagerVoucherStatus?: OrganManagerVoucherStatus;
  organManagerVoucherStatusDTO?: OrganManagerVoucherStatusDTO;
  profileOrganVoucherStatus?: OrganVoucherStatusVO;
  organVoucherStatusProfileIdx?: number;
}

const initialState: VoucherState = {
  status: Status.IDLE,
};

export const getVoucherCurrentStatusAsync = createAsyncThunk("voucher/getCurrentStatus", (userIdx: number) =>
  executePromise(VoucherApi.getCurrentStatus(userIdx))
);

export const getUserOrganVoucherCurrentStatusAsync = createAsyncThunk(
  "voucher/getUserOrganVoucherCurrentStatus",
  (userIdx: number) => executePromise(VoucherApi.getUserOrganVoucherCurrentStatus(userIdx))
);

export const getProfileOrganVoucherCurrentStatusAsync = createAsyncThunk(
  "voucher/getProfileOrganVoucherCurrentStatus",
  (profileIdx: number) => executePromise(VoucherApi.getProfileOrganVoucherCurrentStatus(profileIdx))
);

export const getVoucherHistoryListAsync = createAsyncThunk(
  "voucher/getVoucherHistoryList",
  (condition: VoucherHistorySearchCondition) => executePromise(VoucherApi.getVoucherHistoryList(condition))
);

export const getOrganVoucherHistoryListAsync = createAsyncThunk(
  "voucher/getOrganVoucherHistoryList",
  (condition: VoucherHistorySearchCondition) => executePromise(VoucherApi.getOrganVoucherHistoryList(condition))
);

export const getProfileOrganVoucherHistoryListAsync = createAsyncThunk(
  "voucher/getProfileOrganVoucherHistoryList",
  (condition: ProfileVoucherHistorySearchCondition) =>
    executePromise(VoucherApi.getProfileOrganVoucherHistoryList(condition))
);

export const grantVoucherAsync = createAsyncThunk("voucher/grantVoucher", (param: GrantVoucherRequestParam) =>
  executePromise(VoucherApi.grantVoucher(param))
);

export const grantOrganManagerVoucherAsync = createAsyncThunk(
  "voucher/grantOrganManagerVoucher",
  (param: GrantOrganManagerVoucherRequestParam) => executePromise(VoucherApi.grantOrganManagerVoucher(param))
);

export const collectVoucherAsync = createAsyncThunk(
  "voucher/collectVoucher",
  ({ voucherIdx, param }: { voucherIdx: number; param: CollectVoucherRequestParam }) =>
    executePromise(VoucherApi.collectVoucher(voucherIdx, param))
);

export const getVoucherDetailAsync = createAsyncThunk("voucher/getVoucherDetail", (idx: number) =>
  executePromise(VoucherApi.getVoucherDetail(idx))
);

export const getVouchersByOrderAsync = createAsyncThunk("voucher/getVouchersByOrder", (idx: number) =>
  executePromise(VoucherApi.getVouchersByOrder(idx))
);

export const getOrganManagerVoucherStatusAsync = createAsyncThunk(
  "voucher/getOrganManagerVoucherStatus",
  (organManagerIdx: number) => executePromise(VoucherApi.getOrganManagerVoucherStatus(organManagerIdx))
);

export const getOrganManagerVouchersAsync = createAsyncThunk(
  "voucher/getOrganManagerVouchersAsync",
  ({ organManagerIdx, condition }: { organManagerIdx: number; condition: OrganManagerVoucherSearchCondition }) =>
    executePromise(VoucherApi.getOrganManagerVouchers(organManagerIdx, condition))
);

export const getVoucherHistoriesAsync = createAsyncThunk(
  "voucher/getVoucherHistoriesAsync",
  (voucherHistorySetIdx: number) => executePromise(VoucherApi.getVoucherHistories(voucherHistorySetIdx))
);

export const collectOrganVoucherAsync = createAsyncThunk(
  "voucher/collectOrganVoucher",
  ({ voucherIdx, organManagerIdx }: { voucherIdx: number; organManagerIdx: number }) =>
    executePromise(VoucherApi.collectOrganVoucher(voucherIdx, organManagerIdx))
);

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    setVoucherNewStatusInfo: (state, action: PayloadAction<VoucherStatusNewDTO>) => {
      state.voucherStatusNewDTO = action.payload;
    },
    setOrganVoucherStatusInfo: (state, action: PayloadAction<OrganVoucherStatusInfo>) => {
      state.organVoucherStatusInfo = action.payload;
    },
    setOrganVoucherNewStatus: (state, action: PayloadAction<OrganVoucherNewStatus>) => {
      state.organVoucherNewStatus = action.payload;
    },
    setOrganManagerVoucherStatus: (state, action: PayloadAction<OrganManagerVoucherStatus>) => {
      state.organManagerVoucherStatus = action.payload;
    },
    setOrganManagerVoucherStatusDTO: (state, action: PayloadAction<OrganManagerVoucherStatusDTO>) => {
      state.organManagerVoucherStatusDTO = action.payload;
    },
    setProfileOrganVoucherStatus: (state, action: PayloadAction<OrganVoucherStatusVO>) => {
      state.profileOrganVoucherStatus = action.payload;
    },
    setOrganVoucherStatusProfileIdx: (state, action: PayloadAction<number | undefined>) => {
      state.organVoucherStatusProfileIdx = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getVoucherCurrentStatusAsync);
    addCases(builder, grantVoucherAsync);
    addCases(builder, grantOrganManagerVoucherAsync);
    addCases(builder, collectVoucherAsync);
    addCases(builder, getVoucherDetailAsync);
    addCases(builder, getVoucherHistoryListAsync);
    addCases(builder, getVouchersByOrderAsync);
    addCases(builder, getOrganVoucherHistoryListAsync);
    addCases(builder, getOrganManagerVoucherStatusAsync);
    addCases(builder, getOrganManagerVouchersAsync);
    addCases(builder, getVoucherHistoriesAsync);
    addCases(builder, collectOrganVoucherAsync);
  },
});

export default voucherSlice.reducer;

export const {
  setVoucherNewStatusInfo,
  setOrganVoucherStatusInfo,
  setOrganVoucherNewStatus,
  setOrganManagerVoucherStatusDTO,
} = voucherSlice.actions;

export const voucherStatus = (state: RootState) => state.voucher.status;
export const voucherStatusNewDTO = (state: RootState) => state.voucher.voucherStatusNewDTO;
export const organVoucherNewStatus = (state: RootState) => state.voucher.organVoucherNewStatus;
export const organVoucherStatusInfo = (state: RootState) => state.voucher.organVoucherStatusInfo;
export const organManagerVoucherStatusDTO = (state: RootState) => state.voucher.organManagerVoucherStatusDTO;
export const organVoucherStatusProfileIdx = (state: RootState) => state.voucher.organVoucherStatusProfileIdx;
