import { useCallback } from "react";
import { SettlementStatus } from "../../../../types/affiliateCode";
import styled from "styled-components/macro";
import { useAffiliateCodeType } from "../../../../types/hooks/useAffiliateCodeType";
import { CenterTextStyle } from "../../../../components/Texts";

const RedCenterTextStyle = styled(CenterTextStyle)`
  color: ${(props) => props.theme.color.FGPointRed};
`;

const BlueCenterTextStyle = styled(CenterTextStyle)`
  color: ${(props) => props.theme.color.FGBlue};
`;

export function useSettlementStatusTextElement() {
  const { getSettlementStatusLabel } = useAffiliateCodeType();

  const getSettlementStatusLabelElement = useCallback(
    (status: SettlementStatus) => {
      if (status === SettlementStatus.COMPLETE) {
        return <BlueCenterTextStyle>{getSettlementStatusLabel(status)}</BlueCenterTextStyle>;
      } else {
        return <RedCenterTextStyle>{getSettlementStatusLabel(status)}</RedCenterTextStyle>;
      }
    },
    [getSettlementStatusLabel]
  );

  return {
    getSettlementStatusLabelElement,
  };
}
