import { useOrderFilterValues } from "./hooks/useOrderFilterValues";
import { OrderList } from "./OrderList";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function OrderListWrapper() {
  const { pageInfoOnFilter, filterValues, searchList, listQueryType } = useOrderFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <OrderList />
    </ListFilterValuesContext.Provider>
  );
}
