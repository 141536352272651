import style from "../productEditForm.module.scss";
import {ProductDiscountType} from "../../../../types/product";
import {FilterNumber} from "../../../../layout/content/list/filter/FilterNumber";
import {FilterContentType} from "../../../../types/tableFilter";
import React, {useMemo} from "react";
import {Label104x46} from "../../../../components/Label";
import {Radio, RadioGroup} from "../../../../components/RadioGroup";
import {useTranslation} from "react-i18next";
import {useProductType} from "../../../../types/hooks/useProductType";
import {useLanguage} from "../../../../hooks/hooks";
import {Languages} from "../../../../types/common";

interface Props {
  discountType: ProductDiscountType;
  setDiscountType: (_: ProductDiscountType) => void;
  discountValue: string;
  setDiscountValue: (_: string) => void;
  language: Languages;
}

export function ProductEditDiscount({ discountType, setDiscountType, discountValue, setDiscountValue, language }: Props) {
  const { t } = useTranslation(["product", "common"]);
  const { toStringFromProductDiscountType } = useProductType();
  const radios: Radio<ProductDiscountType>[] = [
    {
      check: (type) => type === ProductDiscountType.NONE,
      onClickFunc: () => {
        setDiscountType(ProductDiscountType.NONE);
        setDiscountValue("");
      },
      text: toStringFromProductDiscountType(ProductDiscountType.NONE),
    },
    {
      check: (type) => type === ProductDiscountType.PERCENT,
      onClickFunc: () => {
        setDiscountType(ProductDiscountType.PERCENT);
        setDiscountValue("");
      },
      text: toStringFromProductDiscountType(ProductDiscountType.PERCENT),
    },
    {
      check: (type) => type === ProductDiscountType.AMOUNT,
      onClickFunc: () => {
        setDiscountType(ProductDiscountType.AMOUNT);
        setDiscountValue("");
      },
      text: toStringFromProductDiscountType(ProductDiscountType.AMOUNT),
    },
  ];
  const { isKorean } = useLanguage();

  const isNoneType = useMemo(() => discountType === ProductDiscountType.NONE, [discountType]);
  const isPercentDiscountType = useMemo(() => discountType === ProductDiscountType.PERCENT, [discountType]);
  const isAmountDiscountType = useMemo(() => discountType === ProductDiscountType.AMOUNT, [discountType]);

  return (
    <div className={`${style.field} ${style.alignCenter}`}>
      <Label104x46 text={t("edit.discount.title")} className={style.label} />
      <RadioGroup
        radios={radios}
        isRequired={true}
        groupName={"discountType"}
        selectedValue={discountType}
      ></RadioGroup>
      <div className={style.discountValueField}>
        {isNoneType ? (
          <></>
        ) : (
          <>
            <FilterNumber
              numberField={{
                type: FilterContentType.NUMBER,
                value: discountValue,
                placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
                onChangeFunc: (v: string) => setDiscountValue(v),
                isRequired: true,
              }}
              className={isKorean ? "" : style.discountFieldNotKO}
            />
            {isPercentDiscountType ? t("edit.discount.percent")
                // 할인금액인 경우에 한국어이면 원 할인 / 아니면 달러 할인 처리
                : isAmountDiscountType
                    ? language === Languages.KO
                        ?  t("edit.discount.amount") : t("edit.discount.usAmount")
                        : ""}
          </>
        )}
      </div>
    </div>
  );
}
