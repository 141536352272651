import { Selector } from "./Selector";
import { SelectorOption } from "../../../types/selector";
import { Trans, useTranslation } from "react-i18next";
import React, { useCallback } from "react";

interface Props {
  pageSize: number;
  setPageSize: (_: number) => void;
}

export function PageSizeSelector({ pageSize, setPageSize }: Props) {
  const { t } = useTranslation("common");

  const PageSizeNum = useCallback(({ num }: { num: number }) => <>{num}</>, []);
  const OptionLabel = useCallback(
    ({ pageSize }: { pageSize: number }) => (
      <Trans t={t} i18nKey={"pageSize.label"} components={[<PageSizeNum num={pageSize} />]} />
    ),
    [PageSizeNum, t]
  );

  const options = [
    { value: "20", label: <OptionLabel pageSize={20} /> },
    { value: "30", label: <OptionLabel pageSize={30} /> },
    { value: "40", label: <OptionLabel pageSize={40} /> },
    { value: "50", label: <OptionLabel pageSize={50} /> },
    { value: "100", label: <OptionLabel pageSize={100} /> },
    { value: "200", label: <OptionLabel pageSize={200} /> },
    { value: "500", label: <OptionLabel pageSize={500} /> },
  ];

  return (
    <Selector
      isPageSizeSelector={true}
      selectedValue={pageSize.toString()}
      setSelectorOption={(option: SelectorOption) => setPageSize(Number(option.value))}
      options={options}
    />
  );
}
