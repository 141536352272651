import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../layout/content/detail/Panel";
import { ContentTable } from "../../../layout/content/detail/ContentTable";
import React, { useEffect, useMemo, useState } from "react";
import { UnitItemDetail } from "../../../types/unitItem";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector, useTimeConverter } from "../../../hooks/hooks";
import { callAsync } from "../../../util/sliceUtil";
import { getUnitItemDetailAsync, unitItemStatus } from "../../../store/unitItemSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { Table } from "../../../layout/content/list/table/Table";
import { useLinkedProductsList } from "./useLinkedProductsList";
import { useLinkedProductsColumns } from "./useLinkedProductsColumns";
import { Pagination } from "../../../layout/content/list/Pagination";
import { PaginationWrapper } from "../../../layout/content/list/PaginationWrapper";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ValidDurationValue } from "../modal/ValidDurationValue";
import { useUnitItemType } from "../../../types/hooks/useUnitItemType";
import { useUtil } from "../../../util/hooks/useUtil";

const LeftAlignTextStyle = styled.div`
  width: 100%;
  text-align: left;
`;

export function LinkedProducts() {
  const { t } = useTranslation(["product", "common"]);
  const dispatch = useAppDispatch();
  const { timeConverter } = useTimeConverter();
  const { defaultErrorMessage } = useUtil();
  const { toStringFromUnitItemType } = useUnitItemType();

  const status = useAppSelector(unitItemStatus);
  useLoader({ status });

  const { id } = useParams<"id">();
  const unitItemIdx = useMemo(() => Number(id), [id]);

  const [unitItemDetail, setUnitItemDetail] = useState<UnitItemDetail>();

  const { pageCondition, getLinkedProducts, linkedProducts } = useLinkedProductsList({ unitItemIdx });
  const columns = useLinkedProductsColumns({
    pageCondition,
    getLinkedProducts: getLinkedProducts(pageCondition),
  });

  useEffect(() => {
    callAsync<UnitItemDetail>(
      dispatch(getUnitItemDetailAsync(unitItemIdx)).unwrap(),
      (result) => setUnitItemDetail(result),
      () => alert(defaultErrorMessage)
    ).then();

    getLinkedProducts({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitItemIdx, defaultErrorMessage]);

  return (
    <PanelWrapper>
      <>
        <Panel title={t("linkedProduct.unitItem.title")}>
          <>
            {unitItemDetail ? (
              <ContentTable
                data={{
                  header: [
                    t("linkedProduct.unitItem.createdAt"),
                    t("linkedProduct.unitItem.type"),
                    t("linkedProduct.unitItem.name"),
                    t("linkedProduct.unitItem.availableUntil"),
                    t("linkedProduct.unitItem.products"),
                  ],
                  contents: [
                    [
                      timeConverter.convertToLocalTime(unitItemDetail.createAt),
                      toStringFromUnitItemType(unitItemDetail.type.code),
                      <LeftAlignTextStyle>{unitItemDetail.name}</LeftAlignTextStyle>,
                      <ValidDurationValue from={unitItemDetail.validDurationFrom} day={unitItemDetail.validDuration} />,
                      unitItemDetail.productItemCnt.toString(),
                    ],
                  ],
                }}
              />
            ) : (
              <></>
            )}
          </>
        </Panel>
        <Panel title={t("linkedProduct.list.title")}>
          {linkedProducts ? (
            <>
              <Table
                listData={linkedProducts}
                columns={columns}
                getList={getLinkedProducts}
                noDataText={t("noSearchResult", { ns: "common" })}
              />
              <PaginationWrapper>
                <Pagination
                  page={linkedProducts}
                  goSpecificPage={(pageNum: number) => getLinkedProducts({ page: pageNum })()}
                />
              </PaginationWrapper>
            </>
          ) : (
            <></>
          )}
        </Panel>
      </>
    </PanelWrapper>
  );
}
