import { useAppDispatch } from "../../../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../../../util/sliceUtil";
import { updateUserRefundAccount } from "../../../../../../store/userSlice";
import { CustomerType } from "../../../../../../types/common";
import { updateOrganManagerRefundAccountAsync } from "../../../../../../store/organManagerSlice";
import { useUtil } from "../../../../../../util/hooks/useUtil";

export interface RefundAccountPutDTO {
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
}

export function useRefundAccountModifier() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const modifyUserRefundAccount = useCallback(
    (userIdx: number, refundAccountPutDTO: RefundAccountPutDTO, onSuccess: () => void) => {
      callAsync(
        dispatch(updateUserRefundAccount({ userIdx, refundAccountPutDTO })).unwrap(),
        () => onSuccess(),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );

  const modifyOrganManagerRefundAccount = useCallback(
    (organManagerIdx: number, refundAccountPutDTO: RefundAccountPutDTO, onSuccess: () => void) => {
      callAsync(
        dispatch(updateOrganManagerRefundAccountAsync({ organManagerIdx, refundAccountPutDTO })).unwrap(),
        () => onSuccess(),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );

  return useCallback(
    (
      customerIdx: number,
      customerType: CustomerType,
      refundAccountPutDTO: RefundAccountPutDTO,
      onSuccess: () => void
    ) => {
      if (customerType === CustomerType.USER) modifyUserRefundAccount(customerIdx, refundAccountPutDTO, onSuccess);
      else modifyOrganManagerRefundAccount(customerIdx, refundAccountPutDTO, onSuccess);
    },
    [modifyUserRefundAccount, modifyOrganManagerRefundAccount]
  );
}
