import React, { useEffect } from "react";
import { CLICK_DOCUMENT_EVENT, publish } from "../../util/events";

interface SelectorClickContextType {
  subscribe: (eventName: string, listener: (_: any) => void) => void;
  unsubscribe: (eventName: string, listener: (_: any) => void) => void;
}

export const SelectorClickEventContext = React.createContext<SelectorClickContextType>(null!);

export function SelectorClickEventProvider({ children }: { children: JSX.Element }) {
  const subscribe = (eventName: string, listener: (_: any) => void) => {
    document.addEventListener(eventName, listener);
  };

  const unsubscribe = (eventName: string, listener: (_: any) => void) => {
    document.removeEventListener(eventName, listener);
  };

  useEffect(() => {
    const clickEvent = (e: MouseEvent) => {
      publish(CLICK_DOCUMENT_EVENT, e.target);
    };

    window.addEventListener("click", clickEvent);

    return () => {
      window.removeEventListener("click", clickEvent);
    };
  }, []);

  return (
    <SelectorClickEventContext.Provider
      value={{
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </SelectorClickEventContext.Provider>
  );
}
