import styled from "styled-components";

export const OrderItemTitleRowStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > img {
    width: 50px;
    height: 50px;
  }

  text-align: left;
  position: relative;
`;
