import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { PopupPostParam, PopupPutDTO, PopupSearchCondition, PopupStatusUpdateParam } from "../types/popup";
import { PopupApi } from "../api/PopupApi";
import { RootState } from "./index";

export interface PopupState extends SliceState {
  isPopupDataSaved: boolean;
}

const initialState: PopupState = {
  status: Status.IDLE,
  isPopupDataSaved: false,
};

export const createPopupAsync = createAsyncThunk("popup/createPopup", (param: PopupPostParam) =>
  executePromise(PopupApi.createPopup(param))
);

export const getPopupsAsync = createAsyncThunk("popup/getPopups", (condition: PopupSearchCondition) =>
  executePromise(PopupApi.getPopups(condition))
);

export const deletePopupsAsync = createAsyncThunk("popup/deletePopups", (idxes: number[]) =>
  executePromise(PopupApi.deletePopups(idxes))
);

export const getPopupDetailAsync = createAsyncThunk("popup/getPopupDetail", (idx: number) =>
  executePromise(PopupApi.getPopup(idx))
);

export const updatePopupStatusAsync = createAsyncThunk("popup/updatePopupStatus", (param: PopupStatusUpdateParam) =>
  executePromise(PopupApi.updatePopupStatus(param))
);

export const updatePopupAsync = createAsyncThunk("", ({ popupIdx, param }: { popupIdx: number; param: PopupPutDTO }) =>
  executePromise(PopupApi.updatePopup(popupIdx, param))
);

export const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    setIsPopupDataSaved: (state, action: PayloadAction<boolean>) => {
      state.isPopupDataSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, createPopupAsync);
    addCases(builder, getPopupsAsync);
    addCases(builder, deletePopupsAsync);
    addCases(builder, updatePopupStatusAsync);
    addCases(builder, getPopupDetailAsync);
    addCases(builder, updatePopupAsync);
  },
});

export default popupSlice.reducer;

export const { setIsPopupDataSaved } = popupSlice.actions;
export const popupStatusState = (state: RootState) => state.popup.status;
export const isPopupDataSavedState = (state: RootState) => state.popup.isPopupDataSaved;
