import style from '../../../layout/content/post/creator.module.scss';

interface Props {
    name: string;
    setName: (_: string) => void;
}
export default function BannerNameField({name, setName} : Props) {

    return(
        <>
            <input
                className={style.inputField}
                value={name}
                required={true}
                onChange={(e) => setName(e.target.value)}
                placeholder={"등록할 배너명 입력"}
            />
        </>
    )
};