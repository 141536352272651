import { UnitItemType } from "../../../types/unitItem";
import { FilterContentType, TableFilter } from "../../../types/tableFilter";
import { useLanguage, useListFilterValues, useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useUnitItemType } from "../../../types/hooks/useUnitItemType";
import { useMemo } from "react";

interface Args {
  search: () => void;
}
export function useUnitItemFilters({ search }: Args): { filters: TableFilter[][] } {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation("unitItem");
  const { timeConverter } = useTimeConverter();
  const { unitItemOptions } = useUnitItemType();
  const { isKorean } = useLanguage();

  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const createdAtFromFilter = useMemo(
    () => filterValues.createdAtFromFilterValue,
    [filterValues.createdAtFromFilterValue]
  );
  const createdAtToFilter = useMemo(() => filterValues.createdAtToFilterValue, [filterValues.createdAtToFilterValue]);
  const unitItemTypeFilter = useMemo(
    () => filterValues.unitItemTypeFilterValue,
    [filterValues.unitItemTypeFilterValue]
  );
  const productCountFromFilter = useMemo(
    () => filterValues.productCountFromFilterValue,
    [filterValues.productCountFromFilterValue]
  );
  const productCountToFilter = useMemo(
    () => filterValues.productCountToFilterValue,
    [filterValues.productCountToFilterValue]
  );

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.search.label"),
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter.value,
          maxLength: 100,
          placeholderVal: t("list.filter.search.placeholder"),
          onChangeFunc: (v) => searchWordFilter.setter(v),
          onEnterKeyUpFunc: search,
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.createdAt"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtFromFilter.value),
            onChangeFunc: (v: string) =>
              createdAtFromFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtToFilter.value),
            onChangeFunc: (v: string) =>
              createdAtToFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
          },
        },
      },
      {
        labelTitle: t("list.filter.type"),
        content: {
          type: FilterContentType.SELECT,
          value: unitItemTypeFilter.value,
          options: unitItemOptions,
          onChangeFunc: (v) => unitItemTypeFilter.setter(v as UnitItemType | ""),
          width: isKorean ? undefined : 200,
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.product"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: productCountFromFilter.value,
            min: 0,
            onChangeFunc: (v) => productCountFromFilter.setter(v),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: productCountToFilter.value,
            min: 0,
            onChangeFunc: (v) => productCountToFilter.setter(v),
          },
        },
      },
    ],
  ];

  return {
    filters,
  };
}
