import {RadioGroupField} from "../../../components/RadioGroupField";
import {WebsiteType} from "../../../types/common";
import {useBannerType} from "../../../types/hooks/useBannerType";
import {BannerType} from "../../../types/banner";

interface Props {
    bannerType: BannerType;
    setBannerType: (_: BannerType) => void;
    websiteType: WebsiteType;
}

export default function BannerTypeField({bannerType, setBannerType, websiteType}: Props) {
    const {getBannerTypeLabel} = useBannerType();

    const newBannerType = websiteType === WebsiteType.KPASS
        ? [BannerType.KOREAN, BannerType.ENGLISH, BannerType.RUSSIA]
        : [BannerType.KOREAN, BannerType.ENGLISH, BannerType.TAIWAN]

    return (
        <>
            <RadioGroupField enumValues={newBannerType}
                             getLabel={getBannerTypeLabel}
                             value={bannerType} setValue={setBannerType}
                             groupName={"bannerType"} isRequired={true}
            />
        </>
    );
};