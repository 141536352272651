import { useCallback } from "react";
import { callAsync } from "../../../util/sliceUtil";
import {
  deleteCouponAsync,
  deleteCouponCompletelyAsync,
  resumeIssuingCouponAsync,
  stopIssuingCouponAsync,
} from "../../../store/couponSlice";
import { useAppDispatch } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Args {
  onCompletedStopIssuing: () => void;
  onCompletedResumeIssuing: () => void;
  onCompletedDeleteOnlyCoupon: () => void;
  onCompletedDeleteCouponCompletely: () => void;
}

export function useCouponActions({
  onCompletedStopIssuing,
  onCompletedResumeIssuing,
  onCompletedDeleteCouponCompletely,
  onCompletedDeleteOnlyCoupon,
}: Args) {
  const { t } = useTranslation("coupon");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const stopIssuingCoupons = useCallback(
    (couponIdxArr: number[]) => {
      const isConfirmed = window.confirm(t("stopIssuing.confirm"));

      if (isConfirmed) {
        callAsync(
          dispatch(stopIssuingCouponAsync(couponIdxArr)).unwrap(),
          () => onCompletedStopIssuing(),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, onCompletedStopIssuing, t, defaultErrorMessage]
  );

  const resumeIssuingCoupons = useCallback(
    (couponIdxArr: number[]) => {
      const isConfirmed = window.confirm(t("reissue.confirm"));

      if (isConfirmed) {
        callAsync(
          dispatch(resumeIssuingCouponAsync(couponIdxArr)).unwrap(),
          () => onCompletedResumeIssuing(),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, onCompletedResumeIssuing, t, defaultErrorMessage]
  );

  const deleteCoupons = useCallback(
    (couponIdxArr: number[]) => {
      const isConfirmed = window.confirm(t("delete.confirm"));

      if (isConfirmed) {
        callAsync(
          dispatch(deleteCouponAsync(couponIdxArr)).unwrap(),
          () => onCompletedDeleteOnlyCoupon(),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, onCompletedDeleteOnlyCoupon, t, defaultErrorMessage]
  );

  const deleteCouponsCompletely = useCallback(
    (couponIdxArr: number[]) => {
      const isConfirmed = window.confirm(t("deleteCompletely.confirm"));

      if (isConfirmed) {
        callAsync(
          dispatch(deleteCouponCompletelyAsync(couponIdxArr)).unwrap(),
          () => onCompletedDeleteCouponCompletely(),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, onCompletedDeleteCouponCompletely, t, defaultErrorMessage]
  );

  return {
    stopIssuingCoupons,
    resumeIssuingCoupons,
    deleteCoupons,
    deleteCouponsCompletely,
  };
}
