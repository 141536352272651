import { Selector } from "../layout/content/selector/Selector";
import { useCallback, useMemo } from "react";
import leftArrow from "../assets/images/button_icons/left_arrow.svg";
import rightArrow from "../assets/images/button_icons/right_arrow.svg";
import styled from "styled-components/macro";
import { SELECTED_VALUE, SELECTOR_AREA, SELECTOR_ARROW } from "../util/etcUtil";
import { SelectorOption } from "../types/selector";

const YearAndMonthWrapperStyle = styled.div`
  width: 100%;
  margin: 20px 0;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;

const ChangeMonthButtonStyle = styled.button`
  width: 60px;
  height: 60px;

  &.noButton {
    cursor: default;
  }
`;

const TextStyle = styled.span`
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.6px;
  color: ${(props) => props.theme.color.FGDarkBlue};
`;

const SelectorsWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const SelectorAndLabelStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .selector {
    height: 60px;
    background: white;

    [data-name="${SELECTOR_AREA}"] {
      padding: 4px 16px;
      gap: 10px;
    }

    [data-name="${SELECTED_VALUE}"] {
      font-size: 32px;
      font-weight: bold;
      color: ${(props) => props.theme.color.FGDarkBlue};
    }

    [data-name="${SELECTOR_ARROW}"] {
      width: 30px;
      height: 30px;
    }
  }

  .year {
    width: 150px;
  }

  .month {
    width: 114px;
  }
`;

interface Props {
  startYear: number;
  startMonth: number;
  year: number;
  month: number;
  setYearAndMonth: (y: number, m: number) => void;
}

const MONTHS_A_YEAR = 12;

interface YearAndMonths {
  year: number;
  months: number[];
}

export function YearAndMonth({ startYear, startMonth, year, month, setYearAndMonth }: Props) {
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const currentMonth = useMemo(() => new Date().getMonth() + 1, []);

  const getMonthsByYear = useCallback(
    (thisYear: number) => {
      switch (true) {
        case thisYear === startYear:
          return Array.from(Array(MONTHS_A_YEAR - startMonth + 1), (_, index) => startMonth + index);
        case thisYear === currentYear:
          return Array.from(Array(currentMonth), (_, index) => index + 1);
        default:
          return Array.from(Array(MONTHS_A_YEAR), (_, index) => index + 1);
      }
    },
    [startYear, currentYear, startMonth, currentMonth]
  );

  const yearAndMonthsData: YearAndMonths[] = useMemo(() => {
    return Array.from(Array(currentYear - startYear + 1), (_, index) => {
      const thisYear = startYear + index;

      return {
        year: thisYear,
        months: getMonthsByYear(thisYear),
      };
    });
  }, [getMonthsByYear, currentYear, startYear]);

  const yearOptions: SelectorOption[] = useMemo(() => {
    return yearAndMonthsData.map((ym) => {
      const yearString = ym.year.toString();
      return {
        value: yearString,
        label: yearString,
      };
    });
  }, [yearAndMonthsData]);

  const getMonthsByYearFromData = useCallback(
    (thisYear: number) => yearAndMonthsData.find((ym) => ym.year === thisYear)?.months ?? [],
    [yearAndMonthsData]
  );

  const monthOptions = useMemo(() => {
    return getMonthsByYearFromData(year).map((m) => {
      const monthString = m.toString();
      return {
        value: monthString,
        label: monthString,
      };
    });
  }, [getMonthsByYearFromData, year]);

  const isFirstMonth = useMemo(() => year === startYear && month === startMonth, [year, month, startYear, startMonth]);
  const isLastMonth = useMemo(
    () => year === currentYear && month === currentMonth,
    [year, month, currentYear, currentMonth]
  );

  const onClickPrevMonthButton = useCallback(() => {
    if (month === 1) {
      setYearAndMonth(year - 1, 12);
    } else {
      setYearAndMonth(year, month - 1);
    }
  }, [month, year, setYearAndMonth]);
  const onClickNextMonthButton = useCallback(() => {
    if (month === 12) {
      setYearAndMonth(year + 1, 1);
    } else {
      setYearAndMonth(year, month + 1);
    }
  }, [month, year, setYearAndMonth]);

  return (
    <YearAndMonthWrapperStyle>
      {!isFirstMonth ? (
        <ChangeMonthButtonStyle onClick={onClickPrevMonthButton}>
          <img src={leftArrow} alt={"왼쪽 화살표"} />
        </ChangeMonthButtonStyle>
      ) : (
        <ChangeMonthButtonStyle className={"noButton"}></ChangeMonthButtonStyle>
      )}
      <SelectorsWrapperStyle>
        <SelectorAndLabelStyle>
          <Selector
            className={"selector year"}
            isPageSizeSelector={false}
            options={yearOptions}
            selectedValue={year.toString()}
            setSelectorOption={(o) => {
              const thisYear = Number(o.value);
              setYearAndMonth(thisYear, getMonthsByYearFromData(thisYear)[0]);
            }}
          />
          <TextStyle>년</TextStyle>
        </SelectorAndLabelStyle>
        <SelectorAndLabelStyle>
          <Selector
            className={"selector month"}
            isPageSizeSelector={false}
            options={monthOptions}
            selectedValue={month.toString()}
            setSelectorOption={(o) => setYearAndMonth(year, Number(o.value))}
          />
          <TextStyle>월</TextStyle>
        </SelectorAndLabelStyle>
      </SelectorsWrapperStyle>

      {!isLastMonth ? (
        <ChangeMonthButtonStyle onClick={onClickNextMonthButton}>
          <img src={rightArrow} alt={"왼쪽 화살표"} />
        </ChangeMonthButtonStyle>
      ) : (
        <ChangeMonthButtonStyle className={"noButton"}></ChangeMonthButtonStyle>
      )}
    </YearAndMonthWrapperStyle>
  );
}
