import { Panel } from "../../../../../../layout/content/detail/Panel";
import { OrganManagerOrganDTO } from "../../../../../../types/organ";
import { DetailActionButton } from "../../../../../../components/Buttons";
import React, { useCallback, useEffect, useState } from "react";
import { Table } from "../../../../../../layout/content/list/table/Table";
import { Pagination } from "../../../../../../layout/content/list/Pagination";
import voucherIcon from "../../../../../../assets/images/button_icons/voucher_icon.svg";
import deleteIcon from "../../../../../../assets/images/button_icons/delete_icon.svg";
import styled from "styled-components";
import { useOrganMemberColumns } from "../hooks/useOrganMemberColumns";
import { useOrganMemberGetter } from "../hooks/useOrganMemberGetter";
import { PageSearchCondition } from "../../../../../../types/page";
import { useKickOutOrganMembers } from "../hooks/useKickOutOrganMembers";
import { useAppSelector, useNewWindow } from "../../../../../../hooks/hooks";
import { OrganMember } from "../../../../../../types/organMember";
import { organMemberStatus } from "../../../../../../store/organMemberSlice";
import { useLoader } from "../../../../../../hooks/common/useLoader";
import { PaginationWrapper } from "../../../../../../layout/content/list/PaginationWrapper";
import { useTranslation } from "react-i18next";

const ListButtonWrapStyle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

interface Props {
  organ?: OrganManagerOrganDTO;
  onRefresh?: () => void;
}

export function OrganMembers({ organ, onRefresh }: Props) {
  const { t } = useTranslation(["organManager", "common"]);
  const status = useAppSelector(organMemberStatus);
  useLoader({ status });
  const [selectedOrganMemberIdxes, setSelectedOrganMemberIdxes] = useState<number[]>([]);
  const { getOrganMembers, organMembers } = useOrganMemberGetter({
    onCompletedCallback: () => setSelectedOrganMemberIdxes([]),
  });
  const kickOutOrganMembers = useKickOutOrganMembers();
  const { openOrganManagerGrantVoucher } = useNewWindow();

  useEffect(() => {
    if (!organ) return;
    getOrganMembers(organ.idx, {})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organ]);

  const openGrantVoucherPopup = useCallback(
    (organ: OrganManagerOrganDTO, organMemberIdxes: number[]) => {
      openOrganManagerGrantVoucher(
        {
          organIdx: organ.idx,
          organName: organ.organName,
          organMemberIdxes: organMemberIdxes,
        },
        onRefresh
      );
    },
    [openOrganManagerGrantVoucher, onRefresh]
  );

  const openGrantVoucherPopupForColumn = useCallback(
    (organMemberIdx: number) => {
      openOrganManagerGrantVoucher(
        {
          organIdx: organ?.idx ?? 0,
          organName: organ?.organName ?? "",
          organMemberIdxes: [organMemberIdx],
        },
        onRefresh
      );
    },
    [organ, openOrganManagerGrantVoucher, onRefresh]
  );

  const getSelectedOrganMembers = useCallback(
    (idxes: number[]): OrganMember[] =>
      (organMembers && organMembers.content.filter((om) => idxes.includes(om.idx))) ?? [],
    [organMembers]
  );

  const onClickKickOutOrganMembers = useCallback(
    (organIdx: number, organMemberIdxes: number[], organName: string) => {
      if (organMemberIdxes.length === 0) {
        alert(t("detail.organMember.noSelectedMember"));
        return;
      }

      if (
        getSelectedOrganMembers(organMemberIdxes).some(
          (om: OrganMember) => om.hasOrganVoucher && om.voucherOrganIdx === organ?.idx
        )
      ) {
        alert(t("detail.organMember.kickOut.cannotKickOut"));
        return;
      }

      if (!window.confirm(t("detail.organMember.kickOut.confirmMessage", { organName }))) return;

      kickOutOrganMembers(organIdx, organMemberIdxes).then(() => {
        alert(t("detail.organMember.kickOut.result"));
        getOrganMembers(organIdx, {})();
        onRefresh?.();
      });
    },
    [getSelectedOrganMembers, getOrganMembers, kickOutOrganMembers, onRefresh, organ?.idx, t]
  );

  const columns = useOrganMemberColumns({
    onRefresh: getOrganMembers(organ?.idx ?? 0, {}),
    openGrantVoucherPopup: (organMemberIdx: number) => openGrantVoucherPopupForColumn(organMemberIdx),
  });
  return organ ? (
    <>
      <Panel
        title={t("detail.organMember.title")}
        subtitle={`(${t("detail.organMember.unit", { count: organ.memberCount })})`}
      >
        <>
          <ListButtonWrapStyle>
            <DetailActionButton
              onClick={() => onClickKickOutOrganMembers(organ.idx, selectedOrganMemberIdxes, organ?.organName ?? "")}
            >
              <img src={deleteIcon} alt={t("detail.organMember.kickOut.alt")} />
              {t("detail.organMember.kickOut.buttonLabel")}
            </DetailActionButton>

            <DetailActionButton
              onClick={() => {
                if (selectedOrganMemberIdxes.length === 0) {
                  alert(t("detail.organMember.grantOrganVoucher.noSelectedMember"));
                  return;
                }

                if (getSelectedOrganMembers(selectedOrganMemberIdxes).some((om) => om.hasOrganVoucher)) {
                  alert(t("detail.organMember.grantOrganVoucher.cannotGrantAlertMessage"));
                  return;
                }

                openGrantVoucherPopup(organ, selectedOrganMemberIdxes);
              }}
            >
              <img src={voucherIcon} alt={t("detail.organMember.grantOrganVoucher.iconAlt")} />
              {t("detail.organMember.grantOrganVoucher.buttonLabel")}
            </DetailActionButton>
          </ListButtonWrapStyle>

          {organMembers && (
            <>
              <Table
                noDataText={t("noSearchResult", { ns: "common" })}
                listData={organMembers}
                columns={columns}
                getList={(pageCondition: PageSearchCondition) => getOrganMembers(organ.idx, pageCondition)}
                checkedRowIdxArr={selectedOrganMemberIdxes}
                setCheckedRowIdxArr={setSelectedOrganMemberIdxes}
                showCheckbox={(it) => !(it.hasOrganVoucher && it.voucherOrganIdx === organ.idx)}
              />

              <PaginationWrapper>
                <Pagination
                  page={organMembers}
                  goSpecificPage={(pageNum) => getOrganMembers(organ?.idx, { page: pageNum })()}
                />
              </PaginationWrapper>
            </>
          )}
        </>
      </Panel>
    </>
  ) : (
    <></>
  );
}
