import { useCallback } from "react";
import { OrderItemVO } from "../../../../../types/orders";
import { ProductDiscountType } from "../../../../../types/product";

export function useOrderItemPrice() {
  const getProductDiscountPrice = useCallback((orderItemVO: OrderItemVO) => {
    if (orderItemVO.discountType === ProductDiscountType.AMOUNT) return orderItemVO.discountValue;
    else if (orderItemVO.discountType === ProductDiscountType.PERCENT)
      return (orderItemVO.regularPrice * orderItemVO.discountValue) / 100;
    return 0;
  }, []);

  const getDiscountPrice = useCallback(
    (orderItemVO: OrderItemVO) => {
      return getProductDiscountPrice(orderItemVO) + orderItemVO.couponDiscountPrice;
    },
    [getProductDiscountPrice]
  );

  const hasDiscount = useCallback(
    (orderItemVO: OrderItemVO) => {
      return getDiscountPrice(orderItemVO) > 0;
    },
    [getDiscountPrice]
  );

  const getTotalSellingPrice = useCallback(
    (orderItems: OrderItemVO[]) => orderItems.reduce((sumPrice, it) => it.sellingPrice + sumPrice, 0),
    []
  );

  const getTotalBuyingPrice = useCallback(
    (orderItems: OrderItemVO[]) => orderItems.reduce((sumPrice, it) => it.sellingPrice * it.quantity + sumPrice, 0),
    []
  );

  const getTotalDiscountPrice = useCallback(
    (orderItems: OrderItemVO[]) => orderItems.reduce((sumPrice, it) => getDiscountPrice(it) + sumPrice, 0),
    [getDiscountPrice]
  );

  return {
    getProductDiscountPrice,
    getDiscountPrice,
    hasDiscount,
    getTotalSellingPrice,
    getTotalBuyingPrice,
    getTotalDiscountPrice,
  };
}
