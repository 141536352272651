import {PanelWrapper} from "../../../layout/content/detail/PanelWrapper";
import {Panel} from "../../../layout/content/detail/Panel";
import React, {useCallback, useEffect, useMemo, useRef} from "react";
import {
  DisplayPosition,
  ProductImage,
  ProductImagePutParam,
  ProductImageType,
  ProductPutParam,
  ProductVO,
} from "../../../types/product";
import {ProductEditForm} from "./ProductEditForm";
import style from "./productDetailModal.module.scss";
import formStyle from "./productEditForm.module.scss";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector, useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {callAsync} from "../../../util/sliceUtil";
import {productStatus, setIsProductDataSaved, updateProductAsync} from "../../../store/productSlice";
import {ProductEditStateValues, useProductEditState} from "../hooks/useProductEditState";
import {useLoader} from "../../../hooks/common/useLoader";
import {FileClassification, FileResponse, FileType} from "../../../types/file";
import {useFile} from "../../../hooks/file/useFile";
import {WindowMessage} from "../../../types/newWindow";
import {useProductGetter} from "../hooks/useProductGetter";
import {useProductDisplayPosition} from "../hooks/useProductDisplayPosition";
import {useProductDetailImageUploader} from "../hooks/useProductDetailImageUploader";
import {useTranslation} from "react-i18next";
import {useUtil} from "../../../util/hooks/useUtil";

export function ProductDetailModal() {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const { timeConverter } = useTimeConverter();
  const { id } = useParams<"id">();
  const { defaultErrorMessage } = useUtil();

  const productIdx = useMemo(() => Number(id), [id]);

  const formRef = useRef<HTMLFormElement>(null);

  const status = useAppSelector(productStatus);
  useLoader({ status });

  const state: ProductEditStateValues = useProductEditState();
  const { uploadImages } = useFile();
  const { uploadDetailImages } = useProductDetailImageUploader();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { productVO, getProductVO } = useProductGetter();
  const { isDesktopImagesNeeded, isMobileImagesNeeded } = useProductDisplayPosition();

  const updateStateFromProductVO = useCallback((product: ProductVO) => {
    state.setName(product.name);
    state.setDisplayPosition(product.displayPosition);
    state.setLanguage(product.language)
    state.setRegularPrice(product.regularPrice.toString());
    state.setDiscountValue(product.discountValue?.toString());
    state.setSellingPrice(product.sellingPrice.toString());
    state.setDiscountType(product.discountType);
    state.setIsCouponUsable(product.isCouponUsable);
    state.setSellingStartDate(
      product.sellingStartDate ? timeConverter.convertToLocalDate(product.sellingStartDate) : ""
    );
    state.setSellingEndDate(
      product.sellingEndDate === null
        ? null
        : product.sellingEndDate
        ? timeConverter.convertToLocalDate(product.sellingEndDate)
        : ""
    );
    state.setDescription(product.description);
    state.setSpecialDescription(product.specialDescription);
    state.setThumbnail(product.thumbnail);
    state.setDesktopImages(product.desktopImages);
    state.setMobileImages(product.mobileImages);
    state.setProductItems(
      product.productItems?.map((item) => ({
        idx: item.idx,
        unitItemName: item.unitItem.name,
        itemType: item.unitItem.type,
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!productVO) {
      getProductVO(productIdx).then((result) => {
        if (result) {
          updateStateFromProductVO(result);
          // state.setLanguage(result.language);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVO, getProductVO, productIdx]);
  const getUploadedThumbnailFile = async (addedThumbnailImage: FileType) => {
    try {
      const thumbnailFileResponseArr: FileResponse[] = await uploadImages(
        [addedThumbnailImage.file],
        FileClassification.PRODUCT_THUMBNAIL_IMAGE
      );

      const uploadedThumbnailFile = thumbnailFileResponseArr.at(0);
      if (uploadedThumbnailFile) {
        return {
          fileIdx: uploadedThumbnailFile.idx,
          uri: uploadedThumbnailFile.uri,
        };
      } else {
        throw Object.assign(new Error(), { message: t("update.uploadThumbnailImageError") });
      }
    } catch (e) {
      console.error(e);
      throw Object.assign(new Error(), { message: t("update.uploadThumbnailImageError") });
    }
  };

  const getUploadedDetailFiles = async (
    detailImages: ProductImage[],
    addedDetailImages: FileType[],
    type: ProductImageType
  ) => {
    try {
      const detailImagePutParamArr: ProductImagePutParam[] = detailImages.map((di, idx) => ({
        idx: di.idx,
        file: di.file,
        orderNum: idx + 1,
        type,
      }));

      if (addedDetailImages.length > 0) {
        const detailFiles: FileResponse[] = await uploadDetailImages(addedDetailImages.map((di) => di.file));

        detailFiles.forEach((file, idx) => {
          detailImagePutParamArr.push({
            file: {
              fileIdx: file.idx,
              uri: file.uri,
            },
            orderNum: detailImages.length + idx + 1,
            type,
          });
        });
      }

      return detailImagePutParamArr;
    } catch (e) {
      console.error(e);
      throw Object.assign(new Error(), { message: t("update.uploadDetailImageError") });
    }
  };

  const onClickUpdateBtn = async () => {
    if (!formRef.current?.reportValidity()) return;

    if (!state.thumbnail && !state.addedThumbnailImage) {
      alert(t("creator.validations.noThumbnail"));
      return;
    }

    if (!state.isSellingAlways && !state.sellingEndDate) {
      alert(t("creator.validations.noSellingDate"));
      return;
    }

    if (isDesktopImagesNeeded(state.displayPosition)) {
      if (state.desktopImages.length === 0 && state.addedDesktopImages.length === 0) {
        alert(t("creator.validations.noDetailImage"));
        return;
      }
    }

    if (isMobileImagesNeeded(state.displayPosition)) {
      if (state.mobileImages.length === 0 && state.addedMobileImages.length === 0) {
        alert(t("creator.validations.noDetailImage"));
        return;
      }
    }

    try {
      const param: ProductPutParam = {
        name: state.name,
        thumbnail: state.thumbnail ?? (await getUploadedThumbnailFile(state.addedThumbnailImage!)),
        regularPrice: Number(state.regularPrice),
        discountType: state.discountType,
        language: state.language,
        discountValue: state.discountValue ? Number(state.discountValue) : undefined,
        sellingPrice: Number(state.sellingPrice),
        sellingStartDate: state.sellingStartDate ? timeConverter.convertToUTC(state.sellingStartDate) : undefined,
        sellingEndDate: state.sellingEndDate ? timeConverter.convertToUTC(state.sellingEndDate) : undefined,
        description: state.description,
        specialDescription: state.specialDescription,
        desktopImages: isDesktopImagesNeeded(state.displayPosition)
          ? await getUploadedDetailFiles(state.desktopImages, state.addedDesktopImages, ProductImageType.DESKTOP)
          : undefined,
        mobileImages: isMobileImagesNeeded(state.displayPosition)
          ? await getUploadedDetailFiles(state.mobileImages, state.addedMobileImages, ProductImageType.MOBILE)
          : undefined,
        isCouponUsable: state.isCouponUsable,
      };

      callAsync(
        dispatch(updateProductAsync({ idx: productIdx, param })).unwrap(),
        () => {
          dispatch(setIsProductDataSaved(true));
          alert(t("update.result"));
        },
        (e) => {
          alert(defaultErrorMessage);
          throw e;
        }
      ).then(() => {
        closeWindowAndNotifyToParentWindow(WindowMessage.UPDATE_PRODUCT);
      });
    } catch (e) {
      alert(defaultErrorMessage);
    }
  };

  return (
    <PanelWrapper>
      <Panel title={t("update.title")}>
        <form className={formStyle.form} ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <ProductEditForm state={state} isCreatePage={false} />
          {state.displayPosition === DisplayPosition.GRANTABLE_PRODUCT ? (
            <></>
          ) : (
            <div className={style.buttonArea}>
              <button onClick={onClickUpdateBtn}>{t("update.buttonLabel")}</button>
            </div>
          )}
        </form>
      </Panel>
    </PanelWrapper>
  );
}
