import { MainApi } from "./MainApi";
import {
  AffiliateCodePostParam,
  AffiliateCodeSearchCondition,
  AffiliateCodeStatusPutParam,
} from "../types/affiliateCode";

export class AffiliateCodeApi {
  static baseUrl = `${MainApi.urlPrefix}/affiliate-codes`;

  static createCode = (param: AffiliateCodePostParam) => () => MainApi.api.post(`${AffiliateCodeApi.baseUrl}`, param);

  static getAffiliateCodes = (condition: AffiliateCodeSearchCondition) => () =>
    MainApi.api.get(`${AffiliateCodeApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static getAffiliateCode = (idx: number) => () => MainApi.api.get(`${AffiliateCodeApi.baseUrl}/${idx}`);

  static updateAffiliateCodeStatus = (param: AffiliateCodeStatusPutParam) => () =>
    MainApi.api.put(`${AffiliateCodeApi.baseUrl}`, param);

  static deleteAffiliateCode = (codeIdxes: number[]) => () =>
    MainApi.api.delete(
      `${AffiliateCodeApi.baseUrl}${MainApi.toParamStringFromObject({ affiliateCodeIdxes: codeIdxes })}`
    );

  static updateAffiliateCodeAvailableDuration = (idx: number, startAt: string, endAt: string | null) => () =>
    MainApi.api.put(`${AffiliateCodeApi.baseUrl}/${idx}/available-date`, {
      availableStartAt: startAt,
      availableEndAt: endAt,
    });
}
