import React, { useEffect, useMemo, useState } from "react";
import style from "../../../layout/content/post/creator.module.scss";
import { useConditionalAffiliateCodeList } from "../hooks/code/useConditionalAffiliateCodeList";
import { AffiliateCodeListVO, AffiliateCodeStatus } from "../../../types/affiliateCode";
import { usePartnerAffiliateCodeColumns } from "../hooks/partner/usePartnerAffiliateCodeColumns";
import { BodyScollTable } from "../../../layout/content/list/table/BodyScrollTable";
import { DEFAULT_SIZE_IN_SUB_TABLE, PageSearchCondition } from "../../../types/page";
import { PartnerSelectedAffiliateCode } from "./PartnerSelectedAffiliateCode";
import styled from "styled-components/macro";

interface Props {
  affiliateCodeIdx: number | null;
  setAffiliateCodeIdx: (_: number | null) => void;
  isCreatorPage: boolean;
  defaultSelectedCode?: AffiliateCodeListVO;
}

const PartnerSelectedAffiliateCodeWrapperStyle = styled.div`
  margin-top: -30px;
  margin-bottom: 30px;
`;

export function PartnerAffiliateCodesField({
  affiliateCodeIdx,
  setAffiliateCodeIdx,
  isCreatorPage,
  defaultSelectedCode,
}: Props) {
  const [searchWord, setSearchWord] = useState("");
  const { getCodeList, codeList } = useConditionalAffiliateCodeList({
    status: AffiliateCodeStatus.AVAILABLE,
    hasPartnerCompany: false,
    organManagerNameQuery: searchWord,
  });
  const { columns, selectedCodeColumns, selectedCodeColumnsOnDetail, columnsOnDetail } = usePartnerAffiliateCodeColumns(
    {
      selectedCodeIdx: affiliateCodeIdx,
      setSelectedCodeIdx: setAffiliateCodeIdx,
    }
  );
  const selectedCode: AffiliateCodeListVO | null = useMemo(
    () => codeList?.content.find((code) => code.idx === affiliateCodeIdx) ?? null,
    [affiliateCodeIdx, codeList]
  );

  useEffect(() => {
    getCodeList({
      size: DEFAULT_SIZE_IN_SUB_TABLE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.tableArea}>
      {!isCreatorPage && (
        <PartnerSelectedAffiliateCodeWrapperStyle>
          {Boolean(defaultSelectedCode) && affiliateCodeIdx === defaultSelectedCode!.idx ? (
            <PartnerSelectedAffiliateCode selectedCode={defaultSelectedCode!} columns={selectedCodeColumnsOnDetail} />
          ) : (
            <PartnerSelectedAffiliateCode selectedCode={selectedCode} columns={selectedCodeColumnsOnDetail} />
          )}
        </PartnerSelectedAffiliateCodeWrapperStyle>
      )}
      <div className={style.textInput}>
        <input
          className={style.searchWordInput}
          type={"text"}
          value={searchWord}
          placeholder={"제휴코드 보유자명 검색"}
          onChange={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setSearchWord(e.target.value);
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            getCodeList({});
          }}
        >
          검색
        </button>
      </div>
      <div className={style.list}>
        {codeList && (
          <BodyScollTable
            listData={codeList}
            columns={isCreatorPage ? columns : columnsOnDetail}
            getList={(pageCondition: PageSearchCondition) => () => getCodeList(pageCondition)}
            noDataText={"검색 결과가 없습니다."}
            tableClassName={style.listTable}
            tbodyClassName={style.listTableBody}
            noResultRowClassName={style.noResultRow}
          />
        )}
      </div>
      {isCreatorPage && <PartnerSelectedAffiliateCode selectedCode={selectedCode} columns={selectedCodeColumns} />}
    </div>
  );
}
