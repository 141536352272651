import { MiniModal } from "../../../layout/modal/MiniModal";
import React from "react";
import styled from "styled-components";
import { useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";

const BlockWrapper = styled.div`
  text-align: left;
  letter-spacing: -0.36px;
  font-size: 12px;
`;

interface Props {
  isShow: boolean;
  callbackCloseModal: (_: React.MouseEvent) => void;
  blockAt: string;
  reasonForBlocking: string;
  linkElement?: HTMLElement;
}

export function BlockReasonModal({ isShow, callbackCloseModal, blockAt, reasonForBlocking, linkElement }: Props) {
  const { t } = useTranslation("member");
  const { timeConverter } = useTimeConverter();

  return (
    <MiniModal
      show={isShow}
      closeModal={(e) => callbackCloseModal(e)}
      title={t("block.reason.label")}
      modalWidth={"200px"}
      linkElement={linkElement}
    >
      <BlockWrapper>
        <span>
          {t("block.blockedAt")} : {timeConverter.convertToLocalTime(blockAt)}
        </span>
        <br />
        <span>{reasonForBlocking}</span>
      </BlockWrapper>
    </MiniModal>
  );
}
