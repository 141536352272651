import { CouponInfo } from "../../../components/coupon/CouponInfo";
import { CustomerType } from "../../../../../types/common";
import { CategoryTab, DetailSubMenuCategories } from "../../../../../layout/content/detail/DetailSubMenuCategories";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCouponCntInfo } from "../../../../coupon/hooks/useCouponCntInfo";
import { OrganManagerVoucherInfo } from "./voucher/OrganManagerVoucherInfo";
import { useOrganManagerVoucherStatusGetter } from "./hooks/useOrganManagerVoucherStatusGetter";
import { useTranslation } from "react-i18next";

enum TabMenu {
  COUPON = "COUPON",
  ORGAN_VOUCHER = "ORGAN_VOUCHER",
}

interface Props {
  organManagerIdx: number;
}

export function OrganManagerCouponAndVoucher({ organManagerIdx }: Props) {
  const { t } = useTranslation("organManager");
  const [selectedTab, setSelectedTab] = useState<TabMenu>(TabMenu.COUPON);

  const { getCouponCntInfo, couponStatusInfo } = useCouponCntInfo({
    targetParam: {
      targetIdx: organManagerIdx,
      targetType: CustomerType.ORGAN_MANAGER,
    },
  });
  const { organMangerVoucherStatus, getOrganMangerVoucherStatus } = useOrganManagerVoucherStatusGetter(organManagerIdx);

  const getTabComponent = useCallback((organManagerIdx: number, menu: TabMenu) => {
    switch (menu) {
      case TabMenu.COUPON:
        return <CouponInfo targetIdx={organManagerIdx} customerType={CustomerType.ORGAN_MANAGER} />;
      case TabMenu.ORGAN_VOUCHER:
        return <OrganManagerVoucherInfo organManagerIdx={organManagerIdx} />;
    }
  }, []);

  const tabMenus: CategoryTab<TabMenu>[] = useMemo(() => {
    return [
      {
        tabName: t("detail.couponAndVoucher.submenus.coupon.label"),
        tabRightValue: t("detail.couponAndVoucher.submenus.coupon.unit", {
          count: couponStatusInfo?.availableCoupon ?? 0,
        }),
        tabValue: TabMenu.COUPON,
      },
      {
        tabName: t("detail.couponAndVoucher.submenus.organVoucher.label"),
        tabRightValue: t("detail.couponAndVoucher.submenus.organVoucher.unit", {
          count: (organMangerVoucherStatus?.voucherDTOs[0]?.grantableProductVoucherCount ?? 0)+
              (organMangerVoucherStatus?.voucherDTOs[1]?.grantableProductVoucherCount ?? 0),
        }),
        tabValue: TabMenu.ORGAN_VOUCHER,
      },
    ];
  }, [couponStatusInfo, organMangerVoucherStatus, t]);

  useEffect(() => {
    if (!couponStatusInfo) {
      getCouponCntInfo();
    }

    if (!organMangerVoucherStatus) {
      getOrganMangerVoucherStatus();
    }
  }, [getCouponCntInfo, couponStatusInfo, organMangerVoucherStatus, getOrganMangerVoucherStatus]);

  return (
    <>
      <DetailSubMenuCategories tabs={tabMenus} selectedTab={selectedTab} onClickTab={setSelectedTab} />
      {getTabComponent(organManagerIdx, selectedTab)}
    </>
  );
}
