import { OrganManagerVoucherStatus } from "./OrganManagerVoucherStatus";
import { OrganManagerVoucherList } from "./OrganManagerVoucherList";
import { useLoader } from "../../../../../../hooks/common/useLoader";
import { useAppSelector } from "../../../../../../hooks/hooks";
import { voucherStatus } from "../../../../../../store/voucherSlice";

interface Props {
  organManagerIdx: number;
}

export function OrganManagerVoucherInfo({ organManagerIdx }: Props) {
  const status = useAppSelector(voucherStatus);
  useLoader({ status });

  return (
    <>
      <OrganManagerVoucherStatus />
      <OrganManagerVoucherList organManagerIdx={organManagerIdx} />
    </>
  );
}
