import { FilterContentType, TableFilter } from "../../../../types/tableFilter";
import { OrganManagerStatus, QueryType } from "../../../../types/organManager";
import { useLanguage, useListFilterValues, useTimeConverter } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useOrganManagerType } from "../../../../types/hooks/useOrganManagerType";

interface Args {
  search: () => void;
}

interface ReturnType {
  filters: TableFilter[][];
}
export function useOrganManagerFilter({ search }: Args): ReturnType {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation("organManager");
  const { timeConverter } = useTimeConverter();
  const { organManagerStatusOptions } = useOrganManagerType();
  const { isKorean: isKoreanLanguage } = useLanguage();

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.searchWord.label"),
        content: {
          type: FilterContentType.TEXT,
          value: filterValues.queryFilterValue.value,
          maxLength: 100,
          placeholderVal: t("list.filter.searchWord.plcaeholder"),
          onChangeFunc: (v) => filterValues.queryFilterValue.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: filterValues.queryTypeFilterValue.value,
            options: [
              { value: QueryType.NAME, label: t("list.filter.queryType.name") },
              { value: QueryType.ID, label: t("list.filter.queryType.id") },
              { value: QueryType.PHONE_NUMBER, label: t("list.filter.queryType.phoneNumber") },
            ],
            onChangeFunc: (v) => filterValues.queryTypeFilterValue.setter(v as QueryType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.createdAt.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(filterValues.startDateOfCreateAtFilterValue.value),
            onChangeFunc: (v) =>
              filterValues.startDateOfCreateAtFilterValue.setter(
                v ? timeConverter.convertToFilterStartOfDay(v) : undefined
              ),
            placeholderVal: t("list.filter.createdAt.placeholder"),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(filterValues.endDateOfCreateAtFilterValue.value),
            onChangeFunc: (v) =>
              filterValues.endDateOfCreateAtFilterValue.setter(
                v ? timeConverter.convertToFilterEndOfDay(v) : undefined
              ),
            placeholderVal: t("list.filter.createdAt.placeholder"),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.status.label"),
        content: {
          type: FilterContentType.SELECT,
          value: filterValues.statusFilterValue.value ?? "",
          options: [{ value: "", label: t("list.filter.status.allLabel") }, ...organManagerStatusOptions],
          onChangeFunc: (v) => filterValues.statusFilterValue.setter(v === "" ? undefined : (v as OrganManagerStatus)),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.filter.organVoucher"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: filterValues.minVoucherCountFilterValue.value,
            onChangeFunc: (v) => filterValues.minVoucherCountFilterValue.setter(v),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: filterValues.maxVoucherCountFilterValue.value,
            onChangeFunc: (v) => filterValues.maxVoucherCountFilterValue.setter(v),
          },
        },
      },
    ],
  ];

  return {
    filters,
  };
}
