import style from "../../../layout/content/post/creator.module.scss";
import { RadioGroup } from "../../../components/RadioGroup";
import { PartnerCompanyStatus } from "../../../types/partnerCompany";
import React from "react";
import { usePartnerCompanyType } from "../../../types/hooks/usePartnerCompanyType";

interface Props {
  changeValue: (v: any, changeFunc: (_: any) => void) => void;
  setStatus: (_: PartnerCompanyStatus) => void;
  status: PartnerCompanyStatus;
  isExposureButtonBlocked: boolean;
}

export function PartnerCompanyStatusRadioField({ changeValue, setStatus, status, isExposureButtonBlocked }: Props) {
  const { getPartnerCompanyStatusLabel } = usePartnerCompanyType();

  return (
    <div className={style.radioGroup}>
      <RadioGroup
        groupName={"partnerCompanyStatus"}
        isRequired={true}
        radios={[
          {
            check: (v: PartnerCompanyStatus) => v === PartnerCompanyStatus.UNEXPOSED,
            onClickFunc: () => changeValue(PartnerCompanyStatus.UNEXPOSED, setStatus),
            text: getPartnerCompanyStatusLabel(PartnerCompanyStatus.UNEXPOSED),
          },
          {
            check: (v: PartnerCompanyStatus) => v === PartnerCompanyStatus.EXPOSED,
            onClickFunc: () => changeValue(PartnerCompanyStatus.EXPOSED, setStatus),
            text: getPartnerCompanyStatusLabel(PartnerCompanyStatus.EXPOSED),
            isDisabled: isExposureButtonBlocked,
          },
        ]}
        selectedValue={status}
      />
    </div>
  );
}
