import style from "../../../components/radioGroup.module.scss";
import React from "react";
import { AvailableCondition } from "../CouponCreator";
import { Radio, RadioGroup } from "../../../components/RadioGroup";
import { useTranslation } from "react-i18next";

interface Props {
  availableCondition: AvailableCondition;
  setAvailableCondition: (_: AvailableCondition) => void;
}

export function AvailableConditionField({ availableCondition, setAvailableCondition }: Props) {
  const { t } = useTranslation("coupon");
  const radios: Radio<AvailableCondition>[] = [
    {
      check: (c: AvailableCondition) => c === AvailableCondition.NONE,
      onClickFunc: () => setAvailableCondition(AvailableCondition.NONE),
      text: t("creator.condition.noLimit"),
    },
    {
      check: (c: AvailableCondition) => c === AvailableCondition.PAY_AMOUNT,
      onClickFunc: () => setAvailableCondition(AvailableCondition.PAY_AMOUNT),
      text: t("creator.condition.paidAmount"),
    },
  ];

  return (
    <div className={style.radioGroup}>
      <RadioGroup groupName={"condition"} isRequired={true} radios={radios} selectedValue={availableCondition} />
    </div>
  );
}
