import { MainApi } from "./MainApi";
import {
  PartnerCompanyOrderingNumberUpdateDTO,
  PartnerCompanyPostDTO,
  PartnerCompanyPutDTO,
  PartnerCompanySearchCondition,
  PartnerCompanyStatusUpdateDTO,
} from "../types/partnerCompany";

export class PartnerCompanyApi {
  static baseUrl = `${MainApi.urlPrefix}/partner-companies`;

  static createPartnerCompany = (param: PartnerCompanyPostDTO) => () =>
    MainApi.api.post(`${PartnerCompanyApi.baseUrl}`, param);

  static getPartnerCompany = (idx: number) => () => MainApi.api.get(`${PartnerCompanyApi.baseUrl}/${idx}`);

  static updatePartnerCompany = (idx: number, param: PartnerCompanyPutDTO) => () =>
    MainApi.api.put(`${PartnerCompanyApi.baseUrl}/${idx}`, param);

  static getPartnerCompanies = (condition: PartnerCompanySearchCondition) => () =>
    MainApi.api.get(`${PartnerCompanyApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static deletePartnerCompanies = (idxes: number[]) => () =>
    MainApi.api.delete(`${PartnerCompanyApi.baseUrl}/${idxes.join(",")}`);

  static updatePartnerCompanyStatus = (dto: PartnerCompanyStatusUpdateDTO) => () =>
    MainApi.api.put(`${PartnerCompanyApi.baseUrl}/status`, dto);

  static updatePartnerCompanyOrderingNumber = (dtos: PartnerCompanyOrderingNumberUpdateDTO[]) => () =>
    MainApi.api.put(`${PartnerCompanyApi.baseUrl}/numeric-order`, dtos);
}
