import { useAppDispatch } from "../../../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../../../util/sliceUtil";
import { RefundItemVO, OrderTranslator } from "../../../../../../types/orders";
import { getRefundItemsAsync } from "../../../../../../store/orderSlice";
import { useUtil } from "../../../../../../util/hooks/useUtil";

export function useRefundItemsGetter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return useCallback(
    (refundIdx: number, onSuccess: (refundItems: RefundItemVO[]) => void) => {
      callAsync(
        dispatch(getRefundItemsAsync(refundIdx)).unwrap(),
        (refundItems: RefundItemVO[]) => onSuccess(refundItems.map(OrderTranslator.createRefundItemVO)),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );
}
