import { FeedGoodLogoImage } from "../../../components/Images";
import React, { useCallback } from "react";
import styled from "styled-components";
import { PrimaryOutlineButton } from "../../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { StrongTextStyle } from "../../../components/Texts";

const WrapperStyle = styled.div`
  padding: 333px 0 150px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  text-align: center;
`;

const TitleStyle = styled.p`
  font-weight: normal;
  font-size: 32px;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.FGBlue};

  white-space: break-spaces;

  > .bold {
    font-weight: bold;
  }
`;

const DescriptionStyle = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: ${(props) => props.theme.color.BodyTextBlue};
`;

export function PendingAdminApproval() {
  const { t } = useTranslation("account");
  const navigate = useNavigate();

  const onClickBackButton = useCallback(() => navigate("/login"), [navigate]);

  return (
    <>
      <WrapperStyle>
        <FeedGoodLogoImage />
        <TitleStyle>
          <Trans t={t} i18nKey={"pendingAdminApproval.title"} components={[<StrongTextStyle />]} />
        </TitleStyle>
        <DescriptionStyle>
          {t("pendingAdminApproval.description.message1")}
          <br />
          {t("pendingAdminApproval.description.message2")}
        </DescriptionStyle>
        <PrimaryOutlineButton onClick={onClickBackButton}>
          {t("pendingAdminApproval.goBackButton")}
        </PrimaryOutlineButton>
      </WrapperStyle>
    </>
  );
}
