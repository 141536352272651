import { PanelWrapper } from "../../../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../../../layout/content/detail/Panel";
import style from "../popup.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { Selector } from "../../../../../layout/content/selector/Selector";
import { callAsync } from "../../../../../util/sliceUtil";
import { useAppDispatch, useAppSelector, useLanguage } from "../../../../../hooks/hooks";
import queryString from "query-string";
import { adminStatus, changeRoleAsync } from "../../../../../store/adminSlice";
import { useLoader } from "../../../../../hooks/common/useLoader";
import { AdminRoleCategory } from "../../../../../types/adminRole";
import { useMyInfoGetter } from "../../../../account/mypage/hooks/useMyInfoGetter";
import { useRoleGetter } from "../../../../role/hooks/useRoleGetter";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";

export function AdminRole() {
  const { t } = useTranslation("member");
  const dispatch = useAppDispatch();
  const { adminIdx, roleIdx } = queryString.parse(window.location.search);
  const [selectedRole, setSelectedRole] = useState(0);
  const status = useAppSelector(adminStatus);
  useLoader({ status });
  const { myInfo } = useMyInfoGetter();
  const { defaultErrorMessage } = useUtil();
  const { isKorean } = useLanguage();

  const { adminRoleOptions } = useRoleGetter({ isRoot: myInfo?.role.category === AdminRoleCategory.ADMIN_ROOT });

  useEffect(() => setSelectedRole(Number(roleIdx)), [roleIdx]);

  const onClickChangeRole = useCallback(() => {
    if (adminIdx == null) return;

    callAsync(
      dispatch(
        changeRoleAsync({
          adminIdx: Number(adminIdx),
          roleIdx: selectedRole,
        })
      ).unwrap(),
      () => {
        alert(t("roleSetting.changeResult"));
        window.opener.postMessage(
          {
            message: "refreshFromAdminRole",
          },
          "*"
        );
        window.close();
      },
      () => alert(defaultErrorMessage)
    ).then();
  }, [t, dispatch, selectedRole, adminIdx, defaultErrorMessage]);

  return (
    <PanelWrapper>
      <Panel title={t("roleSetting.title")} description={t("roleSetting.description")}>
        <>
          <div className={style.inputArea}>
            <label className={style.label}>{t("roleSetting.role")}</label>

            <Selector
              className={`${style.adminRoleSelector} ${isKorean ? "" : style.notKO}`}
              isPageSizeSelector={false}
              selectedValue={selectedRole.toString()}
              setSelectorOption={(it) => setSelectedRole(Number(it.value))}
              options={adminRoleOptions}
            />
          </div>

          <div className={style.buttonArea}>
            <button className={style.blockButton} onClick={onClickChangeRole}>
              {t("roleSetting.saveButton")}
            </button>
          </div>
        </>
      </Panel>
    </PanelWrapper>
  );
}
