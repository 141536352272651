import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { DetailActionButton } from "../../../components/Buttons";
import { DetailBarInfoCategory } from "../../../types/newWindow";
import { useNewWindow } from "../../../hooks/hooks";

export function ArticleContentDetail({ id, title }: ArticleProps) {
  const { openSamplePage } = useNewWindow();
  const onClickViewSampleButton = () => {
    openSamplePage({
      name: "샘플 네임",
      category: DetailBarInfoCategory.USER,
      otherParam: "다른 파라미터",
    });
  };

  const code = `
export const openSamplePage = (
  param: {
    name: string;
    category: DetailBarInfoCategory;
    otherParam: string;
  }
) => {
  openNewWindow(
    NewWindow.SAMPLE,
    {
      idx: 101,
      param,
      width: 1000,
      height: 480
    }
  );
};

openSamplePage({
  name: "샘플 네임",
  category: DetailBarInfoCategory.USER,
  otherParam: "다른 파라미터"
});
  `;
  const description = `
- openNewWindowUtil.ts 파일에서 공통 함수 만들기
  - 공통 함수에서 openNewWindow 함수 호출
- NewWindow enum에 추가할 창 타입 값 추가
- param을 추가하면 새 창의 쿼리 파라미터로 넘어감
  - name의 값은 상단 바의 이름 영역에 표시됨
  - category의 값은 DetailBarInfoCategory 타입으로 상단 바의 이름 왼쪽에 표시됨
  - 이 외에 필요한 값 넘기면 됨
- 호출될 컴포넌트는 index.tsx의 router에서 path가 detail의 하위 라우터로 추가하면 됨
- 호출될 컴포넌트에서 아래처럼 파라미터 받으면 됨
  const {id} = useParams<"id">();
  const {otherParam} = queryString.parse(window.location.search);
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code} description={description}>
          <DetailActionButton onClick={onClickViewSampleButton}>새 창 샘플 보기</DetailActionButton>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
