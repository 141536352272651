import style from "../productItemModal.module.scss";
import React from "react";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";
import {Nations} from "../../../../types/organManager";
import {OrganMemberNationSelector} from "../../../../components/OrganMemberNationSelector";

interface Props {
    nation: string;
    setNation: (_: Nations) => void;
}

export function OrganManagerNationField({nation, setNation}: Props) {
    const {nationOptions} = useOrganManagerType();


    return (
        <div className={style.field}>
            <OrganMemberNationSelector
                options={nationOptions}
                selectedValue={nation}
                setSelectorOption={(it) => setNation(it.value as Nations)}
            />
        </div>
    );
}