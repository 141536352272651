import style from "../../memberUserCoupons.module.scss";
import { Panel } from "../../../../../layout/content/detail/Panel";
import React, { useEffect, useState } from "react";
import { Table } from "../../../../../layout/content/list/table/Table";
import { PaginationWrapper } from "../../../../../layout/content/list/PaginationWrapper";
import { Pagination } from "../../../../../layout/content/list/Pagination";
import { useUserVoucherInfoList } from "../hooks/useUserVoucherInfoList";
import { useUserVoucherInfoColumns } from "../hooks/useUserVoucherInfoColumns";
import { useTranslation } from "react-i18next";

interface Props {
  userIdx: number;
  name: string;
}

export function VoucherInfoList({ userIdx, name }: Props) {
  const { t } = useTranslation("user");
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const { voucherHistoryList, getVoucherHistoryList, pageCondition } = useUserVoucherInfoList({ userIdx, isAvailable });
  const { columns } = useUserVoucherInfoColumns({ userIdx, name, getList: getVoucherHistoryList(pageCondition) });

  useEffect(() => {
    getVoucherHistoryList({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  return (
    <Panel
      title={t("detail.userVoucherInfo.list.title")}
      additionalButtons={
        <label className={style.headerCheckboxLabel}>
          <input type={"checkbox"} readOnly={true} checked={isAvailable} onClick={() => setIsAvailable(!isAvailable)} />
          {t("detail.userVoucherInfo.list.viewOnlyAvailableVoucher")}
        </label>
      }
    >
      <>
        {voucherHistoryList ? (
          <>
            <Table
              listData={voucherHistoryList}
              columns={columns}
              getList={getVoucherHistoryList}
              noDataText={t("detail.userVoucherInfo.list.noData")}
            />
            {voucherHistoryList.content.length === 0 ? (
              <></>
            ) : (
              <PaginationWrapper>
                <Pagination
                  page={voucherHistoryList}
                  goSpecificPage={(pageNum: number) => getVoucherHistoryList({ page: pageNum })()}
                />
              </PaginationWrapper>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </Panel>
  );
}
