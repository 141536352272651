import { useCallback, useEffect, useMemo } from "react";
import { FilterType, FilterValue } from "../../types/filter";
import { DEFAULT_PAGE, DEFAULT_SIZE, DEFAULT_SORT, PageSearchCondition } from "../../types/page";
import { ListQueryType } from "../../types/list";
import { useSortParam } from "./useSortParam";
import { useTimeConverter } from "../hooks";

interface Args<T extends ListQueryType> {
  filterValueArray: FilterValue<any>[];
  listQueryType: T | undefined;
  setPageInfoOnFilter: (_: PageSearchCondition) => void;
}
export function useFilterValues<T extends ListQueryType>({
  filterValueArray,
  listQueryType,
  setPageInfoOnFilter,
}: Args<T>) {
  const { toSortArray } = useSortParam();
  const { timeConverter } = useTimeConverter();

  const filterTypeRouter = useMemo(
    () => ({
      [FilterType.STRING.toString()]: (valueFromUrl: string | undefined, defaultValue?: string) =>
        valueFromUrl ? (valueFromUrl as string) : defaultValue ?? "",
      [FilterType.OTHER.toString()]: (valueFromUrl: string | undefined, defaultValue?: string) =>
        valueFromUrl ? valueFromUrl : defaultValue,
      [FilterType.DATE.toString()]: (valueFromUrl: string | undefined) =>
        valueFromUrl ? new Date(valueFromUrl as string) : undefined,
      [FilterType.START_DATE.toString()]: (valueFromUrl: string | undefined) =>
        valueFromUrl ? timeConverter.convertToFilterStartOfDay(valueFromUrl as string) : undefined,
      [FilterType.END_DATE.toString()]: (valueFromUrl: string | undefined) =>
        valueFromUrl ? timeConverter.convertToFilterEndOfDay(valueFromUrl as string) : undefined,
      [FilterType.NUMBER.toString()]: (valueFromUrl: string | undefined) => (valueFromUrl ? Number(valueFromUrl) : ""),
    }),
    [timeConverter]
  );

  useEffect(() => {
    if (listQueryType) {
      filterValueArray.forEach((filter) => {
        const value = listQueryType[filter.key as keyof T] as any as string | undefined;
        filter.setter(filterTypeRouter[filter.type.toString()](value, filter.defaultValue) as any);
      });

      setPageInfoOnFilter({
        page: listQueryType.page ? Number(listQueryType.page) : DEFAULT_PAGE,
        size: listQueryType.size ? Number(listQueryType.size) : DEFAULT_SIZE,
        sort: listQueryType.sort ? toSortArray(listQueryType.sort) : DEFAULT_SORT,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType]);

  const getFilterValueByKey = useCallback(
    (key: string) => filterValueArray.find((filter) => filter.key === key),
    [filterValueArray]
  );

  return {
    getFilterValueByKey,
  };
}
