import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceState, Status } from "../types/common";
import {
  CheckDuplicatedPhoneNumberRequest,
  FindIdRequest,
  FindPwdRequest,
  LoginInfo,
  ResendAuthConfirmMailRequest,
  ResendTemporaryPasswordRequest,
  SignUpRequest,
} from "../types/account";
import { RootState } from "./index";
import { AuthApi } from "../api/AuthApi";
import { addCases, executePromise } from "../util/sliceUtil";

export interface AccountState extends SliceState {
  foundEmail: string;
}

const initialState: AccountState = {
  status: Status.IDLE,
  foundEmail: "",
};

export const signUpAysnc = createAsyncThunk("account/signUp", (signUpInfo: SignUpRequest) =>
  executePromise(AuthApi.signUp(signUpInfo))
);

export const signInAsnyc = createAsyncThunk("account/signIn", (loginInfo: LoginInfo) =>
  executePromise(AuthApi.signIn(loginInfo))
);

export const getAccessTokenFromRefreshTokenAsync = createAsyncThunk(
  "account/getAccessTokenFromRefreshToken",
  (refreshToken: string) => executePromise(AuthApi.getAccessTokenFromRefreshToken(refreshToken))
);

export const findIdAsync = createAsyncThunk("account/findId", (request: FindIdRequest) =>
  executePromise(AuthApi.findId(request))
);

export const findPwdAsync = createAsyncThunk("account/findPwd", (request: FindPwdRequest) =>
  executePromise(AuthApi.findPwd(request))
);

export const checkDuplicatedPhoneNumberAsync = createAsyncThunk(
  "account/checkDuplicatedPhoneNumber",
  (request: CheckDuplicatedPhoneNumberRequest) => executePromise(AuthApi.checkDuplicatedPhoneNumber(request))
);

export const resendTemporaryPasswordAsync = createAsyncThunk(
  "account/resendTemporaryPassword",
  (request: ResendTemporaryPasswordRequest) => executePromise(AuthApi.resendTemporaryPassword(request))
);

export const resendAuthConfirmMailAsync = createAsyncThunk(
  "account/resendAuthConfirmMail",
  (request: ResendAuthConfirmMailRequest) => executePromise(AuthApi.resendAuthConfirmMail(request))
);

export const getAccessTokenWithNationalityAsync = createAsyncThunk(
  "account/getAccessTokenWithNationality",
  ({ nationality, refreshToken }: { nationality: string; refreshToken: string }) =>
    executePromise(AuthApi.getAccessTokenWithNationality(nationality, refreshToken))
);

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setFoundEmail: (state, action: PayloadAction<string>) => {
      state.foundEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, signUpAysnc);
    addCases(builder, signInAsnyc);
    addCases(builder, findIdAsync);
    addCases(builder, findPwdAsync);
    addCases(builder, checkDuplicatedPhoneNumberAsync);
    addCases(builder, resendTemporaryPasswordAsync);
    addCases(builder, resendAuthConfirmMailAsync);
  },
});

export default accountSlice.reducer;

export const { setFoundEmail } = accountSlice.actions;

export const accountStatus = (state: RootState) => state.account.status;
export const accountFoundEmail = (state: RootState) => state.account.foundEmail;
