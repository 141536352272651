import { OrganManagerStatus } from "../../../../types/organManager";
import { useCallback } from "react";

export function useOrganManagerStatus() {
  const isBlocked = useCallback((status: OrganManagerStatus) => status === OrganManagerStatus.BLOCKED, []);

  const isLeave = useCallback((status: OrganManagerStatus) => status === OrganManagerStatus.LEAVE, []);

  const isBeforeSignUpCompleted = useCallback(
    (status: OrganManagerStatus) => status === OrganManagerStatus.BEFORE_SELF_MAIL_AUTHENTICATION,
    []
  );

  const isNormal = useCallback(
    (status: OrganManagerStatus) =>
      status === OrganManagerStatus.DEFAULT || status === OrganManagerStatus.REVOKED_SELF_PHONE_AUTHENTICATION,
    []
  );

  return {
    isBlocked,
    isLeave,
    isBeforeSignUpCompleted,
    isNormal,
  };
}
