import { AdminType } from "./account";
import { AdminRoleSummary } from "./adminRole";
import { SearchCondition } from "./page";

interface AdminVO {
  idx: number;
  email: string;
  name: string;
  status: AdminStatus;
  accepted: boolean;
  phoneNumber: string;
  type: AdminType;
  role: AdminRoleSummary;
  lastSignInIp: string;
  lastSignInAt: string;
  createAt: string;
  leaveAt: string;
  blockAt: string;
  reasonForBlocking: string;
  nationality: string;
  codePhone: string;
}

enum AdminQueryType {
  NAME = "NAME",
  ID = "ID",
  PHONE_NUMBER = "PHONE_NUMBER",
}

interface AdminSearchCondition extends SearchCondition {
  qtype?: AdminQueryType;
  q?: string;
  createdAtFrom?: Date;
  createdAtTo?: Date;
  roleIdx?: string;
  status?: AdminStatus;
  nationality?: string;
}

interface ChangeRoleParam {
  adminIdx: number;
  roleIdx: number;
}

interface AdminListObj {
  idx: number;
  email: string;
  name: string;
  nickName: string;
  phoneNumber: string;
  status: AdminStatus;
  type: AdminType;
  roleIdx: number;
  roleName: string;
  reasonForBlocking: string;
  blockAt: string;
  leaveAt: string;
  createAt: string;
  nationality: string;
  codePhone: string;
}

enum AdminStatus {
  DEFAULT = "DEFAULT",
  BEFORE_SELF_AUTHENTICATION = "BEFORE_SELF_AUTHENTICATION",
  BEFORE_ADMIN_AUTHENTICATION = "BEFORE_ADMIN_AUTHENTICATION",
  BLOCKED = "BLOCKED",
  LEAVE = "LEAVE",
}

interface AdminPutParam {
  codePhone: string;
  name: string;
  phoneNumber: string;
}

enum MemberAdminDetailTabType {
  SELF_INFO = "SELF_INFO",
  ACTIVITY_HISTORY = "ACTIVITY_HISTORY",
}

export { AdminStatus, AdminQueryType, MemberAdminDetailTabType };
export type { AdminVO, AdminListObj, AdminSearchCondition, ChangeRoleParam, AdminPutParam };
