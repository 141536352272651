import { ArticleWrapper } from "./ArticleWrapper";
import { ArticleProps } from "../../docType";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Table } from "../../../layout/content/list/table/Table";
import { TableColumnData } from "../../../types/common";
import { PageResponse } from "../../../types/page";
import { useState } from "react";
import { sampleColumns, sampleListData, SampleListObject } from "./data";

export function ArticleTable({ id, title }: ArticleProps) {
  const [sampleSelectedItems, setSampleSelectedItems] = useState<number[]>([]);

  const listData: PageResponse<SampleListObject> = sampleListData;

  const columns: TableColumnData<SampleListObject>[] = sampleColumns;

  const description = `
- Table Props
{
  listData: PageResponse<T>;
  checkedRowIdxArr?: number[]; -- 체크박스에 체크된 애들을 담을 array
  setCheckedRowIdxArr?: (_: number[]) => void; -- 위의 array의 setter
  columns: TableColumnData<T>[]; -- 테이블의 칼럼 셋팅 값
  getList: (pageCondition: PageSearchCondition) => VoidFunction; -- 테이블의 리스트 불러오는 함수 
  noDataText?: string; -- 데이터가 없을 경우 보여줄 텍스트
  showCheckbox?: (_: T) => boolean; -- 조건부로 체크박스를 보여줘야할 경우 해당 조건을 만족하는지 체크하는 함수
}

- TableColumnData 타입
{
  accessor: keyof T; -- list data 오브젝트의 키 값
  header: string | ReactNode; -- header 칼럼에 보여줄 요소
  getNode?: (a:T, idx: number) => ReactNode; -- table의 바디 칼럼에 보여줄 요소
  sortable: boolean; -- 정렬 여부 (정렬 가능한 경우, accessor 값으로 정렬 요청) 
  className?: string;
  isLeftAlign?: boolean;
  menuCode?: AdminMenuCode; -- 권한이 필요한 경우 권한 코드 입력
}  
  `;

  const code = `
- 샘플 코드
const listData: PageResponse<SampleListObject> = ${JSON.stringify(listData, null, 2)};

const columns: TableColumnData<SampleListObject>[] = ${JSON.stringify(columns, null, 2)};

<Table listData={listData}
       columns={columns}
       checkedRowIdxArr={sampleSelectedItems}
       setCheckedRowIdxArr={setSampleSelectedItems}
       noDataText={"검색결과가 없습니다."}
       showCheckbox={(s: SampleListObject) => s.orderNumber === 1}
       getList={({}) => () => {}}/>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode description={description} exampleCode={code}>
          <Table
            listData={listData}
            columns={columns}
            checkedRowIdxArr={sampleSelectedItems}
            setCheckedRowIdxArr={setSampleSelectedItems}
            showCheckbox={(s: SampleListObject) => s.orderNumber === 1}
            noDataText={"검색결과가 없습니다."}
            getList={(_) => () => {}}
          />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
