import styled, { css } from "styled-components";
import { LinkStyle } from "../../../../components/styled/LinkStyle";
import { OrganManagerStatus } from "../../../../types/organManager";
import { BlockReasonModal } from "../../../component/modal/BlockReasonModal";
import React, { useState } from "react";
import { useOrganManagerStatus } from "../hooks/useOrganManagerStatus";
import { LeaveModal } from "../../../component/modal/LeaveModal";
import { useTimeConverter } from "../../../../hooks/hooks";
import { useOrganManagerType } from "../../../../types/hooks/useOrganManagerType";

const OrganManagerStatusStyle = styled.div<{ organManagerStatus: OrganManagerStatus }>`
  position: relative;

  ${(props) =>
    props.organManagerStatus === OrganManagerStatus.DEFAULT &&
    css`
      color: ${() => props.theme.color.c75};
    `}

  ${(props) =>
    props.organManagerStatus === OrganManagerStatus.BEFORE_SELF_MAIL_AUTHENTICATION &&
    css`
      color: ${() => props.theme.color.c75};
    `}

  ${(props) =>
    props.organManagerStatus === OrganManagerStatus.BLOCKED &&
    css`
      color: ${() => props.theme.color.FGPointRed};
    `}

  ${(props) =>
    props.organManagerStatus === OrganManagerStatus.LEAVE &&
    css`
      color: ${() => props.theme.color.FGDarkBlue};
    `}
`;

interface Props {
  organManager: {
    status: OrganManagerStatus;
    blockAt: string;
    reasonForBlocking: string;
    leaveAt: string;
  };
}

export default function OrganManagerStatusComponent({ organManager }: Props) {
  const { timeConverter } = useTimeConverter();
  const status = organManager.status;
  const { isBlocked, isLeave } = useOrganManagerStatus();
  const { toStringFromOrganManagerStatus } = useOrganManagerType();

  let [isShow, setIsShow] = useState(false);

  return (
    <>
      {isBlocked(status) || isLeave(status) ? (
        <OrganManagerStatusStyle organManagerStatus={status}>
          <LinkStyle
            onClick={(e) => {
              e.stopPropagation();
              setIsShow(true);
            }}
          >
            {toStringFromOrganManagerStatus(status)}
          </LinkStyle>
          {isBlocked(status) && (
            <BlockReasonModal
              isShow={isShow}
              callbackCloseModal={() => setIsShow(false)}
              blockAt={organManager.blockAt}
              reasonForBlocking={organManager.reasonForBlocking}
            />
          )}
          {isLeave(status) && (
            <LeaveModal
              isShow={isShow}
              callbackCloseModal={() => setIsShow(false)}
              leaveAt={timeConverter.convertToLocalTime(organManager.leaveAt)}
            />
          )}
        </OrganManagerStatusStyle>
      ) : (
        <OrganManagerStatusStyle organManagerStatus={status}>
          {toStringFromOrganManagerStatus(status)}
        </OrganManagerStatusStyle>
      )}
    </>
  );
}
