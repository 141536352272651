import deleteIcon from "../../../../assets/images/button_icons/delete_icon.svg";
import { Panel } from "../../../../layout/content/detail/Panel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { useAppDispatch, useCountry, useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { getTestHistoriesByUserProfileAsync } from "../../../../store/userProfileSlice";
import { AdminTestHistoryListObjByProfile } from "../../../../types/userProfile";
import { DescriptionList, DescriptionValue } from "../../../../layout/content/detail/DescriptionList";
import { AnchorComponent } from "../../../../layout/content/AnchorComponent";
import userDetailStyle from "../memberUserDetail.module.scss";
import { toAddressLine1 } from "../../../../types/user";
import { CategoryTab, DetailSubMenuCategories } from "../../../../layout/content/detail/DetailSubMenuCategories";
import { ProfileTestHistory } from "./ProfileTestHistory";
import { ProfileOrganVoucher } from "./ProfileOrganVoucher";
import { ordersToSortArr, PageResponse, PageSearchCondition, SearchCondition } from "../../../../types/page";
import { AdminMenuCode } from "../../../../types/adminRole";
import { RoleChecker } from "../../../role/RoleChecker";
import { DetailActionButton } from "../../../../components/Buttons";
import { dateStringToDottedString } from "../../../../util/dateUtil";
import { useTesterOrganVoucherStatusGetter } from "./hooks/useTesterOrganVoucherStatusGetter";
import { CopiableText } from "../../../../components/CopiableText";
import { useTesterDeleter } from "./hooks/useTesterDeleter";
import { useTesterDetailGetter } from "./hooks/useTesterDetailGetter";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useGenderType } from "../../../../types/hooks/useGenderType";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Props {
  profileIdx: number;
  onDeletedCallback?: () => void;
}

enum MenuCategory {
  TEST_HISTORY = "TEST_HISTORY",
  ORGAN_VOUCHER = "ORGAN_VOUCHER",
}

const OrganVoucherWrapperStyle = styled.div`
  display: flex;
  gap: 10px;
`;

export function ProfileDetail({ profileIdx, onDeletedCallback }: Props) {
  const { t } = useTranslation(["user", "common"]);
  const { defaultErrorMessage } = useUtil();
  const dispatch = useAppDispatch();

  const [testHistoryList, setTestHistoryList] = useState<PageResponse<AdminTestHistoryListObjByProfile>>();
  const [selectedTab, setSelectedTab] = useState<MenuCategory>(MenuCategory.TEST_HISTORY);

  const { toStringFromGender } = useGenderType();
  const { openNewWindowWithURL } = useNewWindow();
  const { getCountryNameByCodeISOAlpha2 } = useCountry();
  const { timeConverter } = useTimeConverter();
  const { userOrganVoucherNewStatusInfo } = useTesterOrganVoucherStatusGetter({ profileIdx });
  const { profile, getTesterDetail } = useTesterDetailGetter();
  const { deleteUserProfiles } = useTesterDeleter({
    onCompleteCallback: () => {
      onDeletedCallback?.();
    },
  });

  useEffect(() => {
    if (profileIdx) {
      getTesterDetail(profileIdx);
      getTestHistoryList({})();
      setSelectedTab(MenuCategory.TEST_HISTORY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIdx]);

  const testHistoryPageCondition = useMemo(
    () => ({
      page: testHistoryList?.number ?? 0,
      size: testHistoryList?.size ?? 5,
      sort: ordersToSortArr(testHistoryList?.orders),
    }),
    [testHistoryList]
  );

  const getHistoryList = useCallback((param: SearchCondition) => {
    callAsync(
        dispatch(getTestHistoriesByUserProfileAsync({ userProfileIdx: profileIdx, condition: param })).unwrap(),
        (result) => {
          setTestHistoryList(result);
        },
        () => alert(defaultErrorMessage)
    ).then();
    //eslint-disable-next-line
  }, [dispatch, profileIdx, testHistoryList]);



  const getTestHistoryList = ({ page, sort, size }: PageSearchCondition) => {
    const param: SearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: size ?? testHistoryPageCondition.size,
        sort: sort ?? testHistoryPageCondition.sort,
      },
    };

    getHistoryList(param)
    return () => {
      callAsync(
        dispatch(getTestHistoriesByUserProfileAsync({ userProfileIdx: profileIdx, condition: param })).unwrap(),
        (result) => setTestHistoryList(result),
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  const descriptionValue: DescriptionValue[][] = useMemo(() => {
    if (profile) {
      return [
        [
          { key: t("detail.profile.detail.name"), value: profile.name },
          {
            key: t("detail.profile.detail.createdAt"),
            value: <>{timeConverter.convertToLocalTime(profile.createAt)}</>,
          },
        ],
        [
          {
            key: t("detail.profile.detail.age.label"),
            value: (
              <span>
                {dateStringToDottedString(profile.birthday)}
                {profile.age ? <> ({t("detail.profile.detail.age.unit", { age: profile.age })})</> : <></>}
              </span>
            ),
          },
          {
            key: t("detail.profile.detail.userCode"),
            value: <>{profile.userCode ? <CopiableText text={profile.userCode} isBlue={false} /> : <>-</>}</>,
            menuCode: AdminMenuCode.USER_DETAIL_TESTER_USER_CODE_READ,
          },
        ],
        [
          { key: t("detail.profile.detail.gender"), value: toStringFromGender(profile.gender) },
          {
            key: t("nationality.label", { ns: "common" }),
            value: <>{getCountryNameByCodeISOAlpha2(profile.nationality)}</>,
          },
        ],
        [
          {
            key: t("detail.profile.detail.organ"),
            value: (
              <ul className={userDetailStyle.listElement}>
                {!profile.organ || profile.organ.length === 0
                  ? "-"
                  : profile.organ.map((o) => (
                      <li key={`organ-idx-${o.idx}`}>
                        <RoleChecker
                          menuCode={AdminMenuCode.USER_DETAIL_ORGAN_READ}
                          alternativeChildren={<>{o.organName}</>}
                        >
                          <AnchorComponent
                            onClickFunc={(e) => {
                              e.preventDefault();
                              openNewWindowWithURL(
                                `/detail/member/organ-manager/${o.organManagerIdx}?tab=ORGAN&organ=${o.idx}`,
                                {
                                  width: 1000,
                                  height: 800,
                                }
                              );
                            }}
                            content={o.organName}
                          />
                        </RoleChecker>
                      </li>
                    ))}
              </ul>
            ),
          },
          {
            key: t("detail.profile.detail.organVoucher.label"),
            value: profile.organVoucher ? (
              <OrganVoucherWrapperStyle>
                {t("voucherUnit", { count: 1 })} ({profile.organVoucher.organName})
                <CopiableText text={profile.organSignInCode} isBlue={true} />
              </OrganVoucherWrapperStyle>
            ) : (
              <>
                {profile.organSignInCode ? (
                  <OrganVoucherWrapperStyle>
                    {t("voucherUnit", { count: 0 })} (-) <CopiableText text={profile.organSignInCode} isBlue={true} />
                  </OrganVoucherWrapperStyle>
                ) : (
                  <>-</>
                )}
              </>
            ),
          },
        ],
        [
          {
            key: t("detail.profile.detail.country"),
            value: getCountryNameByCodeISOAlpha2(profile?.address?.country) ?? "-",
          },
          { key: t("detail.profile.detail.region"), value: profile.state ?? "-" },
        ],
        [
          {
            key: t("detail.profile.detail.address"),
            value: profile.address ? toAddressLine1(profile.address) : t("noInputData", { ns: "common" }),
          },
        ],
        [
          {
            key: t("detail.profile.detail.pretestPassedOrNot.label"),
            value: (
              <>
                {profile.isPassedPretest === null
                  ? `-`
                  : profile.isPassedPretest
                  ? `${t("detail.profile.detail.pretestPassedOrNot.passed")} (${timeConverter.convertToLocalTime(
                      profile.pretestPassAt
                    )})`
                  : t("detail.profile.detail.pretestPassedOrNot.notPassed")}
              </>
            ),
          },
        ],
      ];
    }

    return [];
  }, [getCountryNameByCodeISOAlpha2, t, timeConverter, toStringFromGender, profile, openNewWindowWithURL]);

  const categoryMenus: CategoryTab<MenuCategory>[] = useMemo(
    () => [
      {
        tabName: t("detail.profile.detail.testHistory.label"),
        tabRightValue: t("detail.profile.detail.testHistory.unit", { count: testHistoryList?.totalElements ?? 0 }),
        tabValue: MenuCategory.TEST_HISTORY,
      },
      {
        tabName: t("detail.profile.detail.organVoucher.label"),
        tabRightValue: t("voucherNoUnit", {
          count: (
              (userOrganVoucherNewStatusInfo?.voucherDTOs[0]?.availableOrganVoucherCount ?? 0) +
              (userOrganVoucherNewStatusInfo?.voucherDTOs[1]?.availableOrganVoucherCount ?? 0)
          )
        }),
        tabValue: MenuCategory.ORGAN_VOUCHER,
        menuCode: AdminMenuCode.USER_DETAIL_TESTER_ORGAN_VOUCHER,
      },
    ],
    [t, testHistoryList, userOrganVoucherNewStatusInfo]
  );

  const onClickDeleteProfileButton = useCallback(() => {
    if (profile?.organVoucher) {
      alert(t("detail.profile.delete.cannotDeleteAlertMessage"));
    } else {
      const isConfirmed = window.confirm(t("detail.profile.delete.confirmMessage", { name: profile?.name ?? "" }));
      if (isConfirmed) {
        deleteUserProfiles([profileIdx]);
      }
    }
  }, [t, profile?.organVoucher, profile?.name, profileIdx, deleteUserProfiles]);

  return (
    <>
      {profile ? (
        <>
          <Panel
            title={t("detail.profile.detail.title")}
            subtitle={`(${profile.name})`}
            additionalButtons={
              <>
                {profile.isOwnerProfile ? (
                  <></>
                ) : (
                  <DetailActionButton onClick={onClickDeleteProfileButton}>
                    <img src={deleteIcon} alt={t("detail.profile.delete.iconAlt")} />
                    {t("detail.profile.delete.buttonLabel")}
                  </DetailActionButton>
                )}
              </>
            }
          >
            <DescriptionList data={descriptionValue} labelWidth={134} />
          </Panel>
          <DetailSubMenuCategories tabs={categoryMenus} selectedTab={selectedTab} onClickTab={setSelectedTab} />
          {selectedTab === MenuCategory.TEST_HISTORY ? (
            <ProfileTestHistory list={testHistoryList} name={profile.name} getList={getTestHistoryList} />
          ) : (
            <ProfileOrganVoucher profileIdx={profileIdx} />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
