import { List } from "../../DocComponents";
import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { ListFilter } from "../../../layout/content/list/ListFilter";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { sampleFilters } from "./data";

export function ArticleListFilter({ id, title }: ArticleProps) {
  const filters = sampleFilters;

  const code = `
const filters: TableFilter[][] = ${JSON.stringify(filters, null, 2)};

<ListFilter filters={filters}
            doSearch={() => alert('검색 클릭!')}/>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code}>
          <ListFilter filters={filters} doSearch={() => alert("검색 클릭!")} />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
