import style from "../productEditForm.module.scss";
import {Selector} from "../../../../layout/content/selector/Selector";
import {DisplayPosition} from "../../../../types/product";
import React from "react";
import {Label104x46} from "../../../../components/Label";
import {useTranslation} from "react-i18next";
import {useProductType} from "../../../../types/hooks/useProductType";
import {useLanguage} from "../../../../hooks/hooks";
import {Languages} from "../../../../types/common";

interface Props {
  displayPosition: DisplayPosition;
  setDisplayPosition: (_: DisplayPosition) => void;
  setLanguage: (_: Languages) => void;
  isCreatePage: boolean;
}

export function ProductEditDisplayPosition({ displayPosition, setDisplayPosition, setLanguage, isCreatePage }: Props) {
  const { t } = useTranslation("product");
  const { displayPositionOptions, toStringFromDisplayPosition } = useProductType();
  const { isKorean } = useLanguage();
  function handleLanguages() {
    setLanguage(Languages.KO);
  }

  return (
    <div className={style.field}>
      <Label104x46 text={t("edit.displayPosition.title")} className={style.label} />
      {isCreatePage ? (
        <Selector
          isPageSizeSelector={false}
          options={displayPositionOptions}
          selectedValue={displayPosition}
          setSelectorOption={(it) => {
            setDisplayPosition(it.value as DisplayPosition);
            handleLanguages();
          }}
          width={isKorean ? undefined : 200}
        />
      ) : (
        <span className={`${style.displayPositionDisabled} ${isKorean ? "" : style.notKO}`}>
          {toStringFromDisplayPosition(displayPosition)}
        </span>
      )}
    </div>
  );
}
