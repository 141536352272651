import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DEFAULT_PAGE, DEFAULT_SIZE, DEFAULT_SORT, PageSearchCondition } from "../../types/page";
import { ListQueryType } from "../../types/list";
import { useSortParam } from "./useSortParam";

export enum PageKeys {
  PAGE = "page",
  SIZE = "size",
  SORT = "sort",
}

export function useListSearchParams<T extends ListQueryType>() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [listQueryType, setListQueryType] = useState<T>();
  const { toQueryParam } = useSortParam();

  const removeUndefinedValue = useCallback(
    (param: any) =>
      Object.entries(param)
        .filter(([_, v]) => v === 0 || Boolean(v))
        .reduce((acc: any, [k, v]) => {
          acc[k] = v;
          return acc;
        }, {}),
    []
  );

  useEffect(() => {
    const page = searchParams.get(PageKeys.PAGE);
    const size = searchParams.get(PageKeys.SIZE);
    const sort = searchParams.get(PageKeys.SORT);

    const listQueryObject: any = {
      page: page ?? undefined,
      size: size ?? undefined,
      sort: sort ?? undefined,
    };
    searchParams.forEach((v, k) => {
      listQueryObject[k as keyof T] = v as string;
    });

    setListQueryType(listQueryObject as T);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setUrlSearchParams = useCallback(
    (param: ListQueryType, { page, size, sort }: PageSearchCondition) => {
      setSearchParams(
        removeUndefinedValue({
          ...param,
          page: page?.toString() ?? param.page ?? DEFAULT_PAGE,
          size: size?.toString() ?? param.size ?? DEFAULT_SIZE,
          sort: (sort ? toQueryParam(sort) : param.sort) ?? DEFAULT_SORT,
        })
      );
    },
    [toQueryParam, removeUndefinedValue, setSearchParams]
  );

  return {
    listQueryType,
    setListQueryType,
    setUrlSearchParams,
  };
}
