import { Navigate, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/hooks";
import { CookieKeys, useCookie } from "../../../hooks/auth/useCookie";
import { AccessibleMenuProvider } from "../../../hooks/accessiblemenu/AccessibleMenuProvider";
import { useMyInfoGetter } from "../mypage/hooks/useMyInfoGetter";
import { useUtil } from "../../../util/hooks/useUtil";

export function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const auth = useAuth();
  const { getValueFromCookie } = useCookie();
  const { getMyInfo } = useMyInfoGetter();
  const { defaultErrorMessage } = useUtil();

  const [resultNode, setResultNode] = useState(<></>);

  const LoginNode = useCallback(() => <Navigate to="/login" state={{ from: location }} />, [location]);

  useEffect(() => {
    const savedAccessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
    const savedEmail = getValueFromCookie(CookieKeys.LOGIN_EMAIL);
    const savedRefreshToken = getValueFromCookie(CookieKeys.REFRESH_TOKEN);

    if (!savedAccessToken) {
      if (!savedRefreshToken) {
        setResultNode(<LoginNode />);
      } else {
        auth.getAccessTokenFromRefreshToken(savedRefreshToken).then(() => {
          getMyInfo(() => {
            setResultNode(<AccessibleMenuProvider>{children}</AccessibleMenuProvider>);
          });
        });
      }
    } else {
      auth.setAccessToken(savedAccessToken);
      auth.setUserEmail(savedEmail ?? null);

      getMyInfo(
        () => {
          setResultNode(<AccessibleMenuProvider>{children}</AccessibleMenuProvider>);
        },
        (error) => {
          if (error.errorCode.httpCode === 401) {
            auth
              .getAccessTokenFromRefreshTokenCookie()
              .then(() => {
                setResultNode(<AccessibleMenuProvider>{children}</AccessibleMenuProvider>);
              })
              .catch(() => {
                auth.signOut(() => {
                  setResultNode(<LoginNode />);
                });
              });
          } else {
            alert(defaultErrorMessage);
          }
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return resultNode;
}
