import {TableColumnData} from "../../../types/common";
import {OrderListVO} from "../../../types/orders";
import {convertCurrencyUnit, numberWithCommas} from "../../../util/etcUtil";
import React from "react";
import {LinkStyle} from "../../../components/styled/LinkStyle";
import {OrderItemColumn} from "../component/OrderItemColumn";
import {OrderStatusStyle} from "../component/OrderStatusStyle";
import {useNationality, useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {useOrderButtons} from "./useOrderButtons";
import {ActionButtonWrapper} from "../../../layout/content/ActionButtonWrapper";
import {useTranslation} from "react-i18next";
import {useOrdersType} from "../../../types/hooks/useOrdersType";
import {usePaymentType} from "../../../types/hooks/usePaymentType";
import {useProductType} from "../../../types/hooks/useProductType";
import style from "../order.module.scss";
import {AnchorComponent} from "../../../layout/content/AnchorComponent";

interface Args {
  onRefresh: () => void;
}
export default function useOrderColumns({ onRefresh }: Args): TableColumnData<OrderListVO>[] {
  const { t } = useTranslation("order");
  const { openCustomerPopup, openOrderDetailPopup, openOrganManagerDetailAffiliateCodeTabPopup } = useNewWindow();
  const { MemoButton, DeleteButton } = useOrderButtons({ onRefresh });
  const { timeConverter } = useTimeConverter();
  const { toStringFromOrderStatus } = useOrdersType();
  const { toStringFromPaymentWay } = usePaymentType();
  const { toStringFromDisplayPosition } = useProductType();
  const { isKorean } = useNationality();

  return [
    {
      accessor: "idx",
      header: t("columns.orderedAt"),
      getNode: (o: OrderListVO) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            openOrderDetailPopup(o.idx);
          }}
        >
          <LinkStyle>{timeConverter.convertToLocalTime(o.createAt)}</LinkStyle>
          <br />
          {o.paidAt && <span>({timeConverter.convertToLocalTime(o.paidAt)})</span>}
        </div>
      ),
      sortable: true,
    },
    {
      accessor: "buyerName",
      header: t("columns.buyerName"),
      sortable: false,
      getNode: (o: OrderListVO) => (
        <LinkStyle
          onClick={(e) => {
            e.preventDefault();
            openCustomerPopup(o.buyerIdx, o.customerType);
          }}
        >
          {o.buyerName}
        </LinkStyle>
      ),
      width: 104,
    },
    {
      accessor: "displayPosition",
      header: t("columns.displayPosition"),
      sortable: false,
      getNode: (o: OrderListVO) => <span>{toStringFromDisplayPosition(o.displayPosition)}</span>,
    },
    {
      accessor: "orderTitle",
      header: t("columns.productName"),
      sortable: false,
      getNode: (o: OrderListVO) => <OrderItemColumn orders={o} />,
      width: 370,
    },
    {
      accessor: "regularPriceAmount",
      header: t("columns.totalPrice"),
      sortable: false,
      getNode: (o: OrderListVO) => <>
        {numberWithCommas(o.regularPriceAmount)}{convertCurrencyUnit(o.paymentWay, t)}
      </>,
      isRightAlign: true,
      className: style.rightPadding12,
    },
    {
      accessor: "payAmount",
      header: t("columns.actualAmount"),
      sortable: false,
      getNode: (o: OrderListVO) =>
      <>
        {numberWithCommas(o.payAmount)}{convertCurrencyUnit(o.paymentWay, t)}
      </>,
      isRightAlign: true,
      className: style.rightPadding12,
    },
    {
      accessor: "paymentWay",
      header: t("columns.paymentWay"),
      sortable: false,
      getNode: (o: OrderListVO) => <span>{toStringFromPaymentWay(o.paymentWay)}</span>,
    },
    {
      accessor: "status",
      header: t("columns.status"),
      sortable: false,
      getNode: (o: OrderListVO) => (
        <OrderStatusStyle orderStatus={o.status}>{toStringFromOrderStatus(o.status)}</OrderStatusStyle>
      ),
    },
    {
      accessor: "affiliateCode",
      header: "제휴코드",
      sortable: false,
      getNode: (o: OrderListVO) => (
        <>
          {o.affiliateCode ? (
            <AnchorComponent
              onClickFunc={(e) => {
                e.preventDefault();
                openOrganManagerDetailAffiliateCodeTabPopup(o.organManagerIdx, o.affiliateCode);
              }}
              content={o.affiliateCode}
            />
          ) : (
            "-"
          )}
        </>
      ),
      hideThisColumn: !isKorean,
    },
    {
      accessor: "idx",
      header: t("columns.additionalFunctions"),
      getNode: (o: OrderListVO) => (
        <ActionButtonWrapper>
          <>
            <MemoButton orderIdx={o.idx} hasMemo={o.hasMemo} />
            <DeleteButton orderIdx={o.idx} orderStatus={o.status} />
          </>
        </ActionButtonWrapper>
      ),
      sortable: false,
    },
  ];
}
