import {useTranslation} from "react-i18next";
import {useCallback, useMemo} from "react";
import {MenuExposedStatus, OrganManagerStatus} from "../organManager";
import {SelectorOption} from "../selector";
import {VoucherPurchaseRestrictions} from "../voucher";
import {AdditionalResultType, TestResultFormType} from "../common";

export function useOrganManagerType() {
  const { t } = useTranslation("organManager");

    const toStringAdditionalType = useCallback(
        (type: AdditionalResultType) =>  {
            switch (type) {
                case AdditionalResultType.SPECIALIZED_HIGH_SCHOOL:
                    return t("additionalType.specializedSchool")
                case AdditionalResultType.CORPORATE_INTERVIEW:
                    return t("additionalType.interview")
                case AdditionalResultType.NONE:
                    return t("additionalType.none")
            }
        },[t]);

    const toStringTestResultForm = useCallback(
        (resultType: TestResultFormType) =>  {
            switch (resultType) {
                case TestResultFormType.FORMAL:
                    return t("testResultForm.official")
                case TestResultFormType.SUMMARY:
                    return t("testResultForm.summary")
                case TestResultFormType.ADDITIONAL:
                    return t("testResultForm.additional")
            }
        },[t]);


    const toStringVoucherTypeLimitation = useCallback(
        (type: VoucherPurchaseRestrictions) => {
            switch (type) {
                case VoucherPurchaseRestrictions.KPASS:
                    return t("voucherTypeLimitation.kpass")
                case VoucherPurchaseRestrictions.DCAS:
                    return t("voucherTypeLimitation.dcas")
                case VoucherPurchaseRestrictions.NONE:
                    return t("voucherTypeLimitation.noLimit")
                default:
                    return t("voucherTypeLimitation.noLimit")
            }
        },[t]);

    const toStringFromMenuExposed = useCallback(
        (menu: MenuExposedStatus) =>{
            switch (menu) {
                case MenuExposedStatus.HOME_AND_ORGAN_MANAGEMENT:
                    return t("menuExposedType.home");
                case MenuExposedStatus.PURCHASE_VOUCHER:
                    return t("menuExposedType.voucher");
                case MenuExposedStatus.AFFILIATE_CODE:
                    return t("menuExposedType.affiliateCode");
                case MenuExposedStatus.PARENT_DISPOSITION_TEST:
                    return t("menuExposedType.parentPropensity");
            }
        },[t])

    const menuExposedOptions: SelectorOption[] = useMemo(
        () =>
            Object.keys(MenuExposedStatus)
                .flatMap((key) => {
                    const valueObj = MenuExposedStatus[key as keyof typeof MenuExposedStatus];

                    if (typeof valueObj === "string") {
                        return [
                            {
                                value: key,
                                label: toStringFromMenuExposed(valueObj),
                            },
                        ];
                    } else {
                        return [];
                    }
                })
                .sort((a, b) => (a.label < b.label ? -1 : 1)),
        [toStringFromMenuExposed]
    );

    const toStringFromNations: SelectorOption[] = useMemo(() => {
        const options =
            [
                {value: 'KR', label: t("nations.ko")},
                {value: 'US', label: t("nations.us")},
                {value: 'MN', label: t("nations.mn")},
                {value: 'TW', label: t("nations.tw")},
                {value: 'VW', label: t("nations.vw")},
            ]
        return options ?? [];
    }, [t]);

    const nationOptions: SelectorOption[] = useMemo(() => {
        return [{label: t("nations.none"), value: ""},
            ...toStringFromNations.filter((o) => o.value !== "")];
    }, [t, toStringFromNations]);



  const toStringFromOrganManagerStatus = useCallback(
    (status: OrganManagerStatus) => {
      switch (status) {
        case OrganManagerStatus.DEFAULT:
          return t("organManagerStatus.default");
        case OrganManagerStatus.REVOKED_SELF_PHONE_AUTHENTICATION:
          return t("organManagerStatus.default");
        case OrganManagerStatus.BEFORE_SELF_MAIL_AUTHENTICATION:
          return t("organManagerStatus.isSigningUp");
        case OrganManagerStatus.BLOCKED:
          return t("organManagerStatus.blocked");
        case OrganManagerStatus.LEAVE:
          return t("organManagerStatus.leaved");
      }
    },
    [t]
  );

  const organManagerStatusOptions = useMemo(
    () => [
      ...Object.keys(OrganManagerStatus).flatMap((key) => {
        const valueObj = OrganManagerStatus[key as keyof typeof OrganManagerStatus];

        if (typeof valueObj === "string" && valueObj !== OrganManagerStatus.REVOKED_SELF_PHONE_AUTHENTICATION) {
          return [
            {
              value: key,
              label: toStringFromOrganManagerStatus(valueObj),
            },
          ];
        } else {
          return [];
        }
      }),
    ],
    [toStringFromOrganManagerStatus]
  );

  return {
    toStringFromOrganManagerStatus,
    organManagerStatusOptions,
    toStringFromNations,
    nationOptions,
    toStringFromMenuExposed,
    menuExposedOptions,
    toStringVoucherTypeLimitation,
    toStringTestResultForm,
    toStringAdditionalType,
  };
}
