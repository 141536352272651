import { SearchCondition } from "./page";
import { ActionUser } from "./dataCommon";

enum MemoTargetType {
  USER = "USER",
  ORDER = "ORDER",
  ORGAN_MANAGER = "ORGAN_MANAGER",
}

enum MemoType {
  GENERAL = "GENERAL",
  INQUIRY = "INQUIRY",
}

interface Memo {
  idx: number;
  targetIdx: number;
  targetType: MemoTargetType;
  memo: string;
  type: MemoType;
  createBy: ActionUser;
  createAt: string;
}

interface MemoSearchCondition extends SearchCondition {
  targetIdx: number;
  targetType: MemoTargetType;
}

interface MemoPostParam {
  memo: string;
  type: MemoType;
  targetIdx: number;
  targetType: MemoTargetType;
}

interface MemoPutParam {
  memo: string;
  type: MemoType;
}

export type { MemoSearchCondition, MemoPostParam, MemoPutParam, Memo };
export { MemoTargetType, MemoType };
