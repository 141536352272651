import { useCallback } from "react";
import { OrderDirection, Sort } from "../../types/page";

export function useSortParam() {
  const toQueryParam = useCallback(
    (sort: Sort[]) => JSON.stringify(sort.map((s) => `${s.columnName},${s.direction}`)),
    []
  );

  const toSortArray = useCallback((queryParam: string): Sort[] => {
    const parsed = JSON.parse(queryParam) as string[];
    return parsed.map((s) => {
      const [column, direction] = s.split(",");
      return {
        columnName: column as string,
        direction: direction as OrderDirection,
      };
    });
  }, []);

  return {
    toQueryParam,
    toSortArray,
  };
}
