import { useListFilterValues } from "../../../../hooks/hooks";
import { usePartnerCompanyType } from "../../../../types/hooks/usePartnerCompanyType";
import { useMemo } from "react";
import { FilterContentType, TableFilter } from "../../../../types/tableFilter";
import { LinkStatus, PartnerCompanyStatus, PartnerCompanyType } from "../../../../types/partnerCompany";

interface Args {
  search: () => void;
}

export function usePartnerCompanyFilters({ search }: Args) {
  const { filterValues } = useListFilterValues();
  const { getPartnerCompanyTypeLabel, getPartnerCompanyStatusLabel, getLinkStatusLabel } = usePartnerCompanyType();

  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const typeFilter = useMemo(() => filterValues.typeFilterValue, [filterValues.typeFilterValue]);
  const statusFilter = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);
  const linkStatusFilter = useMemo(() => filterValues.linkStatusFilterValue, [filterValues.linkStatusFilterValue]);

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: "협력사명 검색",
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter?.value,
          maxLength: 100,
          placeholderVal: "검색할 협력사명 입력",
          onChangeFunc: (v) => searchWordFilter.setter(v),
          onEnterKeyUpFunc: search,
        },
      },
    ],
    [
      {
        labelTitle: "협력사 유형",
        content: {
          type: FilterContentType.SELECT,
          value: typeFilter.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getPartnerCompanyTypeLabel(PartnerCompanyType.DOMESTIC),
              value: PartnerCompanyType.DOMESTIC.toString(),
            },
            {
              label: getPartnerCompanyTypeLabel(PartnerCompanyType.OVERSEAS),
              value: PartnerCompanyType.OVERSEAS.toString(),
            },
          ],
          onChangeFunc: (v) => typeFilter.setter(v as unknown as PartnerCompanyType),
        },
      },
      {
        labelTitle: "제휴코드 여부",
        content: {
          type: FilterContentType.SELECT,
          value: linkStatusFilter.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getLinkStatusLabel(LinkStatus.LINK),
              value: LinkStatus.LINK.toString(),
            },
            {
              label: getLinkStatusLabel(LinkStatus.UNLINK),
              value: LinkStatus.UNLINK.toString(),
            },
            {
              label: getLinkStatusLabel(LinkStatus.DELETED),
              value: LinkStatus.DELETED.toString(),
            },
          ],
          onChangeFunc: (v) => linkStatusFilter.setter(v as unknown as LinkStatus),
        },
      },
      {
        labelTitle: "상태",
        content: {
          type: FilterContentType.SELECT,
          value: statusFilter.value,
          options: [
            { label: "전체", value: "" },
            {
              label: getPartnerCompanyStatusLabel(PartnerCompanyStatus.EXPOSED),
              value: PartnerCompanyStatus.EXPOSED.toString(),
            },
            {
              label: getPartnerCompanyStatusLabel(PartnerCompanyStatus.UNEXPOSED),
              value: PartnerCompanyStatus.UNEXPOSED.toString(),
            },
          ],
          onChangeFunc: (v) => statusFilter.setter(v as unknown as PartnerCompanyStatus),
        },
      },
    ],
  ];

  return {
    filters,
  };
}
