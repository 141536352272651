import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { UnitItemPostParam, UnitItemSearchCondition } from "../types/unitItem";
import { addCases, executePromise } from "../util/sliceUtil";
import { UnitItemApi } from "../api/UnitItemApi";
import { PageSearchCondition } from "../types/page";

export interface UnitItemState extends SliceState {}

const initialState: UnitItemState = {
  status: Status.IDLE,
};

export const getUnitItemListAsync = createAsyncThunk("unitItem/getUnitItemList", (condition: UnitItemSearchCondition) =>
  executePromise(UnitItemApi.getUnitItemList(condition))
);

export const deleteUnitItemsAsync = createAsyncThunk("unitItem/deleteUnitItems", (unitItemIdxes: number[]) =>
  executePromise(UnitItemApi.deleteUnitItems(unitItemIdxes))
);

export const checkWhetherNameIsUniqueAsync = createAsyncThunk("unitItem/checkWhetherNameIsUnique", (name: string) =>
  executePromise(UnitItemApi.checkWhetherNameIsUnique(name))
);

export const createUnitItemAsync = createAsyncThunk("unitItem/createUniitItem", (param: UnitItemPostParam) =>
  executePromise(UnitItemApi.createUnitItem(param))
);

export const getUnitItemDetailAsync = createAsyncThunk("unitItem/getUnitItemDetail", (unitItemIdx: number) =>
  executePromise(UnitItemApi.getUnitItemDetail(unitItemIdx))
);

export const getLinkedProductsAsync = createAsyncThunk(
  "unitItem/getLinkedProducts",
  ({ unitItemIdx, pageCondition }: { unitItemIdx: number; pageCondition: PageSearchCondition }) =>
    executePromise(UnitItemApi.getLinkedProducts(unitItemIdx, pageCondition))
);

export const unitItemSlice = createSlice({
  name: "unitItem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, createUnitItemAsync);
    addCases(builder, checkWhetherNameIsUniqueAsync);
    addCases(builder, getUnitItemDetailAsync);
    addCases(builder, getLinkedProductsAsync);
    addCases(builder, getUnitItemListAsync);
  },
});

export default unitItemSlice.reducer;

export const unitItemStatus = (state: RootState) => state.unitItem.status;
