enum OrderDirection {
  ASC = "ASC",
  DESC = "DESC",
}

interface Orders {
  direction: OrderDirection;
  property: string;
  ignoreCase: boolean;
  nullHandling: "NATIVE" | "NULLS_FIRST" | "NULLS_LAST";
  ascending: boolean;
  descending: boolean;
}

interface Pageable {
  offset: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

interface PageResponse<T> {
  totalPages: number;
  totalElements: number;
  number: number;
  size: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
  empty: boolean;
  orders: Orders[];
  pageable: Pageable;
  content: Array<T>;
}

export const getPageResponseInitialized = <T>(content: Array<T>, pageSize: number) => ({
  totalPages: 1,
  totalElements: 1,
  number: 0,
  size: pageSize,
  numberOfElements: 1,
  first: true,
  last: true,
  empty: false,
  orders: [],
  pageable: {
    offset: 0,
    sort: {
      sorted: false,
      unsorted: true,
      empty: false,
    },
    pageNumber: 0,
    pageSize: 1000,
    paged: true,
    unpaged: false,
  },
  content: content,
});

interface Sort {
  columnName: string;
  direction: OrderDirection;
}

interface PageSearchCondition {
  page?: number;
  size?: number;
  sort?: Sort[] | null;
}

interface SearchCondition {
  pageCondition?: PageSearchCondition;
}

export const DEFAULT_PAGE = 0;
export const DEFAULT_SIZE = 20;

export const DEFAULT_SIZE_IN_DETAIL = 5;
export const DEFAULT_SIZE_IN_SUB_TABLE = 1000;
export const DEFAULT_SORT = undefined;

export const ordersToSortArr = (orders: Orders[] | undefined): Sort[] =>
  orders?.map((o) => ({ columnName: o.property, direction: o.direction })) ?? [];

export type {
  Orders,
  Pageable,
  PageResponse,
  Sort,
  PageSearchCondition,
  SearchCondition,
  // PageParam
};

export { OrderDirection };
