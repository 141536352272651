import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {useCallback, useEffect} from "react";
import {callAsync} from "../../../../../util/sliceUtil";
import {
  getProfileOrganVoucherCurrentStatusAsync,
  getUserOrganVoucherCurrentStatusAsync,
  organVoucherNewStatus,
  organVoucherStatusInfo,
  setOrganVoucherNewStatus
} from "../../../../../store/voucherSlice";
import {OrganVoucherNewStatus} from "../../../../../types/voucher";
import {useUtil} from "../../../../../util/hooks/useUtil";

interface Args {
  userIdx: number;
}


interface Args {
  userIdx: number;
  isTesterType: boolean;
}

export function useUserOrganVoucherCurrentStatus({ userIdx, isTesterType }: Args) {
  const dispatch = useAppDispatch();
  const userOrganVoucherNewStatusInfo = useAppSelector(organVoucherNewStatus);
  const organVoucherStatus = useAppSelector(organVoucherStatusInfo);
  const { defaultErrorMessage } = useUtil();

  const getOrganVoucherStatusInfo = useCallback(() => {
    callAsync(

        isTesterType
            ? dispatch(getProfileOrganVoucherCurrentStatusAsync(userIdx)).unwrap()
            : dispatch(getUserOrganVoucherCurrentStatusAsync(userIdx)).unwrap(),
        (result: OrganVoucherNewStatus) => {dispatch(setOrganVoucherNewStatus(result));},
        () => alert(defaultErrorMessage)
    ).then();
    //eslint-disable-next-line
  }, [dispatch, userIdx, defaultErrorMessage]);

  useEffect(() => {
    if (!userOrganVoucherNewStatusInfo) {
      getOrganVoucherStatusInfo();
    }
  }, [userOrganVoucherNewStatusInfo, getOrganVoucherStatusInfo]);

  return {
    getOrganVoucherStatusInfo,
    userOrganVoucherNewStatusInfo: userOrganVoucherNewStatusInfo,
    organVoucherStatus : organVoucherStatus,
  };
}
