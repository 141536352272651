import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import style from "../../style/grantModal.module.scss";
import { Panel } from "../../../layout/content/detail/Panel";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import queryString from "query-string";
import { useAppDispatch, useAppSelector, useLanguage, useNewWindow, useTimeConverter } from "../../../hooks/hooks";
import { callAsync } from "../../../util/sliceUtil";
import { collectVoucherAsync, voucherStatus } from "../../../store/voucherSlice";
import { ProductVoucherDTO, VoucherStatus } from "../../../types/voucher";
import { WindowMessage } from "../../../types/newWindow";
import { ContentTable } from "../../../layout/content/detail/ContentTable";
import { ValidDurationFrom } from "../../../types/unitItem";
import { useLoader } from "../../../hooks/common/useLoader";
import { Textarea } from "../../../components/Textarea";
import { useVoucherGetter } from "../../voucher/useVoucherGetter";
import { Trans, useTranslation } from "react-i18next";
import { useUnitItemType } from "../../../types/hooks/useUnitItemType";
import { useVoucherType } from "../../../types/hooks/useVoucherType";
import { useUtil } from "../../../util/hooks/useUtil";

export function CollectProductModal() {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();
  const status = useAppSelector(voucherStatus);
  const { timeConverter } = useTimeConverter();
  useLoader({ status });
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { defaultErrorMessage } = useUtil();
  const { toStringFromVoucherType } = useVoucherType();
  const { isKorean } = useLanguage();

  const { voucherIdx, userIdx, userName } = queryString.parse(window.location.search);

  const thisVoucherIdx = useMemo(() => Number(voucherIdx), [voucherIdx]);

  const [reason, setReason] = useState("");
  const [voucherDetail, setVoucherDetail] = useState<ProductVoucherDTO>();
  const [selectedUnitVouchers, setSelectedUnitVouchers] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const { getVoucherDetail } = useVoucherGetter();
  const { toStringFromVoucherStatus } = useVoucherType();

  useEffect(() => {
    callAsync<ProductVoucherDTO>(
      getVoucherDetail(thisVoucherIdx),
      (result) => setVoucherDetail(result),
      () => alert(defaultErrorMessage)
    ).then();
  }, [getVoucherDetail, thisVoucherIdx, defaultErrorMessage]);

  const onClickCollectBtn = () => {
    if (selectedUnitVouchers.length === 0) {
      alert(t("collectProduct.noSelectedUnitItem"));
      return;
    }

    if (!reason) {
      alert(t("collectProduct.noReason"));
      return;
    }

    const isConfirmed = window.confirm(t("collectProduct.confirm"));
    if (isConfirmed) {
      callAsync(
        dispatch(
          collectVoucherAsync({
            voucherIdx: thisVoucherIdx,
            param: {
              userIdx: Number(userIdx),
              collectReason: reason,
              unitVoucherIdxes: selectedUnitVouchers,
            },
          })
        ).unwrap(),
        () => {
          alert(t("collectProduct.result"));
          closeWindowAndNotifyToParentWindow(WindowMessage.COLLECT_VOUCHER);
        },
        () => alert(defaultErrorMessage)
      ).then();
    }
  };

  const isSelectedVoucher = useCallback(
    (idx: number) => {
      return selectedUnitVouchers.findIndex((suvIdx) => suvIdx === idx) > -1;
    },
    [selectedUnitVouchers]
  );

  const onClickCheckbox = (e: React.MouseEvent, idx: number) => {
    const checked = (e.target as HTMLInputElement).checked;
    if (checked) {
      setSelectedUnitVouchers([...selectedUnitVouchers, idx]);
    } else {
      const foundIdx = selectedUnitVouchers.findIndex((uvIdx) => uvIdx === idx);
      const copiedSelectedUnitVouchers = [...selectedUnitVouchers];
      copiedSelectedUnitVouchers.splice(foundIdx, 1);
      setSelectedUnitVouchers(copiedSelectedUnitVouchers);
    }
  };

  useEffect(() => {
    if (allSelected) {
      setSelectedUnitVouchers(
        voucherDetail?.unitVouchers.flatMap((uv) => {
          if (uv.unitVoucherStatus === VoucherStatus.USABLE) {
            return [uv.idx];
          } else {
            return [];
          }
        }) ?? []
      );
    } else {
      setSelectedUnitVouchers([]);
    }
  }, [allSelected, voucherDetail?.unitVouchers]);

  const { toStringFromValidDurationFrom } = useUnitItemType();
  const AvailableUntilValue = useCallback(
    ({ from, duration }: { from: ValidDurationFrom; duration: number }) => (
      <Trans
        t={t}
        i18nKey={"collectProduct.header.availableUntil.value"}
        components={[<>{toStringFromValidDurationFrom(from)}</>, <>{duration}</>]}
        values={{
          from: t("collectProduct.header.availableUntil.from"),
          dayUnit: t("collectProduct.header.availableUntil.dayUnit"),
        }}
      />
    ),
    [t, toStringFromValidDurationFrom]
  );

  const UserNameComponent = useCallback(() => <span className={style.name}>{userName}</span>, [userName]);

  return (
    <PanelWrapper>
      <Panel title={t("collectProduct.title")} panelClassName={style.panel}>
        <>
          <p className={style.desc}>
            {/*<span className={style.name}>{userName}</span>*/}
            <span className={style.otherDesc}>
              <Trans t={t} i18nKey={"collectProduct.description"} components={[<UserNameComponent />]} />
              {/*{t("collectProduct.description")}*/}
            </span>
          </p>
          {voucherDetail ? (
            <ContentTable
              data={{
                header: [
                  t("collectProduct.header.grantedAt"),
                  t("collectProduct.header.product"),
                  t("collectProduct.header.unitItem"),
                  t("collectProduct.header.type"),
                  t("collectProduct.header.availableUntil.label"),
                  <input
                    type={"checkbox"}
                    readOnly={true}
                    checked={allSelected}
                    onClick={(e) => setAllSelected((e.target as HTMLInputElement).checked)}
                  />,
                ],
                contents: voucherDetail.unitVouchers.map((uv) => [
                  timeConverter.convertToLocalTime(voucherDetail.createAt),
                  voucherDetail.productDTO.name,
                  uv.unitItem.name,
                  toStringFromVoucherType(uv.voucherType),
                  uv.availableUntil ? (
                    `${timeConverter.convertToLocalTime(voucherDetail.createAt)} ~ ${timeConverter.convertToLocalTime(
                      uv.availableUntil
                    )}`
                  ) : (
                    <AvailableUntilValue from={uv.unitItem.validDurationFrom} duration={uv.unitItem.validDuration} />
                  ),
                  uv.unitVoucherStatus === VoucherStatus.USABLE ? (
                    <input
                      type={"checkbox"}
                      readOnly={true}
                      value={uv.idx}
                      checked={isSelectedVoucher(uv.idx)}
                      onClick={(e) => onClickCheckbox(e, uv.idx)}
                    />
                  ) : (
                    toStringFromVoucherStatus(uv.unitVoucherStatus)
                  ),
                ]) ?? [[]],
              }}
            />
          ) : (
            <></>
          )}
          <div className={style.reasonArea}>
            <label className={`${style.label} ${isKorean ? "" : style.notKO}`}>
              {t("collectProduct.reason.label")}
            </label>
            <Textarea
              placeholder={t("collectProduct.reason.placeholder")}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              className={style.textarea}
            />
          </div>
          <div className={style.buttonArea}>
            <button onClick={onClickCollectBtn}>{t("collectProduct.reason.button")}</button>
          </div>
        </>
      </Panel>
    </PanelWrapper>
  );
}
