import {useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {useCallback, useEffect, useMemo, useState} from "react";
import {
    DEFAULT_PAGE,
    DEFAULT_SIZE,
    DEFAULT_SORT,
    ordersToSortArr,
    PageResponse,
    PageSearchCondition
} from "../../../../types/page";
import {BannerListVO, BannerLocation, BannerSearchCondition, BannerStatus, BannerType} from "../../../../types/banner";
import {useSortParam} from "../../../../hooks/list/useSortParam";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useBannerGetter} from "./useBannerGetter";
import {dateToString} from "../../../../util/dateUtil";
import {BannerListQueryType} from "./useBannerFilterValues";
import {WebsiteType} from "../../../../types/common";

export function useBannerList() {
    const {timeConverter} = useTimeConverter();
    const [bannerList, setBannerList] = useState<PageResponse<BannerListVO>>();
    const {filterValues, searchList, listQueryType} = useListFilterValues();

    const {toSortArray} = useSortParam();
    const {defaultErrorMessage} = useUtil();
    const [totalCount, setTotalCount] = useState<number | null>(null);
    const {getBanners} = useBannerGetter();
    const pageCondition = useMemo(
        () => ({
            page: bannerList?.number ?? DEFAULT_PAGE,
            size: bannerList?.size ?? DEFAULT_PAGE,
            sort: ordersToSortArr(bannerList?.orders),
        }), [bannerList]
    )


    // 배너 검색 결과 ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
    const searchWordFilterValue = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
    const availableStartAtFilterValue = useMemo(
        () => filterValues.availableStartAtFilterValue,
        [filterValues.availableStartAtFilterValue]
    );
    const availableEndAtFilterValue = useMemo(
        () => filterValues.availableEndAtFilterValue,
        [filterValues.availableEndAtFilterValue]
    );
    const typeFilterValue = useMemo(() => filterValues.typeFilterValue, [filterValues.typeFilterValue]);
    const websiteTypeFilterValue = useMemo(() => filterValues.websiteTypeFilterValue, [filterValues.websiteTypeFilterValue]);
    const positionFilterValue = useMemo(() => filterValues.positionFilterValue, [filterValues.positionFilterValue]);
    const statusFilterValue = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);

    const searchBannerList = useCallback(
        (pageSearchCondition: PageSearchCondition) => {
            const param: BannerListQueryType = {
                searchWord: searchWordFilterValue.value,
                availableStartAt: availableStartAtFilterValue.value
                    ? dateToString(availableStartAtFilterValue.value)
                    : undefined,
                availableEndAt: availableEndAtFilterValue.value ? dateToString(availableEndAtFilterValue.value) : undefined,
                type: typeFilterValue.value ? typeFilterValue.value : undefined,
                websiteType: websiteTypeFilterValue.value ? websiteTypeFilterValue.value : undefined,
                bannerLocation: positionFilterValue.value ? positionFilterValue.value : undefined,
                status: statusFilterValue.value ? statusFilterValue.value : undefined,
            };

            return () => {
                searchList(pageSearchCondition, param)();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            searchList,
            searchWordFilterValue,
            availableStartAtFilterValue,
            availableEndAtFilterValue,
            websiteTypeFilterValue,
            typeFilterValue,
            statusFilterValue,
        ]
    );
    // ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ

    const getBannerList = useCallback(
        async (condition: BannerSearchCondition, isFirstLoad?: boolean) => {
            try {
                const result: PageResponse<BannerListVO> = await getBanners(condition);
                if (isFirstLoad) {
                    setTotalCount(result.totalElements);
                } else {
                    //eslint-disable-next-line
                    result.content.map((data) =>{
                        //eslint-disable-next-line
                        data.websiteType = <WebsiteType>addHyphenAfterFirstLetter(data.websiteType);
                    })
                    setBannerList(result);
                }
            } catch (e) {
                alert(defaultErrorMessage);
            }
        },
        [defaultErrorMessage, getBanners]
    );

    function addHyphenAfterFirstLetter(word: string) {
        return word.charAt(0) + '-' + word.slice(1);
    }

    const getBannerListWithParam = useCallback(
        (isFirstLoad?: boolean) => {
            if (isFirstLoad) {
                getBannerList({}, true).then();
            }

            const bannerListQueryType = listQueryType as BannerListQueryType;
            getBannerList({
                pageCondition: {
                    page: bannerListQueryType.page ? Number(bannerListQueryType.page) : DEFAULT_PAGE,
                    size: bannerListQueryType.size ? Number(bannerListQueryType.size) : DEFAULT_SIZE,
                    sort: bannerListQueryType.sort ? toSortArray(bannerListQueryType.sort) : DEFAULT_SORT,
                },
                q: bannerListQueryType.searchWord?.trim(),
                availableStartAt: bannerListQueryType.availableStartAt
                    ? timeConverter.convertToFilterStartOfDay(bannerListQueryType.availableStartAt)
                    : undefined,
                availableEndAt: bannerListQueryType.availableEndAt
                    ? timeConverter.convertToFilterEndOfDay(bannerListQueryType.availableEndAt)
                    : undefined,
                type: bannerListQueryType.type ? (bannerListQueryType.type as BannerType) : undefined,
                websiteType: bannerListQueryType.websiteType
                    ? (bannerListQueryType.websiteType as WebsiteType)
                    : undefined,
                bannerLocation: bannerListQueryType.bannerLocation ?
                    (bannerListQueryType.bannerLocation as BannerLocation) : undefined,
                status: bannerListQueryType.status ? (bannerListQueryType.status as BannerStatus) : undefined,
            }).then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [listQueryType]
    );

    useEffect(() => {
        getBannerList({}, true).then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (listQueryType) {
            getBannerListWithParam();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getBannerListWithParam]);


    return {
        bannerList,
        pageCondition,
        totalCount,
        getBannerListWithParam,
        searchBannerList,
    };
}