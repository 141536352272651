import { MainApi } from "./MainApi";
import {
  CheckDuplicatedPhoneNumberRequest,
  FindIdRequest,
  FindPwdRequest,
  LoginInfo,
  ResendAuthConfirmMailRequest,
  ResendTemporaryPasswordRequest,
  SignUpRequest,
} from "../types/account";

export class AuthApi {
  static baseUrl = `${MainApi.urlPrefix}/auth`;

  static signUp = (signUpInfo: SignUpRequest) => () => MainApi.api.post(`${AuthApi.baseUrl}/signup`, signUpInfo);

  static signIn = (loginInfo: LoginInfo) => () => MainApi.api.post(`${AuthApi.baseUrl}/sign-in`, loginInfo);

  static findId = (request: FindIdRequest) => () => MainApi.api.post(`${AuthApi.baseUrl}/find/id`, request);

  static findPwd = (request: FindPwdRequest) => () => MainApi.api.post(`${AuthApi.baseUrl}/find/password`, request);

  static checkDuplicatedPhoneNumber = (request: CheckDuplicatedPhoneNumberRequest) => () =>
    MainApi.api.post(`${AuthApi.baseUrl}/dupl-check/phone`, request);

  static getAccessTokenFromRefreshToken = (refreshToken: string) => () => {
    const mainApi = MainApi.getInstance();
    mainApi.setToken(refreshToken);
    return mainApi.instance.post(`${AuthApi.baseUrl}/access-token`);
  };

  static resendTemporaryPassword = (request: ResendTemporaryPasswordRequest) => () =>
    MainApi.api.post(`${AuthApi.baseUrl}/incorrect/password/resend`, request);

  static resendAuthConfirmMail = (request: ResendAuthConfirmMailRequest) => () =>
    MainApi.api.post(`${AuthApi.baseUrl}/resend/signup/confirm/mail`, request);

  static getAccessTokenWithNationality = (nationality: string, refreshToken: string) => () => {
    const mainApi = MainApi.getInstance();
    mainApi.setToken(refreshToken);
    return mainApi.instance.post(`${AuthApi.baseUrl}/access-token/nationality?nationality=${nationality}`);
  };
}
