import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {useCallback, useEffect} from "react";
import {callAsync} from "../../../../../util/sliceUtil";
import {
  getUserOrganVoucherCurrentStatusAsync,
  organVoucherNewStatus,
  setOrganVoucherNewStatus,
  setOrganVoucherStatusInfo,
} from "../../../../../store/voucherSlice";
import {OrganVoucherNewStatus, OrganVoucherStatusInfo} from "../../../../../types/voucher";
import {useUtil} from "../../../../../util/hooks/useUtil";

interface Args {
  userIdx: number;
}

export function useUserOrganVoucherStatusGetter({ userIdx }: Args) {
  const dispatch = useAppDispatch();
  // const userOrganVoucherStatusInfo = useAppSelector(organVoucherStatusInfo);
  const organVoucherNewStatusInfo = useAppSelector(organVoucherNewStatus);
  const { defaultErrorMessage } = useUtil();

  const getOrganVoucherStatusInfo = useCallback(() => {
    callAsync(
      dispatch(getUserOrganVoucherCurrentStatusAsync(userIdx)).unwrap(),
      (result: OrganVoucherStatusInfo) => dispatch(setOrganVoucherStatusInfo(result)),
      () => alert(defaultErrorMessage)
    ).then();
  }, [dispatch, userIdx, defaultErrorMessage]);

  const getOrganVoucherNewStatusInfo = useCallback(() => {
    callAsync(
        dispatch(getUserOrganVoucherCurrentStatusAsync(userIdx)).unwrap(),
        (result: OrganVoucherNewStatus) => dispatch(setOrganVoucherNewStatus(result)),
        () => alert(defaultErrorMessage)
    ).then();
  }, [dispatch, userIdx, defaultErrorMessage]);

  useEffect(() => {
    // if (!userOrganVoucherStatusInfo) {
    //   getOrganVoucherStatusInfo();
    // }
    if (!organVoucherNewStatusInfo) {
      getOrganVoucherNewStatusInfo();
    }
    //eslint-disable-next-line
  }, [
      // userOrganVoucherStatusInfo,
    getOrganVoucherStatusInfo, getOrganVoucherNewStatusInfo]);

  return {
    getOrganVoucherStatusInfo,
    // userOrganVoucherStatusInfo,
    organVoucherNewStatusInfo,
  };
}
