import {RadioGroupField} from "../../../components/RadioGroupField";
import {WebsiteType} from "../../../types/common";
import {useBannerType} from "../../../types/hooks/useBannerType";

interface Props {
    websiteType: WebsiteType;
    setWebsiteType: (_: WebsiteType) => void;
}
export default function WebsiteTypeField({websiteType, setWebsiteType} : Props) {
    const {getWebsiteType} = useBannerType();

    return (
        <>
            <RadioGroupField enumValues={Object.values(WebsiteType)}
                             getLabel={getWebsiteType}
                             value={websiteType} setValue={setWebsiteType}
                             groupName={"websiteType"} isRequired={true}
            />
        </>
    );
};