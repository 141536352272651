import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { DescriptionList, DescriptionValue } from "../../../layout/content/detail/DescriptionList";
import { GridInputText } from "../../../components/Inputs";

export function ArticleDescriptionList({ id, title }: ArticleProps) {
  const listData: DescriptionValue[][] = [
    [
      {
        key: "이름",
        value: "홍길동",
      },
      {
        key: "키",
        value: "값",
      },
    ],
    [
      {
        key: "주소",
        value: "서울특별시 종로구 ...",
      },
    ],
  ];

  const editableListData: DescriptionValue[][] = [
    ...listData,
    [
      {
        key: "생년월일",
        value: "2001-01-01",
        editModeValue: <GridInputText placeholder={"2001-01-01 형태로 입력해주세요."} />,
      },
    ],
  ];

  const description = `
- DescriptionList의 Props 형태
{
  data: DescriptionValue[][];
  labelWidth: number;
  valueWidth?: number;
  valueClassName?: string;
  isEditMode?: boolean; -- 편집 모드 여부
}

- DescriptionValue 타입
{
  key: string | JSX.Element;
  value?: string | JSX.Element;
  editModeValue?: JSX.Element; -- 편집모드일 경우 나타날 element
  menuCode?: AdminMenuCode; -- 권한이 필요한 value의 경우 추가
  alternativeElement?: JSX.Element; -- 권한이 없는 경우 대체하여 보여줄 element
}

- DescriptionList의 isEditMode와 DescriptionValue의 editModeValue를 이용해서 편집 모드 화면을 만들 수 있음  
  `;

  const code = `
const listData: DescriptionValue[][] = ${JSON.stringify(listData, null, 2)};

<DescriptionList data={listData}
                 labelWidth={132}/>
  `;

  const editableElementCode = `
const editableListData: DescriptionValue[][] = [
  ...listData,
  [
    {
      key: "생년월일",
      value: "2001-01-01",
      editModeValue: "<GridInputText placeholder={'2001-01-01 형태로 입력해주세요.'}/>"
    }
  ]
];
  
<DescriptionList data={editableListData} 
                 isEditMode={true}
                 labelWidth={150}/>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code} description={description}>
          <DescriptionList data={listData} labelWidth={132} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={editableElementCode} description={description}>
          <DescriptionList data={editableListData} isEditMode={true} labelWidth={150} />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
