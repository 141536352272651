import { ListTable } from "../../layout/content/list/ListTable";
import { useTestColumns } from "./hooks/useTestColumns";
import { useTestFilters } from "./hooks/useTestFilters";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { deleteTestAsync, downloadExcelAsync, testListStatus } from "../../store/testSlice";
import React, { useCallback, useState } from "react";
import { useTestList } from "./hooks/useTestList";
import deleteIcon from "../../assets/images/button_icons/delete_icon.svg";
import excelIcon from "../../assets/images/button_icons/excel_icon.svg";
import { callAsync } from "../../util/sliceUtil";
import { useDownloadFile } from "../../hooks/common/useDownloadFile";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../util/hooks/useUtil";
import { useLoader } from "../../hooks/common/useLoader";
import { ListCount, WhichList } from "../../components/ListCount";
import {VoucherType} from "../../types/voucher";

interface Args{
  testType: VoucherType;
}

export function TestList({testType} : Args) {
  const { t } = useTranslation("test");
  const status = useAppSelector(testListStatus);
  const { defaultErrorMessage } = useUtil();
  const dispatch = useAppDispatch();
  useLoader({ status });

  const [selectedTestIdxList, setSelectedTestIdxList] = useState<number[]>([]);

  const columns = useTestColumns(testType);
  const { searchTestList, totalTestCnt, testList, pageCondition } = useTestList({
    onCompletedCallback: () => setSelectedTestIdxList([]), testType
  });
  const { filters } = useTestFilters({ search: searchTestList({}) });

  const { getDownloadExcelFile } = useDownloadFile();

  const onClickDownloadExcel = useCallback(() => {
    if (selectedTestIdxList.length === 0) {
      alert(t("noSelectedTest"));
    } else {
      callAsync<string>(
        dispatch(downloadExcelAsync(selectedTestIdxList)).unwrap(),
        (result) => getDownloadExcelFile(result, "test-result"),
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    }
  }, [t, selectedTestIdxList, defaultErrorMessage, getDownloadExcelFile, dispatch]);

  const onClickDeleteTests = useCallback(() => {
    if (selectedTestIdxList.length === 0) {
      alert(t("noSelectedTest"));
    } else {
      if (!window.confirm(t("delete.confirm"))) return;

      callAsync(
        dispatch(deleteTestAsync(selectedTestIdxList)).unwrap(),
        () => searchTestList(pageCondition)(),
        () => alert(defaultErrorMessage)
      ).then();
    }
  }, [dispatch, t, defaultErrorMessage, selectedTestIdxList, searchTestList, pageCondition]);

  return testList ? (
    <ListTable
      pageOnFilter={pageCondition}
      filters={filters}
      totalRowsCnt={{
        totalCount: <ListCount totalCount={totalTestCnt} whichList={WhichList.TEST_HISTORY} />,
        singleUnitKeyInCommonFile: "testHistoryUnit.singleComponent",
        multipleUnitKeyInCommonFile: "testHistoryUnit.multipleComponent",
      }}
      columns={columns}
      checkedRowIdxArr={selectedTestIdxList}
      setCheckedRowIdxArr={setSelectedTestIdxList}
      listData={testList}
      getList={({ page, sort, size }) => searchTestList({ page, sort, size })}
      buttonsNode={
        <>
          <div>
            <button onClick={() => onClickDeleteTests()}>
              <img src={deleteIcon} alt={t("delete.icon")} />
              {t("delete.button")}
            </button>
          </div>

          <div>
            <button onClick={() => onClickDownloadExcel()}>
              <img src={excelIcon} alt={t("download.icon")} />
              {t("download.button")}
            </button>
          </div>
        </>
      }
    />
  ) : (
    <></>
  );
}
