import {SearchCondition} from "./page";

export interface OrganManagerAuthorityDTO{
  menuCode: string[];
  voucherPurchaseRestrictions: string[];
  kpassResultForm: string[];
  dcasResultForm: string[];
  kpassAdditionalResult: string[];
  dcasAdditionalResult: string[];
}

export interface OrganManagerOrganDTO {
  idx: number;
  createAt: string;
  organName: string;
  organType: string;
  codePhone: string;
  phoneNumber: string;
  memberCount: number;
  testCompletionCount: number;
  codePiece: string;
}

export interface OrganManagerOrganSearchCondition extends SearchCondition {}

export interface OrganByProfileDTO {
  idx: number;
  organName: string;
}

export interface OrganEmailCheckDTO{
  email: string;
}

export interface OrganPhoneCheckDTO{
  phoneNumber: string;
  codePhone: string;
}

export namespace OrganByProfileDTO {
  export const toOrganByProfileVO = (dto: OrganByProfileDTO) => dto;
}

export interface OrganByProfileVO {
  idx: number;
  organName: string;
}

export interface OrganDTO {
  idx: number;
  organName: string;
  organType: string;
  codePhone: string;
  phoneNumber: string;
  isDeleted: boolean;
}

export enum OrganManagerIdValidStatus{
  /* 각각 사용가능, 중복, 미선택 */
  VALID = "VALID",
  DUPLICATED = "DUPLICATED",
  UNCHECKED = "UNCHECKED",
  WRONG_TYPE = "WRONG_TYPE",
  NONE = "NONE"
}

export enum OrganManagerCellphoneValidStatus{
  /* 각각 길이, 중복, 미선택 */
  VALID = "VALID",
  INNER_LENGTH = "INNER_LENGTH",
  OUTER_LENGTH = "OUTER_LENGTH",
  DUPLICATED = "DUPLICATED",
  UNCHECKED = "UNCHECKED",
  NONE = "NONE",
}