import { useAppSelector } from "../../hooks/hooks";
import { partnerCompanyStatusState } from "../../store/partnerCompanySlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useCallback, useMemo, useState } from "react";
import { usePartnerCompanyFilters } from "./hooks/partner/usePartnerCompanyFilters";
import { usePartnerCompanyList } from "./hooks/partner/usePartnerCompanyList";
import { usePartnerCompanyColumns } from "./hooks/partner/usePartnerCompanyColumns";
import { ListTable } from "../../layout/content/list/ListTable";
import { ListCount, WhichList } from "../../components/ListCount";
import { DetailActionButton } from "../../components/Buttons";
import deleteIcon from "../../assets/images/button_icons/delete_icon.svg";
import stopIcon from "../../assets/images/button_icons/stop_icon.svg";
import confirmIcon from "../../assets/images/button_icons/confirm_icon.svg";
import { usePartnerCompaniesDeleter } from "./hooks/partner/usePartnerCompaniesDeleter";
import { usePartnerCompaniesStatusUpdater } from "./hooks/partner/usePartnerCompaniesStatusUpdater";
import {
  PartnerCompanyListVO,
  PartnerCompanyOrderingNumberUpdateVO,
  PartnerCompanyStatus,
} from "../../types/partnerCompany";
import { usePartnerCompaniesOrderingNumberUpdater } from "./hooks/partner/usePartnerCompaniesOrderingNumberUpdater";

export function PartnerCompanyList() {
  const status = useAppSelector(partnerCompanyStatusState);
  useLoader({ status });
  const [selectedPartnerIdxes, setSelectedPartnerIdxes] = useState<number[]>([]);
  const [orderingNumberMap, setOrderingNumberMap] = useState<Map<number, number | null>>(new Map());

  const { searchPartnerList, partnerList, pageCondition, totalPartnerCount, getPartnerListWithParam } =
    usePartnerCompanyList({
      onCompletedCallback: () => setSelectedPartnerIdxes([]),
    });
  const { filters } = usePartnerCompanyFilters({ search: searchPartnerList({}) });
  const { deletePartnerCompanies } = usePartnerCompaniesDeleter({ onCompletedCallback: searchPartnerList({}) });
  const { updatePartnerCompaniesStatus } = usePartnerCompaniesStatusUpdater({
    onCompletedCallback: () => {
      getPartnerListWithParam();
    },
  });
  const { updatePartnerCompaniesOrderingNumber } = usePartnerCompaniesOrderingNumberUpdater();

  const selectedPartners = useMemo(
    () => partnerList?.content.filter((vo) => selectedPartnerIdxes.includes(vo.idx)) ?? [],
    [selectedPartnerIdxes, partnerList]
  );

  const onClickHidePartnerButton = useCallback(
    (partners: PartnerCompanyListVO[], isButtonOnList: boolean) => {
      updatePartnerCompaniesStatus(partners, PartnerCompanyStatus.UNEXPOSED, isButtonOnList).then();
    },
    [updatePartnerCompaniesStatus]
  );
  const onClickShowPartnerButton = useCallback(
    (partners: PartnerCompanyListVO[], isButtonOnList: boolean) => {
      updatePartnerCompaniesStatus(partners, PartnerCompanyStatus.EXPOSED, isButtonOnList).then();
    },
    [updatePartnerCompaniesStatus]
  );
  const onClickSaveOrderingNumber = useCallback(() => {
    updatePartnerCompaniesOrderingNumber(orderingNumberMap).then(() => getPartnerListWithParam());
  }, [updatePartnerCompaniesOrderingNumber, orderingNumberMap, getPartnerListWithParam]);
  const { columns } = usePartnerCompanyColumns({
    showPartner: (partner: PartnerCompanyListVO) => onClickShowPartnerButton([partner], true),
    hidePartner: (partner: PartnerCompanyListVO) => onClickHidePartnerButton([partner], true),
    setOrderingNumberMap: (vo: PartnerCompanyOrderingNumberUpdateVO) => {
      orderingNumberMap.set(vo.idx, vo.orderingNumber ? vo.orderingNumber : null);
      setOrderingNumberMap(orderingNumberMap);
    },
    orderingNumberMap,
    getPartnerCompanyList: getPartnerListWithParam,
  });

  return (
    <>
      {partnerList ? (
        <ListTable
          filters={filters}
          buttonsNode={
            <>
              <div>
                <DetailActionButton onClick={() => deletePartnerCompanies(selectedPartnerIdxes)}>
                  <img src={deleteIcon} alt={"삭제 아이콘"} />
                  협력사삭제
                </DetailActionButton>
                <DetailActionButton onClick={onClickSaveOrderingNumber}>순번저장</DetailActionButton>
              </div>
              <div>
                <DetailActionButton onClick={() => onClickHidePartnerButton(selectedPartners, false)}>
                  <img src={stopIcon} alt={"노출중지 아이콘"} />
                  노출중지
                </DetailActionButton>
                <DetailActionButton onClick={() => onClickShowPartnerButton(selectedPartners, false)}>
                  <img src={confirmIcon} alt={"노출재개 아이콘"} />
                  노출재개
                </DetailActionButton>
              </div>
            </>
          }
          listData={partnerList}
          getList={(pageCondition) => searchPartnerList(pageCondition)}
          columns={columns}
          checkedRowIdxArr={selectedPartnerIdxes}
          setCheckedRowIdxArr={setSelectedPartnerIdxes}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalPartnerCount ?? 0} whichList={WhichList.PARTNER} />,
            singleUnitKeyInCommonFile: "partnerUnit.singleComponent",
            multipleUnitKeyInCommonFile: "partnerUnit.multipleComponent",
          }}
          pageOnFilter={pageCondition}
        />
      ) : null}
    </>
  );
}
