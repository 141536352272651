import { Anchor } from "../../../components/Anchor";
import React, { useEffect, useRef, useState } from "react";
import { useTestersOrganCountGetter } from "./hooks/useTestersOrganCountGetter";
import { MiniModal } from "../../../layout/modal/MiniModal";
import styled from "styled-components";
import { useAppSelector } from "../../../hooks/hooks";
import { organsByUserProfileStatus } from "../../../store/userProfileSlice";
import { MINI_MODAL_LINK } from "../../../util/etcUtil";
import { useTranslation } from "react-i18next";

interface Props {
  organCount: number;
  profileIdx: number;
}

const OrganListStyle = styled.ul`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export function TestersOrganCount({ organCount, profileIdx }: Props) {
  const { t } = useTranslation("tester");
  const statusValue = useAppSelector(organsByUserProfileStatus);
  const onClickOrganCount = (e: React.MouseEvent) => {
    e.preventDefault();
    // e.stopPropagation();
    setShowModal(true);
  };
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const [showModal, setShowModal] = useState(false);
  const { getOrganCountByProfileIdx, organByProfileVOs } = useTestersOrganCountGetter();

  useEffect(() => {
    if (showModal) {
      getOrganCountByProfileIdx(profileIdx).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <>
      {organCount === null ? (
        `-`
      ) : (
        <Anchor onClick={onClickOrganCount} style={{ position: "relative" }} ref={linkRef} data-name={MINI_MODAL_LINK}>
          {organCount}
          <MiniModal
            show={showModal}
            closeModal={() => setShowModal(false)}
            title={t("organ.label")}
            modalWidth={"200px"}
            modalLeftPosition={"true"}
            statusValues={[statusValue]}
            linkElement={linkRef.current ?? undefined}
          >
            <OrganListStyle>
              {organByProfileVOs?.map((organ) => (
                <li key={`organ-${profileIdx}-${organ.idx}`}>{organ.organName}</li>
              ))}
            </OrganListStyle>
          </MiniModal>
        </Anchor>
      )}
    </>
  );
}
