import style from "./memo.module.scss";
import { Pagination } from "../../../layout/content/list/Pagination";
import { useCallback, useEffect, useMemo, useState } from "react";
import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { PageResponse } from "../../../types/page";
import { Panel } from "../../../layout/content/detail/Panel";
import { Memo, MemoTargetType } from "../../../types/memo";
import { getMemoListAsync, memoStateStatus } from "../../../store/memoSlice";
import { AMemo } from "./AMemo";
import { MemoWritingPanel } from "./MemoWritingPanel";
import { useLoader } from "../../../hooks/common/useLoader";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
  targetIdx: number;
  targetType: MemoTargetType;
  isWritable?: boolean;
}

export interface MemoTitle {
  memoListTitle: string;
  memoWriteTitle: string;
}

export function MemoComponent({ id, targetIdx, targetType, isWritable = true }: Props) {
  const { t } = useTranslation("memo");
  const dispatch = useAppDispatch();
  const memoStatus = useAppSelector(memoStateStatus);
  useLoader({ status: memoStatus });

  const getMemoListTile = useCallback(
    (memoTargetType: MemoTargetType): string => {
      const memo = {
        [MemoTargetType.USER.toString()]: t("memoComponent.targetType.member"),
        [MemoTargetType.ORDER.toString()]: t("memoComponent.targetType.order"),
        [MemoTargetType.ORGAN_MANAGER.toString()]: t("memoComponent.targetType.member"),
      };

      return memo[memoTargetType.toString()];
    },
    [t]
  );

  const getMemoWriteTitle = useCallback(
    (memoTargetType: MemoTargetType): string => {
      const memo = {
        [MemoTargetType.USER.toString()]: t("memoComponent.title.member"),
        [MemoTargetType.ORDER.toString()]: t("memoComponent.title.order"),
        [MemoTargetType.ORGAN_MANAGER.toString()]: t("memoComponent.title.member"),
      };

      return memo[memoTargetType.toString()];
    },
    [t]
  );

  const [memoList, setMemoList] = useState<PageResponse<Memo>>();
  const pageSize = 3;
  const memoTitle: MemoTitle = useMemo(() => {
    return {
      memoListTitle: getMemoListTile(targetType),
      memoWriteTitle: getMemoWriteTitle(targetType),
    };
  }, [targetType, getMemoListTile, getMemoWriteTitle]);

  const getMemoList = useCallback(
    (page: number) => {
      callAsync(
        dispatch(
          getMemoListAsync({
            targetIdx: targetIdx,
            targetType: targetType,
            pageCondition: {
              page: page,
              size: pageSize,
            },
          })
        ).unwrap(),
        (result) => {
          setMemoList(result);
        },
        () => {}
      ).then();
    },
    [targetIdx, dispatch, targetType]
  );

  useEffect(() => {
    getMemoList(0);
  }, [getMemoList]);

  return (
    <div id={id ?? ""} className={style.memoArea}>
      <div className={style.panelWrap}>
        <Panel title={memoTitle.memoListTitle} panelClassName={style.memoList}>
          <>
            <ul className={style.list}>
              {memoList?.content?.length === 0 ? (
                <li className={style.noDataItem}>{t("memoComponent.noMemo")}</li>
              ) : (
                memoList?.content?.map((memo) => (
                  <AMemo
                    key={`memoIdx-${memo.idx}`}
                    memo={memo}
                    onRefresh={() => getMemoList(0)}
                    memoListTitle={memoTitle.memoListTitle}
                  />
                ))
              )}
            </ul>
            {(memoList?.content?.length ?? 0) > 0 ? (
              <div className={style.pagingWrapper}>
                <Pagination page={memoList!} goSpecificPage={(num: number) => getMemoList(num)} />
              </div>
            ) : (
              <></>
            )}
          </>
        </Panel>
      </div>
      <MemoWritingPanel
        targetIdx={targetIdx}
        memoTitle={memoTitle}
        targetType={targetType}
        onRefresh={() => getMemoList(0)}
        isWritable={isWritable}
      />
    </div>
  );
}
