import React, { useCallback } from "react";
import redVoucherIcon from "../../../assets/images/button_icons/red_voucher_icon.svg";
import { OrderStatus, OrdersVO } from "../../../types/orders";
import { useNewWindow } from "../../../hooks/hooks";
import { ActionButton, ActiveActionButton, DetailActionButton } from "../../../components/Buttons";
import { useOrderDeleter } from "./useOrderDeleter";
import { callAsync } from "../../../util/sliceUtil";
import { useOrderStatus } from "./useOrderStatus";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface MemoButtonProps {
  orderIdx: number;
  hasMemo: boolean;
}

interface DeleteButtonProps {
  orderIdx: number;
  orderStatus: OrderStatus;
}

interface DetailButtonProps {
  order: OrdersVO;
}

interface Args {
  onRefresh: () => void;
}
export function useOrderButtons({ onRefresh }: Args) {
  const { t } = useTranslation("order");
  const { openOrderDetailPopup } = useNewWindow();
  const { deleteOrder } = useOrderDeleter();
  const { defaultErrorMessage } = useUtil();
  const { isBeforePaid } = useOrderStatus();

  const MemoButton = useCallback(
    ({ orderIdx, hasMemo }: MemoButtonProps): JSX.Element =>
      hasMemo ? (
        <ActiveActionButton onClick={() => openOrderDetailPopup(orderIdx)}>{t("memo")}</ActiveActionButton>
      ) : (
        <ActionButton onClick={() => openOrderDetailPopup(orderIdx)}>{t("memo")}</ActionButton>
      ),
    [openOrderDetailPopup, t]
  );

  const onClickDeleteButton = useCallback(
    (orderIdx: number) => {
      if (window.confirm(t("delete.confirm"))) {
        callAsync(
          deleteOrder(orderIdx),
          () => {
            alert(t("delete.result"));
            onRefresh();
          },
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [deleteOrder, onRefresh, t, defaultErrorMessage]
  );

  const DeleteButton = useCallback(
    ({ orderIdx, orderStatus }: DeleteButtonProps): JSX.Element =>
      orderStatus === OrderStatus.AWAITING_PAYMENT ? (
        <ActionButton onClick={() => onClickDeleteButton(orderIdx)}>{t("delete.button")}</ActionButton>
      ) : (
        <></>
      ),
    [onClickDeleteButton, t]
  );

  const CancelButton = useCallback(
    ({ order }: DetailButtonProps): JSX.Element =>
      !isBeforePaid(order.status) && order.status !== OrderStatus.CANCELLED ? (
        <DetailActionButton onClick={() => alert(t("readyForPaymentCancellation"))}>
          <img src={redVoucherIcon} alt={t("paymentCancel.icon")} />
          {t("paymentCancel.button")}
        </DetailActionButton>
      ) : (
        <></>
      ),
    [isBeforePaid, t]
  );

  return {
    MemoButton,
    DeleteButton,
    CancelButton,
  };
}
