import { useCallback, useMemo, useState } from "react";
import { CouponStatus, CouponType } from "../../../types/coupon";
import { PageSearchCondition } from "../../../types/page";
import { ListQueryType } from "../../../types/list";
import { FilterType, FilterValue } from "../../../types/filter";
import { useFilterValues } from "../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../hooks/list/useListSearchParams";

export interface CouponListQueryType extends ListQueryType {
  searchWord?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  couponType?: string;
  couponStatus?: string;
}
export interface CouponListFilterValues {
  searchWordFilterValue: FilterValue<string>;
  createdAtFromFilterValue: FilterValue<Date | undefined>;
  createdAtToFilterValue: FilterValue<Date | undefined>;
  couponTypeFilterValue: FilterValue<CouponType | "">;
  couponStatusFilterValue: FilterValue<CouponStatus | "">;
}

export function useCouponFilterValues() {
  const [searchWord, setSearchWord] = useState("");
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();
  const [couponType, setCouponType] = useState<CouponType | "">("");
  const [couponStatus, setCouponStatus] = useState<CouponStatus | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<CouponListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      { key: "couponType", value: couponType, setter: setCouponType, defaultValue: "", type: FilterType.OTHER },
      { key: "couponStatus", value: couponStatus, setter: setCouponStatus, defaultValue: "", type: FilterType.OTHER },
    ],
    [searchWord, createdAtFrom, createdAtTo, couponType, couponStatus]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
        createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
        couponTypeFilterValue: getFilterValueByKey("couponType") as FilterValue<CouponType | "">,
        couponStatusFilterValue: getFilterValueByKey("couponStatus") as FilterValue<CouponStatus | "">,
      } as CouponListFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: CouponListQueryType) => {
      const param: CouponListQueryType = {
        searchWord: listQueryTypeParam?.searchWord,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
        couponType: listQueryTypeParam?.couponType,
        couponStatus: listQueryTypeParam?.couponStatus,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
