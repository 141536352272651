import { SliceState, Status } from "../types/common";
import { Country } from "../types/country";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";
import { addCases, executePromise } from "../util/sliceUtil";
import { CountryApi } from "../api/CountryApi";

export interface CountryState extends SliceState {
  countries?: Country[];
}

const initialState: CountryState = {
  status: Status.IDLE,
};

export const getCountriesAsync = createAsyncThunk("country/getCountries", () =>
  executePromise(CountryApi.getCountries())
);

export const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getCountriesAsync);
  },
});

export default countrySlice.reducer;

export const { setCountries } = countrySlice.actions;

export const countryState = (state: RootState) => state.country.status;
export const countriesState = (state: RootState) => state.country.countries;
