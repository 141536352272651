import style from "../../../layout/content/post/creator.module.scss";
interface Props {
  imageLink: string;
  setImageLink: (_: string) => void;
}
export function PopupImageLinkField({ imageLink, setImageLink }: Props) {
  return (
    <input
      className={style.inputField}
      type={"url"}
      value={imageLink}
      required={false}
      maxLength={255}
      onChange={(e) => setImageLink(e.target.value)}
      placeholder={"이미지 링크(url) 입력"}
    />
  );
}
