import { useAppDispatch, useTimeConverter } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useCallback } from "react";
import { PopupListVO, PopupStatus } from "../../../../types/popup";
import { updatePopupStatusAsync } from "../../../../store/popupSlice";

interface Args {
  onCompletedCallback: () => void;
}

export function usePopupStatusUpdater({ onCompletedCallback }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { timeConverter } = useTimeConverter();

  const updatePopupStatus = useCallback(
    async (popups: PopupListVO[], toStatus: PopupStatus, isButtonOnList: boolean) => {
      if (popups.length === 0) {
        alert("팝업을 선택해주세요.");
        return;
      }

      if (
        toStatus === PopupStatus.EXPOSED &&
        popups.some((popup) => popup.availableEndAt !== null && timeConverter.isBeforeFromNow(popup.availableEndAt))
      ) {
        alert("팝업의 유효기간이 끝났습니다.\n유효기간을 수정 후 사용해 주세요");
        return;
      }

      if (
        !window.confirm(
          `${isButtonOnList ? "" : "선택한 "}팝업을 ${
            toStatus === PopupStatus.EXPOSED ? "노출재개" : "노출중지"
          } 하시겠습니까?`
        )
      )
        return;

      try {
        await dispatch(
          updatePopupStatusAsync({
            idxes: popups.map((p) => p.idx),
            status: toStatus,
          })
        ).unwrap();
        alert(
          `${isButtonOnList ? "" : "선택한 "}팝업이 홈페이지에 ${
            toStatus === PopupStatus.EXPOSED ? "노출" : "미노출"
          } 되었습니다.`
        );
        onCompletedCallback();
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("팝업 상태 업데이트 에러");
      }
    },
    [timeConverter, defaultErrorMessage, dispatch, onCompletedCallback]
  );

  return {
    updatePopupStatus,
  };
}
