import style from "../productEditForm.module.scss";
import React from "react";
import { Textarea } from "../../../../components/Textarea";
import { Label104x46 } from "../../../../components/Label";
import { useTranslation } from "react-i18next";

interface Props {
  description: string[];
  setDescription: (_: string[]) => void;
}

export function ProductEditDescription({ description, setDescription }: Props) {
  const { t } = useTranslation("product");
  return (
    <div className={style.field}>
      <Label104x46 text={t("edit.description.label")} className={style.label} />
      <Textarea
        style={{
          width: "100%",
          height: "150px",
        }}
        required={true}
        value={description.join("\n")}
        onChange={(e) => setDescription(e.target.value.split("\n"))}
        onBlur={() => setDescription(description.filter((line) => line !== ""))}
        placeholder={t("edit.description.placeholder")}
      />
    </div>
  );
}
