import { useCallback } from "react";

export enum CookieKeys {
  ACCESS_TOKEN = "adminAccessToken",
  REFRESH_TOKEN = "adminRefreshToken",
  LOGIN_EMAIL = "adminLoginEmail",
  LANGUAGE = "lang",
  TIMEZONE = "timezone",
  NATIONALITY = "nationality",
}

export function useCookie() {
  const getValueFromCookie = useCallback((cookieKey: CookieKeys): string | undefined => {
    const cookies = document.cookie.split(";");

    return cookies
      .find((c) => c.trim().startsWith(`${cookieKey}=`))
      ?.split("=")[1]
      .trim();
  }, []);

  const setCookie = useCallback((key: CookieKeys, value: string, expires?: string) => {
    document.cookie = `${key.toString()}=${value};path=/;${expires ? `expires=${expires};` : ""}`;
  }, []);

  return {
    getValueFromCookie,
    setCookie,
  };
}
