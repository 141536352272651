import { useCallback, useMemo, useState } from "react";
import { FindAccountErrorCode } from "../../../types/account";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

export function useFindAccountHandleError() {
  const { t } = useTranslation(["account", "common"]);
  const { defaultErrorMessage } = useUtil();
  const [errorMessage, setErrorMessage] = useState("");

  const errorRouter = useMemo(
    () => ({
      [FindAccountErrorCode.CODE_404.toString()]: () => setErrorMessage(t("findAccount.notSignedUpMember")),
      [FindAccountErrorCode.CODE_477.toString()]: () => setErrorMessage(t("signUp.error.leavedAccount")),
      [FindAccountErrorCode.CODE_493.toString()]: () => setErrorMessage(t("findAccount.findPWResult.wrongPhoneNumber")),
    }),
    [t]
  );

  const handleError = useCallback(
    (httpCode: number) => {
      const errorFunc = errorRouter[`CODE_${httpCode}`];
      if (errorFunc) {
        errorFunc();
      } else {
        alert(defaultErrorMessage);
      }
    },
    [errorRouter, defaultErrorMessage]
  );

  return {
    handleError,
    errorMessage,
  };
}
