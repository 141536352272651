import {ListObject, WebsiteType} from "./common";
import {SearchCondition} from "./page";
import {AddedFile, FileType} from "./file";

export enum BannerLocation{
    MAIN = "MAIN",
}

export enum LinkOption {
    NEW_TAB = "NEW_TAB",
    CURRENT_TAB = "CURRENT_TAB",
}
export enum BannerStatus {
    UNEXPOSED = "UNEXPOSED",
    EXPOSED = "EXPOSED",
}
export enum BannerType{
    KOREAN = "KOREAN",
    ENGLISH = "ENGLISH",
    RUSSIA = "RUSSIAN",
    TAIWAN = "TAIWAN",
}


export interface BannerListVO extends ListObject {
    name: string;
    createdAt: string;
    type: BannerType;
    bannerLocation: BannerLocation;
    linkOption: LinkOption;
    availableStartAt: string;
    availableEndAt: string;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerPostVO {
    name: string;
    type: BannerType;
    bannerLocation: BannerLocation;
    image: FileType;
    // imageLink: string;
    description1: string;
    description2: string;
    buttonDescription: string;
    buttonLink: string;
    linkOption: LinkOption;
    availableStartAt: string;
    availableEndAt?: string;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerDetailVO {
    idx: number;
    name: string;
    createdAt: string;
    type: BannerType;
    bannerLocation: BannerLocation;
    image: AddedFile;
    imageLink: string;
    description1: string;
    description2: string;
    buttonDescription: string;
    buttonLink: string;
    linkOption: LinkOption;
    availableStartAt: string;
    availableEndAt?: string;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerSearchCondition extends SearchCondition {
    q?: string;
    availableStartAt?: Date;
    availableEndAt?: Date;
    type?: BannerType;
    bannerLocation?: BannerLocation;
    status?: BannerStatus;
    websiteType?: WebsiteType;
}

export interface BannerDto extends ListObject {
    name: string;
    createdAt: string;
    type: BannerType;
    bannerLocation: BannerLocation;
    linkOption: LinkOption;
    availableStartAt: string;
    availableEndAt?: string;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerPostParam {
    name: string;
    type: BannerType;
    location: BannerLocation;
    image: AddedFile;
    description1: string;
    description2: string;
    buttonDescription: string;
    buttonLink: string;
    linkOption: LinkOption;
    availableStartAt: string;
    availableEndAt?: string;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerPutVo {
    name: string;
    availableStartAt: string;
    availableEndAt?: string;
    type: BannerType;
    image: AddedFile;
    addedImage?: FileType;
    description1: string;
    description2: string;
    bannerLocation: BannerLocation;
    buttonDescription: string;
    buttonLink: string;
    linkOption: LinkOption;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerPutDTO {
    name: string;
    image: AddedFile;
    availableStartAt: string;
    availableEndAt?: string;
    type: BannerType;
    addedImage?: FileType;
    description1: string;
    description2: string;
    location: BannerLocation;
    buttonDescription: string;
    buttonLink: string;
    linkOption: LinkOption;
    status: BannerStatus;
    websiteType: WebsiteType;
}

export interface BannerStatusUpdateParam {
    idxes: number[];
    status: BannerStatus;
}
