import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PanelWrapper} from "../../../layout/content/detail/PanelWrapper";
import {Panel} from "../../../layout/content/detail/Panel";
import formStyle from "../../product/edit/productEditForm.module.scss";
import style from "../../../layout/content/post/creator.module.scss";
import {Label104x46} from "../../../components/Label";
import {PopupNameField} from "./PopupNameField";
import {useAppDispatch, useAppSelector, useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {isPopupDataSavedState, popupStatusState, setIsPopupDataSaved} from "../../../store/popupSlice";
import {LinkOption, PopupDetailVO, PopupStatus, PopupType} from "../../../types/popup";
import {AddedFile, FileType} from "../../../types/file";
import {usePopupDetailGetter} from "./hooks/usePopupDetailGetter";
import {useParams} from "react-router-dom";
import {PopupDurationField} from "./PopupDurationField";
import {PopupTypeField} from "./PopupTypeField";
import {PopupImageField} from "./PopupImageField";
import {PopupImageLinkField} from "./PopupImageLinkField";
import {PopupLinkOptionField} from "./PopupLinkOptionField";
import {PopupStatusField} from "./PopupStatusField";
import {ButtonWrapperStyle, PrimaryButton} from "../../../components/Buttons";
import {usePopupValidator} from "./hooks/usePopupValidator";
import {usePopupUpdater} from "./hooks/usePopupUpdater";
import {WindowMessage} from "../../../types/newWindow";
import {useNavigationBlocker} from "../../../hooks/navigationblocker/useNavigationBlocker";
import {useLoader} from "../../../hooks/common/useLoader";
import WebsiteTypeField from "./WebsiteTypeField";
import {Languages, WebsiteType} from "../../../types/common";
import {PopupLanguageField} from "./PopupLanguageField";

export function PopupDetail() {
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const isPopupDataSaved = useAppSelector(isPopupDataSavedState);
  const popupStatus = useAppSelector(popupStatusState);
  useLoader({ status: popupStatus });

  const { id } = useParams<"id">();
  const popupIdx = useMemo(() => Number(id), [id]);

  const [hasChangedValue, setHasChangedValue] = useState(false);

  const [name, setName] = useState("");
  const [availableStartAt, setAvailableStartAt] = useState("");
  const [availableEndAt, setAvailableEndAt] = useState("");
  const [isAvailableAlways, setIsAvailableAlways] = useState(false);
  const [popupType, setPopupType] = useState(PopupType.IMAGE);
  const [websiteType, setWebsiteType] = useState(WebsiteType.KPASS);
  const [popupLanguage, setPopupLanguage] = useState(Languages.KO);
  const [popupImage, setPopupImage] = useState<AddedFile | undefined>();
  const [addedPopupImage, setAddedPopupImage] = useState<FileType | undefined>();
  const [imageLink, setImageLink] = useState("");
  const [linkOption, setLinkOption] = useState<LinkOption>(LinkOption.NEW_TAB);
  const [status, setStatus] = useState<PopupStatus>(PopupStatus.UNEXPOSED);

  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      dispatch(setIsPopupDataSaved(false));
      setHasChangedValue(true);
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { timeConverter } = useTimeConverter();
  const { getPopupDetail } = usePopupDetailGetter({ popupIdx });
  useEffect(() => {
    getPopupDetail().then((vo: PopupDetailVO) => {
      setName(vo.name);
      setStatus(vo.status);
      setPopupType(vo.type);
      setPopupImage(vo.image);
      setImageLink(vo.imageLink);
      setLinkOption(vo.linkOption);
      setStatus(vo.status);
      setIsAvailableAlways(vo.availableEndAt === null);
      setAvailableStartAt(timeConverter.convertToLocalDate(vo.availableStartAt));
      setWebsiteType(vo.websiteType);
      setPopupLanguage(vo.language);
      if (vo.availableEndAt) {
        setAvailableEndAt(timeConverter.convertToLocalDate(vo.availableEndAt));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { validate } = usePopupValidator({
    name,
    image: popupImage,
    addedImage: addedPopupImage,
    availableEndAt,
    isAvailableAlways,
    confirmMessage: "팝업 정보를 수정 하시겠습니까?",
  });
  const { updatePopup } = usePopupUpdater();
  const onClickModifyButton = useCallback(() => {
    if (!formRef.current?.reportValidity()) return;
    if (!validate()) return;

    updatePopup(popupIdx, {
      name,
      availableStartAt,
      availableEndAt,
      type: popupType,
      image: popupImage!,
      addedImage: addedPopupImage,
      imageLink: imageLink,
      linkOption: linkOption,
      status,
      websiteType,
      popupLanguage,
    }).then(() => {
      closeWindowAndNotifyToParentWindow(WindowMessage.POPUP);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    validate,
    name,
    imageLink,
    popupImage,
    popupType,
    addedPopupImage,
    availableEndAt,
    availableStartAt,
    closeWindowAndNotifyToParentWindow,
    linkOption,
    popupIdx,
    status,
    updatePopup,
    popupLanguage,
  ]);

  useNavigationBlocker({
    hasChangedValue,
    isDataSaved: isPopupDataSaved,
    confirmMessage: "사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다.",
  });

  return (
    <PanelWrapper>
      <Panel title={"팝업 정보"}>
        <form className={formStyle.form} ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <div className={style.fieldLine}>
            <Label104x46 text={"팝업명"} isRequired={true} />
            <PopupNameField name={name} setName={(v) => changeValue(v, setName)} />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"홈페이지"} isRequired={true}/>
            <WebsiteTypeField websiteType={websiteType}
                              setWebsiteType={(v) => {
                                changeValue(v, setWebsiteType);
                                setPopupLanguage(Languages.KO)
                              }}/>
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"유효기간"} isRequired={true} />
            <PopupDurationField
              startAt={availableStartAt}
              setStartAt={(v) => changeValue(v, setAvailableStartAt)}
              endAt={availableEndAt}
              setEndAt={(v) => changeValue(v, setAvailableEndAt)}
              isAvailableAlways={isAvailableAlways}
              setIsAvailableAlways={(v) => changeValue(v, setIsAvailableAlways)}
            />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"팝업 언어"} isRequired={true}/>
            <PopupLanguageField websiteType={websiteType} popupLanguage={popupLanguage}
                                setPopupLanguage={(v) => {changeValue(v, setPopupLanguage);}}/>
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"팝업 유형"} isRequired={true} />
            <PopupTypeField popupType={popupType} setPopupType={(v) => changeValue(v, setPopupType)} />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"팝업 이미지"} isRequired={true} className={"alignSelfStart"} />
            <PopupImageField
              popupImage={popupImage}
              setPopupImage={(v) => changeValue(v, setPopupImage)}
              addedPopupImage={addedPopupImage}
              setAddedPopupImage={(v) => changeValue(v, setAddedPopupImage)}
            />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"이미지 링크"} isRequired={false} />
            <PopupImageLinkField imageLink={imageLink} setImageLink={(v) => changeValue(v, setImageLink)} />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"링크 옵션"} isRequired={true} />
            <PopupLinkOptionField linkOption={linkOption} setLinkOption={(v) => changeValue(v, setLinkOption)} />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"상태"} isRequired={true} />
            <PopupStatusField popupStatus={status} setPopupStatus={(v) => changeValue(v, setStatus)} />
          </div>
          <ButtonWrapperStyle className={"marginTB20"}>
            <PrimaryButton className={"width300 fontSize20"} onClick={onClickModifyButton}>
              팝업 수정
            </PrimaryButton>
          </ButtonWrapperStyle>
        </form>
      </Panel>
    </PanelWrapper>
  );
}
