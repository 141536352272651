import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../layout/content/detail/Panel";
import style from "./unitItemCreator.module.scss";
import modalStyle from "../../style/grantModal.module.scss";
import { SearchField } from "../../../layout/content/detail/SearchField";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FilterNumber } from "../../../layout/content/list/filter/FilterNumber";
import { FilterContentType } from "../../../types/tableFilter";
import { UnitItemType, ValidDurationFrom } from "../../../types/unitItem";
import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../../hooks/hooks";
import { checkWhetherNameIsUniqueAsync, createUnitItemAsync, unitItemStatus } from "../../../store/unitItemSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { WindowMessage } from "../../../types/newWindow";
import { Label104x46 } from "../../../components/Label";
import { RadioGroup } from "../../../components/RadioGroup";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { useUnitItemType } from "../../../types/hooks/useUnitItemType";

export function UnitItemCreator() {
  const { t } = useTranslation(["unitItem", "common"]);
  const dispatch = useAppDispatch();
  const status = useAppSelector(unitItemStatus);
  useLoader({ status });
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();
  const { toStringFromValidDurationFrom } = useUnitItemType();

  const formRef = useRef<HTMLFormElement>(null);

  const [searchWord, setSearchWord] = useState("");
  const [validDateNum, setValidDateNum] = useState("");
  const [unitItemType, setUnitItemType] = useState<UnitItemType>(UnitItemType.KPASS_VOUCHER);
  const { defaultErrorMessage } = useUtil();
  const [isDuplicatedName, setIsDuplicatedName] = useState<boolean | null>(null);

  const validDurationFrom = useMemo(() => {
    switch (unitItemType) {
      case UnitItemType.KPASS_VOUCHER:
        return ValidDurationFrom.ORDERED_DATE;
      case UnitItemType.DCAS_VOUCHER:
        return ValidDurationFrom.ORDERED_DATE;
      case UnitItemType.CONSULTING_VOUCHER:
        return ValidDurationFrom.TEST_COMPLETED_DATE;
      default:
        return ValidDurationFrom.ORDERED_DATE;
    }
  }, [unitItemType]);

  const isVoucher = (type: UnitItemType) => type === UnitItemType.KPASS_VOUCHER || type === UnitItemType.DCAS_VOUCHER;

  // const isConsultingVoucher = (type: UnitItemType) => type === UnitItemType.CONSULTING_VOUCHER;

  const checkWhetherDuplicatedNameIs = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      if (!searchWord) {
        alert(t("creator.validations.noName"));
        return;
      }

      callAsync(
        dispatch(checkWhetherNameIsUniqueAsync(searchWord.trim())).unwrap(),
        () => setIsDuplicatedName(false),
        () => setIsDuplicatedName(true)
      ).then();
    },
    [dispatch, t, searchWord]
  );

  const onClickCreateBtn = () => {
    if (formRef.current?.reportValidity()) {
      if (isDuplicatedName === null) {
        alert(t("creator.validations.duplicatedNameCheckNeeded"));
        return;
      }

      if (unitItemType === UnitItemType.DEFAULT) {
        alert(t("creator.validations.noType"));
        return;
      }

      if (isDuplicatedName === null || isDuplicatedName) {
        return;
      }

      const isConfirmed = window.confirm(t("creator.confirm"));
      if (isConfirmed) {
        callAsync(
          dispatch(
            createUnitItemAsync({
              name: searchWord,
              type: unitItemType,
              validDurationFrom,
              validDuration: Number(validDateNum),
            })
          ).unwrap(),
          () => {
            setIsDuplicatedName(null);
            closeWindowAndNotifyToParentWindow(WindowMessage.CREATE_UNIT_ITEM);
          },
          () => alert(defaultErrorMessage)
        ).then();
        return;
      }
    }
  };

  return (
    <PanelWrapper>
      <Panel title={t("creator.title")} panelClassName={modalStyle.panel}>
        <>
          <form className={style.form} ref={formRef}>
            <div className={`${style.fieldLine} ${style.flexStart}`}>
              <Label104x46 text={t("creator.name.label")} />
              <div className={style.flexColumn}>
                <SearchField
                  value={searchWord}
                  onKeyUpFunc={() => {}}
                  onChangeFunc={(e) => {
                    setSearchWord((e.target as HTMLInputElement).value);
                    setIsDuplicatedName(null);
                  }}
                  onClickSearchButtonFunc={checkWhetherDuplicatedNameIs}
                  buttonText={t("creator.name.buttonText")}
                  placeholder={t("creator.name.placeholder")}
                  isRequired={true}
                  className={style.nameField}
                  minLength={2}
                  maxLength={255}
                />
                {isDuplicatedName === false ? (
                  <span className={"blue"}>{t("creator.name.checkResults.available")}</span>
                ) : isDuplicatedName === true ? (
                  <span className={"red"}>
                    {t("creator.name.checkResults.duplicated1")}
                    <br />
                    {t("creator.name.checkResults.duplicated2")}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className={style.fieldLine}>
              <Label104x46 text={t("creator.type.label")} />
              <RadioGroup
                groupName={"unitItemType"}
                isRequired={true}
                wrapperClassName={style.radioGroup}
                radios={[
                  {
                    check: (type) => type === UnitItemType.KPASS_VOUCHER,
                    onClickFunc: () => setUnitItemType(UnitItemType.KPASS_VOUCHER),
                    text: t("creator.type.kpassTest"),
                  },
                  {
                    check: (type) => type === UnitItemType.DCAS_VOUCHER,
                    onClickFunc: () => setUnitItemType(UnitItemType.DCAS_VOUCHER),
                    text: t("creator.type.dcasTest"),
                  },
                ]}
                selectedValue={unitItemType}
              />
            </div>
            {/*{unitItemType === UnitItemType.KPASS_VOUCHER || unitItemType === UnitItemType.CONSULTING_VOUCHER ? (*/}
            {unitItemType !== UnitItemType.DEFAULT ? (
              <div className={style.fieldLine}>
                <Label104x46 text={t("creator.validDates.label")} />
                <div className={style.validDateField}>
                  <Trans
                    t={t}
                    i18nKey={"list.columns.validDuration.validDurationValueOnCreator"}
                    values={{
                      durationType: toStringFromValidDurationFrom(
                          isVoucher(unitItemType)
                          ? ValidDurationFrom.ORDERED_DATE
                          : ValidDurationFrom.TEST_COMPLETED_DATE
                      ),
                    }}
                    components={[
                      <FilterNumber
                        numberField={{
                          type: FilterContentType.NUMBER,
                          value: validDateNum,
                          isRequired: true,
                          placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
                          onChangeFunc: (v: string) => setValidDateNum(v),
                        }}
                      />,
                    ]}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </form>
          <div className={modalStyle.buttonArea}>
            <button onClick={onClickCreateBtn}>{t("creator.button")}</button>
          </div>
        </>
      </Panel>
    </PanelWrapper>
  );
}
