import { MainApi } from "./MainApi";
import { MemoPostParam, MemoPutParam, MemoSearchCondition } from "../types/memo";

export class MemoApi {
  static baseUrl = `${MainApi.urlPrefix}/memo`;

  static getMemoList = (condition: MemoSearchCondition) => () =>
    MainApi.api.get(`${MemoApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static deleteMemo = (memoIdx: number) => () => MainApi.api.delete(`${MemoApi.baseUrl}/${memoIdx}`);

  static createMemo = (postParam: MemoPostParam) => () => MainApi.api.post(`${MemoApi.baseUrl}`, postParam);

  static updateMemo = (memoIdx: number, putParam: MemoPutParam) => () =>
    MainApi.api.put(`${MemoApi.baseUrl}/${memoIdx}`, putParam);
}
