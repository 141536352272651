import React from "react";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";
import {AdditionalResultType} from "../../../../types/common";
import {RadioGroupField} from "../../../../components/RadioGroupField";

interface Props {
    dcasAdditionalResult: AdditionalResultType;
    setDcasAdditionalResult: (_: AdditionalResultType) => void;
}

export function OrganManagerDcasAdditionalResult({dcasAdditionalResult, setDcasAdditionalResult}: Props) {
    const {toStringAdditionalType} = useOrganManagerType();

    const getLabel = (value: string) => {
        return toStringAdditionalType(value as AdditionalResultType);
    };

    const testResultFormValues = [
        AdditionalResultType.NONE, AdditionalResultType.SPECIALIZED_HIGH_SCHOOL
        // 현재 기업 면접 옵션을 사용하지 않기 때문에 임시 주석처리
        // , AdditionalResultType.CORPORATE_INTERVIEW
    ];

    return (
        <RadioGroupField enumValues={testResultFormValues}
                         getLabel={getLabel}
                         value={dcasAdditionalResult} setValue={setDcasAdditionalResult}
                         groupName={"DcasAdditionalResult"} isRequired={true}
        />
    );
}