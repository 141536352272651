import {useAppDispatch} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useCallback} from "react";
import {deleteBannersAsync} from "../../../../store/bannerSlice";

interface Args {
    onCompletedCallback: () => void;
}
export function useBannerDeleter({ onCompletedCallback }: Args) {
    const dispatch = useAppDispatch();
    const { defaultErrorMessage } = useUtil();

    const deleteBanners = useCallback(
        async (bannerIdxes: number[]) => {
            if (bannerIdxes.length === 0) {
                alert("배너를 선택해주세요.");
                return;
            }

            if (!window.confirm("선택한 배너를 삭제 하시겠습니까?")) {
                return;
            }

            try {
                await dispatch(deleteBannersAsync(bannerIdxes)).unwrap();
                alert("배너가 삭제 되었습니다.");
                onCompletedCallback();
            } catch (e) {
                console.error(e);
                alert(defaultErrorMessage);
                throw new Error("배너 삭제 에러");
            }
        },
        [onCompletedCallback, defaultErrorMessage, dispatch]
    );

    return {
        deleteBanners,
    };
}