import useOrderGetter from "../../hooks/useOrderGetter";
import useOrderItemGetter from "../../hooks/useOrderItemGetter";
import { useCallback, useState } from "react";
import useVoucherGetter from "../../hooks/useVoucherGetter";
import { useRefundsGetter } from "../component/refund/hooks/useRefundsGetter";
import { OrderItemVO, RefundVO } from "../../../../types/orders";
import { ProductVoucherDTO, ProductVoucherStatus } from "../../../../types/voucher";
import { CustomerType } from "../../../../types/common";
import { useUtil } from "../../../../util/hooks/useUtil";

export interface RefundOrder {
  orderIdx: number;
  refundablePrice: number;
  refundOrderItems: RefundOrderItem[];
  buyerIdx: number;
  customerType: CustomerType;
}

export interface RefundOrderItem {
  orderItemIdx: number;
  productImage: string;
  productTitle: string;
  paymentQuantity: number;
  refundQuantity: number;
  refundableQuantity: number;
  selectedRefundQuantity: number;
}

export default function useRefundModalGetter() {
  const { getOrders } = useOrderGetter();
  const { getOrderItems } = useOrderItemGetter();
  const { getProductVoucher, productVouchers } = useVoucherGetter();
  const { getRefunds } = useRefundsGetter();
  const [refundOrder, setRefundOrder] = useState<RefundOrder>();
  const { defaultErrorMessage } = useUtil();

  const getRefundOrder = useCallback(
    async (orderIdx: number) => {
      try {
        let orders = await getOrders(orderIdx);
        let orderItems = await getOrderItems(orderIdx);
        let productVouchers = await getProductVoucher(orderIdx);
        let refunds = await getRefunds(orderIdx);

        const refundOrderItems: RefundOrderItem[] = orderItems.map((orderItem: OrderItemVO) => {
          return {
            orderItemIdx: orderItem.idx,
            productImage: orderItem.productImage,
            productTitle: orderItem.productName,
            paymentQuantity: orderItem.quantity,
            refundQuantity: orderItem.refundQuantity,
            refundableQuantity: productVouchers.filter(
              (productVoucher: ProductVoucherDTO) =>
                productVoucher.productVoucherStatus === ProductVoucherStatus.NOT_GRANTED
            ).length,
            selectedRefundQuantity: 0,
          };
        });

        const refundOrder = {
          orderIdx: orders.idx,
          refundablePrice:
            orders.payAmount - refunds.reduce((sum: number, refund: RefundVO) => sum + refund.refundPrice, 0),
          refundOrderItems: refundOrderItems,
          buyerIdx: orders.buyerIdx,
          customerType: orders.customerType,
        };
        setRefundOrder(refundOrder);
        return refundOrder;
      } catch (e) {
        alert(defaultErrorMessage);
      }
    },
    [getOrders, getOrderItems, getProductVoucher, getRefunds, defaultErrorMessage]
  );

  const updateRefundQuantity = useCallback(function (orderItemIdx: number, quantity: number) {
    setRefundOrder((prevState) => {
      if (!prevState) return prevState;

      const refundOrderItems = prevState.refundOrderItems.map((orderItem) => {
        if (orderItem.orderItemIdx === orderItemIdx) {
          return { ...orderItem, selectedRefundQuantity: quantity };
        }
        return orderItem;
      });

      return { ...prevState, refundOrderItems };
    });
  }, []);

  return {
    refundOrder,
    getRefundOrder,
    updateRefundQuantity,
    productVouchers
  };
}
