import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { downloadUsersAsync } from "../../../../store/userSlice";
import { useDownloadFile } from "../../../../hooks/common/useDownloadFile";
import { useUtil } from "../../../../util/hooks/useUtil";

interface ReturnType {
  downloadUsers: (_: number[]) => void;
}

export function useUserDownloader(): ReturnType {
  const dispatch = useAppDispatch();

  const { getDownloadExcelFile } = useDownloadFile();
  const { defaultErrorMessage } = useUtil();

  const downloadUsers = useCallback(
    (userIdxes: number[]) => {
      callAsync(
        dispatch(downloadUsersAsync(userIdxes)).unwrap(),
        (result) => getDownloadExcelFile(result, "users"),
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    },
    [defaultErrorMessage, dispatch, getDownloadExcelFile]
  );

  return {
    downloadUsers,
  };
}
