import { AnImageAddition } from "../../../components/AnImageAddition";
import { AddedFile, FileType } from "../../../types/file";
import style from "../../product/edit/productEditForm.module.scss";

interface Props {
  popupImage?: AddedFile;
  setPopupImage: (_: AddedFile | undefined) => void;
  addedPopupImage?: FileType;
  setAddedPopupImage: (_: FileType | undefined) => void;
}

export function PopupImageField({ popupImage, setPopupImage, addedPopupImage, setAddedPopupImage }: Props) {
  return (
    <AnImageAddition
      buttonStyleObject={{
        width: "150px",
        height: "150px",
      }}
      image={popupImage}
      setImage={setPopupImage}
      addedImage={addedPopupImage}
      setAddedImage={setAddedPopupImage}
      additionalText={
        <span className={style.text}>
          * 팝업 이미지는 가로:세로 3:4 비율로 제작해 주세요.
          <br />
          * 권장 이미지 : 1M 이하 / gif, png, jpg(jpeg)
          <br />* 이미지 크기 : 600px*800px
        </span>
      }
    />
  );
}
