import { TableColumnData } from "../../../../../types/common";
import { AdminUserProfileTesterListObj } from "../../../../../types/userProfile";
import { CopiableText } from "../../../../../components/CopiableText";
import { AnchorComponent } from "../../../../../layout/content/AnchorComponent";
import { ActionButtonWrapper } from "../../../../../layout/content/ActionButtonWrapper";
import { Fragment } from "react";
import { ActionButton } from "../../../../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { useNewWindow } from "../../../../../hooks/hooks";
import { AdminMenuCode } from "../../../../../types/adminRole";
import { useTranslation } from "react-i18next";
import { useGenderType } from "../../../../../types/hooks/useGenderType";

interface Args {
  userIdx: number;
  setSelectedProfileIdx: (_: number) => void;
}

export function useUserTesterColumns({ userIdx, setSelectedProfileIdx }: Args) {
  const { t } = useTranslation(["user", "common"]);
  const navigate = useNavigate();
  const { openNewWindowWithURL } = useNewWindow();
  const { toStringFromGender } = useGenderType();

  const columns: TableColumnData<AdminUserProfileTesterListObj>[] = [
    {
      accessor: "name",
      header: t("detail.profile.list.name"),
      sortable: false,
      getNode: (it) => <AnchorComponent onClickFunc={() => setSelectedProfileIdx(it.idx)} content={it.name} />,
    },
    {
      accessor: "age",
      header: t("detail.profile.list.age"),
      sortable: false,
      getNode: (it) => <span>{it.age ?? t("noInputData", { ns: "common" })}</span>,
    },
    {
      accessor: "gender",
      header: t("detail.profile.list.gender"),
      sortable: false,
      getNode: (it) => <span>{it.gender ? toStringFromGender(it.gender) : t("noInputData", { ns: "common" })}</span>,
    },
    {
      accessor: "state",
      header: t("detail.profile.list.region"),
      sortable: false,
      getNode: (it) => <span>{it.state ?? t("noInputData", { ns: "common" })}</span>,
    },
    {
      accessor: "userCode",
      header: t("detail.profile.list.userCode"),
      sortable: false,
      getNode: (it) => <CopiableText text={it.userCode} isBlue={false} />,
      menuCode: AdminMenuCode.USER_DETAIL_USER_CODE_READ,
    },
    {
      accessor: "organ",
      header: t("detail.profile.list.organ"),
      sortable: false,
      getNode: (it, arrIdx) => (
        <>
          {it.organ ? (
            <>
              {it.organ.map((o, idx) => (
                <Fragment key={`organ-${o.idx}-${arrIdx}-${idx}`}>
                  <AnchorComponent
                    onClickFunc={(e) => {
                      e.preventDefault();
                      openNewWindowWithURL(
                        `/detail/member/organ-manager/${o.organManagerIdx}?tab=ORGAN&organ=${o.idx}`,
                        {
                          width: 1000,
                          height: 800,
                        }
                      );
                    }}
                    content={o.organName}
                  />
                  <br />
                </Fragment>
              ))}
            </>
          ) : (
            <>-</>
          )}
        </>
      ),
    },
    {
      accessor: "organVoucherCnt",
      header: t("detail.profile.list.organVoucher"),
      sortable: false,
    },
    {
      accessor: "testCnt",
      header: t("detail.profile.list.testCount"),
      sortable: false,
    },
    {
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (it) => (
        <ActionButtonWrapper>
          <ActionButton onClick={() => navigate(`/detail/member/user/${userIdx}?type=TESTERS&profileIdx=${it.idx}`)}>
            {t("detail.profile.list.seeMore")}
          </ActionButton>
        </ActionButtonWrapper>
      ),
      width: 104,
    },
  ];

  return {
    columns,
  };
}
