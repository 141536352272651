import { useCallback } from "react";
import { AffiliateCodeStatus } from "../../../../types/affiliateCode";
import { useAffiliateCodeStatusUpdater } from "./useAffiliateCodeStatusUpdater";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Args {
  resolve: () => void;
}
export function useAffiliateCodeStatusUpdaterWithValidation({ resolve }: Args) {
  const { updateAffiliateCodeStatus } = useAffiliateCodeStatusUpdater();
  const { defaultErrorMessage } = useUtil();

  const updateCodeStatus = useCallback(
    (codeIdxes: number[], toStatus: AffiliateCodeStatus) => {
      updateAffiliateCodeStatus(codeIdxes, toStatus)
        .then(() => resolve())
        .catch(() => alert(defaultErrorMessage));
    },
    [resolve, updateAffiliateCodeStatus, defaultErrorMessage]
  );

  const stopCodeUsage = useCallback(
    (codeIdxes: number[]) => {
      if (codeIdxes.length === 0) {
        alert("제휴코드를 선택해주세요.");
        return;
      }

      if (
        !window.confirm(
          "선택한 제휴코드를 모두 사용중지 하시겠습니까?\n사용중지 시 해당 제휴코드로 결제가 불가능합니다."
        )
      ) {
        return;
      }

      updateCodeStatus(codeIdxes, AffiliateCodeStatus.UNAVAILABLE);
    },
    [updateCodeStatus]
  );

  const resumeCodeUsage = useCallback(
    (codeIdxes: number[]) => {
      if (codeIdxes.length === 0) {
        alert("제휴코드를 선택해주세요.");
        return;
      }

      if (
        !window.confirm("선택한 제휴코드를 모두 사용재개 하시겠습니까?\n사용재개 시 해당 제휴코드로 결제가 가능합니다.")
      ) {
        return;
      }

      updateCodeStatus(codeIdxes, AffiliateCodeStatus.AVAILABLE);
    },
    [updateCodeStatus]
  );

  return {
    stopCodeUsage,
    resumeCodeUsage,
  };
}
