import { Calendar } from "../../../../types/tableFilter";
import { FilterCalendar } from "./FilterCalendar";

interface Props {
  from: Calendar;
  to: Calendar;
}

export function FilterCalendarRange({ from, to }: Props) {
  return (
    <>
      <FilterCalendar calendar={from} max={to.value} />
      ~
      <FilterCalendar calendar={to} min={from.value} />
    </>
  );
}
