import { OrderDirection, ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../../types/page";
import { CouponHistoryListObj, CouponHistorySearchCondition } from "../../../../../types/coupon";
import { callAsync } from "../../../../../util/sliceUtil";
import { getOrganManagerCouponHistoryListAsync, getUserCouponHistoryListAsync } from "../../../../../store/couponSlice";
import { useCallback, useMemo, useState } from "react";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { CustomerType } from "../../../../../types/common";
import { useUtil } from "../../../../../util/hooks/useUtil";

interface MemberUserCouponFilterValues {
  targetIdx: number;
  customerType: CustomerType;
  isAvailable: boolean;
}

export function useCouponList({ targetIdx, customerType, isAvailable }: MemberUserCouponFilterValues) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const [couponHistoryList, setCouponHistoryList] = useState<PageResponse<CouponHistoryListObj>>();

  const pageCondition: PageSearchCondition = useMemo(
    () => ({
      page: couponHistoryList?.number ?? 0,
      size: couponHistoryList?.size ?? 5,
      sort: couponHistoryList?.orders
        ? ordersToSortArr(couponHistoryList?.orders)
        : [{ columnName: "availableEndAt", direction: OrderDirection.DESC }],
    }),
    [couponHistoryList]
  );

  const getCouponHistory = useCallback(
    (condition: CouponHistorySearchCondition) => {
      if (customerType === CustomerType.USER) {
        return dispatch(getUserCouponHistoryListAsync({ userIdx: targetIdx, condition })).unwrap();
      }

      return dispatch(getOrganManagerCouponHistoryListAsync({ organManagerIdx: targetIdx, condition })).unwrap();
    },
    [dispatch, targetIdx, customerType]
  );

  const getCouponHistoryList = ({ page, sort, size }: PageSearchCondition) => {
    const condition: CouponHistorySearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: size ?? pageCondition.size,
        sort: sort ?? pageCondition.sort,
      },
      isAvailable,
    };

    return () => {
      callAsync<PageResponse<CouponHistoryListObj>>(
        getCouponHistory(condition),
        (result) => {
          setCouponHistoryList(result);
        },
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    };
  };

  return {
    getCouponHistoryList,
    couponHistoryList,
    pageCondition,
  };
}
