import { TableColumnData } from "../../../../../types/common";
import { CouponHistoryListObj, CouponType } from "../../../../../types/coupon";
import style from "../../../user/memberUserCoupons.module.scss";
import React from "react";
import { LOCALES_STRING } from "../../../../../util/etcUtil";
import { ProductSummaryViewer } from "../../../../product/ProductSummaryViewer";
import { useTimeConverter } from "../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useCouponType } from "../../../../../types/hooks/useCouponType";

interface Args {
  deleteCouponHistory: (_: number) => void;
}

export function useCouponColumns({ deleteCouponHistory }: Args) {
  const { t } = useTranslation("member");
  const { timeConverter } = useTimeConverter();
  const { toStringFromCouponType, toStringFromCouponHistoryStatus } = useCouponType();

  const getClassNameFromStatusText = (text: string) => {
    switch (text) {
      case t("coupon.status.expired"):
      case t("coupon.status.deleted"):
        return "red";
      case t("coupon.status.available"):
        return "blue";
      default:
        return "";
    }
  };

  const onClickDeleteBtn = (idx: number) => {
    const wantToDelete = window.confirm(t("coupon.delete.confirmMessage"));

    if (wantToDelete) {
      deleteCouponHistory(idx);
    }
  };

  const columns: TableColumnData<CouponHistoryListObj>[] = [
    {
      accessor: "availableEndAt",
      header: t("coupon.columns.availableEndAt"),
      getNode: (ch: CouponHistoryListObj) => (
        <span>
          {timeConverter.convertToLocalTime(ch.availableStartAt)}
          <br />~{timeConverter.convertToLocalTime(ch.availableEndAt)}
        </span>
      ),
      sortable: true,
      className: style.dueDateColumn,
    },
    {
      accessor: "status",
      header: t("coupon.columns.status"),
      getNode: (ch: CouponHistoryListObj) => (
        <span className={getClassNameFromStatusText(toStringFromCouponHistoryStatus(ch.status, ch.availableEndAt))}>
          {toStringFromCouponHistoryStatus(ch.status, ch.availableEndAt)}
        </span>
      ),
      sortable: false,
    },
    {
      accessor: "coupon",
      header: t("coupon.columns.type"),
      getNode: (ch: CouponHistoryListObj) => <span>{toStringFromCouponType(ch.coupon.type)}</span>,
      sortable: false,
    },
    {
      accessor: "coupon",
      header: t("coupon.columns.name"),
      getNode: (ch: CouponHistoryListObj) => <span>{ch.coupon.name}</span>,
      sortable: false,
      isLeftAlign: true,
    },
    {
      accessor: "coupon",
      header: t("coupon.columns.condition"),
      getNode: (ch: CouponHistoryListObj) => (
        <>
          {ch.coupon.type === CouponType.PRODUCT_DISCOUNT ? (
            <>
              <ProductSummaryViewer productIdx={ch.coupon?.productIdx ?? undefined} />
            </>
          ) : (
            <span>
              {ch.coupon.availableAboveAmount
                ? t("coupon.columns.aboveAmount", {
                    price: ch.coupon.availableAboveAmount.toLocaleString(LOCALES_STRING),
                  })
                : `-`}
            </span>
          )}
        </>
      ),
      sortable: false,
    },
    {
      accessor: "coupon",
      header: t("coupon.columns.benefits"),
      getNode: (ch: CouponHistoryListObj) => (
        <span>
          {ch.coupon.percent
            ? `${ch.coupon.percent}%`
            : `${t("coupon.columns.amountUnit", { price: ch.coupon.point?.toLocaleString(LOCALES_STRING) })} `}
          {t("coupon.columns.discount")}
        </span>
      ),
      sortable: false,
    },
    {
      accessor: "idx",
      header: "",
      getNode: (ch: CouponHistoryListObj) => (
        <>
          {toStringFromCouponHistoryStatus(ch.status, ch.availableEndAt) === t("coupon.status.available") ? (
            <button className={style.deleteBtn} onClick={() => onClickDeleteBtn(ch.idx)}>
              {t("coupon.delete.buttonLabel")}
            </button>
          ) : (
            <></>
          )}
        </>
      ),
      sortable: false,
    },
  ];

  return {
    columns,
  };
}
