import { useAffiliateCodeSettlementFilterValues } from "./settlement/hooks/useAffiliateCodeSettlementFilterValues";
import { SettlementList } from "./SettlementList";
import React from "react";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function SettlementListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList } = useAffiliateCodeSettlementFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        pageInfoOnFilter,
        filterValues,
        searchList,
      }}
    >
      <SettlementList />
    </ListFilterValuesContext.Provider>
  );
}
