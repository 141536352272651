import { useAppDispatch } from "../../../../hooks/hooks";
import { getAffiliateCodeSettlementsAsync } from "../../../../store/affiliateCodeSlice";
import { useCallback } from "react";
import {
  MonthlyAffiliateCodeListVO,
  SettlementDetailsVO,
  SettlementOrganManagerPageDTO,
  SettlementStatus,
} from "../../../../types/affiliateCode";
import { DEFAULT_PAGE, DEFAULT_SIZE, DEFAULT_SORT, PageResponse, PageSearchCondition } from "../../../../types/page";
import { getTwoDigitsText } from "../../../../util/etcUtil";

export interface SettlementSearchCondition {
  year: number;
  month: number;
  status?: SettlementStatus;
  pageCondition?: PageSearchCondition;
}

export function useAffiliateCodeSettlementsGetter() {
  const dispatch = useAppDispatch();

  const getAffiliateCodeSettlements = useCallback(
    async ({
      year,
      month,
      status,
      pageCondition,
    }: SettlementSearchCondition): Promise<PageResponse<SettlementDetailsVO>> => {
      try {
        const result: PageResponse<SettlementOrganManagerPageDTO> = await dispatch(
          getAffiliateCodeSettlementsAsync({
            pageCondition: {
              page: pageCondition?.page ?? DEFAULT_PAGE,
              size: pageCondition?.size ?? DEFAULT_SIZE,
              sort: pageCondition?.sort ?? DEFAULT_SORT,
            },
            yearMonth: `${year}-${getTwoDigitsText(month)}`,
            status,
          })
        ).unwrap();

        const details: PageResponse<SettlementDetailsVO> = {
          ...result,
          content: result.content.map((dto) => ({
            ...dto,
            idx: dto.organManagerIdx,
            codeDetails: dto.monthlyAffiliateCodeListDTO.map((codeDto) => codeDto as MonthlyAffiliateCodeListVO),
          })),
        };

        return details;
      } catch (e) {
        console.error(e);
        throw new Error("getAffiliateCodeSettlements occurs an error");
      }
    },
    [dispatch]
  );

  return {
    getAffiliateCodeSettlements,
  };
}
