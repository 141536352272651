import {
  useAppDispatch,
  useAppSelector,
  useListFilterValues,
  useNationality,
  useTimeConverter,
} from "../../../../hooks/hooks";
import { adminList, getAdminListAsync, setAdminList } from "../../../../store/adminSlice";
import { useEffect, useMemo, useState } from "react";
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SORT,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../types/page";
import { AdminListObj, AdminQueryType, AdminSearchCondition, AdminStatus } from "../../../../types/admin";
import { hasBothValuesOrBothNoValue } from "../../../../util/etcUtil";
import { AdminListQueryType } from "./useAdminFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useSortParam } from "../../../../hooks/list/useSortParam";
import { dateToString } from "../../../../util/dateUtil";

export function useAdminList() {
  const { t } = useTranslation("admin");
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const {
    searchWordTypeFilterValue,
    searchWordFilterValue,
    createdAtFromFilterValue,
    createdAtToFilterValue,
    adminStatusFilterValue,
    adminRoleIdxFilterValue,
  } = filterValues;
  const { nationality } = useNationality();
  const { timeConverter } = useTimeConverter();

  const dispatch = useAppDispatch();
  const list = useAppSelector(adminList);
  const { defaultErrorMessage } = useUtil();
  const { toSortArray } = useSortParam();

  const [totalAdminCnt, setTotalAdminCnt] = useState<number>(0);
  const pageCondition = useMemo(
    () => ({
      page: list?.number ?? 0,
      size: list?.size ?? 20,
      sort: ordersToSortArr(list?.orders),
    }),
    [list]
  );

  const searchAdminList = (pageSearchCondition: PageSearchCondition) => {
    const param: AdminListQueryType = {
      searchWordType: searchWordTypeFilterValue.value,
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      createdAtFrom: createdAtFromFilterValue.value ? dateToString(createdAtFromFilterValue.value) : undefined,
      createdAtTo: createdAtToFilterValue.value ? dateToString(createdAtToFilterValue.value) : undefined,
      adminStatus: adminStatusFilterValue.value ? adminStatusFilterValue.value : undefined,
      adminRoleIdx: adminRoleIdxFilterValue.value ? adminRoleIdxFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.createdAtFrom, param.createdAtTo)) {
        alert(t("list.validation.bothCreatedAtNeeded"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getAdminList = async (param: AdminSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<AdminListObj> = await dispatch(getAdminListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalAdminCnt(result.totalElements);
      } else {
        dispatch(setAdminList(result));
      }
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  useEffect(() => {
    getAdminList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      const adminListQueryType = listQueryType as AdminListQueryType;
      const param: AdminSearchCondition = {
        pageCondition: {
          page: adminListQueryType.page ? Number(adminListQueryType.page) : DEFAULT_PAGE,
          size: adminListQueryType.size ? Number(adminListQueryType.size) : DEFAULT_SIZE,
          sort: adminListQueryType.sort ? toSortArray(adminListQueryType.sort) : DEFAULT_SORT,
        },
        qtype: adminListQueryType.searchWordType ? (adminListQueryType.searchWordType as AdminQueryType) : undefined,
        q: adminListQueryType.searchWord?.trim(),
        createdAtFrom: adminListQueryType.createdAtFrom
          ? timeConverter.convertToFilterStartOfDay(adminListQueryType.createdAtFrom)
          : undefined,
        createdAtTo: adminListQueryType.createdAtTo
          ? timeConverter.convertToFilterEndOfDay(adminListQueryType.createdAtTo)
          : undefined,
        roleIdx: adminListQueryType.adminRoleIdx ? adminListQueryType.adminRoleIdx : undefined,
        status: adminListQueryType.adminStatus ? (adminListQueryType.adminStatus as AdminStatus) : undefined,
      };

      getAdminList(param).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchAdminList,
    totalAdminCnt,
    adminList: list,
    pageCondition,
  };
}
