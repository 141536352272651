import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";

const Button = styled.button`
  width: 200px;
  height: 66px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.8px;
  line-height: 1.6;
`;

export const ButtonWrapperStyle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.marginTB20 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const PrimaryButton = styled(Button)`
  color: white;
  background: ${variables.colorFGBlue};
  border: 1px solid ${variables.colorFGBlue};

  &.width300 {
    width: 300px;
  }

  &.fontSize20 {
    font-size: 20px;
  }
`;

export const PrimaryOutlineButton = styled(Button)`
  color: ${variables.colorFGBlue};
  background: white;
  border: 1px solid ${variables.colorFGBlue};
`;

export const ActionButton = styled.button`
  border: 1px solid ${variables.colorBodyTextBlue};
  border-radius: 2px;
  padding: 1px 6px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.colorBodyTextBlue};
  background-color: white;
`;

export const ActiveActionButton = styled(ActionButton)`
  background-color: ${variables.colorBodyTextBlue};
  color: white;
`;

export const RedActionButton = styled(ActionButton)`
  border: 1px solid ${variables.colorFGPointRed};
  color: ${variables.colorFGPointRed};
`;

export const DetailActionButton = styled.button`
  background-color: white;
  border: 1px solid ${variables.colorDE};
  display: flex;
  align-items: center;
  padding: 4px 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.colorFGDarkBlue};
`;

export const ListActionButton = styled(DetailActionButton)``;

export const AddOrganButton = styled.button`

  background-color: white;
  border: 1px solid ${variables.colorDE};
  display: flex;
  align-items: center;
  padding: 4px 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.colorFGDarkBlue};
`;

export const AddNewOrganMemberButton = styled(AddOrganButton)`
    width: 128px;
    max-width: 270px;
    height: 30px;
    gap:5px;
`;

export const OrganMemberListContainer = styled.div`
    width : 100%;
    display: flex;
    justify-content: space-between;
    align-items : flex-start;
`;
