import { CustomerType, TableColumnData } from "../../../../types/common";
import { MemberUserDetailTabType, UserListObj, UserStatus } from "../../../../types/user";
import React from "react";
import { DetailBarInfoCategory } from "../../../../types/newWindow";
import { useAppDispatch, useCountry, useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { unblockUsersAsync } from "../../../../store/userSlice";
import { callAsync } from "../../../../util/sliceUtil";
import { AnchorComponent } from "../../../../layout/content/AnchorComponent";
import { ActionButtonWrapper } from "../../../../layout/content/ActionButtonWrapper";
import { ActionButton, ActiveActionButton } from "../../../../components/Buttons";
import { AdminMenuCode } from "../../../../types/adminRole";
import { RoleChecker } from "../../../role/RoleChecker";
import { UserStatusText } from "../UserStatusText";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Args {
  getList: () => void;
}

export function useUserColumns({ getList }: Args) {
  const { t } = useTranslation("user");
  const dispatch = useAppDispatch();
  const { getCountryNameByCodeISOAlpha2 } = useCountry();
  const { openGrantCoupon, openGrantProduct, openUserDetailPopup } = useNewWindow();
  const { timeConverter } = useTimeConverter();
  const { defaultErrorMessage } = useUtil();

  const onClickDetail = (e: React.MouseEvent, u: UserListObj) => {
    e.preventDefault();

    openUserDetailPopup(u.idx, {
      type: MemberUserDetailTabType.SELF_INFO,
      name: isLeaved(u.status) ? "" : u.name,
      category: DetailBarInfoCategory.USER,
      isLeaved: isLeaved(u.status),
    });
  };

  const onClickUnblockUser = (userIdx: number) => {
    const isConfirmed = window.confirm(t("list.unblockConfirmMessage"));

    if (isConfirmed) {
      callAsync(
        dispatch(unblockUsersAsync([userIdx])).unwrap(),
        () => getList(),
        () => alert(defaultErrorMessage)
      ).then();
    }
  };

  const onClickGrantCouponBtn = (userIdx: number, userName: string) => {
    openGrantCoupon({
      targetIdx: userIdx,
      targetName: userName,
      customerType: CustomerType.USER,
    });
  };

  const onClickGrantProductBtn = (userIdx: number, userName: string) => {
    openGrantProduct({ targetIdxes: [userIdx], targetType: CustomerType.USER, targetName: userName });
  };

  const isLeaved = (s: UserStatus) => s === UserStatus.LEAVE;
  const isBlocked = (s: UserStatus) => s === UserStatus.BLOCKED;

  const columns: TableColumnData<UserListObj>[] = [
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      getNode: (u: UserListObj) => <span>{timeConverter.convertToLocalTime(u.createAt)}</span>,
      sortable: true,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      getNode: (u: UserListObj) => (
        <AnchorComponent
          onClickFunc={(e) => onClickDetail(e, u)}
          content={isLeaved(u.status) ? t("list.leavedMember") : u.name}
        />
      ),
      sortable: false,
      width: 104,
    },
    {
      accessor: "email",
      header: t("list.columns.id"),
      getNode: (u: UserListObj) => (
        <>{isLeaved(u.status) ? "-" : <AnchorComponent onClickFunc={(e) => onClickDetail(e, u)} content={u.email} />}</>
      ),
      sortable: false,
      width: 236,
    },
    {
      accessor: "phoneNumber",
      header: t("list.columns.phoneNumber"),
      sortable: false,
      getNode: (u) => <>{isLeaved(u.status) ? "-" : `(${u.countryPhoneCode}) ${u.phoneNumber}`}</>,
    },
    {
      accessor: "status",
      header: t("list.columns.status"),
      sortable: false,
      getNode: (u) => (
        <UserStatusText
          userStatus={u.status}
          leavedAt={u.leaveAt}
          blockedAt={u.blockAt}
          reasonForBlock={u.reasonForBlocking}
          reasonForLeave={""}
        />
      ),
    },
    {
      accessor: "age",
      header: t("list.columns.age"),
      getNode: (u: UserListObj) => <span>{isLeaved(u.status) ? "-" : u.age ?? "-"}</span>,
      sortable: true,
    },
    {
      accessor: "nationality",
      header: t("list.columns.nationality"),
      getNode: (u: UserListObj) => (
        <span>{isLeaved(u.status) ? "-" : getCountryNameByCodeISOAlpha2(u.nationality) ?? "-"}</span>
      ),
      sortable: false,
    },
    {
      accessor: "region",
      header: t("list.columns.region"),
      getNode: (u: UserListObj) => <span>{isLeaved(u.status) ? "-" : u.region ?? "-"}</span>,
      sortable: true,
    },
    {
      accessor: "totalTestCnt",
      header: t("list.columns.totalTestCount"),
      sortable: true,
      getNode: (u) => <span>{isLeaved(u.status) ? "-" : u.totalTestCnt}</span>,
    },
    {
      accessor: "idx",
      header: t("list.columns.relatedFeatures"),
      getNode: (u: UserListObj) => (
        <ActionButtonWrapper>
          <>
            {isLeaved(u.status) ? (
              <></>
            ) : (
              <>
                <RoleChecker menuCode={AdminMenuCode.USER_MEMO}>
                  {u.hasMemo ? (
                    <ActiveActionButton onClick={(e) => onClickDetail(e, u)}>
                      {t("list.actionButtons.memo")}
                    </ActiveActionButton>
                  ) : (
                    <ActionButton onClick={(e) => onClickDetail(e, u)}>{t("list.actionButtons.memo")}</ActionButton>
                  )}
                </RoleChecker>
                {isBlocked(u.status) ? (
                  <RoleChecker menuCode={AdminMenuCode.REVERT_BLOCKED_USER}>
                    <ActiveActionButton onClick={() => onClickUnblockUser(u.idx)}>
                      {t("list.actionButtons.unblock")}
                    </ActiveActionButton>
                  </RoleChecker>
                ) : (
                  <>
                    <RoleChecker menuCode={AdminMenuCode.ISSUE_COUPON}>
                      <ActionButton onClick={() => onClickGrantCouponBtn(u.idx, u.name)}>
                        {t("list.actionButtons.coupon")}
                      </ActionButton>
                    </RoleChecker>
                    <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_GRANT_PRODUCT}>
                      <ActionButton onClick={() => onClickGrantProductBtn(u.idx, u.name)}>
                        {t("list.actionButtons.grantProduct")}
                      </ActionButton>
                    </RoleChecker>
                  </>
                )}
              </>
            )}
          </>
        </ActionButtonWrapper>
      ),
      sortable: false,
    },
  ];

  return { columns };
}
