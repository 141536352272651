import { Status } from "../types/common";
import {PaymentWay} from "../types/payment";
import {SelectorOption} from "../types/selector";

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const fillZeroTo2Digit = (n: number) => n.toString().padStart(2, "0");

export const hasBothValuesOrBothNoValue = (a: any, b: any) =>
  (a !== 0 && !a && b !== 0 && !b) || ((a === 0 || Boolean(a)) && (b === 0 || Boolean(b)));

export const isFromNumberMoreThanToNumber = (from: number | undefined, to: number | undefined) =>
  from !== undefined && to !== undefined && from > to;
export const LOCALES_STRING = "ko-KR";
export const MINI_MODAL_LINK = "miniModalLink";

export const SELECTOR_AREA = "selectArea";
export const SELECTED_VALUE = "selectedValue";
export const SELECTOR_ARROW = "selectorArrow";

export const numberWithCommas = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const isLoadingStatus = (statusArr: Status[]) => statusArr.some((s) => s === Status.LOADING);

export const getTwoDigitsText = (num: number) => String(num).padStart(2, "0");

export const convertCurrencyUnit = (paymentWay: PaymentWay, t: any) => {
    return paymentWay === PaymentWay.PAYPAL ? t("columns.usAmountUnit") : t("columns.amountUnit");
};

// 국가 선택지 중 필터 처리 함수
export const filterAllowedCountriesCode = (
    {countries, withEmpty}: { countries:SelectorOption[], withEmpty: boolean; }
) => {
    const baseCountryCodes = ['KR', 'US', 'MN', 'TW', 'VN'];
    const allowedCountryCodes = withEmpty ? ['', ...baseCountryCodes] : baseCountryCodes;

    return countries.filter(option => allowedCountryCodes.includes(option.value));
};

export const filterAllowedCountriesNumber = (
    {countries, withEmpty}: { countries:SelectorOption[], withEmpty: boolean; }
) => {
    const baseCountryCodes = ['+82', '+1', '+976', '+886', '+84'];
    const allowedCountryCodes = withEmpty ? ['', ...baseCountryCodes] : baseCountryCodes;
    return countries.filter(option => allowedCountryCodes.includes(option.value));
};