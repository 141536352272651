import { useAppDispatch } from "../../../../../../hooks/hooks";
import { useCallback, useState } from "react";
import { RefundVO, OrderTranslator } from "../../../../../../types/orders";
import { getRefundsAsync } from "../../../../../../store/orderSlice";

export function useRefundsGetter() {
  const dispatch = useAppDispatch();
  const [refunds, setRefunds] = useState<RefundVO[]>([]);

  const getRefunds = useCallback(
    (orderIdx: number): Promise<RefundVO[]> => {
      return dispatch(getRefundsAsync(orderIdx))
        .unwrap()
        .then((refundDTOs) => {
          const refunds = refundDTOs.map(OrderTranslator.createRefundVO);
          setRefunds(refunds);
          return refunds;
        });
    },
    [dispatch]
  );

  return {
    getRefunds,
    refunds,
  };
}
