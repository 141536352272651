import React, { useCallback, useState } from "react";
import { useAppSelector, useNewWindow } from "../../../hooks/hooks";
import { memberUserStatus } from "../../../store/userSlice";
import { ListTable } from "../../../layout/content/list/ListTable";
import voucherIcon from "../../../assets/images/button_icons/voucher_icon.svg";
import { useUserFilters } from "./hooks/useUserFilters";
import { useUserColumns } from "./hooks/useUserColumns";
import { useUserList } from "./hooks/useUserList";
import { useLoader } from "../../../hooks/common/useLoader";
import { RoleChecker } from "../../role/RoleChecker";
import { AdminMenuCode } from "../../../types/adminRole";
import { CustomerType } from "../../../types/common";
import excelIcon from "../../../assets/images/button_icons/excel_icon.svg";
import { useUserDownloader } from "./hooks/useUserDownloader";
import { UserStatus } from "../../../types/user";
import { useTranslation } from "react-i18next";
import { ListCount, WhichList } from "../../../components/ListCount";

export function MemberUser() {
  const { t } = useTranslation(["user", "common"]);
  const status = useAppSelector(memberUserStatus);

  useLoader({ status });

  const [selectedUserIdxList, setSelectedUserIdxList] = useState<number[]>([]);

  const { searchUserList, totalMemberCnt, userList, pageCondition } = useUserList({
    onCompletedCallback: () => setSelectedUserIdxList([]),
  });
  const { filters } = useUserFilters({
    search: searchUserList({}),
  });
  const { columns } = useUserColumns({ getList: searchUserList({ page: pageCondition.page }) });
  const { openGrantProduct } = useNewWindow();

  const onClickGrantProductBtn = useCallback(() => {
    if (selectedUserIdxList.length === 0) {
      alert(t("list.grantProductAlertNoUser"));
      return;
    }

    openGrantProduct({
      targetIdxes: selectedUserIdxList,
      targetType: CustomerType.USER,
    });
  }, [t, selectedUserIdxList, openGrantProduct]);

  const { downloadUsers } = useUserDownloader();

  const onClickDownloadUsers = useCallback(() => {
    if (selectedUserIdxList.length < 1) {
      alert(t("list.noCheckedUser"));
    } else {
      downloadUsers(selectedUserIdxList);
    }
  }, [downloadUsers, t, selectedUserIdxList]);

  return (
    <>
      {userList ? (
        <ListTable
          pageOnFilter={pageCondition}
          filters={filters}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalMemberCnt ?? 0} whichList={WhichList.MEMBER} />,
            singleUnitKeyInCommonFile: "memberUnit.singleComponent",
            multipleUnitKeyInCommonFile: "memberUnit.multipleComponent",
          }}
          columns={columns}
          listData={userList}
          checkedRowIdxArr={selectedUserIdxList}
          setCheckedRowIdxArr={setSelectedUserIdxList}
          showCheckbox={(u) => u.status !== UserStatus.LEAVE}
          getList={({ page, sort, size }) => searchUserList({ page, sort, size })}
          buttonsNode={
            <>
              <div></div>
              <div>
                <button onClick={onClickDownloadUsers}>
                  <img src={excelIcon} alt={t("list.downloadExcel.iconAlt")} />
                  {t("list.downloadExcel.buttonLabel")}
                </button>
                <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_GRANT_PRODUCT}>
                  <button onClick={onClickGrantProductBtn}>
                    <img src={voucherIcon} alt={t("list.grantProduct.iconAlt")} />
                    {t("list.grantProduct.buttonLabel")}
                  </button>
                </RoleChecker>
              </div>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}
