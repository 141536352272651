import style from "../../../layout/content/post/creator.module.scss";

interface Props {
    buttonText: string;
    setButtonText: (_: string) => void;
}

export default function BannerButtonTextField({buttonText, setButtonText}: Props) {

    return (
        <>
            <input className={style.halfInputField}
                   value={buttonText}
                   required={true}
                   onChange={(e) => setButtonText(e.target.value)}
                   placeholder={"버튼 문구 입력 최대 50자(영문 소문자기준)"}
            />
        </>
    );
};