import {useAppDispatch, useTimeConverter} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useCallback} from "react";
import {BannerListVO, BannerStatus} from "../../../../types/banner";
import {updateBannerStatusAsync} from "../../../../store/bannerSlice";

interface Args {
    onCompletedCallback: () => void;
}

export function useBannerStatusUpdater({ onCompletedCallback }: Args) {
    const dispatch = useAppDispatch();
    const { defaultErrorMessage } = useUtil();
    const { timeConverter } = useTimeConverter();

    const updateBannerStatus = useCallback(
        async (banners: BannerListVO[], toStatus: BannerStatus, isButtonOnList: boolean) => {
            if (banners.length === 0) {
                alert("배너를 선택해주세요.");
                return;
            }

            if (
                toStatus === BannerStatus.EXPOSED &&
                banners.some((banner) => banner.availableEndAt !== null && timeConverter.isBeforeFromNow(banner.availableEndAt))
            ) {
                alert("배너 유효기간이 끝났습니다.\n유효기간을 수정 후 사용해 주세요");
                return;
            }

            if (
                !window.confirm(
                    `${isButtonOnList ? "" : "선택한 "}배너를 ${
                        toStatus === BannerStatus.EXPOSED ? "노출재개" : "노출중지"
                    } 하시겠습니까?`
                )
            )
                return;

            try {
                await dispatch(
                    updateBannerStatusAsync({
                        idxes: banners.map((p) => p.idx),
                        status: toStatus,
                    })
                ).unwrap();
                alert(
                    `${isButtonOnList ? "" : "선택한 "}배너가 홈페이지에 ${
                        toStatus === BannerStatus.EXPOSED ? "노출" : "미노출"
                    } 되었습니다.`
                );
                onCompletedCallback();
            } catch (e) {
                console.error(e);
                alert(defaultErrorMessage);
                throw new Error("팝업 상태 업데이트 에러");
            }
        },
        [timeConverter, defaultErrorMessage, dispatch, onCompletedCallback]
    );

    return {
        updateBannerStatus,
    };
}
