import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  PartnerCompanyOrderingNumberUpdateDTO,
  PartnerCompanyPostDTO,
  PartnerCompanyPutDTO,
  PartnerCompanySearchCondition,
  PartnerCompanyStatusUpdateDTO,
} from "../types/partnerCompany";
import { addCases, executePromise } from "../util/sliceUtil";
import { PartnerCompanyApi } from "../api/PartnerCompanyApi";
import { RootState } from "./index";

export interface PartnerCompanyState extends SliceState {
  isPartnerDataSaved: boolean;
}

const initialState: PartnerCompanyState = {
  status: Status.IDLE,
  isPartnerDataSaved: false,
};

export const createPartnerCompanyAsync = createAsyncThunk(
  "partnerCompany/createPartnerCompany",
  (param: PartnerCompanyPostDTO) => executePromise(PartnerCompanyApi.createPartnerCompany(param))
);

export const getPartnerCompanyAsync = createAsyncThunk("partnerCompany/getPartnerCompany", (idx: number) =>
  executePromise(PartnerCompanyApi.getPartnerCompany(idx))
);

export const updatePartnerCompanyAsync = createAsyncThunk(
  "partnerCompany/updatePartnerCompany",
  ({ idx, param }: { idx: number; param: PartnerCompanyPutDTO }) =>
    executePromise(PartnerCompanyApi.updatePartnerCompany(idx, param))
);

export const getPartnerCompaniesAsync = createAsyncThunk(
  "partnerCompany/getPartnerCompanies",
  (condition: PartnerCompanySearchCondition) => executePromise(PartnerCompanyApi.getPartnerCompanies(condition))
);

export const deletePartnerCompaniesAsync = createAsyncThunk(
  "partnerCompany/deletePartnerCompanies",
  (idxes: number[]) => executePromise(PartnerCompanyApi.deletePartnerCompanies(idxes))
);

export const updatePartnerCompanyStatusAsync = createAsyncThunk(
  "partnerCompany/updatePartnerCompanyStatus",
  (dto: PartnerCompanyStatusUpdateDTO) => executePromise(PartnerCompanyApi.updatePartnerCompanyStatus(dto))
);

export const updatePartnerCompanyOrderingNumberAsync = createAsyncThunk(
  "partnerCompany/updatePartnerCompanyOrderingNumber",
  (dtos: PartnerCompanyOrderingNumberUpdateDTO[]) =>
    executePromise(PartnerCompanyApi.updatePartnerCompanyOrderingNumber(dtos))
);

export const partnerCompanySlice = createSlice({
  name: "partnerCompany",
  initialState,
  reducers: {
    setIsPartnerDataSaved: (state, action: PayloadAction<boolean>) => {
      state.isPartnerDataSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, createPartnerCompanyAsync);
    addCases(builder, getPartnerCompanyAsync);
    addCases(builder, getPartnerCompaniesAsync);
    addCases(builder, updatePartnerCompanyAsync);
    addCases(builder, deletePartnerCompaniesAsync);
    addCases(builder, updatePartnerCompanyStatusAsync);
    addCases(builder, updatePartnerCompanyOrderingNumberAsync);
  },
});

export default partnerCompanySlice.reducer;

export const { setIsPartnerDataSaved } = partnerCompanySlice.actions;

export const partnerCompanyStatusState = (state: RootState) => state.partnerCompany.status;
export const isPartnerDataSavedState = (state: RootState) => state.partnerCompany.isPartnerDataSaved;
