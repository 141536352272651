import {ProfileQueryType} from "../../../../types/userProfile";
import {useCallback, useMemo, useState} from "react";
import {ListQueryType} from "../../../../types/list";
import {PageSearchCondition} from "../../../../types/page";
import {FilterType, FilterValue} from "../../../../types/filter";
import {useFilterValues} from "../../../../hooks/list/useFilterValues";
import {useListSearchParams} from "../../../../hooks/list/useListSearchParams";
import {
    AccessLocation,
    IsAgreementMarketing,
} from "../../../../types/tester";
import {QueryType} from "../../../../types/organManager";

export interface SimpleTesterListQueryType extends ListQueryType {
  searchWordType?: string;
  searchWord?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  accessLocation?: string;
  isAgreementMarketing?: string;
}

export interface SimpleTesterFilterValues {
  searchWordTypeFilterValue: FilterValue<ProfileQueryType>;
  searchWordFilterValue: FilterValue<string>;
  createdAtFromFilterValue: FilterValue<Date | undefined>;
  createdAtToFilterValue: FilterValue<Date | undefined>;
  accessLocationFilterValue: FilterValue<AccessLocation | undefined>;
  isAgreementMarketingFilterValue: FilterValue<IsAgreementMarketing | undefined>;
}

export function useSimpleTesterFilterValues() {
  const [searchWordType, setSearchWordType] = useState<QueryType>(QueryType.NAME);
  const [searchWord, setSearchWord] = useState("");
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();
  const [accessLocation, setAccessLocation] = useState("");
  const [isAgreementMarketing, setIsAgreementMarketing] = useState("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<SimpleTesterListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      {
        key: "searchWordType",
        value: searchWordType,
        setter: setSearchWordType,
        defaultValue: ProfileQueryType.NAME,
        type: FilterType.OTHER,
      },
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
          key: "accessLocation",
          value: accessLocation,
          setter: setAccessLocation,
          defaultValue: "",
          type: FilterType.OTHER
      },
      {
          key: "isAgreementMarketing",
          value: isAgreementMarketing,
          setter: setIsAgreementMarketing,
          defaultValue: "",
          type: FilterType.OTHER
      },

    ],
      //eslint-disable-next-line
    [
      searchWordType,
      searchWord,
      createdAtFrom,
      createdAtTo,
      accessLocation,
      isAgreementMarketing,
    ]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<ProfileQueryType>,
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
        createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
        accessLocationFilterValue: getFilterValueByKey("accessLocation") as FilterValue<AccessLocation | undefined>,
        isAgreementMarketingFilterValue:
            getFilterValueByKey("isAgreementMarketing") as FilterValue<IsAgreementMarketing | undefined>,
      } as SimpleTesterFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: SimpleTesterListQueryType) => {
      const param: SimpleTesterListQueryType = {
        searchWordType: listQueryTypeParam?.searchWordType,
        searchWord: listQueryTypeParam?.searchWord,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
        accessLocation: listQueryTypeParam?.accessLocation,
        isAgreementMarketing: listQueryTypeParam?.isAgreementMarketing,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
