import style from "../productEditForm.module.scss";
import { SearchField } from "../../../../layout/content/detail/SearchField";
import React, { useCallback, useState } from "react";
import { ProductItemInDetail } from "../../../../types/product";
import { callAsync } from "../../../../util/sliceUtil";
import { useAppDispatch } from "../../../../hooks/hooks";
import { DEFAULT_SIZE_IN_SUB_TABLE, PageResponse } from "../../../../types/page";
import { UnitItemListObj, UnitItemType } from "../../../../types/unitItem";
import { getUnitItemListAsync } from "../../../../store/unitItemSlice";
import { ActionButton } from "../../../../components/Buttons";
import { Label104x46 } from "../../../../components/Label";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Props {
  productItems: ProductItemInDetail[];
  isCreatePage: boolean;
  addedUnitItems: ProductItemInDetail[];
  setAddedUnitItems: (_: ProductItemInDetail[]) => void;
}

const NoUnitItemStyle = styled.li`
  padding: 12px 0;
  width: 100%;
  text-align: center;
`;

const AddButtonStyle = styled(ActionButton)`
  flex-shrink: 0;
`;

export function ProductEditUnitItems({ productItems, isCreatePage, addedUnitItems, setAddedUnitItems }: Props) {
  const { t } = useTranslation("product");
  const dispatch = useAppDispatch();

  const [unitItemSearchWord, setUnitItemSearchWord] = useState("");
  const [unitItems, setUnitItems] = useState<UnitItemListObj[]>([]);
  const [isKpassUnitItemAdded, setIsKpassUnitItemAdded] = useState(false);
  const { defaultErrorMessage } = useUtil();

  const getUnitItemList = () => {
    callAsync<PageResponse<UnitItemListObj>>(
      dispatch(
        getUnitItemListAsync({
          q: unitItemSearchWord.trim(),
          pageCondition: { page: 0, size: DEFAULT_SIZE_IN_SUB_TABLE },
        })
      ).unwrap(),
      (result) => {
        setUnitItemSearchWord(unitItemSearchWord.trim());
        setUnitItems(result.content);
      },
      () => alert(defaultErrorMessage)
    ).then();
  };

  const isAdded = useCallback(
    (ui: UnitItemListObj) => addedUnitItems.findIndex((aui) => aui.idx === ui.idx) > -1,
    [addedUnitItems]
  );

  return (
    <div className={style.field}>
      <Label104x46 text={t("edit.unitItem.title")} className={style.label} />
      <div className={style.unitItemField}>
        {isCreatePage ? (
          <>
            <SearchField
              className={style.productEditUnitItemSearchField}
              value={unitItemSearchWord}
              placeholder={t("edit.unitItem.search.placeholder")}
              onKeyUpFunc={() => {}}
              onChangeFunc={(e) => setUnitItemSearchWord((e.target as HTMLInputElement).value)}
              onClickSearchButtonFunc={(e) => {
                e.preventDefault();
                getUnitItemList();
              }}
            />
            <ul className={style.searchResults}>
              {unitItems.length === 0 ? <NoUnitItemStyle>{t("edit.unitItem.search.noData")}</NoUnitItemStyle> : <></>}
              {unitItems.map((ui, idx) => {
                const isAddedItem = isAdded(ui);
                const isKpassUnitItem = ui.type === UnitItemType.KPASS_VOUCHER;
                return (
                  <li
                    key={`unitItems-${idx}`}
                    className={`${style.item} ${
                      isAddedItem || (isKpassUnitItem && isKpassUnitItemAdded) ? style.added : ""
                    }`}
                  >
                    {ui.name}
                    {isAddedItem ? (
                      <span className={style.text}>{t("edit.unitItem.added")}</span>
                    ) : (
                      <>
                        {isKpassUnitItem && isKpassUnitItemAdded ? (
                          <></>
                        ) : (
                          <>
                            <AddButtonStyle
                              onClick={(e) => {
                                e.preventDefault();
                                setAddedUnitItems([
                                  ...addedUnitItems,
                                  { idx: ui.idx, unitItemName: ui.name, itemType: ui.type },
                                ]);
                                if (isKpassUnitItem) {
                                  setIsKpassUnitItemAdded(true);
                                }
                              }}
                            >
                              {t("edit.unitItem.addButton")}
                            </AddButtonStyle>
                          </>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <></>
        )}
        <div className={style.addedItems}>
          <h3 className={style.title}>{t("edit.unitItem.addedItems")}</h3>
          <div className={style.content}>
            {productItems?.length > 0 || addedUnitItems.length > 0 ? (
              <ul className={style.list}>
                {productItems.map((item, idx) => (
                  <li key={`product-item-${idx}`} className={style.item}>
                    <label>
                      {t("edit.unitItem.title")} {idx + 1}
                    </label>
                    <span>{item.unitItemName}</span>
                  </li>
                ))}
                {addedUnitItems.map((item, idx) => (
                  <li key={`added-item-${idx}`} className={style.item}>
                    <label>
                      {t("edit.unitItem.title")} {(productItems?.length ?? 0) + idx + 1}
                    </label>
                    <span>{item.unitItemName}</span>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        const copiedList = [...addedUnitItems];
                        copiedList.splice(idx, 1);
                        setAddedUnitItems(copiedList);
                        if (item.itemType === UnitItemType.KPASS_VOUCHER) {
                          setIsKpassUnitItemAdded(false);
                        }
                      }}
                    >
                      {t("edit.unitItem.deleteButton")}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              <span className={style.noData}>{t("edit.unitItem.noAddedItem")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
