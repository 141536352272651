import style from "./contentDetail.module.scss";
import logo from "../../../assets/images/feelgood_logo_white.svg";
import closeBtn from "../../../assets/images/close_btn.svg";
import { Outlet } from "react-router-dom";
import { useContentDetailHeaderInfoGetter } from "../hooks/useContentDetailHeaderInfoGetter";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../selector/LanguageSelector";
import { TimeZoneSelector } from "../selector/TimeZoneSelector";

export function ContentDetail() {
  const { category, name } = useContentDetailHeaderInfoGetter();
  const { t } = useTranslation("common");

  const onClickCloseBtn = () => {
    window.close();
  };

  return (
    <div className={style.detail}>
      <div className={style.header}>
        <div className={style.logoArea}>
          <img className={style.logoImg} src={logo} alt="logo" />
          <span className={style.badge}>Admin</span>
        </div>
        <div className={style.titleInfo}>
          {category ? <span className={style.roleName}>[{category.toString()}]</span> : <></>}
          {name ? (
            <span className={style.name}>
              <span className={style.accent}>{name}</span>
              {t("sir")}
            </span>
          ) : (
            <></>
          )}
          <div className={style.selectors}>
            <LanguageSelector isWhiteVersion={true} />
            <TimeZoneSelector isWhiteVersion={true} />
          </div>
          <button className={style.closeBtn} onClick={onClickCloseBtn}>
            <img src={closeBtn} alt="close button icon" />
          </button>
        </div>
      </div>
      <Outlet />
    </div>
  );
}
