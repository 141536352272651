import { useAppDispatch } from "../../hooks/hooks";
import { useCallback } from "react";
import { collectOrganVoucherAsync } from "../../store/voucherSlice";

export function useOrganVoucherCollector() {
  const dispatch = useAppDispatch();

  const collectOrganVoucher = useCallback(
    (productVoucherIdx: number, organManagerIdx: number) => {
      return dispatch(collectOrganVoucherAsync({ voucherIdx: productVoucherIdx, organManagerIdx })).unwrap();
    },
    [dispatch]
  );

  return {
    collectOrganVoucher,
  };
}
