import {Post} from "../../../layout/content/post/Post";
import {Label104x46} from "../../../components/Label";
import {PopupNameField} from "./PopupNameField";
import React, {useCallback, useState} from "react";
import style from "../../../layout/content/post/creator.module.scss";
import {LinkOption, PopupStatus, PopupType} from "../../../types/popup";
import {AddedFile, FileType} from "../../../types/file";
import {PopupDurationField} from "./PopupDurationField";
import {PopupTypeField} from "./PopupTypeField";
import {PopupImageField} from "./PopupImageField";
import {PopupImageLinkField} from "./PopupImageLinkField";
import {PopupLinkOptionField} from "./PopupLinkOptionField";
import {PopupStatusField} from "./PopupStatusField";
import {usePopupCreator} from "./hooks/usePopupCreator";
import {dateToString} from "../../../util/dateUtil";
import {usePopupValidator} from "./hooks/usePopupValidator";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {isPopupDataSavedState, popupStatusState, setIsPopupDataSaved} from "../../../store/popupSlice";
import {useLoader} from "../../../hooks/common/useLoader";
import {useNavigate} from "react-router-dom";
import {useUtil} from "../../../util/hooks/useUtil";
import {useNavigationBlocker} from "../../../hooks/navigationblocker/useNavigationBlocker";
import WebsiteTypeField from "./WebsiteTypeField";
import {Languages, WebsiteType} from "../../../types/common";
import {PopupLanguageField} from "./PopupLanguageField";

export function PopupCreator() {
  const dispatch = useAppDispatch();
  const isDataSaved = useAppSelector(isPopupDataSavedState);
  const popupStatus = useAppSelector(popupStatusState);
  useLoader({ status: popupStatus });

  const [name, setName] = useState("");
  const [availableStartAt, setAvailableStartAt] = useState(dateToString(new Date()));
  const [availableEndAt, setAvailableEndAt] = useState("");
  const [isAvailableAlways, setIsAvailableAlways] = useState(false);
  const [popupLanguage, setPopupLanguage] = useState(Languages.KO);
  const [popupType, setPopupType] = useState(PopupType.IMAGE);
  const [websiteType, setWebsiteType] = useState(WebsiteType.KPASS);
  const [popupImage, setPopupImage] = useState<AddedFile | undefined>();
  const [addedPopupImage, setAddedPopupImage] = useState<FileType | undefined>();
  const [imageLink, setImageLink] = useState("");
  const [linkOption, setLinkOption] = useState<LinkOption>(LinkOption.NEW_TAB);
  const [status, setStatus] = useState<PopupStatus>(PopupStatus.UNEXPOSED);

  const [hasChangedValue, setHasChangedValue] = useState(false);

  const navigate = useNavigate();
  const { defaultErrorMessage } = useUtil();
  const { createPopup } = usePopupCreator();
  const { validate } = usePopupValidator({
    name,
    image: popupImage,
    addedImage: addedPopupImage,
    isAvailableAlways,
    availableEndAt,
    confirmMessage: "팝업을 등록 하시겠습니까?",
  });

  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      if (v) {
        dispatch(setIsPopupDataSaved(false));
        setHasChangedValue(true);
      }
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  const onClickCreateButton = useCallback(() => {
    if (!validate()) return;

    createPopup({
      name,
      availableStartAt,
      availableEndAt,
      type: popupType,
      image: addedPopupImage!,
      imageLink,
      linkOption,
      status,
      websiteType,
      language: popupLanguage,
    })
      .then(() => {
        alert("팝업이 등록 되었습니다.");
        navigate(`/catalog/popup-list`);
      })
      .catch(() => alert(defaultErrorMessage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addedPopupImage,
    availableEndAt,
    availableStartAt,
    imageLink,
    linkOption,
    name,
    popupType,
    status,
    validate,
    createPopup,
    navigate,
    defaultErrorMessage,
  ]);

  useNavigationBlocker({
    hasChangedValue,
    isDataSaved,
    confirmMessage: "입력한 팝업 정보가 모두 사라집니다.\n정말 이 페이지를 벗어나시겠습니까?",
  });

  return (
    <Post title={"팝업 등록"} buttonTitle={"팝업 등록"} buttonOnClickAction={onClickCreateButton}>
      <>
        <div className={style.fieldLine}>
          <Label104x46 text={"팝업명"} isRequired={true} />
          <PopupNameField name={name} setName={(v) => changeValue(v, setName)} />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"홈페이지"} isRequired={true}/>
          <WebsiteTypeField websiteType={websiteType}
                            setWebsiteType={(v) => {
                              changeValue(v, setWebsiteType);
                              setPopupLanguage(Languages.KO)
                            }}/>
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"유효기간"} isRequired={true} />
          <PopupDurationField
            startAt={availableStartAt}
            setStartAt={(v) => changeValue(v, setAvailableStartAt)}
            endAt={availableEndAt}
            setEndAt={(v) => changeValue(v, setAvailableEndAt)}
            isAvailableAlways={isAvailableAlways}
            setIsAvailableAlways={(v) => changeValue(v, setIsAvailableAlways)}
          />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"팝업 언어"} isRequired={true}/>
          <PopupLanguageField websiteType={websiteType} popupLanguage={popupLanguage}
                            setPopupLanguage={(v) => {changeValue(v, setPopupLanguage);}}/>
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"팝업 유형"} isRequired={true} />
          <PopupTypeField popupType={popupType} setPopupType={(v) => changeValue(v, setPopupType)} />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"팝업 이미지"} isRequired={true} className={"alignSelfStart"} />
          <PopupImageField
            popupImage={popupImage}
            setPopupImage={(v) => changeValue(v, setPopupImage)}
            addedPopupImage={addedPopupImage}
            setAddedPopupImage={(v) => changeValue(v, setAddedPopupImage)}
          />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"이미지 링크"} />
          <PopupImageLinkField imageLink={imageLink} setImageLink={(v) => changeValue(v, setImageLink)} />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"링크 옵션"} isRequired={true} />
          <PopupLinkOptionField linkOption={linkOption} setLinkOption={(v) => changeValue(v, setLinkOption)} />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"상태"} isRequired={true} />
          <PopupStatusField popupStatus={status} setPopupStatus={(v) => changeValue(v, setStatus)} />
        </div>
      </>
    </Post>
  );
}
