import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";

export const Textarea = styled.textarea`
  border: 1px solid ${variables.colorDE};
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: ${variables.color75};

  &::placeholder {
    color: ${variables.colorC6};
  }
`;
