import {Languages, TableColumnData} from "../../../types/common";
import {DisplayPosition, ProductListObj, ProductStatus} from "../../../types/product";
import {AnchorComponent} from "../../../layout/content/AnchorComponent";
import {convertCurrencyUnit, LOCALES_STRING, MINI_MODAL_LINK} from "../../../util/etcUtil";
import {ActionButtonWrapper} from "../../../layout/content/ActionButtonWrapper";
import React, {useCallback, useRef, useState} from "react";
import {ProductItemModal} from "../modal/ProductItemModal";
import style from "../productList.module.scss";
import {ActionButton, ActiveActionButton} from "../../../components/Buttons";
import {Anchor} from "../../../components/Anchor";
import {useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useProductType} from "../../../types/hooks/useProductType";
import {PaymentWay} from "../../../types/payment";

interface Args {
  stopSelling: ((_: number[]) => void) | null;
  resumeSelling: ((_: number[]) => void) | null;
  getProductList: () => void;
  hasProductLink: boolean;
}

export function useProductColumns({ stopSelling, resumeSelling, getProductList, hasProductLink }: Args) {
  const { t } = useTranslation("product");
  const { toStringFromProductStatus, toStringFromDisplayPosition, toStringFromLanguage } = useProductType();
  const isGrantableProduct = (d: DisplayPosition) => d === DisplayPosition.GRANTABLE_PRODUCT;
  const isSelling = (s: ProductStatus) => s === ProductStatus.IS_SELLING;
  const { openProductDetail } = useNewWindow();
  const { timeConverter } = useTimeConverter();

  const ProductItemCount = ({ p }: { p: ProductListObj }) => {
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const [showProductItems, setShowProductItems] = useState(false);

    const onClickAnchor = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      setShowProductItems(true);
    }, []);

    return (
      <span className={style.itemCount}>
        <Anchor onClick={onClickAnchor} href={"#"} rel="noopener noreferrer" ref={linkRef} data-name={MINI_MODAL_LINK}>
          {String(p.productItemCount)}
        </Anchor>
        <ProductItemModal
          isShow={showProductItems}
          callbackCloseModal={() => setShowProductItems(false)}
          productIdx={p.idx}
          linkElement={linkRef.current ?? undefined}
        />
      </span>
    );
  };

  const columns: TableColumnData<ProductListObj>[] = [
    {
      accessor: "displayPosition",
      header: t("list.columns.displayPosition"),
      getNode: (p) => <>{toStringFromDisplayPosition(p.displayPosition)}</>,
      sortable: false,
    },
    {
      accessor: "language",
      header: t("language.title"),
      getNode: (p) => (
          <>
            {p.language === undefined ? (
                "-"
            ) : (
                toStringFromLanguage(p.language)
            )}
          </>
      ),
      sortable: false,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      getNode: (p) => (
        <div className={style.name}>
          {isGrantableProduct(p.displayPosition) ? (
            <>{p.name}</>
          ) : (
            <>
              <img
                className={style.thumbnail}
                src={p.thumbnailUrl ?? p.thumbnailUri}
                alt={t("list.columns.thumbnail.alt")}
              />
              {hasProductLink ? (
                <AnchorComponent
                  onClickFunc={(e) => {
                    e.preventDefault();
                    openProductDetail(p.idx, getProductList);
                  }}
                  content={p.name}
                />
              ) : (
                <>{p.name}</>
              )}
            </>
          )}
        </div>
      ),
      sortable: false,
      isLeftAlign: true,
    },
    {
      accessor: "sellingStartDate",
      header: t("edit.sellingDate.title"),
      getNode: (p) => (
        <>
          {isGrantableProduct(p.displayPosition) ? (
            "-"
          ) : (
            <span>
              {timeConverter.convertToLocalTime(p.sellingStartDate)}
              <br />~{" "}
              {p.sellingEndDate
                ? timeConverter.convertToLocalTime(p.sellingEndDate)
                : t("edit.sellingDate.regularSales")}
            </span>
          )}
        </>
      ),
      sortable: true,
    },
    {
      accessor: "productItemCount",
      header: t("edit.unitItem.title"),
      getNode: (p) => <ProductItemCount p={p} />,
      sortable: false,
    },
    {
      accessor: "isCouponUsable",
      header: t("edit.couponUsable.label"),
      getNode: (p) => (
        <>
          {isGrantableProduct(p.displayPosition)
            ? "-"
            : p.isCouponUsable
            ? t("edit.couponUsable.available")
            : t("edit.couponUsable.unavailable")}
        </>
      ),
      sortable: false,
    },
    {
      accessor: "status",
      header: t("list.columns.status"),
      getNode: (p) => (
        <span className={isSelling(p.status) ? "blue" : "red"}>{toStringFromProductStatus(p.status)}</span>
      ),
      sortable: false,
    },
    {
      accessor: "regularPrice",
      header: t("edit.regularPrice.title"),
      getNode: (p) => <>
        {p.regularPrice.toLocaleString(LOCALES_STRING)}
          {convertCurrencyUnit(p.language !== Languages.KO ? PaymentWay.PAYPAL : PaymentWay.CARD, t)}
      </>,
      sortable: false,
    },
    {
      accessor: "sellingPrice",
      header: t("edit.sellingPrice.title"),
      getNode: (p) => <>
        {p.sellingPrice.toLocaleString(LOCALES_STRING)}
        {convertCurrencyUnit(p.language !== Languages.KO ? PaymentWay.PAYPAL : PaymentWay.CARD, t)}
      </>,
      sortable: false,
    },
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      getNode: (p) => <>{timeConverter.convertToLocalTime(p.createAt)}</>,
      sortable: true,
    },
    {
      accessor: "idx",
      header: t("list.columns.additionalFunctions"),
      getNode: (p) => (
        <ActionButtonWrapper>
          <>
            {isGrantableProduct(p.displayPosition) ? (
              <></>
            ) : (
              <>
                {isSelling(p.status) ? (
                  stopSelling ? (
                    <ActionButton onClick={() => stopSelling([p.idx])}>{t("list.columns.stopSelling")}</ActionButton>
                  ) : (
                    <></>
                  )
                ) : resumeSelling ? (
                  <ActiveActionButton onClick={() => resumeSelling([p.idx])}>
                    {t("list.columns.resumeSelling")}
                  </ActiveActionButton>
                ) : (
                  <></>
                )}
                <ActionButton onClick={() => {}}>{t("list.columns.sellingURL")}</ActionButton>
              </>
            )}
          </>
        </ActionButtonWrapper>
      ),
      sortable: false,
      width: 170
    },
  ];

  return columns;
}
