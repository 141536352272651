import { useOrganVoucherStatusVO } from "../../../../../hooks/common/useOrganVoucherStatusVO";
import { TableColumnData } from "../../../../../types/common";
import {ProfileOrganVoucherListVO, VoucherStatusVO, VoucherType} from "../../../../../types/voucher";
import React from "react";
import { CopiableText } from "../../../../../components/CopiableText";
import { RedActionButton } from "../../../../../components/Buttons";
import { useAppSelector, useTimeConverter } from "../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useVoucherType } from "../../../../../types/hooks/useVoucherType";
import { useOrganVoucherCollector } from "../../../../voucher/useOrganVoucherCollector";
import { userProfileNameState } from "../../../../../store/userProfileSlice";

interface Args {
  onRefreshCallback: () => void;
}

export function useTesterOrganVoucherColumns({ onRefreshCallback }: Args) {
  const { t } = useTranslation("user");
  const { getTextClassNameByVoucherStatusVO } = useOrganVoucherStatusVO();
  const { timeConverter } = useTimeConverter();
  const { toStringFromVoucherStatusVO } = useVoucherType();
  const { collectOrganVoucher } = useOrganVoucherCollector();
  const profileName = useAppSelector(userProfileNameState);

  const columns: TableColumnData<ProfileOrganVoucherListVO>[] = [
    {
      accessor: "unitVoucherType",
      header: t("detail.profile.organVoucher.type"),
      sortable: false,
      getNode: (a) => <span>{VoucherType[a.unitVoucherType as keyof typeof VoucherType]}</span>,
    },
    {
      accessor: "grantedAt",
      header: t("detail.profile.organVoucher.grantedAt"),
      sortable: true,
      getNode: (a) => <span>{timeConverter.convertToLocalTime(a.grantedAt)}</span>,
    },
    {
      accessor: "availableUntil",
      header: t("detail.profile.organVoucher.availableUntil"),
      sortable: true,
      getNode: (a) => <span>{timeConverter.convertToLocalTime(a.availableUntil)}</span>,
    },
    {
      accessor: "issuerOrganName",
      header: t("detail.profile.organVoucher.issuerOrganName"),
      sortable: false,
    },
    {
      accessor: "voucherStatusVO",
      header: t("detail.profile.organVoucher.voucherStatus"),
      sortable: false,
      getNode: (a) => (
        <span className={getTextClassNameByVoucherStatusVO(a.voucherStatusVO)}>
          {toStringFromVoucherStatusVO(a.voucherStatusVO)}
        </span>
      ),
    },
    {
      accessor: "organSignInCode",
      header: t("detail.profile.organVoucher.organSignInCode"),
      sortable: false,
      getNode: (a) =>
        a.voucherStatusVO === VoucherStatusVO.USABLE ? <CopiableText text={a.organSignInCode} isBlue={true} /> : <>-</>,
    },
    {
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (a) => (
        <>
          {a.voucherStatusVO === VoucherStatusVO.USABLE ? (
            <RedActionButton
              onClick={() => {
                if (
                  window.confirm(t("detail.organVoucherInfo.list.revokeVoucherConfirmMessage", { name: profileName }))
                ) {
                  collectOrganVoucher(a.idx, a.organManagerIdx).then(() => {
                    alert(t("detail.organVoucherInfo.list.revokeVoucherResult"));
                    onRefreshCallback();
                  });
                }
              }}
            >
              {t("detail.profile.organVoucher.revokeVoucher")}
            </RedActionButton>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return {
    columns,
  };
}
