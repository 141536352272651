import { TableColumnData } from "../../../../../types/common";
import {OrganVoucherListVO, VoucherStatusVO, VoucherType} from "../../../../../types/voucher";
import React from "react";
import { RedActionButton } from "../../../../../components/Buttons";
import { CopiableText } from "../../../../../components/CopiableText";
import { Anchor } from "../../../../../components/Anchor";
import { useOrganVoucherStatusVO } from "../../../../../hooks/common/useOrganVoucherStatusVO";
import { useOrganVoucherCollector } from "../../../../voucher/useOrganVoucherCollector";
import { useNewWindow, useTimeConverter } from "../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";
import { useVoucherType } from "../../../../../types/hooks/useVoucherType";

interface Args {
  userIdx: number;
  name: string;
  onCollectCompleted: () => void;
}

export function useUserOrganVoucherInfoColumns({ userIdx, name, onCollectCompleted }: Args) {
  const { t } = useTranslation("user");
  const { getTextClassNameByVoucherStatusVO } = useOrganVoucherStatusVO();
  const { collectOrganVoucher } = useOrganVoucherCollector();
  const { openNewWindowWithURL } = useNewWindow();
  const { timeConverter } = useTimeConverter();
  const { defaultErrorMessage } = useUtil();
  const { toStringFromVoucherStatusVO } = useVoucherType();

  const columns: TableColumnData<OrganVoucherListVO>[] = [
    {
      accessor: "unitVoucherType",
      header: t("detail.organVoucherInfo.list.type"),
      sortable: false,
      getNode: (a) => <span>{VoucherType[a.unitVoucherType as keyof typeof VoucherType]}</span>,
    },
    {
      accessor: "grantedAt",
      header: t("detail.organVoucherInfo.list.grantedAt"),
      sortable: true,
      getNode: (a) => <span>{timeConverter.convertToLocalTime(a.grantedAt)}</span>,
    },
    {
      accessor: "availableUntil",
      header: t("detail.organVoucherInfo.list.availableUntil"),
      sortable: true,
      getNode: (a) => <span>{timeConverter.convertToLocalTime(a.availableUntil)}</span>,
    },
    {
      accessor: "profileName",
      header: t("detail.organVoucherInfo.list.profileName"),
      sortable: false,
      getNode: (a) => (
        <Anchor
          onClick={(e) => {
            e.preventDefault();
            openNewWindowWithURL(`/detail/member/user/${userIdx}?type=TESTERS&profileIdx=${a.profileIdx}`, {
              width: 1000,
              height: 800,
            });
          }}
        >
          {a.profileName}
        </Anchor>
      ),
    },
    {
      accessor: "issuerOrganName",
      header: t("detail.organVoucherInfo.list.issuerOrganName"),
      sortable: false,
      getNode: (a) => <span>{a.issuerOrganName}</span>,
    },
    {
      accessor: "voucherStatusVO",
      header: t("detail.organVoucherInfo.list.voucherStatus"),
      sortable: false,
      getNode: (a) => (
        <span className={getTextClassNameByVoucherStatusVO(a.voucherStatusVO)}>
          {toStringFromVoucherStatusVO(a.voucherStatusVO)}
        </span>
      ),
    },
    {
      accessor: "organSignInCode",
      header: t("detail.organVoucherInfo.list.organSignInCode"),
      sortable: false,
      getNode: (a) => {
        if (a.voucherStatusVO === VoucherStatusVO.USABLE) {
          return <CopiableText text={a.organSignInCode} isBlue={true} />;
        } else {
          return <>-</>;
        }
      },
    },
    {
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (a) => {
        if (a.voucherStatusVO === VoucherStatusVO.USABLE) {
          return (
            <RedActionButton
              onClick={() => {
                const isConfirmed = window.confirm(
                  t("detail.organVoucherInfo.list.revokeVoucherConfirmMessage", { name })
                );
                if (isConfirmed && a.organManagerIdx) {
                  collectOrganVoucher(a.idx, a.organManagerIdx)
                    .then(() => onCollectCompleted())
                    .catch(() => alert(defaultErrorMessage));
                }
              }}
            >
              {t("detail.organVoucherInfo.list.revoke")}
            </RedActionButton>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  return { columns };
}
