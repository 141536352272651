import { GridInputText, InputSelector } from "./Inputs";
import React from "react";
import banks from "../types/banks";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const InputRefundAccountWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const Splitter = styled.div`
  border-right: 1px solid ${(props) => props.theme.color.cDE};
`;

interface Props {
  bankName: string;
  onChangeBankName: (bankName: string) => void;
  accountNumber: string;
  onChangeAccountNumber: (accountNumber: string) => void;
  accountName: string;
  onChangeAccountName: (accountName: string) => void;
  required?: boolean;
}

export function InputRefundAccount({
  bankName,
  onChangeBankName,
  accountNumber,
  onChangeAccountNumber,
  accountName,
  onChangeAccountName,
  required: isRequired = false,
}: Props) {
  const { t } = useTranslation("common");
  const options = banks.map((it) => {
    return {
      label: it,
      value: it,
    };
  });

  return (
    <InputRefundAccountWrapper>
      <InputSelector
        selectedValue={bankName}
        options={[{ label: t("inputRefundAccount.selectBank"), value: "", isLight: true }, ...options]}
        setSelectorOption={(it) => onChangeBankName(it.value)}
        isPageSizeSelector={false}
        required={isRequired}
        width={130}
      />

      <Splitter />

      <GridInputText
        value={accountNumber}
        required={isRequired}
        minLength={7}
        maxLength={20}
        placeholder={t("inputRefundAccount.accountPlaceholder")}
        onChange={(e) => onChangeAccountNumber(e.target.value.replace(/[^0-9]/g, ""))}
      />

      <Splitter />

      <GridInputText
        value={accountName}
        required={isRequired}
        minLength={2}
        maxLength={50}
        placeholder={t("inputRefundAccount.holderNamePlaceholder")}
        onChange={(e) => onChangeAccountName(e.target.value)}
      />
    </InputRefundAccountWrapper>
  );
}
