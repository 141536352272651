import { useState } from "react";
import { DisplayPosition, ProductDiscountType, ProductImage, ProductItemInDetail } from "../../../types/product";
import { AddedFile, FileType } from "../../../types/file";
import { dateToString } from "../../../util/dateUtil";
import {Languages} from "../../../types/common";

export interface ProductEditStateValues {
  name: string;
  setName: (_: string) => void;
  description: string[];
  setDescription: (_: string[]) => void;
  language: Languages;
  setLanguage: (_: Languages) => void;
  specialDescription: string[];
  setSpecialDescription: (_: string[]) => void;
  sellingStartDate: string;
  setSellingStartDate: (_: string) => void;
  sellingEndDate: string | null;
  setSellingEndDate: (_: string | null) => void;
  displayPosition: DisplayPosition;
  setDisplayPosition: (_: DisplayPosition) => void;
  discountType: ProductDiscountType;
  setDiscountType: (_: ProductDiscountType) => void;
  regularPrice: string;
  setRegularPrice: (_: string) => void;
  discountValue: string | null;
  setDiscountValue: (_: string | null) => void;
  sellingPrice: string;
  setSellingPrice: (_: string) => void;
  isCouponUsable: boolean;
  setIsCouponUsable: (_: boolean) => void;
  thumbnail: AddedFile | undefined;
  setThumbnail: (_: AddedFile | undefined) => void;
  productItems: ProductItemInDetail[];
  setProductItems: (_: ProductItemInDetail[]) => void;
  desktopImages: ProductImage[];
  setDesktopImages: (_: ProductImage[]) => void;
  mobileImages: ProductImage[];
  setMobileImages: (_: ProductImage[]) => void;
  addedDesktopImages: FileType[];
  setAddedDesktopImages: (_: FileType[]) => void;
  addedMobileImages: FileType[];
  setAddedMobileImages: (_: FileType[]) => void;
  addedThumbnailImage: FileType | undefined;
  setAddedThumbnailImage: (_: FileType | undefined) => void;
  addedUnitItems: ProductItemInDetail[];
  setAddedUnitItems: (_: ProductItemInDetail[]) => void;
  isSellingAlways: boolean;
  setIsSellingAlways: (_: boolean) => void;
}

export function useProductEditState(): ProductEditStateValues {
  const [name, setName] = useState("");
  const [description, setDescription] = useState<string[]>([]);
  const [language, setLanguage] = useState<Languages>(Languages.KO);
  const [specialDescription, setSpecialDescription] = useState<string[]>([]);
  const [sellingStartDate, setSellingStartDate] = useState(dateToString(new Date()));
  const [sellingEndDate, setSellingEndDate] = useState<string | null>("");
  const [displayPosition, setDisplayPosition] = useState<DisplayPosition>(DisplayPosition.USER_WEB_KPASS);
  const [regularPrice, setRegularPrice] = useState("");
  const [discountType, setDiscountType] = useState<ProductDiscountType>(ProductDiscountType.NONE);
  const [discountValue, setDiscountValue] = useState<string | null>("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [isCouponUsable, setIsCouponUsable] = useState(true);
  const [thumbnail, setThumbnail] = useState<AddedFile>();
  const [productItems, setProductItems] = useState<ProductItemInDetail[]>([]);
  const [desktopImages, setDesktopImages] = useState<ProductImage[]>([]);
  const [mobileImages, setMobileImages] = useState<ProductImage[]>([]);
  const [addedDesktopImages, setAddedDesktopImages] = useState<FileType[]>([]);
  const [addedMobileImages, setAddedMobileImages] = useState<FileType[]>([]);
  const [addedThumbnailImage, setAddedThumbnailImage] = useState<FileType>();
  const [addedUnitItems, setAddedUnitItems] = useState<ProductItemInDetail[]>([]);
  const [isSellingAlways, setIsSellingAlways] = useState(false);

  return {
    name,
    setName,
    description,
    setDescription,
    language,
    setLanguage,
    specialDescription,
    setSpecialDescription,
    sellingStartDate,
    setSellingStartDate,
    sellingEndDate,
    setSellingEndDate,
    displayPosition,
    setDisplayPosition,
    regularPrice,
    setRegularPrice,
    discountType,
    setDiscountType,
    discountValue,
    setDiscountValue,
    sellingPrice,
    setSellingPrice,
    isCouponUsable,
    setIsCouponUsable,
    thumbnail,
    setThumbnail,
    productItems,
    setProductItems,
    desktopImages,
    setDesktopImages,
    mobileImages,
    setMobileImages,
    addedDesktopImages,
    setAddedDesktopImages,
    addedMobileImages,
    setAddedMobileImages,
    addedThumbnailImage,
    setAddedThumbnailImage,
    addedUnitItems,
    setAddedUnitItems,
    isSellingAlways,
    setIsSellingAlways,
  };
}
