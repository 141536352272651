import {BannerLocation} from "../../../types/banner";
import {RadioGroupField} from "../../../components/RadioGroupField";
import {useBannerType} from "../../../types/hooks/useBannerType";

interface Props{
    bannerLocation: BannerLocation;
    setBannerLocation : (_: BannerLocation) => void;
}

export default function BannerLocationField({bannerLocation, setBannerLocation}: Props) {
    const {getBannerLocationLabel} = useBannerType();

    return (
        <>
            <RadioGroupField
                enumValues={Object.values(BannerLocation)}
                getLabel={getBannerLocationLabel}
                value={bannerLocation} setValue={setBannerLocation}
                groupName={"bannerLocation"} isRequired={true}
                             />
        </>
    );
};