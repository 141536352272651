import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Radio, RadioGroup } from "../../../components/RadioGroup";
import { ProductDiscountType } from "../../../types/product";
import { useState } from "react";
import { useProductType } from "../../../types/hooks/useProductType";

export function ArticleRadioGroup({ id, title }: ArticleProps) {
  const [discountType, setDiscountType] = useState<ProductDiscountType>(ProductDiscountType.PERCENT);
  const { toStringFromProductDiscountType } = useProductType();

  const radios: Radio<ProductDiscountType>[] = [
    {
      check: (type) => type === ProductDiscountType.NONE,
      onClickFunc: () => setDiscountType(ProductDiscountType.NONE),
      text: toStringFromProductDiscountType(ProductDiscountType.NONE),
    },
    {
      check: (type) => type === ProductDiscountType.PERCENT,
      onClickFunc: () => setDiscountType(ProductDiscountType.PERCENT),
      text: toStringFromProductDiscountType(ProductDiscountType.PERCENT),
    },
    {
      check: (type) => type === ProductDiscountType.AMOUNT,
      onClickFunc: () => setDiscountType(ProductDiscountType.AMOUNT),
      text: toStringFromProductDiscountType(ProductDiscountType.AMOUNT),
    },
  ];

  const code = `
const [discountType, setDiscountType] = useState<ProductDiscountType>(ProductDiscountType.PERCENT);

const radios: Radio<ProductDiscountType>[] = [
  {
    check: (type) => type === ProductDiscountType.NONE,
    onClickFunc: () => setDiscountType(ProductDiscountType.NONE),
    text: ProductDiscountType.toString(ProductDiscountType.NONE)
  },
  {
    check: (type) => type === ProductDiscountType.PERCENT,
    onClickFunc: () => setDiscountType(ProductDiscountType.PERCENT),
    text: ProductDiscountType.toString(ProductDiscountType.PERCENT)
  },
  {
    check: (type) => type === ProductDiscountType.AMOUNT,
    onClickFunc: () => setDiscountType(ProductDiscountType.AMOUNT),
    text: ProductDiscountType.toString(ProductDiscountType.AMOUNT)
  }
];

<RadioGroup radios={radios}
            groupName={"radio-sample"}
            isRequired={true}
            selectedValue={discountType}/>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code}>
          <RadioGroup radios={radios} groupName={"radio-sample"} isRequired={true} selectedValue={discountType} />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
