import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";
import { useLanguage } from "../hooks/hooks";
import { RequiredTextStyle } from "./Texts";

interface LabelProps {
  width: string;
  height: string;
}

export const Label = styled.label`
  flex-shrink: 0;
  background-color: ${variables.colorF6};
  border: 1px solid ${variables.colorDE};
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.colorFGDarkBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props: LabelProps) => props.width};
  height: ${(props: LabelProps) => props.height};
  text-align: center;

  &.notKO {
    padding: 5px 12px !important;
    line-height: 1.3 !important;
  }

  &.alignSelfStart {
    align-self: flex-start;
  }
`;

export const Label104x46 = ({
  text,
  className,
  isRequired,
}: {
  text: string;
  className?: string;
  isRequired?: boolean;
}) => {
  const { isKorean } = useLanguage();

  return (
    <Label
      className={`${className ?? ""} ${isKorean ? "" : "notKO"}`}
      width={`${isKorean ? 104 : 120}px`}
      height={"46px"}
    >
      {text}
      {isRequired && <RequiredTextStyle>&nbsp;*</RequiredTextStyle>}
    </Label>
  );
};

export const Label132x46 = styled(Label).attrs({
  width: "132px",
  height: "46px",
  className: "input-label",
})``;
