import { useCallback, useMemo } from "react";
import { AdminStatus } from "../../../../types/admin";
import style from "../../user/memberUser.module.scss";

export function useAdminStatus() {
  const statusClassNames = useMemo(
    () => ({
      [AdminStatus.BEFORE_ADMIN_AUTHENTICATION.toString()]: "blue",
      [AdminStatus.BEFORE_SELF_AUTHENTICATION.toString()]: "",
      [AdminStatus.DEFAULT.toString()]: "",
      [AdminStatus.BLOCKED.toString()]: style.statusBlocked,
      [AdminStatus.LEAVE.toString()]: style.statusLeave,
    }),
    []
  );

  const isNoAuthentication = useCallback((s: AdminStatus) => s === AdminStatus.BEFORE_SELF_AUTHENTICATION, []);
  const isSelfAuthentication = useCallback((s: AdminStatus) => s === AdminStatus.BEFORE_ADMIN_AUTHENTICATION, []);
  const isLeaved = useCallback((s: AdminStatus) => s === AdminStatus.LEAVE, []);
  const isBlocked = useCallback((s: AdminStatus) => s === AdminStatus.BLOCKED, []);
  const isChangeableAuthentication = useCallback((s: AdminStatus) => s === AdminStatus.DEFAULT, []);
  const isBeforeSignUp = useCallback(
    (status: AdminStatus) => isSelfAuthentication(status) || isNoAuthentication(status),
    [isSelfAuthentication, isNoAuthentication]
  );

  return {
    statusClassNames,
    isNoAuthentication,
    isSelfAuthentication,
    isLeaved,
    isBlocked,
    isChangeableAuthentication,
    isBeforeSignUp,
  };
}
