import {useTestFilterValues} from "./hooks/useTestFilterValues";
import {TestList} from "./TestList";
import {ListFilterValuesContext} from "../../hooks/list/ListFilterValuesContext";
import {VoucherType} from "../../types/voucher";

export function DcasTestListWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useTestFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <TestList testType={VoucherType.DCAS} />
    </ListFilterValuesContext.Provider>
  );
}
