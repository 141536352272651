import { AdminStatus } from "../../../types/admin";
import { LeaveModal } from "../../component/modal/LeaveModal";
import { BlockReasonModal } from "../../component/modal/BlockReasonModal";
import React from "react";
import { useAdminStatus } from "./hooks/useAdminStatus";
import { useAdminType } from "../../../types/hooks/useAdminType";

interface Props {
  admin: {
    status: AdminStatus;
    leaveAt: string;
    blockAt: string;
    reasonForBlocking: string;
  };
  isShow: boolean;
  onHideStatusPopup: () => void;
  onShowStatusPopup: () => void;
}

export function AdminStatusComponent({ admin, isShow, onHideStatusPopup, onShowStatusPopup }: Props) {
  const { statusClassNames, isLeaved, isBlocked } = useAdminStatus();
  const { toStringFromAdminStatus } = useAdminType();

  return (
    <span
      className={statusClassNames[admin.status.toString()]}
      onClick={(e) => {
        e.stopPropagation();
        onShowStatusPopup();
      }}
    >
      {toStringFromAdminStatus(admin.status)}
      {isLeaved(admin.status) && (
        <LeaveModal isShow={isShow} callbackCloseModal={() => onHideStatusPopup()} leaveAt={admin.leaveAt} />
      )}
      {isBlocked(admin.status) && (
        <BlockReasonModal
          isShow={isShow}
          callbackCloseModal={() => onHideStatusPopup()}
          blockAt={admin.blockAt}
          reasonForBlocking={admin.reasonForBlocking}
        />
      )}
    </span>
  );
}
