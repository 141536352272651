import { PanelWrapper } from "../../../../layout/content/detail/PanelWrapper";
import { DetailSubMenus, DetailTab } from "../../../../layout/content/detail/DetailSubMenus";
import { MemberAdminDetailTabType } from "../../../../types/admin";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import queryString from "query-string";
import { MemberAdminInfo } from "./MemberAdminInfo";
import { useTranslation } from "react-i18next";

export function MemberAdminDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");

  const { id } = useParams<"id">();
  const adminIdx = Number(id);

  const { type } = queryString.parse(window.location.search);
  const [selectedTab, setSelectedTab] = useState<MemberAdminDetailTabType>(MemberAdminDetailTabType.SELF_INFO);
  const tabMenus: DetailTab<MemberAdminDetailTabType>[] = [
    {
      tabValue: MemberAdminDetailTabType.SELF_INFO,
      tabName: t("detail.submenus.adminInfo"),
    },
  ];

  useEffect(() => {
    const currentTab =
      typeof type === "undefined" ? MemberAdminDetailTabType.SELF_INFO : (type as MemberAdminDetailTabType);
    setSelectedTab(currentTab);
  }, [type]);

  const onClickTabMenu = (type: MemberAdminDetailTabType) => {
    navigate(`/detail/member/admin/${id}?${queryString.stringify({ type })}`);
  };

  const getContentComponent = useCallback(
    (tab: MemberAdminDetailTabType) => {
      switch (tab) {
        case MemberAdminDetailTabType.SELF_INFO:
          return <MemberAdminInfo idx={adminIdx} />;
      }
    },
    [adminIdx]
  );

  return (
    <>
      <DetailSubMenus tabs={tabMenus} onClickTab={onClickTabMenu} selectedTab={selectedTab} />
      <PanelWrapper>{getContentComponent(selectedTab)}</PanelWrapper>
    </>
  );
}
