import { Panel } from "../../../../../layout/content/detail/Panel";
import style from "../../memberUserCoupons.module.scss";
import React, { useEffect, useState } from "react";
import { useTesterOrganVoucherListGetter } from "../hooks/useTesterOrganVoucherListGetter";
import { useTesterOrganVoucherColumns } from "../hooks/useTesterOrganVoucherColumns";
import { Table } from "../../../../../layout/content/list/table/Table";
import { PaginationWrapper } from "../../../../../layout/content/list/PaginationWrapper";
import { Pagination } from "../../../../../layout/content/list/Pagination";
import { useTranslation } from "react-i18next";
import { useTesterOrganVoucherStatusGetter } from "../hooks/useTesterOrganVoucherStatusGetter";
import { useUserTesterList } from "../hooks/useUserTesterList";
import { useAppSelector } from "../../../../../hooks/hooks";
import { userProfileUserIdxState } from "../../../../../store/userProfileSlice";

interface Props {
  profileIdx: number;
}

export function TesterOrganVoucherList({ profileIdx }: Props) {
  const { t } = useTranslation("user");
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const { getTesterOrganVoucherStatus } = useTesterOrganVoucherStatusGetter({ profileIdx });
  const userIdx = useAppSelector(userProfileUserIdxState);
  const { getUserProfiles } = useUserTesterList({ userIdx: userIdx ?? 0 });

  const { pageCondition, getOrganVoucherHistoryList, profileOrganVoucherHistoryList } = useTesterOrganVoucherListGetter(
    { profileIdx, isAvailable }
  );
  const { columns } = useTesterOrganVoucherColumns({
    onRefreshCallback: () => {
      if (userIdx) {
        getUserProfiles({})();
      }
      getTesterOrganVoucherStatus(profileIdx);
      getOrganVoucherHistoryList({ page: pageCondition.page, sort: pageCondition.sort })();
    },
  });

  useEffect(() => {
    getOrganVoucherHistoryList({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  return (
    <Panel
      title={t("detail.profile.organVoucher.title")}
      additionalButtons={
        <label className={style.headerCheckboxLabel}>
          <input type={"checkbox"} readOnly={true} checked={isAvailable} onClick={() => setIsAvailable(!isAvailable)} />
          {t("detail.profile.organVoucher.viewOnlyAvailableVoucher")}
        </label>
      }
    >
      <>
        {profileOrganVoucherHistoryList ? (
          <>
            <Table
              listData={profileOrganVoucherHistoryList}
              columns={columns}
              getList={getOrganVoucherHistoryList}
              noDataText={t("detail.profile.organVoucher.noData")}
            />
            {profileOrganVoucherHistoryList.content.length === 0 ? (
              <></>
            ) : (
              <PaginationWrapper>
                <Pagination
                  page={profileOrganVoucherHistoryList}
                  goSpecificPage={(page: number) => getOrganVoucherHistoryList({ page })()}
                />
              </PaginationWrapper>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </Panel>
  );
}
