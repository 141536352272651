import styled from "styled-components/macro";

export const CalculatePanelStyle = styled.div`
  width: 100%;
  padding: 40px;
  background: white;
  border: 1px solid ${(props) => props.theme.color.cDE};

  display: flex;
  flex-direction: column;
  gap: 33px;
`;

export const CalculatePanelTitleStyle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  letter-spacing: -0.6px;
  color: ${(props) => props.theme.color.FGDarkBlue};
`;

export const StatisticsPanelContentWrapperStyle = styled.div`
  width: 100%;
  padding: 0 60px;

  display: flex;
  justify-content: center;
  gap: 50px;
`;

export const StatisticsPanelItemWrapperStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.total {
    flex: 2;
  }
`;

export const StatisticsPanelItemTitleStyle = styled.h4`
  font-size: 20px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: -0.6px;
  color: ${(props) => props.theme.color.FGDarkBlue};
`;

export const StatisticsPanelItemStyle = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

  height: 94px;
  padding: 10px 30px;
  border: 1px solid ${(props) => props.theme.color.cDE};
  border-radius: 10px;

  color: ${(props) => props.theme.color.FGDarkBlue};

  &.completed {
    color: ${(props) => props.theme.color.FGBlue};
  }

  &.uncompleted {
    color: ${(props) => props.theme.color.FGPointRed};
  }

  &.totalAmount {
    background: ${(props) => props.theme.color.FGDarkBlue};
    color: white;
    justify-content: flex-end;
  }
`;

export const FilterButtonStyle = styled.button`
  width: 100px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.cE1};
  padding: 4px 0;

  font-size: 14px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: -0.42px;
  color: ${(props) => props.theme.color.FGDarkBlue};

  &.active {
    background: ${(props) => props.theme.color.FGDarkBlue} !important;
    color: white !important;
    font-weight: bold !important;
  }
`;
