import React, { useCallback, useEffect, useState } from "react";
import { AuthContextType, LoginInfo, LoginResponse } from "../../../types/account";
import jwt_decode from "jwt-decode";
import { MainApi } from "../../../api/MainApi";
import { useAppDispatch, useNewWindow } from "../../../hooks/hooks";
import { getAccessTokenFromRefreshTokenAsync, signInAsnyc } from "../../../store/accountSlice";
import { AuthContext } from "../../../hooks/auth/AuthContext";
import { ErrorType } from "../../../types/error";
import { CookieKeys, useCookie } from "../../../hooks/auth/useCookie";
import { setAccessibleMenuList } from "../../../store/adminRoleSlice";
import { useNavigate } from "react-router-dom";

function getExpiresFromToken(token: string) {
  const { exp } = jwt_decode(token) as { exp: number };
  return new Date(exp * 1000).toUTCString();
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [userEmail, setUserEmail] = useState<string | null>(null);
  let [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { getValueFromCookie, setCookie } = useCookie();
  const { signOutOnChildren } = useNewWindow();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoggedIn(Boolean(getValueFromCookie(CookieKeys.ACCESS_TOKEN)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signIn = async (loginInfo: LoginInfo, successCallback: VoidFunction, errorCallback: (e: ErrorType) => void) => {
    try {
      const result: LoginResponse = await dispatch(signInAsnyc(loginInfo)).unwrap();

      setUserEmail(loginInfo.email);

      const accessToken: string = result.accessToken;
      const refreshToken: string = result.refreshToken;
      const accessTokenExpires = getExpiresFromToken(accessToken);
      const refreshTokenExpires = getExpiresFromToken(refreshToken);

      MainApi.getInstance().setToken(accessToken);
      setCookie(CookieKeys.ACCESS_TOKEN, accessToken, accessTokenExpires);
      setCookie(CookieKeys.LOGIN_EMAIL, loginInfo.email, refreshTokenExpires);
      setCookie(CookieKeys.REFRESH_TOKEN, refreshToken, refreshTokenExpires);
      setIsLoggedIn(true);

      successCallback();
    } catch (e) {
      setUserEmail(null);
      const errorMessage = (e as { message: string }).message;
      const error: ErrorType = JSON.parse(errorMessage);
      errorCallback(error);
    }
  };

  const signOut = useCallback(
    (callback: VoidFunction) => {
      setUserEmail(null);
      setCookie(CookieKeys.ACCESS_TOKEN, "", new Date().toUTCString());
      setCookie(CookieKeys.LOGIN_EMAIL, "", new Date().toUTCString());
      setCookie(CookieKeys.REFRESH_TOKEN, "", new Date().toUTCString());
      setIsLoggedIn(false);
      dispatch(setAccessibleMenuList(undefined));
      callback();
      signOutOnChildren();
    },
    [dispatch, setCookie, signOutOnChildren]
  );

  const setAccessToken = useCallback(
    (accessToken: string) => {
      const accessTokenExpires = getExpiresFromToken(accessToken);
      MainApi.getInstance().setToken(accessToken);
      setIsLoggedIn(true);
      setCookie(CookieKeys.ACCESS_TOKEN, accessToken, accessTokenExpires);
    },
    [setCookie]
  );

  const setRefreshToken = useCallback(
    (token: string) => {
      const expires = getExpiresFromToken(token);
      setCookie(CookieKeys.REFRESH_TOKEN, token, expires);
    },
    [setCookie]
  );

  const getRefreshToken = useCallback(
    (): string | undefined => getValueFromCookie(CookieKeys.REFRESH_TOKEN),
    [getValueFromCookie]
  );

  const getAccessTokenFromRefreshToken = useCallback(
    async (token: string) => {
      try {
        const { accessToken, refreshToken } = await dispatch(getAccessTokenFromRefreshTokenAsync(token)).unwrap();
        if (accessToken) {
          setAccessToken(accessToken);
        }
        if (refreshToken) {
          setRefreshToken(refreshToken);
        }
      } catch (e) {
        throw e;
      }
    },
    [dispatch, setAccessToken, setRefreshToken]
  );

  const getAccessTokenFromRefreshTokenCookie = useCallback(async () => {
    try {
      const savedRefreshToken = getValueFromCookie(CookieKeys.REFRESH_TOKEN);
      if (savedRefreshToken) {
        await getAccessTokenFromRefreshToken(savedRefreshToken);
      } else {
        signOut(() => {
          navigate(`/login`);
        });
      }
    } catch (e) {
      throw e;
    }
  }, [signOut, getAccessTokenFromRefreshToken, getValueFromCookie, navigate]);

  let value: AuthContextType = {
    userEmail,
    signIn,
    signOut,
    setUserEmail,
    setAccessToken,
    setRefreshToken,
    isLoggedIn,
    setIsLoggedIn,
    getRefreshToken,
    getAccessTokenFromRefreshToken,
    getAccessTokenFromRefreshTokenCookie,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
