import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { MemoPostParam, MemoPutParam, MemoSearchCondition } from "../types/memo";
import { MemoApi } from "../api/MemoApi";
import { SliceState, Status } from "../types/common";
import { RootState } from "./index";

export const getMemoListAsync = createAsyncThunk("memo/getMemoList", (condition: MemoSearchCondition) =>
  executePromise(MemoApi.getMemoList(condition))
);

export const createMemoAsync = createAsyncThunk("memo/createMemo", ({ param }: { param: MemoPostParam }) =>
  executePromise(MemoApi.createMemo(param))
);

export const deleteMemoAsync = createAsyncThunk("memo/deleteMemo", ({ memoIdx }: { memoIdx: number }) =>
  executePromise(MemoApi.deleteMemo(memoIdx))
);

export const updateMemoAsync = createAsyncThunk(
  "memo/updateMemo",
  ({ memoIdx, param }: { memoIdx: number; param: MemoPutParam }) => executePromise(MemoApi.updateMemo(memoIdx, param))
);

export interface MemoState extends SliceState {}

const initialState: MemoState = {
  status: Status.IDLE,
};

export const memoSlice = createSlice({
  name: "memo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, getMemoListAsync);
    addCases(builder, createMemoAsync);
    addCases(builder, deleteMemoAsync);
  },
});

export const memoStateStatus = (state: RootState) => state.memo.status;

export default memoSlice.reducer;
