import style from "../../../components/radioGroup.module.scss";
import { CouponAvailableDateType } from "../../../types/coupon";
import React from "react";
import { Radio, RadioGroup } from "../../../components/RadioGroup";
import { useTranslation } from "react-i18next";

interface Props {
  availableDateType: CouponAvailableDateType;
  setAvailableDateType: (_: CouponAvailableDateType) => void;
}

export function AvailableDateTypeRadioField({ availableDateType, setAvailableDateType }: Props) {
  const { t } = useTranslation("coupon");
  const radios: Radio<CouponAvailableDateType>[] = [
    {
      check: (type) => type === CouponAvailableDateType.DURATION,
      onClickFunc: () => setAvailableDateType(CouponAvailableDateType.DURATION),
      text: t("creator.dateType.duration"),
    },
    {
      check: (type) => type === CouponAvailableDateType.GRANTED_DATE,
      onClickFunc: () => setAvailableDateType(CouponAvailableDateType.GRANTED_DATE),
      text: t("creator.dateType.issuedAt"),
    },
  ];

  return (
    <div className={style.radioGroup}>
      <RadioGroup groupName={"availableDateType"} isRequired={true} radios={radios} selectedValue={availableDateType} />
    </div>
  );
}
