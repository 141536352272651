import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "../store";
import { useContext } from "react";
import { AuthContext } from "./auth/AuthContext";
import { CountryContext } from "./country/CountryContext";
import { AccessibleMenuContext } from "./accessiblemenu/AccessibleMenuContext";
import { SelectorClickEventContext } from "./common/SelectorClickEventProvider";
import { NewWindowOpenerContext } from "./newwindow/NewWindowOpenerContext";
import { TimeConverterContext } from "./timeconverter/TimeConverterContext";
import { LanguageContext } from "./language/LanguageContext";
import { MyInfoContext } from "./myinfo/MyInfoContext";
import { NationalityContext } from "./nationality/NationalityContext";
import { ListFilterValuesContext } from "./list/ListFilterValuesContext";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAuth = () => useContext(AuthContext);
export const useCountry = () => useContext(CountryContext);
export const useAccessibleMenus = () => useContext(AccessibleMenuContext);
export const useClickEvent = () => useContext(SelectorClickEventContext);
export const useNewWindow = () => useContext(NewWindowOpenerContext);
export const useTimeConverter = () => useContext(TimeConverterContext);
export const useLanguage = () => useContext(LanguageContext);
export const useMyInfo = () => useContext(MyInfoContext);
export const useNationality = () => useContext(NationalityContext);
export const useListFilterValues = () => useContext(ListFilterValuesContext);
