import styled from "styled-components/macro";
import variables from "../assets/scss/variable.module.scss";

export const SelectorWrapper = styled.div`
  position: relative;
  width: 150px;
  height: 46px;

  &.pagingSize {
    height: 30px;

    &.notKO {
      width: 171px;
    }
  }
`;

export const RoundedSelectorWrapperStyle = styled.div`
  min-width: 126px;
  height: 34px;
  position: relative;
`;

export const FooterBoxSelectorWrapperStyle = styled.div`
  width: 160px;
  height: 37px;
  position: relative;
`;

export const SelectAreaStyle = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${variables.colorDE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px 12px 20px;
  cursor: pointer;

  &.pagingSize {
    padding: 4px 10px;
  }
`;

export const SelectArrowStyle = styled.img`
  width: 22px;
  height: 22px;
`;

export const UnderlineSelectorWrapperStyle = styled.div`
  width: 100%;
  height: 41px;
  position: relative;
`;

export const UnderlineSelectAreaStyle = styled.div`
  background-color: white;
  position: relative;
  z-index: ${(props) => props.theme.zIndex.content};
  width: 100%;
  height: 100%;
  border-bottom: 1px solid ${(props) => props.theme.color.BodyTextBlue};
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.none {
    border-bottom: 1px solid ${(props) => props.theme.color.cC6};
  }
`;

export const SelectedItem = styled.div`
  background-color: transparent;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.color75};
  margin: 0;
  width: 100%;

  &.none {
    color: ${variables.colorC6};
  }
`;

export const RoundedSelectAreaStyle = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.cDE};
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const FooterSelectAreaStyle = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${(props) => props.theme.color.FGFooterBlue};
  padding: 4px 4px 4px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const RoundedSelectedItemStyle = styled.div`
  color: ${(props) => props.theme.color.c75};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: -0.48px;

  &.white {
    color: white;
  }
`;

export const FooterSelectedItemStyle = styled.div`
  color: ${(props) => props.theme.color.FGFooterBlue};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.8;
`;

export const RoundedSelectorArrowStyle = styled.img`
  width: 26px;
  height: 26px;
`;

export const FooterSelectorArrowStyle = styled.img`
  width: 29px;
  height: 29px;
`;

export const UnderlineSelectedItemStyle = styled.span`
  width: 100%;
  color: ${(props) => props.theme.color.BodyTextBlue};
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: -0.48px;

  &.none {
    color: ${(props) => props.theme.color.cC6};
  }
`;

export const UnderlineSelectorArrowStyle = styled.span`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Options = styled.ul`
  position: absolute;
  top: 45px;
  left: 0;
  z-index: ${variables.zIndexSelectOptions};
  max-height: 220px;
  overflow-y: scroll;
  width: inherit;
  border: 1px solid ${(props) => props.theme.color.cDE};

  &.hide {
    display: none;
  }

  &.pagingSize {
    top: 30px;
  }
`;

export const RoundedSelectorOptionsStyle = styled.ul`
  position: absolute;
  max-height: 220px;
  overflow-y: scroll;
  width: 100%;
  top: 32px;
  left: 0;
  border: 1px solid ${(props) => props.theme.color.cDE};

  &.hide {
    display: none;
  }
`;

export const FooterSelectorOptionsStyle = styled.ul`
  position: absolute;
  max-height: 220px;
  overflow-y: scroll;
  width: 100%;
  top: 37px;
  left: 0;
  border: 1px solid ${(props) => props.theme.color.cDE};

  &.hide {
    display: none;
  }
`;

export const UnderlineSelectorOptionsStyle = styled.ul`
  border: 1px solid ${(props) => props.theme.color.cDE};
  position: absolute;
  max-height: 220px;
  overflow-y: scroll;
  width: 100%;
  top: 41px;
  left: 0;

  &.hide {
    display: none;
  }
`;

export const OptionItem = styled.li`
  width: 100%;
  padding: 4px 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.color75};
  cursor: pointer;
  background-color: white;

  &.none {
    color: ${variables.colorC6};
  }

  &.rounded:first-child {
    //border-top: 1px solid ${variables.colorDE};
  }

  &:hover {
    background-color: ${variables.colorF6};
  }
`;

export const SelectorHiddenInputStyle = styled.input.attrs({ type: "text" })`
  z-index: ${variables.zIndexHidden};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const OrganMemberSelectAreaStyle = styled.div`
  background-color: white;
  position: relative;
  z-index: ${(props) => props.theme.zIndex.content};
  width: 100%;
  height: 100%;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

`;

export const OrganMemberSelectedItemStyle = styled.span`
    color: var(--Color-GrayScale-G2-757575, #757575);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;

  &.none {
    color: ${(props) => props.theme.color.cC6};
  }
`;

export const OrganMemberSelectorArrowStyle = styled.span`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OrganMemberCountryOptions = styled.ul`
  position: absolute;
  top: 392px;
  z-index: ${variables.zIndexSelectOptions};
  max-height: 300px;
  overflow-y: scroll;
  width: inherit;
  border: 1px solid ${(props) => props.theme.color.cDE};

  &.hide {
    display: none;
  }

  &.pagingSize {
    top: 30px;
  }
`;

export const OrganMemberPhoneOptions = styled.ul`
  position: absolute;
  top: 523px;
  z-index: ${variables.zIndexSelectOptions};
  max-height: 300px;
  overflow-y: scroll;
  width: inherit;
  border: 1px solid ${(props) => props.theme.color.cDE};

  &.hide {
    display: none;
  }

  &.pagingSize {
    top: 30px;
  }
`;
