import { MiniModal } from "../../../layout/modal/MiniModal";
import style from "./productSummaryModal.module.scss";
import React, { useCallback, useEffect, useState } from "react";
import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { getProductDetailAsync, productStatus } from "../../../store/productSlice";
import { ProductDetail } from "../../../types/product";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  isShow: boolean;
  callbackCloseModal: (_: React.MouseEvent) => void;
  productIdx: number;
  linkElement?: HTMLElement;
}

const TdCellCenteredStyle = styled.td`
  padding: 26px 0 !important;
  justify-content: center;
`;

export function ProductSummaryModal({ isShow, callbackCloseModal, productIdx, linkElement }: Props) {
  const { t } = useTranslation("product");

  const dispatch = useAppDispatch();
  const status = useAppSelector(productStatus);
  const { defaultErrorMessage } = useUtil();

  const [productDetail, setProductDetail] = useState<ProductDetail>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getProductDetail = useCallback(
    (idx: number) => {
      callAsync(
        dispatch(getProductDetailAsync(idx)).unwrap(),
        (result) => setProductDetail(result),
        (e) => {
          if (e.errorCode.httpCode === 404) {
            setErrorMessage(t("couponProductModal.deleteResult"));
          } else {
            alert(defaultErrorMessage);
          }
        }
      ).then();
    },
    [defaultErrorMessage, dispatch, t]
  );

  useEffect(() => {
    if (isShow) {
      getProductDetail(productIdx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIdx, isShow]);

  return (
    <MiniModal
      show={isShow}
      closeModal={(e) => {
        callbackCloseModal(e);
      }}
      title={t("couponProductModal.title")}
      modalWidth={"424px"}
      linkElement={linkElement}
      statusValues={[status]}
      modalLeftPosition={"-350px"}
    >
      <>
        <table className={style.table}>
          <thead>
            <tr>
              <th>{t("couponProductModal.productName")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {errorMessage ? (
                <TdCellCenteredStyle>{errorMessage}</TdCellCenteredStyle>
              ) : (
                <td>
                  <img src={productDetail?.thumbnail.uri} alt={t("couponProductModal.productImage")} />
                  <span>{productDetail?.name}</span>
                </td>
              )}
            </tr>
          </tbody>
        </table>
      </>
    </MiniModal>
  );
}
