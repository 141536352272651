import { Country } from "../../types/country";
import { SelectorOption } from "../../types/selector";
import React from "react";

export interface CountryContextType {
  countries: Country[];
  phoneNumberOptions: SelectorOption[];
  countryOptions: SelectorOption[];
  countryOptionsWithAll: SelectorOption[];
  countryOptionsWithNone: SelectorOption[];
  onlyNationalityOptions: SelectorOption[];
  nationalityOptions: SelectorOption[];
  nationalityOptionsWithAll: SelectorOption[];
  getCountryByCodeISOAlpha2: (_: string) => Country | undefined;
  getCountryNameByCodeISOAlpha2: (_: string) => string | undefined;
}

export const CountryContext = React.createContext<CountryContextType>(null!);
