import styled from "styled-components/macro";

export const ListAreaStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ListPageInfoLineStyle = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  font-weight: normal;
  line-height: 1.6;
  letter-spacing: -0.48px;
  color: ${(props) => props.theme.color.c75};
`;

export const ListButtonLineStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListButtonsWrapperStyle = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ListPaginationWrapperStyle = styled.div`
  margin-top: 30px;
`;
