import { DescriptionList } from "../../../../../layout/content/detail/DescriptionList";
import personIcon from "../../../../../assets/images/button_icons/person_icon.svg";
import { DetailActionButton } from "../../../../../components/Buttons";
import React, { useCallback, useEffect } from "react";
import { TableStyle } from "../../../../../components/styled/TableStyle";
import { OrdersVO, RefundAccountVO } from "../../../../../types/orders";
import { useRefundsGetter } from "./hooks/useRefundsGetter";
import { RefundPriceColumn } from "./RefundPriceColumn";
import { useLanguage, useNewWindow, useTimeConverter } from "../../../../../hooks/hooks";
import { Panel } from "../../../../../layout/content/detail/Panel";
import style from "../../orderDetail.module.scss";
import { useRefundAccountGetter } from "../../../hooks/useRefundAccountGetter";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../util/hooks/useUtil";

interface Props {
  orderVO: OrdersVO;
}

export function RefundInfo({ orderVO }: Props) {
  const { t } = useTranslation("order");
  const { getRefunds, refunds } = useRefundsGetter();
  const { openRefundAccount } = useNewWindow();
  const { defaultErrorMessage } = useUtil();
  const { timeConverter } = useTimeConverter();
  const { loadRefundAccount, customerVO } = useRefundAccountGetter({
    buyerIdx: orderVO.buyerIdx,
    customerType: orderVO.customerType,
  });
  const { isKorean } = useLanguage();

  const getRefundAccountString = useCallback(
    (refundAccountVO: RefundAccountVO) => {
      if (refundAccountVO.refundAccountName === "") return "-";
      else
        return (
          `${t("refund.account.bank")}: ${refundAccountVO.refundBankName} ` +
          `/ ${t("refund.account.accountNumber")}: ${refundAccountVO.refundAccountNumber} ` +
          `/ ${t("refund.account.holderName")}: ${refundAccountVO.refundAccountName}`
        );
    },
    [t]
  );

  useEffect(() => loadRefundAccount(), [loadRefundAccount]);
  useEffect(() => {
    getRefunds(orderVO.idx)
      .then()
      .catch(() => alert(defaultErrorMessage));
  }, [getRefunds, orderVO, defaultErrorMessage]);

  const additionalButtons = customerVO && (
    <>
      <DetailActionButton
        onClick={() =>
          openRefundAccount(
            {
              customerIdx: customerVO.idx,
              customerType: customerVO.customerType,
              bankName: customerVO.refundBankName,
              accountNumber: customerVO.refundAccountNumber,
              accountName: customerVO.refundAccountName,
            },
            () => loadRefundAccount()
          )
        }
      >
        <img src={personIcon} alt={t("refund.account.updateIcon")} />
        {customerVO.refundBankName ? t("refund.account.updateButton") : t("refund.account.createButton")}
      </DetailActionButton>
    </>
  );

  function getDescription(refundAccountVO: RefundAccountVO) {
    return [[{ key: t("refund.account.currentAccount"), value: getRefundAccountString(refundAccountVO) }]];
  }

  return (
    <Panel
      id="REFUND"
      title={t("refund.history")}
      additionalButtons={additionalButtons}
      panelClassName={style.scrollMarginTop}
    >
      {customerVO ? (
        <>
          <DescriptionList data={getDescription(customerVO)} labelWidth={170} />

          <TableStyle className={isKorean ? "" : "notKO"}>
            <thead>
              <tr>
                <th>{t("refund.columns.refundedAt")}</th>
                <th>{t("refund.columns.amount")}</th>
                <th>{t("refund.columns.way")}</th>
                <th>{t("refund.columns.reason")}</th>
              </tr>
            </thead>

            <tbody>
              {refunds.length === 0 ? (
                <tr>
                  <td colSpan={4}>{t("refund.noHistory")}</td>
                </tr>
              ) : (
                <>
                  {refunds.map((it) => (
                    <tr key={`refund-${it.idx}`}>
                      <td>{timeConverter.convertToLocalTime(it.createAt)}</td>
                      <td className={"right"}>
                        <RefundPriceColumn orderRefundVO={it} paymentWay={orderVO.paymentWay} />
                      </td>
                      <td>{it.refundMethod}</td>
                      <td className={"left"}>{it.refundReason}</td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </TableStyle>
        </>
      ) : (
        <></>
      )}
    </Panel>
  );
}
