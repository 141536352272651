import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { OrderSearchWordType, OrderStatus } from "../orders";
import { SelectorOption } from "../selector";

export function useOrdersType() {
  const { t } = useTranslation(["order", "common"]);

  const toStringFromOrderStatus = useCallback(
    (o: OrderStatus) => {
      switch (o) {
        case OrderStatus.AWAITING_PAYMENT:
          return t("orderStatus.awaitingPayment");
        case OrderStatus.AWAITING_DEPOSIT:
          return t("orderStatus.ordered");
        case OrderStatus.PAID:
          return t("orderStatus.paid");
        case OrderStatus.FAIL:
          return t("orderStatus.fail");
        case OrderStatus.CANCELLED:
          return t("orderStatus.allRefunded");
        case OrderStatus.PARTIALLY_CANCELLED:
          return t("orderStatus.partiallyRefunded");
        case OrderStatus.OUTSTANDING_PAYMENT:
          return t("orderStatus.outstandingPayment");
      }
    },
    [t]
  );

  const orderStatusOptions = useMemo(
    (defaultLabel: string = t("all", { ns: "common" })) => [
      { value: "", label: defaultLabel },
      { value: OrderStatus.PAID, label: toStringFromOrderStatus(OrderStatus.PAID) },
      { value: OrderStatus.AWAITING_DEPOSIT, label: toStringFromOrderStatus(OrderStatus.AWAITING_DEPOSIT) },
      { value: OrderStatus.OUTSTANDING_PAYMENT, label: toStringFromOrderStatus(OrderStatus.OUTSTANDING_PAYMENT) },
      { value: OrderStatus.PARTIALLY_CANCELLED, label: toStringFromOrderStatus(OrderStatus.PARTIALLY_CANCELLED) },
      { value: OrderStatus.FAIL, label: toStringFromOrderStatus(OrderStatus.FAIL) },
      { value: OrderStatus.CANCELLED, label: toStringFromOrderStatus(OrderStatus.CANCELLED) },
    ],
    [toStringFromOrderStatus, t]
  );

  const orderSearchWordTypeOptions: SelectorOption[] = useMemo(
    () => [
      { value: OrderSearchWordType.ORDERER_NAME, label: t("filter.search.type.ordererName") },
      { value: OrderSearchWordType.AFFILIATE_CODE, label: "제휴코드" },
    ],
    [t]
  );

  return {
    toStringFromOrderStatus,
    orderStatusOptions,
    orderSearchWordTypeOptions,
  };
}
