import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PanelWrapper } from "../../layout/content/detail/PanelWrapper";
import { Panel } from "../../layout/content/detail/Panel";
import style from "../style/grantModal.module.scss";
import creatorStyle from "../../layout/content/post/creator.module.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { Label104x46 } from "../../components/Label";
import { FilterContentType } from "../../types/tableFilter";
import { FilterCalendarRange } from "../../layout/content/list/filter/FilterCalendarRange";
import { useNewWindow, useTimeConverter } from "../../hooks/hooks";
import styled from "styled-components/macro";
import { PrimaryButton } from "../../components/Buttons";
import { useAffiliateCodeAvailableDurationUpdater } from "./hooks/useAffiliateCodeAvailableDurationUpdater";
import { useUtil } from "../../util/hooks/useUtil";
import { WindowMessage } from "../../types/newWindow";

const ModifyButtonStyle = styled(PrimaryButton)`
  width: 300px;
  padding: 0 !important;
`;

export function AffiliateCodeDuration() {
  const [searchParams] = useSearchParams();
  const { timeConverter } = useTimeConverter();
  const { updateAffiliateCodeDuration } = useAffiliateCodeAvailableDurationUpdater();
  const { defaultErrorMessage } = useUtil();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  const formRef = useRef<HTMLFormElement>(null);

  const { id } = useParams<"id">();
  const name = useMemo(() => searchParams.get("name"), [searchParams]);
  const code = useMemo(() => searchParams.get("code"), [searchParams]);
  const startAt = useMemo(() => searchParams.get("startAt"), [searchParams]);
  const endAt = useMemo(() => searchParams.get("endAt"), [searchParams]);

  const [availableStartAt, setAvailableStartAt] = useState("");
  const [availableEndAt, setAvailableEndAt] = useState("");
  const [isAvailableAlways, setIsAvailableAlways] = useState(false);

  useEffect(() => {
    setAvailableStartAt(timeConverter.convertToLocalDate(startAt));
  }, [timeConverter, startAt]);
  useEffect(() => {
    if (endAt) {
      setAvailableEndAt(timeConverter.convertToLocalDate(endAt));
    } else {
      setIsAvailableAlways(true);
    }
  }, [timeConverter, endAt]);

  const onClickModifyDuration = useCallback(() => {
    if (!id) return;

    if (!formRef.current?.reportValidity()) return;

    if (!availableEndAt && !isAvailableAlways) {
      alert("유효기간 종료일을 선택하세요.");
      return;
    }

    if (!window.confirm("제휴코드 사용기한을 수정 하시겠습니까?")) {
      return;
    }

    updateAffiliateCodeDuration(Number(id), availableStartAt, availableEndAt ? availableEndAt : null)
      .then(() => {
        alert("제휴코드 사용기한이 수정 되었습니다.");
        closeWindowAndNotifyToParentWindow(WindowMessage.AFFILIATE_CODE_DURATION);
      })
      .catch(() => {
        alert(defaultErrorMessage);
      });
  }, [
    updateAffiliateCodeDuration,
    defaultErrorMessage,
    id,
    availableStartAt,
    availableEndAt,
    isAvailableAlways,
    closeWindowAndNotifyToParentWindow,
  ]);

  return (
    <PanelWrapper>
      <Panel title={"제휴코드 사용기한 수정"} panelClassName={style.panel}>
        <>
          <p className={style.desc} style={{ gap: "4px" }}>
            <span className={style.name}>
              {code}({name})
            </span>
            <span className={style.otherDesc}>제휴코드의 사용기한을 수정합니다.</span>
          </p>
          <form className={creatorStyle.fieldLine} ref={formRef}>
            <Label104x46 text={"사용기한"} />
            <FilterCalendarRange
              from={{
                type: FilterContentType.CALENDAR,
                value: availableStartAt,
                onChangeFunc: setAvailableStartAt,
                isRequired: true,
              }}
              to={{
                type: FilterContentType.CALENDAR,
                value: availableEndAt,
                onChangeFunc: setAvailableEndAt,
                isDisabled: isAvailableAlways,
              }}
            />
            <label className={creatorStyle.checkboxLabel}>
              <input
                type={"checkbox"}
                checked={isAvailableAlways}
                readOnly={true}
                onClick={() => {
                  const to = !isAvailableAlways;
                  if (to) {
                    setAvailableEndAt("");
                  }
                  setIsAvailableAlways(to);
                }}
              />
              상시사용
            </label>
          </form>
          <div className={style.buttonArea}>
            <ModifyButtonStyle onClick={onClickModifyDuration}>사용기한 수정</ModifyButtonStyle>
          </div>
        </>
      </Panel>
    </PanelWrapper>
  );
}
