import { Post } from "../../layout/content/post/Post";
import style from "../../layout/content/post/creator.module.scss";
import { Label104x46 } from "../../components/Label";
import React, { useCallback, useEffect, useState } from "react";
import {
  AffiliateCodePostParam,
  CodeChargeType,
  CodeDiscountType,
  TaxDeduction,
  VATIncluded,
} from "../../types/affiliateCode";
import { PercentField } from "../../components/discount/PercentField";
import { PointField } from "../../components/discount/PointField";
import { RadioGroupField } from "../../components/RadioGroupField";
import { useAffiliateCodeType } from "../../types/hooks/useAffiliateCodeType";
import { FilterCalendarRange } from "../../layout/content/list/filter/FilterCalendarRange";
import { FilterContentType } from "../../types/tableFilter";
import { HrLine } from "../../layout/content/post/HrLine";
import { CodeOrganManagers } from "./creator/CodeOrganManagers";
import styled from "styled-components/macro";
import { useAffiliateCodeCreator } from "./creator/useAffiliateCodeCreator";
import { useAppDispatch, useAppSelector, useTimeConverter } from "../../hooks/hooks";
import { useLoader } from "../../hooks/common/useLoader";
import { affiliateCodeStatusState, isCodeDataSavedState, setIsCodeDataSaved } from "../../store/affiliateCodeSlice";
import { dateToString } from "../../util/dateUtil";
import { useNavigate } from "react-router-dom";
import { useNavigationBlocker } from "../../hooks/navigationblocker/useNavigationBlocker";

const FixedWidthAreaStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 168px;
`;
export function CodeCreator() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getCodeDiscountTypeLabel, getCodeChargeTypeLabel, getVATIncludedLabel, getTaxDeductionLabel } =
    useAffiliateCodeType();
  const { createCode } = useAffiliateCodeCreator();
  const { timeConverter } = useTimeConverter();
  const isCodeDataSaved = useAppSelector(isCodeDataSavedState);
  const status = useAppSelector(affiliateCodeStatusState);
  useLoader({ status });

  const [codeDiscountType, setCodeDiscountType] = useState<CodeDiscountType>(CodeDiscountType.PERCENT);
  const [codeChargeType, setCodeChargeType] = useState<CodeChargeType>(CodeChargeType.PERCENT);
  const [isVATIncluded, setIsVATIncluded] = useState<VATIncluded>(VATIncluded.Y);
  const [taxDeduction, setTaxDeduction] = useState<TaxDeduction>(TaxDeduction.N);
  const [discountValue, setDiscountValue] = useState("");
  const [chargeValue, setChargeValue] = useState("");
  const [availableStartAt, setAvailableStartAt] = useState(dateToString(new Date()));
  const [availableEndAt, setAvailableEndAt] = useState("");
  const [isAvailableAlways, setIsAvailableAlways] = useState(false);
  const [selectedOrganManagerIdx, setSelectedOrganManagerIdx] = useState<number | null>(null);

  const [hasChangedValue, setHasChangedValue] = useState(false);

  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      if (v) {
        dispatch(setIsCodeDataSaved(false));
        setHasChangedValue(true);
      }
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  useNavigationBlocker({
    hasChangedValue,
    isDataSaved: isCodeDataSaved,
    confirmMessage: "입력한 제휴코드 정보가 모두 사라집니다.\n정말 이 페이지를 벗어나시겠습니까?",
  });

  useEffect(() => {
    if (isVATIncluded === VATIncluded.Y) {
      setTaxDeduction(TaxDeduction.N);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVATIncluded]);

  useEffect(() => {
    if (taxDeduction === TaxDeduction.Y) {
      setIsVATIncluded(VATIncluded.N);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxDeduction]);

  const onClickCreateButton = useCallback(() => {
    if (codeDiscountType === CodeDiscountType.PERCENT && 100 < Number(discountValue)) {
      alert("할인율은 0~100 사이의 수를 입력해주세요.");
      return;
    }

    if (codeChargeType === CodeChargeType.PERCENT && 100 < Number(chargeValue)) {
      alert("백분율은 0~100 사이의 수를 입력해주세요.");
      return;
    }

    if (!availableEndAt && !isAvailableAlways) {
      alert("사용기한 항목을 등록해주세요.");
      return;
    }

    if (selectedOrganManagerIdx === null) {
      alert("보유자를 선택해주세요.");
      return;
    }

    const param: AffiliateCodePostParam = {
      discountType: codeDiscountType,
      discountValue: Number(discountValue),
      commissionType: codeChargeType,
      commissionValue: Number(chargeValue),
      isVatInclude: isVATIncluded === VATIncluded.Y,
      availableStartAt: timeConverter.convertStartOfDayToUTC(availableStartAt),
      availableEndAt: availableEndAt ? timeConverter.convertEndOfDayToUTC(availableEndAt) : undefined,
      organManagerIdx: selectedOrganManagerIdx!,
      isTaxDeduction: taxDeduction === TaxDeduction.Y,
    };

    createCode(param).then(() => {
      dispatch(setIsCodeDataSaved(true));
      navigate(`/affiliate-code/code-list`);
    });
  }, [
    isAvailableAlways,
    selectedOrganManagerIdx,
    codeDiscountType,
    discountValue,
    codeChargeType,
    chargeValue,
    isVATIncluded,
    availableStartAt,
    availableEndAt,
    taxDeduction,
    createCode,
    timeConverter,
    navigate,
    dispatch,
  ]);

  return (
    <Post buttonTitle={"제휴코드 등록"} buttonOnClickAction={onClickCreateButton} title={"제휴코드 등록"}>
      <>
        <div className={style.fieldLine}>
          <Label104x46 text={"할인"} />
          <RadioGroupField
            value={codeDiscountType}
            setValue={(v) => changeValue(v, setCodeDiscountType)}
            groupName={"codeDiscountType"}
            isRequired={true}
            enumValues={Object.values(CodeDiscountType)}
            getLabel={getCodeDiscountTypeLabel}
          />
          <div className={style.addedField}>
            {codeDiscountType === CodeDiscountType.PERCENT && (
              <PercentField percent={discountValue} setPercent={(v) => changeValue(v, setDiscountValue)} />
            )}
            {codeDiscountType === CodeDiscountType.AMOUNT && (
              <PointField point={discountValue} setPoint={(v) => changeValue(v, setDiscountValue)} />
            )}
          </div>
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"수수료"} />
          <RadioGroupField
            value={codeChargeType}
            setValue={(v) => changeValue(v, setCodeChargeType)}
            groupName={"codeChargeType"}
            isRequired={true}
            enumValues={Object.values(CodeChargeType)}
            getLabel={getCodeChargeTypeLabel}
          />
          <div className={style.addedField}>
            {codeChargeType === CodeChargeType.PERCENT && (
              <PercentField percent={chargeValue} setPercent={(v) => changeValue(v, setChargeValue)} label={"%"} />
            )}
            {codeChargeType === CodeChargeType.AMOUNT && (
              <PointField point={chargeValue} setPoint={(v) => changeValue(v, setChargeValue)} label={"원"} />
            )}
          </div>
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"부가세"} />
          <FixedWidthAreaStyle>
            <RadioGroupField
              value={isVATIncluded}
              setValue={(v) => changeValue(v, setIsVATIncluded)}
              groupName={"isVATIncluded"}
              isRequired={true}
              enumValues={Object.values(VATIncluded)}
              getLabel={getVATIncludedLabel}
            />
          </FixedWidthAreaStyle>
          <Label104x46 text={"세금공제"} />
          <RadioGroupField
            value={taxDeduction}
            setValue={(v) => changeValue(v, setTaxDeduction)}
            groupName={"taxDeduction"}
            isRequired={true}
            enumValues={Object.values(TaxDeduction)}
            getLabel={getTaxDeductionLabel}
          />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"사용기한"} />
          <FilterCalendarRange
            from={{
              type: FilterContentType.CALENDAR,
              value: availableStartAt,
              onChangeFunc: (s) => changeValue(s, setAvailableStartAt),
              isRequired: true,
            }}
            to={{
              type: FilterContentType.CALENDAR,
              value: availableEndAt,
              onChangeFunc: (s) => changeValue(s, setAvailableEndAt),
              isDisabled: isAvailableAlways,
            }}
          />
          <label className={style.checkboxLabel}>
            <input
              type={"checkbox"}
              checked={isAvailableAlways}
              readOnly={true}
              onClick={() => setIsAvailableAlways(!isAvailableAlways)}
            />
            상시사용
          </label>
        </div>
        <HrLine />
        <div className={`${style.fieldLine} ${style.tableLine}`}>
          <Label104x46 text={"보유자"} />
          <CodeOrganManagers
            selectedOrganManagerIdx={selectedOrganManagerIdx}
            setSelectedOrganManagerIdx={(v) => changeValue(v, setSelectedOrganManagerIdx)}
          />
        </div>
        <HrLine />
      </>
    </Post>
  );
}
