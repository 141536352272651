import { useAppDispatch } from "../../hooks/hooks";
import { useCallback } from "react";
import { deleteOrganAsync } from "../../store/organSlice";

export function useOrganDeleter() {
  const dispatch = useAppDispatch();

  const deleteOrgan = useCallback((organIdx: number) => dispatch(deleteOrganAsync(organIdx)).unwrap(), [dispatch]);

  return {
    deleteOrgan,
  };
}
