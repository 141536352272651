import { useCallback } from "react";
import { useAppDispatch } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { getPopupDetailAsync } from "../../../../store/popupSlice";
import { PopupDetailVO, PopupDTO } from "../../../../types/popup";

interface Args {
  popupIdx: number;
}

export function usePopupDetailGetter({ popupIdx }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const getPopupDetail = useCallback(async (): Promise<PopupDetailVO> => {
    try {
      const dto: PopupDTO = await dispatch(getPopupDetailAsync(popupIdx)).unwrap();
      return { ...dto } as PopupDetailVO;
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
      throw new Error("팝업 상세 조회 에러");
    }
  }, [dispatch, defaultErrorMessage, popupIdx]);

  return {
    getPopupDetail,
  };
}
