import {FilterContentType, TableFilter} from "../../../../types/tableFilter";
import {useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {BannerFilterValues} from "./useBannerFilterValues";
import {BannerLocation, BannerStatus, BannerType} from "../../../../types/banner";
import {WebsiteType} from "../../../../types/common";

interface Args {
    search: () => void;
}

export function useBannerFilters({search}: Args) {
    const {filterValues} = useListFilterValues();
    const {timeConverter} = useTimeConverter();

    const {
        searchWordFilterValue,
        availableStartAtFilterValue,
        availableEndAtFilterValue,
        typeFilterValue,
        positionFilterValue,
        websiteTypeFilterValue,
        statusFilterValue,
    } = filterValues as BannerFilterValues;


    const filters: TableFilter[][] = [
        [
            {
                labelTitle: "배너명 검색",
                content: {
                    type: FilterContentType.TEXT,
                    value: searchWordFilterValue?.value,
                    maxLength: 100,
                    placeholderVal: "검색할 배너명 입력",
                    onChangeFunc: (v) => searchWordFilterValue.setter(v),
                    onEnterKeyUpFunc: search,
                },
            },
        ],
        [
            {
                labelTitle: "유효기간",
                content: {
                    type: FilterContentType.CALENDAR_RANGE,
                    from: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(availableStartAtFilterValue?.value),
                        onChangeFunc: (v) =>
                            availableStartAtFilterValue?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
                        placeholderVal: "날짜 입력",
                    },
                    to: {
                        type: FilterContentType.CALENDAR,
                        value: timeConverter.convertToFilterDateString(availableEndAtFilterValue?.value),
                        onChangeFunc: (v) =>
                            availableEndAtFilterValue?.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
                        placeholderVal: "날짜 입력",
                    },
                },
            },
        ],
        [
            {
                labelTitle: "홈페이지",
                content: {
                    type: FilterContentType.SELECT,
                    value: websiteTypeFilterValue.value,
                    options: [
                        {label: "전체", value: ""},
                        {label: "K-PASS", value: WebsiteType.KPASS},
                        {label: "D-KASS", value: WebsiteType.DCAS}
                    ],
                    onChangeFunc: (v) => websiteTypeFilterValue.setter(v as unknown as WebsiteType),
                },
            },
            {
                labelTitle: "배너 언어",
                content: {
                    type: FilterContentType.SELECT,
                    value: typeFilterValue.value,
                    options: [
                        {label: "전체", value: ""},
                        {label: "한국어", value: BannerType.KOREAN},
                        {label: "영어", value: BannerType.ENGLISH},
                        {label: "중문(번체)", value: BannerType.TAIWAN},
                        {label: "러시아어", value: BannerType.RUSSIA}
                    ],
                    onChangeFunc: (v) => typeFilterValue.setter(v as unknown as BannerType),
                },
            },
            {
                labelTitle: "배너 위치",
                content: {
                    type: FilterContentType.SELECT,
                    value: positionFilterValue.value,
                    options: [
                        {label: "전체", value: ""},
                        {label: "메인", value: BannerLocation.MAIN},
                    ],
                    onChangeFunc: (v) => positionFilterValue.setter(v as unknown as BannerLocation),
                },
            },
            {
                labelTitle: "상태",
                content: {
                    type: FilterContentType.SELECT,
                    value: statusFilterValue.value,
                    options: [
                        {label: "전체", value: ""},
                        {label: "노출", value: BannerStatus.EXPOSED},
                        {label: "미노출", value: BannerStatus.UNEXPOSED},
                    ],
                    onChangeFunc: (v) => statusFilterValue.setter(v as unknown as BannerStatus),
                },
            },
        ],
    ];

    return {
        filters,
    };
}