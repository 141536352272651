import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { OrderDetailInfo } from "./component/OrderDetailInfo";
import { DetailSubMenus, DetailTab } from "../../../layout/content/detail/DetailSubMenus";
import { OrderDetailTabMenu, OrderStatus } from "../../../types/orders";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { OrderBuyerInfo } from "./component/orderbuyer/OrderBuyerInfo";
import { OrderItemInfo } from "./component/orderitem/OrderItemInfo";
import { PaymentInfo } from "./component/PaymentInfo";
import { PaymentWayInfo } from "./component/PaymentWayInfo";
import { RefundInfo } from "./component/refund/RefundInfo";
import { AdminMenuCode } from "../../../types/adminRole";
import { MemoComponent } from "../../component/memo/MemoComponent";
import { MemoTargetType } from "../../../types/memo";
import { RoleChecker } from "../../role/RoleChecker";
import { useParams } from "react-router-dom";
import { CashReceiptInfo } from "./component/CashReceiptInfo";
import useOrderGetter from "../hooks/useOrderGetter";
import { useAppSelector } from "../../../hooks/hooks";
import { useLoader } from "../../../hooks/common/useLoader";
import { orderStatus } from "../../../store/orderSlice";
import usePaymentGetter from "../hooks/usePaymentGetter";
import { PaymentVO, PaymentWay } from "../../../types/payment";
import { memberUserStatus } from "../../../store/userSlice";
import { organManagerStatus } from "../../../store/organManagerSlice";
import variables from "../../../assets/scss/variable.module.scss";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

const OrderDetailStyle = styled.div`
  &.fixed-tabs {
    padding-top: 58px;

    .tabs {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: ${variables.zIndexFixedMenu};
    }
  }
`;

export function OrderDetail() {
  const { t } = useTranslation("order");
  const { id } = useParams<"id">();
  const orderIdx = Number(id);
  const { defaultErrorMessage } = useUtil();
  const { orders, getOrders } = useOrderGetter();
  const getPayment = usePaymentGetter();
  const [paymentVO, setPaymentVO] = useState<PaymentVO>();
  const orderIsLoading = useAppSelector(orderStatus);
  const memberUserIsLoading = useAppSelector(memberUserStatus);
  const organManagerIsLoading = useAppSelector(organManagerStatus);
  useLoader({ status: orderIsLoading });
  useLoader({ status: memberUserIsLoading });
  useLoader({ status: organManagerIsLoading });

  const orderDetailElement = useRef<HTMLDivElement>(null);
  const [stickyElementOffset, setStickyElementOffset] = useState<number>(0);

  const isVBankOrder = useMemo(() => orders?.paymentWay === PaymentWay.VBANK, [orders?.paymentWay]);
  const isCardOrder = useMemo(() => orders?.paymentWay === PaymentWay.CARD, [orders?.paymentWay]);

  const tabMenus: DetailTab<OrderDetailTabMenu>[] = useMemo(() => {
    const menus = [
      { tabValue: OrderDetailTabMenu.BUYER, tabName: t("detail.tabMenus.buyer") },
      { tabValue: OrderDetailTabMenu.ORDER_ITEM, tabName: t("detail.tabMenus.orderItem") },
      { tabValue: OrderDetailTabMenu.PAYMENT, tabName: t("detail.tabMenus.paymentInfo") },
      { tabValue: OrderDetailTabMenu.PAYMENT_WAY, tabName: t("detail.tabMenus.paymentWay") },
      { tabValue: OrderDetailTabMenu.CASH_RECEIPT, tabName: t("detail.tabMenus.cashReceipt") },
      { tabValue: OrderDetailTabMenu.REFUND, tabName: t("detail.tabMenus.refundHistory") },
      { tabValue: OrderDetailTabMenu.MEMO, tabName: t("detail.tabMenus.memo") },
    ];

    if (isCardOrder) {
      return menus.filter((menu) => menu.tabValue !== OrderDetailTabMenu.CASH_RECEIPT);
    } else {
      return menus;
    }
  }, [t, isCardOrder]);

  const [selectedTab, setSelectedTab] = useState<OrderDetailTabMenu>(OrderDetailTabMenu.BUYER);
  const onScroll: EventListener = useCallback(() => {
    if (orderDetailElement === null || orderDetailElement.current === null) return;
    const tabs = orderDetailElement.current.getElementsByClassName("tabs")[0] as HTMLDivElement;
    if (window.scrollY >= tabs.offsetTop && !orderDetailElement.current.classList.contains("fixed-tabs")) {
      setStickyElementOffset(tabs.offsetTop);
      orderDetailElement.current.classList.add("fixed-tabs");
    }
    if (window.scrollY < stickyElementOffset && orderDetailElement.current.classList.contains("fixed-tabs")) {
      orderDetailElement.current.classList.remove("fixed-tabs");
    }
  }, [stickyElementOffset]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [onScroll, orderDetailElement]);

  const refreshOrder = useCallback(() => {
    getOrders(orderIdx)
      .then()
      .catch(() => alert(defaultErrorMessage));
  }, [orderIdx, getOrders, defaultErrorMessage]);

  useEffect(() => {
    refreshOrder();
  }, [refreshOrder]);

  useEffect(() => {
    if (orders && orders.status === OrderStatus.PAID) getPayment(orders.idx, (paymentVO) => setPaymentVO(paymentVO));
  }, [orders, getPayment]);

  return (
    <OrderDetailStyle ref={orderDetailElement}>
      <PanelWrapper>{orders && <OrderDetailInfo orderVO={orders} />}</PanelWrapper>

      <div className={"tabs"}>
        <DetailSubMenus
          tabs={tabMenus}
          onClickTab={(type) => {
            setSelectedTab(type);
            window.location.hash = type;
          }}
          selectedTab={selectedTab}
        />
      </div>

      <PanelWrapper>
        {orders && <OrderBuyerInfo orderVO={orders} />}
        {orders && <OrderItemInfo orderVO={orders} onRefresh={() => refreshOrder()} />}
        {orders && <PaymentInfo orderVO={orders} />}
        {orders && <PaymentWayInfo orderVO={orders} paymentVO={paymentVO} />}

        {isVBankOrder && <CashReceiptInfo paymentVO={paymentVO} />}
        {orders && <RefundInfo orderVO={orders} />}

        <RoleChecker menuCode={AdminMenuCode.USER_MEMO}>
          <MemoComponent id="MEMO" targetIdx={orderIdx} targetType={MemoTargetType.ORDER} />
        </RoleChecker>
      </PanelWrapper>
    </OrderDetailStyle>
  );
}
