import React from "react";
import { OrganVoucherInfoStatus } from "./OrganVoucherInfoStatus";
import { OrganVoucherInfoList } from "./OrganVoucherInfoList";

interface Props {
  userIdx: number;
  name: string;
}

export function OrganVoucherInfo({ userIdx, name }: Props) {
  return (
    <>
      <OrganVoucherInfoStatus userIdx={userIdx} />
      <OrganVoucherInfoList userIdx={userIdx} name={name} />
    </>
  );
}
