import styled from "styled-components";
import React, { useMemo } from "react";
import { useCountry } from "../hooks/hooks";

const PhoneNumberWrapper = styled.div`
  display: flex;
  height: 100%;
`;

interface Props {
  codePhone: string;
  phoneNumber: string;
}

export function PhoneNumber({ codePhone, phoneNumber }: Props) {
  const { countries } = useCountry();
  const foundCountry = useMemo(() => countries.find((it) => it.codePhone === codePhone), [countries, codePhone]);

  return (
    <PhoneNumberWrapper>
      {foundCountry && (
        <>
          <span>{`(${foundCountry.codePhone})`}</span>&nbsp;
          <span>{phoneNumber}</span>
        </>
      )}
    </PhoneNumberWrapper>
  );
}
