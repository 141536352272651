import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { deleteMemoAsync } from "../../../../store/memoSlice";
import { callAsync } from "../../../../util/sliceUtil";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Args {
  onRefresh: () => void;
}
export function useMemoDeleter({ onRefresh }: Args) {
  const { t } = useTranslation("memo");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deleteMemo = useCallback(
    (memoIdx: number) => {
      const wantToDelete = window.confirm(t("delete.confirm"));

      if (wantToDelete) {
        callAsync(
          dispatch(deleteMemoAsync({ memoIdx })).unwrap(),
          () => {
            onRefresh();
            alert(t("delete.result"));
          },
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, onRefresh, t, defaultErrorMessage]
  );

  return {
    deleteMemo,
  };
}
