import React from "react";
import { useNavigate } from "react-router-dom";
import { AccountResult } from "../../../layout/account/AccountResult";
import { useAppSelector } from "../../../hooks/hooks";
import { accountFoundEmail } from "../../../store/accountSlice";
import { useTranslation } from "react-i18next";

export function FindIDSuccess() {
  const { t } = useTranslation("account");

  const navigate = useNavigate();
  const foundEmail = useAppSelector(accountFoundEmail);

  return (
    <AccountResult
      title={t("findAccount.findIDResult.title")}
      description={t("findAccount.findIDResult.description1", { id: foundEmail })}
      buttonText={t("findAccount.findIDResult.loginButton")}
      buttonAction={() => navigate(`/login`)}
    />
  );
}
