import { MainApi } from "./MainApi";
import { AdminPasswordUpdateParam } from "../types/mypage";

export class MyPageApi {
  static baseUrl = `${MainApi.urlPrefix}/my-page`;

  static getPersonalInfo = () => () => MainApi.api.get(`${MyPageApi.baseUrl}/detail-info`);

  static updatePassword = (adminPasswordUpdateParam: AdminPasswordUpdateParam) => () =>
    MainApi.api.post(`${MyPageApi.baseUrl}/update/pwd`, adminPasswordUpdateParam);

  static deleteMyAccount = () => () => MainApi.api.delete(`${MyPageApi.baseUrl}`);
}
