import style from "./numberStepper.module.scss";
import { useCallback, useEffect, useState } from "react";

interface Props {
  value: string;
  setValue: (_: string) => void;
  min?: number;
  max?: number;
}

export function NumberStepper({ value, setValue, min, max }: Props) {
  const [inputNumber, setInputNumber] = useState<string>(value);

  useEffect(() => {
    setInputNumber(value);
  }, [value]);

  const changeNumber = useCallback(
    (num: string) => {
      setInputNumber(num);
      setValue(num);
    },
    [setValue]
  );

  return (
    <div className={style.stepper}>
      <button
        onClick={() => {
          if (!Number.isNaN(inputNumber)) {
            const nextNumber = Number(inputNumber) - 1;
            if (min !== undefined && nextNumber < min) {
              changeNumber(min.toString());
            } else {
              changeNumber(nextNumber.toString());
            }
          }
        }}
      >
        -
      </button>
      <input
        type={"number"}
        value={inputNumber}
        onChange={(e) => setInputNumber(e.target.value)}
        onBlur={() => {
          const enteredNumber = Number(inputNumber);

          if (min !== undefined && enteredNumber < min) {
            changeNumber(min.toString());
            return;
          }

          if (max !== undefined && max < enteredNumber) {
            changeNumber(max.toString());
            return;
          }
          changeNumber(enteredNumber.toString());
        }}
      />
      <button
        onClick={() => {
          if (!Number.isNaN(inputNumber)) {
            const nextNumber = Number(inputNumber) + 1;
            if (max !== undefined && max < nextNumber) {
              changeNumber(max.toString());
            } else {
              changeNumber(nextNumber.toString());
            }
          }
        }}
      >
        +
      </button>
    </div>
  );
}
