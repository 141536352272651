import {ListTable} from "../../../layout/content/list/ListTable";
import {useBannerList} from "./hooks/useBannerList";
import {ListCount, WhichList} from "../../../components/ListCount";
import {useBannerColumns} from "./hooks/useBannerColumns";
import {BannerListVO, BannerStatus} from "../../../types/banner";
import {useCallback, useMemo, useState} from "react";
import {useBannerFilters} from "./hooks/useBannerFilters";
import {DetailActionButton} from "../../../components/Buttons";
import deleteIcon from "../../../assets/images/button_icons/delete_icon.svg";
import stopIcon from "../../../assets/images/button_icons/stop_icon.svg";
import confirmIcon from "../../../assets/images/button_icons/confirm_icon.svg";
import {useBannerDeleter} from "./hooks/useBannerDeleter";
import {useBannerStatusUpdater} from "./hooks/useBannerStatusUpdater";

export function BannerList() {

    const [selectedBannerIdxes, setSelectedBannerIdxes] = useState<number[]>([]);
    const {totalCount, bannerList, getBannerListWithParam, searchBannerList, pageCondition} = useBannerList();
    const {filters} = useBannerFilters({search: searchBannerList({})})
    const {columns} = useBannerColumns({
        showBanner: (vo: BannerListVO) => onClickShowBannerButton([vo], true),
        hideBanner: (vo: BannerListVO) => onClickHideBannerButton([vo], true),
        getList: getBannerListWithParam,
    });

    const { updateBannerStatus } = useBannerStatusUpdater({ onCompletedCallback: () => getBannerListWithParam() });
    const { deleteBanners } = useBannerDeleter({ onCompletedCallback: () => getBannerListWithParam(true) });



    const onClickDeleteButton = useCallback(() => {
        deleteBanners(selectedBannerIdxes).then();
    }, [deleteBanners, selectedBannerIdxes]);

    const selectedBanners = useMemo(
        () => bannerList?.content.filter((vo) => selectedBannerIdxes.includes(vo.idx)) ?? [],
        [selectedBannerIdxes, bannerList?.content]
    );

    const onClickShowBannerButton = useCallback(
        (banners: BannerListVO[], isButtonOnList: boolean) => {
            updateBannerStatus(banners, BannerStatus.EXPOSED, isButtonOnList).then();
        },
        [updateBannerStatus]
    );

    const onClickHideBannerButton = useCallback(
        (banners: BannerListVO[], isButtonOnList: boolean) => {
            updateBannerStatus(banners, BannerStatus.UNEXPOSED, isButtonOnList).then();
        },
        [updateBannerStatus]
    );

    return <>
        {bannerList ? (
            <ListTable
                filters={filters}
                // todo 작업 필요
                buttonsNode={
                    <>
                        <div>
                            <DetailActionButton
                                onClick={() => onClickDeleteButton()}
                            >
                                <img src={deleteIcon} alt={"삭제 아이콘"}/>
                                배너삭제
                            </DetailActionButton>
                        </div>
                        <div>
                            <DetailActionButton
                                onClick={() => onClickHideBannerButton(selectedBanners, false)}
                            >
                                <img src={stopIcon} alt={"노출중지 아이콘"}/>
                                노출중지
                            </DetailActionButton>
                            <DetailActionButton
                                onClick={() => onClickShowBannerButton(selectedBanners, false)}
                            >
                                <img src={confirmIcon} alt={"노출재개 아이콘"}/>
                                노출재개
                            </DetailActionButton>
                        </div>
                    </>
                }
                checkedRowIdxArr={selectedBannerIdxes}
                setCheckedRowIdxArr={setSelectedBannerIdxes}
                listData={bannerList}
                getList={({page, sort, size}) => searchBannerList({page, sort, size})}
                columns={columns}
                totalRowsCnt={{
                    totalCount: <ListCount totalCount={totalCount ?? 0} whichList={WhichList.BANNER}/>,
                    singleUnitKeyInCommonFile: "bannerUnit.singleComponent",
                    multipleUnitKeyInCommonFile: "bannerUnit.multipleComponent",
                }}
                pageOnFilter={pageCondition}/>
        ) : null}

    </>;
}
