import style from "./radioGroup.module.scss";
import { Radio, RadioGroup } from "./RadioGroup";
import { useMemo } from "react";

interface Props<T> {
  enumValues: string[];
  getLabel: (_: T) => string;
  value: T;
  setValue: (_: T) => void;
  groupName: string;
  isRequired: boolean;
}
export function RadioGroupField<T>({ enumValues, getLabel, value, setValue, groupName, isRequired }: Props<T>) {
  const radios: Radio<T>[] = useMemo(
    () =>
      enumValues.map((enumValue) => {
        const typeValue: T = enumValue as unknown as T;
        return {
          check: (v: T) => v === typeValue,
          onClickFunc: () => setValue(typeValue),
          text: getLabel(typeValue),
        };
      }),
    [enumValues, getLabel, setValue]
  );

  return (
    <div className={style.radioGroup}>
      <RadioGroup groupName={groupName} isRequired={isRequired} radios={radios} selectedValue={value} />
    </div>
  );
}
