import style from "./memo.module.scss";
import { ActionButton, ActiveActionButton, RedActionButton } from "../../../components/Buttons";
import { Memo, MemoType } from "../../../types/memo";
import { useMemoDeleter } from "./hooks/useMemoDeleter";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MemoTypeSelector } from "./MemoTypeSelector";
import { MemoTextArea } from "./MemoTextArea";
import { AdminMenuCode } from "../../../types/adminRole";
import { RoleChecker } from "../../role/RoleChecker";
import { useMemoUpdater } from "./hooks/useMemoUpdater";
import { useTranslation } from "react-i18next";

interface Props {
  memo: Memo;
  onRefresh: () => void;
  memoListTitle: string;
}
export function AMemo({ memo, onRefresh, memoListTitle }: Props) {
  const { t } = useTranslation("memo");
  const formRef = useRef<HTMLFormElement>(null);
  const memoRef = useRef<HTMLTextAreaElement>(null);

  const { deleteMemo } = useMemoDeleter({ onRefresh });
  const [isEditMode, setIsEditMode] = useState(false);
  const [memoPutParam, setMemoPutParam] = useState("");
  const [memoTypePutParam, setMemoTypePutParam] = useState<MemoType>(MemoType.GENERAL);
  const { updateMemo } = useMemoUpdater({
    onRefresh: () => {
      setIsEditMode(false);
      onRefresh();
    },
  });

  useEffect(() => {
    setMemoPutParam(memo.memo);
    setMemoTypePutParam(memo.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDeleteMemoBtn = useCallback((memoIdx: number) => deleteMemo(memoIdx), [deleteMemo]);

  const onClickEditButton = useCallback(() => setIsEditMode(true), []);
  const onClickCancelButton = useCallback(() => setIsEditMode(false), []);
  const onClickSaveButton = useCallback(() => {
    if (!formRef.current?.reportValidity()) return;

    updateMemo(memo.idx, {
      memo: memoPutParam,
      type: memoTypePutParam,
    });
  }, [memoPutParam, memoTypePutParam, memo.idx, updateMemo]);

  return (
    <li className={style.item}>
      <div className={style.contentArea}>
        <div className={style.writerInfo}>
          <span className={style.dateTime}>{memo.createAt}</span>
          <span className={style.writer}>
            {memo.createBy.name}({memo.createBy.email})
          </span>
        </div>
        <RoleChecker menuCode={AdminMenuCode.EDIT_MEMO}>
          <div className={style.deleteButtonArea}>
            {isEditMode ? (
              <>
                <ActionButton onClick={onClickCancelButton}>{t("aMemo.cancelButton")}</ActionButton>
                <ActiveActionButton onClick={onClickSaveButton}>{t("aMemo.saveButton")}</ActiveActionButton>
              </>
            ) : (
              <>
                <ActionButton onClick={onClickEditButton}>{t("aMemo.editButton")}</ActionButton>
                <RedActionButton onClick={() => onClickDeleteMemoBtn(memo.idx)}>
                  {t("aMemo.deleteButton")}
                </RedActionButton>
              </>
            )}
          </div>
        </RoleChecker>
      </div>
      <div className={style.content}>
        {isEditMode ? (
          <div className={style.memoEditForm}>
            <MemoTypeSelector
              targetType={memo.targetType}
              memoType={memoTypePutParam}
              setMemoType={setMemoTypePutParam}
            />
            <form className={style.textArea} ref={formRef}>
              <MemoTextArea
                memoListTitle={memoListTitle}
                defaultValue={memo.memo}
                setMemo={setMemoPutParam}
                memoRef={memoRef}
              />
            </form>
          </div>
        ) : (
          <>
            <span className={style.type}>
              [{memo.type === MemoType.GENERAL ? t("aMemo.category.general") : t("aMemo.category.inquiry")}]
            </span>
            <span className={style.contentText}>{memo.memo}</span>
          </>
        )}
      </div>
    </li>
  );
}
