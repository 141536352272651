import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {executePromise} from "../util/sliceUtil";
import {SimpleTestApi} from "../api/SimpleTestApi";
import {SimpleSearchCondition, SimpleTesterListDTO} from "../types/tester";
import {SliceState, Status} from "../types/common";
import {RootState} from "./index";
import {PageResponse} from "../types/page";

const initialState: SimpleTesterState = {
    status: Status.IDLE,
};

export interface SimpleTesterState extends SliceState {
    simpleTestList?: PageResponse<SimpleSearchCondition>;
    simpleTestVO?: SimpleTesterListDTO;
}


export const getSimpleTester = createAsyncThunk(
    "simpleTest/getSimpleTester",
    (condition: SimpleSearchCondition) => executePromise(SimpleTestApi.getSimpleTesters(condition))
);

export const disableMarketingAgreement = createAsyncThunk(
    "simpleTest/getSimpleTester",
    (condition: number[]) => executePromise(SimpleTestApi.disableMarketingAgreement(condition))
);

export const downloadSimpleTesterAsync = createAsyncThunk("simpleTester/downloadUsers", (simpleUserIdxes: number[]) =>
    executePromise(SimpleTestApi.downloadSimpleTester(simpleUserIdxes))
);

export const simpleTesterSlice = createSlice({
    name: "simpleTester",
    initialState,
    reducers: {},
    extraReducers: (builder) => {},
});

// export const {setSimpleTEstList, set}

export default simpleTesterSlice.reducer;
export const simpleTestList = (state: RootState) => state.simpleTester.simpleTestList;