import {MainApi} from "./MainApi";
import {SimpleSearchCondition} from "../types/tester";

export class SimpleTestApi {
    static baseUrl = `${MainApi.dcasSimpleUrlPrefix}`;

    static getSimpleTesters = (condition: SimpleSearchCondition) => () =>
        MainApi.api.post(`${SimpleTestApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

    static disableMarketingAgreement = (condition: number[]) => () =>
        MainApi.api.put(`${SimpleTestApi.baseUrl}/disagree-terms`, {
            simpleUserIdxes: condition
        }, {});

    static downloadSimpleTester = (simpleUserIdxes: number[]) => () =>
        MainApi.api.get(`${SimpleTestApi.baseUrl}/download?${simpleUserIdxes.map((idx) => `simpleUserIdxes=${idx}`).join("&")}`, {
            responseType: "blob",
        });
}