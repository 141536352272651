import { Panel } from "../../../../../layout/content/detail/Panel";
import { DescriptionList } from "../../../../../layout/content/detail/DescriptionList";
import { useCallback, useEffect, useState } from "react";
// eslint-disable-next-line max-len
import { useAffiliateCodeSettlementStaticsGetter } from "../../../../affiliate_code/settlement/hooks/useAffiliateCodeSettlementStaticsGetter";
import { useUtil } from "../../../../../util/hooks/useUtil";
import { OrganManagerMonthlyAffiliateCodeStaticsVO, SettlementStatus } from "../../../../../types/affiliateCode";
import { numberWithCommas } from "../../../../../util/etcUtil";
import { CenterTextStyle, RightTextStyle } from "../../../../../components/Texts";
import { useSettlementStatusTextElement } from "../../../../affiliate_code/hooks/code/useSettlementStatusTextElement";
// eslint-disable-next-line max-len
import { useAffiliateCodeSettlementStatusUpdateButtons } from "../../../../affiliate_code/settlement/hooks/useAffiliateCodeSettlementStatusUpdateButtons";

interface Props {
  organManagerIdx: number;
  year: number;
  month: number;
}

export function OrganManagerAffiliateCodeStatistics({ organManagerIdx, year, month }: Props) {
  const { getAffiliateCodeSettlementStaticsByOrderIdx } = useAffiliateCodeSettlementStaticsGetter();
  const { defaultErrorMessage } = useUtil();
  const [staticsVO, setStaticsVO] = useState<OrganManagerMonthlyAffiliateCodeStaticsVO>();
  const { getSettlementStatusLabelElement } = useSettlementStatusTextElement();
  const getStatics = useCallback(
    (organManagerIdx: number, year: number, month: number) => {
      getAffiliateCodeSettlementStaticsByOrderIdx({ organManagerIdx, year, month })
        .then((result: OrganManagerMonthlyAffiliateCodeStaticsVO) => setStaticsVO(result))
        .catch(() => alert(defaultErrorMessage));
    },
    [defaultErrorMessage, getAffiliateCodeSettlementStaticsByOrderIdx]
  );

  const { SettlementCancellationButton, SettlementCompletionButton } = useAffiliateCodeSettlementStatusUpdateButtons({
    year,
    month,
    resolve: () => getStatics(organManagerIdx, year, month),
  });

  useEffect(() => {
    if (year && month) {
      getStatics(organManagerIdx, year, month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organManagerIdx, year, month]);

  return (
    <Panel
      title={"월별 제휴코드 통계"}
      additionalButtons={
        <>
          {!Boolean(staticsVO?.status) ? (
            <></>
          ) : (
            <>
              {staticsVO!.status === SettlementStatus.COMPLETE ? (
                <SettlementCancellationButton checkedIdxes={[organManagerIdx]} />
              ) : (
                <SettlementCompletionButton checkedIdxes={[organManagerIdx]} />
              )}
            </>
          )}
        </>
      }
    >
      <DescriptionList
        data={[
          [
            {
              key: "총 제휴결제건수",
              value: <CenterTextStyle>{staticsVO?.paidCount ?? 0} 건</CenterTextStyle>,
            },
            {
              key: "총 정산금액",
              value: <RightTextStyle>{numberWithCommas(staticsVO?.totalSettlementValue ?? 0)} 원</RightTextStyle>,
            },
            {
              key: "정산여부",
              value: (
                <>
                  {staticsVO?.status ? (
                    <>{getSettlementStatusLabelElement(staticsVO.status)}</>
                  ) : (
                    <CenterTextStyle>-</CenterTextStyle>
                  )}
                </>
              ),
            },
          ],
        ]}
        labelWidth={130}
        valueWidth={150}
      ></DescriptionList>
    </Panel>
  );
}
