import styled from "styled-components/macro";
import { FilterInputText } from "../Inputs";

export const KoreanAddressInputWrapperStyle = styled.div`
  width: 700px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, auto);
`;

export const KoreanAddress1InputStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 46px;
  padding: 11px 14px;
  border: 1px solid ${(props) => props.theme.color.cDE};
`;
export const KoreanAddress2InputStyle = styled(FilterInputText)`
  margin-left: -1px;
`;
