import { useLanguage } from "../../../hooks/hooks";
import { Language } from "../../../types/language";
import { RoundSelector } from "./RoundSelector";

interface Props {
  isWhiteVersion?: boolean;
}
export function LanguageSelector({ isWhiteVersion = false }: Props) {
  const { changeLanguage, language, languageOptions } = useLanguage();

  return (
    <RoundSelector
      isWhiteVersion={isWhiteVersion}
      options={languageOptions}
      selectedValue={language}
      setSelectorOption={(lang) => changeLanguage(lang.value as Language)}
    />
  );
}
