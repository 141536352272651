import style from "./nav.module.scss";
import logo from "../../assets/images/feelgood_logo_white.svg";
import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Menu } from "../../types/common";
import { useRoleChecker } from "../../features/role/hooks/useRoleChecker";
import { useTranslation } from "react-i18next";
import {useLanguage} from "../../hooks/hooks";
import {Language} from "../../types/language";

interface Props {
  menuList: Menu[];
  thisFirstMenu: Menu | null;
  firstMenuPath: string;
  secondMenuPath: string;
}

export function Nav({ menuList, thisFirstMenu, firstMenuPath, secondMenuPath }: Props) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const { getRoleCheckedElement } = useRoleChecker();
  const [selectedMenu, setSelectedMenu] = useState<Menu | null>(thisFirstMenu);

  const {language} = useLanguage();
  const hiddenValue = {
    ko: [],
    en: ['catalog', 'affiliate-code', 'coupon'],
  };
  menuList = language === Language.KO ? menuList
      : menuList.filter(menu => !hiddenValue[language].includes(menu.link));



  const isThisMenuSelected = useCallback((menuLink: string) => selectedMenu?.link === menuLink, [selectedMenu]);
  const isThisSecondMenuPage = useCallback(
    (firstMenuLink: string, secondMenuLink: string) =>
      firstMenuPath === firstMenuLink && secondMenuPath === secondMenuLink,
    [firstMenuPath, secondMenuPath]
  );

  useEffect(() => {
    setSelectedMenu(thisFirstMenu);
  }, [thisFirstMenu]);

  const getMenuCollapsedBtn = (menu: Menu) => {
    if (menu.subMenu) {
      if (isThisMenuSelected(menu.link)) {
        return <div className={style.expandedIcon}></div>;
      } else {
        return <div className={style.collapsedIcon}></div>;
      }
    }

    return <></>;
  };

  const getUrl = useCallback((firstPath: string, secondPath: string) => `/${firstPath}/${secondPath}`, []);

  const getSubMenuList = (menu: Menu, menuLink: string) => {
    if (menu.subMenu && isThisMenuSelected(menu.link)) {
      return (
        <ul className={style.secondMenuList}>
          {menu.subMenu?.map((subMenu) => {
            const key = `submenu-${menuLink}-${subMenu.link}`;
            const element = (
              <li
                className={
                  `${style.secondMenu} ` +
                  `${isThisSecondMenuPage(menu.link, subMenu.link) ? style.active : ""} ` +
                  `${subMenu.isDisabled ? style.disabled : ""} ` +
                  `${menu.isSpecial ? style.special : ""}`
                }
                onClick={() => onClickSecondMenu(getUrl(menu.link, subMenu.link))}
              >
                <span>-&nbsp;&nbsp;&nbsp;</span>
                {subMenu.isDisabled ? (
                  <>{subMenu.label}</>
                ) : (
                  <Link to={getUrl(menu.link, subMenu.link)}>{subMenu.label}</Link>
                )}
              </li>
            );

            return getRoleCheckedElement(key, element, subMenu.menuCode);
          })}
        </ul>
      );
    }

    return <></>;
  };

  const onClickFirstMenuArea = (menu: Menu) => {
    if (!menu.isDisabled) {
      setSelectedMenu(menu);
    }
  };

  const onClickFirstMenu = useCallback(
    (e: React.MouseEvent, menu: Menu) => {
      if (selectedMenu?.link === menu.link) {
        setSelectedMenu(null);
        e.stopPropagation();
      }
    },
    [selectedMenu?.link]
  );

  const onClickSecondMenu = (goTo: string) => {
    navigate(goTo);
  };

  useEffect(() => {
    setSelectedMenu(menuList.find((menu) => menu.link === firstMenuPath) ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <nav className={style.nav}>
      <div className={style.logoArea}>
        <img src={logo} alt={t("logo")} />
        <div className={style.adminBadge}>Admin</div>
      </div>
      <ul className={style.menuList}>
        {menuList.map((menu) => {
          const key = `menu-${menu.link}`;
          const element = (
            <li className={style.item} onClick={() => onClickFirstMenuArea(menu)}>
              <div
                className={`${style.firstMenu} ${menu.isSpecial ? style.special : ""} `}
                onClick={(e) => onClickFirstMenu(e, menu)}
              >
                {menu.label}
                {getMenuCollapsedBtn(menu)}
              </div>
              {getSubMenuList(menu, menu.link)}
            </li>
          );

          return getRoleCheckedElement(key, element, menu.menuCode);
        })}
      </ul>
    </nav>
  );
}
