import style from "../productEditForm.module.scss";
import {FilterNumber} from "../../../../layout/content/list/filter/FilterNumber";
import {FilterContentType} from "../../../../types/tableFilter";
import React from "react";
import {Label104x46} from "../../../../components/Label";
import {useTranslation} from "react-i18next";
import {Languages} from "../../../../types/common";
import {convertCurrencyUnit} from "../../../../util/etcUtil";
import {PaymentWay} from "../../../../types/payment";

interface Props {
  regularPrice: string;
  setRegularPrice: (_: string) => void;
  language: Languages;
}

export function ProductEditRegularPrice({ regularPrice, setRegularPrice, language }: Props) {
  const { t } = useTranslation(["product", "common"]);
  return (
    <div className={`${style.field} ${style.alignCenter} ${style.flex0}`}>
      <Label104x46 text={t("edit.regularPrice.title")} className={style.label} />
      <FilterNumber
        numberField={{
          type: FilterContentType.NUMBER,
          value: regularPrice,
          min: 1,
          isRequired: true,
          placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          onChangeFunc: (v: string) => setRegularPrice(v),
        }}
        className={style.priceField}
      />
        <span className={style.unitText}>{convertCurrencyUnit(language !== Languages.KO
            ? PaymentWay.PAYPAL
            : PaymentWay.CARD, t)}</span>
    </div>
  );
}
