import style from "../productEditForm.module.scss";
import { FilterCalendarRange } from "../../../../layout/content/list/filter/FilterCalendarRange";
import { FilterContentType } from "../../../../types/tableFilter";
import React, { useEffect } from "react";
import { Label104x46 } from "../../../../components/Label";
import { useTranslation } from "react-i18next";

interface Props {
  sellingStartDate: string;
  setSellingStartDate: (_: string) => void;
  sellingEndDate: string | null;
  setSellingEndDate: (_: string | null) => void;
  isSellingAlways: boolean;
  setIsSellingAlways: (_: boolean) => void;
}

export function ProductEditSellingDate({
  sellingStartDate,
  setSellingStartDate,
  sellingEndDate,
  setSellingEndDate,
  isSellingAlways,
  setIsSellingAlways,
}: Props) {
  const { t } = useTranslation(["product", "common"]);

  useEffect(() => {
    if (sellingEndDate === null) {
      setIsSellingAlways(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellingEndDate]);

  return (
    <div className={`${style.field} ${style.alignCenter}`}>
      <Label104x46 text={t("edit.sellingDate.title")} className={style.label} />
      <FilterCalendarRange
        from={{
          type: FilterContentType.CALENDAR,
          value: sellingStartDate,
          onChangeFunc: (v) => setSellingStartDate(v),
          placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
          isRequired: true,
        }}
        to={{
          type: FilterContentType.CALENDAR,
          value: sellingEndDate ?? "",
          onChangeFunc: (v) => setSellingEndDate(v),
          placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
          isDisabled: isSellingAlways,
        }}
      />
      <label className={style.checkField}>
        <input
          type={"checkbox"}
          checked={isSellingAlways}
          readOnly={true}
          onClick={() => setIsSellingAlways(!isSellingAlways)}
        />
        {t("edit.sellingDate.regularSales")}
      </label>
    </div>
  );
}
