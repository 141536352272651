import React, { useCallback, useEffect, useState } from "react";
import { FeedGoodLogoImage } from "../../../components/Images";
import style from "../loginFail.module.scss";
import { PrimaryButton, PrimaryOutlineButton } from "../../../components/Buttons";
import { RemainingSeconds } from "../components/RemainingSeconds";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useInterval } from "../../../hooks/auth/useInterval";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { accountStatus, resendAuthConfirmMailAsync } from "../../../store/accountSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { callAsync } from "../../../util/sliceUtil";
import { Trans, useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { StrongTextStyle } from "../../../components/Texts";

export function NoAuthorization() {
  const { t } = useTranslation("account");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const status = useAppSelector(accountStatus);
  useLoader({ status });
  const { defaultErrorMessage } = useUtil();

  const [startDateTime, setStartDateTime] = useState(new Date(Date.now() - 30000));
  const [remainingTime, setRemainingTime] = useState(0);
  const { email } = queryString.parse(window.location.search, {});

  const goBack = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (!email) goBack();
  }, [email, goBack]);

  const updateDateTime = useCallback(
    () => setRemainingTime(Math.round(30 - (new Date(Date.now()).getTime() - startDateTime.getTime()) / 1000)),
    [startDateTime]
  );
  useInterval(updateDateTime, 1000);

  const onClickResendAuthMail = useCallback(() => {
    if (remainingTime > 1) {
      alert(t("beforeAuthorization.waitForSec", { sec: remainingTime }));
      return;
    }

    callAsync(
      dispatch(
        resendAuthConfirmMailAsync({
          email: email as string,
        })
      ).unwrap(),
      () => {
        setStartDateTime(new Date(Date.now()));
        alert(t("beforeAuthorization.resendResultMessage"));
      },
      () => alert(defaultErrorMessage)
    ).then();
  }, [remainingTime, email, dispatch, defaultErrorMessage, t]);
  return (
    <div className={style.noAuthWrapper}>
      <FeedGoodLogoImage />

      <p className={style.message}>
        <Trans t={t} i18nKey={"beforeAuthorization.message1"} components={[<StrongTextStyle></StrongTextStyle>]} />
      </p>

      <p className={style.description}>{t("beforeAuthorization.message2")}</p>

      <div className={style.buttonsWrap}>
        <div className={style.recreateWrap}>
          <PrimaryButton onClick={onClickResendAuthMail}>{t("beforeAuthorization.resendButton")}</PrimaryButton>
          <RemainingSeconds remainSeconds={remainingTime} />
        </div>

        <PrimaryOutlineButton onClick={goBack}>{t("beforeAuthorization.logoutButton")}</PrimaryOutlineButton>
      </div>
    </div>
  );
}
