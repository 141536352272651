import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TestListDTO, TestListObj, TestSearchCondition } from "../types/test";
import { addCases, executePromise } from "../util/sliceUtil";
import { TestApi } from "../api/TestApi";
import { SliceState, Status } from "../types/common";
import { PageResponse } from "../types/page";
import { RootState } from "./index";

export interface TestState extends SliceState {
  testList?: PageResponse<TestListObj>;
}

const initialState: TestState = {
  status: Status.IDLE,
};

export const getKpassTestListAsync = createAsyncThunk("test/getKpassList", (condition: TestSearchCondition) =>
  executePromise(TestApi.getKpassTestList(condition))
);

export const getDcasTestListAsync = createAsyncThunk("test/getDcasList", (condition: TestSearchCondition) =>
    executePromise(TestApi.getDcasTestList(condition))
);

export const downloadExcelAsync = createAsyncThunk("test/downloadExcel", (testIdxes: number[]) =>
  executePromise(TestApi.downloadExcel(testIdxes))
);

export const deleteTestAsync = createAsyncThunk("text/deleteTest", (testIdxes: number[]) =>
  executePromise(TestApi.deleteTests(testIdxes))
);

export const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    setTestList: (state, action: PayloadAction<PageResponse<TestListDTO>>) => {
      state.testList = {
        ...action.payload,
        content: action.payload.content.map((it) => {
          return {
            idx: it.idx,
            userIdx: it.userIdx,
            profileIdx: it.profileIdx,
            userStatus: it.userStatus,
            endedAt: it.endedAt,
            email: it.email,
            name: it.name,
            userCode: it.userCode,
            age: it.ageYear ? `${it.ageYear}(${it.ageMonth})` : null,
            gender: it.gender,
            region: it.region,
            testRound: it.testRound,
            isDeleted: it.isDeleted,
            nationality: it.nationality,
            dcasResultForm: it.dcasResultForm,
            kpassResultForm: it.kpassResultForm,
          };
        }),
      };
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getKpassTestListAsync);
    addCases(builder, downloadExcelAsync);
    addCases(builder, deleteTestAsync);
  },
});

export default testSlice.reducer;

export const { setTestList } = testSlice.actions;

export const testList = (state: RootState) => state.test.testList;
export const testListStatus = (state: RootState) => state.test.status;
