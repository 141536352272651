import {RadioGroupField} from "../../../components/RadioGroupField";
import {Languages, WebsiteType} from "../../../types/common";
import {usePopupType} from "../../../types/hooks/usePopupType";

interface Props {
    popupLanguage: Languages;
    setPopupLanguage: (_: Languages) => void;
    websiteType: WebsiteType;
}

export function PopupLanguageField({popupLanguage, setPopupLanguage, websiteType}: Props) {
    const {getPopupLanguageLabel} = usePopupType();

    const newBannerType = websiteType === WebsiteType.KPASS
        ? [Languages.KO, Languages.EN, Languages.RU]
        : [Languages.KO, Languages.EN, Languages.ZH]

    return (
        <>
            <RadioGroupField enumValues={newBannerType}
                             getLabel={getPopupLanguageLabel}
                             value={popupLanguage} setValue={setPopupLanguage}
                             groupName={"popupLanguage"} isRequired={true}
            />
        </>
    );
}