import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SliceState, Status } from "../types/common";
import { AccessibleMenu, AdminRoleCategory, AdminRoleListObj } from "../types/adminRole";
import { RootState } from "./index";
import { executePromise } from "../util/sliceUtil";
import { AdminRoleApi } from "../api/AdminRoleApi";
import { PageResponse } from "../types/page";

export interface AdminRoleState extends SliceState {
  accessibleMenuList?: AccessibleMenu[];
  adminRoles?: AdminRoleListObj[];
}

const initialState: AdminRoleState = {
  status: Status.IDLE,
};

export const getRolesAsync = createAsyncThunk("role/getRoles", () =>
  executePromise<PageResponse<AdminRoleListObj>>(AdminRoleApi.getRoles())
);

export const getRoleByCategoryAsync = createAsyncThunk("role/getRoleByCategory", (category: AdminRoleCategory) =>
  executePromise(AdminRoleApi.getRoleByCategory(category))
);

export const getMyAccessibleMenuAsync = createAsyncThunk("role/getMyAccessibleMenu", () =>
  executePromise(AdminRoleApi.getMyAccessibleMenu())
);

export const adminRoleSlice = createSlice({
  name: "adminRole",
  initialState,
  reducers: {
    setAccessibleMenuList: (state, action: PayloadAction<AccessibleMenu[] | undefined>) => {
      state.accessibleMenuList = action.payload;
    },
    setAdminRoles: (state, action: PayloadAction<AdminRoleListObj[]>) => {
      state.adminRoles = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export default adminRoleSlice.reducer;

export const { setAccessibleMenuList, setAdminRoles } = adminRoleSlice.actions;
export const adminRoleStatus = (state: RootState) => state.adminRole.status;
export const accessibleMenuListByRole = (state: RootState) => state.adminRole.accessibleMenuList;
export const adminRoles = (state: RootState) => state.adminRole.adminRoles;
