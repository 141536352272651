import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback, useState } from "react";
import { getOrgansByProfileAsync } from "../../../../store/userProfileSlice";
import { OrganByProfileDTO, OrganByProfileVO } from "../../../../types/organ";

export function useTestersOrganCountGetter() {
  const dispatch = useAppDispatch();

  const [organByProfileVOs, setOrganByProfileVOs] = useState<OrganByProfileVO[]>();

  const getOrganCountByProfileIdx = useCallback(
    (profileIdx: number) => {
      return dispatch(getOrgansByProfileAsync(profileIdx))
        .unwrap()
        .then((result: OrganByProfileDTO[]) =>
          setOrganByProfileVOs(result.map((dto) => OrganByProfileDTO.toOrganByProfileVO(dto)))
        );
    },
    [dispatch]
  );

  return {
    organByProfileVOs,
    getOrganCountByProfileIdx,
  };
}
