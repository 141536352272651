import React, { useEffect, useRef, useState } from "react";
import { MiniModal } from "../../../layout/modal/MiniModal";
import { LinkStyle } from "../../../components/styled/LinkStyle";
import { TableStyle } from "../../../components/styled/TableStyle";
import { useAppSelector, useNewWindow } from "../../../hooks/hooks";
import { orderItemGetterStatus } from "../../../store/orderSlice";
import useOrderItemGetter from "../hooks/useOrderItemGetter";
import { OrderItemTitleRowStyle } from "./OrderItemTitleRowStyle";
import { MINI_MODAL_LINK } from "../../../util/etcUtil";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  orders: {
    idx: number;
    orderImage: string;
    orderTitle: string;
    createAt: string;
  };
}

export function OrderItemColumn({ orders }: Props) {
  const { t } = useTranslation("order");
  const { orderItems, getOrderItems } = useOrderItemGetter();
  const [isShow, setIsShow] = useState<boolean>(false);
  const status = useAppSelector(orderItemGetterStatus);
  const linkRef = useRef<HTMLSpanElement | null>(null);
  const { defaultErrorMessage } = useUtil();
  const { openProductDetail } = useNewWindow();

  useEffect(() => {
    if (isShow)
      getOrderItems(orders.idx)
        .then()
        .catch(() => alert(defaultErrorMessage));
  }, [orders, isShow, getOrderItems, defaultErrorMessage]);

  return (
    <OrderItemTitleRowStyle>
      <img src={orders.orderImage} alt={t("productImage")} />
      <LinkStyle
        onClick={(e) => {
          e.preventDefault();
          setIsShow(true);
        }}
        data-name={MINI_MODAL_LINK}
        ref={linkRef}
        style={{ position: "relative" }}
      >
        {orders.orderTitle}

        <MiniModal
          show={isShow}
          closeModal={() => setIsShow(false)}
          title={t("orderedProductDetail", { createAt: orders.createAt })}
          modalWidth={"504px"}
          linkElement={linkRef.current ?? undefined}
          statusValues={[status]}
        >
          <TableStyle>
            <thead>
              <tr>
                <th>{t("productName")}</th>
                <th>{t("itemCount")}</th>
              </tr>
            </thead>

            <tbody>
              {orderItems?.map((it) => (
                <tr key={it.idx}>
                  <td>
                    <OrderItemTitleRowStyle onClick={() => openProductDetail(it.productIdx)}>
                      <img src={it.productImage} alt={t("productImage")} />
                      <span>{it.productName}</span>
                    </OrderItemTitleRowStyle>
                  </td>
                  <td>{it.quantity}</td>
                </tr>
              ))}
            </tbody>
          </TableStyle>
        </MiniModal>
      </LinkStyle>
    </OrderItemTitleRowStyle>
  );
}
