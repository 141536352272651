import { CustomerType, TableColumnData } from "../../../../types/common";
import React, { useCallback } from "react";
import { OrganManagerListDTO, OrganManagerStatus } from "../../../../types/organManager";
import { LinkStyle } from "../../../../components/styled/LinkStyle";
import { ActionButton, ActiveActionButton } from "../../../../components/Buttons";
import { ActionButtonWrapper } from "../../../../layout/content/ActionButtonWrapper";
import OrganManagerStatusComponent from "../components/OrganManagerStatusComponent";
import { useUnblockOrganMember } from "./useUnblockOrganMember";
import { useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { useOrganManagerEmailResender } from "./useOrganManagerEmailResender";
import { useOrganManagerStatus } from "./useOrganManagerStatus";
import { useOrganManagerDeleter } from "./useOrganManagerDeleter";
import { useTranslation } from "react-i18next";

function getCountTextByStatus(count: number, status: OrganManagerStatus): string {
  switch (status) {
    case OrganManagerStatus.DEFAULT:
    case OrganManagerStatus.BLOCKED:
    case OrganManagerStatus.REVOKED_SELF_PHONE_AUTHENTICATION:
      return `${count}`;
    case OrganManagerStatus.LEAVE:
    case OrganManagerStatus.BEFORE_SELF_MAIL_AUTHENTICATION:
      return "-";
  }
}

interface ReturnType {
  columns: TableColumnData<OrganManagerListDTO>[];
  columnsInCodeCreator: TableColumnData<OrganManagerListDTO>[];
}

export function useOrganManagerColumns(getList?: () => void): ReturnType {
  const { t } = useTranslation("organManager");
  const unblockOrganMember = useUnblockOrganMember();
  const { openOrganManagerDetailPopup, openGrantProduct, openGrantCoupon } = useNewWindow();
  const { resendEmail } = useOrganManagerEmailResender();
  const { isLeave, isBeforeSignUpCompleted } = useOrganManagerStatus();
  const { timeConverter } = useTimeConverter();

  const openDetailPopup = useCallback(
    (idx: number) => {
      openOrganManagerDetailPopup(idx);
    },
    [openOrganManagerDetailPopup]
  );

  const getMemoButton = (hasMemo: boolean, idx: number) => {
    return hasMemo ? (
      <ActiveActionButton onClick={() => openDetailPopup(idx)}>{t("list.columns.memoButton")}</ActiveActionButton>
    ) : (
      <ActionButton onClick={() => openDetailPopup(idx)}>{t("list.columns.memoButton")}</ActionButton>
    );
  };
  const onClickGrantProductBtn = (organManagerIdx: number, organManagerName: string) => {
    openGrantProduct({
      targetIdxes: [organManagerIdx],
      targetType: CustomerType.ORGAN_MANAGER,
      targetName: organManagerName,
    });
  };

  const onClickGrantCouponButton = (organManagerIdx: number, organManagerName: string) => {
    openGrantCoupon({
      targetIdx: organManagerIdx,
      targetName: organManagerName,
      customerType: CustomerType.ORGAN_MANAGER,
    });
  };

  const { deleteJoiningOrganManager } = useOrganManagerDeleter();
  const onClickDeleteButton = useCallback(
    (organManagerIdx: number) => {
      if (window.confirm(t("delete.confirmMessage"))) {
        deleteJoiningOrganManager(organManagerIdx, () => {
          alert(t("delete.resultMessage"));
          getList?.();
        });
      }
    },
    [t, deleteJoiningOrganManager, getList]
  );

  const getActionButtons = (organManager: OrganManagerListDTO): JSX.Element => {
    switch (organManager.status) {
      case OrganManagerStatus.DEFAULT:
      case OrganManagerStatus.REVOKED_SELF_PHONE_AUTHENTICATION:
        return (
          <>
            {getMemoButton(organManager.hasMemo, organManager.idx)}
            <ActionButton onClick={() => onClickGrantCouponButton(organManager.idx, organManager.name)}>
              {t("issueCoupon.couponButton")}
            </ActionButton>
            <ActionButton onClick={() => onClickGrantProductBtn(organManager.idx, organManager.name)}>
              {t("list.columns.grantableProductButton")}
            </ActionButton>
          </>
        );
      case OrganManagerStatus.BLOCKED:
        return (
          <>
            {getMemoButton(organManager.hasMemo, organManager.idx)}
            <ActiveActionButton onClick={() => unblockOrganMember(organManager.idx, getList)}>
              {t("unblock.unblockButton")}
            </ActiveActionButton>
          </>
        );
      case OrganManagerStatus.LEAVE:
        return <></>;
      case OrganManagerStatus.BEFORE_SELF_MAIL_AUTHENTICATION:
        return (
          <>
            <ActionButton onClick={() => onClickDeleteButton(organManager.idx)}>{t("delete.buttonLabel")}</ActionButton>
            <ActionButton onClick={() => resendEmail(organManager.idx)}>{t("resend.resendButton")}</ActionButton>
          </>
        );
    }
  };

  const getEmailNode = useCallback(
    (om: OrganManagerListDTO): string | JSX.Element => {
      switch (om.status) {
        case OrganManagerStatus.LEAVE:
          return "-";
        case OrganManagerStatus.BEFORE_SELF_MAIL_AUTHENTICATION:
          return om.email;
        default:
          return <LinkStyle onClick={() => openDetailPopup(om.idx)}>{om.email}</LinkStyle>;
      }
    },
    [openDetailPopup]
  );

  const getPhoneNumberNode = useCallback(
    (it: OrganManagerListDTO) => (
      <>{isLeave(it.status) || !it.isPhoneAuthentication ? "-" : `(${it.codePhone}) ${it.phoneNumber}`}</>
    ),
    [isLeave]
  );

  const columns: TableColumnData<OrganManagerListDTO>[] = [
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      sortable: true,
      getNode: (it) => <span>{timeConverter.convertToLocalTime(it.createAt)}</span>,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      sortable: false,
      getNode: (it) =>
        isBeforeSignUpCompleted(it.status) ? (
          it.name
        ) : (
          <LinkStyle onClick={() => openDetailPopup(it.idx)}>
            {isLeave(it.status) ? t("list.columns.leavedMember") : it.name}
          </LinkStyle>
        ),
      width: 104,
    },
    {
      accessor: "email",
      header: t("list.columns.id"),
      sortable: false,
      getNode: (it) => getEmailNode(it),
      width: 260,
    },
    {
      accessor: "phoneNumber",
      header: t("list.columns.phoneNumber"),
      sortable: false,
      getNode: (it) => getPhoneNumberNode(it),
    },
    {
      accessor: "status",
      header: t("list.columns.status"),
      sortable: false,
      getNode: (it) => <OrganManagerStatusComponent organManager={it} />,
    },
    {
      accessor: "voucherCount",
      header: t("list.columns.organVoucher"),
      sortable: true,
      getNode: (it) => getCountTextByStatus(it.voucherCount, it.status),
    },
    {
      accessor: "idx",
      header: t("list.columns.additionalFunctions"),
      sortable: false,
      getNode: (it) => <ActionButtonWrapper>{getActionButtons(it)}</ActionButtonWrapper>,
    },
  ];

  const columnsInCodeCreator: TableColumnData<OrganManagerListDTO>[] = [
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      sortable: true,
      getNode: (it) => <span>{timeConverter.convertToLocalTime(it.createAt)}</span>,
      width: 180,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      sortable: false,
      getNode: (it) => <>{it.name}</>,
      width: 180,
    },
    {
      accessor: "email",
      header: t("list.columns.id"),
      sortable: false,
      getNode: (it) => <>{it.email}</>,
      width: 240,
    },
    {
      accessor: "phoneNumber",
      header: t("list.columns.phoneNumber"),
      sortable: false,
      getNode: (it) => getPhoneNumberNode(it),
      width: 180,
    },
    {
      accessor: "status",
      header: t("list.columns.status"),
      sortable: false,
      getNode: (it) => <OrganManagerStatusComponent organManager={it} />,
      width: 100,
    },
    {
      accessor: "voucherCount",
      header: t("list.columns.organVoucher"),
      sortable: true,
      getNode: (it) => getCountTextByStatus(it.voucherCount, it.status),
      width: 100,
    },
  ];

  return {
    columns,
    columnsInCodeCreator,
  };
}
