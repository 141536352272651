import { useCallback } from "react";
import { callAsync } from "../../../../../../util/sliceUtil";
import {
  getOrganManagerAsync,
  organManagerVOState,
  setOrganManagerVO,
} from "../../../../../../store/organManagerSlice";
import { OrganManagerDTO, OrganManagerTranslator, OrganManagerVO } from "../../../../../../types/organManager";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/hooks";
import { useContentDetailHeaderInfoSetter } from "../../../../../../layout/content/hooks/useContentDetailHeaderInfoSetter";
import { useOrganManagerStatus } from "../../../hooks/useOrganManagerStatus";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../../../util/hooks/useUtil";

interface ReturnType {
  organManagerVO: OrganManagerVO | undefined;
  getOrganManager: (organManagerIdx: number) => Promise<OrganManagerVO>;
  setOrganManager: (it: OrganManagerVO) => void;
}

export function useOrganManagerGetter(): ReturnType {
  const { t } = useTranslation("organManager");
  const dispatch = useAppDispatch();
  const organManagerVO = useAppSelector(organManagerVOState);
  const { setCategory, setName } = useContentDetailHeaderInfoSetter();
  const { defaultErrorMessage } = useUtil();
  const { isLeave } = useOrganManagerStatus();

  const getOrganManager = useCallback(
    async (organManagerIdx: number) => {
      return callAsync(
        dispatch(getOrganManagerAsync(organManagerIdx)).unwrap(),
        (result: OrganManagerDTO) => {
          const vo = OrganManagerTranslator.createOrganManagerVO(result);
          dispatch(setOrganManagerVO(vo));
          if (isLeave(vo.status)) {
          } else {
            setCategory(t("memberCategory"));
            setName(vo.name);
          }

          return vo;
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, isLeave, setCategory, setName, t, defaultErrorMessage]
  );

  const setOrganManager = useCallback(
    (organManager: OrganManagerVO) => {
      dispatch(setOrganManagerVO(organManager));
    },
    [dispatch]
  );

  return {
    organManagerVO,
    getOrganManager,
    setOrganManager,
  };
}
