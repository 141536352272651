export const getRegexStr = (regExp: RegExp) => regExp.toString().substring(1, regExp.toString().length - 1);
//eslint-disable-next-line
export const pwdRegExp = new RegExp(/(?=.*\d)(?=.*[a-z|A-Z])(?=.*[?,./;:'!@#$%^&*~()+={}\[\]\\|_-]).{8,16}/);
export const emailRegExp = /^(?=.{6,60}$)[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneNumberInKRRegExp = new RegExp(/(?=.*\d).{11}/);
export const phoneNumberInOtherRegExp = new RegExp(/(?=.*\d).{5,15}/);
export const pwdRegexStr = getRegexStr(pwdRegExp);
export const phoneNumberInKRRegExpStr = getRegexStr(phoneNumberInKRRegExp);
export const phoneNumberInOtherRegExpStr = getRegexStr(phoneNumberInOtherRegExp);
export const getOnlyNumberText = (s: string) => s.replace(/[^0-9]/g, "");
