import { ArticleProps } from "../../docType";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { CopiableText } from "../../../components/CopiableText";
import { ArticleWrapper } from "./ArticleWrapper";
import { RequiredTextStyle } from "../../../components/Texts";

export function ArticleTexts({ id, title }: ArticleProps) {
  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={`<CopiableText text={"CopiableText"} isBlue={false}/>`}>
          <CopiableText text={"CopiableText"} isBlue={false} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<CopiableText text={"CopiableText"} isBlue={true}/>`}>
          <CopiableText text={"CopiableText"} isBlue={true} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<span className={"red"}>Text</span>`}>
          <span className={"red"}>Text</span>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<span className={"blue"}>Text</span>`}>
          <span className={"blue"}>Text</span>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<span className={"darkBlue"}>Text</span>`}>
          <span className={"darkBlue"}>Text</span>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<span className={"underline"}>Text</span>`}>
          <span className={"underline"}>Text</span>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<RequiredTextStyle>*</RequiredTextStyle>`}>
          <RequiredTextStyle>*</RequiredTextStyle>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
