import { AdminMenuCode } from "../../types/adminRole";
import { useEffect, useState } from "react";
import { useAccessibleMenus } from "../../hooks/hooks";

interface Props {
  children: JSX.Element;
  menuCode?: AdminMenuCode;
  alternativeChildren?: JSX.Element;
}

export function RoleChecker({ children, menuCode, alternativeChildren }: Props) {
  const { accessibleMenus } = useAccessibleMenus();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!menuCode) {
      setShow(true);
      return;
    }

    if (accessibleMenus) {
      const foundIdx = accessibleMenus.findIndex((menu) => menu.menu.code === menuCode) ?? -1;

      if (foundIdx > -1) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleMenus]);

  return <>{show ? children : alternativeChildren ?? <></>}</>;
}
