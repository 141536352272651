import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./index";

export interface ContentDetailState {
  name: string;
  category: string;
}

const initialState: ContentDetailState = {
  name: "",
  category: "",
};

export const contentDetailSlice = createSlice({
  name: "contentDetail",
  initialState,
  reducers: {
    setContentDetailName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setContentDetailCategory: (state, action: PayloadAction<string>) => {
      state.category = action.payload;
    },
  },
});

export default contentDetailSlice.reducer;

export const { setContentDetailCategory, setContentDetailName } = contentDetailSlice.actions;

export const contentDetailCategoryState = (state: RootState) => state.contentDetail.category;
export const contentDetailNameState = (state: RootState) => state.contentDetail.name;
