import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { Gender } from "../common";
import { SelectorOption } from "../selector";

export function useGenderType() {
  const { t } = useTranslation("common");

  const toStringFromGender = useCallback(
    (gender: Gender | null) => {
      if (gender) {
        return gender === Gender.MALE ? t("male") : t("female");
      } else {
        return "-";
      }
    },
    [t]
  );

  const getOptions = useCallback(
    (defaultLabel: string = t("all"), isLight: boolean = false): SelectorOption[] => [
      { value: "", label: defaultLabel, isLight },
      { value: Gender.MALE, label: toStringFromGender(Gender.MALE) },
      { value: Gender.FEMALE, label: toStringFromGender(Gender.FEMALE) },
    ],
    [toStringFromGender, t]
  );

  return {
    toStringFromGender,
    getOptions,
  };
}
