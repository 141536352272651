import { useListFilterValues, useTimeConverter } from "../../../../hooks/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSortParam } from "../../../../hooks/list/useSortParam";
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SORT,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../types/page";
import { AffiliateCodeListQueryType } from "./useAffiliateCodeFilterValues";
import { dateToString } from "../../../../util/dateUtil";
import { hasBothValuesOrBothNoValue, isFromNumberMoreThanToNumber } from "../../../../util/etcUtil";
import { useUtil } from "../../../../util/hooks/useUtil";
import {
  AffiliateCodeListVO,
  AffiliateCodeSearchCondition,
  AffiliateCodeSearchWordType,
  AffiliateCodeStatus,
} from "../../../../types/affiliateCode";
import { useAffiliateCodesGetter } from "./useAffiliateCodesGetter";

export function useAffiliateCodeList() {
  const { timeConverter } = useTimeConverter();
  const [codeList, setCodeList] = useState<PageResponse<AffiliateCodeListVO>>();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { toSortArray } = useSortParam();
  const { defaultErrorMessage } = useUtil();
  const [totalCodeCount, setTotalCodeCount] = useState<number | null>(null);
  const { getAffiliateCodes } = useAffiliateCodesGetter();
  const pageCondition = useMemo(
    () => ({
      page: codeList?.number ?? DEFAULT_PAGE,
      size: codeList?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(codeList?.orders),
    }),
    [codeList]
  );

  const {
    searchWordTypeFilterValue,
    searchWordFilterValue,
    availableStartAtFilterValue,
    availableEndAtFilterValue,
    paidCountFromFilterValue,
    paidCountToFilterValue,
    statusFilterValue,
  } = filterValues;

  const searchCodeList = (pageSearchCondition: PageSearchCondition) => {
    const param: AffiliateCodeListQueryType = {
      searchWordType: searchWordTypeFilterValue.value,
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      availableStartAt: availableStartAtFilterValue.value ? dateToString(availableStartAtFilterValue.value) : undefined,
      availableEndAt: availableEndAtFilterValue.value ? dateToString(availableEndAtFilterValue.value) : undefined,
      paidCountFrom: paidCountFromFilterValue.value ? paidCountFromFilterValue.value : undefined,
      paidCountTo: paidCountToFilterValue.value ? paidCountToFilterValue.value : undefined,
      status: statusFilterValue.value ? statusFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.availableStartAt, param.availableEndAt)) {
        alert("사용기한 범위 값을 모두 입력해주세요.");
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.paidCountFrom, param.paidCountTo)) {
        alert("결제건수 범위 값을 모두 입력해주세요.");
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.paidCountFrom), Number(param.paidCountTo))) {
        alert("결제건수 범위를 올바르게 입력해주세요.");
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getCodeList = useCallback(
    async (param: AffiliateCodeSearchCondition, isFirstLoad?: boolean) => {
      try {
        const result: PageResponse<AffiliateCodeListVO> = await getAffiliateCodes(param);
        if (isFirstLoad) {
          setTotalCodeCount(result.totalElements);
        } else {
          setCodeList(result);
        }
      } catch (e) {
        alert(defaultErrorMessage);
      }
    },
    [getAffiliateCodes, defaultErrorMessage]
  );

  const getCodeListWithParam = useCallback(() => {
    const codeListQueryType = listQueryType as AffiliateCodeListQueryType;
    getCodeList({
      pageCondition: {
        page: codeListQueryType.page ? Number(codeListQueryType.page) : DEFAULT_PAGE,
        size: codeListQueryType.size ? Number(codeListQueryType.size) : DEFAULT_SIZE,
        sort: codeListQueryType.sort ? toSortArray(codeListQueryType.sort) : DEFAULT_SORT,
      },
      q: codeListQueryType.searchWord?.trim(),
      qtype: codeListQueryType.searchWordType
        ? (codeListQueryType.searchWordType as AffiliateCodeSearchWordType)
        : undefined,
      availableStartAt: codeListQueryType.availableStartAt
        ? timeConverter.convertToFilterStartOfDay(codeListQueryType.availableStartAt)
        : undefined,
      availableEndAt: codeListQueryType.availableEndAt
        ? timeConverter.convertToFilterEndOfDay(codeListQueryType.availableEndAt)
        : undefined,
      paymentsCountFrom: codeListQueryType.paidCountFrom ? Number(codeListQueryType.paidCountFrom) : undefined,
      paymentsCountTo: codeListQueryType.paidCountTo ? Number(codeListQueryType.paidCountTo) : undefined,
      status: codeListQueryType.status ? (codeListQueryType.status as AffiliateCodeStatus) : undefined,
    }).then();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType]);

  useEffect(() => {
    getCodeList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      getCodeListWithParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCodeListWithParam]);

  return {
    codeList,
    pageCondition,
    totalCodeCount,
    getCodeListWithParam,
    searchCodeList,
  };
}
