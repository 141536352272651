import { useCallback } from "react";
import { AddedFile, FileType } from "../../../../types/file";
import { PartnerCompanyType } from "../../../../types/partnerCompany";
import { Address } from "../../../../types/user";
import { AffiliateCodeListVO, AffiliateCodeStatus } from "../../../../types/affiliateCode";

interface Args {
  name: string;
  logo: AddedFile | undefined;
  addedLogo: FileType | undefined;
  type: PartnerCompanyType;
  address: Address;
  affiliateCodeIdx?: number | null;
  defaultAffiliateCode?: AffiliateCodeListVO | null;
}

const NAME_MAX_LENGTH = 50;
const IMAGE_MAX_SIZE = 10000000;

export function usePartnerCompanyValidator({
  name,
  logo,
  addedLogo,
  type,
  address,
  affiliateCodeIdx,
  defaultAffiliateCode,
}: Args) {
  const validate = useCallback(
    (confirmMessage: string) => {
      const totalNameLength = name
        .trim()
        .split("")
        .reduce((acc, char) => {
          const enLowercaseRegExp = /[a-z]/;
          const numRegExp = /[0-9]/;
          const spaceRegExp = /\s/;

          const isHalfChar = enLowercaseRegExp.test(char) || numRegExp.test(char) || spaceRegExp.test(char);
          if (isHalfChar) return acc + 1;
          else return acc + 2;
        }, 0);

      if (totalNameLength > NAME_MAX_LENGTH) {
        alert("협력사명이 너무 깁니다.\n※ 띄어쓰기 특수문자 포함, 최대 영문 50자, 한글25자");
        return false;
      }

      if (!logo && !addedLogo) {
        alert("로고 이미지 파일을 업로드해 주세요.");
        return false;
      }

      if (addedLogo && addedLogo.file.size > IMAGE_MAX_SIZE) {
        alert("이미지 용량이 너무 큽니다.\n최대 10Mb 이미지로 탑재해 주세요. 권장 크기는 1Mb 이하 입니다.");
        return;
      }

      const isDomestic = type === PartnerCompanyType.DOMESTIC;

      if (isDomestic && !address.address1) {
        alert("협력사 기본주소를 입력하세요.");
        return false;
      }

      if (
        isDomestic &&
        defaultAffiliateCode &&
        defaultAffiliateCode.status === AffiliateCodeStatus.DELETED &&
        defaultAffiliateCode.idx === affiliateCodeIdx
      ) {
        return window.confirm(
          "연결된 제휴코드가 삭제되어 있습니다.\n제휴코드를 수정하지 않고 협력사 정보를 수정 하시겠습니까?"
        );
      } else {
        return window.confirm(confirmMessage);
      }
    },
    [address.address1, logo, addedLogo, name, type, affiliateCodeIdx, defaultAffiliateCode]
  );

  return {
    validate,
  };
}
