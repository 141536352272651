import { NationalityContext } from "./NationalityContext";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAuth } from "../hooks";
import { getAccessTokenWithNationalityAsync } from "../../store/accountSlice";
import { CookieKeys, useCookie } from "../auth/useCookie";

export const DEFAULT_NATIONALITY = "KR";
export function NationalityProvider({ children }: { children: JSX.Element }) {
  const dispatch = useAppDispatch();
  const [nationality, setNationality] = useState<string | undefined>(undefined);

  const { getRefreshToken, setAccessToken } = useAuth();
  const { getValueFromCookie, setCookie } = useCookie();

  useEffect(() => {
    const savedNationality = getValueFromCookie(CookieKeys.NATIONALITY);
    setNationality(savedNationality ? savedNationality : DEFAULT_NATIONALITY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeNationality = useCallback(
    async (nationality: string) => {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        const { accessToken } = await dispatch(
          getAccessTokenWithNationalityAsync({ nationality, refreshToken })
        ).unwrap();
        setAccessToken(accessToken);
        setNationality(nationality);
      } else {
        console.error("no refreshToken");
      }
    },
    [getRefreshToken, dispatch, setAccessToken]
  );

  useEffect(() => {
    if (nationality) {
      setCookie(CookieKeys.NATIONALITY, nationality);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationality]);

  const isKorean = useMemo(() => nationality === "KR", [nationality]);

  return (
    <NationalityContext.Provider
      value={{
        nationality: nationality ?? "",
        setNationality: changeNationality,
        isKorean,
      }}
    >
      {children}
    </NationalityContext.Provider>
  );
}
