import excelIcon from "../../../assets/images/button_icons/excel_icon.svg";
import { DetailActionButton } from "../../../components/Buttons";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import { OptionItem, Options } from "../../../components/SelectorStyle";
import { useClickEvent } from "../../../hooks/hooks";
import { CLICK_DOCUMENT_EVENT } from "../../../util/events";
import { useTranslation } from "react-i18next";

interface Props {
  onClickAllDownloadButton: () => void;
  onClickSelectedItemsDownloadButton: () => void;
}

const ButtonWrapperStyle = styled.div`
  position: relative;
`;

const OptionsStyle = styled(Options)`
  width: max-content;
  left: auto;
  right: 0;
`;

const EXCEL_BUTTON = "excelButton";
export function ExcelDownloadButton({ onClickAllDownloadButton, onClickSelectedItemsDownloadButton }: Props) {
  const { t } = useTranslation("common");
  const [showOptions, setShowOptions] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { subscribe, unsubscribe } = useClickEvent();

  useEffect(() => {
    const clickDocumentListener = (e: { detail: any }) => {
      if (showOptions && e.detail.closest(`button[data-name='${EXCEL_BUTTON}']`) !== buttonRef.current) {
        setShowOptions(false);
      }
    };

    subscribe(CLICK_DOCUMENT_EVENT, clickDocumentListener);

    return () => {
      unsubscribe(CLICK_DOCUMENT_EVENT, clickDocumentListener);
    };
  }, [showOptions, subscribe, unsubscribe]);

  return (
    <ButtonWrapperStyle>
      <DetailActionButton data-name={EXCEL_BUTTON} onClick={() => setShowOptions(!showOptions)} ref={buttonRef}>
        <img src={excelIcon} alt={"excel download"} />
        {t("downloadExcel.buttonTitle")}
      </DetailActionButton>
      <OptionsStyle
        className={showOptions ? "" : "hide"}
        style={{ top: `${(buttonRef.current?.getBoundingClientRect().height ?? 0) - 1}px` }}
      >
        <OptionItem onClick={onClickAllDownloadButton}>{t("downloadExcel.downloadAll")}</OptionItem>
        <OptionItem onClick={onClickSelectedItemsDownloadButton}>{t("downloadExcel.downloadSelected")}</OptionItem>
      </OptionsStyle>
    </ButtonWrapperStyle>
  );
}
