import { FilterNumber } from "../../../layout/content/list/filter/FilterNumber";
import { FilterContentType } from "../../../types/tableFilter";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import style from "../../../layout/content/post/creator.module.scss";
import { useLanguage } from "../../../hooks/hooks";

interface Props {
  availableDate: string;
  setAvailableDate: (_: string) => void;
}

export function GrantedDateField({ availableDate, setAvailableDate }: Props) {
  const { t } = useTranslation(["coupon", "common"]);
  const { isKorean } = useLanguage();

  return (
    <>
      <Trans
        t={t}
        i18nKey={"creator.grantedDate.availableDuration"}
        components={[
          <FilterNumber
            className={`${style.availableDaysField} ${isKorean ? "" : style.notKO}`}
            numberField={{
              type: FilterContentType.NUMBER,
              value: availableDate,
              onChangeFunc: (s) => setAvailableDate(s),
              placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
              isRequired: true,
            }}
          />,
        ]}
      />
    </>
  );
}
