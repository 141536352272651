import style from "../productItemModal.module.scss";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

interface Props {
    password: string;
    confirmPassword: string;
    setConfirmPassword: (_: string) => void;
}

export function OrganManagerConfirmPasswordField({password, confirmPassword, setConfirmPassword}: Props) {
    const {t} = useTranslation("organManager");
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [isPasswordEqual, setIsPasswordEqual] = useState(false);

    useEffect(() => {
        checkPassword();
        //eslint-disable-next-line
    }, [password, confirmPassword]);

    function checkPassword() {
        setIsPasswordEqual(confirmPassword === password)
    }


    return (
        <div className={style.fieldLine}>
            <input
                minLength={8}
                maxLength={16}
                className={style.inputField}
                value={confirmPassword}
                required={true}
                type={"password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder={t("registration.validator.confirmPasswordPlaceholder")}
                onKeyUp={(e) => {
                    if (e.getModifierState("CapsLock")) {
                        setIsCapsLockOn(true);
                    } else {
                        setIsCapsLockOn(false);
                    }
                }}
            />
            {isCapsLockOn && <div className={style.capsLockMessage}>{t("registration.capsLockOn")}</div>}
            {confirmPassword.length > 0 && (
                <>
                    {isPasswordEqual
                        ? <div className={style.correctMessage}>{t("registration.validator.passwordEqual")}</div>
                        : <div className={style.errorMessage}>{t("registration.validator.passwordUnEqual")}</div>
                    }
                </>
            )}
        </div>
    )
}