import style from "../productItemModal.module.scss";
import {SearchField} from "../../../../layout/content/detail/SearchField";
import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {useOrgansGetter} from "../../../member/organmanager/detail/organ/hooks/useOrgansGetter";
import {OrganManagerIdValidStatus} from "../../../../types/organ";

interface Props {
    id: string;
    setId: (_: string) => void;
    setIdValid: (_: OrganManagerIdValidStatus) => void;
    idValid: OrganManagerIdValidStatus;
}

export function OrganManagerIdField({id, setId, idValid, setIdValid}: Props) {
    const {t} = useTranslation("organManager");
    const {checkEmail} = useOrgansGetter(0);

    const checkWhetherDuplicatedNameIs = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();

            try {
                const result = await checkEmail(id);
                const status = !result.result ? OrganManagerIdValidStatus.VALID : OrganManagerIdValidStatus.DUPLICATED;
                setIdValid(status);
            } catch (error) {
                setIdValid(OrganManagerIdValidStatus.WRONG_TYPE)
                console.error('Error occurred while checking email:', error);
            }

            if (!id) {
                return;
            }
        },
        //eslint-disable-next-line
        [checkEmail]
    );

    const idValidationMessage = () => {
        switch (idValid) {
            case OrganManagerIdValidStatus.UNCHECKED:
                return <div className={style.errorMessage}>{t("registration.validator.unCheckedId")}</div>;
            case OrganManagerIdValidStatus.DUPLICATED:
                return <div className={style.errorMessage}>{t("registration.validator.duplicatedId")}</div>;
            case OrganManagerIdValidStatus.WRONG_TYPE:
                return <div className={style.errorMessage}>{t("registration.validator.inValidId")}</div>;
            default:
                return <div className={style.correctMessage}>{t("registration.validator.valid")}</div>;
        }
    };

    return (
        <div className={style.fieldLine}>
            <SearchField value={id} onKeyUpFunc={() => {
            }}
                         minLength={6}
                         maxLength={60}
                         buttonText={t("registration.uniqueCheck")}
                         isRequired={true}
                         placeholder={t("registration.validator.idPlaceholder")}
                         onChangeFunc={(e) => {
                             setId((e.target as HTMLInputElement).value);
                             setIdValid(OrganManagerIdValidStatus.NONE);
                         }}
                         onClickSearchButtonFunc={checkWhetherDuplicatedNameIs}
                         className={style.nameField}
            />
            {id.length > 0 && idValid !== OrganManagerIdValidStatus.NONE && idValidationMessage()}
        </div>
    )
}