import { Panel } from "../../../../../layout/content/detail/Panel";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../../hooks/hooks";
import { orderStatus } from "../../../../../store/orderSlice";
import { useLoader } from "../../../../../hooks/common/useLoader";
import useMemberOrderColumns from "../../../components/order/hooks/useMemberOrderColumns";
import { Table } from "../../../../../layout/content/list/table/Table";
import { Pagination } from "../../../../../layout/content/list/Pagination";
import { useTranslation } from "react-i18next";
import { useOrganManagerCodeOrderList } from "./useOrganManagerCodeOrderList";
import { ExcelDownloadButton } from "../../../../../layout/content/selector/ExcelDownloadButton";
import {
  InfoAboveListStrongTextStyle,
  InfoAboveListStyle,
  InfoAboveListTextStyle,
  PaginationWrapperStyle,
} from "../../../../../components/styled/ListInDetailStyle";
import { useOrderDownloader } from "../../../../order/hooks/useOrderDownloader";

interface Props {
  organManagerIdx: number;
  selectedCode: string;
  year: number;
  month: number;
}

export function OrganManagerAffiliateCodeOrderList({ organManagerIdx, selectedCode, year, month }: Props) {
  const { t } = useTranslation("common");
  const status = useAppSelector(orderStatus);
  useLoader({ status });

  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  const { codeOrdersColumn } = useMemberOrderColumns();
  const { getOrderList, list, getListParameters } = useOrganManagerCodeOrderList();
  const { onClickDownloadSelectedOrders, downloadAllOrders } = useOrderDownloader();

  const onClickDownloadAll = useCallback(() => {
    downloadAllOrders(
      getListParameters({
        code: selectedCode,
        codeHolderOrganManagerIdx: organManagerIdx,
        year,
        month,
      })
    );
  }, [downloadAllOrders, getListParameters, month, organManagerIdx, selectedCode, year]);

  useEffect(() => {
    getOrderList({
      page: 0,
      codeHolderOrganManagerIdx: organManagerIdx,
      code: selectedCode,
      year,
      month,
    }).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCode, organManagerIdx, year, month]);

  return (
    <Panel title={"제휴결제내역"} subtitle={`(${selectedCode ? selectedCode : "전체"})`}>
      <>
        <InfoAboveListStyle>
          <InfoAboveListTextStyle>
            총 결제내역 <InfoAboveListStrongTextStyle>{list?.totalElements ?? 0}</InfoAboveListStrongTextStyle>건
          </InfoAboveListTextStyle>
          <div>
            <ExcelDownloadButton
              onClickAllDownloadButton={() => onClickDownloadAll()}
              onClickSelectedItemsDownloadButton={() => onClickDownloadSelectedOrders(checkedIdxes)}
            />
          </div>
        </InfoAboveListStyle>
        {list && (
          <>
            <Table
              listData={list}
              columns={codeOrdersColumn}
              getList={({ page }) =>
                () => {
                  getOrderList({
                    page,
                    code: selectedCode,
                    year,
                    month,
                    codeHolderOrganManagerIdx: organManagerIdx,
                  }).then();
                }}
              checkedRowIdxArr={checkedIdxes}
              setCheckedRowIdxArr={setCheckedIdxes}
              noDataText={t("noSearchResult")}
            />
            <PaginationWrapperStyle>
              <Pagination
                page={list}
                goSpecificPage={(page: number) => {
                  getOrderList({
                    page,
                    code: selectedCode,
                    year,
                    month,
                    codeHolderOrganManagerIdx: organManagerIdx,
                  }).then();
                  setCheckedIdxes([]);
                }}
              />
            </PaginationWrapperStyle>
          </>
        )}
      </>
    </Panel>
  );
}
