import { useCallback } from "react";
import { callAsync } from "../../../../../../util/sliceUtil";
import { useAppDispatch } from "../../../../../../hooks/hooks";
import { kickOutMembersAsync } from "../../../../../../store/organMemberSlice";
import { useUtil } from "../../../../../../util/hooks/useUtil";

export function useKickOutOrganMembers() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return useCallback(
    (organIdx: number, organMembersIdxes: number[]): Promise<void> =>
      callAsync<void>(
        dispatch(kickOutMembersAsync({ organIdx, memberIdxes: organMembersIdxes })).unwrap(),
        () => {},
        () => alert(defaultErrorMessage)
      ).then(),
    [dispatch, defaultErrorMessage]
  );
}
