import { useCallback, useState } from "react";
import { useBlocker } from "./useBlocker";
import { Transition } from "history";
import { useLocation } from "react-router-dom";

export const useCallbackPrompt = (when: boolean): [boolean, () => void, () => void] => {
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [blockedLocation, setBlockedLocation] = useState<Transition | null>(null);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setBlockedLocation(null);
  }, []);

  const blocker = useCallback(
    (tx: Transition) => {
      if (when && tx.location.pathname !== location.pathname) {
        setBlockedLocation(tx);
        setShowPrompt(true);
      } else {
        setBlockedLocation(null);
        setShowPrompt(false);
      }
    },
    [location, when]
  );

  const confirmNavigation = useCallback(() => {
    if (blockedLocation) {
      blockedLocation.retry();
      cancelNavigation(); // 클린업
    }
  }, [blockedLocation, cancelNavigation]);

  useBlocker(blocker, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
};
