import React, { useCallback } from "react";
import { OrganManagerOrganDTO } from "../../../../../../types/organ";
import { TableColumnData } from "../../../../../../types/common";
import { ActionButton } from "../../../../../../components/Buttons";
import { LinkStyle } from "../../../../../../components/styled/LinkStyle";
import { useNavigate } from "react-router-dom";
import { useTimeConverter } from "../../../../../../hooks/hooks";
import { useTranslation } from "react-i18next";

export function useOrganManagerOrganColumns(organManagerIdx: number): TableColumnData<OrganManagerOrganDTO>[] {
  const { t } = useTranslation("organManager");
  const { timeConverter } = useTimeConverter();
  const navigate = useNavigate();
  const openOrganDetail = useCallback(
    (organManagerIdx: number, organIdx: number) =>
      navigate(`/detail/member/organ-manager/${organManagerIdx}?tab=ORGAN&organ=${organIdx}`),
    [navigate]
  );

  return [
    {
      accessor: "createAt",
      header: t("detail.organ.createdAt"),
      sortable: true,
      getNode: (it) => <span>{timeConverter.convertToLocalTime(it.createAt)}</span>,
    },
    {
      accessor: "organName",
      header: t("detail.organ.organName"),
      sortable: false,
      getNode: (it) => <LinkStyle onClick={() => openOrganDetail(organManagerIdx, it.idx)}>{it.organName}</LinkStyle>,
    },
    {
      accessor: "codePiece",
      header: t("detail.organ.code"),
      sortable: false,
      getNode: (it) => <span>{it.codePiece}</span>,
    },
    {
      accessor: "memberCount",
      header: t("detail.organ.organMember"),
      sortable: false,
      getNode: (it) => <span>{it.memberCount}</span>,
    },
    {
      accessor: "testCompletionCount",
      header: t("detail.organ.testCompletion"),
      sortable: false,
      getNode: (it) => <span>{it.testCompletionCount}</span>,
    },
    {
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (it) => (
        <ActionButton onClick={() => openOrganDetail(organManagerIdx, it.idx)}>
          {t("detail.organ.seeMore")}
        </ActionButton>
      ),
    },
  ];
}
