import { useMemo } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition, SearchCondition } from "../../../../../types/page";
import { callAsync } from "../../../../../util/sliceUtil";
import { getUserProfilesByUserAsync, setUserProfiles, userProfilesState } from "../../../../../store/userProfileSlice";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { AdminUserProfileTesterListObj } from "../../../../../types/userProfile";
import { useUtil } from "../../../../../util/hooks/useUtil";

interface Args {
  userIdx: number;
}

export function useUserTesterList({ userIdx }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const profileList = useAppSelector(userProfilesState);

  const pageCondition = useMemo(
    () => ({
      page: profileList?.number ?? 0,
      size: profileList?.size ?? 5,
      sort: ordersToSortArr(profileList?.orders),
    }),
    [profileList]
  );

  const getUserProfiles =
    ({ page, sort, size }: PageSearchCondition) =>
    () => {
      const param: SearchCondition = {
        pageCondition: {
          page: page ?? 0,
          size: size ?? pageCondition.size,
          sort: sort ?? pageCondition.sort,
        },
      };

      callAsync(
        dispatch(getUserProfilesByUserAsync({ userIdx, condition: param })).unwrap(),
        (result: PageResponse<AdminUserProfileTesterListObj>) => {
          dispatch(setUserProfiles(result));
        },
        () => alert(defaultErrorMessage)
      ).then();
    };

  return {
    profileList,
    getUserProfiles,
    pageCondition,
  };
}
