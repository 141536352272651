import style from "../productEditForm.module.scss";
import React from "react";
import { Label104x46 } from "../../../../components/Label";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  setName: (_: string) => void;
}

export function ProductEditName({ name, setName }: Props) {
  const { t } = useTranslation("product");
  return (
    <div className={style.field}>
      <Label104x46 className={style.label} text={t("edit.name.title")} />
      <input
        className={style.inputField}
        type={"text"}
        value={name}
        required={true}
        onChange={(e) => setName(e.target.value)}
        placeholder={t("edit.name.placeholder")}
        minLength={2}
        maxLength={255}
        onKeyDown={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
      />
    </div>
  );
}
