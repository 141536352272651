import { useAppDispatch } from "../../hooks/hooks";
import { useCallback, useState } from "react";
import { getOrganDetailAsync } from "../../store/organSlice";
import { OrganDTO } from "../../types/organ";

interface ReturnType {
  getOrganDetail: (organIdx: number) => Promise<OrganDTO>;
  organDetail: OrganDTO | undefined;
}

export function useOrganGetter(): ReturnType {
  const dispatch = useAppDispatch();
  const [organDetail, setOrganDetail] = useState<OrganDTO>();

  const getOrganDetail = useCallback(
    (organIdx: number) => {
      return dispatch(getOrganDetailAsync(organIdx))
        .unwrap()
        .then((result) => {
          setOrganDetail(result);
          return result;
        });
    },
    [dispatch]
  );

  return {
    getOrganDetail,
    organDetail,
  };
}
