import { MainApi } from "./MainApi";
import { PopupPostParam, PopupPutDTO, PopupSearchCondition, PopupStatusUpdateParam } from "../types/popup";

export class PopupApi {
  static baseUrl = `${MainApi.urlPrefix}/catalog/pop-ups`;

  static createPopup = (param: PopupPostParam) => () => MainApi.api.post(`${PopupApi.baseUrl}`, param);

  static getPopups = (condition: PopupSearchCondition) => () =>
    MainApi.api.get(`${PopupApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static deletePopups = (idxes: number[]) => () => MainApi.api.delete(`${PopupApi.baseUrl}/${idxes.join(",")}`);

  static getPopup = (idx: number) => () => MainApi.api.get(`${PopupApi.baseUrl}/${idx}`);

  static updatePopupStatus = (param: PopupStatusUpdateParam) => () =>
    MainApi.api.put(`${PopupApi.baseUrl}/status`, param);

  static updatePopup = (idx: number, param: PopupPutDTO) => () => MainApi.api.put(`${PopupApi.baseUrl}/${idx}`, param);
}
