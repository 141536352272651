import { useOrganManagerFilterValues } from "./hooks/useOrganManagerFilterValues";
import { MemberOrganManager } from "./MemberOrganManager";
import { ListFilterValuesContext } from "../../../hooks/list/ListFilterValuesContext";

export function MemberOrganManagerWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useOrganManagerFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <MemberOrganManager />
    </ListFilterValuesContext.Provider>
  );
}
