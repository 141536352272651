import { DisplayPosition, ProductStatus } from "../../../types/product";
import { useCallback, useMemo, useState } from "react";
import { ListQueryType } from "../../../types/list";
import { PageSearchCondition } from "../../../types/page";
import { FilterType, FilterValue } from "../../../types/filter";
import { useFilterValues } from "../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../hooks/list/useListSearchParams";
import {Languages} from "../../../types/common";

export interface ProductListQueryType extends ListQueryType {
  searchWord?: string;
  displayPosition?: string;
  language?: string;
  status?: string;
  isCouponUsable?: string;
  sellingStartDate?: string;
  sellingEndDate?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
}

export interface ProductListFilterValues {
  searchWordFilterValue: string;
  displayPositionFilterValue: DisplayPosition | "";
  statusFilterValue: ProductStatus | "";
  isCouponUsableFilterValue: string;
  sellingStartDateFilterValue: Date | undefined;
  sellingEndDateFilterValue: Date | undefined;
  languageFilterValue: Languages | undefined;
  createdAtFromFilterValue: Date | undefined;
  createdAtToFilterValue: Date | undefined;
}

export function useProductFilterValues() {
  const [searchWord, setSearchWord] = useState("");
  const [displayPosition, setDisplayPosition] = useState<DisplayPosition | "">("");
  const [status, setStatus] = useState<ProductStatus | "">("");
  const [isCouponUsable, setIsCouponUsable] = useState<string>("");
  const [sellingStartDate, setSellingStartDate] = useState<Date | undefined>();
  const [sellingEndDate, setSellingEndDate] = useState<Date | undefined>();
  const [language, setLanguage] = useState<Languages | undefined>();
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<ProductListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "displayPosition",
        value: displayPosition,
        setter: setDisplayPosition,
        defaultValue: "",
        type: FilterType.OTHER,
      },
      {
        key: "language",
        value: language,
        setter: setLanguage,
        defaultValue: "",
        type: FilterType.OTHER,
      },
      { key: "status", value: status, setter: setStatus, defaultValue: "", type: FilterType.OTHER },
      {
        key: "isCouponUsable",
        value: isCouponUsable,
        setter: setIsCouponUsable,
        defaultValue: "",
        type: FilterType.STRING,
      },
      {
        key: "sellingStartDate",
        value: sellingStartDate,
        setter: setSellingStartDate,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "sellingEndDate",
        value: sellingEndDate,
        setter: setSellingEndDate,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
    ],
    [
      searchWord,
      displayPosition,
      status,
      isCouponUsable,
      sellingStartDate,
      sellingEndDate,
      language,
      createdAtFrom,
      createdAtTo,
    ]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () => ({
      searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
      displayPositionFilterValue: getFilterValueByKey("displayPosition") as FilterValue<DisplayPosition | "">,
      statusFilterValue: getFilterValueByKey("status") as FilterValue<ProductStatus | "">,
      isCouponUsableFilterValue: getFilterValueByKey("isCouponUsable") as FilterValue<string>,
      sellingStartDateFilterValue: getFilterValueByKey("sellingStartDate") as FilterValue<Date | undefined>,
      sellingEndDateFilterValue: getFilterValueByKey("sellingEndDate") as FilterValue<Date | undefined>,
      languageFilterValue: getFilterValueByKey("language") as FilterValue<Languages | undefined>,
      createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
      createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
    }),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: ProductListQueryType) => {
      const param: ProductListQueryType = {
        searchWord: listQueryTypeParam?.searchWord,
        displayPosition: listQueryTypeParam?.displayPosition,
        status: listQueryTypeParam?.status,
        isCouponUsable: listQueryTypeParam?.isCouponUsable,
        sellingStartDate: listQueryTypeParam?.sellingStartDate,
        sellingEndDate: listQueryTypeParam?.sellingEndDate,
        language: listQueryTypeParam?.language,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
