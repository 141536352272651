import { useMemo } from "react";
import { CookieKeys, useCookie } from "../../../../hooks/auth/useCookie";
import jwt_decode from "jwt-decode";

export function useSignedInAdminIdx(): number | null {
  const { getValueFromCookie } = useCookie();

  return useMemo(() => {
    let accessToken = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
    if (typeof accessToken == "undefined") return null;

    const { userIdx } = jwt_decode(accessToken) as { userIdx: number };
    return Number(userIdx);
  }, [getValueFromCookie]);
}
