import style from "./listFilter.module.scss";
import { Calendar, FilterContentType, NumberFilter, Select, TableFilter, Text } from "../../../types/tableFilter";
import { SelectorOption } from "../../../types/selector";
import { Selector } from "../selector/Selector";
import { FilterCalendar } from "./filter/FilterCalendar";
import { FilterCalendarRange } from "./filter/FilterCalendarRange";
import { FilterNumber } from "./filter/FilterNumber";
import { FilterNumberRange } from "./filter/FilterNumberRange";
import { FilterInputText } from "../../../components/Inputs";
import { useTranslation } from "react-i18next";
import React from "react";
import { useBlockEnter } from "../../../hooks/useBlockEnter";
import { useLanguage } from "../../../hooks/hooks";

interface Props {
  filters: TableFilter[][];
  doSearch: VoidFunction;
  isDetailPage?: boolean;
  title?: string;
}

export function ListFilter({ filters, doSearch, isDetailPage, title }: Props) {
  const { t } = useTranslation("common");
  const { blockEnterKeyEvent } = useBlockEnter();
  const { isKorean } = useLanguage();
  const SelectorComponent = ({ selector }: { selector: Select }) => (
    <Selector
      isPageSizeSelector={false}
      className={style.selectField}
      options={selector.options}
      setSelectorOption={(option: SelectorOption) => selector.onChangeFunc(option.value)}
      selectedValue={selector.value}
      width={selector.width}
    />
  );

  const getFilterNode = (tableFilter: TableFilter) => {
    switch (tableFilter.content.type) {
      case FilterContentType.SELECT:
        const selectFilter: Select = tableFilter.content;
        return (
          <Selector
            isPageSizeSelector={false}
            className={style.selectField}
            options={selectFilter.options}
            setSelectorOption={(option: SelectorOption) => {
              return selectFilter.onChangeFunc(option.value);
            }}
            selectedValue={selectFilter.value ?? ""}
            width={selectFilter.width}
          />
        );
      case FilterContentType.TEXT:
        const textFilter: Text = tableFilter.content;
        return (
          <>
            {textFilter.typeSelector ? <SelectorComponent selector={textFilter.typeSelector} /> : <></>}
            <FilterInputText
              value={textFilter.value}
              onChange={(e) => textFilter.onChangeFunc(e.target.value)}
              maxLength={textFilter.maxLength}
              placeholder={textFilter.placeholderVal}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  textFilter.onEnterKeyUpFunc?.(e.target.value.trim());
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onKeyDown={blockEnterKeyEvent}
              onBlur={(e) => textFilter.onChangeFunc(e.target.value.trim())}
            />
          </>
        );
      case FilterContentType.NUMBER_RANGE:
        const numberFilterFrom: NumberFilter = tableFilter.content.from;
        const numberFilterTo: NumberFilter = tableFilter.content.to;
        return (
          <>
            <FilterNumberRange from={numberFilterFrom} to={numberFilterTo} />
          </>
        );
      case FilterContentType.NUMBER:
        return (
          <>
            <FilterNumber numberField={tableFilter.content} />
          </>
        );
      case FilterContentType.CALENDAR:
        return (
          <>
            <FilterCalendar calendar={tableFilter.content} />
          </>
        );
      case FilterContentType.CALENDAR_RANGE:
        const calendarRangeFilter = tableFilter.content;
        const from: Calendar = calendarRangeFilter.from;
        const to: Calendar = calendarRangeFilter.to;
        return (
          <>
            {calendarRangeFilter.typeSelector ? (
              <SelectorComponent selector={calendarRangeFilter.typeSelector} />
            ) : (
              <></>
            )}
            <FilterCalendarRange from={from} to={to} />
          </>
        );
    }
  };

  return (
    <form
      className={`${style.searchFilter} ${isDetailPage ? style.detailPage : ""}`}
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {title ? <p className={style.filterTitle}>{title}</p> : <></>}
      <div className={style.filterWrapper}>
        <div className={style.filters}>
          {filters.map((filterLine, lineIdx) => (
            <div className={style.line} key={`filter-line-${lineIdx}`}>
              {filterLine
                .filter((filter) => filter.hideFilter !== true)
                .map((aFilter, filterIdx) => (
                  <div className={style.filterItem} key={`a-filter-${lineIdx}-${filterIdx}`}>
                    <span className={`${style.label} ${isKorean ? "" : style.notKO}`}>{aFilter.labelTitle}</span>
                    {getFilterNode(aFilter)}
                  </div>
                ))}
            </div>
          ))}
        </div>
        <button className={style.searchBtn} onClick={doSearch}>
          {t("search")}
        </button>
      </div>
    </form>
  );
}
