import {useTranslation} from "react-i18next";
import {useCallback, useMemo} from "react";
import {UserStatus} from "../user";
import {AccessLocation} from "../tester";

export function useUserType() {
  const { t } = useTranslation(["user", "common"]);

  const toStringTesterApproachPosition = useCallback(
      (approached: AccessLocation) => {
        switch (approached) {
          case AccessLocation.DCAS:
            return t("approached.dcasApproached");
          default:
            return "";
        }
      }, [t]
  );

  const testerApproached = useMemo(() => {
    const list = Object.keys(AccessLocation).flatMap((key) => {
      const valueObj = AccessLocation[key as keyof typeof AccessLocation];

      if (typeof valueObj === "string" && valueObj !== 'KPASS') {
        return [
          {
            value: key,
            label: toStringTesterApproachPosition(valueObj),
          },
        ];
      } else {
        return [];
      }
    });

    list.unshift({ value: "", label: t("all", { ns: "common" }) });

    return list;
  }, [toStringTesterApproachPosition, t]);


  const toStringFromUserStatus = useCallback(
    (status: UserStatus) => {
      switch (status) {
        case UserStatus.BLOCKED:
          return t("userStatus.blocked");
        case UserStatus.DEFAULT:
          return t("userStatus.default");
        case UserStatus.LEAVE:
          return t("userStatus.leaved");
        case UserStatus.DORMANT:
          return t("userStatus.dormant");
        default:
          return "";
      }
    },
    [t]
  );

  const userStatusOptions = useMemo(() => {
    const list = Object.keys(UserStatus).flatMap((key) => {
      const valueObj = UserStatus[key as keyof typeof UserStatus];

      if (typeof valueObj === "string") {
        return [
          {
            value: key,
            label: toStringFromUserStatus(valueObj),
          },
        ];
      } else {
        return [];
      }
    });

    list.unshift({ value: "", label: t("all", { ns: "common" }) });

    return list;
  }, [toStringFromUserStatus, t]);

  return {
    toStringFromUserStatus,
    userStatusOptions,
    toStringTesterApproachPosition,
    testerApproached,
  };
}
