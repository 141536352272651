import {SearchCondition} from "./page";
import {VoucherPurchaseRestrictions} from "./voucher";
import {AdditionalResultType, TestResultFormType} from "./common";

export enum OrganManagerStatus {
  DEFAULT = "DEFAULT",
  BEFORE_SELF_MAIL_AUTHENTICATION = "BEFORE_SELF_MAIL_AUTHENTICATION",
  BLOCKED = "BLOCKED",
  LEAVE = "LEAVE",
  REVOKED_SELF_PHONE_AUTHENTICATION = "REVOKED_SELF_PHONE_AUTHENTICATION",
}

export enum QueryType {
  NAME = "NAME",
  ID = "ID",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export enum Nations{
  //todo 이거 none 리팩토링 필수
  KR = "KR",
  none = 'none',
}

export interface MenuCode{
  menuCode: MenuExposedStatus[]
}

export enum MenuExposedStatus{
  HOME_AND_ORGAN_MANAGEMENT = "HOME_AND_ORGAN_MANAGEMENT",
  PURCHASE_VOUCHER = 'PURCHASE_VOUCHER',
  AFFILIATE_CODE = 'AFFILIATE_CODE',
  PARENT_DISPOSITION_TEST = 'PARENT_DISPOSITION_TEST',
}
export interface OrganManagerPostDTO{
  nationality: string;
  email: string;
  codePhone: string;
  phoneNumber: string;
  name: string;
  password: string;
  adminOrganManagerPermissionPostDTO: AdminOrganManagerPermissionPostDTO;
}

export interface AdminOrganManagerPermissionPostDTO{
  accessibleMenuPostDTO: MenuCode;
  voucherPurchaseRestrictions: VoucherPurchaseRestrictions;
  kpassResultForm: TestResultFormType;
  kpassAdditionalResultForm: AdditionalResultType;
  dcasResultForm: TestResultFormType;
  dcasAdditionalResultForm: AdditionalResultType;
}

export interface OrganManagerPermissionDTO{
  accessibleMenuDTO: MenuCode;
  voucherPurchaseRestrictions: VoucherPurchaseRestrictions;
  kpassResultForm: TestResultFormType;
  kpassAdditionalResultForm: AdditionalResultType;
  dcasResultForm: TestResultFormType;
  dcasAdditionalResultForm: AdditionalResultType;
}


export interface OrganManagerSearchCondition extends SearchCondition {
  query?: string;
  queryType?: QueryType;
  status?: OrganManagerStatus[];
  startDateOfCreatedAt?: Date;
  endDateOfCreatedAt?: Date;
  minVoucherCount?: number;
  maxVoucherCount?: number;
  nationality?: string;
}

export interface OrganManagerListDTO {
  idx: number;
  createAt: string;
  name: string;
  email: string;
  phoneNumber: string;
  isPhoneAuthentication: boolean;
  codePhone: string;
  status: OrganManagerStatus;
  organCount: number;
  voucherCount: number;
  testCount: number;
  memberCount: number;
  hasMemo: boolean;
  reasonForBlocking: string;
  blockAt: string;
  leaveAt: string;
  nationality: string;
}

export interface OrganManagerDTO {
  idx: number;
  email: string;
  codePhone: string;
  phoneNumber: string;
  isPhoneAuthentication: boolean;
  name: string;
  status: OrganManagerStatus;
  voucherCount: number;
  isOrganManagersAgreementEmailMarketingReceipt: boolean;
  isOrganManagersAgreementSMSMarketingReceipt: boolean;
  isOrganManagersAgreementPersonalInfoCollectionAndUsage: boolean;
  lastSignInIp: string;
  lastSignInAt: string;
  blockAt: string;
  reasonForBlocking: string;
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
  createAt: string;
  nationality: string;
}

export interface OrganManagerVO {
  idx: number;
  email: string;
  codePhone: string;
  phoneNumber: string;
  isPhoneAuthentication: boolean;
  name: string;
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
  status: OrganManagerStatus;
  voucherCount: number;
  isOrganManagersAgreementEmailMarketingReceipt: boolean;
  isOrganManagersAgreementSMSMarketingReceipt: boolean;
  isOrganManagersAgreementPersonalInfoCollectionAndUsage: boolean;
  lastSignInIp: string;
  lastSignInAt: string;
  blockAt: string;
  reasonForBlocking: string;
  createAt: string;
  leaveAt: string;
  nationality: string;
  organManagerPermissionDTO:OrganManagerPermissionDTO
}

export interface OrganManagerPermissionPutDTO{
  accessibleMenuPutDTO: MenuCode;
  voucherPurchaseRestrictions: VoucherPurchaseRestrictions;
  kpassResultForm: TestResultFormType;
  kpassAdditionalResultForm: AdditionalResultType;
  dcasResultForm: TestResultFormType;
  dcasAdditionalResultForm: AdditionalResultType;
}

export enum MemberOrganManagerDetailTabType {
  SELF_INFO = "SELF_INFO",
  ORDER = "ORDER",
  COUPON_VOUCHER = "COUPON_VOUCHER",
  ORGAN = "ORGAN",
  AFFILIATE_CODE = "AFFILIATE_CODE",
}

export interface OrganManagerUpdateDTO {
  name: string;
  refundBankName?: string;
  refundAccountNumber?: string;
  refundAccountName?: string;
}

export namespace OrganManagerTranslator {
  export function createOrganManagerVO(dto: OrganManagerDTO): OrganManagerVO {
    return dto as OrganManagerVO;
  }
}
