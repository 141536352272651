import { useCallback } from "react";
import { BuyerDTO, BuyerVO } from "../../../../../types/orders";
import { getBuyerAsync } from "../../../../../store/orderSlice";
import { callAsync } from "../../../../../util/sliceUtil";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { useUtil } from "../../../../../util/hooks/useUtil";

export default function useBuyerGetter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return useCallback(
    (orderIdx: number, onSuccess: (buyerVO: BuyerVO) => void) => {
      callAsync(
        dispatch(getBuyerAsync(orderIdx)).unwrap(),
        (buyerDTO: BuyerDTO) => {
          onSuccess({
            idx: buyerDTO.idx,
            name: buyerDTO.name,
            email: buyerDTO.email,
            codePhone: buyerDTO.codePhone,
            phoneNumber: buyerDTO.phoneNumber,
          });
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );
}
