export interface FilterValue<T> {
  key: string;
  value: T;
  setter: (_: T) => void;
  type: FilterType;
  defaultValue: T;
}

export enum FilterType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  DATE = "DATE",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
  OTHER = "OTHER",
}
