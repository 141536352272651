import {useNewWindow, useTimeConverter} from "../../../../hooks/hooks";
import {usePopupType} from "../../../../types/hooks/usePopupType";
import {TableColumnData} from "../../../../types/common";
import {PopupListVO, PopupStatus} from "../../../../types/popup";
import {useMemo} from "react";
import {Anchor} from "../../../../components/Anchor";
import {ActionButton, ActiveActionButton} from "../../../../components/Buttons";

interface Args {
  showPopup: (popup: PopupListVO) => void;
  hidePopup: (popup: PopupListVO) => void;
  getList: () => void;
}

export function usePopupColumns({ showPopup, hidePopup, getList }: Args) {
  const { timeConverter } = useTimeConverter();
  const { getPopupTypeLabel, getPopupLinkOptionLabelOnList, getPopupStatusLabel, getWebsiteTypeLabel, getPopupLanguageLabel }
      = usePopupType();
  const { openPopupDetail } = useNewWindow();

  const createdAtColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "createdAt",
      header: "등록일시",
      sortable: true,
      getNode: (vo: PopupListVO) => <span>{timeConverter.convertToLocalTime(vo.createdAt)}</span>,
    }),
    [timeConverter]
  );
  const nameColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "name",
      header: "팝업명",
      sortable: false,
      getNode: (vo: PopupListVO) => <Anchor onClick={() => openPopupDetail(vo.idx, () => getList())}>{vo.name}</Anchor>,
    }),
    [openPopupDetail, getList]
  );
    const websiteType: TableColumnData<PopupListVO> = useMemo(
        () => ({
            accessor: "websiteType",
            header: "홈페이지",
            sortable: false,
            getNode: (vo: PopupListVO) => <>{getWebsiteTypeLabel(vo.websiteType)}</>,
        }),
        [getWebsiteTypeLabel]
    );
    const language: TableColumnData<PopupListVO> = useMemo(
        () => ({
            accessor: "language",
            header: "팝업 언어",
            sortable: false,
            getNode: (vo: PopupListVO) =>
                <>
                    {getPopupLanguageLabel(vo.language)}
                </>,
        }),
        [getPopupLanguageLabel]
    );
  const popupTypeColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "type",
      header: "팝업 유형",
      sortable: false,
      getNode: (vo: PopupListVO) => <>{getPopupTypeLabel(vo.type)}</>,
    }),
    [getPopupTypeLabel]
  );
  const linkOptionColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "linkOption",
      header: "링크 옵션",
      sortable: false,
      getNode: (vo: PopupListVO) => <>{!vo.imageLink ? "링크 없음" : getPopupLinkOptionLabelOnList(vo.linkOption)}</>,
    }),
    [getPopupLinkOptionLabelOnList]
  );
  const durationColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "availableStartAt",
      header: "유효기간",
      sortable: false,
      getNode: (vo: PopupListVO) => (
        <>
          {timeConverter.convertToLocalDate(vo.availableStartAt)}
          <br />~ {vo.availableEndAt ? timeConverter.convertToLocalDate(vo.availableEndAt) : "상시사용"}
        </>
      ),
    }),
    [timeConverter]
  );
  const statusColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "status",
      header: "상태",
      sortable: false,
      getNode: (vo: PopupListVO) => (
        <span className={vo.status === PopupStatus.EXPOSED ? "blue" : "red"}>{getPopupStatusLabel(vo.status)}</span>
      ),
    }),
    [getPopupStatusLabel]
  );
  const etcColumn: TableColumnData<PopupListVO> = useMemo(
    () => ({
      accessor: "idx",
      header: "관련 기능",
      sortable: false,
      getNode: (vo: PopupListVO) => (
        <>
          {vo.status === PopupStatus.EXPOSED ? (
            <ActionButton onClick={() => hidePopup(vo)}>노출중지</ActionButton>
          ) : (
            <ActiveActionButton onClick={() => showPopup(vo)}>노출재개</ActiveActionButton>
          )}
        </>
      ),
    }),
    [hidePopup, showPopup]
  );

  const columns: TableColumnData<PopupListVO>[] = useMemo(
    () => [createdAtColumn, nameColumn, websiteType, language,
        popupTypeColumn, linkOptionColumn, durationColumn, statusColumn, etcColumn],
    [createdAtColumn, nameColumn, websiteType, language,
        popupTypeColumn, linkOptionColumn, durationColumn, statusColumn, etcColumn]
  );

  return {
    columns,
  };
}
