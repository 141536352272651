import { useCallbackPrompt } from "./useCallbackPrompt";
import { useEffect } from "react";

interface Args {
  hasChangedValue: boolean;
  confirmMessage: string;
  isDataSaved: boolean;
}
export function useNavigationBlocker({ hasChangedValue, isDataSaved, confirmMessage }: Args) {
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(!isDataSaved && hasChangedValue);

  useEffect(() => {
    if (!isDataSaved && showPrompt) {
      const isConfirmed = window.confirm(confirmMessage);
      if (isConfirmed) {
        confirmNavigation();
      } else {
        cancelNavigation();
      }
    }
  }, [isDataSaved, showPrompt, confirmNavigation, cancelNavigation, confirmMessage]);
}
