import { Address, toAddressLine1, UserPutParam, UserStatus, UserVO } from "../../../../types/user";
import { DescriptionList, DescriptionValue } from "../../../../layout/content/detail/DescriptionList";
import style from "../memberUserDetail.module.scss";
import { CustomerType, Gender } from "../../../../types/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CopiableText } from "../../../../components/CopiableText";
import { BlockReasonModal } from "../../../component/modal/BlockReasonModal";
import { dateToDottedString } from "../../../../util/dateUtil";
import { SelectorOption } from "../../../../types/selector";
import { GridInputText, InputSelector } from "../../../../components/Inputs";
import { InputPhoneNumber } from "../../../../components/InputPhoneNumber";
import { PhoneNumber } from "../../../../components/PhoneNumber";
import { AdminMenuCode } from "../../../../types/adminRole";
import { CountrySelector } from "../../../../components/CountrySelector";
import {
  ChangeAddressButton,
  LightTextStyle,
  MiddleAlignWrapperStyle,
  UserDetailInnerWrapperStyle,
  UserDetailKoreaAddressInputWrapperStyle,
  UserDetailMarketingReceiptInfoStyle,
  UserDetailMarketingReceiptWrapperStyle,
} from "../components/UserSelfInfoDataStyle";
import { MemberUserDisagreeMarketingTerms } from "../MemberUserDisagreeMarketingTerms";
import { MarketingTermsCategory } from "../../../../types/terms";
import { useCountry, useLanguage, useTimeConverter } from "../../../../hooks/hooks";
import { getOnlyNumberText } from "../../../../util/regexpUtil";
import { useTermsAgreementText } from "../../../../hooks/common/useTermsAgreementText";
import { useUserDetailValues } from "./useUserDetailValues";
import { InputRefundAccount } from "../../../../components/InputRefundAccount";
import { Trans, useTranslation } from "react-i18next";
import { useGenderType } from "../../../../types/hooks/useGenderType";
import { useUserType } from "../../../../types/hooks/useUserType";

interface Args {
  user?: UserVO;
  getUserDetail: (_: number) => void;
  onUpdateUserPutParam: (userPutParam: UserPutParam) => void;
}

export function useUserSelfInfoData({ user, getUserDetail, onUpdateUserPutParam }: Args) {
  const { t } = useTranslation(["user", "common"]);
  const [showBlockedReason, setShowBlockedReason] = useState(false);
  const userProfile = useMemo(() => user?.profile, [user]);
  const { getCountryNameByCodeISOAlpha2 } = useCountry();
  const { timeConverter } = useTimeConverter();
  const { OrganListValue, OrganVoucherValue } = useUserDetailValues();
  const { toStringFromGender } = useGenderType();
  const { isKorean } = useLanguage();
  const { toStringFromUserStatus } = useUserType();
  const getOptionalValueText = (value?: string) => value ?? "-";

  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [gender, setGender] = useState<Gender | undefined>(undefined);
  const [codePhone, setCodePhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const [refundBankName, setRefundBankName] = useState("");
  const [refundAccountNumber, setRefundAccountNumber] = useState("");
  const [refundAccountName, setRefundAccountName] = useState("");

  const isKorea = useMemo(() => address?.country === "KR" || address?.country === '', [address?.country]);

  const resetData = useCallback(() => {
    setName(userProfile?.name ?? "");
    setBirthday(userProfile?.birthday?.replaceAll("-", "") ?? "");
    setGender(userProfile?.gender);
    setCodePhone(user?.codePhone ?? "");
    setPhoneNumber(user?.phoneNumber ?? "");
    setAddress(userProfile?.address ? JSON.parse(JSON.stringify(userProfile.address)) : undefined);
    setRefundBankName(user?.refundBankName ?? "");
    setRefundAccountNumber(user?.refundAccountNumber ?? "");
    setRefundAccountName(user?.refundAccountName ?? "");
  }, [user, userProfile]);

  useEffect(() => {
    resetData();
  }, [resetData]);

  useEffect(() => {
    onUpdateUserPutParam({
      name,
      codePhone,
      phoneNumber,
      gender,
      birthday,
      address: JSON.parse(
        JSON.stringify(
          address ?? {
            address1: "",
            address2: "",
            address3: "",
            city: "",
            state: "",
            country: "",
            postalCode: "",
          }
        )
      ),
      refundBankName,
      refundAccountNumber,
      refundAccountName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, codePhone, phoneNumber, gender, birthday, address, refundBankName, refundAccountNumber, refundAccountName]);

  const { toAgreementString } = useTermsAgreementText();
  const { getOptions } = useGenderType();

  const isRequiredRefundAccount = useMemo(
    () => refundBankName.length > 0 || refundAccountNumber.length > 0 || refundAccountName.length > 0,
    [refundBankName, refundAccountNumber, refundAccountName]
  );

  const OptionalText = useCallback(
    () => <LightTextStyle>({t("detail.userInfo.address.optional")})</LightTextStyle>,
    [t]
  );
  // const RequiredText = useCallback(
  //   () => <LightTextStyle>({t("detail.userInfo.address.required")})</LightTextStyle>,
  //   [t]
  // );
  const narrowPaddingStyleObject: React.CSSProperties = useMemo(
    () => ({
      paddingTop: "5px",
      paddingBottom: "5px",
    }),
    []
  );

  const getDescList = (user: UserVO): DescriptionValue[][] => [
    [
      {
        key: t("detail.userInfo.name.label"),
        value: userProfile?.name,
        editModeValue: (
          <GridInputText
            value={name}
            required={true}
            placeholder={t("detail.userInfo.name.placeholder")}
            minLength={2}
            maxLength={50}
            onChange={(e) => setName(e.target.value)}
          />
        ),
      },
      { key: t("detail.userInfo.createdAt"), value: timeConverter.convertToLocalTime(user.createAt) },
    ],
    [
      { key: t("detail.userInfo.id"), value: user.email },
      {
        key: t("detail.userInfo.status"),
        value: (
          <span
            className={`${user.status === UserStatus.BLOCKED ? style.blocked : ""}`}
            onClick={(e) => {
              e.stopPropagation();
              setShowBlockedReason(true);
            }}
          >
            {toStringFromUserStatus(user.status)}
            <BlockReasonModal
              isShow={showBlockedReason}
              callbackCloseModal={() => setShowBlockedReason(false)}
              blockAt={user.blockAt}
              reasonForBlocking={user.reasonForBlocking}
            />
          </span>
        ),
      },
    ],
    [
      {
        key: t("detail.userInfo.phoneNumber"),
        value: <PhoneNumber codePhone={user.codePhone} phoneNumber={user.phoneNumber} />,
        editModeValue: (
          <InputPhoneNumber
            codePhone={codePhone}
            phoneNumber={phoneNumber}
            onChangeCodePhone={(codePhone) => setCodePhone(codePhone)}
            onChangePhoneNumber={(phoneNumber) => setPhoneNumber(getOnlyNumberText(phoneNumber))}
          />
        ),
      },
      {
        key: t("detail.userInfo.age.label"),
        value: `${
          userProfile?.birthday ? dateToDottedString(new Date(userProfile.birthday)) : getOptionalValueText()
        } ${user.age === 0 || user.age ? `(${user.age}${t("detail.userInfo.age.ageUnit")})` : ""}`,
        editModeValue: (
          <GridInputText
            value={birthday}
            pattern="[0-9]{8}"
            title={t("detail.userInfo.age.inputTitle")}
            placeholder={"YYYYMMDD"}
            onChange={(e) => setBirthday(getOnlyNumberText(e.target.value))}
          />
        ),
      },
    ],
    [
      {
        key: t("detail.userInfo.gender.label"),
        value: `${getOptionalValueText(userProfile?.gender ? toStringFromGender(userProfile.gender) : undefined)}`,
        editModeValue: (
          <InputSelector
            isPageSizeSelector={false}
            options={getOptions(t("detail.userInfo.gender.noGender"), true)}
            setSelectorOption={(option: SelectorOption) => setGender(option.value as Gender)}
            selectedValue={gender ?? ""}
          />
        ),
      },
      {
        key: t("nationality.label", { ns: "common" }),
        value: getCountryNameByCodeISOAlpha2(user.nationality),
      },
    ],
    [
      {
        key: t("detail.userInfo.userCode"),
        value: <CopiableText text={userProfile?.userCode ?? ""} isBlue={false} />,
        menuCode: AdminMenuCode.USER_DETAIL_USER_CODE_READ,
        editModeDisabledValue: <>{userProfile?.userCode ?? ""}</>,
      },
      {
        key: t("detail.userInfo.lastLoginHistory"),
        value: (
          <>
            {timeConverter.convertToLocalTime(user.lastSignInAt)}
            <br />
            {user.lastSignInIp}
          </>
        ),
        menuCode: AdminMenuCode.USER_DETAIL_ACCESS_LOG,
      },
    ],
    [
      {
        key: t("detail.userInfo.organ"),
        value: <OrganListValue user={user} isEditMode={false} />,
        editModeDisabledValue: <OrganListValue user={user} isEditMode={true} />,
      },
      {
        key: t("detail.userInfo.organVoucher"),
        value: <OrganVoucherValue user={user} isEditMode={false} />,
        editModeDisabledValue: <OrganVoucherValue user={user} isEditMode={true} />,
      },
    ],
    [
      {
        key: t("detail.userInfo.country"),
        value: `${getOptionalValueText(
          userProfile?.address?.country ? getCountryNameByCodeISOAlpha2(userProfile.address.country) : undefined
        )}`,
        editModeValue: (
          <CountrySelector
            className={style.countrySelector}
            selectedCountryCodeISOAlpha2={address?.country ?? ""}
            setSelectedCountryCodeISOAlpha2={(code: string) =>
              setAddress({
                city: "",
                address1: "",
                country: code,
              })
            }
          />
        ),
      },
      {
        key: t("detail.userInfo.region"),
        value: `${getOptionalValueText(address?.state)}`,
      },
    ],
    [
      {
        key: t("detail.userInfo.address.label"),
        value: <>{address ? (toAddressLine1(address).trim() === "" ? "-" : toAddressLine1(address).trim()) : "-"}</>,
        editModeValue: (
          <>
            {isKorea ? (
              <UserDetailKoreaAddressInputWrapperStyle>
                <GridInputText
                  placeholder={t("detail.userInfo.address.placeholder")}
                  readOnly={true}
                  value={address ? toAddressLine1(address).trim() : ""}
                />
                <ChangeAddressButton onComplete={(address) => setAddress(address)}>
                  {t("detail.userInfo.address.search")}
                </ChangeAddressButton>
              </UserDetailKoreaAddressInputWrapperStyle>
            ) : (
              <UserDetailInnerWrapperStyle>
                <DescriptionList
                  valueClassName={style.innerDescriptionValue}
                  data={[
                    [
                      {
                        key: (
                          <Trans
                            t={t}
                            i18nKey={"detail.userInfo.address.state.label"}
                            components={[<OptionalText />]}
                          />
                        ),
                        value: (
                          <GridInputText
                            placeholder={t("detail.userInfo.address.state.placeholder")}
                            minLength={2}
                            maxLength={50}
                            value={address?.state ?? ""}
                            onChange={(e) => {
                              setAddress({
                                ...(address ?? {
                                  address1: "",
                                  country: "",
                                  city: "",
                                }),
                                state: e.target.value,
                              });
                            }}
                          />
                        ),
                        keyStyleObject: narrowPaddingStyleObject,
                      },
                    ],
                    [
                      {
                        key: (
                          <Trans
                            t={t}
                            i18nKey={"detail.userInfo.address.zipCode.label"}
                            components={[<OptionalText />]}
                          />
                        ),
                        value: (
                          <GridInputText
                            placeholder={`${t("detail.userInfo.address.zipCode.placeholder")}`}
                            minLength={2}
                            maxLength={20}
                            value={address?.postalCode ?? ""}
                            onChange={(e) => {
                              setAddress({
                                ...(address ?? {
                                  address1: "",
                                  country: "",
                                  city: "",
                                }),
                                postalCode: e.target.value,
                              });
                            }}
                          />
                        ),
                        keyStyleObject: narrowPaddingStyleObject,
                      },
                    ],
                    [
                      {
                        key: (
                          <Trans
                            t={t}
                            i18nKey={"detail.userInfo.address.address2.label"}
                            components={[<OptionalText />]}
                          />
                        ),
                        value: (
                          <GridInputText
                            placeholder={`${t("detail.userInfo.address.address2.placeholder")}`}
                            minLength={2}
                            maxLength={60}
                            value={address?.address2 ?? ""}
                            onChange={(e) => {
                              setAddress({
                                ...(address ?? {
                                  address1: "",
                                  country: "",
                                  city: "",
                                }),
                                address2: e.target.value,
                              });
                            }}
                          />
                        ),
                        keyStyleObject: narrowPaddingStyleObject,
                      },
                    ],
                  ]}
                  labelWidth={132}
                  listClassName={style.innerDescriptionList}
                />
              </UserDetailInnerWrapperStyle>
            )}
          </>
        ),
      },
      {
        key: "",
        value: <>{address?.address2 ? address.address2 : t("detail.userInfo.noValue")}</>,
        editModeValue: (
          <>
            {isKorea && address ? (
              <UserDetailInnerWrapperStyle style={{ alignItems: "flex-start" }}>
                <GridInputText
                  placeholder={t("detail.userInfo.address.address2.detailAddress")}
                  minLength={2}
                  maxLength={60}
                  value={address?.address2 ?? ""}
                  onChange={(e) => {
                    setAddress({
                      ...address,
                      address2: e.target.value,
                    });
                  }}
                />
              </UserDetailInnerWrapperStyle>
            ) : (
              <>
                <DescriptionList
                  valueClassName={style.innerDescriptionValue}
                  data={[
                    [
                      {
                        key: (
                          <Trans t={t} i18nKey={"detail.userInfo.address.city.label"} components={[<OptionalText />]} />
                        ),
                        value: (
                          <GridInputText
                            placeholder={t("detail.userInfo.address.city.placeholder")}
                            minLength={2}
                            maxLength={50}
                            value={address?.city ?? ""}
                            onChange={(e) => {
                              setAddress({
                                ...(address ?? {
                                  address1: "",
                                  country: "",
                                }),
                                city: e.target.value,
                              });
                            }}
                          />
                        ),
                        keyStyleObject: narrowPaddingStyleObject,
                      },
                    ],
                    [
                      {
                        key: (
                          <Trans
                            t={t}
                            i18nKey={"detail.userInfo.address.address1.label"}
                            components={[<OptionalText />]}
                          />
                        ),
                        value: (
                          <GridInputText
                            placeholder={t("detail.userInfo.address.address1.placeholder")}
                            minLength={2}
                            maxLength={60}
                            value={address?.address1 ?? ""}
                            onChange={(e) => {
                              setAddress({
                                ...(address ?? {
                                  city: "",
                                  country: "",
                                }),
                                address1: e.target.value,
                              });
                            }}
                          />
                        ),
                        keyStyleObject: narrowPaddingStyleObject,
                      },
                    ],
                  ]}
                  labelWidth={132}
                  listClassName={style.innerDescriptionList}
                />
              </>
            )}
          </>
        ),
      },
    ],
    [
      {
        key: t("detail.userInfo.refundAccount.label"),
        menuCode: AdminMenuCode.USER_DETAIL_REFUND_ACCOUNT_READ,
        value: (
          <MiddleAlignWrapperStyle>
            {user.refundBankName ? (
              <>
                <span>
                  {t("detail.userInfo.refundAccount.bank")}: {user.refundBankName}
                </span>
                <span> / </span>
                <span>
                  {t("detail.userInfo.refundAccount.accountNumber")}: {user.refundAccountNumber}
                </span>
                <span> / </span>
                <span>
                  {t("detail.userInfo.refundAccount.holderName")}: {user.refundAccountName}
                </span>
              </>
            ) : (
              "-"
            )}
          </MiddleAlignWrapperStyle>
        ),
        editModeValue: (
          <InputRefundAccount
            bankName={refundBankName}
            onChangeBankName={setRefundBankName}
            accountNumber={refundAccountNumber}
            onChangeAccountNumber={setRefundAccountNumber}
            accountName={refundAccountName}
            onChangeAccountName={setRefundAccountName}
            required={isRequiredRefundAccount}
          />
        ),
      },
    ],
    [
      {
        key: t("detail.userInfo.marketingTerms.label"),
        value: (
          <MiddleAlignWrapperStyle>
            {t("detail.userInfo.marketingTerms.privateInfo")}:{" "}
            {toAgreementString(user.marketingAgreement?.isCollectingPrivateInfo)} /{" "}
            {t("detail.userInfo.marketingTerms.viaEmail")}:{" "}
            {toAgreementString(user.marketingAgreement?.isReceivingViaEmail)} /{" "}
            {t("detail.userInfo.marketingTerms.viaSMS")}:{" "}
            {toAgreementString(user.marketingAgreement?.isReceivingViaSMS)}
          </MiddleAlignWrapperStyle>
        ),
        editModeValue: (
          <UserDetailMarketingReceiptWrapperStyle>
            <UserDetailMarketingReceiptInfoStyle className={isKorean ? "" : "notKO"}>
              {t("detail.userInfo.marketingTerms.privateInfo")}:{" "}
              {toAgreementString(user.marketingAgreement?.isCollectingPrivateInfo)}
              {user.marketingAgreement?.isCollectingPrivateInfo ? (
                <MemberUserDisagreeMarketingTerms
                  targetIdx={user.idx}
                  targetName={user.profile.name}
                  targetType={CustomerType.USER}
                  category={MarketingTermsCategory.COLLECTION_AND_USE_OF_PERSONAL_INFORMATION}
                  onCompletedCallback={() => {
                    getUserDetail(user.idx);
                  }}
                />
              ) : (
                <></>
              )}
            </UserDetailMarketingReceiptInfoStyle>
            <UserDetailMarketingReceiptInfoStyle className={isKorean ? "" : "notKO"}>
              {t("detail.userInfo.marketingTerms.viaEmail")}:{" "}
              {toAgreementString(user.marketingAgreement?.isReceivingViaEmail)}
              {user.marketingAgreement?.isReceivingViaEmail ? (
                <MemberUserDisagreeMarketingTerms
                  targetIdx={user.idx}
                  targetName={user.profile.name}
                  targetType={CustomerType.USER}
                  category={MarketingTermsCategory.RECEIVE_MARKETING_INFORMATION_EMAILS}
                  onCompletedCallback={() => {
                    getUserDetail(user.idx);
                  }}
                />
              ) : (
                <></>
              )}
            </UserDetailMarketingReceiptInfoStyle>
            <UserDetailMarketingReceiptInfoStyle className={isKorean ? "" : "notKO"}>
              {t("detail.userInfo.marketingTerms.viaSMS")}:{" "}
              {toAgreementString(user.marketingAgreement?.isReceivingViaSMS)}
              {user.marketingAgreement?.isReceivingViaSMS ? (
                <MemberUserDisagreeMarketingTerms
                  targetIdx={user.idx}
                  targetName={user.profile.name}
                  targetType={CustomerType.USER}
                  category={MarketingTermsCategory.RECEIVE_MARKETING_INFORMATION_SMS}
                  onCompletedCallback={() => {
                    getUserDetail(user.idx);
                  }}
                />
              ) : (
                <></>
              )}
            </UserDetailMarketingReceiptInfoStyle>
          </UserDetailMarketingReceiptWrapperStyle>
        ),
      },
    ],
  ];

  const getLeavedUserDescList = (user: UserVO): DescriptionValue[][] => [
    [
      { key: t("detail.userInfo.name.label"), value: t("detail.leavedMember.leavedMember") },
      { key: t("detail.leavedMember.memberCategoryLabel"), value: t("detail.leavedMember.memberCategoryValue") },
    ],
    [
      { key: t("detail.userInfo.createdAt"), value: timeConverter.convertToLocalTime(user.createAt) },
      { key: t("detail.leavedMember.leavedAt"), value: timeConverter.convertToLocalTime(user.leaveAt) },
    ],
    [{ key: t("list.columns.nationality"), value: getCountryNameByCodeISOAlpha2(user.nationality) }],
    [{ key: t("detail.leavedMember.reason"), value: user.reasonForLeave }],
  ];

  return {
    getDescList,
    getLeavedUserDescList,
    resetData,
  };
}
