import { useCallback, useMemo } from "react";
import { LinkStatus, PartnerCompanyStatus, PartnerCompanyType } from "../partnerCompany";

export function usePartnerCompanyType() {
  const partnerCompanyTypeLabelRouter = useMemo(
    () => ({
      [PartnerCompanyType.DOMESTIC.toString()]: "국내",
      [PartnerCompanyType.OVERSEAS.toString()]: "해외",
    }),
    []
  );
  const getPartnerCompanyTypeLabel = useCallback(
    (type: PartnerCompanyType) => partnerCompanyTypeLabelRouter[type.toString()],
    [partnerCompanyTypeLabelRouter]
  );

  const partnerCompanyStatusLabelRouter = useMemo(
    () => ({
      [PartnerCompanyStatus.EXPOSED.toString()]: "노출",
      [PartnerCompanyStatus.UNEXPOSED.toString()]: "미노출",
    }),
    []
  );
  const getPartnerCompanyStatusLabel = useCallback(
    (status: PartnerCompanyStatus) => partnerCompanyStatusLabelRouter[status.toString()],
    [partnerCompanyStatusLabelRouter]
  );

  const linkStatusLabelRouter = useMemo(
    () => ({
      [LinkStatus.LINK.toString()]: "연동",
      [LinkStatus.UNLINK.toString()]: "미연동",
      [LinkStatus.DELETED.toString()]: "삭제됨",
    }),
    []
  );
  const getLinkStatusLabel = useCallback(
    (status: LinkStatus) => linkStatusLabelRouter[status.toString()],
    [linkStatusLabelRouter]
  );

  return {
    getPartnerCompanyTypeLabel,
    getPartnerCompanyStatusLabel,
    getLinkStatusLabel,
  };
}
