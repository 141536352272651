import { AdminMenuCode } from "../../../types/adminRole";
import { useRoleChecker } from "../../../features/role/hooks/useRoleChecker";
import styled from "styled-components";
import variables from "../../../assets/scss/variable.module.scss";
import { useLanguage } from "../../../hooks/hooks";

export interface DetailTab<T> {
  tabValue: T;
  tabName: string;
  menuCode?: AdminMenuCode;
}

interface Props<T> {
  tabs: DetailTab<T>[];
  onClickTab: (_: T) => void;
  selectedTab: T;
}

const TabList = styled.ul`
  width: 100%;
  height: 58px;
  display: flex;
  background-color: ${variables.colorLightBlue};
`;

const Tab = styled.li`
  width: 114px;
  height: 58px;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.colorBodyTextBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;

  &.notKO {
    width: 150px;
    padding: 14px 12px;
    line-height: 1.3;
  }

  &.active {
    background-color: ${variables.colorFGDarkBlue};
    font-weight: bold;
    color: white;
  }
`;

export function DetailSubMenus<T>({ tabs, onClickTab, selectedTab }: Props<T>) {
  const { getRoleCheckedElement } = useRoleChecker();
  const { isKorean } = useLanguage();

  return (
    <TabList>
      {tabs.map((tab, idx) => {
        const element = (
          <Tab
            onClick={() => onClickTab(tab.tabValue)}
            className={`${selectedTab === tab.tabValue ? "active" : ""} ${isKorean ? "" : "notKO"}`}
          >
            {tab.tabName}
          </Tab>
        );

        return getRoleCheckedElement(`tab-menu-${idx}`, element, tab.menuCode);
      })}
    </TabList>
  );
}
