import { Post } from "../../layout/content/post/Post";
import React, { useCallback, useMemo, useState } from "react";
import style from "../../layout/content/post/creator.module.scss";
import { Label104x46 } from "../../components/Label";
import { PartnerNameField } from "./partner_company/PartnerNameField";
import { PartnerCompanyStatus, PartnerCompanyType } from "../../types/partnerCompany";
import { RadioGroupField } from "../../components/RadioGroupField";
import { usePartnerCompanyType } from "../../types/hooks/usePartnerCompanyType";
import { HrLine } from "../../layout/content/post/HrLine";
import { PartnerHomepageField } from "./partner_company/PartnerHomepageField";
import { PartnerAffiliateCodesField } from "./partner_company/PartnerAffiliateCodesField";
import { PartnerLogoField } from "./partner_company/PartnerLogoField";
import { AddedFile, FileType } from "../../types/file";
import { Address } from "../../types/user";
import { PartnerKoreanAddress } from "./partner_company/PartnerKoreanAddress";
import { usePartnerCompanyCreator } from "./hooks/partner/usePartnerCompanyCreator";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  isPartnerDataSavedState,
  partnerCompanyStatusState,
  setIsPartnerDataSaved,
} from "../../store/partnerCompanySlice";
import { useNavigationBlocker } from "../../hooks/navigationblocker/useNavigationBlocker";
import { usePartnerCompanyValidator } from "./hooks/partner/usePartnerCompanyValidator";
import { useLoader } from "../../hooks/common/useLoader";
import { PartnerCompanyStatusRadioField } from "./partner_company/PartnerCompanyStatusRadioField";
import { affiliateCodeStatusState } from "../../store/affiliateCodeSlice";
import { useNavigate } from "react-router-dom";

export function PartnerCompanyCreator() {
  const dispatch = useAppDispatch();
  const isPartnerDataSaved = useAppSelector(isPartnerDataSavedState);
  const partnerCompanyStatus = useAppSelector(partnerCompanyStatusState);
  const affiliateCodeStatus = useAppSelector(affiliateCodeStatusState);
  useLoader({ status: [partnerCompanyStatus, affiliateCodeStatus] });

  const [name, setName] = useState("");
  const [homepage, setHomepage] = useState("");
  const [type, setType] = useState(PartnerCompanyType.DOMESTIC);
  const [status, setStatus] = useState(PartnerCompanyStatus.UNEXPOSED);
  const [logo, setLogo] = useState<AddedFile>();
  const [addedLogo, setAddedLogo] = useState<FileType>();
  const [address, setAddress] = useState<Address>({
    address1: "",
    address2: "",
    city: "",
    country: "KR",
  });
  const [affiliateCodeIdx, setAffiliateCodeIdx] = useState<number | null>(null);
  const [hasChangedValue, setHasChangedValue] = useState(false);

  const navigate = useNavigate();

  const { getPartnerCompanyTypeLabel } = usePartnerCompanyType();
  const { createPartner } = usePartnerCompanyCreator();
  const { validate } = usePartnerCompanyValidator({
    name,
    logo,
    type,
    address,
    addedLogo,
    affiliateCodeIdx,
  });

  const onClickCreateButton = useCallback(() => {
    if (!validate("협력사를 등록 하시겠습니까?")) return;

    createPartner({
      name,
      type,
      status,
      logo: addedLogo!,
      address: { ...address, address2: address.address2?.trim() },
      webSite: homepage,
      affiliateCodeIdx: affiliateCodeIdx ?? undefined,
    }).then(() => navigate(`/affiliate-code/partner-list`));
  }, [validate, createPartner, name, type, addedLogo, address, homepage, affiliateCodeIdx, status, navigate]);

  const isDomestic = useMemo(() => type === PartnerCompanyType.DOMESTIC, [type]);

  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      if (v) {
        dispatch(setIsPartnerDataSaved(false));
        setHasChangedValue(true);
      }
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  useNavigationBlocker({
    hasChangedValue,
    isDataSaved: isPartnerDataSaved,
    confirmMessage: "입력한 협력사 정보가 모두 사라집니다.\n정말 이 페이지를 벗어나시겠습니까?",
  });

  return (
    <Post title={"협력사 등록"} buttonTitle={"협력사 등록"} buttonOnClickAction={onClickCreateButton}>
      <>
        <div className={style.fieldLine}>
          <Label104x46 text={"협력사명"} isRequired={true} />
          <PartnerNameField name={name} setName={(name) => changeValue(name, setName)} />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"협력사 유형"} isRequired={true} />
          <RadioGroupField
            value={type}
            setValue={(v) => changeValue(v, setType)}
            groupName={"partnerCompanyType"}
            isRequired={true}
            enumValues={Object.values(PartnerCompanyType)}
            getLabel={getPartnerCompanyTypeLabel}
          />
        </div>
        <div className={style.fieldLine}>
          <Label104x46 text={"로고 이미지"} isRequired={true} className={"alignSelfStart"} />
          <PartnerLogoField
            logo={logo}
            setLogo={(v) => changeValue(v, setLogo)}
            addedLogo={addedLogo}
            setAddedLogo={(v) => changeValue(v, setAddedLogo)}
          />
        </div>
        {isDomestic && (
          <>
            <div className={style.fieldLine}>
              <Label104x46 text={"협력사 주소"} isRequired={true} />
              <PartnerKoreanAddress address={address} setAddress={(v) => changeValue(v, setAddress)} />
            </div>
            <div className={style.fieldLine}>
              <Label104x46 text={"협력사 홈페이지"} isRequired={false} />
              <PartnerHomepageField homepage={homepage} setHomepage={(v) => changeValue(v, setHomepage)} />
            </div>
            <HrLine />
            <div className={`${style.fieldLine} ${style.tableLine}`}>
              <Label104x46 text={"제휴코드 연결"} isRequired={false} />
              <PartnerAffiliateCodesField
                affiliateCodeIdx={affiliateCodeIdx}
                setAffiliateCodeIdx={(v) => changeValue(v, setAffiliateCodeIdx)}
                isCreatorPage={true}
              />
            </div>
            <HrLine />
          </>
        )}
        <div className={style.fieldLine}>
          <Label104x46 text={"상태"} isRequired={true} />
          <PartnerCompanyStatusRadioField
            status={status}
            setStatus={setStatus}
            changeValue={changeValue}
            isExposureButtonBlocked={true}
          />
        </div>
      </>
    </Post>
  );
}
