import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import {
  ActionButton,
  ActiveActionButton,
  DetailActionButton,
  PrimaryButton,
  PrimaryOutlineButton,
  RedActionButton,
} from "../../../components/Buttons";
import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import personIcon from "../../../assets/images/button_icons/person_icon.svg";

export function ArticleButtons({ id, title }: ArticleProps) {
  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={`<PrimaryButton>버튼</PrimaryButton>`}>
          <PrimaryButton>버튼</PrimaryButton>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<PrimaryOutlineButton>버튼</PrimaryOutlineButton>`}>
          <PrimaryOutlineButton>버튼</PrimaryOutlineButton>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<ActionButton>버튼</ActionButton>`}>
          <ActionButton>버튼</ActionButton>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<ActiveActionButton>버튼</ActiveActionButton>`}>
          <ActiveActionButton>버튼</ActiveActionButton>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<RedActionButton>버튼</RedActionButton>`}>
          <RedActionButton>버튼</RedActionButton>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<DetailActionButton>버튼</DetailActionButton>`}>
          <DetailActionButton>버튼</DetailActionButton>
        </ListItemViewAndCode>
        <ListItemViewAndCode
          exampleCode={`
<DetailActionButton>
  <img src={personIcon} alt={"사람 아이콘"}/>
  내 정보 조회
</DetailActionButton>
      `}
        >
          <DetailActionButton>
            <img src={personIcon} alt={"사람 아이콘"} />내 정보 조회
          </DetailActionButton>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
