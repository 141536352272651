import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Label, Label104x46 } from "../../../components/Label";

export function ArticleLabel({ id, title }: ArticleProps) {
  const code = `
<Label width={"100px"} 
       height={"50px"}>레이블</Label>
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code}>
          <Label width={"100px"} height={"50px"}>
            레이블
          </Label>
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={`<Label104x46 text={"104*46"}/>`}>
          <Label104x46 text={"104*46"} />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
