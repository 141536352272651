import {useAppDispatch, useAppSelector} from "../../../../../hooks/hooks";
import {useCallback, useEffect} from "react";
import {
  getProfileOrganVoucherCurrentStatusAsync,
  organVoucherNewStatus,
  organVoucherStatusProfileIdx,
  setOrganVoucherNewStatus,
} from "../../../../../store/voucherSlice";
import {callAsync} from "../../../../../util/sliceUtil";
import {OrganVoucherNewStatus} from "../../../../../types/voucher";
import {useUtil} from "../../../../../util/hooks/useUtil";

interface ReturnType {
  getTesterOrganVoucherStatus: (profileIdx: number) => void;
  userOrganVoucherNewStatusInfo: OrganVoucherNewStatus | undefined;
}

interface Args {
  profileIdx: number;
}

export function useTesterOrganVoucherStatusGetter({ profileIdx }: Args): ReturnType {
  const dispatch = useAppDispatch();

  const prevProfileIdx = useAppSelector(organVoucherStatusProfileIdx);
  const userOrganVoucherNewStatusInfo = useAppSelector(organVoucherNewStatus);
  const { defaultErrorMessage } = useUtil();

  const getTesterOrganVoucherStatus = useCallback(
    (profileIdx: number) => {
      callAsync(
        dispatch(getProfileOrganVoucherCurrentStatusAsync(profileIdx)).unwrap(),
        (result: OrganVoucherNewStatus) => {
          dispatch(setOrganVoucherNewStatus(result));
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [defaultErrorMessage, dispatch]
  );

  useEffect(() => {
    if (profileIdx && (profileIdx !== prevProfileIdx)) {
      getTesterOrganVoucherStatus(profileIdx);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIdx]);

  return {
    getTesterOrganVoucherStatus,
    userOrganVoucherNewStatusInfo,
  };
}
