import { OrderItemVO } from "../../../../../types/orders";
import { LinkStyle } from "../../../../../components/styled/LinkStyle";
import {convertCurrencyUnit, MINI_MODAL_LINK, numberWithCommas} from "../../../../../util/etcUtil";
import React, { useCallback, useRef, useState } from "react";
import { useOrderItemPrice } from "./useOrderItemPrice";
import { MiniModal } from "../../../../../layout/modal/MiniModal";
import styled from "styled-components";
import { TableStyle } from "../../../../../components/styled/TableStyle";
import { ProductDiscountType } from "../../../../../types/product";
import { useTranslation } from "react-i18next";
import {PaymentWay} from "../../../../../types/payment";

const OrderItemDiscountPriceColumnStyle = styled.div`
  position: relative;
`;

interface Props {
  orderItemVO: OrderItemVO;
  paymentWay: PaymentWay;
}

export function OrderItemDiscountPriceColumn({ orderItemVO, paymentWay }: Props) {
  const { t } = useTranslation("order");
  const { getProductDiscountPrice, getDiscountPrice, hasDiscount } = useOrderItemPrice();
  const linkRef = useRef<HTMLSpanElement | null>(null);

  const getDiscountValueString = useCallback((orderItemVO: OrderItemVO): string => {
    if (orderItemVO.discountType === ProductDiscountType.AMOUNT)
      return `-${numberWithCommas(orderItemVO.discountValue)}`;
    else if (orderItemVO.discountType === ProductDiscountType.PERCENT) return `-${orderItemVO.discountValue}%`;
    return "";
  }, []);

  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <OrderItemDiscountPriceColumnStyle>
      {hasDiscount(orderItemVO) ? (
        <LinkStyle onClick={() => setIsShow(true)} ref={linkRef} data-name={MINI_MODAL_LINK}>
          {numberWithCommas(getDiscountPrice(orderItemVO))}
          {numberWithCommas(getDiscountPrice(orderItemVO)) === '0'
            ? ''
            : convertCurrencyUnit(paymentWay, t)}
        </LinkStyle>
      ) : (
          <>
            {numberWithCommas(getDiscountPrice(orderItemVO))}
            {numberWithCommas(getDiscountPrice(orderItemVO)) === '0'
                ? ''
                : convertCurrencyUnit(paymentWay, t)}
          </>
      )}

      <MiniModal
        show={isShow}
        closeModal={(e) => {
          e.stopPropagation();
          setIsShow(false);
        }}
        title={t("orderItem.productDiscountDetail.label")}
        modalWidth={"504px"}
        modalRightPosition={"0"}
        linkElement={linkRef.current ?? undefined}
      >
        <TableStyle>
          <thead>
            <tr>
              <th>{t("orderItem.productDiscountDetail.couponName")}</th>
              <th>{t("orderItem.productDiscountDetail.discountAmount")}</th>
            </tr>
          </thead>
          <tbody>
            {orderItemVO.couponDiscountPrice > 0 && (
              <tr>
                <td className={"left"}>{orderItemVO.couponName}</td>
                <td className={"right"}>
                  {numberWithCommas(orderItemVO.couponDiscountPrice)}{convertCurrencyUnit(paymentWay, t)}
                </td>
              </tr>
            )}
            {orderItemVO.discountType !== ProductDiscountType.NONE && (
              <tr>
                <td className={"left"}>
                  {t("orderItem.productDiscountDetail.productDiscountAmount")}({getDiscountValueString(orderItemVO)})
                </td>
                <td className={"right"}>
                  {numberWithCommas(getProductDiscountPrice(orderItemVO))}{convertCurrencyUnit(paymentWay, t)}
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td>{t("orderItem.productDiscountDetail.totalDiscountAmount")}</td>
              <td className={"right"}>
                {numberWithCommas(orderItemVO.couponDiscountPrice + getProductDiscountPrice(orderItemVO))}
                {convertCurrencyUnit(paymentWay, t)}
              </td>
            </tr>
          </tfoot>
        </TableStyle>
      </MiniModal>
    </OrderItemDiscountPriceColumnStyle>
  );
}
