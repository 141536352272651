import { usePopupType } from "../../../types/hooks/usePopupType";
import { RadioGroupField } from "../../../components/RadioGroupField";
import { PopupStatus } from "../../../types/popup";

interface Props {
  popupStatus: PopupStatus;
  setPopupStatus: (_: PopupStatus) => void;
}
export function PopupStatusField({ popupStatus, setPopupStatus }: Props) {
  const { getPopupStatusLabel } = usePopupType();
  return (
    <RadioGroupField
      enumValues={Object.values(PopupStatus)}
      getLabel={getPopupStatusLabel}
      value={popupStatus}
      setValue={setPopupStatus}
      groupName={"popupStatus"}
      isRequired={true}
    />
  );
}
