import { updateUserAsync } from "../../../../store/userSlice";
import { useCallback } from "react";
import { useAppDispatch } from "../../../../hooks/hooks";
import { UserPutParam } from "../../../../types/user";

export function useUserDetailUpdater() {
  const dispatch = useAppDispatch();

  const updateUser = useCallback(
    (idx: number, param: UserPutParam) =>
      dispatch(
        updateUserAsync({
          idx,
          userPutParam: {
            name: param.name.trim(),
            codePhone: param.codePhone.trim(),
            phoneNumber: param.phoneNumber.trim(),
            gender: param.gender,
            birthday: param.birthday?.trim(),
            address: {
              address1: param.address?.address1?.trim(),
              address2: param.address?.address2?.trim(),
              city: param.address?.city?.trim(),
              state: param.address?.state?.trim(),
              postalCode: param.address?.postalCode?.trim(),
              country: param.address?.country,
            },
            refundBankName: param.refundBankName,
            refundAccountNumber: param.refundAccountNumber?.trim(),
            refundAccountName: param.refundAccountName?.trim(),
          },
        })
      ).unwrap(),
    [dispatch]
  );

  return {
    updateUser,
  };
}
