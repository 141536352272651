import profileBar from "./profileBar.json";
import role from "./role.json";
import nav from "./nav.json";
import user from "./member/user.json";
import filter from "./filter.json";
import common from "./common.json";
import tester from "./member/tester.json";
import organManager from "./member/organManager.json";
import simpleTester from "./member/simpleTester.json";
import admin from "./member/admin.json";
import member from "./member/member.json";
import account from "./account.json";
import memo from "./memo.json";
import product from "./product.json";
import coupon from "./coupon.json";
import order from "./order.json";
import unitItem from "./unitItem.json";
import terms from "./terms.json";
import test from "./test.json";
import voucher from "./voucher.json";

const files = {
  account,
  memo,
  product,
  coupon,
  order,
  unitItem,
  profileBar,
  role,
  nav,
  user,
  filter,
  common,
  tester,
  organManager,
  admin,
  member,
  terms,
  test,
  voucher,
  simpleTester,
};

export default files;
