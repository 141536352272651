import {ListFilterValuesContext} from "../../../hooks/list/ListFilterValuesContext";
import {MemberSimpleTester} from "./MemberSimpleTester";
import {useSimpleTesterFilterValues} from "./hooks/useSimpleTesterFilterValues";

export function MemberSimpleTesterWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useSimpleTesterFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <MemberSimpleTester />
    </ListFilterValuesContext.Provider>
  );
}
