import style from "../../../layout/content/post/creator.module.scss";
import { CouponType } from "../../../types/coupon";
import React from "react";
import { Radio, RadioGroup } from "../../../components/RadioGroup";
import { useTranslation } from "react-i18next";

interface Props {
  couponType: CouponType;
  setCouponType: (_: CouponType) => void;
}

export function CouponTypeRadioField({ couponType, setCouponType }: Props) {
  const { t } = useTranslation("coupon");
  const radios: Radio<CouponType>[] = [
    {
      check: (type: CouponType) => type === CouponType.TOTAL_AMOUNT_DISCOUNT,
      onClickFunc: () => setCouponType(CouponType.TOTAL_AMOUNT_DISCOUNT),
      text: t("creator.type.discountPaidAmount"),
    },
    {
      check: (type: CouponType) => type === CouponType.PRODUCT_DISCOUNT,
      onClickFunc: () => setCouponType(CouponType.PRODUCT_DISCOUNT),
      text: t("creator.type.discountProductAmount"),
    },
  ];

  return (
    <div className={style.radioGroup}>
      <RadioGroup groupName={"couponType"} isRequired={true} radios={radios} selectedValue={couponType} />
    </div>
  );
}
