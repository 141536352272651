import { TableColumnData } from "../../../types/common";
import { UnitItemListObj } from "../../../types/unitItem";
import { ActionButton } from "../../../components/Buttons";
import { useNewWindow, useTimeConverter } from "../../../hooks/hooks";
import { Trans, useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useUnitItemType } from "../../../types/hooks/useUnitItemType";
import { UnitItemValidDurationFrom } from "../unit_item/UnitItemValidDurationFrom";

interface Args {
  canBeDeleted: (ui: UnitItemListObj) => boolean;
}

export function useUnitItemColumns({ canBeDeleted }: Args) {
  const { t } = useTranslation("unitItem");
  const { openUnitItemProductsInfo } = useNewWindow();
  const { timeConverter } = useTimeConverter();

  const { toStringFromUnitItemType } = useUnitItemType();
  const ValidDurationDays = useCallback(({ duration }: { duration: number }) => <>{duration}</>, []);

  const columns: TableColumnData<UnitItemListObj>[] = [
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      sortable: true,
      getNode: (ui) => <>{timeConverter.convertToLocalTime(ui.createAt)}</>,
    },
    {
      accessor: "type",
      header: t("list.columns.type"),
      sortable: false,
      getNode: (ui) => <>{toStringFromUnitItemType(ui.type)}</>,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      sortable: false,
      isLeftAlign: true,
      width: 368,
    },
    {
      accessor: "validDuration",
      header: t("list.columns.validDuration.label"),
      sortable: false,
      getNode: (ui) => (
        <Trans
          t={t}
          i18nKey={"list.columns.validDuration.validDurationValue"}
          components={[
            <UnitItemValidDurationFrom from={ui.validDurationFrom} />,
            <ValidDurationDays duration={ui.validDuration} />,
          ]}
        />
      ),
    },
    {
      accessor: "productItemCnt",
      header: t("list.columns.product"),
      sortable: true,
      getNode: (ui) => <>{canBeDeleted(ui) ? "-" : ui.productItemCnt}</>,
    },
    {
      accessor: "salesCnt",
      header: t("list.columns.additionalFunctions"),
      sortable: false,
      getNode: (ui) => (
        <>
          {ui.productItemCnt > 0 ? (
            <ActionButton onClick={() => openUnitItemProductsInfo(ui.idx)}>
              {t("list.columns.linkedProduct")}
            </ActionButton>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  return columns;
}
