import {RadioGroupField} from "../../../components/RadioGroupField";
import {BannerStatus} from "../../../types/banner";
import {useBannerType} from "../../../types/hooks/useBannerType";

interface Props {
    bannerStatus: BannerStatus;
    setBannerStatus: (_: BannerStatus) => void;
}

export default function BannerStatusField({bannerStatus, setBannerStatus}: Props) {
    const {getBannerStatusLabel} = useBannerType();
    return (
        <>
            <RadioGroupField
                enumValues={Object.values(BannerStatus)}
                getLabel={getBannerStatusLabel} value={bannerStatus} setValue={setBannerStatus}
                groupName={"bannerStatus"} isRequired={true}
            />
        </>
    );
};