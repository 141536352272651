import { useListFilterValues } from "../../../../hooks/hooks";
import { useSortParam } from "../../../../hooks/list/useSortParam";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DEFAULT_PAGE, DEFAULT_SIZE, ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../types/page";
import {
  LinkStatus,
  PartnerCompanyListVO,
  PartnerCompanySearchCondition,
  PartnerCompanyStatus,
  PartnerCompanyType,
} from "../../../../types/partnerCompany";
import { useUtil } from "../../../../util/hooks/useUtil";
import { PartnerCompanyFilterValues, PartnerCompanyListQueryType } from "./usePartnerCompanyFilterValues";
import { usePartnerCompaniesGetter } from "./usePartnerCompaniesGetter";

interface Args {
  onCompletedCallback: () => void;
}
export function usePartnerCompanyList({ onCompletedCallback }: Args) {
  const [partnerList, setPartnerList] = useState<PageResponse<PartnerCompanyListVO>>();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { getPartnerCompanies } = usePartnerCompaniesGetter();
  const { toSortArray } = useSortParam();
  const { defaultErrorMessage } = useUtil();
  const [totalPartnerCount, setTotalPartnerCount] = useState<number | null>(null);
  const pageCondition = useMemo(
    () => ({
      page: partnerList?.number ?? DEFAULT_PAGE,
      size: partnerList?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(partnerList?.orders),
    }),
    [partnerList]
  );

  const { searchWordFilterValue, typeFilterValue, statusFilterValue, linkStatusFilterValue } =
    filterValues as PartnerCompanyFilterValues;

  const searchPartnerList = (pageSearchCondition: PageSearchCondition) => {
    const param: PartnerCompanyListQueryType = {
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      type: typeFilterValue.value ? typeFilterValue.value : undefined,
      status: statusFilterValue.value ? statusFilterValue.value : undefined,
      linkStatus: linkStatusFilterValue.value ? linkStatusFilterValue.value : undefined,
    };

    return () => {
      searchList(pageSearchCondition, param)();
    };
  };

  const getPartnerList = useCallback(
    async (param: PartnerCompanySearchCondition, isFirstLoad?: boolean) => {
      try {
        const result: PageResponse<PartnerCompanyListVO> = await getPartnerCompanies(param);
        if (isFirstLoad) {
          setTotalPartnerCount(result.totalElements);
        } else {
          setPartnerList(result);
        }
        onCompletedCallback();
      } catch (e) {
        alert(defaultErrorMessage);
      }
    },
    [defaultErrorMessage, getPartnerCompanies, onCompletedCallback]
  );

  useEffect(() => {
    getPartnerList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPartnerListWithParam = useCallback(() => {
    const partnerListQueryType = listQueryType as PartnerCompanyListQueryType;
    getPartnerList({
      pageCondition: {
        page: partnerListQueryType.page ? Number(partnerListQueryType.page) : DEFAULT_PAGE,
        size: partnerListQueryType.size ? Number(partnerListQueryType.size) : DEFAULT_SIZE,
        sort: partnerListQueryType.sort ? toSortArray(partnerListQueryType.sort) : undefined,
      },
      q: partnerListQueryType.searchWord?.trim(),
      type: partnerListQueryType.type ? (partnerListQueryType.type as PartnerCompanyType) : undefined,
      status: partnerListQueryType.status ? (partnerListQueryType.status as PartnerCompanyStatus) : undefined,
      linkStatus: partnerListQueryType.linkStatus
        ? (partnerListQueryType.linkStatus as unknown as LinkStatus)
        : undefined,
    }).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType]);

  useEffect(() => {
    if (listQueryType) {
      getPartnerListWithParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPartnerListWithParam]);

  return {
    searchPartnerList,
    totalPartnerCount,
    partnerList,
    pageCondition,
    getPartnerListWithParam,
  };
}
