import React, {useEffect} from "react";
import {TableStyle} from "../../../../../components/styled/TableStyle";
import {OrderItemTitleRowStyle} from "../../../component/OrderItemTitleRowStyle";
import {OrderStatus, OrdersVO} from "../../../../../types/orders";
import {OrderStatusStyle} from "../../../component/OrderStatusStyle";
import {DetailActionButton} from "../../../../../components/Buttons";
import minusIcon from "../../../../../assets/images/button_icons/minus_icon.svg";
import useOrderItemGetter from "../../../hooks/useOrderItemGetter";
import {convertCurrencyUnit, numberWithCommas} from "../../../../../util/etcUtil";
import {useOrderItemPrice} from "./useOrderItemPrice";
import {OrderItemDiscountPriceColumn} from "./OrderItemDiscountPriceColumn";
import {useLanguage, useNewWindow} from "../../../../../hooks/hooks";
import {Panel} from "../../../../../layout/content/detail/Panel";
import style from "../../orderDetail.module.scss";
import {useTranslation} from "react-i18next";
import {useOrdersType} from "../../../../../types/hooks/useOrdersType";
import {useUtil} from "../../../../../util/hooks/useUtil";

interface Props {
  orderVO: OrdersVO;
  onRefresh: () => void;
}

export function OrderItemInfo({ orderVO, onRefresh }: Props) {
  const { t } = useTranslation("order");
  const { getOrderItems, orderItems } = useOrderItemGetter();
  const { getTotalBuyingPrice, getTotalDiscountPrice } = useOrderItemPrice();
  const { openRefund } = useNewWindow();
  const { toStringFromOrderStatus } = useOrdersType();
  const { defaultErrorMessage } = useUtil();
  const { isKorean } = useLanguage();

  useEffect(() => {
    getOrderItems(orderVO.idx)
      .then()
      .catch(() => alert(defaultErrorMessage));
  }, [orderVO, getOrderItems, defaultErrorMessage]);

  const isPaid = (orderStatus: OrderStatus): boolean =>
    orderStatus === OrderStatus.PAID || orderStatus === OrderStatus.PARTIALLY_CANCELLED;

  const additionalButtons = (
    <>
      {isPaid(orderVO.status) && (
        <DetailActionButton onClick={() => openRefund(orderVO.idx, onRefresh)}>
          <img src={minusIcon} alt={t("orderItem.refund.icon")} />
          {t("orderItem.refund.button")}
        </DetailActionButton>
      )}
    </>
  );

  return (
    <Panel
      id="ORDER_ITEM"
      title={t("orderItem.title")}
      additionalButtons={additionalButtons}
      panelClassName={style.scrollMarginTop}
    >
      {orderItems ? (
        <TableStyle className={isKorean ? "" : "notKO"}>
          <thead>
            <tr>
              <th>{t("orderItem.list.columns.productName")}</th>
              <th>{t("orderItem.list.columns.sellingPrice")}</th>
              <th>{t("orderItem.list.columns.quantity")}</th>
              <th>{t("orderItem.list.columns.actualPrice")}</th>
              <th>{t("orderItem.list.columns.discountAmount")}</th>
              <th>{t("orderItem.list.columns.status")}</th>
            </tr>
          </thead>

          <tbody>
            {orderItems.map((it) => (
              <tr key={it.idx}>
                <td>
                  <OrderItemTitleRowStyle>
                    <img src={it.productImage} alt={t("productImage")} />
                    <span>{it.productName}</span>
                  </OrderItemTitleRowStyle>
                </td>
                <td className={"right"}>
                  {numberWithCommas(it.sellingPrice)}{convertCurrencyUnit(orderVO.paymentWay, t)}
                </td>
                <td>{it.quantity}</td>
                <td className={"right"}>
                  {numberWithCommas(it.sellingPrice * it.quantity)}
                  {numberWithCommas(it.sellingPrice * it.quantity) === '0'
                    ? ''
                    : convertCurrencyUnit(orderVO.paymentWay, t)}
                </td>
                <td className={"right"}>
                  <OrderItemDiscountPriceColumn orderItemVO={it} paymentWay={orderVO.paymentWay} />
                </td>
                <td>
                  <OrderStatusStyle orderStatus={it.status}>{toStringFromOrderStatus(it.status)}</OrderStatusStyle>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>{t("orderItem.list.total")}</td>
              <td></td>
              <td>{orderItems.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0)}</td>
              <td className={"right"}>
                {numberWithCommas(getTotalBuyingPrice(orderItems))}{convertCurrencyUnit(orderVO.paymentWay, t)}
              </td>
              <td className={"right"}>
                {numberWithCommas(getTotalDiscountPrice(orderItems))}
                {numberWithCommas(getTotalDiscountPrice(orderItems)) === '0'
                  ? ''
                  :convertCurrencyUnit(orderVO.paymentWay, t)}
              </td>
              <td></td>
            </tr>
          </tfoot>
        </TableStyle>
      ) : (
        <></>
      )}
    </Panel>
  );
}
