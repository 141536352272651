import { FilterContentType, TableFilter } from "../../../types/tableFilter";
import { TestQueryType } from "../../../types/test";
import { Gender } from "../../../types/common";
import { useLanguage, useListFilterValues, useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useGenderType } from "../../../types/hooks/useGenderType";
import { useMemo } from "react";

interface ReturnType {
  filters: TableFilter[][];
}

interface Args {
  search: () => void;
}

export function useTestFilters({ search }: Args): ReturnType {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation(["test", "common"]);
  const { getOptions } = useGenderType();
  const { timeConverter } = useTimeConverter();
  const { isKorean } = useLanguage();

  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const searchWordTypeFilter = useMemo(
    () => filterValues.searchWordTypeFilterValue,
    [filterValues.searchWordTypeFilterValue]
  );
  const startDateFilter = useMemo(() => filterValues.startDateFilterValue, [filterValues.startDateFilterValue]);
  const endDateFilter = useMemo(() => filterValues.endDateFilterValue, [filterValues.endDateFilterValue]);
  const minTestCntFilter = useMemo(() => filterValues.minTestCntFilterValue, [filterValues.minTestCntFilterValue]);
  const maxTestCntFilter = useMemo(() => filterValues.maxTestCntFilterValue, [filterValues.maxTestCntFilterValue]);
  const minAgeFilter = useMemo(() => filterValues.minAgeFilterValue, [filterValues.minAgeFilterValue]);
  const maxAgeFilter = useMemo(() => filterValues.maxAgeFilterValue, [filterValues.maxAgeFilterValue]);
  const genderFilter = useMemo(() => filterValues.genderFilterValue, [filterValues.genderFilterValue]);

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.searchWord.label"),
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter.value,
          maxLength: 100,
          placeholderVal: t("list.filter.searchWord.placeholder"),
          onChangeFunc: (v) => searchWordFilter.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: searchWordTypeFilter.value,
            options: [
              { value: TestQueryType.USER_EMAIL, label: t("list.filter.queryType.id") },
              { value: TestQueryType.USER_NAME, label: t("list.filter.queryType.testerName") },
              { value: TestQueryType.TEST_REGION, label: t("list.filter.queryType.region") },
            ],
            onChangeFunc: (v) => searchWordTypeFilter.setter(v as TestQueryType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.testEndedAt"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(startDateFilter.value),
            onChangeFunc: (v) => startDateFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(endDateFilter.value),
            onChangeFunc: (v) => endDateFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
          },
        },
      },
      {
        labelTitle: t("list.filter.testCount"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: minTestCntFilter.value,
            onChangeFunc: (v) => minTestCntFilter.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: maxTestCntFilter.value,
            onChangeFunc: (v) => maxTestCntFilter.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.age"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: minAgeFilter.value,
            onChangeFunc: (v) => minAgeFilter.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: maxAgeFilter.value,
            onChangeFunc: (v) => maxAgeFilter.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
        },
      },
      {
        labelTitle: t("list.filter.gender"),
        content: {
          type: FilterContentType.SELECT,
          value: genderFilter.value,
          options: getOptions(),
          onChangeFunc: (v) => genderFilter.setter(v as Gender),
          width: isKorean ? undefined : 200,
        },
      },
    ],
  ];

  return {
    filters,
  };
}
