import {Post} from "../../../layout/content/post/Post";
import React, {useCallback, useState} from "react";
import {Label104x46} from "../../../components/Label";
import BannerNameField from "./BannerNameField";
import style from "../../../layout/content/post/creator.module.scss";
import {dateToString} from "../../../util/dateUtil";
import {BannerLocation, BannerStatus, BannerType, LinkOption} from "../../../types/banner";
import {WebsiteType} from "../../../types/common";
import {AddedFile, FileType} from "../../../types/file";
import {useAppDispatch} from "../../../hooks/hooks";
import {setIsBannerDataSaved} from "../../../store/bannerSlice";
import BannerDurationField from "./BannerDurationField";
import BannerTypeField from "./BannerTypeField";
import BannerLocationField from "./BannerLocationField";
import BannerStatusField from "./BannerStatusField";
import BannerLinkOptionField from "./BannerLinkOptionField";
import BannerImageField from "./BannerImageField";
import BannerButtonTextField from "./BannerButtonTextField";
import BannerButtonLink from "./BannerButtonLink";
import BannerFirTextField from "./BannerFirTextField";
import BannerSecTextField from "./BannerSecTextField";
import {useBannerValidator} from "./hooks/useBannerValidator";
import {useBannerCreator} from "./hooks/useBannerCreator";
import {useNavigate} from "react-router-dom";
import {useUtil} from "../../../util/hooks/useUtil";
import WebsiteTypeField from "./WebsiteTypeField";

export function BannerCreator() {

    const navigate = useNavigate();
    const { defaultErrorMessage } = useUtil();
    const dispatch = useAppDispatch();
    const {createBanner} = useBannerCreator();

    const [name, setName] = useState("");
    const [availableStartAt, setAvailableStartAt] = useState(dateToString(new Date()));
    const [availableEndAt, setAvailableEndAt] = useState(dateToString(new Date()));
    const [isAvailableAlways, setIsAvailableAlways] = useState(false);
    const [websiteType, setWebsiteType] = useState(WebsiteType.KPASS);
    const [bannerType, setBannerType] = useState(BannerType.KOREAN);
    const [bannerLocation, setBannerLocation] = useState(BannerLocation.MAIN);
    const [bannerImage, setBannerImage] = useState<AddedFile | undefined>();
    const [addBannerImage, setAddBannerImage] = useState<FileType | undefined>();
    const [description1, setDescription1] = useState("");
    const [description2, setDescription2] = useState("");
    const [buttonDescription, setButtonDescription] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [linkOption, setLinkOption] = useState(LinkOption.NEW_TAB);
    const [status, setStatus] = useState(BannerStatus.UNEXPOSED);

    //eslint-disable-next-line
    const [hasChangedValue, setHasChangedValue] = useState(false);

    const changeValue = useCallback(
        (v: any, changeFunc: (_: any) => void) => {
            if (v) {
                dispatch(setIsBannerDataSaved(false));
                setHasChangedValue(true);
            }
            changeFunc(v);
        },
        [dispatch, setHasChangedValue]
    );

    const {validate} = useBannerValidator({
        name,
        availableEndAt,
        isAvailableAlways,
        image: bannerImage,
        addedImage: addBannerImage,
        description1,
        description2,
        buttonDescription,
        websiteType,
        bannerType,
        confirmMessage: "배너를 등록 하시겠습니까?"
    });

    const onClickCreateButton = useCallback(() => {
        if (!validate()) return;

        createBanner({
            name,
            type : bannerType,
            bannerLocation,
            availableStartAt,
            availableEndAt,
            description1,
            description2,
            buttonDescription,
            buttonLink,
            image: addBannerImage!,
            linkOption,
            status,
            websiteType,
        })
            .then(() => {
                alert("배너가 등록 되었습니다.");
                navigate(`/catalog/banner-list`);
            })
            .catch(() => alert(defaultErrorMessage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        name,
        bannerType,
        availableStartAt,
        availableEndAt,
        description1,
        description2,
        buttonDescription,
        buttonLink,
        addBannerImage,
        linkOption,
        status,
        validate,
        navigate,
        defaultErrorMessage,
    ]);


    return <>
        <Post buttonTitle={"배너 등록"} buttonOnClickAction={onClickCreateButton} title={"배너 등록"}>
            <>
                <div className={style.fieldLine}>
                    <Label104x46 text={"배너명"} isRequired={true}/>
                    <BannerNameField name={name} setName={(v) => changeValue(v, setName)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"홈페이지"} isRequired={true}/>
                    <WebsiteTypeField websiteType={websiteType}
                                      setWebsiteType={(v) => {
                                             changeValue(v, setWebsiteType);
                                             setBannerType(BannerType.KOREAN)}}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"유효기간"} isRequired={true}/>
                    <BannerDurationField
                        startAt={availableStartAt} setStartAt={(v) => changeValue(v, setAvailableStartAt)}
                        endAt={availableEndAt} setEndAt={(v) => changeValue(v, setAvailableEndAt)}
                        isAvailableAlways={isAvailableAlways}
                        setIsAvailableAlways={(v) => changeValue(v, setIsAvailableAlways)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"배너 언어"} isRequired={true}/>
                    <BannerTypeField bannerType={bannerType} websiteType={websiteType}
                                     setBannerType={(v) => changeValue(v, setBannerType)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"배너 위치"} isRequired={true}/>
                    <BannerLocationField bannerLocation={bannerLocation}
                                         setBannerLocation={(v) => changeValue(v, setBannerLocation)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"배너 이미지"} isRequired={true} className={"alignSelfStart"}/>
                    <BannerImageField image={bannerImage}
                                      setImage={(v) => changeValue(v, setBannerImage)}
                                      addBannerImage={addBannerImage}
                                      setAddBannerImage={(v) => changeValue(v, setAddBannerImage)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"배너 문구1"}  className={"alignSelfStart"}/>
                    <BannerFirTextField bannerText={description1} bannerTextNumber={"배너 문구1"}
                                        setBannerText={(v) => changeValue(v, setDescription1)}/>
                </div>
                <div className={style.fieldLine}>
                    <Label104x46 text={"배너 문구2"}  className={"alignSelfStart"}/>
                    <BannerSecTextField bannerText={description2} bannerTextNumber={"배너 문구2"}
                                        setBannerText={(v) => changeValue(v, setDescription2)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"버튼 문구"} isRequired={true}/>
                    <BannerButtonTextField buttonText={buttonDescription}
                                           setButtonText={(v) => changeValue(v, setButtonDescription)}/>

                    <Label104x46 text={"버튼 링크"} isRequired={true}/>
                    <BannerButtonLink buttonLink={buttonLink} setButtonLink={(v) => changeValue(v, setButtonLink)}/>
                </div>

                <div className={style.fieldLine}>
                    <Label104x46 text={"링크 옵션"} isRequired={true}/>
                    <BannerLinkOptionField linkOption={linkOption}
                                           setLinkOption={(v) => changeValue(v, setLinkOption)}/>
                </div>
                <div className={style.fieldLine}>
                    <Label104x46 text={"상태"} isRequired={true}/>
                    <BannerStatusField bannerStatus={status} setBannerStatus={(v) => changeValue(v, setStatus)}/>
                </div>

            </>
        </Post>
    </>;
}
