import { TableColumnData } from "../../../types/common";
import { CouponAvailableDateType, CouponListObj, CouponStatus, CouponType } from "../../../types/coupon";
import { ActionButtonWrapper } from "../../../layout/content/ActionButtonWrapper";
import React from "react";
import { LOCALES_STRING } from "../../../util/etcUtil";
import { ActionButton, ActiveActionButton } from "../../../components/Buttons";
import { ProductSummaryViewer } from "../../product/ProductSummaryViewer";
import { useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useCouponType } from "../../../types/hooks/useCouponType";

interface Args {
  stopIssuingCoupons: ((_: number[]) => void) | null;
  resumeIssuingCoupons: ((_: number[]) => void) | null;
}

export function useCouponColumns({ stopIssuingCoupons, resumeIssuingCoupons }: Args) {
  const { t } = useTranslation("coupon");
  const { timeConverter } = useTimeConverter();
  const { toStringFromCouponType, toStringFromCouponStatus } = useCouponType();

  const getTextClassName = (status: CouponStatus) => {
    switch (status) {
      case CouponStatus.DELETED:
        return "red";
      case CouponStatus.IS_ISSUING:
        return "blue";
      default:
        return "";
    }
  };

  const RelatedFeaturesNode = ({ status, couponIdx }: { status: CouponStatus; couponIdx: number }) => {
    let button;
    switch (status) {
      case CouponStatus.IS_ISSUING:
        button = (
          <ActionButton
            onClick={() => {
              if (stopIssuingCoupons) {
                stopIssuingCoupons([couponIdx]);
              }
            }}
          >
            {t("stopIssuing.button")}
          </ActionButton>
        );
        break;
      case CouponStatus.STOP_ISSUING:
        button = (
          <ActiveActionButton
            onClick={() => {
              if (resumeIssuingCoupons) {
                resumeIssuingCoupons([couponIdx]);
              }
            }}
          >
            {t("reissue.button")}
          </ActiveActionButton>
        );
        break;
      default:
        button = <></>;
    }

    return <ActionButtonWrapper>{button}</ActionButtonWrapper>;
  };

  const TotalDiscountTypeCondition = ({ c }: { c: CouponListObj }) => {
    if (c.availableAboveAmount) {
      return <>{t("creator.amount.aboveAmount", { price: c.availableAboveAmount?.toLocaleString(LOCALES_STRING) })}</>;
    }

    return <>{t("creator.condition.noLimit")}</>;
  };

  const columns: TableColumnData<CouponListObj>[] = [
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      getNode: (c: CouponListObj) => <>{timeConverter.convertToLocalTime(c.createAt)}</>,
      sortable: true,
    },
    {
      accessor: "status",
      header: t("list.columns.status"),
      getNode: (c: CouponListObj) => (
        <span className={getTextClassName(c.status)}>{toStringFromCouponStatus(c.status)}</span>
      ),
      sortable: false,
    },
    {
      accessor: "type",
      header: t("list.columns.type"),
      getNode: (c: CouponListObj) => <>{toStringFromCouponType(c.type)}</>,
      sortable: false,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      sortable: false,
      isLeftAlign: true,
      width: 304,
    },
    {
      accessor: "availableAboveAmount",
      header: t("list.columns.availableAboveAmount"),
      getNode: (c: CouponListObj) => (
        <>
          {c.type === CouponType.TOTAL_AMOUNT_DISCOUNT ? <TotalDiscountTypeCondition c={c} /> : <></>}
          {c.type === CouponType.PRODUCT_DISCOUNT ? <ProductSummaryViewer productIdx={c.productIdx} /> : <></>}
        </>
      ),
      sortable: false,
      width: 200,
    },
    {
      accessor: "point",
      header: t("list.columns.benefits"),
      getNode: (c: CouponListObj) => (
        <span>
          {c.point ? `${c.point?.toLocaleString(LOCALES_STRING)}${t("amountUnit")}` : `${c.percent}%`} {t("discount")}
        </span>
      ),
      sortable: false,
    },
    {
      accessor: "availableDateType",
      header: t("list.columns.availableDuration"),
      getNode: (c: CouponListObj) => (
        <span>
          {c.availableDateType === CouponAvailableDateType.DURATION ? (
            <>
              {timeConverter.convertToLocalTime(c.availableStartAt)}
              <br />~{timeConverter.convertToLocalTime(c.availableEndAt)}
            </>
          ) : (
            <>{t("list.columns.issuedAt", { days: c.availableDateAfterGranted })}</>
          )}
        </span>
      ),
      sortable: false,
    },
    {
      accessor: "idx",
      header: t("list.columns.additionalFunctions"),
      getNode: (c: CouponListObj) => <RelatedFeaturesNode status={c.status} couponIdx={c.idx} />,
      sortable: false,
    },
  ];

  return {
    columns,
  };
}
