import { LinkOption } from "../../../types/popup";
import { RadioGroupField } from "../../../components/RadioGroupField";
import { usePopupType } from "../../../types/hooks/usePopupType";

interface Props {
  linkOption: LinkOption;
  setLinkOption: (_: LinkOption) => void;
}
export function PopupLinkOptionField({ linkOption, setLinkOption }: Props) {
  const { getPopupLinkOptionLabel } = usePopupType();

  return (
    <RadioGroupField
      enumValues={Object.values(LinkOption)}
      getLabel={getPopupLinkOptionLabel}
      value={linkOption}
      setValue={setLinkOption}
      groupName={"popupLinkOption"}
      isRequired={true}
    />
  );
}
