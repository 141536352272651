import { Panel } from "../../../../../layout/content/detail/Panel";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useOrganManagerInfoData } from "./hooks/useOrganManagerInfoData";
import { OrganManagerStatus, OrganManagerUpdateDTO, OrganManagerVO } from "../../../../../types/organManager";
import { DescriptionList } from "../../../../../layout/content/detail/DescriptionList";
import { MemoTargetType } from "../../../../../types/memo";
import { MemoComponent } from "../../../../component/memo/MemoComponent";
import { AdminMenuCode } from "../../../../../types/adminRole";
import voucherIcon from "../../../../../assets/images/button_icons/voucher_icon.svg";
import checkIcon from "../../../../../assets/images/button_icons/check_icon.svg";
import { RoleChecker } from "../../../../role/RoleChecker";
import { DetailActionButton } from "../../../../../components/Buttons";
import couponIcon from "../../../../../assets/images/button_icons/coupon_icon.svg";
import passwordIcon from "../../../../../assets/images/button_icons/password_icon.svg";
import personIcon from "../../../../../assets/images/button_icons/person_icon.svg";
import style from "../../../user/memberUserDetail.module.scss";
import deleteIcon from "../../../../../assets/images/button_icons/delete_icon.svg";
import { useAppSelector, useNewWindow } from "../../../../../hooks/hooks";
import { organManagerStatus } from "../../../../../store/organManagerSlice";
import { useUnblockOrganMember } from "../../hooks/useUnblockOrganMember";
import { ActionUserType } from "../../../../../types/dataCommon";
import { useLoader } from "../../../../../hooks/common/useLoader";
import { useOrganManagerGetter } from "./hooks/useOrganManagerGetter";
import confirmIcon from "../../../../../assets/images/button_icons/confirm_icon.svg";
import { useOrganManagerUpdater } from "./hooks/useOrganManagerUpdater";
import { CustomerType } from "../../../../../types/common";
import { useOrganManagerStatus } from "../../hooks/useOrganManagerStatus";
import { useOrganManagerTemporaryPasswordIssuer } from "../../hooks/useOrganManagerTemporaryPasswordIssuer";
import { useTranslation } from "react-i18next";

const isLeaved = (status: OrganManagerStatus) => status === OrganManagerStatus.LEAVE;

interface Props {
  idx: number;
}

export function OrganManagerInfo({ idx }: Props) {
  const { t } = useTranslation("organManager");
  const formRef = useRef<HTMLFormElement>(null);
  const { organManagerVO, getOrganManager, setOrganManager } = useOrganManagerGetter();
  const unblockOrganMember = useUnblockOrganMember();
  const updateOrganManager = useOrganManagerUpdater();
  const { openBlockMemberPopup, openGrantProduct } = useNewWindow();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const status = useAppSelector(organManagerStatus);
  const [updateDTO, setUpdateDTO] = useState<OrganManagerUpdateDTO | undefined>(undefined);
  useLoader({ status });
  const { isBlocked, isLeave } = useOrganManagerStatus();

  const loadOrganManager = useCallback((idx: number) => getOrganManager(idx).then(), [getOrganManager]);
  const { getDescList } = useOrganManagerInfoData({
    organManagerVO,
    onUpdateOrganManagerPutParam: setUpdateDTO,
    getDetail: () => loadOrganManager(idx).then(),
  });
  const { openGrantCoupon } = useNewWindow();

  useEffect(() => {
    if (organManagerVO?.idx !== idx) {
      loadOrganManager(idx).then();
    }
  }, [organManagerVO?.idx, idx, loadOrganManager]);

  const onClickUpdateOrganManagerButton = useCallback(() => {
    if (!formRef.current?.reportValidity()) return;
    if (organManagerVO === undefined) return;

    updateOrganManager(organManagerVO.idx, updateDTO!, (it) => {
      setOrganManager(it);
      alert(t("detail.update.resultMessage"));
    });
    setIsEditMode(false);
  }, [t, organManagerVO, setOrganManager, updateDTO, updateOrganManager]);

  const { issueTemporaryPassword } = useOrganManagerTemporaryPasswordIssuer();
  const onClickIssuePasswordButton = useCallback(() => {
    if (window.confirm(t("detail.issuePassword.confirmMessage"))) {
      issueTemporaryPassword(idx, () => alert(t("detail.issuePassword.resultMessage")));
    }
  }, [issueTemporaryPassword, idx, t]);
  const getAdditionalButtons = (organManagerVO: OrganManagerVO, updateDTO?: OrganManagerUpdateDTO): JSX.Element => {
    switch (true) {
      case isLeave(organManagerVO.status):
        return <></>;
      case isBlocked(organManagerVO.status):
        return (
          <DetailActionButton
            onClick={() => unblockOrganMember(organManagerVO.idx, () => loadOrganManager(organManagerVO.idx))}
          >
            <img src={checkIcon} alt={t("unblock.voucherIcon")} />
            {t("unblock.unblockButton")}
          </DetailActionButton>
        );
      case isEditMode:
        return (
          <>
            <DetailActionButton onClick={() => setIsEditMode(false)}>{t("detail.update.cancel")}</DetailActionButton>
            {updateDTO && (
              <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_UPDATE}>
                <DetailActionButton onClick={onClickUpdateOrganManagerButton}>
                  <img src={confirmIcon} alt={t("detail.update.personIcon")} />
                  {t("detail.update.save")}
                </DetailActionButton>
              </RoleChecker>
            )}
          </>
        );
      default:
        return (
          <>
            <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_UPDATE}>
              <DetailActionButton onClick={() => setIsEditMode(true)}>
                <img src={personIcon} alt={t("detail.update.personIcon")} />
                {t("detail.update.update")}
              </DetailActionButton>
            </RoleChecker>
            <RoleChecker menuCode={AdminMenuCode.ADMIN_ISSUE_TEMPORARY_PASSWORD}>
              <DetailActionButton onClick={onClickIssuePasswordButton}>
                <img src={passwordIcon} alt={t("detail.issuePassword.alt")} />
                {t("detail.issuePassword.buttonLabel")}
              </DetailActionButton>
            </RoleChecker>
            <RoleChecker menuCode={AdminMenuCode.ISSUE_COUPON}>
              <DetailActionButton
                onClick={() =>
                  openGrantCoupon({
                    targetIdx: organManagerVO.idx,
                    targetName: organManagerVO.name,
                    customerType: CustomerType.ORGAN_MANAGER,
                  })
                }
              >
                <img src={couponIcon} alt={t("issueCoupon.couponIcon")} />
                {t("issueCoupon.buttonLabel")}
              </DetailActionButton>
            </RoleChecker>
            <DetailActionButton
              onClick={() => {
                openGrantProduct({
                  targetIdxes: [organManagerVO.idx],
                  targetType: CustomerType.ORGAN_MANAGER,
                  targetName: organManagerVO.name,
                });
              }}
            >
              <img src={voucherIcon} alt={t("list.grantVoucher.voucherIcon")} />
              {t("list.grantVoucher.buttonLabel")}
            </DetailActionButton>
          </>
        );
    }
  };

  return organManagerVO ? (
    <>
      <Panel title={t("detail.title")} additionalButtons={getAdditionalButtons(organManagerVO, updateDTO)}>
        <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <DescriptionList data={getDescList(organManagerVO)} isEditMode={isEditMode} labelWidth={132} />
        </form>
      </Panel>

      <MemoComponent
        targetIdx={organManagerVO.idx}
        targetType={MemoTargetType.ORGAN_MANAGER}
        isWritable={!isLeaved(organManagerVO.status)}
      />

      <div className={style.buttonArea}>
        {isLeaved(organManagerVO.status) || isBlocked(organManagerVO.status) ? (
          <></>
        ) : (
          <button
            className={style.blockBtn}
            onClick={() =>
              openBlockMemberPopup(
                {
                  userType: ActionUserType.ORGAN_MANAGER,
                  memberIdxArr: [organManagerVO.idx],
                },
                () => loadOrganManager(organManagerVO.idx)
              )
            }
          >
            <img src={deleteIcon} alt={t("block.deleteIcon")} />
            {t("block.buttonLabel")}
          </button>
        )}
      </div>
    </>
  ) : (
    <></>
  );
}
