import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { ProductApi } from "../api/ProductApi";
import { RootState } from "./index";
import {
  GrantableProductPostParam,
  ProductPostParam,
  ProductPutParam,
  ProductSearchCondition,
  ProductStatusUpdateParam,
} from "../types/product";

export interface ProductState extends SliceState {
  unitItemsStatus: Status;
  isProductDataSaved: boolean;
}

const initialState: ProductState = {
  status: Status.IDLE,
  unitItemsStatus: Status.IDLE,
  isProductDataSaved: false,
};

export const getProductDetailAsync = createAsyncThunk("product/getProductDetail", (productIdx: number) =>
  executePromise(ProductApi.getProductDetail(productIdx))
);

export const getProductListAsync = createAsyncThunk("product/getProductList", (condition: ProductSearchCondition) =>
  executePromise(ProductApi.getProductList(condition))
);

export const createProductAsync = createAsyncThunk("product/createProduct", (param: ProductPostParam) =>
  executePromise(ProductApi.createProduct(param))
);

export const createGrantableProductAsync = createAsyncThunk(
  "product/createGrantableProduct",
  (param: GrantableProductPostParam) => executePromise(ProductApi.createGrantableProduct(param))
);

export const updateProductStatusAsync = createAsyncThunk(
  "product/updateProductStatus",
  (param: ProductStatusUpdateParam) => executePromise(ProductApi.updateStatus(param))
);

export const getProductItemsByProductAsync = createAsyncThunk(
  "product/getProductItemsByProduct",
  (productIdx: number) => executePromise(ProductApi.getProductItemsByProductIdx(productIdx))
);

export const getProductImagesAsync = createAsyncThunk("product/getProductImages", (productIdx: number) =>
  executePromise(ProductApi.getProductImages(productIdx))
);

export const deleteProductsAsync = createAsyncThunk("product/deleteProducts", (productIdxes: number[]) =>
  executePromise(ProductApi.deleteProducts(productIdxes))
);

export const updateProductAsync = createAsyncThunk(
  "product/updateProduct",
  ({ idx, param }: { idx: number; param: ProductPutParam }) => executePromise(ProductApi.updateProduct(idx, param))
);

export const askProductsDeletableOrNotAsync = createAsyncThunk(
  "product/askProductsDeletableOrNot",
  (productIdxes: number[]) => executePromise(ProductApi.askProductsDeletableOrNot(productIdxes))
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setIsProductDataSaved: (state, action: PayloadAction<boolean>) => {
      state.isProductDataSaved = action.payload;
    },
  },
  extraReducers: (builder) => {
    addCases(builder, getProductDetailAsync);
    addCases(builder, createProductAsync);
    addCases(builder, createGrantableProductAsync);
    addCases(builder, getProductListAsync);
    addCases(builder, updateProductAsync);
    addCases(builder, updateProductStatusAsync);
    addCases(builder, askProductsDeletableOrNotAsync);
    builder
      .addCase(getProductItemsByProductAsync.pending, (state) => {
        state.unitItemsStatus = Status.LOADING;
      })
      .addCase(getProductItemsByProductAsync.rejected, (state) => {
        state.unitItemsStatus = Status.FAILED;
      })
      .addCase(getProductItemsByProductAsync.fulfilled, (state) => {
        state.unitItemsStatus = Status.IDLE;
      });
  },
});

export default productSlice.reducer;

export const { setIsProductDataSaved } = productSlice.actions;
export const productStatus = (state: RootState) => state.product.status;
export const unitItemsStatus = (state: RootState) => state.product.unitItemsStatus;
export const isProductDataSavedState = (state: RootState) => state.product.isProductDataSaved;
