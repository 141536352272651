import React, { useCallback, useEffect, useMemo, useState } from "react";
import { VoucherInfo } from "./coupon_and_voucher/voucher/VoucherInfo";
import { OrganVoucherInfo } from "./coupon_and_voucher/organ_voucher/OrganVoucherInfo";
import { CategoryTab, DetailSubMenuCategories } from "../../../layout/content/detail/DetailSubMenuCategories";
import { useUserVoucherStatus } from "./coupon_and_voucher/hooks/useUserVoucherStatus";
import { useCouponCntInfo } from "../../coupon/hooks/useCouponCntInfo";
import { CustomerType } from "../../../types/common";
import { useUserOrganVoucherStatusGetter } from "./coupon_and_voucher/hooks/useUserOrganVoucherStatusGetter";
import { CouponInfo } from "../components/coupon/CouponInfo";
import { useTranslation } from "react-i18next";

enum MenuCategory {
  COUPON = "COUPON",
  VOUCHER = "VOUCHER",
  ORGAN_VOUCHER = "ORGAN_VOUCHER",
}

interface Props {
  idx: number;
  name: string;
}

export function MemberUserCoupons({ idx, name }: Props) {
  const { t } = useTranslation("user");
  const [menuCategory, setMenuCategory] = useState(MenuCategory.COUPON);

  const getCategoryComponent = useCallback(
    (menu: MenuCategory) => {
      switch (menu) {
        case MenuCategory.COUPON:
          return <CouponInfo targetIdx={idx} customerType={CustomerType.USER} />;
        case MenuCategory.VOUCHER:
          return <VoucherInfo userIdx={idx} name={name} />;
        case MenuCategory.ORGAN_VOUCHER:
          return <OrganVoucherInfo userIdx={idx} name={name} />;
        default:
          return <></>;
      }
    },
    [idx, name]
  );

  const { getVoucherStatusInfo, userVoucherStatusInfo } = useUserVoucherStatus({ userIdx: idx });
  const { organVoucherNewStatusInfo } = useUserOrganVoucherStatusGetter({ userIdx: idx });
  //eslint-disable-next-line
  const [availableCount, setAvailableCount] = useState(0);
  const { getCouponCntInfo, couponStatusInfo } = useCouponCntInfo({
    targetParam: {
      targetIdx: idx,
      targetType: CustomerType.USER,
    },
  });

  const categoryMenus: CategoryTab<MenuCategory>[] = useMemo(
    () => {
      return [
        {
          tabName: t("detail.coupon.submenus.coupon.label"),
          tabRightValue: t("detail.coupon.submenus.coupon.unit", { count: couponStatusInfo?.availableCoupon ?? 0 }),
          tabValue: MenuCategory.COUPON,
        },
        {
          tabName: t("detail.coupon.submenus.userVoucher.label"),
          tabRightValue: t("detail.coupon.submenus.userVoucher.unit", {
            count: (userVoucherStatusInfo?.voucherDTOs?.[0]?.availableVoucher ?? 0) +
                (userVoucherStatusInfo?.voucherDTOs?.[1]?.availableVoucher ?? 0),
          }),
          tabValue: MenuCategory.VOUCHER,
        },
        {
          tabName: t("detail.coupon.submenus.organVoucher.label"),
          tabRightValue: t("detail.coupon.submenus.organVoucher.unit", {
            count: availableCount,
          }),
          tabValue: MenuCategory.ORGAN_VOUCHER,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      idx,
      couponStatusInfo?.availableCoupon,
      userVoucherStatusInfo?.voucherDTOs,
      t,
      availableCount,
    ]
  );

  useEffect(() => {
    if (!couponStatusInfo) {
      getCouponCntInfo();
    }
    setAvailableCount(((organVoucherNewStatusInfo?.voucherDTOs[0]?.availableOrganVoucherCount ?? 0)
        + (organVoucherNewStatusInfo?.voucherDTOs[1]?.availableOrganVoucherCount ?? 0)))
    //eslint-disable-next-line
  }, [getCouponCntInfo, couponStatusInfo]);

  useEffect(() => {
    if (!userVoucherStatusInfo) {
      getVoucherStatusInfo();
    }
  }, [getVoucherStatusInfo, userVoucherStatusInfo]);

  return (
    <>
      <DetailSubMenuCategories tabs={categoryMenus} selectedTab={menuCategory} onClickTab={setMenuCategory} />
      {getCategoryComponent(menuCategory)}
    </>
  );
}
