import { OrganManagerAffiliateCodeList } from "./OrganManagerAffiliateCodeList";
import { OrganManagerAffiliateCodeOrderList } from "./OrganManagerAffiliateCodeOrderList";
import { OrganManagerAffiliateCodeStatistics } from "./OrganManagerAffiliateCodeStatistics";
import { YearAndMonth } from "../../../../../components/YearAndMonth";
import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MemberOrganManagerDetailTabType } from "../../../../../types/organManager";
import {
  SETTLEMENT_START_MONTH,
  SETTLEMENT_START_YEAR,
} from "../../../../affiliate_code/settlement/SettlementYearAndMonthWrapper";

interface Props {
  organManagerIdx: number;
}

export const CODE_KEY = "code";
export const YEAR_KEY = "year";
export const MONTH_KEY = "month";
export function OrganManagerAffiliateCode({ organManagerIdx }: Props) {
  const [year, setYear] = useState<number | undefined>();
  const [month, setMonth] = useState<number | undefined>();
  const [selectedCode, setSelectedCode] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isReadSearchParams, setIsReadSearchParams] = useState(false);

  const changeParams = useCallback(
    ({ code, month: monthParam, year: yearParam }: { code?: string; year?: number; month?: number }) => {
      setSearchParams({
        tab: MemberOrganManagerDetailTabType.AFFILIATE_CODE,
        [CODE_KEY]: code ? code : "",
        [YEAR_KEY]: (yearParam ?? year!).toString(),
        [MONTH_KEY]: (monthParam ?? month!).toString(),
      });
    },
    [setSearchParams, year, month]
  );

  useEffect(() => {
    const codeFromUrl = searchParams.get(CODE_KEY);
    const yearFromUrl = searchParams.get(YEAR_KEY);
    const monthFromUrl = searchParams.get(MONTH_KEY);

    setSelectedCode(codeFromUrl ?? "");
    setYear(yearFromUrl ? Number(yearFromUrl) : new Date().getFullYear());
    setMonth(monthFromUrl ? Number(monthFromUrl) : new Date().getMonth() + 1);
    setIsReadSearchParams(true);
  }, [searchParams]);

  return (
    <>
      {isReadSearchParams && (
        <>
          <YearAndMonth
            startYear={SETTLEMENT_START_YEAR}
            startMonth={SETTLEMENT_START_MONTH}
            year={year!}
            month={month!}
            setYearAndMonth={(y, m) => changeParams({ year: y, month: m })}
          />
          <OrganManagerAffiliateCodeStatistics organManagerIdx={organManagerIdx} year={year!} month={month!} />
          <OrganManagerAffiliateCodeList
            organManagerIdx={organManagerIdx}
            setCode={(code) =>
              changeParams({
                code,
              })
            }
          />
          <OrganManagerAffiliateCodeOrderList
            organManagerIdx={organManagerIdx}
            selectedCode={selectedCode ?? ""}
            year={year!}
            month={month!}
          />
        </>
      )}
    </>
  );
}
