import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { issueTemporaryPasswordAsync } from "../../../../store/organManagerSlice";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useOrganManagerTemporaryPasswordIssuer() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const issueTemporaryPassword = useCallback(
    (organManagerIdx: number, onSuccess: () => void) => {
      callAsync(
        dispatch(issueTemporaryPasswordAsync(organManagerIdx)).unwrap(),
        () => onSuccess(),
        () => alert(defaultErrorMessage)
      ).then();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultErrorMessage]
  );

  return {
    issueTemporaryPassword,
  };
}
