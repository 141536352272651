import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { PanelWrapper } from "../../../layout/content/detail/PanelWrapper";
import { Panel } from "../../../layout/content/detail/Panel";
import formStyle from "../../product/edit/productEditForm.module.scss";
import style from "../../../layout/content/post/creator.module.scss";
import { Label104x46 } from "../../../components/Label";
import { PartnerNameField } from "./PartnerNameField";
import {
  isPartnerDataSavedState,
  partnerCompanyStatusState,
  setIsPartnerDataSaved,
} from "../../../store/partnerCompanySlice";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../../hooks/hooks";
import { useLoader } from "../../../hooks/common/useLoader";
import { RadioGroupField } from "../../../components/RadioGroupField";
import { PartnerCompanyStatus, PartnerCompanyType, PartnerCompanyVO } from "../../../types/partnerCompany";
import { usePartnerCompanyType } from "../../../types/hooks/usePartnerCompanyType";
import { PartnerLogoField } from "./PartnerLogoField";
import { AddedFile, FileType } from "../../../types/file";
import { Address } from "../../../types/user";
import { PartnerKoreanAddress } from "./PartnerKoreanAddress";
import { PartnerHomepageField } from "./PartnerHomepageField";
import { HrLine } from "../../../layout/content/post/HrLine";
import { PartnerAffiliateCodesField } from "./PartnerAffiliateCodesField";
import { ButtonWrapperStyle, PrimaryButton } from "../../../components/Buttons";
import { usePartnerCompanyGetter } from "../hooks/partner/usePartnerCompanyGetter";
import { useParams, useSearchParams } from "react-router-dom";
import { useAffiliateCodeListVOMapper } from "../hooks/code/useAffiliateCodeListVOMapper";
import { AffiliateCodeListVO } from "../../../types/affiliateCode";
import { useNavigationBlocker } from "../../../hooks/navigationblocker/useNavigationBlocker";
import { usePartnerCompanyValidator } from "../hooks/partner/usePartnerCompanyValidator";
import { WindowMessage } from "../../../types/newWindow";
import { PartnerCompanyStatusRadioField } from "./PartnerCompanyStatusRadioField";
import { usePartnerCompanyUpdater } from "../hooks/partner/usePartnerCompanyUpdater";

export const PARTNER_COMPANY_DETAIL_PARAM_ORDERING_NUM = "orderingNumber";

export function PartnerCompanyDetail() {
  const dispatch = useAppDispatch();
  const isPartnerDataSaved = useAppSelector(isPartnerDataSavedState);
  const partnerCompanyStatus = useAppSelector(partnerCompanyStatusState);
  useLoader({ status: partnerCompanyStatus });
  const formRef = useRef<HTMLFormElement>(null);
  const { getPartnerCompany } = usePartnerCompanyGetter();

  const { id } = useParams<"id">();
  const [searchParams] = useSearchParams();

  const { mapAffiliateCodeDTOToListVO } = useAffiliateCodeListVOMapper();
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  const orderingNumber: string | null = useMemo(
    () => searchParams.get(PARTNER_COMPANY_DETAIL_PARAM_ORDERING_NUM) ?? null,
    [searchParams]
  );

  const [name, setName] = useState("");
  const [hasChangedValue, setHasChangedValue] = useState(false);
  const [type, setType] = useState(PartnerCompanyType.DOMESTIC);
  const [logo, setLogo] = useState<AddedFile>();
  const [addedLogo, setAddedLogo] = useState<FileType>();
  const [address, setAddress] = useState<Address>({
    address1: "",
    address2: "",
    city: "",
    country: "KR",
  });
  const [homepage, setHomepage] = useState("");
  const [affiliateCodeIdx, setAffiliateCodeIdx] = useState<number | null>(null);
  const [affiliateCode, setAffiliateCode] = useState<AffiliateCodeListVO | null>(null);
  const [status, setStatus] = useState(PartnerCompanyStatus.UNEXPOSED);
  const isDomestic = useMemo(() => type === PartnerCompanyType.DOMESTIC, [type]);

  const { getPartnerCompanyTypeLabel } = usePartnerCompanyType();
  const changeValue = useCallback(
    (v: any, changeFunc: (_: any) => void) => {
      if (v) {
        dispatch(setIsPartnerDataSaved(false));
        setHasChangedValue(true);
      }
      changeFunc(v);
    },
    [dispatch, setHasChangedValue]
  );

  useNavigationBlocker({
    hasChangedValue,
    isDataSaved: isPartnerDataSaved,
    confirmMessage: "사이트에서 나가시겠습니까?\n변경사항이 저장되지 않을 수 있습니다",
  });

  useEffect(() => {
    if (id) {
      getPartnerCompany(Number(id)).then((vo: PartnerCompanyVO) => {
        setName(vo.name);
        if (vo.address) setAddress(vo.address);
        setType(vo.type);
        setStatus(vo.status);
        setHomepage(vo.webSite ?? "");
        setLogo(vo.logo);

        if (vo.affiliateCode) {
          mapAffiliateCodeDTOToListVO(vo.affiliateCode).then((listVO: AffiliateCodeListVO) => {
            setAffiliateCode(listVO);
            setAffiliateCodeIdx(vo.affiliateCode!.idx);
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { validate } = usePartnerCompanyValidator({
    name,
    logo,
    type,
    address,
    addedLogo,
    defaultAffiliateCode: affiliateCode,
    affiliateCodeIdx,
  });
  const { updatePartner } = usePartnerCompanyUpdater();
  const onClickModifyButton = useCallback(() => {
    if (!formRef.current?.reportValidity()) return;
    if (!validate("협력사 정보를 수정 하시겠습니까?")) return;

    updatePartner(Number(id), {
      name: name.trim(),
      defaultLogo: logo!,
      addedLogo,
      type,
      status,
      address,
      webSite: homepage?.trim(),
      affiliateCodeIdx: affiliateCodeIdx ?? undefined,
    }).then(() => {
      closeWindowAndNotifyToParentWindow(WindowMessage.PARTNER_COMPANY);
    });
  }, [
    validate,
    updatePartner,
    id,
    closeWindowAndNotifyToParentWindow,
    name,
    logo,
    addedLogo,
    type,
    status,
    address,
    homepage,
    affiliateCodeIdx,
  ]);

  return (
    <PanelWrapper>
      <Panel title={"협력사 정보"}>
        <form className={formStyle.form} ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <div className={style.fieldLine}>
            <Label104x46 text={"협력사명"} isRequired={true} />
            <PartnerNameField name={name} setName={(name) => changeValue(name, setName)} />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"협력사 유형"} isRequired={true} />
            <RadioGroupField
              value={type}
              setValue={(v) => changeValue(v, setType)}
              groupName={"partnerCompanyType"}
              isRequired={true}
              enumValues={Object.values(PartnerCompanyType)}
              getLabel={getPartnerCompanyTypeLabel}
            />
          </div>
          <div className={style.fieldLine}>
            <Label104x46 text={"로고 이미지"} isRequired={true} className={"alignSelfStart"} />
            <PartnerLogoField
              logo={logo}
              setLogo={(v) => changeValue(v, setLogo)}
              addedLogo={addedLogo}
              setAddedLogo={(v) => changeValue(v, setAddedLogo)}
            />
          </div>
          {isDomestic && (
            <>
              <div className={style.fieldLine}>
                <Label104x46 text={"협력사 주소"} isRequired={true} />
                <PartnerKoreanAddress address={address} setAddress={(v) => changeValue(v, setAddress)} />
              </div>
              <div className={style.fieldLine}>
                <Label104x46 text={"협력사 홈페이지"} isRequired={false} />
                <PartnerHomepageField homepage={homepage} setHomepage={(v) => changeValue(v, setHomepage)} />
              </div>
              <HrLine />
              <div className={`${style.fieldLine} ${style.tableLine}`}>
                <Label104x46 text={"제휴코드 연결"} isRequired={false} />
                <PartnerAffiliateCodesField
                  affiliateCodeIdx={affiliateCodeIdx}
                  setAffiliateCodeIdx={setAffiliateCodeIdx}
                  isCreatorPage={false}
                  defaultSelectedCode={affiliateCode ?? undefined}
                />
              </div>
              <HrLine />
            </>
          )}
          <div className={style.fieldLine}>
            <Label104x46 text={"상태"} isRequired={true} />
            <PartnerCompanyStatusRadioField
              changeValue={changeValue}
              setStatus={setStatus}
              status={status}
              isExposureButtonBlocked={orderingNumber === null}
            />
          </div>
          <ButtonWrapperStyle className={"marginTB20"}>
            <PrimaryButton className={"width300 fontSize20"} onClick={onClickModifyButton}>
              협력사 수정
            </PrimaryButton>
          </ButtonWrapperStyle>
        </form>
      </Panel>
    </PanelWrapper>
  );
}
