import { FilterContentType, TableFilter } from "../../../../types/tableFilter";
import { useMemo } from "react";
import { AdminQueryType, AdminStatus } from "../../../../types/admin";
import { useRoleGetter } from "../../../role/hooks/useRoleGetter";
import { useLanguage, useListFilterValues, useTimeConverter } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useAdminType } from "../../../../types/hooks/useAdminType";

interface Args {
  search: () => void;
}

export function useAdminFilters({ search }: Args): {
  filters: TableFilter[][];
} {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation(["admin", "common"]);
  const { adminRoleOptions } = useRoleGetter({});
  const roleOptions = useMemo(
    () => [{ label: t("list.filter.all"), value: "" }, ...adminRoleOptions],
    [t, adminRoleOptions]
  );
  const { timeConverter } = useTimeConverter();
  const { getAdminStatusOptions } = useAdminType();
  const { isKorean: isKoreanLanguage } = useLanguage();

  const searchWordTypeFilter = useMemo(
    () => filterValues.searchWordTypeFilterValue,
    [filterValues.searchWordTypeFilterValue]
  );
  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const createdAtFromFilter = useMemo(
    () => filterValues.createdAtFromFilterValue,
    [filterValues.createdAtFromFilterValue]
  );
  const createdAtToFilter = useMemo(() => filterValues.createdAtToFilterValue, [filterValues.createdAtToFilterValue]);
  const adminStatusFilter = useMemo(() => filterValues.adminStatusFilterValue, [filterValues.adminStatusFilterValue]);
  const adminRoleIdxFilter = useMemo(
    () => filterValues.adminRoleIdxFilterValue,
    [filterValues.adminRoleIdxFilterValue]
  );

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.searchWord.label"),
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter.value,
          maxLength: 100,
          placeholderVal: t("list.filter.searchWord.placeholder"),
          onChangeFunc: (it) => searchWordFilter.setter(it),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: searchWordTypeFilter.value,
            options: [
              { value: AdminQueryType.NAME, label: t("list.filter.queryType.name") },
              { value: AdminQueryType.ID, label: t("list.filter.queryType.id") },
              { value: AdminQueryType.PHONE_NUMBER, label: t("list.filter.queryType.phoneNumber") },
            ],
            onChangeFunc: (it) => searchWordTypeFilter.setter(it as AdminQueryType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.createdAt.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtFromFilter.value),
            onChangeFunc: (v) => createdAtFromFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("list.filter.createdAt.placeholder"),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtToFilter.value),
            onChangeFunc: (v) => createdAtToFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("list.filter.createdAt.placeholder"),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.role"),
        content: {
          type: FilterContentType.SELECT,
          value: adminRoleIdxFilter.value,
          options: roleOptions,
          onChangeFunc: (it) => adminRoleIdxFilter.setter(it as AdminStatus),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.filter.status"),
        content: {
          type: FilterContentType.SELECT,
          value: adminStatusFilter.value,
          options: getAdminStatusOptions(),
          onChangeFunc: (it) => adminStatusFilter.setter(it as AdminStatus),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
    ],
  ];

  return {
    filters,
  };
}
