import style from "../../../layout/content/post/creator.module.scss";
import React, { useEffect, useMemo, useState } from "react";
import { useOrganManagerColumns } from "../../member/organmanager/hooks/useOrganManagerColumns";
import { BodyScollTable } from "../../../layout/content/list/table/BodyScrollTable";
import { useConditionalOrganManagerList } from "../../member/organmanager/hooks/useConditionalOrganManagerList";
import { TableColumnData } from "../../../types/common";
import { OrganManagerListDTO, OrganManagerStatus } from "../../../types/organManager";
import { ActionButton, RedActionButton } from "../../../components/Buttons";
import { useUtil } from "../../../util/hooks/useUtil";
import { Table } from "../../../layout/content/list/table/Table";
import { useLoader } from "../../../hooks/common/useLoader";
import { useAppSelector } from "../../../hooks/hooks";
import { organManagerStatus } from "../../../store/organManagerSlice";

interface Props {
  selectedOrganManagerIdx: number | null;
  setSelectedOrganManagerIdx: (_: number | null) => void;
}

export function CodeOrganManagers({ selectedOrganManagerIdx, setSelectedOrganManagerIdx }: Props) {
  const [searchWord, setSearchWord] = useState("");
  const { getConditionalOrganManagerList, list } = useConditionalOrganManagerList({ searchWord });
  const { columnsInCodeCreator } = useOrganManagerColumns();
  const [selectedOrganManager, setSelectedOrganManager] = useState<OrganManagerListDTO | null>(null);
  const { getDefaultPageResponseObject } = useUtil();
  const status = useAppSelector(organManagerStatus);
  useLoader({ status });

  useEffect(() => {
    if (selectedOrganManager) {
      setSelectedOrganManagerIdx(selectedOrganManager.idx);
    } else {
      setSelectedOrganManagerIdx(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganManager]);

  const refinedColumns: TableColumnData<OrganManagerListDTO>[] = useMemo(() => {
    const copiedColumns = [...columnsInCodeCreator];
    copiedColumns.push({
      accessor: "idx",
      header: "",
      sortable: false,
      getNode: (it: OrganManagerListDTO) => (
        <>
          {selectedOrganManagerIdx !== null && selectedOrganManagerIdx === it.idx ? (
            <span className={"blue"}>추가됨</span>
          ) : it.status === OrganManagerStatus.DEFAULT ? (
            <ActionButton
              onClick={(e) => {
                e.preventDefault();

                if (selectedOrganManager) {
                  alert("이미 보유자가 선택되어있습니다.\n선택된 보유자를 삭제하고 다시 시도해주세요.");
                  return;
                }

                setSelectedOrganManager(it);
              }}
            >
              선택
            </ActionButton>
          ) : (
            <></>
          )}
        </>
      ),
      width: 78,
    });

    return copiedColumns;
  }, [columnsInCodeCreator, selectedOrganManager, selectedOrganManagerIdx]);

  const addedItemColumns: TableColumnData<OrganManagerListDTO>[] = useMemo(() => {
    const copiedColumns = [...refinedColumns];
    copiedColumns.pop();
    return [
      ...copiedColumns,
      {
        accessor: "idx",
        header: "",
        sortable: false,
        getNode: () => <RedActionButton onClick={() => setSelectedOrganManager(null)}>삭제</RedActionButton>,
      },
    ];
  }, [refinedColumns]);

  useEffect(() => {
    getConditionalOrganManagerList({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={style.tableArea}>
      <div className={style.textInput}>
        <input
          className={style.searchWordInput}
          type={"text"}
          value={searchWord}
          placeholder={"등록할 소속관리자 회원명 검색"}
          onChange={(e) => setSearchWord(e.target.value)}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            getConditionalOrganManagerList({})();
          }}
        >
          검색
        </button>
      </div>
      <div className={style.list}>
        {list && (
          <BodyScollTable
            listData={list}
            columns={refinedColumns}
            getList={getConditionalOrganManagerList}
            noDataText={"검색 결과가 없습니다."}
            tableClassName={style.listTable}
            tbodyClassName={style.listTableBody}
            noResultRowClassName={style.noResultRow}
          />
        )}
      </div>
      <div className={style.selectedItem}>
        <h3 className={style.title}>선택된 보유자(소속관리자 회원)</h3>
        <div className={style.list}>
          {selectedOrganManager ? (
            <Table
              listData={getDefaultPageResponseObject([selectedOrganManager])}
              columns={addedItemColumns}
              noDataText={""}
              getList={() => () => {}}
            ></Table>
          ) : (
            <span className={style.noItem}>선택된 보유자가 없습니다.</span>
          )}
        </div>
      </div>
    </div>
  );
}
