import { fillZeroTo2Digit } from "./etcUtil";

export const dateTimeStringToString = (s: string) => (s ? dateTimeToString(new Date(s)) : "-");
export const dateStringToDottedString = (s: string) => (s ? dateToDottedString(new Date(s)) : "-");

export const dateTimeToString = (date: Date) => `${dateToString(date)} ${timeToString(date)}`;

export const timeToString = (date: Date) =>
  `${fillZeroTo2Digit(date.getHours())}:${fillZeroTo2Digit(date.getMinutes())}:${fillZeroTo2Digit(date.getSeconds())}`;
export const dateToString = (date: Date) =>
  `${date.getFullYear()}-${fillZeroTo2Digit(date.getMonth() + 1)}-${fillZeroTo2Digit(date.getDate())}`;

export const dateToDottedString = (date: Date) =>
  `${date.getFullYear()}.${fillZeroTo2Digit(date.getMonth() + 1)}.${fillZeroTo2Digit(date.getDate())}`;

export const getStartDateTimeFromDate = (date: Date) => date.toISOString();
export const getEndDateTimeFromDate = (date: Date) => {
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString();
};
export const isValidDate = (d: Date) => d instanceof Date && !isNaN(d.getTime());
