import style from "../productEditForm.module.scss";
import React, {useEffect} from "react";
import {Label104x46} from "../../../../components/Label";
import {useTranslation} from "react-i18next";
import {Languages} from "../../../../types/common";
import {DisplayPosition} from "../../../../types/product";
import {Radio, RadioGroup} from "../../../../components/RadioGroup";
import {useProductType} from "../../../../types/hooks/useProductType";

interface Props {
    language: Languages;
    setLanguage: (_: Languages) => void;
    displayPosition: DisplayPosition;
    setDisplayPosition: (_: DisplayPosition) => void;
}

export function ProductEditLanguage({ language, setLanguage, displayPosition, setDisplayPosition }: Props) {
    const { t } = useTranslation("product");
    const {toStringFromLanguage} = useProductType();
    useEffect(() =>{
        setLanguage(Languages.KO);

        //eslint-disable-next-line
    },[setDisplayPosition])

    const radios: Radio<Languages>[] = [
        {
            check: (type) => type === Languages.KO,
            onClickFunc: () => {
                setLanguage(Languages.KO);
            },
            text: toStringFromLanguage(Languages.KO),
        },
        {
            check: (type) => type === Languages.EN,
            onClickFunc: () => {
                setLanguage(Languages.EN);
            },
            text: toStringFromLanguage(Languages.EN),
        },
        {
            check: (type) => type === Languages.RU,
            onClickFunc: () => {
                setLanguage(Languages.RU);
            },
            text: toStringFromLanguage(Languages.RU),
            isHide : (displayPosition === DisplayPosition.USER_WEB_DCAS
                || displayPosition === DisplayPosition.ORGAN_WEB_DCAS),
        },
        {
            check: (type) => type === Languages.ZH,
            onClickFunc: () => {
                setLanguage(Languages.ZH);
            },
            text: toStringFromLanguage(Languages.ZH),
            isHide : (displayPosition === DisplayPosition.USER_WEB_KPASS
                || displayPosition === DisplayPosition.ORGAN_WEB_KPASS),
        },
    ];

    return (
        <div className={style.field}>
            <Label104x46 className={style.label} text={t("language.title")} />
            <RadioGroup
                radios={radios}
                isRequired={true}
                groupName={"language"}
                selectedValue={language}
            />
        </div>
    );
}
