import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import {
  getAffiliateCodeSettlementStaticsAsync,
  getAffiliateCodeSettlementStaticsByOrderIdxAsync,
} from "../../../../store/affiliateCodeSlice";
import {
  AffiliateCodeSettlementStaticsParam,
  MonthlyAffiliateCodeStatisticsDTO,
  MonthlyAffiliateCodeStatisticsVO,
  OrganManagerMonthlyAffiliateCodeStaticsDTO,
  OrganManagerMonthlyAffiliateCodeStaticsVO,
} from "../../../../types/affiliateCode";

export function useAffiliateCodeSettlementStaticsGetter() {
  const dispatch = useAppDispatch();

  const getAffiliateCodeSettlementStaticsByOrderIdx = useCallback(
    async (param: AffiliateCodeSettlementStaticsParam) => {
      try {
        const result: OrganManagerMonthlyAffiliateCodeStaticsDTO = await dispatch(
          getAffiliateCodeSettlementStaticsByOrderIdxAsync(param)
        ).unwrap();

        return {
          ...result,
        } as OrganManagerMonthlyAffiliateCodeStaticsVO;
      } catch (e) {
        console.error(e);
        throw new Error(
          "useAffiliateCodeSettlementStaticsGetter - getAffiliateCodeSettlementStaticsByOrderIdx occurs an error"
        );
      }
    },
    [dispatch]
  );

  const getAffiliateCodeSettlementStatics = useCallback(
    async (year: number, month: number) => {
      try {
        const result: MonthlyAffiliateCodeStatisticsDTO = await dispatch(
          getAffiliateCodeSettlementStaticsAsync({ year, month })
        ).unwrap();

        return {
          ...result,
        } as MonthlyAffiliateCodeStatisticsVO;
      } catch (e) {
        console.error(e);
        throw new Error("useAffiliateCodeSettlementStaticsGetter - getAffiliateCodeSettlementStatics occurs an error");
      }
    },
    [dispatch]
  );

  return {
    getAffiliateCodeSettlementStaticsByOrderIdx,
    getAffiliateCodeSettlementStatics,
  };
}
