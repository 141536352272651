import { useCallback, useEffect, useMemo } from "react";
import { SelectorOption } from "../../../types/selector";
import { callAsync } from "../../../util/sliceUtil";
import { adminRoles, getRolesAsync, setAdminRoles } from "../../../store/adminRoleSlice";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { AdminRoleCategory } from "../../../types/adminRole";
import { useUtil } from "../../../util/hooks/useUtil";
import { useRole } from "../../../hooks/useRole";

interface Args {
  isRoot?: boolean;
}
export function useRoleGetter({ isRoot }: Args) {
  const dispatch = useAppDispatch();
  const roles = useAppSelector(adminRoles);
  const { defaultErrorMessage } = useUtil();
  const { getRoleText } = useRole();

  const adminRoleOptions: SelectorOption[] = useMemo(
    () =>
      roles
        ?.filter((it) => {
          if (!it.category) return false;
          if (it.category.toString().startsWith("CONSULTANT_")) return false;
          if (isRoot === false) return it.category !== AdminRoleCategory.ADMIN_ROOT;

          return true;
        })
        .map((it) => {
          return {
            label: getRoleText(it.category),
            value: it.idx.toString(),
          };
        })
        .sort((a, b) => (a.label < b.label ? -1 : 1)) ?? [],
    [roles, isRoot, getRoleText]
  );

  const getAdminRole = useCallback(() => {
    callAsync(
      dispatch(getRolesAsync()).unwrap(),
      (it) => dispatch(setAdminRoles(it.content)),
      () => alert(defaultErrorMessage)
    ).then();
  }, [dispatch, defaultErrorMessage]);

  useEffect(() => {
    if (adminRoleOptions.length === 0) {
      getAdminRole();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getRoleNameByRoleIdx = useCallback(
    (roleIdx: number) => {
      const category = roles?.find((role) => role.idx === roleIdx)?.category;
      if (category) {
        return getRoleText(category);
      }
    },
    [roles, getRoleText]
  );

  return {
    adminRoleOptions,
    roles,
    getRoleNameByRoleIdx,
  };
}
