import { useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../../types/page";
import {
  VoucherHistoryListObj,
  VoucherHistoryListVO,
  VoucherHistorySearchCondition,
} from "../../../../../types/voucher";
import { callAsync } from "../../../../../util/sliceUtil";
import { getVoucherHistoryListAsync } from "../../../../../store/voucherSlice";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { useVoucherType } from "../../../../../types/hooks/useVoucherType";
import { useUtil } from "../../../../../util/hooks/useUtil";

const LIST_SIZE = 5;

interface Args {
  userIdx: number;
  isAvailable: boolean;
}

export function useUserVoucherInfoList({ userIdx, isAvailable }: Args) {
  const dispatch = useAppDispatch();

  const [voucherHistoryList, setVoucherHistoryList] = useState<PageResponse<VoucherHistoryListVO>>();
  const { toVoucherHistoryListVO } = useVoucherType();
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: voucherHistoryList?.number ?? 0,
      size: LIST_SIZE,
      sort: ordersToSortArr(voucherHistoryList?.orders),
    }),
    [voucherHistoryList]
  );

  const getVoucherHistoryList = ({ page, sort }: PageSearchCondition) => {
    const param: VoucherHistorySearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: LIST_SIZE,
        sort: sort ?? pageCondition.sort,
      },
      userIdx,
      usable: isAvailable,
    };

    return () => {
      callAsync<PageResponse<VoucherHistoryListObj>>(
        dispatch(getVoucherHistoryListAsync(param)).unwrap(),
        (result) => {
          const voResult: PageResponse<VoucherHistoryListVO> = {
            ...result,
            content: result.content.map((dto) => toVoucherHistoryListVO(dto)),
          };
          setVoucherHistoryList(voResult);
        },
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  return {
    pageCondition,
    getVoucherHistoryList,
    voucherHistoryList,
  };
}
