import {Panel} from "../../../../../layout/content/detail/Panel";
import React, {useMemo} from "react";
import {DescriptionValue} from "../../../../../layout/content/detail/DescriptionList";
import style from "../../memberUserCoupons.module.scss";
import {useTranslation} from "react-i18next";
import {useLanguage} from "../../../../../hooks/hooks";
import {useUserOrganVoucherCurrentStatus} from "../../coupon_and_voucher/hooks/useUserOrganVoucherCurrentStatus";
import KPASS_LOGO from "../../../../../assets/images/K-PASS_logo_in_tester.svg";
import DCSS_LOGO from "../../../../../assets/images/D-CAS_logo_in_tester.svg";
import {VoucherType} from "../../../../../types/voucher";
import {VoucherList} from "../../../../../layout/content/detail/VoucherList";

interface Props {
  profileIdx: number;
}
interface OrganVoucherResponse {
  unitVoucherType: VoucherType;
  receivedOrganVoucherCount: number;
  availableOrganVoucherCount: number;
  collectedOrganVoucherCount: number;
}

export function TesterOrganVoucherStatus({ profileIdx }: Props) {
  const { t } = useTranslation("user");
  const { userOrganVoucherNewStatusInfo } = useUserOrganVoucherCurrentStatus({ userIdx: profileIdx, isTesterType: true });

  const { isKorean } = useLanguage();

  const voucherStatusDescriptionValues= (
      //eslint-disable-next-line
      logo: string, organVoucherResponse: OrganVoucherResponse | undefined) => [
      {
        key: t(""),
        value: <img className={style.logoImg} src={logo} alt={logo}/>,
      },
        {
          key: t("detail.profile.organVoucher.status.grantedVoucher"),
          value: t("voucherNoUnit",
              { count: organVoucherResponse?.receivedOrganVoucherCount ?? 0 }),
        },
        {
          key: t("detail.profile.organVoucher.status.availableVoucher"),
          value: t("voucherNoUnit",
              { count: organVoucherResponse?.availableOrganVoucherCount ?? 0 }),
        },
        {
          key: t("detail.profile.organVoucher.status.collectedVoucher"),
          value: t("voucherNoUnit",
              { count: organVoucherResponse?.collectedOrganVoucherCount ?? 0 }),
        },
    ];

  const kpassVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [voucherStatusDescriptionValues(KPASS_LOGO, userOrganVoucherNewStatusInfo?.voucherDTOs[0])];
    //eslint-disable-next-line
  }, [t, userOrganVoucherNewStatusInfo]);

  const dcasVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [voucherStatusDescriptionValues(DCSS_LOGO, userOrganVoucherNewStatusInfo?.voucherDTOs[1])];
    //eslint-disable-next-line
  }, [t, userOrganVoucherNewStatusInfo]);

  const voucherStatusList: DescriptionValue[][][] = useMemo(() => {
    return [kpassVoucherStatus, dcasVoucherStatus];
  }, [kpassVoucherStatus, dcasVoucherStatus]);

  return (
      <Panel title={t("detail.profile.organVoucher.status.title")}>
        {voucherStatusList.map((data, index) => (
            <React.Fragment key={index}>
              <VoucherList
                  data={data}
                  labelWidth={130}
                  valueWidth={isKorean ? 100 : 80}
                  valueClassName={style.statusCell}
              />
            </React.Fragment>
        ))}
      </Panel>
  );
}
