import { useAppDispatch } from "../../../hooks/hooks";
import { useCallback, useState } from "react";
import { getOrderItemsAsync } from "../../../store/orderSlice";
import { OrderItemDTO, OrderItemVO, OrderTranslator } from "../../../types/orders";

export default function useOrderItemGetter() {
  const dispatch = useAppDispatch();
  const [orderItems, setOrderItems] = useState<OrderItemVO[]>([]);

  const getOrderItems = useCallback(
    (orderIdx: number): Promise<OrderItemVO[]> => {
      return dispatch(getOrderItemsAsync(orderIdx))
        .unwrap()
        .then((it: OrderItemDTO[]) => {
          const orderItems = it.map(OrderTranslator.createOrderItemVO);
          setOrderItems(orderItems);
          return orderItems;
        });
    },
    [dispatch]
  );

  return {
    orderItems,
    getOrderItems,
  };
}
