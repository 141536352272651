import style from "./modal.module.scss";
import closeBtn from "../../assets/images/modal/close_btn.svg";
import { useTranslation } from "react-i18next";

interface Props {
  children: JSX.Element;
  contentStyle: string;
  show: boolean;
  closeModal: VoidFunction;
  hasBg: boolean;
}

export function Modal({ children, contentStyle, show, closeModal, hasBg }: Props) {
  const { t } = useTranslation("common");

  return (
    <div className={`${style.modal} ${hasBg ? "" : style.noBg} ${show ? style.show : ""}`}>
      <div className={`${style.content} ${contentStyle}`}>
        <button className={style.closeBtn} onClick={closeModal}>
          <img src={closeBtn} alt={t("modalCloseIcon")} />
        </button>
        {children}
      </div>
    </div>
  );
}
