import {useCallback} from "react";
import {updateOrganManagerPermissionAsync} from "../../../store/organManagerSlice";
import {useAppDispatch} from "../../../hooks/hooks";
import {OrganManagerPermissionPutDTO} from "../../../types/organManager";

export function useOrganManagerPermissionUpdater() {
    const dispatch = useAppDispatch();

    const updateOrganManagerPermission = useCallback(
        async ({
                   organManagerIdx,
                   accessibleMenuPutDTO,
                   voucherPurchaseRestrictions,
                   kpassAdditionalResultForm,
                   kpassResultForm,
                   dcasAdditionalResultForm,
                   dcasResultForm
               }
                   : { organManagerIdx: number } & OrganManagerPermissionPutDTO) => {

            try {
                await dispatch(
                    updateOrganManagerPermissionAsync({
                        organManagerIdx: organManagerIdx,
                        condition:{accessibleMenuPutDTO,
                            voucherPurchaseRestrictions,
                            kpassAdditionalResultForm,
                            kpassResultForm,
                            dcasAdditionalResultForm,
                            dcasResultForm}
                    })
                ).unwrap();
            } catch (e) {
                console.error(e);
                throw new Error("소속관리자 권한 수정 에러");
            }
        },
        [dispatch]
    );
    return {
        updateOrganManagerPermission
    }
}