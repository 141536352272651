import {useAppDispatch, useTimeConverter} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useFile} from "../../../../hooks/file/useFile";
import {useCallback} from "react";
import {BannerPutVo} from "../../../../types/banner";
import {FileClassification, FileResponse} from "../../../../types/file";
import {setIsBannerDataSaved, updateBannerAsync} from "../../../../store/bannerSlice";

export function useBannerUpdater() {
    const dispatch = useAppDispatch();
    const {defaultErrorMessage} = useUtil();
    const {uploadImages} = useFile();
    const {timeConverter} = useTimeConverter();

    const updateBanner = useCallback(
        async (idx: number, putVO: BannerPutVo) => {
            try {
                let bannerImage = putVO.image;

                if (putVO.addedImage) {
                    const uploadedFile: FileResponse[] = await uploadImages(
                        [putVO.addedImage.file],
                        FileClassification.BANNER_IMAGE
                    );
                    bannerImage = {
                        uri: uploadedFile[0].uri,
                        fileIdx: uploadedFile[0].idx,
                    };
                }
                await dispatch(
                    updateBannerAsync({
                        bannerIdx: idx,
                        param: {
                            name: putVO.name.trim(),
                            image: bannerImage,
                            availableStartAt: timeConverter.convertStartOfDayToUTC(putVO.availableStartAt),
                            availableEndAt: putVO.availableEndAt
                                ? timeConverter.convertEndOfDayToUTC(putVO.availableEndAt)
                                : undefined,
                            type: putVO.type,
                            description1:putVO.description1,
                            description2:putVO.description2,
                            location: putVO.bannerLocation,
                            buttonDescription:putVO.buttonDescription,
                            buttonLink:putVO.buttonLink,
                            linkOption: putVO.linkOption,
                            status: putVO.status,
                            websiteType: putVO.websiteType,
                        },
                    })
                ).unwrap();
                dispatch(setIsBannerDataSaved(true));
                alert("배너가 수정 되었습니다.");
            } catch (e) {
                console.error(e);
                alert(defaultErrorMessage);
                throw new Error("배너 수정 에러");
            }
        },
        [defaultErrorMessage, dispatch, timeConverter, uploadImages]
    );

    return {updateBanner};
}