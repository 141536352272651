const banks = [
  "KDB산업",
  "기업",
  "국민",
  "하나",
  "수협",
  "농협",
  "지역축농협",
  "우리",
  "신한",
  "SC제일",
  "한국씨티",
  "대구",
  "부산",
  "광주",
  "제주",
  "전북",
  "경남",
  "새마을금고",
  "신협",
  "산림조합",
  "우체국",
  "케이뱅크",
  "카카오뱅크",
  "토스뱅크",
];
export default banks;
