import {useCallback} from "react";
import {CookieKeys, useCookie} from "./auth/useCookie";

export function useOpenSimpleTestResult() {
    const {getValueFromCookie} = useCookie();

    return useCallback(
        (idxInfo: string | undefined) => {
            const token = getValueFromCookie(CookieKeys.ACCESS_TOKEN);
            const type = 'dcas-simple';

            if (token) {
                window.open(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/${type}/test-result/${idxInfo}`,
                    "_blank"
                );
            }
        },
        //eslint-disable-next-line
        [getValueFromCookie]
    );
}
