import { useCallback, useState } from "react";
import { OrdersDTO, OrdersVO, OrderTranslator } from "../../../types/orders";
import { getOrdersAsync } from "../../../store/orderSlice";
import { useAppDispatch } from "../../../hooks/hooks";

export default function useOrderGetter() {
  const dispatch = useAppDispatch();
  const [orders, setOrders] = useState<OrdersVO>();

  const getOrders = useCallback(
    (orderIdx: number): Promise<OrdersVO> => {
      return dispatch(getOrdersAsync(orderIdx))
        .unwrap()
        .then((orderDTO: OrdersDTO) => {
          let ordersVO = OrderTranslator.createOrdersVO(orderDTO);
          setOrders(ordersVO);
          return ordersVO;
        });
    },
    [dispatch]
  );

  return {
    orders,
    getOrders,
  };
}
