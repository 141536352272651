import React, { useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { couponStatus } from "../../../../store/couponSlice";
import { useLoader } from "../../../../hooks/common/useLoader";
import { CouponInfoStatus } from "./CouponInfoStatus";
import { CouponInfoList } from "./CouponInfoList";
import { CustomerType } from "../../../../types/common";
import { useCouponCntInfo } from "../../../coupon/hooks/useCouponCntInfo";
import { useCouponList } from "./hooks/useCouponList";

interface Props {
  targetIdx: number;
  customerType: CustomerType;
}

export function CouponInfo({ targetIdx, customerType }: Props) {
  const status = useAppSelector(couponStatus);
  useLoader({ status });

  const { couponStatusInfo, getCouponCntInfo } = useCouponCntInfo({
    targetParam: {
      targetIdx,
      targetType: customerType,
    },
  });

  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const { couponHistoryList, getCouponHistoryList, pageCondition } = useCouponList({
    targetIdx,
    customerType,
    isAvailable,
  });

  return (
    <>
      <CouponInfoStatus
        targetIdx={targetIdx}
        customerType={customerType}
        couponStatusInfo={couponStatusInfo}
        refreshAll={() => {
          getCouponCntInfo();
          getCouponHistoryList({})();
        }}
      />
      <CouponInfoList
        targetIdx={targetIdx}
        customerType={customerType}
        isAvailable={isAvailable}
        setIsAvailable={setIsAvailable}
        couponHistoryList={couponHistoryList}
        getCouponHistoryList={getCouponHistoryList}
        pageCondition={pageCondition}
      />
    </>
  );
}
