import { useAppDispatch, useAppSelector, useAuth } from "../hooks";
import { AccessibleMenuContext } from "./AccessibleMenuContext";
import { accessibleMenuListByRole, getMyAccessibleMenuAsync, setAccessibleMenuList } from "../../store/adminRoleSlice";
import { useCallback, useEffect } from "react";
import { callAsync } from "../../util/sliceUtil";
import { AccessibleMenu } from "../../types/adminRole";
import { useTranslation } from "react-i18next";

export function AccessibleMenuProvider({ children }: { children: JSX.Element }) {
  const { t } = useTranslation("common");
  const dispatch = useAppDispatch();
  const accessibleMenus = useAppSelector(accessibleMenuListByRole);
  const { isLoggedIn } = useAuth();

  const getMyAccessibleMenus = useCallback(() => {
    callAsync<AccessibleMenu[]>(
      dispatch(getMyAccessibleMenuAsync()).unwrap(),
      (result) => {
        dispatch(setAccessibleMenuList(result.filter((am) => !am.isDeleted)));
      },
      () => {
        alert(t("errorWhileLoadingMenus"));
      }
    ).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    if (isLoggedIn && !accessibleMenus) {
      getMyAccessibleMenus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessibleMenus, isLoggedIn]);

  return (
    <AccessibleMenuContext.Provider
      value={{
        accessibleMenus: accessibleMenus ?? [],
      }}
    >
      {children}
    </AccessibleMenuContext.Provider>
  );
}
