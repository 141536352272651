import {useAppDispatch} from "../../../../hooks/hooks";
import {useUtil} from "../../../../util/hooks/useUtil";
import {useCallback} from "react";
import {BannerDetailVO, BannerDto} from "../../../../types/banner";
import {getBannerDetailAsync} from "../../../../store/bannerSlice";

interface Props{
    bannerIdx: number;
}
export default function useBannerDetailGetter({bannerIdx} : Props) {

    const dispatch = useAppDispatch();
    const { defaultErrorMessage } = useUtil();

    const getBannerDetail = useCallback(async (): Promise<BannerDetailVO> => {
        try {
            const dto: BannerDto = await dispatch(getBannerDetailAsync(bannerIdx)).unwrap();
            return { ...dto } as BannerDetailVO;
        } catch (e) {
            console.error(e);
            alert(defaultErrorMessage);
            throw new Error("배너 상세 조회 에러");
        }
    }, [dispatch, defaultErrorMessage, bannerIdx]);

    return {
        getBannerDetail
    };
};