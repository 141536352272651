import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";

export const Anchor = styled.a`
  font-size: 14px;
  text-decoration-line: underline !important;
  color: ${variables.color75};

  &:hover {
    color: ${variables.colorFGBlue};
  }
`;
