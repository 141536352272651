import { useAppDispatch } from "../../../../../hooks/hooks";
import { useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../../../types/page";
import {
  OrganVoucherListDTO,
  ProfileOrganVoucherListVO,
  ProfileVoucherHistorySearchCondition,
} from "../../../../../types/voucher";
import { callAsync } from "../../../../../util/sliceUtil";
import { getProfileOrganVoucherHistoryListAsync } from "../../../../../store/voucherSlice";
import { useUtil } from "../../../../../util/hooks/useUtil";

interface Args {
  profileIdx: number;
  isAvailable: boolean;
}

const LIST_SIZE = 5;

export function useTesterOrganVoucherListGetter({ profileIdx, isAvailable }: Args) {
  const dispatch = useAppDispatch();
  const [profileOrganVoucherHistoryList, setProfileOrganVoucherHistoryList] =
    useState<PageResponse<ProfileOrganVoucherListVO>>();
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: profileOrganVoucherHistoryList?.number ?? 0,
      size: LIST_SIZE,
      sort: ordersToSortArr(profileOrganVoucherHistoryList?.orders),
    }),
    [profileOrganVoucherHistoryList]
  );

  const getOrganVoucherHistoryList = ({ page, sort }: PageSearchCondition) => {
    const param: ProfileVoucherHistorySearchCondition = {
      pageCondition: {
        page: page ?? 0,
        size: LIST_SIZE,
        sort: sort ?? pageCondition.sort,
      },
      profileIdx,
      usable: isAvailable,
    };

    return () => {
      callAsync<PageResponse<any>>(
        dispatch(getProfileOrganVoucherHistoryListAsync(param)).unwrap(),
        (result: PageResponse<OrganVoucherListDTO>) => {
          setProfileOrganVoucherHistoryList({
            ...result,
            content: result.content.map((dto) => OrganVoucherListDTO.toProfileOrganVoucherListVO(dto)),
          });
        },
        () => alert(defaultErrorMessage)
      ).then();
    };
  };

  return {
    pageCondition,
    getOrganVoucherHistoryList,
    profileOrganVoucherHistoryList,
  };
}
