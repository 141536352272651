import { UnitItemListObj } from "../../types/unitItem";
import { useUnitItemFilters } from "./hooks/useUnitItemFilters";
import { useUnitItemColumns } from "./hooks/useUnitItemColumns";
import { useUnitItemList } from "./hooks/useUnitItemList";
import React, { useState } from "react";
import { ListTable } from "../../layout/content/list/ListTable";
import deleteIcon from "../../assets/images/button_icons/delete_icon.svg";
import confirmIcon from "../../assets/images/button_icons/confirm_icon.svg";
import { callAsync } from "../../util/sliceUtil";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../hooks/hooks";
import { deleteUnitItemsAsync, unitItemStatus } from "../../store/unitItemSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../util/hooks/useUtil";
import { ListCount, WhichList } from "../../components/ListCount";

export function UnitItemList() {
  const { t } = useTranslation("unitItem");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const status = useAppSelector(unitItemStatus);
  useLoader({ status });

  const canBeDeleted = (ui: UnitItemListObj) => ui.productItemCnt === 0 && ui.salesCnt === 0;

  const [selectedUnitItemIdxList, setSelectedUnitItemIdxList] = useState<number[]>([]);

  const { searchUnitItemList, totalCnt, unitItemList, pageCondition } = useUnitItemList({
    onCompletedCallback: () => setSelectedUnitItemIdxList([]),
  });
  const { filters } = useUnitItemFilters({ search: searchUnitItemList({}) });
  const columns = useUnitItemColumns({ canBeDeleted });
  const { openUnitItemCreator } = useNewWindow();

  const onClickDeleteBtn = () => {
    if (selectedUnitItemIdxList.length === 0) {
      alert(t("list.noSelectedItem"));
      return;
    }

    const isConfirmed = window.confirm(t("list.delete.confirm"));
    if (isConfirmed) {
      callAsync(
        dispatch(deleteUnitItemsAsync(selectedUnitItemIdxList)).unwrap(),
        () => {
          setSelectedUnitItemIdxList([]);
          searchUnitItemList(pageCondition)();
        },
        () => alert(defaultErrorMessage)
      ).then();
    }
  };

  const onClickCreateUnitItemBtn = () => {
    openUnitItemCreator(searchUnitItemList(pageCondition));
  };

  return (
    <>
      {unitItemList ? (
        <ListTable
          pageOnFilter={pageCondition}
          noDataText={t("list.noData")}
          filters={filters}
          listData={unitItemList}
          getList={({ page, sort, size }) => searchUnitItemList({ page, sort, size })}
          columns={columns}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalCnt} whichList={WhichList.UNIT_ITEM} />,
            singleUnitKeyInCommonFile: "unitItemUnit.singleComponent",
            multipleUnitKeyInCommonFile: "unitItemUnit.multipleComponent",
          }}
          checkedRowIdxArr={selectedUnitItemIdxList}
          setCheckedRowIdxArr={setSelectedUnitItemIdxList}
          showCheckbox={(ui) => canBeDeleted(ui)}
          buttonsNode={
            <>
              <div>
                <button onClick={onClickDeleteBtn}>
                  <img src={deleteIcon} alt={t("list.delete.icon")} />
                  {t("list.delete.button")}
                </button>
              </div>
              <div>
                <button onClick={onClickCreateUnitItemBtn}>
                  <img src={confirmIcon} alt={t("list.create.icon")} />
                  {t("list.create.button")}
                </button>
              </div>
            </>
          }
        />
      ) : (
        <></>
      )}
    </>
  );
}
