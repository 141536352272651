import styled from "styled-components";
import variables from "../assets/scss/variable.module.scss";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 50px;
  color: #333;
  overflow-x: hidden;
  position: relative;
`;

export const H1 = styled.h1`
  font-size: 40px;
`;

export const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const H2 = styled.h2`
  font-size: 30px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
`;

export const Pre = styled.pre`
  font-size: 16px;
  font-family: monospace;
  background-color: #333;
  color: white;
  padding: 10px 20px;
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
`;

export const PreDescription = styled(Pre)`
  background-color: white;
  border: 1px solid ${variables.colorDE};
  color: #333;
`;

export const Nav = styled.nav`
  font-size: 18px;
  border: 1px solid ${variables.colorDE};
  padding: 30px;
`;

export const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const NavListItem = styled.li``;

export const TopButton = styled.button`
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  border: 1px solid ${variables.colorFGBlue};
  border-radius: 5px;
  color: ${variables.colorFGBlue};
  background-color: white;

  &:hover {
    background-color: ${variables.colorF6};
  }
`;
