import { FilterContentType, TableFilter } from "../../../types/tableFilter";
import { CouponStatus, CouponType } from "../../../types/coupon";
import { useLanguage, useListFilterValues, useTimeConverter } from "../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useCouponType } from "../../../types/hooks/useCouponType";
import { useMemo } from "react";

interface Args {
  search: () => void;
}

interface ReturnType {
  filters: TableFilter[][];
}

export function useCouponFilters({ search }: Args): ReturnType {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation("coupon");
  const { timeConverter } = useTimeConverter();
  const { couponStatusOptions, couponTypeOptionsExceptGoods } = useCouponType();
  const { isKorean } = useLanguage();

  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const createdAtFromFilter = useMemo(
    () => filterValues.createdAtFromFilterValue,
    [filterValues.createdAtFromFilterValue]
  );
  const createdAtToFilter = useMemo(() => filterValues.createdAtToFilterValue, [filterValues.createdAtToFilterValue]);
  const couponTypeFilter = useMemo(() => filterValues.couponTypeFilterValue, [filterValues.couponTypeFilterValue]);
  const couponStatusFilter = useMemo(
    () => filterValues.couponStatusFilterValue,
    [filterValues.couponStatusFilterValue]
  );

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.search.label"),
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter.value,
          maxLength: 100,
          placeholderVal: t("list.filter.search.placeholder"),
          onChangeFunc: (v) => searchWordFilter.setter(v),
          onEnterKeyUpFunc: search,
        },
      },
      {
        labelTitle: t("list.filter.createdAt.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: createdAtFromFilter.value ? timeConverter.convertToFilterDateString(createdAtFromFilter.value) : "",
            onChangeFunc: (v) => createdAtFromFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("list.filter.createdAt.placeholder"),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: createdAtToFilter.value ? timeConverter.convertToFilterDateString(createdAtToFilter.value) : "",
            onChangeFunc: (v) => createdAtToFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("list.filter.createdAt.placeholder"),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.type"),
        content: {
          type: FilterContentType.SELECT,
          value: couponTypeFilter.value,
          options: couponTypeOptionsExceptGoods,
          onChangeFunc: (v) => couponTypeFilter.setter(v as CouponType),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.filter.status"),
        content: {
          type: FilterContentType.SELECT,
          value: couponStatusFilter.value,
          options: couponStatusOptions,
          onChangeFunc: (v) => couponStatusFilter.setter(v as CouponStatus),
          width: isKorean ? undefined : 200,
        },
      },
    ],
  ];

  return {
    filters,
  };
}
