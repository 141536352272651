import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Selector } from "../../../layout/content/selector/Selector";
import { PageSizeSelector } from "../../../layout/content/selector/PageSizeSelector";
import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { CountrySelector } from "../../../components/CountrySelector";
import { useState } from "react";

export function ArticleSelector({ id, title }: ArticleProps) {
  const [countryCode, setCountryCode] = useState("");

  const code1 = `
<Selector isPageSizeSelector={false} 
          options={[
            {label: "전체", value: ""},
            {label: "동해물", value: "dong"},
            {label: "과백두산이", value: "back"}
          ]}
          selectedValue={""}
          setSelectorOption={() => {}}/>
  `;
  const code2 = `
<PageSizeSelector pageSize={20} 
                  setPageSize={() => {}}/>
  `;
  const code3 = `
<CountrySelector selectedCountryCodeISOAlpha2={countryCode} 
                 setSelectedCountryCodeISOAlpha2={setCountryCode} />
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code1}>
          <Selector
            isPageSizeSelector={false}
            options={[
              { label: "전체", value: "" },
              { label: "동해물", value: "dong" },
              { label: "과백두산이", value: "back" },
            ]}
            selectedValue={""}
            setSelectorOption={() => {}}
          />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={code2}>
          <PageSizeSelector pageSize={20} setPageSize={() => {}} />
        </ListItemViewAndCode>
        <ListItemViewAndCode exampleCode={code3}>
          <>
            CountrySelector:{" "}
            <CountrySelector
              selectedCountryCodeISOAlpha2={countryCode}
              setSelectedCountryCodeISOAlpha2={setCountryCode}
            />
          </>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
