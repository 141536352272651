import { Panel } from "../../../../../layout/content/detail/Panel";
import { useConditionalAffiliateCodeList } from "../../../../affiliate_code/hooks/code/useConditionalAffiliateCodeList";
import { useEffect, useState } from "react";
import { DEFAULT_PAGE, DEFAULT_SIZE_IN_DETAIL, OrderDirection } from "../../../../../types/page";
import { useAffiliateCodeColumns } from "../../../../affiliate_code/hooks/code/useAffiliateCodeColumns";
import { Table } from "../../../../../layout/content/list/table/Table";
import listStyle from "../../../../../layout/content/list/list.module.scss";
import { ActionButton, DetailActionButton } from "../../../../../components/Buttons";
import deleteIcon from "../../../../../assets/images/button_icons/delete_icon.svg";
import stopIcon from "../../../../../assets/images/button_icons/stop_icon.svg";
import confirmIcon from "../../../../../assets/images/button_icons/confirm_icon.svg";
import { Pagination } from "../../../../../layout/content/list/Pagination";
import styled from "styled-components";
// eslint-disable-next-line max-len
import { useAffiliateCodeStatusUpdaterWithValidation } from "../../../../affiliate_code/hooks/code/useAffiliateCodeStatusUpdaterWithValidation";
// eslint-disable-next-line max-len
import { useAffiliateCodeDeleterWithValidation } from "../../../../affiliate_code/hooks/code/useAffiliateCodeDeleterWithValidation";
import { useAppSelector } from "../../../../../hooks/hooks";
import { affiliateCodeStatusState } from "../../../../../store/affiliateCodeSlice";
import { useLoader } from "../../../../../hooks/common/useLoader";

interface Props {
  organManagerIdx: number;
  setCode: (_?: string) => void;
}

const PageWrapperStyle = styled.div`
  padding-top: 20px;
`;

const TableLastRowStyle = styled.div`
  background: #f6f6f6;
  padding: 12px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dedede;
  margin-top: -11px;
`;

export function OrganManagerAffiliateCodeList({ organManagerIdx, setCode }: Props) {
  const status = useAppSelector(affiliateCodeStatusState);
  useLoader({ status });
  const [selectedCodeIdxes, setSelectedCodeIdxes] = useState<number[]>([]);
  const { getCodeList, codeList } = useConditionalAffiliateCodeList({ organManagerIdx });
  const { resumeCodeUsage, stopCodeUsage } = useAffiliateCodeStatusUpdaterWithValidation({
    resolve: () => {
      setSelectedCodeIdxes([]);
      getCodeList({});
    },
  });
  const { columnsInDetail } = useAffiliateCodeColumns({
    resumeCodeUsage,
    stopCodeUsage,
    setCode,
  });
  const { deleteCode } = useAffiliateCodeDeleterWithValidation({
    resolve: () => {
      setSelectedCodeIdxes([]);
      getCodeList({});
    },
  });

  useEffect(() => {
    getCodeList({
      page: DEFAULT_PAGE,
      size: DEFAULT_SIZE_IN_DETAIL,
      sort: [{ columnName: "createAt", direction: OrderDirection.DESC }],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organManagerIdx]);

  return (
    <Panel title={"제휴코드"}>
      <div className={`${listStyle.list} ${listStyle.noBorderNoPadding}`} style={{ paddingTop: "30px" }}>
        <div className={listStyle.buttons}>
          <div>
            <DetailActionButton
              onClick={() =>
                deleteCode(codeList ? codeList?.content.filter((c) => selectedCodeIdxes.includes(c.idx)) : [])
              }
            >
              <img src={deleteIcon} alt={"삭제 아이콘"} />
              코드삭제
            </DetailActionButton>
          </div>
          <div>
            <DetailActionButton onClick={() => stopCodeUsage(selectedCodeIdxes)}>
              <img src={stopIcon} alt={"사용중지 아이콘"} />
              사용중지
            </DetailActionButton>
            <DetailActionButton onClick={() => resumeCodeUsage(selectedCodeIdxes)}>
              <img src={confirmIcon} alt={"사용재개 아이콘"} />
              사용재개
            </DetailActionButton>
          </div>
        </div>
        {codeList && (
          <>
            <Table
              checkedRowIdxArr={selectedCodeIdxes}
              setCheckedRowIdxArr={setSelectedCodeIdxes}
              listData={codeList}
              columns={columnsInDetail}
              getList={(pageCondition) => () => getCodeList(pageCondition)}
              noDataText={"보유한 제휴코드가 없습니다."}
            />
            {codeList.content.length > 0 && (
              <TableLastRowStyle>
                <ActionButton onClick={() => setCode(undefined)}>전체 보기</ActionButton>
              </TableLastRowStyle>
            )}
            <PageWrapperStyle>
              <Pagination
                page={codeList}
                goSpecificPage={(page: number) => {
                  getCodeList({ page });
                  setSelectedCodeIdxes([]);
                }}
              />
            </PageWrapperStyle>
          </>
        )}
      </div>
    </Panel>
  );
}
