import { useAppDispatch, useTimeConverter } from "../../../hooks/hooks";
import { useCallback } from "react";
import { updateAffiliateCodeAvailableDurationAsync } from "../../../store/affiliateCodeSlice";

export function useAffiliateCodeAvailableDurationUpdater() {
  const dispatch = useAppDispatch();
  const { timeConverter } = useTimeConverter();

  const updateAffiliateCodeDuration = useCallback(
    async (idx: number, startAt: string, endAt: string | null) => {
      try {
        await dispatch(
          updateAffiliateCodeAvailableDurationAsync({
            idx,
            startAt: timeConverter.convertStartOfDayToUTC(startAt),
            endAt: endAt ? timeConverter.convertEndOfDayToUTC(endAt) : null,
          })
        ).unwrap();
      } catch (e) {
        console.error(e);
        throw new Error("제휴코드 사용기한 수정 에러");
      }
    },
    [dispatch, timeConverter]
  );

  return {
    updateAffiliateCodeDuration,
  };
}
