import { FilterContentType, TableFilter } from "../../../../types/tableFilter";
import { UserQueryType, UserStatus } from "../../../../types/user";
import { useCountry, useLanguage, useListFilterValues, useTimeConverter } from "../../../../hooks/hooks";
import { useTranslation } from "react-i18next";
import { useUserType } from "../../../../types/hooks/useUserType";
import { useMemo } from "react";
import {filterAllowedCountriesCode} from "../../../../util/etcUtil";

interface Args {
  search: () => void;
}
export function useUserFilters({ search }: Args) {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation(["filter", "common"]);
  const { countryOptionsWithAll } = useCountry();
  const filteredOptions = filterAllowedCountriesCode(
      {countries:countryOptionsWithAll, withEmpty:true});

  const { timeConverter } = useTimeConverter();
  const { userStatusOptions } = useUserType();
  const { isKorean: isKoreanLanguage } = useLanguage();

  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const searchWordTypeFilter = useMemo(
    () => filterValues.searchWordTypeFilterValue,
    [filterValues.searchWordTypeFilterValue]
  );
  const createdAtFromFilter = useMemo(
    () => filterValues.createdAtFromFilterValue,
    [filterValues.createdAtFromFilterValue]
  );
  const createdAtToFilter = useMemo(() => filterValues.createdAtToFilterValue, [filterValues.createdAtToFilterValue]);
  const statusFilter = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);
  const ageFromFilter = useMemo(() => filterValues.ageFromFilterValue, [filterValues.ageFromFilterValue]);
  const ageToFilter = useMemo(() => filterValues.ageToFilterValue, [filterValues.ageToFilterValue]);
  const countryFilter = useMemo(() => filterValues.countryFilterValue, [filterValues.countryFilterValue]);
  const totalTestCntFromFilter = useMemo(
    () => filterValues.totalTestCntFromFilterValue,
    [filterValues.totalTestCntFromFilterValue]
  );
  const totalTestCntToFilter = useMemo(
    () => filterValues.totalTestCntToFilterValue,
    [filterValues.totalTestCntToFilterValue]
  );

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("searchWord.label"),
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter?.value,
          maxLength: 100,
          placeholderVal: t("searchWord.placeholder"),
          onChangeFunc: (v) => searchWordFilter?.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: searchWordTypeFilter?.value,
            options: [
              { value: UserQueryType.NAME, label: t("searchWordCategory.name") },
              { value: UserQueryType.ID, label: t("searchWordCategory.id") },
              { value: UserQueryType.PHONE_NUMBER, label: t("searchWordCategory.phoneNumber") },
              { value: UserQueryType.REGION, label: t("searchWordCategory.region") },
            ],
            onChangeFunc: (v) => searchWordTypeFilter?.setter(v as UserQueryType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("createdAt.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtFromFilter?.value),
            onChangeFunc: (v) =>
              createdAtFromFilter?.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder"),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtToFilter?.value),
            onChangeFunc: (v) => createdAtToFilter?.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder"),
          },
        },
      },
      {
        labelTitle: t("status"),
        content: {
          type: FilterContentType.SELECT,
          value: statusFilter?.value,
          options: userStatusOptions,
          onChangeFunc: (v) => statusFilter?.setter(v as UserStatus),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("age"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: ageFromFilter?.value,
            min: 0,
            max: 100,
            onChangeFunc: (v) => ageFromFilter?.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: ageToFilter?.value,
            min: 0,
            max: 100,
            onChangeFunc: (v) => ageToFilter?.setter(v),
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("nationality"),
        content: {
          type: FilterContentType.SELECT,
          value: countryFilter?.value,
          options: filteredOptions,
          onChangeFunc: (v) => countryFilter?.setter(v),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("totalTestCount"),
        content: {
          type: FilterContentType.NUMBER_RANGE,
          from: {
            type: FilterContentType.NUMBER,
            value: totalTestCntFromFilter?.value,
            min: 1,
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
            onChangeFunc: (v) => totalTestCntFromFilter?.setter(v),
          },
          to: {
            type: FilterContentType.NUMBER,
            value: totalTestCntToFilter?.value,
            min: 1,
            placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
            onChangeFunc: (v) => totalTestCntToFilter?.setter(v),
          },
        },
      },
    ],
  ];

  return {
    filters,
  };
}
