import { useCouponColumns } from "./hooks/useCouponColumns";
import { useCouponList } from "./hooks/useCouponList";
import stopIcon from "../../assets/images/button_icons/stop_icon.svg";
import confirmIcon from "../../assets/images/button_icons/confirm_icon.svg";
import deleteIcon from "../../assets/images/button_icons/delete_icon.svg";
import { ListTable } from "../../layout/content/list/ListTable";
import { useCouponFilters } from "./hooks/useCouponFilters";
import { CouponStatus } from "../../types/coupon";
import React, { useCallback, useState } from "react";
import { useAppSelector } from "../../hooks/hooks";
import { couponStatus } from "../../store/couponSlice";
import { useLoader } from "../../hooks/common/useLoader";
import { useCouponActions } from "./hooks/useCouponActions";
import { useTranslation } from "react-i18next";
import { ListCount, WhichList } from "../../components/ListCount";

export function CouponList() {
  const { t } = useTranslation("coupon");
  const status = useAppSelector(couponStatus);
  useLoader({ status });

  const [checkedIdxArr, setCheckedIdxArr] = useState<number[]>([]);

  const { searchCouponList, couponList, pageCondition, totalCnt } = useCouponList({
    onCompletedCallback: () => setCheckedIdxArr([]),
  });
  const { filters } = useCouponFilters({ search: searchCouponList({}) });

  const onCompletedCallback = useCallback(() => {
    searchCouponList(pageCondition)();
    setCheckedIdxArr([]);
  }, [setCheckedIdxArr, searchCouponList, pageCondition]);

  const { stopIssuingCoupons, resumeIssuingCoupons, deleteCoupons, deleteCouponsCompletely } = useCouponActions({
    onCompletedDeleteOnlyCoupon: () => {
      alert(t("resultAlert.deleteOnlyCoupon"));
      onCompletedCallback();
    },
    onCompletedDeleteCouponCompletely: () => {
      alert(t("resultAlert.deleteCouponCompletely"));
      onCompletedCallback();
    },
    onCompletedResumeIssuing: () => {
      alert(t("resultAlert.resumeCouponIssuing"));
      onCompletedCallback();
    },
    onCompletedStopIssuing: () => {
      alert(t("resultAlert.stopCouponIssuing"));
      onCompletedCallback();
    },
  });
  const { columns } = useCouponColumns({ stopIssuingCoupons, resumeIssuingCoupons });

  const onClickStopIssuingBtn = useCallback(() => {
    if (checkedIdxArr.length === 0) {
      alert(t("noCouponSelected"));
      return;
    }

    const isAllIsIssuingCoupons = couponList?.content
      .filter((c) => checkedIdxArr.includes(c.idx))
      .every((c) => c.status === CouponStatus.IS_ISSUING);

    if (!isAllIsIssuingCoupons) {
      alert(t("stopIssuing.selectedAlreadyStoppedCoupon"));
      return;
    }

    stopIssuingCoupons(checkedIdxArr);
  }, [couponList?.content, t, checkedIdxArr, stopIssuingCoupons]);

  const onClickResumeIssuingBtn = useCallback(() => {
    if (checkedIdxArr.length === 0) {
      alert(t("noCouponSelected"));
      return;
    }

    const isAllIsIssuingCoupons = couponList?.content
      .filter((c) => checkedIdxArr.includes(c.idx))
      .every((c) => c.status === CouponStatus.STOP_ISSUING);

    if (!isAllIsIssuingCoupons) {
      alert(t("reissue.selectedAlreadyIssuedCoupon"));
      return;
    }

    resumeIssuingCoupons(checkedIdxArr);
  }, [couponList?.content, t, checkedIdxArr, resumeIssuingCoupons]);

  const onClickDeleteCouponsBtn = () => {
    if (checkedIdxArr.length === 0) {
      alert(t("noCouponSelected"));
      return;
    }

    deleteCoupons(checkedIdxArr);
  };
  const onClickDeleteCouponsCompletelyBtn = () => {
    if (checkedIdxArr.length === 0) {
      alert(t("noCouponSelected"));
      return;
    }

    deleteCouponsCompletely(checkedIdxArr);
  };

  const ButtonNodes = () => (
    <>
      <div>
        <button onClick={onClickDeleteCouponsCompletelyBtn}>
          <img src={deleteIcon} alt={t("deleteCompletely.icon")} />
          {t("deleteCompletely.button")}
        </button>
        <button onClick={onClickDeleteCouponsBtn}>
          <img src={deleteIcon} alt={t("delete.icon")} />
          {t("delete.button")}
        </button>
      </div>
      <div>
        <button onClick={onClickStopIssuingBtn}>
          <img src={stopIcon} alt={t("stopIssuing.icon")} />
          {t("stopIssuing.button")}
        </button>
        <button onClick={onClickResumeIssuingBtn}>
          <img src={confirmIcon} alt={t("reissue.icon")} />
          {t("reissue.button")}
        </button>
      </div>
    </>
  );

  return (
    <>
      {couponList ? (
        <ListTable
          filters={filters}
          buttonsNode={<ButtonNodes />}
          listData={couponList}
          getList={({ page, sort, size }) => searchCouponList({ page, sort, size })}
          columns={columns}
          checkedRowIdxArr={checkedIdxArr}
          setCheckedRowIdxArr={setCheckedIdxArr}
          showCheckbox={(c) => c.status !== CouponStatus.DELETED}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalCnt} whichList={WhichList.COUPON} />,
            singleUnitKeyInCommonFile: "couponUnit.singleComponent",
            multipleUnitKeyInCommonFile: "couponUnit.multipleComponent",
          }}
          pageOnFilter={pageCondition}
        />
      ) : (
        <></>
      )}
    </>
  );
}
