import { useAppDispatch } from "../../../hooks/hooks";
import { useCallback } from "react";
import { UserDTO } from "../../../types/user";
import { callAsync } from "../../../util/sliceUtil";
import { getUserDetailAsync } from "../../../store/userSlice";
import { CustomerType } from "../../../types/common";
import { OrganManagerDTO } from "../../../types/organManager";
import { getOrganManagerAsync } from "../../../store/organManagerSlice";
import { useUtil } from "../../../util/hooks/useUtil";

export interface CustomerVO {
  idx: number;
  name: string;
  customerType: CustomerType;
  refundBankName: string;
  refundAccountNumber: string;
  refundAccountName: string;
}

export function useCustomerGetter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const getUserRefundAccount = useCallback(
    (userIdx: number, onSuccess: (customerVO: CustomerVO) => void) => {
      callAsync(
        dispatch(getUserDetailAsync(userIdx)).unwrap(),
        (it: UserDTO) => {
          onSuccess({
            idx: it.idx,
            name: it.name,
            customerType: CustomerType.USER,
            refundBankName: it.refundBankName,
            refundAccountNumber: it.refundAccountNumber,
            refundAccountName: it.refundAccountName,
          });
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );

  const getOrganManagerRefundAccount = useCallback(
    (organManagerIdx: number, onSuccess: (refundAccountVO: CustomerVO) => void) => {
      callAsync(
        dispatch(getOrganManagerAsync(organManagerIdx)).unwrap(),
        (it: OrganManagerDTO) => {
          onSuccess({
            idx: it.idx,
            name: it.name,
            customerType: CustomerType.ORGAN_MANAGER,
            refundBankName: it.refundBankName,
            refundAccountNumber: it.refundAccountNumber,
            refundAccountName: it.refundAccountName,
          });
        },
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );

  return useCallback(
    (targetIdx: number, customerType: CustomerType, onSuccess: (customerVO: CustomerVO) => void) => {
      if (customerType === CustomerType.USER) getUserRefundAccount(targetIdx, onSuccess);
      else getOrganManagerRefundAccount(targetIdx, onSuccess);
    },
    [getUserRefundAccount, getOrganManagerRefundAccount]
  );
}
