import { useCallback, useState } from "react";
import { callAsync } from "../../../../../util/sliceUtil";
import { getAdminDetailAsync } from "../../../../../store/adminSlice";
import { AdminVO } from "../../../../../types/admin";
import { useAppDispatch } from "../../../../../hooks/hooks";
import { useContentDetailHeaderInfoSetter } from "../../../../../layout/content/hooks/useContentDetailHeaderInfoSetter";
import { useAdminStatus } from "../../hooks/useAdminStatus";
import { useUtil } from "../../../../../util/hooks/useUtil";
import { useTranslation } from "react-i18next";

interface ReturnType {
  adminVO: AdminVO | undefined;
  getAdmin: (adminIdx: number) => void;
  setAdmin: (adminVO: AdminVO) => void;
}

export function useAdminGetter(): ReturnType {
  const { t } = useTranslation("account");
  const dispatch = useAppDispatch();
  const [adminVO, setAdminVO] = useState<AdminVO>();
  const { setCategory, setName } = useContentDetailHeaderInfoSetter();
  const { defaultErrorMessage } = useUtil();
  const { isLeaved } = useAdminStatus();

  const getAdmin = useCallback(
    (adminIdx: number) => {
      callAsync(
        dispatch(getAdminDetailAsync(adminIdx)).unwrap(),
        (it: AdminVO) => {
          setAdminVO(it);
          if (!isLeaved(it.status)) {
            setCategory(t("adminTypeTab.admin"));
            setName(it.name);
          }
        },
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    },
    [dispatch, setName, setCategory, isLeaved, defaultErrorMessage, t]
  );

  return {
    adminVO,
    getAdmin,
    setAdmin: setAdminVO,
  };
}
