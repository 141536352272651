import style from "../productItemModal.module.scss";
import React from "react";
import {MenuExposedStatus} from "../../../../types/organManager";
import {useOrganManagerType} from "../../../../types/hooks/useOrganManagerType";

interface Props {
    checkedMenuStatus: MenuExposedStatus[];
    setCheckedMenuStatus: (_: MenuExposedStatus[]) => void;
}

export function OrganManagerMenuExposedField({checkedMenuStatus, setCheckedMenuStatus}: Props) {
    const {menuExposedOptions} = useOrganManagerType();


    const handleCheckboxChange = (status: MenuExposedStatus) => {
        setCheckedMenuStatus(
            checkedMenuStatus.includes(status)
                ? checkedMenuStatus.filter((v) => v !== status)
                : [...checkedMenuStatus, status]
        );
    };


    return (
        <div className={style.checkboxContainer}>
            {Object.keys(MenuExposedStatus).map((key, index) => {
                const status = MenuExposedStatus[key as keyof typeof MenuExposedStatus];
                const option = menuExposedOptions.find(option => option.value === status);
                const label = option ? option.label : status;
                const isDisabled = status === MenuExposedStatus.HOME_AND_ORGAN_MANAGEMENT
                return (
                    <label key={status} className={style.checkboxLabel}>
                        <input
                            type="checkbox"
                            checked={checkedMenuStatus.includes(status)}
                            onChange={() => handleCheckboxChange(status)}
                            className={style.checkboxInputField}
                            disabled={isDisabled}
                        />
                        <span className={style.checkboxTextField}>{label}</span>
                    </label>
                );
            })}
        </div>
    );
}