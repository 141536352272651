import { useCallback } from "react";
import { getAffiliateCodesAsync } from "../../../../store/affiliateCodeSlice";
import { useAppDispatch } from "../../../../hooks/hooks";
import {
  AffiliateCodeListDTO,
  AffiliateCodeListVO,
  AffiliateCodeSearchCondition,
} from "../../../../types/affiliateCode";
import { PageResponse } from "../../../../types/page";

export function useAffiliateCodesGetter() {
  const dispatch = useAppDispatch();

  const getAffiliateCodes = useCallback(
    async (param: AffiliateCodeSearchCondition) => {
      try {
        const result: PageResponse<AffiliateCodeListDTO> = await dispatch(getAffiliateCodesAsync(param)).unwrap();
        return {
          ...result,
          content: result.content.map((dto) => ({ ...dto } as AffiliateCodeListVO)),
        };
      } catch (e) {
        console.error(e);
        throw new Error("getAffiliateCodes occurs an error");
      }
    },
    [dispatch]
  );

  return {
    getAffiliateCodes,
  };
}
