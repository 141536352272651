import { useListFilterValues } from "../../../../hooks/hooks";
import { SettlementSearchCondition, useAffiliateCodeSettlementsGetter } from "./useAffiliateCodeSettlementsGetter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useUtil } from "../../../../util/hooks/useUtil";
import { AffiliateCodeSettlementListFilterValues } from "./useAffiliateCodeSettlementFilterValues";
import { DEFAULT_PAGE, DEFAULT_SIZE, ordersToSortArr, PageResponse } from "../../../../types/page";
import { MonthlyAffiliateCodeListVO, SettlementDetailsVO, SettlementStatus } from "../../../../types/affiliateCode";

export interface SettlementOneRow {
  organManagerName: string;
  organManagerIdx: number;
  code: string;
  paidCounts: number;
  discountAmount: number;
  commissionAmount: number;
  vatAmount: number;
  deductedTaxAmount: number;
  settlementAmount: number;
  status?: SettlementStatus;
  isTotalRow: boolean;
  isFirstRow: boolean;
}

export function useSettlementDetailList() {
  const { filterValues, pageInfoOnFilter } = useListFilterValues();

  const [listByOrganManager, setListByOrganManager] = useState<PageResponse<SettlementDetailsVO>>();
  const { getAffiliateCodeSettlements } = useAffiliateCodeSettlementsGetter();
  const { defaultErrorMessage } = useUtil();

  const pageCondition = useMemo(
    () => ({
      page: listByOrganManager?.number ?? DEFAULT_PAGE,
      size: listByOrganManager?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(listByOrganManager?.orders),
    }),
    [listByOrganManager]
  );

  const getSettlementList = useCallback(
    async (param: SettlementSearchCondition) => {
      try {
        const result: PageResponse<SettlementDetailsVO> = await getAffiliateCodeSettlements(param);
        setListByOrganManager(result);
      } catch (e) {
        alert(defaultErrorMessage);
      }
    },
    [getAffiliateCodeSettlements, defaultErrorMessage]
  );

  const settlements: SettlementOneRow[] | undefined = useMemo(
    () =>
      listByOrganManager?.content.flatMap((om) => {
        const thisRows: SettlementOneRow[] = om.codeDetails.map((codeDetail: MonthlyAffiliateCodeListVO, codeIdx) => ({
          organManagerName: om.organManagerName,
          organManagerIdx: om.organManagerIdx,
          code: codeDetail.affiliateCode,
          paidCounts: codeDetail.paymentsCount,
          discountAmount: codeDetail.discountValue,
          commissionAmount: codeDetail.commissionValue,
          vatAmount: codeDetail.vatValue,
          deductedTaxAmount: codeDetail.taxValue,
          settlementAmount: codeDetail.settlementValue,
          status: codeDetail.status,
          isTotalRow: false,
          isFirstRow: codeIdx === 0,
        }));

        return [
          ...thisRows,
          {
            organManagerName: om.organManagerName,
            organManagerIdx: om.organManagerIdx,
            code: "",
            paidCounts: thisRows.reduce((acc, row) => acc + row.paidCounts, 0),
            discountAmount: thisRows.reduce((acc, row) => acc + row.discountAmount, 0),
            commissionAmount: thisRows.reduce((acc, row) => acc + row.commissionAmount, 0),
            vatAmount: thisRows.reduce((acc, row) => acc + row.vatAmount, 0),
            deductedTaxAmount: thisRows.reduce((acc, row) => acc + row.deductedTaxAmount, 0),
            settlementAmount: thisRows.reduce((acc, row) => acc + row.settlementAmount, 0),
            status: thisRows[0]?.status,
            isFirstRow: false,
            isTotalRow: true,
          },
        ];
      }),
    [listByOrganManager?.content]
  );

  const totalCodeCounts = useMemo(
    () =>
      listByOrganManager?.content.reduce((acc, currentOrganManager) => acc + currentOrganManager.codeDetails.length, 0),
    [listByOrganManager?.content]
  );

  const year = useMemo(
    () => (filterValues as AffiliateCodeSettlementListFilterValues).yearFilterValue.value,
    [filterValues]
  );
  const month = useMemo(
    () => (filterValues as AffiliateCodeSettlementListFilterValues).monthFilterValue.value,
    [filterValues]
  );
  const status = useMemo(
    () => (filterValues as AffiliateCodeSettlementListFilterValues).statusFilterValue.value,
    [filterValues]
  );

  const getSearchParam = useCallback(
    (): SettlementSearchCondition => ({
      pageCondition: {
        page: pageInfoOnFilter.page ? Number(pageInfoOnFilter.page) : DEFAULT_PAGE,
        size: pageInfoOnFilter.size ? Number(pageInfoOnFilter.size) : DEFAULT_SIZE,
        sort: pageInfoOnFilter.sort ? pageInfoOnFilter.sort : undefined,
      },
      year: Number(year),
      month: Number(month),
      status: status && status !== SettlementStatus.ALL ? status : undefined,
    }),
    [pageInfoOnFilter, year, month, status]
  );

  const getList = useCallback(async () => {
    const param: SettlementSearchCondition = getSearchParam();
    return await getSettlementList(param);
  }, [getSettlementList, getSearchParam]);

  useEffect(() => {
    if (year && month && status) {
      const param: SettlementSearchCondition = getSearchParam();

      getSettlementList(param).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSearchParam]);

  return {
    listByOrganManager,
    pageCondition,
    totalCodeCounts,
    settlements,
    getList,
  };
}
