import { ListFilterValuesContext } from "../../../hooks/list/ListFilterValuesContext";
import { useTesterFilterValues } from "./hooks/useTesterFilterValues";
import { MemberTester } from "./MemberTester";

export function MemberTesterWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useTesterFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        pageInfoOnFilter,
        searchList,
        listQueryType,
      }}
    >
      <MemberTester />
    </ListFilterValuesContext.Provider>
  );
}
