import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../hooks/hooks";
import { orderStatus } from "../../../../store/orderSlice";
import { useLoader } from "../../../../hooks/common/useLoader";
import { useMemberOrderFilters } from "./hooks/useMemberOrderFilters";
import useMemberOrderList from "./hooks/useMemberOrderList";
import useMemberOrderColumns from "./hooks/useMemberOrderColumns";
import { ListTable } from "../../../../layout/content/list/ListTable";
import { useTranslation } from "react-i18next";
import { ListCount, WhichList } from "../../../../components/ListCount";
import { ExcelDownloadButton } from "../../../../layout/content/selector/ExcelDownloadButton";
import { useOrderDownloader } from "../../../order/hooks/useOrderDownloader";
import { useMemberOrderFilterValues } from "./hooks/useMemberOrderFilterValues";
import { DEFAULT_PAGE } from "../../../../types/page";

interface Props {
  userIdx?: number;
  organManagerIdx?: number;
}

export function MemberOrderList({ userIdx, organManagerIdx }: Props) {
  const { t } = useTranslation("member");
  const status = useAppSelector(orderStatus);
  useLoader({ status });

  const [checkedIdxes, setCheckedIdxes] = useState<number[]>([]);
  const filterValues = useMemberOrderFilterValues();
  const { getOrderList, totalOrderCount, orderList, getOrderSearchConditionFromOrderFilterValues } = useMemberOrderList(
    filterValues,
    organManagerIdx,
    userIdx
  );
  const { userFilters, organManagerFilters } = useMemberOrderFilters({
    search: () => {
      getOrderList({ page: DEFAULT_PAGE })();
    },
    filterValues,
  });
  const { userColumn, organManagerColumn } = useMemberOrderColumns();
  const { onClickDownloadSelectedOrders, downloadAllOrders } = useOrderDownloader();

  const onClickDownloadAll = useCallback(() => {
    downloadAllOrders(getOrderSearchConditionFromOrderFilterValues());
  }, [downloadAllOrders, getOrderSearchConditionFromOrderFilterValues]);

  useEffect(() => {
    getOrderList({ size: 5 }, true)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isUserMember = useMemo(() => (userIdx === undefined ? false : true), [userIdx]);

  return orderList ? (
    <ListTable
      checkedRowIdxArr={checkedIdxes}
      setCheckedRowIdxArr={setCheckedIdxes}
      filters={isUserMember ? userFilters : organManagerFilters}
      filterTitle={t("order.filter.title")}
      totalRowsCnt={{
        totalCount: <ListCount totalCount={totalOrderCount ?? 0} whichList={WhichList.PAYMENT} />,
        singleUnitKeyInCommonFile: "paymentUnit.singleComponent",
        multipleUnitKeyInCommonFile: "paymentUnit.multipleComponent",
      }}
      columns={isUserMember ? userColumn : organManagerColumn}
      listData={orderList}
      getList={getOrderList}
      buttonsNode={<></>}
      noDataText={t("order.list.noData")}
      isDetailPage={true}
      pageOnFilter={{}}
      totalCountInfoRightElement={
        <ExcelDownloadButton
          onClickAllDownloadButton={() => {
            onClickDownloadAll();
          }}
          onClickSelectedItemsDownloadButton={() => {
            const sortedCheckedIdxes =
              orderList?.content.filter((order) => checkedIdxes.includes(order.idx)).map((order) => order.idx) ?? [];
            onClickDownloadSelectedOrders(sortedCheckedIdxes);
          }}
        />
      }
    />
  ) : (
    <></>
  );
}
