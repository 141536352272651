import { useCallback, useMemo } from "react";
import {
  AffiliateCodeSearchWordType,
  AffiliateCodeStatus,
  CodeChargeType,
  CodeDiscountType,
  SettlementStatus,
  TaxDeduction,
  VATIncluded,
} from "../affiliateCode";

export function useAffiliateCodeType() {
  const codeDiscountTypeLabelRouter = useMemo(
    () => ({
      [CodeDiscountType.PERCENT.toString()]: "할인율",
      [CodeDiscountType.AMOUNT.toString()]: "할인 금액",
    }),
    []
  );
  const getCodeDiscountTypeLabel = useCallback(
    (type: CodeDiscountType) => codeDiscountTypeLabelRouter[type.toString()],
    [codeDiscountTypeLabelRouter]
  );

  const codeChargeTypeLabelRouter = useMemo(
    () => ({
      [CodeChargeType.PERCENT.toString()]: "백분율",
      [CodeChargeType.AMOUNT.toString()]: "금액 설정",
    }),
    []
  );
  const getCodeChargeTypeLabel = useCallback(
    (type: CodeChargeType) => codeChargeTypeLabelRouter[type.toString()],
    [codeChargeTypeLabelRouter]
  );

  const vatIncludedLabelRouter = useMemo(
    () => ({
      [VATIncluded.Y.toString()]: "선택",
      [VATIncluded.N.toString()]: "미선택",
    }),
    []
  );
  const getVATIncludedLabel = useCallback(
    (type: VATIncluded) => vatIncludedLabelRouter[type.toString()],
    [vatIncludedLabelRouter]
  );

  const taxDeductionLabelRouter = useMemo(
    () => ({
      [TaxDeduction.Y.toString()]: "선택",
      [TaxDeduction.N.toString()]: "미선택",
    }),
    []
  );
  const getTaxDeductionLabel = useCallback(
    (type: TaxDeduction) => taxDeductionLabelRouter[type.toString()],
    [taxDeductionLabelRouter]
  );

  const statusRouter = useMemo(
    () => ({
      [AffiliateCodeStatus.AVAILABLE.toString()]: "사용중",
      [AffiliateCodeStatus.UNAVAILABLE.toString()]: "사용중지",
      [AffiliateCodeStatus.DELETED.toString()]: "삭제됨",
    }),
    []
  );
  const getAffiliateCodeStatusLabel = useCallback(
    (status: AffiliateCodeStatus) => statusRouter[status.toString()],
    [statusRouter]
  );

  const searchWordTypeRouter = useMemo(
    () => ({
      [AffiliateCodeSearchWordType.CODE.toString()]: "제휴코드",
      [AffiliateCodeSearchWordType.NAME.toString()]: "보유자명",
    }),
    []
  );
  const getAffiliateCodeSearchWordTypeLabel = useCallback(
    (type: AffiliateCodeSearchWordType) => searchWordTypeRouter[type.toString()],
    [searchWordTypeRouter]
  );

  const settlementStatusRouter = useMemo(
    () => ({
      [SettlementStatus.COMPLETE.toString()]: "정산완료",
      [SettlementStatus.INCOMPLETE.toString()]: "미완료",
    }),
    []
  );
  const getSettlementStatusLabel = useCallback(
    (status: SettlementStatus) => settlementStatusRouter[status.toString()],
    [settlementStatusRouter]
  );

  return {
    getCodeDiscountTypeLabel,
    getCodeChargeTypeLabel,
    getVATIncludedLabel,
    getTaxDeductionLabel,
    getAffiliateCodeStatusLabel,
    getAffiliateCodeSearchWordTypeLabel,
    getSettlementStatusLabel,
  };
}
