import React, { useCallback } from "react";

export function useBlockEnter() {
  const blockEnterKeyEvent = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
    }
  }, []);

  return {
    blockEnterKeyEvent,
  };
}
