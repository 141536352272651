import { MainApi } from "./MainApi";
import { TestSearchCondition } from "../types/test";

export class TestApi {
  static baseUrl = `${MainApi.urlPrefix}/test-history`;
  static dcasBaseUrl = `${MainApi.dcasUrlPrefix}/test-history`;

  // kpass 검사결과 조회
  static getKpassTestList = (condition: TestSearchCondition) => () =>
    MainApi.api.get(`${TestApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  // dcas 검사결과 조회
  static getDcasTestList = (condition: TestSearchCondition) => () =>
    MainApi.api.get(`${TestApi.dcasBaseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static downloadExcel = (testIdxes: number[]) => () =>
    MainApi.api.post(
      `${TestApi.baseUrl}/excel`,
      {
        contentExecutedInfoIdxes: testIdxes,
      },
      {
        responseType: "blob",
      }
    );

  static deleteTests = (testIdxes: number[]) => () => MainApi.api.delete(`${TestApi.baseUrl}/${testIdxes.join(",")}`);
}
