import { useCallback } from "react";

interface ReturnType {
  getDownloadExcelFile: (blobResult: any, fileName: string) => void;
}
export function useDownloadFile(): ReturnType {
  const getDownloadExcelFile = useCallback((blobResult: any, fileName: string) => {
    const downloadLink = window.document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(
      new Blob([blobResult], { type: "application/vnd.ms-excel;charset=UTF-8" })
    );
    downloadLink.download = `${fileName}.xlsx`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, []);

  return {
    getDownloadExcelFile,
  };
}
