import { ArticleProps } from "../../docType";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { ArticleWrapper } from "./ArticleWrapper";

export function ArticleRoleChecker({ id, title }: ArticleProps) {
  const description = `
- RoleChecker의 Props 
{
  children: JSX.Element;
  menuCode: AdminMenuCode;
  alternativeChildren?: JSX.Element;  -- 권한이 없는 경우 대체할 element
}  
  `;

  const code = `
- ADMIN_ROLE 권한을 가지고 있는 유저의 경우 버튼이 표시되고, 그렇지 않은 경우 표시되지 않음
  
<RoleChecker menuCode={AdminMenuCode.ADMIN_ROLE}>
  <ActionButton>권한이 필요한 어떤 버튼</ActionButton>
</RoleChecker>  
  `;

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode description={description} exampleCode={code}>
          <span style={{ fontSize: "14px" }}>권한 체크하는 컴포넌트 예제 코드 보기</span>
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
