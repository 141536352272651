import styled from "styled-components/macro";

export const ImageFieldStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > .imageType {
    margin-top: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: ${(props) => props.theme.color.FGDarkBlue};
  }

  > .images {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  > .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: ${(props) => props.theme.color.c75};
    white-space: break-spaces;
  }
`;
