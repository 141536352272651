import { SelectorOption } from "./selector";

interface TableFilter {
  labelTitle: string;
  hideFilter?: boolean;
  content: Text | Select | CalendarRange | Calendar | NumberFilter | NumberRange;
}

enum FilterContentType {
  TEXT = "text",
  SELECT = "select",
  CALENDAR = "calendar",
  CALENDAR_RANGE = "calendar_range",
  NUMBER = "number",
  NUMBER_RANGE = "number_range",
}

interface FilterContent {
  type: FilterContentType;
}

interface Text extends FilterContent {
  type: FilterContentType.TEXT;
  value: string;
  maxLength: number;
  placeholderVal: string;
  onChangeFunc: (v: string) => void;
  onEnterKeyUpFunc?: (v: string) => void;
  typeSelector?: Select;
}

interface Select extends FilterContent {
  type: FilterContentType.SELECT;
  value: string;
  options: SelectorOption[];
  onChangeFunc: (v: string) => void;
  width?: number;
}

interface CalendarRange extends FilterContent {
  type: FilterContentType.CALENDAR_RANGE;
  from: Calendar;
  to: Calendar;
  typeSelector?: Select;
}

interface Calendar extends FilterContent {
  type: FilterContentType.CALENDAR;
  value: string;
  onChangeFunc: (_: string) => void;
  placeholderVal?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
}

interface NumberFilter extends FilterContent {
  type: FilterContentType.NUMBER;
  value: string;
  max?: number;
  min?: number;
  isRequired?: boolean;
  placeholderVal?: string;
  onChangeFunc: (v: string) => void;
}

interface NumberRange extends FilterContent {
  type: FilterContentType.NUMBER_RANGE;
  from: NumberFilter;
  to: NumberFilter;
}

export type { TableFilter, FilterContent, Select, Calendar, Text, NumberFilter, CalendarRange, NumberRange };
export { FilterContentType };
