import { useState } from "react";

export function useSampleFilterNumber() {
  const [sampleFilterNumber, setSampleFilterNumber] = useState("12300");

  const filterNumberCode = `
const [samplePrice, setSamplePrice] = useState('12300');

<FilterNumber
  numberField={
    {
      type: FilterContentType.NUMBER,
      value: samplePrice,
      min: 1,
      isRequired: true,
      placeholderVal: '숫자 입력',
      onChangeFunc: setSamplePrice
    }
  }
/>      
`;

  return {
    sampleFilterNumber,
    setSampleFilterNumber,
    filterNumberCode,
  };
}
