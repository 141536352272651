import couponIcon from "../../../../assets/images/button_icons/coupon_icon.svg";
import { DescriptionList, DescriptionValue } from "../../../../layout/content/detail/DescriptionList";
import style from "../../user/memberUserCoupons.module.scss";
import { Panel } from "../../../../layout/content/detail/Panel";
import React, { useCallback, useMemo } from "react";
import { GrantCouponParam } from "../../../../types/newWindow";
import { CustomerType } from "../../../../types/common";
import queryString from "query-string";
import { DetailActionButton } from "../../../../components/Buttons";
import { useLanguage, useNewWindow } from "../../../../hooks/hooks";
import { CouponCntInfo } from "../../../../types/coupon";
import { useTranslation } from "react-i18next";

interface Props {
  targetIdx: number;
  customerType: CustomerType;
  couponStatusInfo: CouponCntInfo | undefined;
  refreshAll: () => void;
}

export function CouponInfoStatus({ targetIdx, customerType, couponStatusInfo, refreshAll }: Props) {
  const { t } = useTranslation("member");
  const { name } = queryString.parse(window.location.search);
  const { openGrantCoupon } = useNewWindow();
  const { isKorean } = useLanguage();

  const onClickGrantCouponBtn = useCallback(() => {
    const param: GrantCouponParam = { targetIdx, targetName: (name as string) ?? "", customerType };
    openGrantCoupon(param, () => refreshAll());
  }, [targetIdx, name, customerType, openGrantCoupon, refreshAll]);

  const couponCntStatus: DescriptionValue[][] = useMemo(() => {
    return [
      [
        {
          key: t("coupon.statusPanel.issued"),
          value: t("coupon.statusPanel.unit", { count: couponStatusInfo?.issuedCoupon ?? 0 }),
        },
        {
          key: t("coupon.statusPanel.available"),
          value: t("coupon.statusPanel.unit", { count: couponStatusInfo?.availableCoupon ?? 0 }),
        },
        {
          key: t("coupon.statusPanel.notAvailable"),
          value: t("coupon.statusPanel.unit", { count: couponStatusInfo?.unavailableCoupon ?? 0 }),
        },
      ],
    ];
  }, [couponStatusInfo, t]);

  return (
    <Panel
      title={t("coupon.statusPanel.title")}
      additionalButtons={
        <>
          <DetailActionButton onClick={onClickGrantCouponBtn}>
            <img src={couponIcon} alt={t("coupon.statusPanel.icon")} />
            {t("coupon.statusPanel.issueCouponButton")}
          </DetailActionButton>
        </>
      }
    >
      <DescriptionList
        data={couponCntStatus}
        labelWidth={isKorean ? 130 : [132, 147, 163]}
        valueWidth={isKorean ? 100 : 80}
        valueClassName={style.statusCell}
      />
    </Panel>
  );
}
