import { useEffect } from "react";
import { Table } from "../../../../layout/content/list/table/Table";
import { Pagination } from "../../../../layout/content/list/Pagination";
import { useUserTesterColumns } from "./hooks/useUserTesterColumns";
import { useUserTesterList } from "./hooks/useUserTesterList";
import { PaginationWrapper } from "../../../../layout/content/list/PaginationWrapper";
import { Panel } from "../../../../layout/content/detail/Panel";
import { OrderDirection } from "../../../../types/page";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

interface Props {
  userIdx: number;
  setSelectedProfileIdx: (_: number) => void;
}

export function ProfileList({ userIdx, setSelectedProfileIdx }: Props) {
  const { t } = useTranslation(["user", "common"]);
  const { getUserProfiles, profileList } = useUserTesterList({ userIdx });
  const { columns } = useUserTesterColumns({ userIdx, setSelectedProfileIdx });

  const { profileIdx: queryProfileIdx } = queryString.parse(window.location.search);
  useEffect(() => {
    if (queryProfileIdx) {
      setSelectedProfileIdx(Number(queryProfileIdx));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileList, queryProfileIdx]);

  useEffect(() => {
    getUserProfiles({ sort: [{ columnName: "createAt", direction: OrderDirection.ASC }] })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Panel title={t("detail.profile.list.title")}>
      {profileList ? (
        <>
          <Table
            listData={profileList}
            columns={columns}
            getList={getUserProfiles}
            noDataText={t("noSearchResult", { ns: "common" })}
          />
          <PaginationWrapper>
            <Pagination page={profileList} goSpecificPage={(pageNum: number) => getUserProfiles({ page: pageNum })()} />
          </PaginationWrapper>
        </>
      ) : (
        <></>
      )}
    </Panel>
  );
}
