import { useCallback } from "react";
import { callAsync } from "../../../../../util/sliceUtil";
import {
  getVoucherCurrentStatusAsync,
  setVoucherNewStatusInfo,
  voucherStatusNewDTO,
} from "../../../../../store/voucherSlice";
import {VoucherStatusNewDTO} from "../../../../../types/voucher";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/hooks";
import { useUtil } from "../../../../../util/hooks/useUtil";

interface Args {
  userIdx: number;
}

export function useUserVoucherStatus({ userIdx }: Args) {
  const dispatch = useAppDispatch();
  const userVoucherStatusInfo = useAppSelector(voucherStatusNewDTO);
  const { defaultErrorMessage } = useUtil();

  const getVoucherStatusInfo = useCallback(() => {
    callAsync(
      dispatch(getVoucherCurrentStatusAsync(userIdx)).unwrap(),
      (result: VoucherStatusNewDTO) => dispatch(setVoucherNewStatusInfo(result)),
      () => alert(defaultErrorMessage)
    ).then();
  }, [dispatch, userIdx, defaultErrorMessage]);

  return {
    getVoucherStatusInfo,
    userVoucherStatusInfo,
  };
}
