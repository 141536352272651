import { AdminMenuCode } from "../../../types/adminRole";
import { RedActionButton } from "../../../components/Buttons";
import { RoleChecker } from "../../role/RoleChecker";
import React, { useCallback } from "react";
import { callAsync } from "../../../util/sliceUtil";
import { disagreeMarketingAsync } from "../../../store/userSlice";
import { MarketingTermsCategory } from "../../../types/terms";
import { useAppDispatch } from "../../../hooks/hooks";
import { CustomerType } from "../../../types/common";
import { useOrganManagerMarketingTermsWithdrawer } from "../organmanager/hooks/useOrganManagerMarketingTermsWithdrawer";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  targetIdx: number;
  targetType: CustomerType;
  targetName: string;
  category: MarketingTermsCategory;
  onCompletedCallback: () => void;
}

export function MemberUserDisagreeMarketingTerms({
  targetIdx,
  targetType,
  targetName,
  category,
  onCompletedCallback,
}: Props) {
  const { t } = useTranslation("user");
  const dispatch = useAppDispatch();
  const { withdrawTerms } = useOrganManagerMarketingTermsWithdrawer();
  const { defaultErrorMessage } = useUtil();

  const onClickDisagreeMarketingButton = useCallback(
    (userIdx: number, userName: string, category: MarketingTermsCategory) => {
      const isConfirmed = window.confirm(t("detail.userInfo.withdrawTerms.confirmMessage", { name: userName }));
      if (isConfirmed) {
        callAsync(
          dispatch(disagreeMarketingAsync({ userIdx, category })).unwrap(),
          () => onCompletedCallback(),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [dispatch, onCompletedCallback, defaultErrorMessage, t]
  );

  const onClickOrganManagerDisagreeMarketingButton = useCallback(
    (organManagerIdx: number, name: string, category: MarketingTermsCategory) => {
      if (window.confirm(t("detail.userInfo.withdrawTerms.confirmMessage", { name }))) {
        withdrawTerms(organManagerIdx, category, () => {
          alert(t("detail.userInfo.withdrawTerms.resultMessage"));
          onCompletedCallback();
        });
      }
    },
    [withdrawTerms, onCompletedCallback, t]
  );

  return (
    <RoleChecker menuCode={AdminMenuCode.USER_DETAIL_WITHDRAW_CONSENT}>
      <RedActionButton
        onClick={(e) => {
          e.preventDefault();
          switch (targetType) {
            case CustomerType.USER:
              onClickDisagreeMarketingButton(targetIdx, targetName, category);
              break;
            case CustomerType.ORGAN_MANAGER:
              onClickOrganManagerDisagreeMarketingButton(targetIdx, targetName, category);
              break;
          }
        }}
      >
        {t("detail.userInfo.withdrawTerms.buttonLabel")}
      </RedActionButton>
    </RoleChecker>
  );
}
