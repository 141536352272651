import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../hooks/hooks";
import { getVouchersByOrderAsync } from "../../../store/voucherSlice";
import { ProductVoucherDTO } from "../../../types/voucher";

export default function useVoucherGetter() {
  const dispatch = useAppDispatch();
  const [productVouchers, setProductVouchers] = useState<ProductVoucherDTO[]>();

  const getProductVoucher = useCallback(
    (orderIdx: number): Promise<ProductVoucherDTO[]> => {
      return dispatch(getVouchersByOrderAsync(orderIdx))
        .unwrap()
        .then((response: ProductVoucherDTO[]) => {
          setProductVouchers(response);
          return response;
        });
    },
    [dispatch]
  );

  return {
    productVouchers,
    getProductVoucher,
  };
}
