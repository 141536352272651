import styled from "styled-components";
import { GridInputText } from "../../../../components/Inputs";
import { Postcode } from "../../../../components/Postcode";
import variables from "../../../../assets/scss/variable.module.scss";

export const UserDetailInnerWrapperStyle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const UserDetailMarketingReceiptWrapperStyle = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
`;

export const UserDetailMarketingReceiptInfoStyle = styled.div`
  padding: 12px 20px;
  border-left: 1px solid #dedede;

  display: flex;
  align-items: center;
  gap: 10px;

  &.notKO {
    align-items: flex-start;
  }

  &:first-child {
    border-left: none;
  }
`;

export const DetailPositionInMiddleStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;

export const UserDetailKoreaAddressInputWrapperStyle = styled(UserDetailInnerWrapperStyle)`
  padding-right: 20px;
`;

export const GridInputAccountNumberInputStyle = styled(GridInputText)`
  width: 40%;
`;

export const GridInputAccountNameInputStyle = styled(GridInputText)`
  border-left: 1px solid ${(props) => props.theme.color.cDE};
  width: 35%;
`;

export const ChangeAddressButton = styled(Postcode)`
  font-size: 14px;
  line-height: 1.6;
  padding: 0 6px;
  color: ${variables.colorFGDarkBlue};
  border: 1px solid ${variables.colorFGDarkBlue};
  border-radius: 2px;
  flex-shrink: 0;
  height: 24px;
`;

export const MiddleAlignWrapperStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const LightTextStyle = styled.span`
  font-weight: normal;
`;
