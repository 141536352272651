import { AdminMenuCode } from "../../../types/adminRole";
import { RoleChecker } from "../RoleChecker";
import React, { Fragment } from "react";

export function useRoleChecker() {
  const getRoleCheckedElement = (
    key: string,
    element: JSX.Element,
    menuCode?: AdminMenuCode,
    alternativeElement?: JSX.Element
  ) =>
    menuCode ? (
      <RoleChecker key={key} menuCode={menuCode} alternativeChildren={alternativeElement}>
        {element}
      </RoleChecker>
    ) : (
      <Fragment key={key}>{element}</Fragment>
    );

  return {
    getRoleCheckedElement,
  };
}
