import { useAppDispatch, useListFilterValues, useNationality, useTimeConverter } from "../../../../hooks/hooks";
import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SORT,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../types/page";
import {
  AdminUserProfileListDTO,
  ProfileQueryType,
  ProfileSearchCondition,
  TesterListVO,
} from "../../../../types/userProfile";
import { useEffect, useMemo, useState } from "react";
import { hasBothValuesOrBothNoValue, isFromNumberMoreThanToNumber } from "../../../../util/etcUtil";
import { getUserProfileListAsync } from "../../../../store/userProfileSlice";
import { TesterListQueryType } from "./useTesterFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";
import { UserStatus } from "../../../../types/user";
import { useSortParam } from "../../../../hooks/list/useSortParam";
import { dateToString } from "../../../../util/dateUtil";

interface Args {
  onCompletedCallback?: () => void;
}
export function useTesterList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("tester");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { toSortArray } = useSortParam();
  const { timeConverter } = useTimeConverter();

  const [testers, setTesters] = useState<PageResponse<TesterListVO>>();
  const [totalTesterCount, setTotalTesterCount] = useState<number>(0);

  const pageCondition = useMemo(
    () => ({
      page: testers?.number ?? 0,
      size: testers?.size ?? 20,
      sort: ordersToSortArr(testers?.orders),
    }),
    [testers]
  );
  const { nationality } = useNationality();

  const getTesterList = async (param: ProfileSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<AdminUserProfileListDTO> = await dispatch(getUserProfileListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalTesterCount(result.totalElements);
      } else {
        setTesters({
          ...result,
          content: result.content.map((dto) => AdminUserProfileListDTO.toTesterListVO(dto)),
        });
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  const searchTesterList = (pageSearchCondition: PageSearchCondition) => {
    const param: TesterListQueryType = {
      searchWordType: filterValues.searchWordTypeFilterValue.value,
      searchWord: filterValues.searchWordFilterValue.value
        ? filterValues.searchWordFilterValue.value.trim()
        : undefined,
      ageFrom: filterValues.ageFromFilterValue.value ? filterValues.ageFromFilterValue.value : undefined,
      ageTo: filterValues.ageToFilterValue.value ? filterValues.ageToFilterValue.value : undefined,
      createdAtFrom: filterValues.createdAtFromFilterValue.value
        ? dateToString(filterValues.createdAtFromFilterValue.value)
        : undefined,
      createdAtTo: filterValues.createdAtToFilterValue.value
        ? dateToString(filterValues.createdAtToFilterValue.value)
        : undefined,
      nationality: filterValues.nationalityFilterValue.value ? filterValues.nationalityFilterValue.value : undefined,
      testCntFrom: filterValues.testCntFromFilterValue.value ? filterValues.testCntFromFilterValue.value : undefined,
      testCntTo: filterValues.testCntToFilterValue.value ? filterValues.testCntToFilterValue.value : undefined,
      status: filterValues.statusFilterValue.value ? filterValues.statusFilterValue.value : undefined,
      hasOrganVoucher: filterValues.hasOrganVoucherFilterValue.value
        ? filterValues.hasOrganVoucherFilterValue.value
        : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.ageFrom, param.ageTo)) {
        alert(t("list.validations.bothAgeNeeded"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.ageFrom), Number(param.ageTo))) {
        alert(t("list.validations.incorrectAges"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.testCntFrom, param.testCntTo)) {
        alert(t("list.validations.bothTestCountNeeded"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.testCntFrom), Number(param.testCntTo))) {
        alert(t("list.validations.incorrectTestCount"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.createdAtFrom, param.createdAtTo)) {
        alert(t("list.validations.BothCreatedAtNeeded"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  useEffect(() => {
    getTesterList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      getTesterList({
        pageCondition: {
          page: listQueryType.page ? Number(listQueryType.page) : DEFAULT_PAGE,
          size: listQueryType.size ? Number(listQueryType.size) : DEFAULT_SIZE,
          sort: listQueryType.sort ? toSortArray(listQueryType.sort) : DEFAULT_SORT,
        },
        q: listQueryType.searchWord?.trim(),
        queryType: listQueryType.searchWordType ? (listQueryType.searchWordType as ProfileQueryType) : undefined,
        ageFrom: listQueryType.ageFrom ? Number(listQueryType.ageFrom) : undefined,
        ageTo: listQueryType.ageTo ? Number(listQueryType.ageTo) : undefined,
        createdAtFrom: listQueryType.createdAtFrom
          ? timeConverter.convertToFilterStartOfDay(listQueryType.createdAtFrom)
          : undefined,
        createdAtTo: listQueryType.createdAtTo
          ? timeConverter.convertToFilterEndOfDay(listQueryType.createdAtTo)
          : undefined,
        country: listQueryType.country ? listQueryType.country : undefined,
        nationality: listQueryType.nationality ? listQueryType.nationality : undefined,
        status: listQueryType.status ? (listQueryType.status as UserStatus) : undefined,
        testCntFrom: listQueryType.testCntFrom ? Number(listQueryType.testCntFrom) : undefined,
        testCntTo: listQueryType.testCntTo ? Number(listQueryType.testCntTo) : undefined,
        hasOrganVoucher: listQueryType.hasOrganVoucher ? Boolean(listQueryType.hasOrganVoucher) : undefined,
      }).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchTesterList,
    totalTesterCount,
    testers,
    pageCondition,
  };
}
