import React from "react";
import styled from "styled-components";
import variables from "../../../assets/scss/variable.module.scss";
import { AdminMenuCode } from "../../../types/adminRole";
import { RoleChecker } from "../../../features/role/RoleChecker";

export interface CategoryTab<T> {
  tabName: string;
  tabRightValue: string;
  tabValue: T;
  menuCode?: AdminMenuCode;
}

interface Props<T> {
  tabs: CategoryTab<T>[];
  selectedTab: T;
  onClickTab: (_: T) => void;
}

const MenuList = styled.ul`
  display: flex;
  gap: 20px;
`;

const MenuItem = styled.li`
  box-sizing: border-box;

  height: 58px;
  background-color: white;
  border: 1px solid ${variables.colorDE};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  cursor: pointer;

  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variables.colorFGDarkBlue};
  min-width: 160px;
  gap: 10px;

  &.active {
    color: ${variables.colorFGBlue};
    position: relative;

    &:before {
      content: "";
      width: calc(100% + 2px);
      height: 6px;
      background-color: ${variables.colorFGBlue};
      position: absolute;
      left: -1px;
      top: -1px;
    }
  }
`;

const KeyText = styled.span`
  font-weight: normal;
`;

const ValueText = styled.span`
  font-weight: bold;
`;

export function DetailSubMenuCategories<T>({ tabs, selectedTab, onClickTab }: Props<T>) {
  return (
    <MenuList>
      {tabs.map((tab, idx) => (
        <RoleChecker key={`sub-category-menu-${idx}`} menuCode={tab.menuCode}>
          <MenuItem className={selectedTab === tab.tabValue ? "active" : ""} onClick={() => onClickTab(tab.tabValue)}>
            <KeyText>{tab.tabName}</KeyText>
            <ValueText>{tab.tabRightValue}</ValueText>
          </MenuItem>
        </RoleChecker>
      ))}
    </MenuList>
  );
}
