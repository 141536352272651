import { NumberFilter } from "../../../../types/tableFilter";
import { FilterNumber } from "./FilterNumber";

interface Props {
  from: NumberFilter;
  to: NumberFilter;
}

export function FilterNumberRange({ from, to }: Props) {
  return (
    <>
      <FilterNumber numberField={from} />
      ~
      <FilterNumber numberField={to} />
    </>
  );
}
