import { Anchor } from "../../../components/Anchor";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MiniModal } from "../../../layout/modal/MiniModal";
import { useVoucherGetter } from "../../voucher/useVoucherGetter";
import { callAsync } from "../../../util/sliceUtil";
import { MINI_MODAL_LINK } from "../../../util/etcUtil";
import { useOrganGetter } from "../../organ/useOrganGetter";
import { ProductVoucherDTO, ProfileOrganVoucherVO, VoucherTranslator } from "../../../types/voucher";
import { OrganDTO } from "../../../types/organ";
import styled from "styled-components";
import { useAppSelector, useTimeConverter } from "../../../hooks/hooks";
import { voucherStatus } from "../../../store/voucherSlice";
import { organStatus } from "../../../store/organSlice";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";

interface Props {
  productVoucherIdx: number;
  organSignInCode: string;
}

const ContentStyle = styled.p`
  text-align: left;
`;

const SpanStyle = styled.span`
  color: #583be2;
`;

export function TesterOrganVoucherText({ productVoucherIdx, organSignInCode }: Props) {
  const { t } = useTranslation("tester");
  const voucherStatusValue = useAppSelector(voucherStatus);
  const organStatusValue = useAppSelector(organStatus);
  const { timeConverter } = useTimeConverter();
  const [showModal, setShowModal] = useState(false);
  const { getVoucherDetail } = useVoucherGetter();
  const { getOrganDetail } = useOrganGetter();
  const [voucherVO, setVoucherVO] = useState<ProfileOrganVoucherVO>();
  const linkRef = useRef<HTMLAnchorElement | null>(null);
  const { defaultErrorMessage } = useUtil();

  useEffect(() => {
    if (showModal && !voucherVO) {
      getVoucherDetail(productVoucherIdx).then((dto: ProductVoucherDTO) => {
        if (dto.originalOwnerOrganIdx) {
          getOrganDetail(dto.originalOwnerOrganIdx).then((organDTO: OrganDTO) => {
            setVoucherVO(VoucherTranslator.createProfileOrganVoucherVO(dto, organDTO, organSignInCode));
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, voucherVO]);

  const onClickOrganVoucher = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      callAsync(
        getVoucherDetail(productVoucherIdx),
        (_) => {},
        () => alert(defaultErrorMessage)
      ).then();
      setShowModal(true);
    },
    [getVoucherDetail, productVoucherIdx, defaultErrorMessage]
  );

  const addHyphenAfterFirstLetter = (str: string) => {
    if (!str) return '';
    return str.charAt(0) + '-' + str.slice(1);
  };

  return (
    <Anchor onClick={onClickOrganVoucher} style={{ position: "relative" }} ref={linkRef} data-name={MINI_MODAL_LINK}>
      {t("organVoucher.inPossession")}
      <MiniModal
        show={showModal}
        closeModal={() => setShowModal(false)}
        title={t("organVoucher.vouchersInPossession")}
        modalWidth={"max-content"}
        statusValues={[voucherStatusValue, organStatusValue]}
        linkElement={linkRef.current ?? undefined}
        modalLeftPosition={"auto"}
        modalRightPosition={"0"}
      >
        <>
          {voucherVO ? (
            <ContentStyle>
              {t("organVoucher.inPossession")} ({voucherVO.organName})<br />
              {t("organVoucher.organSignInCode")} : {voucherVO.loginCode} <br />
              {t("organVoucher.voucherType")} : <SpanStyle>{addHyphenAfterFirstLetter(voucherVO.voucherType)}</SpanStyle><br />
              {t("organVoucher.availableUntil")} : {timeConverter.convertToLocalTime(voucherVO.grantedAt)}~
              {timeConverter.convertToLocalTime(voucherVO.availableUntil)}
            </ContentStyle>
          ) : (
            <></>
          )}
        </>
      </MiniModal>
    </Anchor>
  );
}
