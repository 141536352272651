import { Calendar } from "../../../../types/tableFilter";
import { FilterInputCalendar } from "../../../../components/Inputs";
import { useTranslation } from "react-i18next";

interface CalendarProps {
  calendar: Calendar;
  min?: string;
  max?: string;
}

export function FilterCalendar({ calendar, min, max }: CalendarProps) {
  const { t } = useTranslation("common");

  return (
    <FilterInputCalendar
      value={calendar.value}
      onKeyDown={(e) => {
        if (new RegExp(/\d/).test(e.key)) {
          e.preventDefault();
        }
      }}
      onChange={(e) => {
        if (e.target.value) {
          calendar.onChangeFunc(e.target.value);
        } else {
          calendar.onChangeFunc("");
        }
      }}
      required={calendar.isRequired}
      disabled={calendar.isDisabled ?? false}
      min={min}
      max={max}
      placeholder={calendar.placeholderVal ?? t("inputDatePlaceholder")}
    />
  );
}
