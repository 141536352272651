import loader from "../../assets/images/loader.gif";
import styles from "./loader.module.scss";
import { Status } from "../../types/common";

interface Props {
  status?: Status;
}

export function Loader({ status }: Props) {
  return (
    <>
      {status === Status.LOADING ? (
        <div className={styles.backdrop}>
          <img className={styles.loader} src={loader} alt="loading icon" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
