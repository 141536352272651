import { useState } from "react";

export function useSampleSearchWord() {
  const [sampleSearchWord, setSampleSearchWord] = useState("");
  const searchWordCode = `
const [sampleSearchWord, setSampleSearchWord] = useState('');

<SearchField value={sampleSearchWord}
             onKeyUpFunc={e => {
               if (e.key === 'Enter') {
                 alert('검색!');
               }
             }}
             onChangeFunc={e => setSampleSearchWord((e.target as HTMLInputElement).value)}
             onClickSearchButtonFunc={() => alert('버튼 클릭')}/>  
  `;

  const searchWordDescription = `
- SearchField의 Props 타입
{
  value: string;
  onKeyUpFunc: (e: React.KeyboardEvent) => void;
  onChangeFunc: (e: React.ChangeEvent) => void;
  onClickSearchButtonFunc: (e: React.MouseEvent) => void;
  placeholder?: string;
  buttonText?: string;
  className?: string;
  isRequired?: boolean;  
}  
  `;

  return {
    sampleSearchWord,
    setSampleSearchWord,
    searchWordCode,
    searchWordDescription,
  };
}
