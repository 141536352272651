import { Panel } from "../../../../layout/content/detail/Panel";
import { DescriptionList } from "../../../../layout/content/detail/DescriptionList";
import { useAdminInfoData } from "./hooks/useAdminInfoData";
import deleteIcon from "../../../../assets/images/button_icons/delete_icon.svg";
import React, { useCallback, useEffect, useRef, useState } from "react";
import style from "../../user/memberUserDetail.module.scss";
import { AdminPutParam, AdminVO } from "../../../../types/admin";
import { callAsync } from "../../../../util/sliceUtil";
import { adminStatus, issueTemporaryPassword, updateAdminAsync } from "../../../../store/adminSlice";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../../../hooks/hooks";
import { useLoader } from "../../../../hooks/common/useLoader";
import { useSignedInAdminIdx } from "../hooks/useSignedInAdminIdx";
import { DetailActionButton } from "../../../../components/Buttons";
import confirmIcon from "../../../../assets/images/button_icons/confirm_icon.svg";
import personIcon from "../../../../assets/images/button_icons/person_icon.svg";
import passwordIcon from "../../../../assets/images/button_icons/password_icon.svg";
import { RoleChecker } from "../../../role/RoleChecker";
import { AdminMenuCode } from "../../../../types/adminRole";
import { useAdminGetter } from "./hooks/useAdminGetter";
import { useAdminStatus } from "../hooks/useAdminStatus";
import { useAdminButtons } from "../hooks/useAdminButtons";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

interface Props {
  idx: number;
}

export function MemberAdminInfo({ idx }: Props) {
  const { t } = useTranslation("admin");
  const dispatch = useAppDispatch();
  const status = useAppSelector(adminStatus);
  const formRef = useRef<HTMLFormElement>(null);
  useLoader({ status });
  const userIdx = useSignedInAdminIdx();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [adminPutParam, setAdminPutParam] = useState<AdminPutParam | null>(null);
  const { adminVO, getAdmin, setAdmin } = useAdminGetter();
  const { openChangingPasswordPopup, openAdminRolePopup } = useNewWindow();
  const { isBlocked, isLeaved } = useAdminStatus();
  const { defaultErrorMessage } = useUtil();

  useEffect(() => {
    if (idx) getAdmin(idx);
  }, [idx, getAdmin]);

  const onClickChangingPasswordButton = useCallback(() => openChangingPasswordPopup(), [openChangingPasswordPopup]);

  const onClickChangeRoleButton = useCallback(() => {
    if (adminVO == null) return;
    openAdminRolePopup(
      {
        adminIdx: adminVO.idx,
        roleIdx: adminVO.role.idx,
      },
      () => getAdmin(idx)
    );
  }, [idx, adminVO, getAdmin, openAdminRolePopup]);

  const onClickIssueTemporaryPasswordButton = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (adminVO == null) return;

      if (!window.confirm(t("buttons.issuePassword.confirmMessage", { name: adminVO.name }))) return;

      callAsync(
        dispatch(issueTemporaryPassword(adminVO.idx)).unwrap(),
        () => window.alert(t("buttons.issuePassword.resultMessage")),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, t, adminVO, defaultErrorMessage]
  );

  const { getDescList, reset } = useAdminInfoData({
    admin: adminVO,
    onClickChangeRoleButton,
    onUpdateAdminPutParam: setAdminPutParam,
  });

  const { UnblockButtonOnDetail, BlockButtonOnDetail, LeaveButtonOnDetail } = useAdminButtons({
    onRefresh: () => getAdmin(idx),
  });

  const onClickUpdateAdminButton = useCallback(() => {
    if (adminPutParam == null) return;
    if (!formRef.current?.reportValidity()) return;

    callAsync(
      dispatch(updateAdminAsync({ idx, adminPutParam })).unwrap(),
      (result: AdminVO) => {
        setAdmin(result);

        alert(t("buttons.update.resultMessage"));
        setIsEditMode(false);
      },
      (e) => {
        if (e.errorCode.httpCode === 473) {
          alert(t("buttons.update.duplicatedPhoneNumber"));
        } else {
          alert(defaultErrorMessage);
        }
      }
    ).then();
  }, [setAdmin, t, setIsEditMode, dispatch, idx, adminPutParam, defaultErrorMessage]);

  const getAdditionalButtons = (): JSX.Element => {
    return (
      <>
        {adminVO ? <UnblockButtonOnDetail adminIdx={adminVO.idx} adminStatus={adminVO.status} /> : <></>}
        {isEditMode ? (
          <>
            <DetailActionButton
              onClick={() => {
                setIsEditMode(false);
                reset();
              }}
            >
              {t("buttons.update.cancelButton")}
            </DetailActionButton>
            <DetailActionButton onClick={() => onClickUpdateAdminButton()}>
              <img src={confirmIcon} alt={t("buttons.update.icon")} />
              {t("buttons.update.saveButton")}
            </DetailActionButton>
          </>
        ) : (
          <>
            {adminVO?.status && (isBlocked(adminVO.status) || isLeaved(adminVO.status)) ? (
              <></>
            ) : (
              <>
                <RoleChecker menuCode={AdminMenuCode.ADMIN_UPDATE_INFO}>
                  <DetailActionButton onClick={() => setIsEditMode(true)}>
                    <img src={personIcon} alt={t("buttons.update.icon")} />
                    {t("buttons.update.updateButton")}
                  </DetailActionButton>
                </RoleChecker>

                {!isCurrentAdminIsMe() && (
                  <RoleChecker menuCode={AdminMenuCode.ADMIN_ISSUE_TEMPORARY_PASSWORD}>
                    <DetailActionButton onClick={(e) => onClickIssueTemporaryPasswordButton(e)}>
                      <img src={passwordIcon} alt={t("buttons.issuePassword.icon")} />
                      {t("buttons.issuePassword.buttonLabel")}
                    </DetailActionButton>
                  </RoleChecker>
                )}
              </>
            )}
          </>
        )}
        {isCurrentAdminIsMe() && !isEditMode && (
          <DetailActionButton onClick={onClickChangingPasswordButton}>
            <img src={deleteIcon} alt={t("buttons.changePassword.icon")} />
            {t("buttons.changePassword.buttonLabel")}
          </DetailActionButton>
        )}
      </>
    );
  };

  const isCurrentAdminIsMe = () => Number(userIdx) === Number(adminVO?.idx);
  return adminVO ? (
    <>
      <Panel title={t("detail.title")} additionalButtons={getAdditionalButtons()}>
        <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
          <DescriptionList data={getDescList(adminVO)} isEditMode={isEditMode} labelWidth={132} />
        </form>
      </Panel>

      <div className={style.buttonArea}>
        <BlockButtonOnDetail
          admin={{ adminIdx: adminVO.idx, adminStatus: adminVO.status }}
          isCurrentAdminIsMe={isCurrentAdminIsMe()}
        />
        <LeaveButtonOnDetail adminStatus={adminVO.status} isCurrentAdminIsMe={isCurrentAdminIsMe()} />
      </div>
    </>
  ) : (
    <></>
  );
}
