import style from "./post.module.scss";
import { useRef } from "react";

interface Props {
  children: JSX.Element;
  buttonTitle: string;
  buttonOnClickAction: VoidFunction;
  title: string;
  formClassName?: string;
}

export function Post({ children, buttonTitle, buttonOnClickAction, title, formClassName }: Props) {
  const formRef = useRef<HTMLFormElement>(null);

  const onClickButton = () => {
    if (formRef.current?.reportValidity()) {
      buttonOnClickAction();
    }
  };

  return (
    <div className={style.post}>
      <h2 className={style.title}>{title}</h2>
      <form className={`${style.form} ${formClassName ?? ""}`} ref={formRef} onSubmit={(e) => e.preventDefault()}>
        {children}
        <div className={style.buttonArea}>
          <button type={"button"} onClick={onClickButton}>
            {buttonTitle}
          </button>
        </div>
      </form>
    </div>
  );
}
