import {ordersToSortArr, PageResponse, PageSearchCondition} from "../../../../../../types/page";
import {
    OrganEmailCheckDTO,
    OrganManagerAuthorityDTO,
    OrganManagerOrganDTO,
    OrganManagerOrganSearchCondition,
    OrganPhoneCheckDTO
} from "../../../../../../types/organ";
import {callAsync} from "../../../../../../util/sliceUtil";
import {useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/hooks";
import {getOrgansAsync, organs, setOrgans} from "../../../../../../store/organSlice";
import {useUtil} from "../../../../../../util/hooks/useUtil";
import {checkOrganEmailAsync, checkOrganPhoneNumberAsync} from "../../../../../../store/organManagerSlice";

export function useOrgansGetter(organManagerIdx: number) {
    const dispatch = useAppDispatch();
    const list = useAppSelector(organs);
    const {defaultErrorMessage} = useUtil();

    const pageCondition = useMemo(
        () => ({
            page: list?.number ?? 0,
            size: list?.size ?? 5,
            sort: ordersToSortArr(list?.orders),
        }),
        [list]
    );

    const getOrganList = ({page, size, sort}: PageSearchCondition) => {
        const param: OrganManagerOrganSearchCondition = {
            pageCondition: {
                page: page ?? 0,
                size: size ?? pageCondition.size,
                sort: sort ?? pageCondition.sort,
            },
        };

        return () => {
            callAsync<PageResponse<OrganManagerOrganDTO>>(
                dispatch(getOrgansAsync({organManagerIdx, condition: param})).unwrap(),
                (result) => dispatch(setOrgans(result)),
                () => alert(defaultErrorMessage)
            ).then();
        };
    };

    const checkEmail = async (email: string) => {
        const param: OrganEmailCheckDTO = {
            email: email,
        };

        try {
            return await dispatch(checkOrganEmailAsync({condition:param})).unwrap();
        } catch (e) {
            console.error(e);
            throw new Error("입력 오류");
        }
    };

    const checkCellphone = async (number: string, codePhone: string) => {
        const param: OrganPhoneCheckDTO = {
            phoneNumber: number,
            codePhone: codePhone,
        };

        try {
            return await dispatch(checkOrganPhoneNumberAsync({condition:param})).unwrap();
        } catch (e) {
            console.error(e);
            throw new Error("입력 오류");
        }
    };

    //eslint-disable-next-line
    const getOrganAuthority = ({}: OrganManagerAuthorityDTO) => {
        return () => {

        }
    };


    return {
        getOrganList,
        organs: list,
        getOrganAuthority,
        organAuthority: list,
        checkEmail,
        checkCellphone
    };
}
