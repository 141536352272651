import {useCallback} from "react";
import {useAppDispatch} from "../../../../hooks/hooks";
import {disableMarketingAgreement} from "../../../../store/simpleTestSlice";

interface DisagreeType {
    marketingDisagree: (idx: number[]) => Promise<any>;
}

export function useSimpleTesterMarketingDisagree(): DisagreeType {
    const dispatch = useAppDispatch();

    const marketingDisagree = useCallback(
        (idx: number[]) => {
            return dispatch(disableMarketingAgreement(idx)).unwrap();
        },
        [dispatch]
    );

    return {
        marketingDisagree,
    };
}