import { CustomerType } from "../../../types/common";
import { useCallback, useState } from "react";
import { CustomerVO, useCustomerGetter } from "./useCustomerGetter";

interface Props {
  customerType?: CustomerType;
  buyerIdx?: number;
}
export function useRefundAccountGetter({ buyerIdx, customerType }: Props) {
  const getRefundAccount = useCustomerGetter();
  const [customerVO, setCustomerVO] = useState<CustomerVO>();

  const loadRefundAccount = useCallback(() => {
    if (buyerIdx && customerType) {
      getRefundAccount(buyerIdx, customerType, setCustomerVO);
    }
  }, [getRefundAccount, buyerIdx, customerType]);

  return {
    customerVO,
    loadRefundAccount,
  };
}
