import { DescriptionList } from "../../../../layout/content/detail/DescriptionList";
import { OrderStatus, OrdersVO } from "../../../../types/orders";
import { useCallback, useMemo } from "react";
import { PaymentVO, PaymentWay } from "../../../../types/payment";
import styled from "styled-components";
import {convertCurrencyUnit, numberWithCommas} from "../../../../util/etcUtil";
import { Panel } from "../../../../layout/content/detail/Panel";
import style from "../orderDetail.module.scss";
import { useOrderButtons } from "../../hooks/useOrderButtons";
import { useTranslation } from "react-i18next";
import { usePaymentType } from "../../../../types/hooks/usePaymentType";
import { useTimeConverter } from "../../../../hooks/hooks";
import { MiddleAlignWrapperStyle } from "../../../member/user/components/UserSelfInfoDataStyle";

const PaymentListStyle = styled.ul`
  li {
    list-style: inside;
  }
`;

interface Props {
  orderVO: OrdersVO;
  paymentVO?: PaymentVO;
}

export function PaymentWayInfo({ orderVO, paymentVO }: Props) {
  const { t } = useTranslation("order");
  const { CancelButton } = useOrderButtons({ onRefresh: () => {} });
  const { toStringFromPaymentWay } = usePaymentType();
  const { timeConverter } = useTimeConverter();

  const isAfterPaid = useCallback(
    (orderStatus: OrderStatus) => orderStatus === OrderStatus.PAID || orderStatus === OrderStatus.CANCELLED,
    []
  );

  const inAppPaymentDescription = useMemo(
    () => [
      [{ key: t("paymentWayInfo.payer"), value: orderVO.buyerName }],
      [
        { key: t("paymentWayInfo.way"), value: toStringFromPaymentWay(orderVO.paymentWay) },
        {
          key: t("paymentWayInfo.paymentCompany.label"),
          value: toStringFromPaymentWay(orderVO.paymentWay),
        },
      ],
      [
        {
          key: t("paymentWayInfo.cardPaymentInfo.label"),
          value: (
            <MiddleAlignWrapperStyle>
              {isAfterPaid(orderVO.status) ? (
                <PaymentListStyle>
                  <li>TID: {paymentVO?.tid ?? ""}</li>
                </PaymentListStyle>
              ) : (
                "-"
              )}
            </MiddleAlignWrapperStyle>
          ),
        },
        {
          key: t("paymentWayInfo.paymentDetails.label"),
          value: isAfterPaid(orderVO.status) ? (
            <MiddleAlignWrapperStyle>
              <PaymentListStyle>
                <li>
                  {`${timeConverter.convertToLocalTime(orderVO.paidAt!!)} / 
                  ${numberWithCommas(orderVO.payAmount)}${convertCurrencyUnit(orderVO.paymentWay, t)}`}{" "}
                </li>
                <li>
                  {t("paymentWayInfo.paymentDetails.finalPaymentAmount")}: {numberWithCommas(orderVO.payAmount)}
                  {convertCurrencyUnit(orderVO.paymentWay, t)}
                </li>
              </PaymentListStyle>
            </MiddleAlignWrapperStyle>
          ) : (
            "-"
          ),
        },
      ],
    ],
    [t, orderVO, toStringFromPaymentWay, isAfterPaid, paymentVO, timeConverter]
  );

  function getDescription(paymentVO?: PaymentVO) {
    switch (orderVO.paymentWay) {
      case PaymentWay.VBANK:
        return [
          [
            { key: t("paymentWayInfo.payer"), value: orderVO.buyerName },
            { key: t("paymentWayInfo.way"), value: toStringFromPaymentWay(orderVO.paymentWay) },
          ],
          [
            {
              key: t("paymentWayInfo.bank"),
              value: (
                <MiddleAlignWrapperStyle>
                  {orderVO.vbankName
                    ? `${orderVO.vbankName} / ${orderVO.vbankAccountNumber} / ${orderVO.buyerName}`
                    : "-"}
                </MiddleAlignWrapperStyle>
              ),
            },
            {
              key: t("paymentWayInfo.paidAt"),
              value: (
                <MiddleAlignWrapperStyle>
                  {orderVO?.paidAt ? timeConverter.convertToLocalTime(orderVO.paidAt) : "-"}
                </MiddleAlignWrapperStyle>
              ),
            },
          ],
        ];
      case PaymentWay.APPLE:
        return inAppPaymentDescription;
      case PaymentWay.GOOGLE:
        return inAppPaymentDescription;
      default:
        return [
          [{ key: t("paymentWayInfo.payer"), value: isAfterPaid(orderVO.status) ? orderVO.buyerName : "-" }],
          [
            {
              key: t("paymentWayInfo.way"),
              value: isAfterPaid(orderVO.status) ? toStringFromPaymentWay(orderVO.paymentWay) : "-",
            },
            {
              key: t("paymentWayInfo.paymentCompany.label"),
              value: isAfterPaid(orderVO.status) ? t("paymentWayInfo.paymentCompany.nicePayments") : "-",
            },
          ],
          [
            {
              key: t("paymentWayInfo.cardPaymentInfo.label"),
              value: (
                <MiddleAlignWrapperStyle>
                  {isAfterPaid(orderVO.status) ? (
                    <PaymentListStyle>
                      <li>
                        {t("paymentWayInfo.cardPaymentInfo.cardCompany")}: {paymentVO?.cardCorp ?? ""}
                      </li>
                      <li>TID: {paymentVO?.tid ?? ""}</li>
                    </PaymentListStyle>
                  ) : (
                    "-"
                  )}
                </MiddleAlignWrapperStyle>
              ),
            },
            {
              key: t("paymentWayInfo.paymentDetails.label"),
              value: isAfterPaid(orderVO.status) ? (
                <MiddleAlignWrapperStyle>
                  <PaymentListStyle>
                    <li>
                      {t("paymentWayInfo.paymentDetails.creditCardPayment")}:{" "}
                      {`${timeConverter.convertToLocalTime(orderVO.paidAt!!)} / ${numberWithCommas(orderVO.payAmount)}`}
                      {convertCurrencyUnit(orderVO.paymentWay,t)}
                    </li>
                    <li>
                      {t("paymentWayInfo.paymentDetails.finalPaymentAmount")}: {numberWithCommas(orderVO.payAmount)}
                      {convertCurrencyUnit(orderVO.paymentWay,t)}
                    </li>
                  </PaymentListStyle>
                </MiddleAlignWrapperStyle>
              ) : (
                "-"
              ),
            },
          ],
        ];
    }
  }

  return (
    <Panel
      id="PAYMENT_WAY"
      title={t("paymentWayInfo.title")}
      panelClassName={style.scrollMarginTop}
      additionalButtons={<CancelButton order={orderVO} />}
    >
      <>{<DescriptionList data={getDescription(paymentVO)} labelWidth={132} labelClassName={style.paddingTB12} />}</>
    </Panel>
  );
}
