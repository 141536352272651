import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AffiliateCodeOrderSearchCondition, OrderSearchCondition, RefundRequestDTO } from "../types/orders";
import { addCases, executePromise } from "../util/sliceUtil";
import { OrderApi } from "../api/OrderApi";
import { RootState } from "./index";

export interface OrderState extends SliceState {
  refundItemGetterStatus: Status;
  orderItemGetterStatus: Status;
}

const initialState: OrderState = {
  status: Status.IDLE,
  refundItemGetterStatus: Status.IDLE,
  orderItemGetterStatus: Status.IDLE,
};

export const searchOrdersAsync = createAsyncThunk(
  "orders/searchOrders",
  (condition: OrderSearchCondition | AffiliateCodeOrderSearchCondition) =>
    executePromise(OrderApi.searchOrders(condition))
);

export const getOrderItemsAsync = createAsyncThunk("orders/getOrderItems", (orderIdx: number) =>
  executePromise(OrderApi.getOrderItems(orderIdx))
);

export const getOrdersAsync = createAsyncThunk("orders/getOrders", (orderIdx: number) =>
  executePromise(OrderApi.getOrders(orderIdx))
);

export const getBuyerAsync = createAsyncThunk("orders/getBuyer", (orderIdx: number) =>
  executePromise(OrderApi.getBuyer(orderIdx))
);

export const getRefundsAsync = createAsyncThunk("orders/getRefunds", (orderIdx: number) =>
  executePromise(OrderApi.getRefunds(orderIdx))
);

export const getRefundItemsAsync = createAsyncThunk("orders/getRefundItems", (refundIdx: number) =>
  executePromise(OrderApi.getRefundItems(refundIdx))
);

export const postRefundAsync = createAsyncThunk(
  "orders/putRefundAsync",
  ({ orderIdx, refundRequestDTO }: { orderIdx: number; refundRequestDTO: RefundRequestDTO }) =>
    executePromise(OrderApi.postRefund(orderIdx, refundRequestDTO))
);

export const deleteOrderAsync = createAsyncThunk("orders/deleteOrder", (orderIdx: number) =>
  executePromise(OrderApi.deleteOrder(orderIdx))
);

export const downloadOrdersAsync = createAsyncThunk(
  "order/downloadOrders",
  ({ orderIdxes, timezone }: { orderIdxes: number[]; timezone: string }) =>
    executePromise(OrderApi.downloadOrders(orderIdxes, timezone))
);

export const downloadAllOrdersAsync = createAsyncThunk(
  "order/downloadAllOrders",
  ({
    condition,
    timezone,
  }: {
    condition: OrderSearchCondition | AffiliateCodeOrderSearchCondition;
    timezone: string;
  }) => executePromise(OrderApi.downloadAllOrders(condition, timezone))
);

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, searchOrdersAsync);
    addCases(builder, getOrdersAsync);
    addCases(builder, getBuyerAsync);
    addCases(builder, getRefundsAsync);
    addCases(builder, postRefundAsync);
    addCases(builder, downloadOrdersAsync);
    addCases(builder, deleteOrderAsync);
    addCases(builder, downloadAllOrdersAsync);

    builder
      .addCase(getOrderItemsAsync.pending, (state) => {
        state.orderItemGetterStatus = Status.LOADING;
      })
      .addCase(getOrderItemsAsync.rejected, (state) => {
        state.orderItemGetterStatus = Status.FAILED;
      })
      .addCase(getOrderItemsAsync.fulfilled, (state) => {
        state.orderItemGetterStatus = Status.IDLE;
      })
      .addCase(getRefundItemsAsync.pending, (state) => {
        state.refundItemGetterStatus = Status.LOADING;
      })
      .addCase(getRefundItemsAsync.rejected, (state) => {
        state.refundItemGetterStatus = Status.FAILED;
      })
      .addCase(getRefundItemsAsync.fulfilled, (state) => {
        state.refundItemGetterStatus = Status.IDLE;
      });
  },
});

export default orderSlice.reducer;
export const orderStatus = (state: RootState) => state.orders.status;
export const refundItemGetterStatus = (state: RootState) => state.orders.refundItemGetterStatus;
export const orderItemGetterStatus = (state: RootState) => state.orders.orderItemGetterStatus;
