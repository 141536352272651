import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { resendSignUpEmailAsync } from "../../../../store/organManagerSlice";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useOrganManagerEmailResender() {
  const { t } = useTranslation("organManager");
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const resendEmail = useCallback(
    (organManagerIdx: number) => {
      if (window.confirm(t("resend.confirmMessage"))) {
        callAsync(
          dispatch(resendSignUpEmailAsync(organManagerIdx)).unwrap(),
          () => alert(t("resend.resultMessage")),
          () => alert(defaultErrorMessage)
        ).then();
      }
    },
    [defaultErrorMessage, dispatch, t]
  );

  return {
    resendEmail,
  };
}
