import { VoucherStatusVO } from "../../types/voucher";
import { useCallback } from "react";

export function useOrganVoucherStatusVO() {
  const getTextClassNameByVoucherStatusVO = useCallback((status: VoucherStatusVO) => {
    switch (status) {
      case VoucherStatusVO.COLLECTED:
        return "red underline";
      case VoucherStatusVO.USED:
        return "gray";
      case VoucherStatusVO.USABLE:
        return "blue";
      case VoucherStatusVO.EXPIRED:
      default:
        return "red";
    }
  }, []);

  return {
    getTextClassNameByVoucherStatusVO,
  };
}
