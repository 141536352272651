import style from "./productEditForm.module.scss";
import AddImageImg from "../../../assets/images/product/add_image.svg";
import React, { useMemo } from "react";
import { FileAcceptType } from "../../component/file/FileInput";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
  imageSrc?: string;
  onClickCloseBtn?: VoidFunction;
  onClickBlankImgBtn?: VoidFunction;
  isThumbnail?: boolean;
  buttonStyleObject?: React.CSSProperties;
}

export function ImageButton({
  className,
  imageSrc,
  onClickCloseBtn,
  onClickBlankImgBtn,
  isThumbnail = false,
  buttonStyleObject,
}: Props) {
  const { t } = useTranslation("product");
  const isImage = useMemo(() => {
    const imageSuffixes = FileAcceptType.getTypes(FileAcceptType.IMAGE);
    return imageSrc?.startsWith("data:image") || imageSuffixes.some((suffix) => imageSrc?.endsWith(suffix));
  }, [imageSrc]);

  const isVideo = useMemo(() => {
    const videoSuffixes = FileAcceptType.getTypes(FileAcceptType.VIDEO);
    return imageSrc?.startsWith("data:video") || videoSuffixes.some((suffix) => imageSrc?.endsWith(suffix));
  }, [imageSrc]);

  return (
    <button
      type={"button"}
      className={`${style.imageButton} ${className ?? ""}`}
      onClick={(e) => {
        e.preventDefault();
        if (onClickBlankImgBtn) {
          onClickBlankImgBtn();
        }
      }}
      style={buttonStyleObject}
    >
      {imageSrc ? (
        <div className={style.addedImage}>
          {isImage && <img src={imageSrc} alt={t("edit.imageButton.addedImage")} />}
          {isVideo && (
            <video>
              <source src={imageSrc} />
            </video>
          )}
          <div className={style.closeBtn} onClick={onClickCloseBtn}>
            <div className={style.closeIcon}></div>
          </div>
        </div>
      ) : (
        <div className={style.blankImage}>
          <img
            className={isThumbnail ? style.thumbnail : style.detail}
            src={AddImageImg}
            alt={t("edit.imageButton.attachedImageIcon")}
          />
        </div>
      )}
    </button>
  );
}
