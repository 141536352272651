import {ProfileQueryType} from "../../../../types/userProfile";
import {FilterContentType, TableFilter} from "../../../../types/tableFilter";
import {useLanguage, useListFilterValues, useTimeConverter} from "../../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useUserType} from "../../../../types/hooks/useUserType";
import {AccessLocation, IsAgreementMarketing} from "../../../../types/tester";

interface UseTesterFiltersReturnType {
  filters: TableFilter[][];
}

interface Args {
  search: () => void;
}

export function useSimpleTesterFilters({ search }: Args): UseTesterFiltersReturnType {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation(["simpleTester", "common"]);
  const { timeConverter } = useTimeConverter();
  const { testerApproached } = useUserType();
  const { isKorean: isKoreanLanguage } = useLanguage();

  // @ts-ignore
  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.searchWord.label"),
        content: {
          type: FilterContentType.TEXT,
          value: filterValues.searchWordFilterValue.value,
          maxLength: 100,
          placeholderVal: t("list.filter.searchWord.placeholder"),
          onChangeFunc: (v) => filterValues.searchWordFilterValue.setter(v),
          onEnterKeyUpFunc: search,
          typeSelector: {
            type: FilterContentType.SELECT,
            value: filterValues.searchWordTypeFilterValue.value,
            options: [
              { value: ProfileQueryType.NAME, label: t("list.filter.queryType.name") },
              { value: ProfileQueryType.PHONE_NUMBER, label: t("list.filter.queryType.phoneNumber") },
            ],
            onChangeFunc: (v) => filterValues.searchWordTypeFilterValue.setter(v as ProfileQueryType),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("list.filter.createdAt.label"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(filterValues.createdAtFromFilterValue.value),
            onChangeFunc: (v) =>
                filterValues.createdAtFromFilterValue.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder", { ns: "simpleTester" }),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(filterValues.createdAtToFilterValue.value),
            onChangeFunc: (v) =>
                filterValues.createdAtToFilterValue.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
            placeholderVal: t("inputDatePlaceholder", { ns: "simpleTester" }),
          },
        },
      },
      {
        labelTitle: t("list.filter.approached"),
        content: {
          type: FilterContentType.SELECT,
          value: filterValues.accessLocationFilterValue.value ?? "",
          options: testerApproached,
          onChangeFunc: (v) => filterValues.accessLocationFilterValue.setter(v as AccessLocation),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.filter.marketingStatus"),
        content: {
          type: FilterContentType.SELECT,
          value: filterValues.isAgreementMarketingFilterValue.value ?? "",
          options: [
            { value: '', label: '전체' },
            { value: IsAgreementMarketing.Y, label: 'Y' },
            { value: IsAgreementMarketing.N, label: 'N' },
          ],
          onChangeFunc: (v) => filterValues.isAgreementMarketingFilterValue.setter(v as IsAgreementMarketing),
          width: isKoreanLanguage ? undefined : 200,
        },
      },
    ],
  ];

  return {
    filters,
  };
}
