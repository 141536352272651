import { callAsync } from "../../../util/sliceUtil";
import { deleteCouponHistoryAsync } from "../../../store/couponSlice";
import { useCallback } from "react";
import { useAppDispatch } from "../../../hooks/hooks";
import { useUtil } from "../../../util/hooks/useUtil";

interface Args {
  onComplete: () => void;
}

export function useCouponHistoryDeleter({ onComplete }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deleteCouponHistory = useCallback(
    (idx: number) => {
      callAsync(
        dispatch(deleteCouponHistoryAsync(idx)).unwrap(),
        () => onComplete(),
        () => {
          alert(defaultErrorMessage);
        }
      ).then();
    },
    [onComplete, defaultErrorMessage, dispatch]
  );

  return {
    deleteCouponHistory,
  };
}
