import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import accountReducer from "./accountSlice";
import adminRoleReducer from "./adminRoleSlice";
import termsReducer from "./termsSlice";
import adminReducer from "./adminSlice";
import countryReducer from "./countrySlice";
import memberUserReducer from "./userSlice";
import testReducer from "./testSlice";
import couponReducer from "./couponSlice";
import userProfileReducer from "./userProfileSlice";
import memoSlice from "./memoSlice";
import myPageSlice from "./myPageSlice";
import productSlice from "./productSlice";
import voucherSlice from "./voucherSlice";
import fileSlice from "./fileSlice";
import unitItemSlice from "./unitItemSlice";
import OrderSlice from "./orderSlice";
import organManagerSlice from "./organManagerSlice";
import organSlice from "./organSlice";
import organMemberSlice from "./organMemberSlice";
import PaymentSlice from "./paymentSlice";
import ContentDetailSlice from "./contentDetailSlice";
import AffiliateCodeSlice from "./affiliateCodeSlice";
import PartnerCompanySlice from "./partnerCompanySlice";
import PopupSlice from "./popupSlice";
import simpleTesterReducer from "./simpleTestSlice";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    adminRole: adminRoleReducer,
    terms: termsReducer,
    admin: adminReducer,
    organManager: organManagerSlice,
    organ: organSlice,
    organMember: organMemberSlice,
    country: countryReducer,
    memberUser: memberUserReducer,
    test: testReducer,
    simpleTester: simpleTesterReducer,
    coupon: couponReducer,
    userProfile: userProfileReducer,
    memo: memoSlice,
    myPage: myPageSlice,
    product: productSlice,
    voucher: voucherSlice,
    file: fileSlice,
    unitItem: unitItemSlice,
    orders: OrderSlice,
    payment: PaymentSlice,
    contentDetail: ContentDetailSlice,
    affiliateCode: AffiliateCodeSlice,
    partnerCompany: PartnerCompanySlice,
    popup: PopupSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
