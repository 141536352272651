import React, { useCallback, useEffect, useState } from "react";
import style from "../loginFail.module.scss";
import { useNavigate } from "react-router-dom";
import { useInterval } from "../../../hooks/auth/useInterval";
import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { accountStatus, resendTemporaryPasswordAsync } from "../../../store/accountSlice";
import queryString from "query-string";
import { useLoader } from "../../../hooks/common/useLoader";
import { PrimaryButton, PrimaryOutlineButton } from "../../../components/Buttons";
import { RemainingSeconds } from "../components/RemainingSeconds";
import { FeedGoodLogoImage } from "../../../components/Images";
import { AdminType } from "../../../types/account";
import { Trans, useTranslation } from "react-i18next";
import { StrongTextStyle } from "../../../components/Texts";

export function LockAccount() {
  const { t } = useTranslation("account");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const status = useAppSelector(accountStatus);
  useLoader({ status });

  const [startDateTime, setStartDateTime] = useState(new Date(Date.now()));
  const [remainingTime, setRemainingTime] = useState(30);
  const { email, adminType } = queryString.parse(window.location.search, {});

  const goBack = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    if (!email) goBack();
  }, [email, goBack]);

  const regenerateTemporaryPassword = useCallback(() => {
    if (remainingTime > 1) {
      alert(t("lockedAccount.waitForSec", { sec: remainingTime }));
      return;
    }

    callAsync(
      dispatch(
        resendTemporaryPasswordAsync({
          email: email as string,
          adminType: adminType as AdminType,
        })
      ).unwrap(),
      () => {
        setStartDateTime(new Date(Date.now()));
        alert(t("lockedAccount.resultMessage"));
      },
      () => {}
    ).then();
  }, [remainingTime, email, dispatch, t, adminType]);

  const updateDateTime = useCallback(() => {
    setRemainingTime(Math.round(30 - (new Date(Date.now()).getTime() - startDateTime.getTime()) / 1000));
  }, [startDateTime]);

  useInterval(updateDateTime, 1000);
  return (
    <div className={style.lockAccountWrapper}>
      <FeedGoodLogoImage />

      <p className={style.message}>
        <Trans t={t} i18nKey={"lockedAccount.message1.value"} components={[<StrongTextStyle></StrongTextStyle>]} />
      </p>

      <p className={style.description}>
        {t("lockedAccount.message2")}
        <br />
        {t("lockedAccount.message3")}
      </p>

      <div className={style.buttonsWrap}>
        <div className={style.recreateWrap}>
          <PrimaryButton onClick={regenerateTemporaryPassword}>{t("lockedAccount.reissuePWButton")}</PrimaryButton>
          <RemainingSeconds remainSeconds={remainingTime} />
        </div>

        <PrimaryOutlineButton onClick={goBack}>{t("lockedAccount.goBackButton")}</PrimaryOutlineButton>
      </div>
    </div>
  );
}
