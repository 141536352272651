import { useAppDispatch } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useCallback } from "react";
import { PartnerCompanyListVO, PartnerCompanyStatus } from "../../../../types/partnerCompany";
import { updatePartnerCompanyStatusAsync } from "../../../../store/partnerCompanySlice";

interface Args {
  onCompletedCallback: () => void;
}

export function usePartnerCompaniesStatusUpdater({ onCompletedCallback }: Args) {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const updatePartnerCompaniesStatus = useCallback(
    async (partners: PartnerCompanyListVO[], toStatus: PartnerCompanyStatus, isButtonOnList: boolean) => {
      if (partners.length === 0) {
        alert("협력사를 선택해주세요.");
        return;
      }

      if (toStatus === PartnerCompanyStatus.EXPOSED && partners.some((vo) => vo.numericOrder === undefined)) {
        alert("순번을 저장한 후 상태를 변경해주세요.");
        return;
      }

      if (
        !window.confirm(
          `${isButtonOnList ? "" : "선택한 "}협력사를 ${
            toStatus === PartnerCompanyStatus.EXPOSED ? "노출재개" : "노출중지"
          } 하시겠습니까?`
        )
      ) {
        return;
      }

      try {
        await dispatch(
          updatePartnerCompanyStatusAsync({
            idx: partners.map((vo) => vo.idx),
            status: toStatus,
          })
        ).unwrap();
        alert(
          `${isButtonOnList ? "" : "선택한 "}협력사가 홈페이지에 ${
            toStatus === PartnerCompanyStatus.EXPOSED ? "노출" : "미노출"
          } 되었습니다.`
        );
        onCompletedCallback();
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("협력사 상태 업데이트 에러");
      }
    },
    [defaultErrorMessage, dispatch, onCompletedCallback]
  );

  return {
    updatePartnerCompaniesStatus,
  };
}
