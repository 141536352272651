import style from "../hooks/partner/partnerCompany.module.scss";
import { FilterContentType } from "../../../types/tableFilter";
import { FilterNumber } from "../../../layout/content/list/filter/FilterNumber";
import React, { useEffect, useState } from "react";
import { PartnerCompanyListVO, PartnerCompanyOrderingNumberUpdateVO } from "../../../types/partnerCompany";

interface Props {
  vo: PartnerCompanyListVO;
  setOrderingNumber: (_: PartnerCompanyOrderingNumberUpdateVO) => void;
}

export function PartnerCompanyOrderingNumber({ vo, setOrderingNumber }: Props) {
  const [number, setNumber] = useState<string | undefined>(undefined);

  useEffect(() => {
    setNumber(vo.numericOrder ? String(vo.numericOrder) : undefined);
  }, [vo.numericOrder]);

  return (
    <FilterNumber
      className={style.width60}
      numberField={{
        type: FilterContentType.NUMBER,
        value: number ?? "",
        min: 0,
        isRequired: true,
        placeholderVal: "숫자 입력",
        onChangeFunc: (v) => {
          setNumber(v);
          setOrderingNumber({
            idx: vo.idx,
            orderingNumber: v ? Number(v) : undefined,
          });
        },
      }}
    />
  );
}
