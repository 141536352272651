import { FilterNumber } from "../../../layout/content/list/filter/FilterNumber";
import { FilterContentType } from "../../../types/tableFilter";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  conditionAmount: string;
  setConditionAmount: (_: string) => void;
}

export function AvailableConditionAmountField({ conditionAmount, setConditionAmount }: Props) {
  const { t } = useTranslation(["coupon", "common"]);
  return (
    <>
      <Trans
        t={t}
        i18nKey={"creator.amount.aboveAmount2"}
        components={[
          <FilterNumber
            numberField={{
              type: FilterContentType.NUMBER,
              value: conditionAmount,
              onChangeFunc: (v) => setConditionAmount(v),
              placeholderVal: t("inputNumberPlaceholder", { ns: "common" }),
              isRequired: true,
            }}
          />,
        ]}
      />
    </>
  );
}
