import { useCallback } from "react";
import { callAsync } from "../../../util/sliceUtil";
import { useAppDispatch } from "../../../hooks/hooks";
import { PaymentDTO, PaymentVO } from "../../../types/payment";
import { getPaymentAsync } from "../../../store/paymentSlice";
import { useUtil } from "../../../util/hooks/useUtil";

export default function usePaymentGetter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  return useCallback(
    (orderIdx: number, onSuccess: (paymentVO: PaymentVO) => void) => {
      callAsync(
        dispatch(getPaymentAsync(orderIdx)).unwrap(),
        (paymentDTO: PaymentDTO) => {
          onSuccess({
            idx: paymentDTO.idx,
            orderIdx: paymentDTO.orderIdx,
            name: paymentDTO.name,
            email: paymentDTO.email,
            phoneNumber: paymentDTO.phoneNumber,
            paymentWay: paymentDTO.paymentWay,
            tid: paymentDTO.tid,
            cardType: paymentDTO.cardType,
            cardCorp: paymentDTO.cardCorp,
            approvedAt: paymentDTO.approvedAt,
            vBankName: paymentDTO.vbankName,
            vBankAccountNumber: paymentDTO.vbankAccountNumber,
            vBankDepositDeadline: paymentDTO.vbankDepositDeadline,
            receiptType: paymentDTO.receiptType,
            receiptNumber: paymentDTO.receiptNumber,
          });
        },
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );
}
