import {DisplayPosition, ProductStatus} from "../../../types/product";
import {FilterContentType, TableFilter} from "../../../types/tableFilter";
import {useLanguage, useListFilterValues, useTimeConverter} from "../../../hooks/hooks";
import {useTranslation} from "react-i18next";
import {useProductType} from "../../../types/hooks/useProductType";
import {useMemo} from "react";
import {Languages} from "../../../types/common";

interface Args {
  search: () => void;
}
export function useProductFilters({ search }: Args): {
  filters: TableFilter[][];
} {
  const { filterValues } = useListFilterValues();
  const { t } = useTranslation("product");
  const { timeConverter } = useTimeConverter();
  const { productStatusOptions, displayPositionOptions } = useProductType();
  const displayOptions = useMemo(
    () => [{ label: t("list.filter.all"), value: "" }, ...displayPositionOptions],
    [t, displayPositionOptions]
  );
  const { isKorean } = useLanguage();

  const searchWordFilter = useMemo(() => filterValues.searchWordFilterValue, [filterValues.searchWordFilterValue]);
  const displayPositionFilter = useMemo(
    () => filterValues.displayPositionFilterValue,
    [filterValues.displayPositionFilterValue]
  );
  const languageFilter = useMemo(
      () => filterValues.languageFilterValue,
      [filterValues.languageFilterValue]
  );
  const statusFilter = useMemo(() => filterValues.statusFilterValue, [filterValues.statusFilterValue]);
  const isCouponUsableFilter = useMemo(
    () => filterValues.isCouponUsableFilterValue,
    [filterValues.isCouponUsableFilterValue]
  );
  const sellingStartDateFilter = useMemo(
    () => filterValues.sellingStartDateFilterValue,
    [filterValues.sellingStartDateFilterValue]
  );
  const sellingEndDateFilter = useMemo(
    () => filterValues.sellingEndDateFilterValue,
    [filterValues.sellingEndDateFilterValue]
  );
  const createdAtFromFilter = useMemo(
    () => filterValues.createdAtFromFilterValue,
    [filterValues.createdAtFromFilterValue]
  );
  const createdAtToFilter = useMemo(() => filterValues.createdAtToFilterValue, [filterValues.createdAtToFilterValue]);

  const filters: TableFilter[][] = [
    [
      {
        labelTitle: t("list.filter.search.label"),
        content: {
          type: FilterContentType.TEXT,
          value: searchWordFilter.value,
          maxLength: 100,
          placeholderVal: t("list.filter.search.placeholder"),
          onChangeFunc: (v) => searchWordFilter.setter(v),
          onEnterKeyUpFunc: search,
        },
      },
    ],
    [
      {
        labelTitle: t("edit.displayPosition.title"),
        content: {
          type: FilterContentType.SELECT,
          value: displayPositionFilter.value,
          options: displayOptions,
          onChangeFunc: (v) => displayPositionFilter.setter(v as DisplayPosition | ""),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("language.title"),
        content: {
          type: FilterContentType.SELECT,
          value: languageFilter.value,
          options:
              [{
              label: t("list.filter.all"),
              value: "",
            },
            {
              label: t("language.korean"),
              value: Languages.KO,
            },
            {
              label: t("language.english"),
              value: Languages.EN,
            },
            {
              label: t("language.taiwanese"),
              value: Languages.ZH,
            },
            {
              label: t("language.russian"),
              value: Languages.RU,
            },
              ],
          onChangeFunc: (v) => languageFilter.setter(v as unknown as Languages),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("edit.sellingDate.title"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(sellingStartDateFilter.value),
            onChangeFunc: (v: string) =>
              sellingStartDateFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(sellingEndDateFilter.value),
            onChangeFunc: (v: string) =>
              sellingEndDateFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
          },
        },
      },
    ],
    [
      {
        labelTitle: t("edit.couponUsable.label"),
        content: {
          type: FilterContentType.SELECT,
          value: String(isCouponUsableFilter.value),
          options: [
            {
              label: t("list.filter.all"),
              value: "",
            },
            {
              label: t("edit.couponUsable.available"),
              value: String(true),
            },
            {
              label: t("edit.couponUsable.unavailable"),
              value: String(false),
            },
          ],
          onChangeFunc: (v) => isCouponUsableFilter.setter(v),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.columns.status"),
        content: {
          type: FilterContentType.SELECT,
          value: statusFilter.value,
          options: productStatusOptions,
          onChangeFunc: (v) => statusFilter.setter(v as ProductStatus | ""),
          width: isKorean ? undefined : 200,
        },
      },
      {
        labelTitle: t("list.columns.createdAt"),
        content: {
          type: FilterContentType.CALENDAR_RANGE,
          from: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtFromFilter.value),
            onChangeFunc: (v: string) =>
              createdAtFromFilter.setter(v ? timeConverter.convertToFilterStartOfDay(v) : undefined),
          },
          to: {
            type: FilterContentType.CALENDAR,
            value: timeConverter.convertToFilterDateString(createdAtToFilter.value),
            onChangeFunc: (v: string) =>
              createdAtToFilter.setter(v ? timeConverter.convertToFilterEndOfDay(v) : undefined),
          },
        },
      },
    ],
  ];

  return {
    filters,
  };
}
