import { SliceState, Status } from "../types/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addCases, executePromise } from "../util/sliceUtil";
import { PaymentApi } from "../api/PaymentApi";

export interface PaymentState extends SliceState {}

const initialState: PaymentState = {
  status: Status.IDLE,
};

export const getPaymentAsync = createAsyncThunk("payments/getPayment", (orderIdx: number) =>
  executePromise(PaymentApi.getPayment(orderIdx))
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addCases(builder, getPaymentAsync);
  },
});

export default paymentSlice.reducer;
