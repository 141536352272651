import { SelectorOption } from "../types/selector";
import {
  OptionItem,
  SelectorHiddenInputStyle,
  UnderlineSelectAreaStyle,
  UnderlineSelectedItemStyle,
  UnderlineSelectorArrowStyle,
  UnderlineSelectorOptionsStyle,
  UnderlineSelectorWrapperStyle,
} from "./SelectorStyle";
import arrow from "../assets/images/select_arrow.svg";
import React from "react";
import { useSelector } from "../layout/content/selector/useSelector";
import { SELECTOR_AREA } from "../util/etcUtil";
import { useBlockEnter } from "../hooks/useBlockEnter";

interface Props {
  options: SelectorOption[];
  selectedValue: string;
  setSelectorOption: (_: SelectorOption) => void;
  isRequired?: boolean;
}

export function UnderlineSelector({ options, selectedValue, setSelectorOption, isRequired = false }: Props) {
  const { selectorRef, onClickSelector, onClickOption, selectedOption, showOptions } = useSelector({
    options,
    selectedValue,
    setSelectorOption,
  });
  const { blockEnterKeyEvent } = useBlockEnter();

  return (
    <UnderlineSelectorWrapperStyle>
      <UnderlineSelectAreaStyle
        data-name={SELECTOR_AREA}
        className={`${selectedOption?.value ? "" : "none"}`}
        onClick={onClickSelector}
        ref={selectorRef}
      >
        <UnderlineSelectedItemStyle className={`${selectedOption?.value ? "" : "none"}`}>
          {selectedOption?.label}
        </UnderlineSelectedItemStyle>
        <UnderlineSelectorArrowStyle>
          <img src={arrow} alt={""} />
        </UnderlineSelectorArrowStyle>
      </UnderlineSelectAreaStyle>
      <UnderlineSelectorOptionsStyle className={`${showOptions ? "" : "hide"}`}>
        {options.map((option, idx) => (
          <OptionItem
            className={"rounded"}
            key={`option-${option.value}-${idx}`}
            data-value={option.value}
            onClick={() => onClickOption(option)}
          >
            {option.label}
          </OptionItem>
        ))}
      </UnderlineSelectorOptionsStyle>
      <SelectorHiddenInputStyle
        required={isRequired}
        value={selectedOption?.value ?? ""}
        onChange={() => {}}
        onKeyDown={blockEnterKeyEvent}
      />
    </UnderlineSelectorWrapperStyle>
  );
}
