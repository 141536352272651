import {useAppDispatch, useTimeConverter} from "../../../../hooks/hooks";
import {useFile} from "../../../../hooks/file/useFile";
import {useCallback} from "react";
import {BannerPostVO} from "../../../../types/banner";
import {FileClassification, FileResponse} from "../../../../types/file";
import {createBannerAsync, setIsBannerDataSaved} from "../../../../store/bannerSlice";

export function useBannerCreator() {
    const dispatch = useAppDispatch();
    const {timeConverter} = useTimeConverter();
    const {uploadImages} = useFile();



    const createBanner = useCallback(
        async ({ name, type, bannerLocation, availableStartAt, availableEndAt,
                   description1, description2, buttonDescription, buttonLink, image, linkOption, status, websiteType }
                   : BannerPostVO) => {
            try {
                const files: FileResponse[] = await uploadImages([image.file], FileClassification.BANNER_IMAGE);
                await dispatch(
                    createBannerAsync({
                        name: name.trim(),
                        type,
                        location : bannerLocation,
                        image: {
                            uri: files[0].uri,
                            fileIdx: files[0].idx,
                        },
                        description1,
                        description2,
                        buttonDescription, buttonLink,
                        linkOption,
                        availableStartAt: timeConverter.convertStartOfDayToUTC(availableStartAt),
                        availableEndAt: availableEndAt ? timeConverter.convertEndOfDayToUTC(availableEndAt) : undefined,
                        status,
                        websiteType,
                    })
                ).unwrap();
                dispatch(setIsBannerDataSaved(true));
            } catch (e) {
                console.error(e);
                throw new Error("배너 등록 에러");
            }
        },
        [dispatch, uploadImages, timeConverter]
    );

    return {
        createBanner
    };
}