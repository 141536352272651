import { useCallback, useMemo, useState } from "react";
import { TestQueryType } from "../../../types/test";
import { Gender } from "../../../types/common";
import { ListQueryType } from "../../../types/list";
import { FilterType, FilterValue } from "../../../types/filter";
import { PageSearchCondition } from "../../../types/page";
import { useFilterValues } from "../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../hooks/list/useListSearchParams";

export interface TestListQueryType extends ListQueryType {
  searchWord?: string;
  searchWordType?: string;
  startDate?: string;
  endDate?: string;
  minTestCnt?: string;
  maxTestCnt?: string;
  minAge?: string;
  maxAge?: string;
  gender?: string;
}

export interface TestFilterValues {
  searchWordFilterValue: FilterValue<string>;
  searchWordTypeFilterValue: FilterValue<TestQueryType>;
  startDateFilterValue: FilterValue<Date | undefined>;
  endDateFilterValue: FilterValue<Date | undefined>;
  minTestCntFilterValue: FilterValue<string>;
  maxTestCntFilterValue: FilterValue<string>;
  minAgeFilterValue: FilterValue<string>;
  maxAgeFilterValue: FilterValue<string>;
  genderFilterValue: FilterValue<Gender | "">;
}

export function useTestFilterValues() {
  const [searchWord, setSearchWord] = useState("");
  const [searchWordType, setSearchWordType] = useState<TestQueryType>(TestQueryType.USER_EMAIL);
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [minTestCnt, setMinTestCnt] = useState("");
  const [maxTestCnt, setMaxTestCnt] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [gender, setGender] = useState<Gender | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<TestListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "searchWordType",
        value: searchWordType,
        setter: setSearchWordType,
        defaultValue: TestQueryType.USER_EMAIL,
        type: FilterType.OTHER,
      },
      { key: "startDate", value: startDate, setter: setStartDate, defaultValue: undefined, type: FilterType.DATE },
      { key: "endDate", value: endDate, setter: setEndDate, defaultValue: undefined, type: FilterType.DATE },
      { key: "minTestCnt", value: minTestCnt, setter: setMinTestCnt, defaultValue: "", type: FilterType.STRING },
      { key: "maxTestCnt", value: maxTestCnt, setter: setMaxTestCnt, defaultValue: "", type: FilterType.STRING },
      { key: "minAge", value: minAge, setter: setMinAge, defaultValue: "", type: FilterType.STRING },
      { key: "maxAge", value: maxAge, setter: setMaxAge, defaultValue: "", type: FilterType.STRING },
      { key: "gender", value: gender, setter: setGender, defaultValue: "", type: FilterType.OTHER },
    ],
    [searchWord, searchWordType, startDate, endDate, minTestCnt, maxTestCnt, minAge, maxAge, gender]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<TestQueryType>,
        startDateFilterValue: getFilterValueByKey("startDate") as FilterValue<Date | undefined>,
        endDateFilterValue: getFilterValueByKey("endDate") as FilterValue<Date | undefined>,
        minTestCntFilterValue: getFilterValueByKey("minTestCnt") as FilterValue<string>,
        maxTestCntFilterValue: getFilterValueByKey("maxTestCnt") as FilterValue<string>,
        minAgeFilterValue: getFilterValueByKey("minAge") as FilterValue<string>,
        maxAgeFilterValue: getFilterValueByKey("maxAge") as FilterValue<string>,
        genderFilterValue: getFilterValueByKey("gender") as FilterValue<Gender | "">,
      } as TestFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: TestListQueryType) => {
      const param: TestListQueryType = {
        searchWord: listQueryTypeParam?.searchWord,
        searchWordType: listQueryTypeParam?.searchWordType,
        startDate: listQueryTypeParam?.startDate,
        endDate: listQueryTypeParam?.endDate,
        minTestCnt: listQueryTypeParam?.minTestCnt,
        maxTestCnt: listQueryTypeParam?.maxTestCnt,
        minAge: listQueryTypeParam?.minAge,
        maxAge: listQueryTypeParam?.maxAge,
        gender: listQueryTypeParam?.gender,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
