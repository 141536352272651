enum ActionUserType {
  USER = "USER",
  ADMIN = "ADMIN",
  ORGAN_MANAGER = "ORGAN_MANAGER",
  EXPERT = "EXPERT",
}

interface ActionUser {
  idx: number;
  name: string;
  email: string;
  userType: ActionUserType;
  imgUri: string;
  history: string[];
}

export type { ActionUser };
export { ActionUserType };
