import { MainApi } from "./MainApi";
import { FileClassification } from "../types/file";

export class FileApi {
  static baseUrl = `${MainApi.urlPrefix}/file`;

  static uploadImageFiles = (formData: FormData, fileClassification: FileClassification) => () =>
    MainApi.api.post(`${FileApi.baseUrl}/image?classification=${fileClassification}`, formData);

  static deleteImageFile = (fileIdx: number, fileClassification: FileClassification) => () =>
    MainApi.api.delete(`${FileApi.baseUrl}/image/${fileIdx}?classification=${fileClassification}`);
}
