import {Panel} from "../../../../../../layout/content/detail/Panel";
import {DescriptionValue} from "../../../../../../layout/content/detail/DescriptionList";
import React, {useMemo, useRef, useState} from "react";
import {useAppSelector, useLanguage} from "../../../../../../hooks/hooks";
import {organManagerVoucherStatusDTO} from "../../../../../../store/voucherSlice";
import style from "../../../../user/memberUserCoupons.module.scss";
import {MINI_MODAL_LINK} from "../../../../../../util/etcUtil";
import questionIcon from "../../../../../../assets/images/question_icon.svg";
import {GrantedVoucherHelpModal} from "../../../../user/coupon_and_voucher/voucher/GrantedVoucherHelpModal";
import {useTranslation} from "react-i18next";
import KPASS_LOGO from "../../../../../../assets/images/K-PASS_logo_in_tester.svg";
import DCSS_LOGO from "../../../../../../assets/images/D-CAS_logo_in_tester.svg";
import {VoucherList} from "../../../../../../layout/content/detail/VoucherList";

export function OrganManagerVoucherStatus() {
  const { t } = useTranslation("organManager");
  const voucherStatus = useAppSelector(organManagerVoucherStatusDTO);
  const linkRef = useRef<HTMLButtonElement | null>(null);
  const { isKorean } = useLanguage();
  const FreeVoucherHeader = () => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className={style.freeVoucherInfo}>
          {t("detail.couponAndVoucher.organVoucherStatus.grantedVoucher.label")}
          <div className={style.helpButtonWrapper}>
            <button
                className={style.helpButton}
                onClick={() => setShowModal(true)}
                ref={linkRef}
                data-name={MINI_MODAL_LINK}
            >
              <img
                  src={questionIcon}
                  alt={t("detail.couponAndVoucher.organVoucherStatus.grantedVoucher.supportIcon")}
              />
            </button>
            <GrantedVoucherHelpModal
                show={showModal}
                closeModal={() => setShowModal(false)}
                linkElement={linkRef.current ?? undefined}
            />
          </div>
        </div>
    );
  };

  const organVoucherList = (
      logo: string, voucherTypeNumber: number
  ) => [
    {
      key: t(""),
      value: <img className={style.logoImg} src={logo} alt={logo}/>,
    },
    {
      key: t("detail.couponAndVoucher.organVoucherStatus.paidVoucher.label"),
      value: t("detail.couponAndVoucher.organVoucherStatus.paidVoucher.unit", {
        count:
            voucherStatus?.voucherDTOs[voucherTypeNumber]?.purchasedVoucherCount ?? 0,
      }),
    },
    {
      key: <FreeVoucherHeader />,
      value: t("detail.couponAndVoucher.organVoucherStatus.paidVoucher.unit", {
        count: voucherStatus?.voucherDTOs[voucherTypeNumber]?.receivedVoucherCount ?? 0,
      }),
    },
    {
      key: t("detail.couponAndVoucher.organVoucherStatus.grantableVoucher.label"),
      value: t("detail.couponAndVoucher.organVoucherStatus.grantableVoucher.unit", {
        count: voucherStatus?.voucherDTOs[voucherTypeNumber]?.grantableProductVoucherCount ?? 0,
      }),
    },
    {
      key: t("detail.couponAndVoucher.organVoucherStatus.grantCompletedVoucher.label"),
      value: t("detail.couponAndVoucher.organVoucherStatus.grantCompletedVoucher.unit", {
        count: voucherStatus?.voucherDTOs[voucherTypeNumber]?.grantedProductVoucherCount ?? 0,
      }),
    },
  ];

  const kpassVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [organVoucherList(KPASS_LOGO, 0)];
    //eslint-disable-next-line
  }, [t, organVoucherList]);

  const dcasVoucherStatus: DescriptionValue[][] = useMemo(() => {
    return [organVoucherList(DCSS_LOGO, 1)];
    //eslint-disable-next-line
  }, [t, organVoucherList]);

  const voucherStatusList: DescriptionValue[][][] = useMemo(() => {
    return [kpassVoucherStatus, dcasVoucherStatus];
  }, [kpassVoucherStatus, dcasVoucherStatus]);

  return (
      <Panel title={t("detail.couponAndVoucher.organVoucherStatus.title")}>
        {voucherStatusList.map((status, index) => (
            <VoucherList
                key={index}
                data={status}
                labelWidth={isKorean ? 130 : [133, 114, 132, 132]}
                valueWidth={isKorean ? 100 : 80}
                valueClassName={style.statusCell}
            />
        ))}
      </Panel>
  );
}
