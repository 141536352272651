import { HttpClient } from "./HttpClient";
import { SearchCondition } from "../types/page";
import { camelToSnakeCase } from "../util/etcUtil";
import { TimeConverter } from "../util/timeConverter";

export class MainApi extends HttpClient {
  private static classInstance?: MainApi;

  public static api = MainApi.getInstance().instance;
  public static urlPrefix = "/api/v1/kpass/admin";
  public static dcasUrlPrefix = "/api/v1/dcas/admin";
  public static dcasSimpleUrlPrefix = "/api/v1/dcas-simple/admin";

  private constructor() {
    super(process.env.REACT_APP_BASE_URL as string);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MainApi();
    }

    return this.classInstance;
  }

  public setToken(token: string) {
    this.instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  public static toParamStringFromObject<T>(param: T) {
    const keyArr = Object.keys(param);
    if (keyArr.length > 0) {
      const paramStr = keyArr.reduce((acc, k) => {
        const value = param[k as keyof T];

        if (typeof value === "function") return acc;
        else {
          if (k === "pageCondition") {
            const pageParam = (param as SearchCondition)[k];
            if (pageParam?.page || pageParam?.page === 0) {
              acc += `&page=${pageParam.page}`;
            }

            if (pageParam?.size) {
              acc += `&size=${pageParam.size}`;
            }

            if (pageParam?.sort) {
              const sortString = pageParam.sort.map((s) => `&sort=${s.columnName},${s.direction}`).join("");
              acc += sortString;
            }

            return acc;
          } else {
            if (k && value !== undefined && value !== null && value instanceof Date) {
              return (
                acc +
                `&${camelToSnakeCase(k)}=${encodeURIComponent(TimeConverter.getInstance().convertToUTCFromDate(value))}`
              );
            }
            if (k && value !== undefined && value !== null && (value as unknown as string) !== "") {
              return acc + `&${camelToSnakeCase(k)}=${encodeURIComponent(String(value))}`;
            } else {
              return acc;
            }
          }
        }
      }, "");

      return `?${paramStr.substring(1)}`;
    }

    return "";
  }
}
