import { TimeConverter } from "../../util/timeConverter";
import React from "react";

export interface TimeConverterContextType {
  timeConverter: TimeConverter;
  timezone: string;
  setTimezone: (_: string) => void;
}

export const TimeConverterContext = React.createContext<TimeConverterContextType>({
  timeConverter: TimeConverter.getInstance(),
  timezone: "",
  setTimezone: () => {},
});
