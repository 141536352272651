import {
  CalculatePanelStyle,
  CalculatePanelTitleStyle,
  StatisticsPanelContentWrapperStyle,
  StatisticsPanelItemStyle,
  StatisticsPanelItemTitleStyle,
  StatisticsPanelItemWrapperStyle,
} from "./SettlementStyle";
import { StrongTextStyle } from "../../../components/Texts";
import React, { MutableRefObject, useCallback, useEffect, useState } from "react";
import { MonthlyAffiliateCodeStatisticsVO } from "../../../types/affiliateCode";
import { useUtil } from "../../../util/hooks/useUtil";
import { useAffiliateCodeSettlementStaticsGetter } from "./hooks/useAffiliateCodeSettlementStaticsGetter";
import { numberWithCommas } from "../../../util/etcUtil";
import { useListFilterValues } from "../../../hooks/hooks";
import { AffiliateCodeSettlementListFilterValues } from "./hooks/useAffiliateCodeSettlementFilterValues";

interface Props {
  refreshRef: MutableRefObject<(y: string, m: string) => void>;
}
export function SettlementStatistics({ refreshRef }: Props) {
  const { filterValues } = useListFilterValues();
  const [statics, setStatics] = useState<MonthlyAffiliateCodeStatisticsVO>();

  const { defaultErrorMessage } = useUtil();
  const { getAffiliateCodeSettlementStatics } = useAffiliateCodeSettlementStaticsGetter();
  const { yearFilterValue, monthFilterValue } = filterValues as AffiliateCodeSettlementListFilterValues;

  const refresh = useCallback(
    (y: string, m: string) => {
      getAffiliateCodeSettlementStatics(Number(y), Number(m))
        .then((result) => setStatics(result))
        .catch(() => alert(defaultErrorMessage));
    },
    [getAffiliateCodeSettlementStatics, defaultErrorMessage]
  );

  useEffect(() => {
    refreshRef.current = refresh;
  }, [refresh, refreshRef]);

  useEffect(() => {
    if (Boolean(yearFilterValue.value && monthFilterValue.value)) {
      refresh(yearFilterValue.value, monthFilterValue.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearFilterValue.value, monthFilterValue.value]);

  return (
    <CalculatePanelStyle>
      <CalculatePanelTitleStyle>월별 제휴코드 통계</CalculatePanelTitleStyle>
      <StatisticsPanelContentWrapperStyle>
        <StatisticsPanelItemWrapperStyle>
          <StatisticsPanelItemTitleStyle>정산 완료 회원</StatisticsPanelItemTitleStyle>
          <StatisticsPanelItemStyle className={"completed"}>
            <StrongTextStyle>{statics?.completeCount ?? 0}</StrongTextStyle>
            <span>명</span>
          </StatisticsPanelItemStyle>
        </StatisticsPanelItemWrapperStyle>
        <StatisticsPanelItemWrapperStyle>
          <StatisticsPanelItemTitleStyle>정산 미완료 회원</StatisticsPanelItemTitleStyle>
          <StatisticsPanelItemStyle className={"uncompleted"}>
            <StrongTextStyle>{statics?.incompleteCount ?? 0}</StrongTextStyle>
            <span>명</span>
          </StatisticsPanelItemStyle>
        </StatisticsPanelItemWrapperStyle>
        <StatisticsPanelItemWrapperStyle>
          <StatisticsPanelItemTitleStyle>총 제휴결제건수</StatisticsPanelItemTitleStyle>
          <StatisticsPanelItemStyle>
            <StrongTextStyle>{statics?.totalAffiliatePaidCount ?? 0}</StrongTextStyle>
            <span>건</span>
          </StatisticsPanelItemStyle>
        </StatisticsPanelItemWrapperStyle>
        <StatisticsPanelItemWrapperStyle className={"total"}>
          <StatisticsPanelItemTitleStyle>총 정산금액</StatisticsPanelItemTitleStyle>
          <StatisticsPanelItemStyle className={"totalAmount"}>
            <StrongTextStyle>{numberWithCommas(statics?.totalSettlementValue ?? 0)}</StrongTextStyle>
            <span>원</span>
          </StatisticsPanelItemStyle>
        </StatisticsPanelItemWrapperStyle>
      </StatisticsPanelContentWrapperStyle>
    </CalculatePanelStyle>
  );
}
