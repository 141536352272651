import queryString from "query-string";
import { useParams } from "react-router-dom";

export function SampleContentDetail() {
  const { id } = useParams<"id">();
  const { otherParam } = queryString.parse(window.location.search);

  return (
    <>
      샘플 페이지 입니다.
      <br />
      (idx: {id}, otherParam: {otherParam})
    </>
  );
}
