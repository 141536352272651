import { useAppDispatch, useListFilterValues, useNationality, useTimeConverter } from "../../../hooks/hooks";
import { useEffect, useMemo, useState } from "react";
import { ordersToSortArr, PageResponse, PageSearchCondition } from "../../../types/page";
import { CouponListObj, CouponSearchCondition, CouponStatus, CouponType } from "../../../types/coupon";
import { getCouponListAsync } from "../../../store/couponSlice";
import { hasBothValuesOrBothNoValue } from "../../../util/etcUtil";
import { CouponListQueryType } from "./useCouponFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../util/hooks/useUtil";
import { useSortParam } from "../../../hooks/list/useSortParam";
import { dateToString } from "../../../util/dateUtil";

interface Args {
  onCompletedCallback?: () => void;
}

export function useCouponList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("coupon");
  const dispatch = useAppDispatch();

  const { timeConverter } = useTimeConverter();
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const [couponList, setCouponList] = useState<PageResponse<CouponListObj>>();
  const [totalCnt, setTotalCnt] = useState(0);
  const { defaultErrorMessage } = useUtil();
  const { toSortArray } = useSortParam();
  const {
    searchWordFilterValue,
    createdAtFromFilterValue,
    createdAtToFilterValue,
    couponTypeFilterValue,
    couponStatusFilterValue,
  } = filterValues;
  const { nationality } = useNationality();

  const pageCondition = useMemo(
    () => ({
      page: couponList?.number ?? 0,
      size: couponList?.size ?? 20,
      sort: ordersToSortArr(couponList?.orders),
    }),
    [couponList]
  );

  const searchCouponList = (pageSearchCondition: PageSearchCondition) => {
    const param: CouponListQueryType = {
      searchWord: searchWordFilterValue.value?.trim(),
      createdAtFrom: createdAtFromFilterValue.value ? dateToString(createdAtFromFilterValue.value) : undefined,
      createdAtTo: createdAtToFilterValue.value ? dateToString(createdAtToFilterValue.value) : undefined,
      couponType: couponTypeFilterValue.value,
      couponStatus: couponStatusFilterValue.value,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.createdAtFrom, param.createdAtTo)) {
        alert(t("list.validations.bothCreatedAtNeeded"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getCouponList = async (param: CouponSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<CouponListObj> = await dispatch(getCouponListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalCnt(result.totalElements);
      } else {
        setCouponList(result);
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  useEffect(() => {
    getCouponList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      const couponListQueryType = listQueryType as CouponListQueryType;
      const param: CouponSearchCondition = {
        pageCondition: {
          page: couponListQueryType.page ? Number(couponListQueryType.page) : 0,
          size: couponListQueryType.size ? Number(couponListQueryType.size) : 20,
          sort: couponListQueryType.sort ? toSortArray(couponListQueryType.sort) : undefined,
        },
        q: couponListQueryType.searchWord?.trim(),
        type: couponListQueryType.couponType ? (couponListQueryType.couponType as CouponType) : undefined,
        status: couponListQueryType.couponStatus ? (couponListQueryType.couponStatus as CouponStatus) : undefined,
        createdAtFrom: couponListQueryType.createdAtFrom
          ? timeConverter.convertToFilterStartOfDay(couponListQueryType.createdAtFrom)
          : undefined,
        createdAtTo: couponListQueryType.createdAtTo
          ? timeConverter.convertToFilterEndOfDay(couponListQueryType.createdAtTo)
          : undefined,
      };

      getCouponList(param).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchCouponList,
    totalCnt,
    couponList,
    pageCondition,
  };
}
