import { FilterCalendarRange } from "../../../layout/content/list/filter/FilterCalendarRange";
import { FilterContentType } from "../../../types/tableFilter";
import creatorStyle from "../../../layout/content/post/creator.module.scss";
import React from "react";

interface Props {
  startAt: string;
  setStartAt: (_: string) => void;
  endAt: string;
  setEndAt: (_: string) => void;
  isAvailableAlways: boolean;
  setIsAvailableAlways: (_: boolean) => void;
}
export function PopupDurationField({
  startAt,
  setStartAt,
  endAt,
  setEndAt,
  isAvailableAlways,
  setIsAvailableAlways,
}: Props) {
  return (
    <>
      <FilterCalendarRange
        from={{
          type: FilterContentType.CALENDAR,
          value: startAt,
          onChangeFunc: setStartAt,
          isRequired: true,
          isDisabled: false,
        }}
        to={{
          type: FilterContentType.CALENDAR,
          value: endAt,
          onChangeFunc: setEndAt,
          isRequired: false,
          isDisabled: isAvailableAlways,
        }}
      />
      <label className={creatorStyle.checkboxLabel}>
        <input
          type={"checkbox"}
          checked={isAvailableAlways}
          readOnly={true}
          onClick={() => {
            const to = !isAvailableAlways;
            if (to) {
              setEndAt("");
            }
            setIsAvailableAlways(to);
          }}
        />
        상시사용
      </label>
    </>
  );
}
