import { ProfileQueryType } from "../../../../types/userProfile";
import { UserStatus } from "../../../../types/user";
import { useCallback, useMemo, useState } from "react";
import { ListQueryType } from "../../../../types/list";
import { PageSearchCondition } from "../../../../types/page";
import { FilterType, FilterValue } from "../../../../types/filter";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface TesterListQueryType extends ListQueryType {
  searchWordType?: string;
  searchWord?: string;
  ageFrom?: string;
  ageTo?: string;
  testCntFrom?: string;
  testCntTo?: string;
  status?: string;
  nationality?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  hasOrganVoucher?: string;
}

export interface MemberTesterFilterValues {
  searchWordTypeFilterValue: FilterValue<ProfileQueryType>;
  searchWordFilterValue: FilterValue<string>;
  ageFromFilterValue: FilterValue<string>;
  ageToFilterValue: FilterValue<string>;
  testCntFromFilterValue: FilterValue<string>;
  testCntToFilterValue: FilterValue<string>;
  statusFilterValue: FilterValue<UserStatus | "">;
  nationalityFilterValue: FilterValue<string>;
  createdAtFromFilterValue: FilterValue<Date | undefined>;
  createdAtToFilterValue: FilterValue<Date | undefined>;
  hasOrganVoucherFilterValue: FilterValue<string>;
}

export function useTesterFilterValues() {
  const [searchWordType, setSearchWordType] = useState<ProfileQueryType>(ProfileQueryType.NAME);
  const [searchWord, setSearchWord] = useState("");
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();
  const [ageFrom, setAgeFrom] = useState("");
  const [ageTo, setAgeTo] = useState("");
  const [nationality, setNationality] = useState("");
  const [testCntFrom, setTestCntFrom] = useState("");
  const [testCntTo, setTestCntTo] = useState("");
  const [status, setStatus] = useState<UserStatus | "">("");
  const [hasOrganVoucher, setHasOrganVoucher] = useState("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<TesterListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      {
        key: "searchWordType",
        value: searchWordType,
        setter: setSearchWordType,
        defaultValue: ProfileQueryType.NAME,
        type: FilterType.OTHER,
      },
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      { key: "ageFrom", value: ageFrom, setter: setAgeFrom, defaultValue: "", type: FilterType.STRING },
      { key: "ageTo", value: ageTo, setter: setAgeTo, defaultValue: "", type: FilterType.STRING },
      { key: "nationality", value: nationality, setter: setNationality, defaultValue: "", type: FilterType.STRING },
      { key: "testCntFrom", value: testCntFrom, setter: setTestCntFrom, defaultValue: "", type: FilterType.STRING },
      { key: "testCntTo", value: testCntTo, setter: setTestCntTo, defaultValue: "", type: FilterType.STRING },
      { key: "status", value: status, setter: setStatus, defaultValue: "", type: FilterType.OTHER },
      {
        key: "hasOrganVoucher",
        value: hasOrganVoucher,
        setter: setHasOrganVoucher,
        defaultValue: "",
        type: FilterType.STRING,
      },
    ],
    [
      searchWordType,
      searchWord,
      createdAtFrom,
      createdAtTo,
      ageFrom,
      ageTo,
      nationality,
      testCntFrom,
      testCntTo,
      status,
      hasOrganVoucher,
    ]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<ProfileQueryType>,
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        ageFromFilterValue: getFilterValueByKey("ageFrom") as FilterValue<string>,
        ageToFilterValue: getFilterValueByKey("ageTo") as FilterValue<string>,
        testCntFromFilterValue: getFilterValueByKey("testCntFrom") as FilterValue<string>,
        testCntToFilterValue: getFilterValueByKey("testCntTo") as FilterValue<string>,
        statusFilterValue: getFilterValueByKey("status") as FilterValue<UserStatus | "">,
        nationalityFilterValue: getFilterValueByKey("nationality") as FilterValue<string>,
        createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
        createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
        hasOrganVoucherFilterValue: getFilterValueByKey("hasOrganVoucher") as FilterValue<string>,
      } as MemberTesterFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: TesterListQueryType) => {
      const param: TesterListQueryType = {
        searchWordType: listQueryTypeParam?.searchWordType,
        searchWord: listQueryTypeParam?.searchWord,
        ageFrom: listQueryTypeParam?.ageFrom,
        ageTo: listQueryTypeParam?.ageTo,
        testCntFrom: listQueryTypeParam?.testCntFrom,
        testCntTo: listQueryTypeParam?.testCntTo,
        status: listQueryTypeParam?.status,
        nationality: listQueryTypeParam?.nationality,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
        hasOrganVoucher: listQueryTypeParam?.hasOrganVoucher,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
