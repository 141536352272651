import style from "../productItemModal.module.scss";
import {SearchField} from "../../../../layout/content/detail/SearchField";
import React, {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useCountry} from "../../../../hooks/hooks";
import {OrganMemberPhoneSelector} from "../../../../components/OrganMemberPhoneSelector";
import {useOrgansGetter} from "../../../member/organmanager/detail/organ/hooks/useOrgansGetter";
import {OrganManagerCellphoneValidStatus} from "../../../../types/organ";
import {filterAllowedCountriesNumber} from "../../../../util/etcUtil";

interface Props {
    cellphone: string;
    setCellphone: (_: string) => void;
    phoneCountryCode: string;
    setPhoneCountryCode: (_: string) => void;
    setCellphoneValid: (_: OrganManagerCellphoneValidStatus) => void;
    cellphoneValid: OrganManagerCellphoneValidStatus;
}

export function OrganManagerCellphoneField(
    {cellphone, setCellphone, phoneCountryCode, setPhoneCountryCode, cellphoneValid, setCellphoneValid}
        : Props) {
    const {t: tOrganManager} = useTranslation("organManager");
    const {t: tCommon} = useTranslation("common");
    const {phoneNumberOptions} = useCountry();
    const foreignRegex = /^[0-9]*$/;
    const isCellphoneCodeKorean = phoneCountryCode === '+82';
    const {checkCellphone} = useOrgansGetter(0);

    const phoneNumberOption = useMemo(
        () => [{value: "", label: tCommon("selectCountry")}, ...phoneNumberOptions],
        [phoneNumberOptions, tCommon]
    );
    const filteredOptions = filterAllowedCountriesNumber(
        {countries:phoneNumberOption,withEmpty:false}
    );

    function handleCellphoneChange(e: string, setCellphone: (value: string) => void){
        const inputValue = e;
        const isValidInput = foreignRegex.test(inputValue);
        if (isValidInput) {
            setCellphoneValid(OrganManagerCellphoneValidStatus.NONE);
            setCellphone(inputValue)
        }
    }


    const checkWhetherDuplicatedCellPhoneNumberIs = useCallback(
        async (e: React.MouseEvent) => {
            e.preventDefault();

            try {
                // 국내 휴대폰번호 자릿수 유효성 검사
                if (isCellphoneCodeKorean && cellphone.length < 11) {
                    setCellphoneValid(OrganManagerCellphoneValidStatus.INNER_LENGTH);
                    return;
                }
                // 국외 휴대폰번호 자릿수 유효성 검사
                if (!isCellphoneCodeKorean && cellphone.length < 5) {
                    setCellphoneValid(OrganManagerCellphoneValidStatus.OUTER_LENGTH);
                    return;
                }
                const result = await checkCellphone(cellphone, phoneCountryCode);
                const status = !result.result
                    ? OrganManagerCellphoneValidStatus.VALID
                    : OrganManagerCellphoneValidStatus.DUPLICATED
                setCellphoneValid(status)
            } catch (error) {
                setCellphoneValid(OrganManagerCellphoneValidStatus.INNER_LENGTH);
                console.error('Error occurred while checking email:', error);
            }

            if (!cellphone) {
                return;
            }
        },
        //eslint-disable-next-line
        [cellphone, phoneCountryCode]
    );

    const cellphoneValidationMessage = () => {
        switch (cellphoneValid) {
            case OrganManagerCellphoneValidStatus.UNCHECKED:
                return <div className={style.errorMessage}>
                    {tOrganManager("registration.validator.unCheckedId")}</div>;
            case OrganManagerCellphoneValidStatus.DUPLICATED:
                return <div className={style.errorMessage}>
                    {tOrganManager("registration.validator.duplicatedPhoneNumber")}</div>;
            case OrganManagerCellphoneValidStatus.INNER_LENGTH:
                return <div className={style.errorMessage}>
                    {tOrganManager("registration.validator.shortLengthInnerPhoneNumber")}</div>;
            case OrganManagerCellphoneValidStatus.OUTER_LENGTH:
                return <div className={style.errorMessage}>
                    {tOrganManager("registration.validator.shortLengthOuterPhoneNumber")}</div>;
            default:
                return <div className={style.correctMessage}>{tOrganManager("registration.validator.valid")}</div>;
        }
    };


    return (
        <div className={style.fieldLine}>
            <div className={style.field}>
                <OrganMemberPhoneSelector
                    options={filteredOptions}
                    selectedValue={phoneCountryCode}
                    setSelectorOption={(it) => {
                        setPhoneCountryCode(it.value as string);
                        setCellphoneValid(OrganManagerCellphoneValidStatus.NONE);
                        setCellphone('');
                    }}
                />
            </div>
            <div className={style.fieldLine}>
                <SearchField value={cellphone} onKeyUpFunc={() => {
                }}
                             minLength={isCellphoneCodeKorean ? 0 : 5}
                             maxLength={isCellphoneCodeKorean ? 11 : 15}
                             isRequired={true}
                             buttonText={tOrganManager("registration.uniqueCheck")}
                             placeholder={isCellphoneCodeKorean
                                 ?tOrganManager("registration.validator.koreanPhonePlaceholder")
                                 :tOrganManager("registration.validator.foreignPhonePlaceholder")

                }
                             onChangeFunc={(e) =>
                                 handleCellphoneChange((e.target as HTMLInputElement).value, setCellphone)}
                             onClickSearchButtonFunc={checkWhetherDuplicatedCellPhoneNumberIs}
                             className={style.nameField}
                />
            </div>
            {
                cellphone.length > 0
                && cellphoneValid !== OrganManagerCellphoneValidStatus.NONE
                && cellphoneValidationMessage()
            }
        </div>
    );
}