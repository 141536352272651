import { UnderlineSelector } from "./UnderlineSelector";
import { useCountry } from "../hooks/hooks";

interface Props {
  nationality: string;
  setNationality: (_: string) => void;
  isRequired?: boolean;
}

export function NationalityUnderlineSelector({ nationality, setNationality, isRequired = false }: Props) {
  const { nationalityOptions } = useCountry();

  return (
    <UnderlineSelector
      options={nationalityOptions}
      selectedValue={nationality}
      setSelectorOption={(o) => setNationality(o.value)}
      isRequired={isRequired}
    />
  );
}
