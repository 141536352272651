import { ArticleProps } from "../../docType";
import { ArticleWrapper } from "./ArticleWrapper";
import { List } from "../../DocComponents";
import { ListItemViewAndCode } from "../ListItemViewAndCode";
import { Pagination } from "../../../layout/content/list/Pagination";
import { sampleListData } from "./data";
import { PageResponse } from "../../../types/page";

export function ArticlePagination({ id, title }: ArticleProps) {
  const code = `
<Pagination page={pageData}
            goSpecificPage={(n: number) => {alert('{n} 페이지 조회!')}}/>
  `;

  const description = `
- Pagination의 Props 타입
{
  page: PageResponse<any>; -- 페이지를 나타내는 Table 컴포넌트에서 받는 listData와 같은 데이터
  goSpecificPage: (pageNum: number) => void; -- 특정 페이지의 데이터를 불러오는 함수, pageNum은 제로베이스임
}
  `;

  const pageData: PageResponse<any> = {
    ...sampleListData,
    totalPages: 100,
    number: 12,
    first: false,
  };

  return (
    <ArticleWrapper id={id} title={title}>
      <List>
        <ListItemViewAndCode exampleCode={code} description={description}>
          <Pagination
            page={pageData}
            goSpecificPage={(n: number) => {
              alert(`${n} 페이지 조회!`);
            }}
          />
        </ListItemViewAndCode>
      </List>
    </ArticleWrapper>
  );
}
