import styled from "styled-components/macro";
import variable from "../../../assets/scss/variable.module.scss";
import React from "react";

interface Props {
  // children: JSX.Element;
  children: React.ReactNode;
  additionalButtons?: JSX.Element;
  id?: string;
  panelClassName?: string;
  title: string;
  subtitle?: string;
  description?: string;
}

const PanelStyled = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid ${variable.colorDE};
  padding: 20px 19px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PanelHeader = styled.div`
  display: flex;
  justify-content: space-between;

  > .title {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: ${variable.colorFGDarkBlue};

    > .subtitle {
      font-weight: normal;
    }
  }

  > .rightElement {
    display: flex;
    gap: 10px;
  }
`;

const PanelTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: ${variable.colorFGDarkBlue};
`;

const Subtitle = styled.span`
  font-weight: normal;
`;

const RightElement = styled.div`
  display: flex;
  gap: 10px;
`;

const Description = styled.p`
  margin-top: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  color: ${variable.color75};
  white-space: pre-line;
  letter-spacing: -0.42px;
`;

export function Panel({ children, additionalButtons, id, panelClassName, title, subtitle, description }: Props) {
  return (
    <PanelStyled id={id ?? ""} className={panelClassName ?? ""}>
      <PanelHeader>
        <PanelTitle>
          {title}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : <></>}
        </PanelTitle>
        <RightElement>{additionalButtons ?? <></>}</RightElement>
      </PanelHeader>
      {description && <Description>{description}</Description>}
      {children}
    </PanelStyled>
  );
}
