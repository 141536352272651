import {SearchCondition} from "./page";
import {ProfileQueryType} from "./userProfile";
import {ListObject} from "./common";

export enum AccessLocation{
    DCAS = "DCAS",
    KPASS = "KPASS",
}

export enum IsAgreementMarketing{
    Y = "Y",
    N = "N",
}

export interface SimpleSearchCondition extends SearchCondition {
    q?: string;
    qtype?:ProfileQueryType
    createdAtFrom?: Date;
    createdAtTo?: Date;
    accessLocation?: string;
    isAgreementMarketing?: boolean;
}

export interface SimpleTesterListDTO {

    idx?:number;
    createdAt?:string;
    name?: string;
    birthday?: string;
    phoneNumber?: string;
    discountCode?: string;
    accessLocation?: string;
    testResultIdx?: string;
    executedInfoIdx?: string;
    isAgreementMarketing?: string;
}

export namespace SimpleTesterListDTO {
    export const toSimpleTesterListVO = (dto: SimpleTesterListDTO): SimpleTesterVO => {
        const copiedDto = { ...dto };
        //eslint-disable-next-line
        return <SimpleTesterVO>{
            ...copiedDto,
        };
    };
}

export interface SimpleTesterVO extends ListObject{
    idx: number;
    createdAt: string;
    name: string;
    birthday?: string;
    phoneNumber?: string;
    discountCode?: string;
    accessLocation?: string;
    testResultIdx?: string;
    executedInfoIdx?: string;
    isAgreementMarketing?: boolean;
}

