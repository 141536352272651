import style from "../style.module.scss";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AdminType } from "../../../types/account";
import { phoneNumberInKRRegExpStr, phoneNumberInOtherRegExpStr } from "../../../util/regexpUtil";
import { useAppDispatch } from "../../../hooks/hooks";
import { findIdAsync, setFoundEmail } from "../../../store/accountSlice";
import { useNavigate } from "react-router-dom";
import { callAsync } from "../../../util/sliceUtil";
import { ResponseString } from "../../../types/response";
import { useTranslation } from "react-i18next";
import { PrimaryOutlineButton } from "../../../components/Buttons";
import { PhoneCountryCodeUnderlineSelector } from "../../../components/PhoneCountryCodeUnderlineSelector";
import { useFindAccountHandleError } from "./useFindAccountHandleError";
import { useBlockEnter } from "../../../hooks/useBlockEnter";

export function FindID() {
  const { t } = useTranslation(["account", "common"]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const { blockEnterKeyEvent } = useBlockEnter();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [adminType] = useState(AdminType.GENERAL);
  const [phoneCountryCode, setPhoneCountryCode] = useState("");

  const isKoreanPhoneCountryCode = useMemo(() => phoneCountryCode === "+82", [phoneCountryCode]);
  const { handleError, errorMessage } = useFindAccountHandleError();

  const onChangePhoneNumber = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber.replace(/[^0-9]/g, ""));
  };

  const onClickFindIDBtn = async () => {
    if (formRef.current?.reportValidity()) {
      callAsync<ResponseString>(
        dispatch(findIdAsync({ codePhone: phoneCountryCode, adminType, phoneNumber })).unwrap(),
        ({ result }) => {
          dispatch(setFoundEmail(result));
          navigate(`/find-account/id-success`);
        },
        (error) => {
          setShowErrorMessage(true);
          handleError(error.errorCode.httpCode);
        }
      ).then();
    }
  };

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const changeInputValue = useCallback((value: string, func: (_: string) => void) => {
    setShowErrorMessage(false);
    func(value);
  }, []);

  return (
    <form className={style.form} onSubmit={(e) => e.preventDefault()} ref={formRef}>
      <div className={style.field}>
        <label className={style.label}>{t("findAccount.phoneNumber.label")}</label>
        <div className={style.line}>
          <PhoneCountryCodeUnderlineSelector
            code={phoneCountryCode}
            setCode={(code) => changeInputValue(code, setPhoneCountryCode)}
          />
        </div>
        <div className={style.line} style={{ marginTop: "0" }}>
          <input
            type={"tel"}
            name={"phone"}
            className={style.input}
            required={true}
            value={phoneNumber}
            onChange={(e) => changeInputValue(e.target.value, onChangePhoneNumber)}
            maxLength={isKoreanPhoneCountryCode ? 11 : 15}
            pattern={isKoreanPhoneCountryCode ? phoneNumberInKRRegExpStr : phoneNumberInOtherRegExpStr}
            placeholder={
              isKoreanPhoneCountryCode
                ? t("inputPhoneNumber.placeholder1", { ns: "common" })
                : t("inputPhoneNumber.placeholder2", { ns: "common" })
            }
            onKeyDown={blockEnterKeyEvent}
          />
        </div>
        <span className={`${style.addedMessage} ${style.normalValue}`}>- {t("findAccount.phoneNumber.support")}</span>
        {showErrorMessage ? (
          <span className={`${style.addedMessage} ${style.notAvailableValue}`}>- {errorMessage}</span>
        ) : (
          <></>
        )}
      </div>
      <div className={style.buttonWrapper}>
        <PrimaryOutlineButton className={style.cancelButton} onClick={() => navigate(`/login`)}>
          {t("findAccount.cancelButton")}
        </PrimaryOutlineButton>
        <button className={style.findButton} onClick={onClickFindIDBtn}>
          {t("findAccount.findIDButton")}
        </button>
      </div>
    </form>
  );
}
