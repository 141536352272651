import { ArticleProps } from "../../docType";
import { Article, H2 } from "../../DocComponents";

interface Props extends ArticleProps {
  children: JSX.Element;
}

export function ArticleWrapper({ id, title, children }: Props) {
  return (
    <Article id={id}>
      <H2>{title}</H2>
      {children}
    </Article>
  );
}
