import { useAppDispatch } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useFile } from "../../../../hooks/file/useFile";
import { useCallback } from "react";
import { PartnerCompanyPutVO, PartnerCompanyType } from "../../../../types/partnerCompany";
import { FileClassification, FileResponse } from "../../../../types/file";
import { setIsPartnerDataSaved, updatePartnerCompanyAsync } from "../../../../store/partnerCompanySlice";

export function usePartnerCompanyUpdater() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { uploadImages } = useFile();

  const updatePartner = useCallback(
    async (idx: number, param: PartnerCompanyPutVO) => {
      try {
        let logoFile = param.defaultLogo;

        if (param.addedLogo) {
          const uploadedFile: FileResponse[] = await uploadImages(
            [param.addedLogo.file],
            FileClassification.PARTNER_COMPANY_LOGO
          );
          logoFile = {
            uri: uploadedFile[0].uri,
            fileIdx: uploadedFile[0].idx,
          };
        }
        await dispatch(
          updatePartnerCompanyAsync({
            idx,
            param: {
              logo: logoFile,
              name: param.name.trim(),
              type: param.type,
              status: param.status,
              address: param.type === PartnerCompanyType.DOMESTIC ? param.address : undefined,
              webSite: param.webSite ? param.webSite : undefined,
              affiliateCodeIdx: param.affiliateCodeIdx,
            },
          })
        ).unwrap();
        dispatch(setIsPartnerDataSaved(true));
        alert("협력사가 수정 되었습니다.");
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("협력사 수정 에러");
      }
    },
    [dispatch, defaultErrorMessage, uploadImages]
  );

  return {
    updatePartner,
  };
}
