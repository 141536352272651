import { TableColumnData } from "../../../types/common";
import { LinkedProductListObj } from "../../../types/unitItem";
import { PageSearchCondition } from "../../../types/page";
import { useProductColumns } from "../hooks/useProductColumns";

interface Args {
  pageCondition: PageSearchCondition;
  getLinkedProducts: () => void;
}

export function useLinkedProductsColumns({ pageCondition, getLinkedProducts }: Args) {
  const productColumns = useProductColumns({
    stopSelling: null,
    resumeSelling: null,
    getProductList: getLinkedProducts,
    hasProductLink: true,
  });

  const copiedColumns = [...productColumns];
  ["productItemCount", "createAt", "idx"].forEach((accessor) => {
    const foundIdx = copiedColumns.findIndex((c) => c.accessor === accessor);
    copiedColumns.splice(foundIdx, 1);
  });

  copiedColumns.unshift({
    accessor: "idx",
    header: "No.",
    sortable: false,
    getNode: (_, idx) => <>{(pageCondition.page ?? 0) * (pageCondition.size ?? 5) + (idx + 1)}</>,
  });
  return copiedColumns as unknown as TableColumnData<LinkedProductListObj>[];
}
