import { ProfileList } from "./tester/ProfileList";
import { ProfileDetail } from "./tester/ProfileDetail";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, useNewWindow } from "../../../hooks/hooks";
import { setUserProfileUserIdx, userProfileStatus } from "../../../store/userProfileSlice";
import { useLoader } from "../../../hooks/common/useLoader";
import { WindowMessage } from "../../../types/newWindow";

interface Props {
  idx: number;
}

export function MemberUserTesters({ idx }: Props) {
  const dispatch = useAppDispatch();
  const status = useAppSelector(userProfileStatus);
  useLoader({ status });

  const [selectedProfileIdx, setSelectedProfileIdx] = useState(0);
  const { closeWindowAndNotifyToParentWindow } = useNewWindow();

  useEffect(() => {
    dispatch(setUserProfileUserIdx(idx));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  return (
    <>
      <ProfileList userIdx={idx} setSelectedProfileIdx={setSelectedProfileIdx} />
      <ProfileDetail
        profileIdx={selectedProfileIdx}
        onDeletedCallback={() => closeWindowAndNotifyToParentWindow(WindowMessage.USER)}
      />
    </>
  );
}
