import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { getPopupsAsync } from "../../../../store/popupSlice";
import { PopupDTO, PopupListVO, PopupSearchCondition } from "../../../../types/popup";
import { PageResponse } from "../../../../types/page";

export function usePopupsGetter() {
  const dispatch = useAppDispatch();

  const getPopups = useCallback(
    async (condition: PopupSearchCondition) => {
      try {
        const result: PageResponse<PopupDTO> = await dispatch(getPopupsAsync(condition)).unwrap();
        return {
          ...result,
          content: result.content.map((dto) => ({ ...dto } as PopupListVO)),
        };
      } catch (e) {
        console.error(e);
        throw new Error("팝업 목록 조회 에러");
      }
    },
    [dispatch]
  );

  return {
    getPopups,
  };
}
