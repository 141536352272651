import { useCallback } from "react";
import { AffiliateCodeDTO, AffiliateCodeListVO } from "../../../../types/affiliateCode";
import { useOrganManagerGetter } from "../../../member/organmanager/detail/info/hooks/useOrganManagerGetter";
import { OrganManagerVO } from "../../../../types/organManager";

export function useAffiliateCodeListVOMapper() {
  const { getOrganManager } = useOrganManagerGetter();

  const mapAffiliateCodeDTOToListVO = useCallback(
    async (codeDTO: AffiliateCodeDTO) => {
      try {
        const organManager: OrganManagerVO = await getOrganManager(codeDTO.organManagerIdx);

        return {
          idx: codeDTO.idx,
          createAt: codeDTO.createAt,
          organManagerIdx: codeDTO.organManagerIdx,
          name: organManager.name,
          email: organManager.email,
          codePhone: organManager.codePhone,
          phoneNumber: organManager.phoneNumber,
          code: codeDTO.code,
          status: codeDTO.status,
          discountType: codeDTO.discountType,
          discountValue: codeDTO.discountValue,
          commissionType: codeDTO.commissionType,
          commissionValue: codeDTO.commissionValue,
          isVatInclude: codeDTO.vatInclude,
          isTaxDeduction: codeDTO.isTaxDeduction,
          availableStartAt: codeDTO.availableStartAt,
          availableEndAt: codeDTO.availableEndAt,
        } as AffiliateCodeListVO;
      } catch (e) {
        console.error(e);
        throw new Error("mapAffiliateCodeDTOToListVO 에러");
      }
    },
    [getOrganManager]
  );

  return {
    mapAffiliateCodeDTOToListVO,
  };
}
