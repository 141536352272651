import { TableColumnData } from "../../../../types/common";
import React, { useCallback, useState } from "react";
import { AdminListObj, AdminStatus } from "../../../../types/admin";
import listStyle from "../../../../layout/content/list/list.module.scss";
import { useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { AdminStatusComponent } from "../AdminStatusComponent";
import { useSignedInAdminIdx } from "./useSignedInAdminIdx";
import { Anchor } from "../../../../components/Anchor";
import { useAdminStatus } from "./useAdminStatus";
import { useAdminButtons } from "./useAdminButtons";
import { useTranslation } from "react-i18next";
import { useRoleGetter } from "../../../role/hooks/useRoleGetter";

interface Args {
  getList: () => void;
}

export function useAdminColumns({ getList }: Args) {
  const { t } = useTranslation("admin");
  const signedInAdminIdx = useSignedInAdminIdx();
  const { openAdminDetailPopup } = useNewWindow();
  const { isBeforeSignUp } = useAdminStatus();
  const { ResendButton, DeleteButton, UnblockButton, RoleSettingButton, AuthorizeButton } = useAdminButtons({
    onRefresh: getList,
  });

  const { timeConverter } = useTimeConverter();
  const { getRoleNameByRoleIdx } = useRoleGetter({});

  const Status = ({ admin }: { admin: AdminListObj }) => {
    const [show, setShow] = useState(false);

    return (
      <AdminStatusComponent
        admin={admin}
        isShow={show}
        onHideStatusPopup={() => setShow(false)}
        onShowStatusPopup={() => setShow(true)}
      />
    );
  };

  const isLeaved = useCallback((status: AdminStatus) => status === AdminStatus.LEAVE, []);

  const columns: TableColumnData<AdminListObj>[] = [
    {
      accessor: "createAt",
      header: t("list.columns.createdAt"),
      getNode: (it) => <span>{timeConverter.convertToLocalTime(it.createAt)}</span>,
      sortable: true,
    },
    {
      accessor: "roleName",
      header: t("list.columns.roleName"),
      getNode: (it) => <span>{getRoleNameByRoleIdx(it.roleIdx)}</span>,
      sortable: false,
    },
    {
      accessor: "name",
      header: t("list.columns.name"),
      getNode: (it) =>
        isBeforeSignUp(it.status) ? (
          <>{it.name}</>
        ) : (
          <Anchor
            onClick={(e) => {
              e.preventDefault();
              openAdminDetailPopup(it.idx);
            }}
            rel="noopener noreferrer"
          >
            {it.status === AdminStatus.LEAVE ? t("list.columns.leaveMemberName") : it.name}
          </Anchor>
        ),
      sortable: false,
      width: 104,
    },
    {
      accessor: "email",
      header: t("list.columns.id"),
      getNode: (it) => <span>{isLeaved(it.status) ? "-" : it.email}</span>,
      sortable: false,
      width: 236,
    },
    {
      accessor: "phoneNumber",
      header: t("list.columns.phoneNumber"),
      getNode: (it) => <span>{isLeaved(it.status) ? "-" : `(${it.codePhone}) ${it.phoneNumber}`}</span>,
      sortable: false,
    },
    {
      accessor: "status",
      header: t("list.columns.status"),
      getNode: (it) => <Status admin={it} />,
      sortable: false,
    },
    {
      accessor: "idx",
      header: t("list.columns.additionalFunctions"),
      getNode: (it) =>
        it.idx !== signedInAdminIdx && (
          <div className={listStyle.additionalButtons}>
            <UnblockButton adminIdx={it.idx} adminStatus={it.status} />
            <DeleteButton adminIdx={it.idx} adminStatus={it.status} />
            <ResendButton adminIdx={it.idx} adminStatus={it.status} />
            <AuthorizeButton adminIdx={it.idx} adminStatus={it.status} />
            <RoleSettingButton adminIdx={it.idx} adminStatus={it.status} adminRoleIdx={it.roleIdx} />
          </div>
        ),
      sortable: false,
      width: 230,
    },
  ];

  return { columns };
}
