import listStyle from "../list/list.module.scss";
import React from "react";

export interface ContentTableData {
  header: (string | JSX.Element)[];
  contents: (string | JSX.Element)[][];
}

export function ContentTable({ data }: { data: ContentTableData }) {
  return (
    <table className={listStyle.table}>
      <thead>
        <tr>
          {data.header.map((h, idx) => (
            <th key={`header-${idx}`} className={listStyle.th}>
              {h}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.contents.map((row, rowIdx) => (
          <tr key={`contents-row-${rowIdx}`}>
            {row.map((column, colIdx) => (
              <td key={`contents-row-${rowIdx}-col-${colIdx}`} className={listStyle.td}>
                {column}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
