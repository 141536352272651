import { MainApi } from "./MainApi";
import {
  CouponCountInfoParam,
  CouponDeleteParam,
  CouponHistoryPostParam,
  CouponHistorySearchCondition,
  CouponPostParam,
  CouponSearchCondition,
  CouponStatusUpdateParam,
} from "../types/coupon";

export class CouponApi {
  static baseUrl = `${MainApi.urlPrefix}/coupon`;

  static getUserCouponHistoryList = (userIdx: number, condition: CouponHistorySearchCondition) => () =>
    MainApi.api.get(`${CouponApi.baseUrl}/users/${userIdx}/histories${MainApi.toParamStringFromObject(condition)}`);

  static getOrganManagerCouponHistoryList = (organManagerIdx: number, condition: CouponHistorySearchCondition) => () =>
    MainApi.api.get(
      `${CouponApi.baseUrl}/organ-managers/${organManagerIdx}/histories` +
        `${MainApi.toParamStringFromObject(condition)}`
    );

  static deleteCouponHistory = (historyIdx: number) => () =>
    MainApi.api.delete(`${CouponApi.baseUrl}/history/${historyIdx}`);

  static getCouponList = (condition: CouponSearchCondition) => () =>
    MainApi.api.get(`${CouponApi.baseUrl}${MainApi.toParamStringFromObject(condition)}`);

  static deleteCoupon = (param: CouponDeleteParam) => () => MainApi.api.delete(`${CouponApi.baseUrl}`, { data: param });

  static deleteCouponCompletely = (param: CouponDeleteParam) => () =>
    MainApi.api.delete(`${CouponApi.baseUrl}/completely`, { data: param });

  static updateCouponStatus = (param: CouponStatusUpdateParam) => () =>
    MainApi.api.put(`${CouponApi.baseUrl}/status`, param);

  static getCouponDetail = (couponIdx: number) => () => MainApi.api.get(`${CouponApi.baseUrl}/${couponIdx}`);

  static grantCoupon = (param: CouponHistoryPostParam) => () => MainApi.api.post(`${CouponApi.baseUrl}/history`, param);

  static createCoupon = (param: CouponPostParam) => () => MainApi.api.post(`${CouponApi.baseUrl}`, param);

  static getCouponCountInfo = (param: CouponCountInfoParam) => () =>
    MainApi.api.get(`${CouponApi.baseUrl}/history/count-info${MainApi.toParamStringFromObject(param)}`);
}
