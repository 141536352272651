import { TableColumnData } from "../../../../types/common";
import {
  AffiliateCodeListVO,
  AffiliateCodeStatus,
  CodeChargeType,
  CodeDiscountType,
} from "../../../../types/affiliateCode";
import { useNewWindow, useTimeConverter } from "../../../../hooks/hooks";
import { AnchorComponent } from "../../../../layout/content/AnchorComponent";
import { numberWithCommas } from "../../../../util/etcUtil";
import { useAffiliateCodeType } from "../../../../types/hooks/useAffiliateCodeType";
import { ActionButton, ActiveActionButton } from "../../../../components/Buttons";
import { useCallback, useMemo } from "react";
import { CopiableText } from "../../../../components/CopiableText";
import { Anchor } from "../../../../components/Anchor";

interface Args {
  resumeCodeUsage: (idx: number[]) => void;
  stopCodeUsage: (idx: number[]) => void;
  setCode?: (_?: string) => void;
  getList?: () => void;
}

export function useAffiliateCodeColumns({ resumeCodeUsage, stopCodeUsage, setCode, getList }: Args) {
  const { timeConverter } = useTimeConverter();
  const { getAffiliateCodeStatusLabel } = useAffiliateCodeType();
  const { openOrganManagerDetailPopup, openOrganManagerDetailAffiliateCodeTabPopup, openAffiliateCodeDurationPopup } =
    useNewWindow();

  const createAtColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "createAt",
      header: "등록일시",
      sortable: true,
      getNode: (vo: AffiliateCodeListVO) => <span>{timeConverter.convertToLocalTime(vo.createAt)}</span>,
    }),
    [timeConverter]
  );
  const nameColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "name",
      header: "보유자",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <AnchorComponent
          onClickFunc={(e) => {
            e.preventDefault();
            openOrganManagerDetailPopup(vo.organManagerIdx);
          }}
          content={vo.name}
        />
      ),
    }),
    [openOrganManagerDetailPopup]
  );
  const codeColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "code",
      header: "제휴코드",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <AnchorComponent
          onClickFunc={(e) => {
            e.preventDefault();
            openOrganManagerDetailAffiliateCodeTabPopup(vo.organManagerIdx, vo.code);
          }}
          content={vo.code}
        />
      ),
    }),
    [openOrganManagerDetailAffiliateCodeTabPopup]
  );
  const discountColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "discountValue",
      header: "할인 금액",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <>
          {numberWithCommas(vo.discountValue)}
          {vo.discountType === CodeDiscountType.PERCENT ? "%" : "원"} 할인
        </>
      ),
    }),
    []
  );
  const commissionColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "commissionValue",
      header: "수수료",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <>
          {numberWithCommas(vo.commissionValue)}
          {vo.commissionType === CodeChargeType.PERCENT ? "%" : "원"}
        </>
      ),
    }),
    []
  );
  const vatColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "isVatInclude",
      header: "부가세",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => <>{vo.isVatInclude ? "10%" : "미포함"}</>,
    }),
    []
  );
  const taxDeductionColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "isTaxDeduction",
      header: "세금공제",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => <>{vo.isTaxDeduction ? "세금공제" : "-"}</>,
    }),
    []
  );
  const paymentsCountColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "paymentsCount",
      header: "결제건수",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => <>{vo.paymentsCount}</>,
    }),
    []
  );

  const getStatusColorClassName = useCallback((status: AffiliateCodeStatus) => {
    switch (status) {
      case AffiliateCodeStatus.AVAILABLE:
        return "blue";
      case AffiliateCodeStatus.UNAVAILABLE:
        return "";
      case AffiliateCodeStatus.DELETED:
        return "red";
      default:
        return "";
    }
  }, []);

  const statusColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "status",
      header: "상태",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <span className={getStatusColorClassName(vo.status)}>{getAffiliateCodeStatusLabel(vo.status)}</span>
      ),
    }),
    [getAffiliateCodeStatusLabel, getStatusColorClassName]
  );

  const getDurationText = useCallback(
    (vo: AffiliateCodeListVO) => (
      <>
        {timeConverter.convertToLocalDate(vo.availableStartAt)}
        <br />~ {vo.availableEndAt ? timeConverter.convertToLocalDate(vo.availableEndAt) : "상시사용"}
      </>
    ),
    [timeConverter]
  );
  const availableStartAtColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "availableStartAt",
      header: "사용기한",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => {
        return (
          <>
            {vo.status === AffiliateCodeStatus.DELETED ? (
              getDurationText(vo)
            ) : (
              <Anchor
                onClick={(e) => {
                  e.preventDefault();
                  openAffiliateCodeDurationPopup(
                    {
                      codeIdx: vo.idx,
                      organManagerName: vo.name,
                      code: vo.code,
                      startAt: vo.availableStartAt,
                      endAt: vo.availableEndAt ?? null,
                    },
                    getList
                  );
                }}
              >
                {getDurationText(vo)}
              </Anchor>
            )}
          </>
        );
      },
    }),
    [getDurationText, openAffiliateCodeDurationPopup, getList]
  );
  const availableStartAtOnDetailColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "availableStartAt",
      header: "사용기한",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => {
        return <>{getDurationText(vo)}</>;
      },
    }),
    [getDurationText]
  );
  const etcColumn: TableColumnData<AffiliateCodeListVO> = useMemo(
    () => ({
      accessor: "idx",
      header: "관련 기능",
      sortable: false,
      getNode: (vo: AffiliateCodeListVO) => (
        <>
          {vo.status === AffiliateCodeStatus.AVAILABLE && (
            <ActionButton onClick={() => stopCodeUsage([vo.idx])}>사용중지</ActionButton>
          )}
          {vo.status === AffiliateCodeStatus.UNAVAILABLE && (
            <ActiveActionButton onClick={() => resumeCodeUsage([vo.idx])}>사용재개</ActiveActionButton>
          )}
        </>
      ),
    }),
    [resumeCodeUsage, stopCodeUsage]
  );

  const columns: TableColumnData<AffiliateCodeListVO>[] = useMemo(
    () => [
      createAtColumn,
      nameColumn,
      codeColumn,
      discountColumn,
      commissionColumn,
      vatColumn,
      taxDeductionColumn,
      paymentsCountColumn,
      statusColumn,
      availableStartAtColumn,
      etcColumn,
    ],
    [
      createAtColumn,
      nameColumn,
      codeColumn,
      discountColumn,
      commissionColumn,
      vatColumn,
      taxDeductionColumn,
      paymentsCountColumn,
      statusColumn,
      availableStartAtColumn,
      etcColumn,
    ]
  );

  const columnsInDetail: TableColumnData<AffiliateCodeListVO>[] = useMemo(
    () => [
      { ...createAtColumn, width: 100 },
      { ...codeColumn, getNode: (vo) => <CopiableText text={vo.code} isBlue={false} /> },
      discountColumn,
      commissionColumn,
      vatColumn,
      taxDeductionColumn,
      paymentsCountColumn,
      statusColumn,
      availableStartAtOnDetailColumn,
      {
        accessor: "idx",
        header: "",
        sortable: false,
        getNode: (vo: AffiliateCodeListVO) => <ActionButton onClick={() => setCode?.(vo.code)}>보기</ActionButton>,
      },
    ],
    [
      createAtColumn,
      codeColumn,
      discountColumn,
      commissionColumn,
      vatColumn,
      taxDeductionColumn,
      paymentsCountColumn,
      statusColumn,
      availableStartAtOnDetailColumn,
      setCode,
    ]
  );

  return {
    columns,
    columnsInDetail,
  };
}
