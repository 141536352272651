import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Nav } from "./Nav";
import style from "./layout.module.scss";
import { ProfileBar } from "./ProfileBar";
import { SubNav } from "./SubNav";
import { Menu } from "../../types/common";
import { useNavMenu } from "./hooks/useNavMenu";

export function ContentMain() {
  const location = useLocation();
  const pathArr = location.pathname.split("/");

  const [thisFirstMenu, setThisFirstMenu] = useState<Menu | null>(null);
  const [thisSecondMenu, setThisSecondMenu] = useState<Menu | null>(null);
  const [firstPath, setFirstPath] = useState(pathArr[1]);
  const [secondPath, setSecondPath] = useState(pathArr[2]);

  const { menuList } = useNavMenu();

  useEffect(() => {
    setFirstPath(pathArr[1]);
    setSecondPath(pathArr[2]);
  }, [pathArr]);

  useEffect(() => {
    const thisMenu = menuList.find((menu) => menu.link === firstPath);
    setThisFirstMenu(thisMenu ?? null);

    const thisSubMenu = thisMenu?.subMenu?.find((menu) => menu.link === secondPath);
    setThisSecondMenu(thisSubMenu ?? null);
  }, [menuList, firstPath, secondPath]);

  return (
    <div className={style.layout}>
      <Nav menuList={menuList} thisFirstMenu={thisFirstMenu} firstMenuPath={firstPath} secondMenuPath={secondPath} />
      <div className={style.content}>
        <ProfileBar />
        <SubNav menus={thisFirstMenu?.subMenu ?? []} firstPath={firstPath} secondPath={secondPath} />
        <main className={style.main}>
          <ul className={style.breadcrumb}>
            <li className={`${style.item} ${style.mainMenu}`}>{thisFirstMenu?.label}</li>
            <li className={`${style.item} ${style.subMenu}`}>{thisSecondMenu?.label}</li>
          </ul>
          <Outlet />
        </main>
      </div>
    </div>
  );
}
