import {useCallback} from "react";
import {createOrganManagerAsync} from "../../../store/organManagerSlice";
import {useAppDispatch} from "../../../hooks/hooks";
import {OrganManagerPostDTO} from "../../../types/organManager";

export function useOrganManagerCreator() {
    const dispatch = useAppDispatch();

    const createOrganManager = useCallback(
        async ({ nationality, email, codePhone, phoneNumber, name, password, adminOrganManagerPermissionPostDTO }
                   : OrganManagerPostDTO) => {
            try {
                await dispatch(
                    createOrganManagerAsync({
                        nationality, email, codePhone, phoneNumber, name, password, adminOrganManagerPermissionPostDTO
                    })
                ).unwrap();
            } catch (e) {
                console.error(e);
                throw new Error("소속관리자 등록 에러");
            }
        },
        [dispatch]
    );
    return {
        createOrganManager
    }
}