import { useCallback, useMemo, useState } from "react";
import { UserQueryType, UserStatus } from "../../../../types/user";
import { PageSearchCondition } from "../../../../types/page";
import { ListQueryType } from "../../../../types/list";
import { FilterType, FilterValue } from "../../../../types/filter";
import { useFilterValues } from "../../../../hooks/list/useFilterValues";
import { useListSearchParams } from "../../../../hooks/list/useListSearchParams";

export interface UserListQueryType extends ListQueryType {
  searchWordType?: string;
  searchWord?: string;
  ageFrom?: string;
  ageTo?: string;
  totalTestCntFrom?: string;
  totalTestCntTo?: string;
  status?: string;
  createdAtFrom?: string;
  createdAtTo?: string;
  country?: string;
}

export interface MemberUserFilterValues {
  searchWordTypeFilterValue: FilterValue<UserQueryType>;
  searchWordFilterValue: FilterValue<string>;
  ageFromFilterValue: FilterValue<string>;
  ageToFilterValue: FilterValue<string>;
  totalTestCntFromFilterValue: FilterValue<string>;
  totalTestCntToFilterValue: FilterValue<string>;
  statusFilterValue: FilterValue<UserStatus | "">;
  createdAtFromFilterValue: FilterValue<Date | undefined>;
  createdAtToFilterValue: FilterValue<Date | undefined>;
  countryFilterValue: FilterValue<string>;
}

export function useUserFilterValues() {
  const [searchWordType, setSearchWordType] = useState<UserQueryType>(UserQueryType.NAME);
  const [searchWord, setSearchWord] = useState("");
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>();
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>();
  const [ageFrom, setAgeFrom] = useState("");
  const [ageTo, setAgeTo] = useState("");
  const [country, setCountry] = useState("");
  const [totalTestCntFrom, setTotalTestCntFrom] = useState("");
  const [totalTestCntTo, setTotalTestCntTo] = useState("");
  const [status, setStatus] = useState<UserStatus | "">("");

  const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

  const { listQueryType, setUrlSearchParams } = useListSearchParams<UserListQueryType>();

  const filterValueArray: FilterValue<any>[] = useMemo(
    () => [
      {
        key: "searchWordType",
        value: searchWordType,
        setter: setSearchWordType,
        defaultValue: UserQueryType.NAME,
        type: FilterType.OTHER,
      },
      { key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING },
      {
        key: "createdAtFrom",
        value: createdAtFrom,
        setter: setCreatedAtFrom,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      {
        key: "createdAtTo",
        value: createdAtTo,
        setter: setCreatedAtTo,
        defaultValue: undefined,
        type: FilterType.DATE,
      },
      { key: "ageFrom", value: ageFrom, setter: setAgeFrom, defaultValue: "", type: FilterType.STRING },
      { key: "ageTo", value: ageTo, setter: setAgeTo, defaultValue: "", type: FilterType.STRING },
      { key: "country", value: country, setter: setCountry, defaultValue: "", type: FilterType.STRING },
      {
        key: "totalTestCntFrom",
        value: totalTestCntFrom,
        setter: setTotalTestCntFrom,
        defaultValue: "",
        type: FilterType.STRING,
      },
      {
        key: "totalTestCntTo",
        value: totalTestCntTo,
        setter: setTotalTestCntTo,
        defaultValue: "",
        type: FilterType.STRING,
      },
      { key: "status", value: status, setter: setStatus, defaultValue: "", type: FilterType.OTHER },
    ],
    [
      searchWordType,
      searchWord,
      createdAtFrom,
      createdAtTo,
      ageFrom,
      ageTo,
      country,
      totalTestCntFrom,
      totalTestCntTo,
      status,
    ]
  );

  const { getFilterValueByKey } = useFilterValues({
    filterValueArray,
    listQueryType,
    setPageInfoOnFilter,
  });

  const filterValues = useMemo(
    () =>
      ({
        searchWordTypeFilterValue: getFilterValueByKey("searchWordType") as FilterValue<UserQueryType>,
        searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
        ageFromFilterValue: getFilterValueByKey("ageFrom") as FilterValue<string>,
        ageToFilterValue: getFilterValueByKey("ageTo") as FilterValue<string>,
        totalTestCntFromFilterValue: getFilterValueByKey("totalTestCntFrom") as FilterValue<string>,
        totalTestCntToFilterValue: getFilterValueByKey("totalTestCntTo") as FilterValue<string>,
        statusFilterValue: getFilterValueByKey("status") as FilterValue<UserStatus | "">,
        createdAtFromFilterValue: getFilterValueByKey("createdAtFrom") as FilterValue<Date | undefined>,
        createdAtToFilterValue: getFilterValueByKey("createdAtTo") as FilterValue<Date | undefined>,
        countryFilterValue: getFilterValueByKey("country") as FilterValue<string>,
      } as MemberUserFilterValues),
    [getFilterValueByKey]
  );

  const searchList = useCallback(
    (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: UserListQueryType) => {
      const param: UserListQueryType = {
        searchWordType: listQueryTypeParam?.searchWordType,
        searchWord: listQueryTypeParam?.searchWord,
        ageFrom: listQueryTypeParam?.ageFrom,
        ageTo: listQueryTypeParam?.ageTo,
        totalTestCntFrom: listQueryTypeParam?.totalTestCntFrom,
        totalTestCntTo: listQueryTypeParam?.totalTestCntTo,
        status: listQueryTypeParam?.status,
        createdAtFrom: listQueryTypeParam?.createdAtFrom,
        createdAtTo: listQueryTypeParam?.createdAtTo,
        country: listQueryTypeParam?.country,
      };

      return () => setUrlSearchParams(param, pageSearchCondition);
    },
    [setUrlSearchParams]
  );

  return {
    pageInfoOnFilter,
    filterValues,
    searchList,
    listQueryType,
  };
}
