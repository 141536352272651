import { TableColumnData } from "../../../../../../types/common";
import { OrganMember } from "../../../../../../types/organMember";
import React from "react";
import { LinkStyle } from "../../../../../../components/styled/LinkStyle";
import { CopiableText } from "../../../../../../components/CopiableText";
import { ActionButton } from "../../../../../../components/Buttons";
import { ActionButtonWrapper } from "../../../../../../layout/content/ActionButtonWrapper";
import { useNewWindow, useTimeConverter } from "../../../../../../hooks/hooks";
import { OrganVoucherCount } from "../components/OrganVoucherCount";
import { useTranslation } from "react-i18next";
import { useGenderType } from "../../../../../../types/hooks/useGenderType";

interface Args {
  openGrantVoucherPopup: (organMemberIdx: number) => void;
  onRefresh: () => void;
}
export function useOrganMemberColumns({ openGrantVoucherPopup, onRefresh }: Args): TableColumnData<OrganMember>[] {
  const { t } = useTranslation("organManager");
  const { timeConverter } = useTimeConverter();
  const { toStringFromGender } = useGenderType();

  const { openNewWindowWithURL, openCreatedProfileDetailPopup } = useNewWindow();
  const getRelatedActionButtons = function (organMember: OrganMember) {
    return (
      <ActionButtonWrapper>
        <>
          {!organMember.hasOrganVoucher && (
            <ActionButton onClick={() => openGrantVoucherPopup(organMember.idx)}>
              {t("detail.organMember.grantOrganVoucher.buttonLabel")}
            </ActionButton>
          )}
        </>
      </ActionButtonWrapper>
    );
  };

  return [
    {
      accessor: "createAt",
      header: t("detail.organMember.columns.createdAt"),
      sortable: true,
      getNode: (it) => <span>{timeConverter.convertToLocalTime(it.createAt)}</span>,
    },
    {
      accessor: "nickname",
      header: t("detail.organMember.columns.testerName"),
      sortable: false,
      getNode: (it: OrganMember) => (
        <LinkStyle
          onClick={() => {
            if (it.userIdx) {
              openNewWindowWithURL(`/detail/member/user/${it.userIdx}?type=TESTERS&profileIdx=${it.profileIdx}`, {
                width: 1000,
                height: 800,
              });
            } else {
              openCreatedProfileDetailPopup(it.profileIdx, onRefresh);
            }
          }}
        >
          {it.name}
        </LinkStyle>
      ),
    },
    {
      accessor: "age",
      header: t("detail.organMember.columns.age"),
      sortable: false,
      getNode: (it) => <span>{it.age}</span>,
    },
    {
      accessor: "gender",
      header: t("detail.organMember.columns.gender"),
      sortable: false,
      getNode: (it) => <span>{it.gender ? toStringFromGender(it.gender) : "-"}</span>,
    },
    {
      accessor: "testCount",
      header: t("detail.organMember.columns.testCount"),
      sortable: false,
      getNode: (it) => <span>{it.testCount}</span>,
    },
    {
      accessor: "hasOrganVoucher",
      header: t("detail.organMember.columns.organVoucher"),
      sortable: false,
      getNode: (it) =>
        it.hasOrganVoucher ? (
          <OrganVoucherCount organVoucherCount={it.hasOrganVoucher ? 1 : 0} organIdx={it.voucherOrganIdx} />
        ) : (
          <span>0</span>
        ),
    },
    {
      accessor: "organSignInCode",
      header: t("detail.organMember.columns.organSignInCode"),
      sortable: false,
      getNode: (it) => (
        <span>{it.organSignInCode ? <CopiableText text={it.organSignInCode} isBlue={false} /> : "-"}</span>
      ),
    },
    {
      accessor: "idx",
      header: t("detail.organMember.columns.additionalFunctions"),
      sortable: false,
      getNode: (it) => getRelatedActionButtons(it),
    },
  ];
}
