import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { withdrawMarketingTermsAsync } from "../../../../store/organManagerSlice";
import { MarketingTermsCategory } from "../../../../types/terms";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useOrganManagerMarketingTermsWithdrawer() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const withdrawTerms = useCallback(
    (organManagerIdx: number, termsCategory: MarketingTermsCategory, onSuccess: () => void) => {
      callAsync(
        dispatch(withdrawMarketingTermsAsync({ organManagerIdx, termsCategory })).unwrap(),
        () => onSuccess(),
        () => alert(defaultErrorMessage)
      ).then();
    },
    [dispatch, defaultErrorMessage]
  );

  return {
    withdrawTerms,
  };
}
