import { ListObject, TableColumnData } from "../../../../types/common";
import listStyle from "../list.module.scss";
import { OrderDirection, PageResponse, PageSearchCondition } from "../../../../types/page";
import React, { useMemo } from "react";

interface Args<T extends ListObject> {
  listData: PageResponse<T>;
  getList: (pageCondition: PageSearchCondition) => VoidFunction;
  setCheckedRowIdxArr?: (_: number[]) => void;
  checkedRowIdxArr?: number[];
  showCheckbox?: (_: T) => boolean;
}

export function useTable<T extends ListObject>({
  listData,
  getList,
  setCheckedRowIdxArr,
  checkedRowIdxArr,
  showCheckbox,
}: Args<T>) {
  const showOrderNode = (c: TableColumnData<T>) => {
    if (c.sortable) {
      const foundOrderColumn = listData.orders.find((o) => o.property === c.accessor);

      return (
        <>
          {foundOrderColumn ? (
            foundOrderColumn.ascending ? (
              <button
                className={`${listStyle.orderBtn} ${listStyle.ascBtn} ${listStyle.active}`}
                onClick={(event) => {
                  event.preventDefault();
                  getList({
                    sort: [
                      {
                        columnName: c.accessor.toString(),
                        direction: OrderDirection.DESC,
                      },
                    ],
                  })();
                  setCheckedRowIdxArr?.([]);
                }}
              ></button>
            ) : (
              <button
                className={`${listStyle.orderBtn} ${listStyle.descBtn} ${listStyle.active}`}
                onClick={(event) => {
                  event.preventDefault();
                  getList({
                    sort: [
                      {
                        columnName: c.accessor.toString(),
                        direction: OrderDirection.ASC,
                      },
                    ],
                  })();
                  setCheckedRowIdxArr?.([]);
                }}
              ></button>
            )
          ) : (
            <button
              className={`${listStyle.orderBtn} ${listStyle.ascBtn}`}
              onClick={(event) => {
                event.preventDefault();
                getList({
                  sort: [
                    {
                      columnName: c.accessor.toString(),
                      direction: OrderDirection.ASC,
                    },
                  ],
                })();
                setCheckedRowIdxArr?.([]);
              }}
            ></button>
          )}
        </>
      );
    }

    return <></>;
  };

  const onChangeHeaderCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setCheckedRowIdxArr && checkedRowIdxArr) {
      if ((e.target as HTMLInputElement).checked) {
        const idxes = listData?.content.filter((data) => showCheckbox?.(data) ?? true).map((data) => data.idx) ?? [];
        setCheckedRowIdxArr(idxes);
      } else {
        setCheckedRowIdxArr([]);
      }
    }
  };

  const onChangeRowCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const thisElement = e.target as HTMLInputElement;
    const thisElementIdx = Number(thisElement.dataset.idx);

    if (setCheckedRowIdxArr && checkedRowIdxArr) {
      if (thisElement.checked) {
        setCheckedRowIdxArr([...checkedRowIdxArr, thisElementIdx]);
      } else {
        setCheckedRowIdxArr(checkedRowIdxArr.filter((idx) => idx !== thisElementIdx));
      }
    }
  };

  const isAllChecked = useMemo(() => {
    if (checkedRowIdxArr) {
      const filteredContents = listData.content.filter((data) => showCheckbox?.(data) ?? true);

      if (filteredContents.length > 1) {
        return filteredContents.map((data) => data.idx).every((idx) => checkedRowIdxArr.includes(idx));
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [listData.content, showCheckbox, checkedRowIdxArr]);

  return {
    showOrderNode,
    onChangeHeaderCheckbox,
    onChangeRowCheckbox,
    isAllChecked,
  };
}
