import {ListQueryType} from "../../../../types/list";
import {FilterType, FilterValue} from "../../../../types/filter";
import {BannerLocation, BannerStatus, BannerType} from "../../../../types/banner";
import {WebsiteType} from "../../../../types/common";
import {useCallback, useMemo, useState} from "react";
import {PageSearchCondition} from "../../../../types/page";
import {useListSearchParams} from "../../../../hooks/list/useListSearchParams";
import {useFilterValues} from "../../../../hooks/list/useFilterValues";

export interface BannerListQueryType extends ListQueryType {
    searchWord?: string;
    availableStartAt?: string;
    availableEndAt?: string;
    type?: string;
    websiteType?: string;
    bannerLocation?: string;
    status?: string;
}

export interface BannerFilterValues {
    searchWordFilterValue: FilterValue<string>;
    typeFilterValue: FilterValue<BannerType | "">;
    websiteTypeFilterValue: FilterValue<WebsiteType | "">;
    positionFilterValue: FilterValue<BannerLocation | "">;
    statusFilterValue: FilterValue<BannerStatus | "">;
    availableStartAtFilterValue: FilterValue<Date | undefined>;
    availableEndAtFilterValue: FilterValue<Date | undefined>;
}

export function useBannerFilterValues() {
    const [searchWord, setSearchWord] = useState("");
    const [availableStartAt, setAvailableStartAt] = useState<Date | undefined>();
    const [availableEndAt, setAvailableEndAt] = useState<Date | undefined>();
    const [type, setType] = useState<BannerType | "">("");
    const [websiteType, setWebsiteType] = useState<WebsiteType | "">("");
    const [bannerLocation, setBannerLocation] = useState<BannerLocation | "">("");
    const [status, setStatus] = useState<BannerStatus | "">("");

    const [pageInfoOnFilter, setPageInfoOnFilter] = useState<PageSearchCondition>({});

    const { listQueryType, setUrlSearchParams } = useListSearchParams<BannerListQueryType>();

    const filterValueArray: FilterValue<any>[] = useMemo(
        () => [
            {key: "searchWord", value: searchWord, setter: setSearchWord, defaultValue: "", type: FilterType.STRING},
            {
                key: "availableStartAt",
                value: availableStartAt,
                setter: setAvailableStartAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "availableEndAt",
                value: availableEndAt,
                setter: setAvailableEndAt,
                defaultValue: undefined,
                type: FilterType.DATE,
            },
            {
                key: "type",
                value: type,
                setter: setType,
                defaultValue: "",
                type: FilterType.OTHER,
            },
            {
                key: "websiteType",
                value: websiteType,
                setter: setWebsiteType,
                defaultValue: "",
                type: FilterType.OTHER,
            },
            {
                key: "bannerLocation",
                value: bannerLocation,
                setter: setBannerLocation,
                defaultValue: "",
                type: FilterType.OTHER,
            },
            {
                key: "status",
                value: status,
                setter: setStatus,
                defaultValue: "",
                type: FilterType.OTHER,
            },
        ],
        [searchWord, bannerLocation, type, status, websiteType, availableStartAt, availableEndAt]
    );


    const { getFilterValueByKey } = useFilterValues({
        filterValueArray,
        listQueryType,
        setPageInfoOnFilter,
    });

    const filterValues: BannerFilterValues = useMemo(
        () =>
            ({
                searchWordFilterValue: getFilterValueByKey("searchWord") as FilterValue<string>,
                availableStartAtFilterValue: getFilterValueByKey("availableStartAt") as FilterValue<Date | undefined>,
                availableEndAtFilterValue: getFilterValueByKey("availableEndAt") as FilterValue<Date | undefined>,
                websiteTypeFilterValue: getFilterValueByKey("websiteType") as FilterValue<WebsiteType | "">,
                typeFilterValue: getFilterValueByKey("type") as FilterValue<BannerType | "">,
                positionFilterValue: getFilterValueByKey("bannerLocation") as FilterValue<BannerLocation | "">,
                statusFilterValue: getFilterValueByKey("status") as FilterValue<BannerStatus | "">,
            } as BannerFilterValues),
        [getFilterValueByKey]
    );


    const searchList = useCallback(
        (pageSearchCondition: PageSearchCondition, listQueryTypeParam?: BannerListQueryType) => {
            const param: BannerListQueryType = {
                searchWord: listQueryTypeParam?.searchWord,
                type: listQueryTypeParam?.type,
                status: listQueryTypeParam?.status,
                websiteType: listQueryTypeParam?.websiteType,
                availableStartAt: listQueryTypeParam?.availableStartAt,
                availableEndAt: listQueryTypeParam?.availableEndAt,
            };

            return () => setUrlSearchParams(param, pageSearchCondition);
        },
        [setUrlSearchParams]
    );

    return {
        pageInfoOnFilter,
        filterValues,
        searchList,
        listQueryType,
    };
}