import React from "react";
import { Anchor } from "../../components/Anchor";

interface Props {
  onClickFunc: (e: React.MouseEvent) => void;
  content: string;
  isLeftAlign?: boolean;
}

export function AnchorComponent({ onClickFunc, content }: Props) {
  return (
    <Anchor onClick={onClickFunc} href={"#"} rel="noopener noreferrer">
      {content}
    </Anchor>
  );
}
