import {LinkOption} from "../../../types/banner";
import {useBannerType} from "../../../types/hooks/useBannerType";
import {RadioGroupField} from "../../../components/RadioGroupField";

interface Props{
    linkOption: LinkOption;
    setLinkOption: (_: LinkOption) => void;
}
export default function BannerLinkOptionField({linkOption, setLinkOption} : Props) {
    const {getBannerLinkOptionLabel} = useBannerType();

    return (
        <>
            <RadioGroupField
                enumValues={Object.values(LinkOption)}
                getLabel={getBannerLinkOptionLabel}
                value={linkOption} setValue={setLinkOption}
                groupName={"bannerLinkOption"} isRequired={true}
            />
        </>
    );
};