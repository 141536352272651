import { Panel } from "../../../../layout/content/detail/Panel";
import { DescriptionList } from "../../../../layout/content/detail/DescriptionList";
import { OrdersVO } from "../../../../types/orders";
import { OrderStatusStyle } from "../../component/OrderStatusStyle";
import { useOrderStatus } from "../../hooks/useOrderStatus";
import { useTranslation } from "react-i18next";
import { useOrdersType } from "../../../../types/hooks/useOrdersType";
import { useProductType } from "../../../../types/hooks/useProductType";
import { useTimeConverter } from "../../../../hooks/hooks";

interface Props {
  orderVO: OrdersVO;
}

export function OrderDetailInfo({ orderVO }: Props) {
  const { isBeforePaid } = useOrderStatus();
  const { t } = useTranslation("order");
  const { toStringFromOrderStatus } = useOrdersType();
  const { toStringFromDisplayPosition } = useProductType();
  const { timeConverter } = useTimeConverter();
  function getDescription() {
    return [
      [
        { key: t("detail.orderedAt"), value: timeConverter.convertToLocalTime(orderVO.createAt) },
        {
          key: t("detail.status"),
          value: (
            <OrderStatusStyle orderStatus={orderVO.status}>{toStringFromOrderStatus(orderVO.status)}</OrderStatusStyle>
          ),
        },
      ],
      [
        {
          key: t("detail.paidAt"),
          value:
            isBeforePaid(orderVO.status) || !orderVO.paidAt
              ? t("detail.unpaid")
              : timeConverter.convertToLocalTime(orderVO.paidAt),
        },
        { key: t("detail.displayPosition"), value: toStringFromDisplayPosition(orderVO.displayPosition) },
      ],
    ];
  }

  return (
    <Panel title={t("detail.title")}>
      <DescriptionList data={getDescription()} labelWidth={132} />
    </Panel>
  );
}
