enum TermsCategory {
  NONE = "",
  PRIVACY_POLICY_ADMIN = "PRIVACY_POLICY_ADMIN",
  TERMS_OF_SERVICE_ADMIN = "TERMS_OF_SERVICE_ADMIN",
}

export enum MarketingTermsCategory {
  COLLECTION_AND_USE_OF_PERSONAL_INFORMATION = "COLLECTION_AND_USE_OF_PERSONAL_INFORMATION ",
  RECEIVE_MARKETING_INFORMATION_EMAILS = "RECEIVE_MARKETING_INFORMATION_EMAILS",
  RECEIVE_MARKETING_INFORMATION_SMS = "RECEIVE_MARKETING_INFORMATION_SMS",
}

interface Terms {
  idx: number;
  title: string;
  content: string;
  isAgreementRequired: boolean;
}

export { TermsCategory };
export type { Terms };
