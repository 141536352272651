import { useCallback } from "react";
import { useAppDispatch, useTimeConverter } from "../../../../hooks/hooks";
import { useUtil } from "../../../../util/hooks/useUtil";
import { useFile } from "../../../../hooks/file/useFile";
import { PopupPutVO } from "../../../../types/popup";
import { FileClassification, FileResponse } from "../../../../types/file";
import { setIsPopupDataSaved, updatePopupAsync } from "../../../../store/popupSlice";

export function usePopupUpdater() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();
  const { uploadImages } = useFile();
  const { timeConverter } = useTimeConverter();

  const updatePopup = useCallback(
    async (idx: number, putVO: PopupPutVO) => {
      try {
        let popupImage = putVO.image;

        if (putVO.addedImage) {
          const uploadedFile: FileResponse[] = await uploadImages(
            [putVO.addedImage.file],
            FileClassification.POP_UP_IMAGE
          );
          popupImage = {
            uri: uploadedFile[0].uri,
            fileIdx: uploadedFile[0].idx,
          };
        }
        await dispatch(
          updatePopupAsync({
            popupIdx: idx,
            param: {
              name: putVO.name.trim(),
              image: popupImage,
              availableStartAt: timeConverter.convertStartOfDayToUTC(putVO.availableStartAt),
              availableEndAt: putVO.availableEndAt
                ? timeConverter.convertEndOfDayToUTC(putVO.availableEndAt)
                : undefined,
              type: putVO.type,
              imageLink: putVO.imageLink?.trim(),
              linkOption: putVO.linkOption,
              status: putVO.status,
              websiteType: putVO.websiteType,
              language: putVO.popupLanguage,
            },
          })
        ).unwrap();
        dispatch(setIsPopupDataSaved(true));
        alert("팝업이 수정 되었습니다.");
      } catch (e) {
        console.error(e);
        alert(defaultErrorMessage);
        throw new Error("팝업 수정 에러");
      }
    },
    [defaultErrorMessage, dispatch, timeConverter, uploadImages]
  );

  return { updatePopup };
}
