import { FilterContentType } from "../../../types/tableFilter";
import { FilterCalendarRange } from "../../../layout/content/list/filter/FilterCalendarRange";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  availableStartAt: string;
  setAvailableStartAt: (_: string) => void;
  availableEndAt: string;
  setAvailableEndAt: (_: string) => void;
}

export function DurationField({ availableEndAt, availableStartAt, setAvailableEndAt, setAvailableStartAt }: Props) {
  const { t } = useTranslation(["coupon", "common"]);
  return (
    <FilterCalendarRange
      from={{
        type: FilterContentType.CALENDAR,
        value: availableStartAt,
        onChangeFunc: (s) => setAvailableStartAt(s),
        placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
        isRequired: true,
      }}
      to={{
        type: FilterContentType.CALENDAR,
        value: availableEndAt,
        onChangeFunc: (s) => setAvailableEndAt(s),
        placeholderVal: t("inputDatePlaceholder", { ns: "common" }),
        isRequired: true,
      }}
    />
  );
}
