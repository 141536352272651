import styled from "styled-components";

export const TableStyle = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.cDE};

  th {
    background-color: ${(props) => props.theme.color.cF6};
    height: 46px;
    border: 1px solid ${(props) => props.theme.color.cDE};
    padding: 12px 20px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: ${(props) => props.theme.color.FGDarkBlue};
    text-align: center;
  }

  td {
    border: 1px solid ${(props) => props.theme.color.cDE};
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: ${(props) => props.theme.color.c75};
    text-align: center;
    padding: 12px;

    &.left {
      text-align: left;
    }

    &.right {
      text-align: right;
    }
  }

  tfoot {
    height: 30px;
    line-height: 30px;

    background-color: ${(props) => props.theme.color.cF6};

    font-size: 14px;
    letter-spacing: -0.03em;
    color: ${(props) => props.theme.color.FGDarkBlue};

    td {
      padding: 4px 12px;
      color: ${(props) => props.theme.color.FGDarkBlue};
    }
  }

  &.notKO {
    th {
      padding: 5px 6px;
      line-height: 1.3;
    }
  }
`;
