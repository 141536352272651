import { useAppDispatch } from "../../../../hooks/hooks";
import { useCallback } from "react";
import { callAsync } from "../../../../util/sliceUtil";
import { deleteJoiningOrganManagerAsync } from "../../../../store/organManagerSlice";
import { useUtil } from "../../../../util/hooks/useUtil";

export function useOrganManagerDeleter() {
  const dispatch = useAppDispatch();
  const { defaultErrorMessage } = useUtil();

  const deleteJoiningOrganManager = useCallback(
    (organManagerIdx: number, onSuccess: () => void) => {
      callAsync(
        dispatch(deleteJoiningOrganManagerAsync(organManagerIdx)).unwrap(),
        () => onSuccess(),
        () => alert(defaultErrorMessage)
      ).then();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [defaultErrorMessage, dispatch]
  );

  return {
    deleteJoiningOrganManager,
  };
}
