import style from "../productEditForm.module.scss";
import React from "react";
import { AddedFile, FileType } from "../../../../types/file";
import { Label104x46 } from "../../../../components/Label";
import { useTranslation } from "react-i18next";
import { AnImageAddition } from "../../../../components/AnImageAddition";

interface Props {
  thumbnail: AddedFile | undefined;
  setThumbnail: (_: AddedFile | undefined) => void;
  addedThumbnailImage: FileType | undefined;
  setAddedThumbnailImage: (_: FileType | undefined) => void;
}

export function ProductEditThumbnail({ thumbnail, setThumbnail, addedThumbnailImage, setAddedThumbnailImage }: Props) {
  const { t } = useTranslation("product");

  return (
    <div className={style.field}>
      <Label104x46 className={style.label} text={t("edit.thumbnail.title")} />
      <AnImageAddition
        image={thumbnail}
        setImage={setThumbnail}
        addedImage={addedThumbnailImage}
        setAddedImage={setAddedThumbnailImage}
        additionalText={<span className={style.text}>{t("edit.thumbnail.support")}</span>}
      />
    </div>
  );
}
