import { useCallback } from "react";
import { updateAffiliateCodeSettlementStatusAsync } from "../../../../store/affiliateCodeSlice";
import { useAppDispatch } from "../../../../hooks/hooks";
import { SettlementStatus } from "../../../../types/affiliateCode";
import { getTwoDigitsText } from "../../../../util/etcUtil";

export function useAffiliateCodeSettlementStatusUpdater() {
  const dispatch = useAppDispatch();

  const updateAffiliateCodeSettlementStatus = useCallback(
    async ({
      organManagerIdxes,
      year,
      month,
      toStatus,
    }: {
      organManagerIdxes: number[];
      year: number;
      month: number;
      toStatus: SettlementStatus;
    }) => {
      try {
        await dispatch(
          updateAffiliateCodeSettlementStatusAsync({
            organManagerIdxes,
            yearMonth: `${year}-${getTwoDigitsText(month)}`,
            status: toStatus,
          })
        ).unwrap();
      } catch (e) {
        console.error(e);
        throw new Error(
          "useAffiliateCodeSettlementStatusUpdater - updateAffiliateCodeSettlementStatus occurs an error"
        );
      }
    },
    [dispatch]
  );

  return {
    updateAffiliateCodeSettlementStatus,
  };
}
