import { MiniModal } from "../../../layout/modal/MiniModal";
import React from "react";
import { dateToString } from "../../../util/dateUtil";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../../hooks/hooks";

interface Props {
  isShow: boolean;
  callbackCloseModal: (_: React.MouseEvent) => void;
  leaveAt: string;
  linkElement?: HTMLElement;
}

export function LeaveModal({ isShow, callbackCloseModal, leaveAt, linkElement }: Props) {
  const { t } = useTranslation("member");
  const { isKorean } = useLanguage();

  return (
    <MiniModal
      show={isShow}
      closeModal={(e) => {
        callbackCloseModal(e);
      }}
      title={t("leave.leavedAt")}
      modalWidth={isKorean ? "104px" : "159px"}
      linkElement={linkElement}
    >
      <>
        <span>{dateToString(new Date(leaveAt))}</span>
      </>
    </MiniModal>
  );
}
