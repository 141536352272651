import style from "../../user/memberUserCoupons.module.scss";
import { Table } from "../../../../layout/content/list/table/Table";
import { PaginationWrapper } from "../../../../layout/content/list/PaginationWrapper";
import { Pagination } from "../../../../layout/content/list/Pagination";
import { Panel } from "../../../../layout/content/detail/Panel";
import React, { useEffect } from "react";
import { useCouponColumns } from "./hooks/useCouponColumns";
import { CustomerType } from "../../../../types/common";
import { useCouponHistoryDeleter } from "../../../coupon/hooks/useCouponHistoryDeleter";
import { useCouponCntInfo } from "../../../coupon/hooks/useCouponCntInfo";
import { PageResponse, PageSearchCondition } from "../../../../types/page";
import { CouponHistoryListObj } from "../../../../types/coupon";
import { useTranslation } from "react-i18next";

interface Props {
  targetIdx: number;
  customerType: CustomerType;
  isAvailable: boolean;
  setIsAvailable: (_: boolean) => void;
  couponHistoryList: PageResponse<CouponHistoryListObj> | undefined;
  getCouponHistoryList: ({ page, sort, size }: PageSearchCondition) => () => void;
  pageCondition: PageSearchCondition;
}

export function CouponInfoList({
  targetIdx,
  customerType,
  isAvailable,
  setIsAvailable,
  couponHistoryList,
  getCouponHistoryList,
  pageCondition,
}: Props) {
  const { t } = useTranslation("member");
  const { getCouponCntInfo } = useCouponCntInfo({
    targetParam: {
      targetIdx,
      targetType: customerType,
    },
  });

  const { deleteCouponHistory } = useCouponHistoryDeleter({
    onComplete: () => {
      alert(t("coupon.delete.resultMessage"));
      getCouponHistoryList({ page: pageCondition.page })();
      getCouponCntInfo();
    },
  });
  const { columns } = useCouponColumns({ deleteCouponHistory });

  useEffect(() => {
    getCouponHistoryList({})();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAvailable]);

  return (
    <Panel
      title={t("coupon.list.title")}
      additionalButtons={
        <label className={style.headerCheckboxLabel}>
          <input type={"checkbox"} readOnly={true} checked={isAvailable} onClick={() => setIsAvailable(!isAvailable)} />
          {t("coupon.list.viewOnlyAvailableItem")}
        </label>
      }
    >
      <>
        {couponHistoryList ? (
          <>
            <Table
              listData={couponHistoryList}
              columns={columns}
              getList={getCouponHistoryList}
              noDataText={t("coupon.list.noData")}
            />
            {couponHistoryList.content.length === 0 ? (
              <></>
            ) : (
              <PaginationWrapper>
                <Pagination
                  page={couponHistoryList}
                  goSpecificPage={(pageNum: number) => getCouponHistoryList({ page: pageNum })()}
                />
              </PaginationWrapper>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    </Panel>
  );
}
