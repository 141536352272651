import {useCallback, useMemo} from "react";
import {LinkOption, PopupStatus, PopupType} from "../popup";
import {Languages, WebsiteType} from "../common";

export function usePopupType() {
  const popupTypeLabelRouter = useMemo(
    () => ({
      [PopupType.IMAGE.toString()]: "이미지",
    }),
    []
  );
  const getPopupTypeLabel = useCallback(
    (type: PopupType) => popupTypeLabelRouter[type.toString()],
    [popupTypeLabelRouter]
  );

  const popupLinkOptionLabelRouter = useMemo(
    () => ({
      [LinkOption.NEW_TAB.toString()]: "새로운 탭으로 이동",
      [LinkOption.CURRENT_TAB.toString()]: "현재 탭에서 이동",
    }),
    []
  );
  const getPopupLinkOptionLabel = useCallback(
    (linkOption: LinkOption) => popupLinkOptionLabelRouter[linkOption.toString()],
    [popupLinkOptionLabelRouter]
  );

  const popupLinkOptionLabelOnListRouter = useMemo(
    () => ({
      [LinkOption.NEW_TAB.toString()]: "새로운 탭",
      [LinkOption.CURRENT_TAB.toString()]: "현재 탭",
    }),
    []
  );
  const getPopupLinkOptionLabelOnList = useCallback(
    (linkOption: LinkOption) => popupLinkOptionLabelOnListRouter[linkOption.toString()],
    [popupLinkOptionLabelOnListRouter]
  );

  const popupStatusLabelRouter = useMemo(
    () => ({
      [PopupStatus.UNEXPOSED.toString()]: "미노출",
      [PopupStatus.EXPOSED.toString()]: "노출",
    }),
    []
  );
  const getPopupStatusLabel = useCallback(
    (status: PopupStatus) => popupStatusLabelRouter[status.toString()],
    [popupStatusLabelRouter]
  );

    const websiteTypeLabelRouter = useMemo(
        () => ({
            [WebsiteType.KPASS.toString()]: "K-PASS",
            [WebsiteType.DCAS.toString()]: "D-CAS",
        }),
        []
    );
    const getWebsiteTypeLabel = useCallback(
        (type: WebsiteType) => websiteTypeLabelRouter[type],
        [websiteTypeLabelRouter]
    );

    const popupLanguageLabelRouter = useMemo(
        () => ({
            [Languages.KO.toString()]: "한국어",
            [Languages.EN.toString()]: "영어",
            [Languages.ZH.toString()]: "중문(번체)",
            [Languages.RU.toString()]: "러시아어",
        }),
        []
    );
    const getPopupLanguageLabel = useCallback(
        (type: Languages) => popupLanguageLabelRouter[type],
        [popupLanguageLabelRouter]
    );

  return {
    getPopupTypeLabel,
    getPopupLinkOptionLabel,
    getPopupStatusLabel,
    getWebsiteTypeLabel,
    getPopupLinkOptionLabelOnList,
    getPopupLanguageLabel,
  };
}
