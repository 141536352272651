import {
  DEFAULT_PAGE,
  DEFAULT_SIZE,
  DEFAULT_SORT,
  ordersToSortArr,
  PageResponse,
  PageSearchCondition,
} from "../../../../types/page";
import { UserListObj, UserQueryType, UserSearchCondition, UserStatus } from "../../../../types/user";
import { hasBothValuesOrBothNoValue, isFromNumberMoreThanToNumber } from "../../../../util/etcUtil";
import { getUserListAsync, memberUserList, setUserList } from "../../../../store/userSlice";
import {
  useAppDispatch,
  useAppSelector,
  useListFilterValues,
  useNationality,
  useTimeConverter,
} from "../../../../hooks/hooks";
import { useEffect, useMemo, useState } from "react";
import { UserListQueryType } from "./useUserFilterValues";
import { useTranslation } from "react-i18next";
import { useUtil } from "../../../../util/hooks/useUtil";
import { dateToString } from "../../../../util/dateUtil";
import { useSortParam } from "../../../../hooks/list/useSortParam";

interface Args {
  onCompletedCallback?: () => void;
}

export function useUserList({ onCompletedCallback }: Args) {
  const { t } = useTranslation("user");
  const dispatch = useAppDispatch();
  const userList = useAppSelector(memberUserList);
  const { filterValues, searchList, listQueryType } = useListFilterValues();
  const { timeConverter } = useTimeConverter();
  const { defaultErrorMessage } = useUtil();
  const { toSortArray } = useSortParam();
  const pageCondition = useMemo(
    () => ({
      page: userList?.number ?? DEFAULT_PAGE,
      size: userList?.size ?? DEFAULT_SIZE,
      sort: ordersToSortArr(userList?.orders),
    }),
    [userList]
  );
  const { nationality } = useNationality();
  const {
    searchWordTypeFilterValue,
    searchWordFilterValue,
    ageFromFilterValue,
    ageToFilterValue,
    totalTestCntFromFilterValue,
    totalTestCntToFilterValue,
    statusFilterValue,
    createdAtFromFilterValue,
    createdAtToFilterValue,
    countryFilterValue,
  } = filterValues;

  const [totalMemberCnt, setTotalMemberCnt] = useState<number | null>(null);

  const searchUserList = (pageSearchCondition: PageSearchCondition) => {
    const param: UserListQueryType = {
      searchWordType: searchWordTypeFilterValue.value,
      searchWord: searchWordFilterValue.value ? searchWordFilterValue.value.trim() : undefined,
      ageFrom: ageFromFilterValue.value ? ageFromFilterValue.value : undefined,
      ageTo: ageToFilterValue.value ? ageToFilterValue.value : undefined,
      createdAtFrom: createdAtFromFilterValue.value ? dateToString(createdAtFromFilterValue.value) : undefined,
      createdAtTo: createdAtToFilterValue.value ? dateToString(createdAtToFilterValue.value) : undefined,
      country: countryFilterValue.value ? countryFilterValue.value : undefined,
      totalTestCntFrom: totalTestCntFromFilterValue.value ? totalTestCntFromFilterValue.value : undefined,
      totalTestCntTo: totalTestCntToFilterValue.value ? totalTestCntToFilterValue.value : undefined,
      status: statusFilterValue.value ? statusFilterValue.value : undefined,
    };

    return () => {
      if (!hasBothValuesOrBothNoValue(param.ageFrom, param.ageTo)) {
        alert(t("search.alertInputBothAgeValues"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.ageFrom), Number(param.ageTo))) {
        alert(t("search.alertInputCorrectAgeValue"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.createdAtFrom, param.createdAtTo)) {
        alert(t("search.alertInputBothCreatedAtValues"));
        return;
      }

      if (!hasBothValuesOrBothNoValue(param.totalTestCntFrom, param.totalTestCntTo)) {
        alert(t("search.alertInputBothTotalTestCountValues"));
        return;
      }

      if (isFromNumberMoreThanToNumber(Number(param.totalTestCntFrom), Number(param.totalTestCntTo))) {
        alert(t("search.alertInputCorrectTotalTestCountValues"));
        return;
      }

      searchList(pageSearchCondition, param)();
    };
  };

  const getUserList = async (param: UserSearchCondition, isFirstLoad?: boolean) => {
    try {
      const result: PageResponse<UserListObj> = await dispatch(getUserListAsync(param)).unwrap();
      if (isFirstLoad) {
        setTotalMemberCnt(result.totalElements);
      } else {
        dispatch(setUserList(result));
      }
      onCompletedCallback?.();
    } catch (e) {
      console.error(e);
      alert(defaultErrorMessage);
    }
  };

  useEffect(() => {
    getUserList({}, true).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listQueryType) {
      getUserList({
        pageCondition: {
          page: listQueryType.page ? Number(listQueryType.page) : DEFAULT_PAGE,
          size: listQueryType.size ? Number(listQueryType.size) : DEFAULT_SIZE,
          sort: listQueryType.sort ? toSortArray(listQueryType.sort) : DEFAULT_SORT,
        },
        queryType: listQueryType.searchWordType ? (listQueryType.searchWordType as UserQueryType) : undefined,
        q: listQueryType.searchWord?.trim(),
        ageFrom: listQueryType.ageFrom ? Number(listQueryType.ageFrom) : undefined,
        ageTo: listQueryType.ageTo ? Number(listQueryType.ageTo) : undefined,
        createdAtFrom: listQueryType.createdAtFrom
          ? timeConverter.convertToFilterStartOfDay(listQueryType.createdAtFrom)
          : undefined,
        createdAtTo: listQueryType.createdAtTo
          ? timeConverter.convertToFilterEndOfDay(listQueryType.createdAtTo)
          : undefined,
        country: listQueryType.country ? listQueryType.country : undefined,
        nationality: listQueryType.nationality ? listQueryType.nationality : undefined,
        totalTestCntFrom: listQueryType.totalTestCntFrom ? Number(listQueryType.totalTestCntFrom) : undefined,
        totalTestCntTo: listQueryType.totalTestCntTo ? Number(listQueryType.totalTestCntTo) : undefined,
        status: listQueryType.status ? (listQueryType.status as UserStatus) : undefined,
      }).then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listQueryType, nationality]);

  return {
    searchUserList,
    totalMemberCnt,
    userList,
    pageCondition,
  };
}
