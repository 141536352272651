import styled from "styled-components/macro";

export const SupportTextStyle = styled.span`
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: ${(props) => props.theme.color.c75};
  white-space: break-spaces;
`;

export const StrongTextStyle = styled.span`
  font-weight: bold;
`;

export const StrongDarkTextStyle = styled(StrongTextStyle)`
  color: ${(props) => props.theme.color.FGDarkBlue};
`;

export const StrongBlueTextStyle = styled(StrongTextStyle)`
  color: ${(props) => props.theme.color.FGBlue};
`;

export const ErrorMessageStyle = styled.span`
  font-size: 12px;
  color: ${(props) => props.theme.color.FGPointRed};
`;

export const CenterTextStyle = styled.div`
  text-align: center;
`;

export const RightTextStyle = styled.div`
  text-align: right;
`;

export const RequiredTextStyle = styled.span`
  color: ${(props) => props.theme.color.required};
`;

export const LightGrayTextStyle = styled.span`
  color: ${(props) => props.theme.color.cC6};
`;
