import React, {useCallback, useState} from "react";
import {ListTable} from "../../../layout/content/list/ListTable";
import {ListCount, WhichList} from "../../../components/ListCount";
import {useSimpleTesterFilters} from "./hooks/useSimpleTesterFilters";
import {useSimpleTesterColumns} from "./hooks/useSimpleTesterColumns";
import {useSimpleTesterList} from "./hooks/useSimpleTesterList";
import styles from "../../../layout/content/list/list.module.scss"
import {useTranslation} from "react-i18next";
import {useSimpleTesterDownloader} from "./hooks/useSimpleTesterDownloader";

export function MemberSimpleTester() {
  const { t } = useTranslation("simpleTester");
  const [selectedSimpleTesterIdxes, setSelectedSimpleTesterIdxes] = useState<number[]>([]);

  const { searchTesterList, totalTesterCount, testers, pageCondition } = useSimpleTesterList({
    onCompletedCallback: () => setSelectedSimpleTesterIdxes([]),
  });
  const { filters } = useSimpleTesterFilters({ search: searchTesterList({}) });

  const { columns } = useSimpleTesterColumns();
  const {downloadSimpleTester} = useSimpleTesterDownloader();

  const onClickDownloadSimpleTesters = useCallback(() => {
    if (selectedSimpleTesterIdxes.length < 1) {
      alert(t("list.noChecked"));
    } else {
      downloadSimpleTester(selectedSimpleTesterIdxes)
    }
    //eslint-disable-next-line
  }, [t, selectedSimpleTesterIdxes]);

  return (
    <>
      {testers ? (
        <ListTable
          pageOnFilter={pageCondition}
          filters={filters}
          getList={searchTesterList}
          columns={columns}
          totalRowsCnt={{
            totalCount: <ListCount totalCount={totalTesterCount} whichList={WhichList.TESTER} />,
            singleUnitKeyInCommonFile: "testerUnit.singleComponent",
            multipleUnitKeyInCommonFile: "testerUnit.multipleComponent",
          }}
          buttonsNode={
          <div className={styles.btnWrapper}>
            <button className={styles.excelBtn} onClick={onClickDownloadSimpleTesters}>
              <div className={styles.textWrapper}>
                <div className={styles.img}></div>
                <span className={styles.text}>{t("excelBtn")}</span>
              </div>
            </button>
          </div>
        }
          checkedRowIdxArr={selectedSimpleTesterIdxes}
          setCheckedRowIdxArr={setSelectedSimpleTesterIdxes}
          listData={testers}
        />
      ) : (
        <></>
      )}
    </>
  );
}
