import { useAdminFilterValues } from "./admin/hooks/useAdminFilterValues";
import { MemberAdmin } from "./admin/MemberAdmin";
import { ListFilterValuesContext } from "../../hooks/list/ListFilterValuesContext";

export function MemberAdminWrapper() {
  const { filterValues, pageInfoOnFilter, searchList, listQueryType } = useAdminFilterValues();

  return (
    <ListFilterValuesContext.Provider
      value={{
        filterValues,
        listQueryType,
        pageInfoOnFilter,
        searchList,
      }}
    >
      <MemberAdmin />
    </ListFilterValuesContext.Provider>
  );
}
