import {PanelWrapper} from "../../../layout/content/detail/PanelWrapper";
import {Panel} from "../../../layout/content/detail/Panel";
import style from "../../../layout/content/post/creator.module.scss";
import {Label104x46} from "../../../components/Label";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import BannerNameField from "./BannerNameField";
import {useAppDispatch, useNewWindow, useTimeConverter} from "../../../hooks/hooks";
import {dateToString} from "../../../util/dateUtil";
import {BannerDetailVO, BannerLocation, BannerStatus, BannerType, LinkOption} from "../../../types/banner";
import {WebsiteType} from "../../../types/common";
import {AddedFile, FileType} from "../../../types/file";
import {setIsBannerDataSaved} from "../../../store/bannerSlice";
import BannerDurationField from "./BannerDurationField";
import formStyle from "../../product/edit/productEditForm.module.scss";
import BannerTypeField from "./BannerTypeField";
import BannerLocationField from "./BannerLocationField";
import BannerImageField from "./BannerImageField";
import BannerFirTextField from "./BannerFirTextField";
import BannerSecTextField from "./BannerSecTextField";
import BannerButtonTextField from "./BannerButtonTextField";
import BannerButtonLink from "./BannerButtonLink";
import BannerLinkOptionField from "./BannerLinkOptionField";
import BannerStatusField from "./BannerStatusField";
import {ButtonWrapperStyle, PrimaryButton} from "../../../components/Buttons";
import useBannerDetailGetter from "./hooks/useBannerDetailGetter";
import {useParams} from "react-router-dom";
import {useBannerValidator} from "./hooks/useBannerValidator";
import {WindowMessage} from "../../../types/newWindow";
import {useBannerUpdater} from "./hooks/useBannerUpdater";
import WebsiteTypeField from "./WebsiteTypeField";

export default function BannerDetail() {
    const dispatch = useAppDispatch();
    const formRef = useRef<HTMLFormElement>(null);
    const { closeWindowAndNotifyToParentWindow } = useNewWindow();
    const {timeConverter} = useTimeConverter();

    const { updateBanner } = useBannerUpdater();

    const {id} = useParams<"id">();
    const bannerIdx = useMemo(() => Number(id), [id]);

    const [name, setName] = useState("");
    const [availableStartAt, setAvailableStartAt] = useState(dateToString(new Date()));
    const [availableEndAt, setAvailableEndAt] = useState(dateToString(new Date()));
    const [isAvailableAlways, setIsAvailableAlways] = useState(false);
    const [websiteType, setWebsiteType] = useState(WebsiteType.KPASS);
    const [bannerType, setBannerType] = useState(BannerType.KOREAN);
    const [bannerLocation, setBannerLocation] = useState(BannerLocation.MAIN);
    const [bannerImage, setBannerImage] = useState<AddedFile | undefined>();
    const [addBannerImage, setAddBannerImage] = useState<FileType | undefined>();
    const [description1, setDescription1] = useState("");
    const [description2, setDescription2] = useState("");
    const [buttonDescription, setButtonDescription] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [linkOption, setLinkOption] = useState(LinkOption.NEW_TAB);
    const [status, setStatus] = useState(BannerStatus.UNEXPOSED);

    /* eslint-disable */
    const [hasChangedValue, setHasChangedValue] = useState(false);

    const changeValue = useCallback(
        (v: any, changeFunc: (_: any) => void) => {
            if (v) {
                dispatch(setIsBannerDataSaved(false));
                setHasChangedValue(true);
            }
            changeFunc(v);
        },
        [dispatch, setHasChangedValue]
    );

    const {getBannerDetail} = useBannerDetailGetter({bannerIdx});
    useEffect(() => {
    getBannerDetail().then((vo: BannerDetailVO) => {
        setName(vo.name);
        setBannerImage(vo.image);
        setBannerType(vo.type);
        setDescription1(vo.description1);
        setDescription2(vo.description2);
        setWebsiteType(vo.websiteType);
        setButtonDescription(vo.buttonDescription);
        setButtonLink(vo.buttonLink);
        setLinkOption(vo.linkOption);
        setStatus(vo.status);
        setIsAvailableAlways(vo.availableEndAt === null);
        setAvailableStartAt(timeConverter.convertToLocalDate(vo.availableStartAt));
        setAvailableEndAt("");
        if (vo.availableEndAt) {
            setAvailableEndAt(timeConverter.convertToLocalDate(vo.availableEndAt));
        }
    });
    }, []);

    const {validate} = useBannerValidator({
        name,
        availableEndAt,
        isAvailableAlways,
        image: bannerImage,
        addedImage: addBannerImage,
        description1,
        description2,
        buttonDescription,
        websiteType,
        bannerType,
        confirmMessage: "배너 정보를 수정 하시겠습니까?"
    });


    const onClickModifyButton = useCallback(() => {
        if (!formRef.current?.reportValidity()) return;
        if (!validate()) return;

        updateBanner(bannerIdx, {
            name,
            type : bannerType,
            bannerLocation,
            availableStartAt,
            availableEndAt,
            description1,
            description2,
            buttonDescription,
            buttonLink,
            image: bannerImage!,
            addedImage: addBannerImage,
            linkOption,
            status,
            websiteType,
        }).then(() => {
            closeWindowAndNotifyToParentWindow(WindowMessage.BANNER);
        });
    }, [
        name,
        bannerType,
        availableStartAt,
        availableEndAt,
        description1,
        description2,
        buttonDescription,
        buttonLink,
        addBannerImage,
        linkOption,
        status,
        validate,
        websiteType,
    ]);
    return (
        <PanelWrapper>
            <Panel title={"배너 정보"}>
                <form className={formStyle.form} onSubmit={(e) => e.preventDefault()} ref={formRef}>
                    <div className={style.fieldLine}>
                        <Label104x46 text={"배너명"} isRequired={true}/>
                        <BannerNameField name={name} setName={(v) => changeValue(v, setName)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"홈페이지"} isRequired={true}/>
                        <WebsiteTypeField websiteType={websiteType}
                                          setWebsiteType={(v) => {
                                             changeValue(v, setWebsiteType);
                                             setBannerType(BannerType.KOREAN)}}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"유효기간"} isRequired={true}/>
                        <BannerDurationField
                            startAt={availableStartAt} setStartAt={(v) => changeValue(v, setAvailableStartAt)}
                            endAt={availableEndAt} setEndAt={(v) => changeValue(v, setAvailableEndAt)}
                            isAvailableAlways={isAvailableAlways}
                            setIsAvailableAlways={(v) => changeValue(v, setIsAvailableAlways)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"배너 언어"} isRequired={true}/>
                        <BannerTypeField bannerType={bannerType} websiteType={websiteType}
                                         setBannerType={(v) => changeValue(v, setBannerType)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"배너 위치"} isRequired={true}/>
                        <BannerLocationField bannerLocation={bannerLocation}
                                             setBannerLocation={(v) => changeValue(v, setBannerLocation)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"배너 이미지"} isRequired={true} className={"alignSelfStart"}/>
                        <BannerImageField image={bannerImage}
                                          setImage={(v) => changeValue(v, setBannerImage)}
                                          addBannerImage={addBannerImage}
                                          setAddBannerImage={(v) => changeValue(v, setAddBannerImage)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"배너 문구1"} className={"alignSelfStart"}/>
                        <BannerFirTextField bannerText={description1} bannerTextNumber={"배너 문구1"}
                                            setBannerText={(v) => changeValue(v, setDescription1)}/>
                    </div>
                    <div className={style.fieldLine}>
                        <Label104x46 text={"배너 문구2"} className={"alignSelfStart"}/>
                        <BannerSecTextField bannerText={description2} bannerTextNumber={"배너 문구2"}
                                            setBannerText={(v) => changeValue(v, setDescription2)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"버튼 문구"} isRequired={true}/>
                        <BannerButtonTextField buttonText={buttonDescription}
                                               setButtonText={(v) => changeValue(v, setButtonDescription)}/>

                        <Label104x46 text={"버튼 링크"} isRequired={true}/>
                        <BannerButtonLink buttonLink={buttonLink} setButtonLink={(v) => changeValue(v, setButtonLink)}/>
                    </div>

                    <div className={style.fieldLine}>
                        <Label104x46 text={"링크 옵션"} isRequired={true}/>
                        <BannerLinkOptionField linkOption={linkOption}
                                               setLinkOption={(v) => changeValue(v, setLinkOption)}/>
                    </div>
                    <div className={style.fieldLine}>
                        <Label104x46 text={"상태"} isRequired={true}/>
                        <BannerStatusField bannerStatus={status} setBannerStatus={(v) => changeValue(v, setStatus)}/>
                    </div>

                    <ButtonWrapperStyle className={"marginTB20"}>
                        <PrimaryButton className={"width300 fontSize20"} onClick={onClickModifyButton}>
                            배너 수정
                        </PrimaryButton>
                    </ButtonWrapperStyle>
                </form>
            </Panel>
        </PanelWrapper>
    );
};